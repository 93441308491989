import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Select from 'react-select'
import { getTrans } from '../../../utils'
import './AnalystSidebar.css'

class AnalystSidebar extends Component {
    state = {
        allowedPage: ['Publish To Platform', 'Published News', 'News Pull', 'Admin', 'Template'],
    }
    componentDidMount() {
        this.checkAllowedPage()
    }
    checkAllowedPage() {
        let currentPath = window.location.pathname
        if (currentPath === '/app/raw-search/') {
            currentPath = 'Publish To Platform'
        } else if (currentPath === '/app/publish-search/') {
            currentPath = 'Published News'
        } else if (currentPath === '/app/analyst-dashboard/') {
            currentPath = 'News Pull'
        } else if (currentPath.includes('admin')) {
            currentPath = 'Admin'
        } else if (currentPath.includes('template')) {
            currentPath = 'Template'
        } else {
            window.location.pathname = '/app/analyst-dashboard/'
        }
    }
    render() {
        const isPageAllowed = page => this.state.allowedPage.indexOf(page) !== -1

        return (
            <>
                {isPageAllowed('News Pull') && (
                    <NavLink to="/app/analyst-dashboard/" className="nav-item">
                        <span>{getTrans('News Pull')}</span>
                    </NavLink>
                )}
                {isPageAllowed('Publish To Platform') && (
                    <NavLink to="/app/raw-search/" className="nav-item">
                        <span>{getTrans('Publish To Platform')}</span>
                    </NavLink>
                )}
                {isPageAllowed('Published News') && (
                    <NavLink to="/app/publish-search/" className="nav-item">
                        <span>{getTrans('Published News')}</span>
                    </NavLink>
                )}
                {isPageAllowed('Admin') && (
                    <NavLink
                        to="/app/admin/enduser/"
                        className={this.props.urlPath.includes('admin') ? 'nav-item active' : 'nav-item'}
                    >
                        <span>{getTrans('Admin')}</span>
                    </NavLink>
                )}
                {isPageAllowed('Template') && (
                    <NavLink
                        to="/app/newsletter-template/"
                        className={this.props.urlPath.includes('template') ? 'nav-item active' : 'nav-item'}
                    >
                        <span>{getTrans('Templates')}</span>
                    </NavLink>
                )}
                {/* <NavLink to="/app/analyst-dashboard/" className="nav-item">
                    <span>{getTrans('News Pull')}</span>
                </NavLink>
                <NavLink to="/app/raw-news/" className="nav-item">
                    <span>{getTrans('Publish To Platform')}</span>
                </NavLink>
                <NavLink to="/app/publish-deals/" className="nav-item">
                    <span>{getTrans('Publish Deals')}</span>
                </NavLink>
                <NavLink to="/app/add-news/" className="nav-item">
                    <div className="addNewsButtonContainer">
                        <p>Add News</p>
                        <p>+</p>
                    </div>
                </NavLink>
                <NavLink to="/app/published-news/" className="nav-item">
                    <span>{getTrans('Published News')}</span>
                </NavLink>
                <NavLink
                    to="/app/admin/enduser/"
                    className={this.props.urlPath.includes('admin') ? 'nav-item active' : 'nav-item '}
                >
                    <span>{getTrans('Admin')}</span>
                </NavLink>
                <NavLink to="/app/bingsearch/" className="nav-item">
                    <span>{getTrans('Bing Search')}</span>
                </NavLink> */}

                {/* {this.props.allIndustries && (
                    <div className="analystSidebar__dropdownContainer">
                        <p className="analystSidebar__dropdownLabel">Industry</p>
                        <Select
                            className="analystSidebar__dropdown"
                            name="Industry"
                            placeholder="Industry"
                            options={this.props.allIndustries.filter(arr => arr.type === 'Industry')}
                            value={this.props.sidebarIndustries.industry}
                            onChange={value =>
                                this.props.setSidebarIndustries({
                                    industry: value,
                                    sector: {},
                                    company: {},
                                })
                            }
                        />
                        <p className="analystSidebar__dropdownLabel">Sector</p>
                        <Select
                            className="analystSidebar__dropdown"
                            name="Sector"
                            placeholder="Sector"
                            options={this.props.allIndustries.filter(arr => arr.type === 'Sector')}
                            value={this.props.sidebarIndustries.sector}
                            onChange={value =>
                                this.props.setSidebarIndustries({
                                    industry: {},
                                    sector: value,
                                    company: {},
                                })
                            }
                        />
                        <p className="analystSidebar__dropdownLabel">Company</p>
                        <Select
                            name="Company"
                            placeholder="Company"
                            options={this.props.allCompanies}
                            value={this.props.sidebarIndustries.company}
                            onChange={value =>
                                this.props.setSidebarIndustries({
                                    industry: {},
                                    sector: {},
                                    company: value,
                                })
                            }
                        />
                    </div>
                )} */}
            </>
        )
    }
}

export default AnalystSidebar
