import React, { useState } from 'react'
import CompanyLogo from '../../../components/CompanyLogo/CompanyLogo'
import DownCarrotLarge from '../../../assets/icons/down-carrot-large.svg'
import { CgNotes } from 'react-icons/cg'
import { Button } from '@mui/material'
import ApartmentIcon from '@mui/icons-material/Apartment'
import FactoryIcon from '@mui/icons-material/Factory'
import WarehouseIcon from '@mui/icons-material/Warehouse'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

export default function Description(props) {
    const { Name, Logo, Overview, windowWidth, companyId, screenType, companyUrl } = props
    const { Industry, Revenue, Share, Headquaters } = props
    const [boxExpand, setBoxExpand] = useState(windowWidth > 900 ? true : false)
    const [descExpanded, setDescExpanded] = useState(false)

    const CompanyInfoLines = props => {
        return (
            <div
                className="companyOverview__keyValueBox"
                style={{
                    padding: '16px',
                    border: '1px solid #ececec',
                    // borderRadius: '4px',
                    backgroundColor: 'white',
                    boxShadow: '0 2px 5px whitesmoke',
                    // margin: '0px 80px 0px 120px',
                    // padding: '15px',
                }}
            >
                <p className="companyOverview__key">{props.first}</p>
                <p className="companyOverview__value">{props.second}</p>
            </div>
        )
    }

    const CompanyInfoLine = props => {
        return (
            <div style={{ marginTop: '10px' }}>
                <Button
                    variant="contained"
                    // startIcon={props.icon}
                    style={{
                        background: '#66B2EC1A',
                        borderRadius: '4px',
                        width: '92%',
                        fontSize: '12px',
                        height: '30px',
                        color: 'rgb(25, 118, 210)',
                        fontWeight: 'bold',
                        textTransform: 'none',
                        border: 'none',
                        boxShadow: 'none',
                        justifyContent: 'left',
                        borderRadius: '25px',
                        margin: 'auto',
                        display: 'flex',
                        overflow: 'hidden',
                    }}
                    // title={props.first}
                >
                    {props.first} - {props.second}
                </Button>
            </div>
        )
    }

    return (
        <div className="companyOverview__descriptionContainer d-flex bg-white" style={{ width: '100%' }}>
            <div className="" style={{ width: '68%' }}>
                <div className="companyOverview__heading" style={{ width: '100%' }}>
                    <div
                        className="companyOverview__logoAndName"
                        id="companyOverview__logoAndName"
                        style={{ width: '100%' }}
                    >
                        <CompanyLogo
                            logo={Logo}
                            name={Name}
                            id={companyId}
                            title_link={null}
                            OuterStyle={{ maxWidth: '56px', maxHeight: '56px', margin: '0 10px' }}
                            square={true}
                        />

                        <div className="companyOverview__nameAndDesc">
                            <p className="companyOverview__companyName text-black">{Name}</p>
                            {companyUrl && (
                                <a
                                    href={
                                        companyUrl.split(';')[0].includes('http')
                                            ? companyUrl.split(';')[0]
                                            : `https://${companyUrl.split(';')[0]}`
                                    }
                                    style={{ color: 'black', textDecoration: 'none' }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <p>{companyUrl.split(';')[0]}</p>
                                </a>
                            )}
                        </div>
                        {screenType === 'companies' && (
                            <button
                                title="Pre-meeting brief"
                                className="d-flex align-items-center justify-content-center ms-auto"
                                style={{
                                    marginBottom: '0px',
                                    background: 'rgb(25, 118, 210)',
                                    marginLeft: 'auto',
                                    color: 'white',
                                    fontSize: '13px',
                                    padding: '0px 5px',
                                    borderRadius: '4px',
                                    height: '40px',
                                    fontWeight: 600,
                                    width: 'max-content',
                                }}
                                onClick={props.getPreMeetingData}
                            >
                                Generate Brief
                            </button>
                        )}
                    </div>
                </div>

                <div className="companyOverview__descriptionBox">
                    <div>
                        <span
                            style={{
                                cursor: 'pointer',
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitLineClamp: descExpanded ? 'unset' : 3,
                                WebkitBoxOrient: 'vertical',
                                fontSize: '14px',
                                textAlign: 'justify',
                            }}
                        >
                            {Overview.split('\n').map((article, index) => (
                                <div key={index}>{article}</div>
                            ))}
                        </span>
                        {Overview.length > 200 && (
                            <span
                                style={{ color: 'rgb(25, 118, 210)', cursor: 'pointer', marginTop: '5px' }}
                                onClick={() => {
                                    setDescExpanded(!descExpanded)
                                }}
                                className="d-flex align-items-center"
                            >
                                <span style={{ fontWeight: 600, fontSize: '14px' }}>
                                    {descExpanded ? 'See Less' : 'See More'}
                                </span>
                                {descExpanded ? (
                                    <FaChevronUp
                                        style={{
                                            fontSize: '12px',
                                            marginLeft: '4px',
                                        }}
                                    />
                                ) : (
                                    <FaChevronDown
                                        style={{
                                            fontSize: '12px',
                                            marginLeft: '4px',
                                        }}
                                    />
                                )}
                            </span>
                        )}
                    </div>
                </div>
            </div>

            <div
                style={{
                    background: 'white',
                    width: '31%',
                    // display: 'grid',
                    // gridTemplateColumns: 'repeat(2, 1fr)',
                    // gap: '16px',
                    // rowGap: '0px',
                    // columnGap: '1px',
                    // justifyContent: 'center',
                    // padding: '8px 40px 8px 120px',
                }}
                // className="d-md-grid d-sm-block"
                // className="col-md-4 col-md-30"
            >
                <div
                    style={{
                        width: '95%',
                        margin: '10px auto',
                        boxShadow: '0px 0px 2px 2px lightgray',
                        borderRadius: '20px',
                        padding: '10px 0px',
                    }}
                >
                    {Industry && Industry.length > 0 && (
                        <CompanyInfoLine
                            icon={<FactoryIcon style={{ fontSize: '18px' }} />}
                            first="Industry"
                            second={Industry[0].name}
                        />
                    )}
                    {(Revenue || Revenue !== 0) && (
                        <CompanyInfoLine
                            icon={<AttachMoneyIcon style={{ fontSize: '18px' }} />}
                            first="Revenue"
                            second={Revenue.replace('$', '')}
                        />
                    )}
                    {Share && (
                        <CompanyInfoLine
                            icon={<WarehouseIcon style={{ fontSize: '18px' }} />}
                            first="Type"
                            second={Share}
                        />
                    )}
                    {Headquaters && (
                        <CompanyInfoLine
                            icon={<WarehouseIcon style={{ fontSize: '18px' }} />}
                            first="Headquater"
                            second={Headquaters}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
