import React, { PureComponent } from 'react'
import { getTrans } from '../../utils'
import styled from 'styled-components'
import { IoMdDocument } from 'react-icons/io'

class NoResults extends PureComponent {
    render() {
        return (
            <Wrapper>
                <div className="icon-wrap">
                    <IoMdDocument size={120} />
                </div>
                <p className="title">{getTrans('No results found')}</p>
                <p className="hint-txt">{getTrans('Try selecting another industry.')}</p>
            </Wrapper>
        )
    }
}

export default NoResults

const Wrapper = styled.div`
    .title {
        font-size: 1.5rem;
    }
    .hint-txt {
        font-size: 0.8em;
        color: #9a9a9a;
    }
    .icon-wrap {
        background: #eff0f5;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 25px;
        color: #d3d9dd;
        width: 160px;
        height: 160px;
        border-radius: 50%;
        margin-bottom: 1rem;
    }
`
