import axios from 'axios'
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { Button, Input } from 'reactstrap'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { getCookie } from '../../../utils'
import { BiPlus, BiMinus, BiSearch } from 'react-icons/bi'
import './Filter.css'
import { getYMD } from '../../../utils/dateHelpers'
import { Circles } from 'react-loader-spinner'
import { FcCalendar } from 'react-icons/fc'
import { BiCalendar } from 'react-icons/bi'
import { subDays, addDays } from 'date-fns'
import NewsEditDropdownFilterDatePicker from '../../../components/NewsEditDropdownFilter/NewsEditDropdownFilterDatePicker'
import { IoMdSettings } from 'react-icons/io'
import RangeInput from './RangeInput'
const token = getCookie('csrftoken')
axios.defaults.headers.common['X-CSRFToken'] = token

export default function BasicAdvanceDropDown(props) {
    const { setBasicSearchResponse } = props
    const { btnColour, setIsLoading, popularBtns, regionHierarchy, setRegionHierarchy } = props
    const [regionSection, setRegionSection] = useState(false)
    const [companySection, setCompanySection] = useState(false)
    const [practiceSection, setPracticeSection] = useState(false)
    const [topicSection, setTopicSection] = useState(false)
    const [sourceSection, setSourceSection] = useState(false)
    const [actionSection, setActionSection] = useState(false)
    const [regionInput, setRegionInput] = useState('')
    const [companyInput, setCompanyInput] = useState('')
    const [practiceInput, setPracticeInput] = useState('')
    const [topicInput, setTopicInput] = useState('')
    const [sourceInput, setSourceInput] = useState('')
    const { selectedOption, setSelectedOption } = props
    const { selectedRelavance, setSelectedRelavance, selectedAlpha, setSelectedAlpha } = props
    const [subCategoryOpen, setSubCategoryOpen] = useState([])
    const [sectorOpen, setSectorOpen] = useState([])
    const [topicOpen, setTopicOpen] = useState([])
    const [countryOpen, setCountryOpen] = useState([])
    const [provinceOpen, setProvisionOpen] = useState([])
    const currentPath = window.location.pathname
    const { selectedLevel, setSelectedLevel } = props

    const {
        selectedSectors,
        setSelectedSectors,
        selectedCompanies,
        setSelectedCompanies,
        selectedCategories,
        setSelectedCategories,
        selectedTopics,
        setSelectedTopics,
        selectedRegions,
        setSelectedRegions,
        selectedSources,
        setSelectedSources,
        setSelectedDateFrom,
        setSelectedDateTo,
        allHierarchy,
        practiceSubCategory,
        apiLevel,
        getAllCompanies,
        checkedCompanies,
        setCheckedCompanies,
        checkedPractices,
        setCheckedPractices,
        checkedSources,
        setCheckedSources,
        checkedRegions,
        setCheckedRegions,
        checkedTopics,
        setCheckedTopics,
        usertype,
        generateQuery,
        defaultData,
        setDefaultData,
        dateFormatChanges,
        customDate,
        setcustomDate,
        dateSection,
        setDateSection,
    } = props

    const [selectedIndustries, setSelectedIndustries] = useState([])
    const [selectAllRegion, setSelectAllRegion] = useState(false)
    useEffect(() => {
        setDefaultData(props.allCompanies)
    }, [props.allCompanies])

    ///sector-company
    const [showType, setShowType] = useState({
        topics: [],
        industries: [],
        sectors: [],
    })
    const [showCompanies, setShowCompanies] = useState([])

    const wrapperRef = useRef(null)
    closeExpandable(wrapperRef)

    function closeExpandable(ref) {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setRegionSection(false)
                setCompanySection(false)
                setPracticeSection(false)
                setTopicSection(false)
                setSourceSection(false)
                setDateSection(false)
                setActionSection(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }

    ///industry->sector->topic
    const allIndustriesName = allHierarchy.map(val => val.name)

    const allSectorsName = allHierarchy
        .map(val => val.sectors.map(sec => sec.name))
        .reduce((acc, curr) => acc.concat(curr), [])

    const allTopicsName = allHierarchy
        .map(val => val.sectors.map(sec => sec.topics.map(top => top.name)))
        .reduce((acc, curr) => acc.concat(curr), [])
        .reduce((acc, curr) => acc.concat(curr), [])

    const addingOfIndustriesSectorsNames = allIndustriesName.concat(allSectorsName)

    const stateMap = {
        Industry: [selectedIndustries, setSelectedIndustries],
        sector: [selectedSectors, setSelectedSectors],
        company: [selectedCompanies, setSelectedCompanies],
        country: [selectedRegions, setSelectedRegions],
        topic: [selectedTopics, setSelectedTopics],
        source: [selectedSources, setSelectedSources],
        category: [selectedCategories, setSelectedCategories],
        showCompanies: [showCompanies, setShowCompanies],
    }

    const bulkChange = (type, data, name) => {
        const [selectedItems, setSelectedItems] = stateMap[type]

        let result = selectedItems

        const isSelectedItem = selectedItems.find(item => item === name)
        data.forEach(list => {
            if (isSelectedItem) {
                result = result.filter(item => item !== list.name)
            } else {
                result.push(list.name)
            }
        })

        setSelectedItems([...result])
    }

    const bulkChangesub = (type, data, name) => {
        const [selectedItems, setSelectedItems] = stateMap[type]

        let result = selectedItems

        const isSelectedItem = selectedItems.find(item => item === name)
        data.forEach(list => {
            if (isSelectedItem) {
                result = result.filter(item => item !== list.id)
            } else {
                result.push(list.id)
            }
        })

        setSelectedItems([...result])
    }

    const changeCheck = (type, id, typeOf = null, value = null) => {
        const [selectedItems, setSelectedItems] = stateMap[type]
        const isItemSelected = selectedItems.includes(id)
        if (isItemSelected) {
            const filteredItems = selectedItems.filter(item => item !== id)
            setSelectedItems(filteredItems)
        } else {
            setSelectedItems([...selectedItems, id])
        }

        if (typeOf) {
            if (showType[typeOf].includes(value)) {
                const filtered = showType[typeOf].filter(item => item != value)
                setShowType({ ...showType, [typeOf]: filtered })
            } else {
                setShowType({ ...showType, [typeOf]: [...showType[typeOf], value] })
            }
        }
        // if (type === 'country' && id === 'All') {
        //     selectAllRegion ? setSelectedRegions(props.allRegions.map(each => each.label)) : setSelectedRegions([])
        // }
    }

    const customOnchange = ranges => {
        const startDate = dateFormatChanges(new Date(ranges.selection.startDate))
        const endDate = dateFormatChanges(new Date(ranges.selection.endDate))

        const formatDate = { startDate: startDate, endDate: endDate }
        setcustomDate(formatDate)
    }

    const toggleid = (Id, type) => {
        type(prevExpandedValues => {
            if (prevExpandedValues.includes(Id)) {
                return prevExpandedValues.filter(id => id !== Id)
            } else {
                return [...prevExpandedValues, Id]
            }
        })
    }

    // Function to handle "Select All" checkbox
    const handleSelectAllCompany = () => {
        if (checkedCompanies) {
            setSelectedCompanies([])
        } else {
            const allCompanyLabels = defaultData
                .filter(
                    company => company.label
                    // .toLowerCase().includes(companyInput.toLowerCase())
                )
                .map(company => company.label)
            setSelectedCompanies(allCompanyLabels)
        }
        setCheckedCompanies(!checkedCompanies)
    }

    const handleSelectAllSources = () => {
        if (checkedSources) {
            setSelectedSources([])
        } else {
            const allSourceLabels = props.allSources
                .filter(source => source.label.toUpperCase().includes(sourceInput.toUpperCase()))
                .map(source => source.value)
            setSelectedSources(allSourceLabels)
        }
        setCheckedSources(!checkedSources)
    }

    const handleSelectAllPractice = () => {
        if (checkedPractices) {
            setSelectedCategories([])
        } else {
            const allPracticeLabels = practiceSubCategory
                .filter(category => {
                    const categoryAndSubcategories = [
                        category.name,
                        ...category.subcategories.map(subcategory => subcategory.name),
                    ]
                    return categoryAndSubcategories.some(name =>
                        name.toLowerCase().includes(practiceInput.toLowerCase())
                    )
                })
                .reduce((result, category) => {
                    result.push(category.id)

                    const matchingSubcategories = category.subcategories.filter(subcategory =>
                        subcategory.name.toLowerCase().includes(practiceInput.toLowerCase())
                    )

                    matchingSubcategories.forEach(subcategory => {
                        result.push(subcategory.id)
                    })

                    return result
                }, [])
            setSelectedCategories(allPracticeLabels)
        }
        setCheckedPractices(!checkedPractices)
    }

    const handleSelectAllTopics = () => {
        if (checkedTopics) {
            setSelectedSectors([])
        } else {
            const filteredItems = allHierarchy.filter(ind => {
                const matchingSectors = ind.sectors.filter(sec =>
                    sec.topics.some(topic => topic.name.toLowerCase().includes(topicInput.toLowerCase()))
                )

                return (
                    ind.name.toLowerCase().includes(topicInput.toLowerCase()) ||
                    matchingSectors.length > 0 ||
                    ind.sectors.some(sec => sec.name.toLowerCase().includes(topicInput.toLowerCase()))
                )
            })

            const filteredTopicLabels = filteredItems.reduce((result, val) => {
                result.push(val.name)

                val.sectors.forEach(sector => {
                    result.push(sector.name)

                    sector.topics.forEach(topic => {
                        if (topic.name.toLowerCase().includes(topicInput.toLowerCase())) {
                            result.push(topic.name)
                        }
                    })
                })

                return result
            }, [])
            setSelectedSectors(filteredTopicLabels)
        }
        setCheckedTopics(!checkedTopics)
    }

    const handleSelectAllRegions = () => {
        if (checkedRegions) {
            setSelectedRegions([])
        } else {
            const filteredItems = regionHierarchy.filter(reg => {
                const matchingcountries = reg.countries.filter(cty =>
                    cty.provinces.some(pro => pro.name.toLowerCase().includes(regionInput.toLowerCase()))
                )

                return (
                    reg.name.toLowerCase().includes(regionInput.toLowerCase()) ||
                    matchingcountries.length > 0 ||
                    reg.countries.some(cty => cty.name.toLowerCase().includes(regionInput.toLowerCase()))
                )
            })

            const filteredProvincesLabels = filteredItems.reduce((result, val) => {
                result.push(val.name)

                val.countries.forEach(country => {
                    result.push(country.name)

                    country.provinces.forEach(pro => {
                        if (pro.name.toLowerCase().includes(regionInput.toLowerCase())) {
                            result.push(pro.name)
                        }
                    })
                })

                return result
            }, [])
            setSelectedRegions(filteredProvincesLabels)
        }
        setCheckedRegions(!checkedRegions)
    }

    const companySearch = e => {
        setCompanyInput(e.target.value)
        if (e.target.value.length > 2) {
            axios
                .get(`/news/auto-complete/?type=company&string=${encodeURIComponent(e.target.value)}`)
                .then(response => {
                    const filteredData = response.data.map(item => ({
                        value: item.id,
                        label: item.name,
                    }))
                    const uniqueData = new Set([...filteredData])
                    const arrayFromSet = [...uniqueData]
                    setDefaultData(arrayFromSet)
                })
                .catch(err => console.log(err))
        } else {
            setDefaultData(props.allCompanies)
        }
    }

    return (
        <>
            <div className="Advanced_filters">
                <div
                    className="d-grid overall_drop"
                    style={{ columnGap: '20px', gridTemplateColumns: 'repeat(6, 1fr)' }}
                >
                    <div id="date_drop">
                        <div className="">
                            <Button
                                id="date_btn"
                                style={{
                                    background: btnColour ? '#3B7DBA' : '',
                                    color: btnColour ? 'white' : '',
                                    borderRadius: '25px',
                                    fontSize: '10px',
                                    width: '100%',
                                    // width: '180px',
                                }}
                                onClick={() => {
                                    setDateSection(!dateSection)
                                }}
                                className="d-flex justify-content-center align-content-center"
                            >
                                {currentPath === '/app/search/' || selectedLevel === 'level0'
                                    ? 'Date'
                                    : apiLevel === 'level1'
                                    ? 'Pull Date'
                                    : 'Publish Date'}
                                <ExpandMoreIcon style={{ fontSize: '14px' }} />{' '}
                            </Button>
                        </div>
                        {dateSection && (
                            <div
                                ref={wrapperRef}
                                className="date_section"
                                style={{
                                    width: '220px',
                                    marginTop: '10px',
                                    background: 'white',
                                    position: 'absolute',
                                    padding: '10px',
                                    height: 'auto',
                                    cursor: 'pointer',
                                    marginLeft: '-20px',
                                    borderRadius: '4px',
                                    boxShadow: '0px 0px 2px 2px lightgray',
                                    zIndex: 1,
                                }}
                            >
                                <div className="radio">
                                    <label>
                                        <input
                                            type="radio"
                                            value="1"
                                            checked={selectedOption === '1'}
                                            // onChange={this.onValueChange}
                                            onChange={e => {
                                                setSelectedOption(e.target.value)
                                                generateQuery(1)
                                            }}
                                        />
                                        <span style={{ marginLeft: '10px' }}>Today</span>
                                    </label>
                                </div>
                                <div className="radio">
                                    <label>
                                        <input
                                            type="radio"
                                            value="6"
                                            checked={selectedOption === '6'}
                                            // onChange={this.onValueChange}
                                            onChange={e => {
                                                setSelectedOption(e.target.value)
                                                generateQuery(6)
                                            }}
                                        />
                                        <span style={{ marginLeft: '10px' }}>Last 7 days</span>
                                    </label>
                                </div>
                                <div className="radio">
                                    <label>
                                        <input
                                            type="radio"
                                            value="29"
                                            defaultChecked
                                            checked={selectedOption === '29'}
                                            // onChange={this.onValueChange}
                                            onChange={e => {
                                                setSelectedOption(e.target.value)
                                                generateQuery(29)
                                            }}
                                        />
                                        <span style={{ marginLeft: '10px' }}>Last 30 days</span>
                                    </label>
                                </div>
                                <div className="radio">
                                    <label>
                                        <input
                                            type="radio"
                                            value="59"
                                            checked={selectedOption === '59'}
                                            // onChange={this.onValueChange}
                                            onChange={e => {
                                                setSelectedOption(e.target.value)
                                                generateQuery(59)
                                            }}
                                        />
                                        <span style={{ marginLeft: '10px' }}>Last 60 days</span>
                                    </label>
                                </div>
                                <div className="radio">
                                    <label>
                                        <input
                                            type="radio"
                                            value="89"
                                            checked={selectedOption === '89'}
                                            // onChange={this.onValueChange}
                                            onChange={e => {
                                                setSelectedOption(e.target.value)
                                                generateQuery(89)
                                            }}
                                        />
                                        <span style={{ marginLeft: '10px' }}>Last 90 days</span>
                                    </label>
                                </div>
                                <div className="radio">
                                    <label>
                                        <input
                                            type="radio"
                                            value="364"
                                            checked={selectedOption === '364'}
                                            // onChange={this.onValueChange}
                                            onChange={e => {
                                                setSelectedOption(e.target.value)
                                                generateQuery(364)
                                            }}
                                        />
                                        <span style={{ marginLeft: '10px' }}>Last 1 year</span>
                                    </label>
                                </div>
                                {currentPath !== '/app/search/' && selectedLevel !== 'level0' && (
                                    <div>
                                        <label>
                                            <input
                                                type="radio"
                                                value="custom"
                                                checked={selectedOption === 'custom'}
                                                // onChange={this.onValueChange}
                                                onChange={e => {
                                                    setSelectedOption(e.target.value)
                                                }}
                                            />
                                            <span style={{ marginLeft: '10px' }}>
                                                Custom Date{' '}
                                                <BiCalendar style={{ fontSize: '18px', marginBottom: '5px' }} />
                                                {customDate.startDate !== null && (
                                                    <span>
                                                        <button
                                                            className="btn btn-primary"
                                                            onClick={() => {
                                                                generateQuery('custom')
                                                            }}
                                                            style={{
                                                                height: '4opx',
                                                                fontSize: '10px',
                                                                marginLeft: '10px',
                                                            }}
                                                        >
                                                            OK
                                                        </button>
                                                    </span>
                                                )}
                                            </span>
                                        </label>
                                        {selectedOption === 'custom' && (
                                            <NewsEditDropdownFilterDatePicker
                                                isCustomFilter={true}
                                                setDateRange={setcustomDate}
                                                selectedDateRange={customDate}
                                                customOnchange={customOnchange}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {selectedLevel !== 'level0' && (
                        <div id="region_drop">
                            <div className="d-flex region">
                                <Button
                                    style={{
                                        background: btnColour ? '#3B7DBA' : '',
                                        color: btnColour ? 'white' : '',
                                        borderRadius: '25px',
                                        fontSize: '10px',
                                        width: '100%',
                                    }}
                                    onClick={() => {
                                        setRegionSection(!regionSection)
                                    }}
                                    className="d-flex justify-content-center align-content-center"
                                >
                                    Country{selectedRegions.length !== 0 ? '(' + selectedRegions.length + ')' : ''}
                                    <ExpandMoreIcon style={{ fontSize: '14px' }} />{' '}
                                </Button>
                            </div>
                            {regionSection && (
                                <div
                                    ref={wrapperRef}
                                    className="region_section"
                                    style={{
                                        width: '300px',
                                        marginTop: '10px',
                                        marginLeft: '-80px',
                                        background: 'white',
                                        position: 'absolute',
                                        padding: '20px',
                                        height: '300px',
                                        zIndex: 1,
                                        borderRadius: '4px',
                                        boxShadow: '0px 0px 2px 2px lightgray',
                                    }}
                                >
                                    <Input
                                        type="search"
                                        value={regionInput}
                                        onChange={e => {
                                            setRegionInput(e.target.value)
                                        }}
                                    />
                                    <div style={{ height: '200px', overflow: 'auto' }}>
                                        <div className="d-flex" style={{ marginTop: '10px' }}>
                                            <input
                                                type="checkbox"
                                                id="selectAll"
                                                checked={checkedRegions}
                                                onChange={() => {
                                                    handleSelectAllRegions()
                                                }}
                                            />
                                            <label style={{ marginLeft: '10px' }}>Select All</label>
                                        </div>
                                        {regionHierarchy
                                            .filter(region => {
                                                const regionNameMatches = region.name
                                                    .toLowerCase()
                                                    .includes(regionInput.toLowerCase())

                                                const countriesMatch = region.countries.some(country =>
                                                    country.name.toLowerCase().includes(regionInput.toLowerCase())
                                                )

                                                return regionNameMatches || countriesMatch
                                            })
                                            .map(reg => {
                                                return (
                                                    <>
                                                        <div
                                                            className="d-flex"
                                                            style={{ marginTop: '10px' }}
                                                            key={reg.name}
                                                        >
                                                            <>
                                                                <input
                                                                    type="checkbox"
                                                                    value={reg.name}
                                                                    id={'country' + reg.name}
                                                                    checked={selectedRegions.includes(reg.name)}
                                                                    onChange={() => {
                                                                        bulkChange('country', [reg], reg.name)
                                                                    }}
                                                                />

                                                                <label style={{ marginLeft: '10px' }}>{reg.name}</label>
                                                            </>
                                                        </div>
                                                        {reg.countries &&
                                                            reg.countries.map(cty => (
                                                                <>
                                                                    <div
                                                                        className="d-flex"
                                                                        style={{
                                                                            marginTop: '10px',
                                                                            marginLeft: '15px',
                                                                        }}
                                                                        key={cty.name}
                                                                    >
                                                                        <input
                                                                            type="checkbox"
                                                                            value={cty.name}
                                                                            id={'country' + cty.name}
                                                                            checked={selectedRegions.includes(cty.name)}
                                                                            onChange={() => {
                                                                                bulkChange('country', [cty], cty.name)
                                                                            }}
                                                                        />

                                                                        <label style={{ marginLeft: '10px' }}>
                                                                            {cty.name}
                                                                        </label>
                                                                    </div>
                                                                </>
                                                            ))}
                                                    </>
                                                )
                                            })}
                                    </div>
                                    <div className="d-flex">
                                        <Button
                                            onClick={() => {
                                                setRegionSection(!regionSection)
                                            }}
                                            style={{
                                                backgroundColor: '#fff0',
                                                color: '#1c2b3e',
                                                borderRadius: '25px',
                                                marginRight: '10px',
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                setRegionSection(!regionSection)
                                                generateQuery(selectedOption)
                                            }}
                                            style={{
                                                backgroundColor: '#197bbd',
                                                color: 'white',
                                                borderRadius: '25px',
                                                marginLeft: '10px',
                                            }}
                                            disabled={selectedRegions.length > 0 ? false : true}
                                        >
                                            Show Results
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    {usertype !== 'Regular User' && usertype !== 'Power User' && selectedLevel !== 'level0' && (
                        <>
                            <div id="sector_drop">
                                <div className="d-flex">
                                    <Button
                                        style={{
                                            background: btnColour ? '#3B7DBA' : '',
                                            color: btnColour ? 'white' : '',
                                            borderRadius: '25px',
                                            fontSize: '10px',
                                            width: '100%',
                                        }}
                                        onClick={() => {
                                            setTopicSection(!topicSection)
                                        }}
                                        className="d-flex justify-content-center align-content-center"
                                    >
                                        Topic
                                        {selectedSectors.length !== 0 ? '(' + selectedSectors.length + ')' : ''}{' '}
                                        <ExpandMoreIcon style={{ fontSize: '14px' }} />{' '}
                                    </Button>
                                </div>
                                {topicSection && (
                                    <div
                                        ref={wrapperRef}
                                        className="region_section"
                                        style={{
                                            width: '300px',
                                            marginTop: '10px',
                                            marginLeft: '-80px',
                                            background: 'white',
                                            position: 'absolute',
                                            padding: '20px',
                                            height: '300px',
                                            zIndex: 1,
                                            borderRadius: '4px',
                                            boxShadow: '0px 0px 2px 2px lightgray',
                                        }}
                                    >
                                        <Input
                                            type="search"
                                            value={topicInput}
                                            onChange={e => {
                                                setTopicInput(e.target.value)
                                            }}
                                        />
                                        <div style={{ height: '200px', overflow: 'auto' }}>
                                            <div className="d-flex" style={{ marginTop: '10px' }}>
                                                <input
                                                    type="checkbox"
                                                    id="selectAll"
                                                    checked={checkedTopics}
                                                    onChange={() => {
                                                        handleSelectAllTopics()
                                                    }}
                                                />
                                                <label style={{ marginLeft: '10px' }}>Select All</label>
                                            </div>
                                            {allHierarchy
                                                .reduce((result, ind) => {
                                                    const filteredSectors = ind.sectors.filter(sec =>
                                                        sec.topics.some(top =>
                                                            top.name.toLowerCase().startsWith(topicInput.toLowerCase())
                                                        )
                                                    )

                                                    if (
                                                        filteredSectors.length > 0 ||
                                                        ind.name.toLowerCase().startsWith(topicInput.toLowerCase()) ||
                                                        ind.sectors.find(sec =>
                                                            sec.name.toLowerCase().startsWith(topicInput.toLowerCase())
                                                        )
                                                    ) {
                                                        if (topicInput.length > 2) {
                                                            sectorOpen.push(ind.id)
                                                            filteredSectors.forEach(sec => {
                                                                topicOpen.push(sec.id)
                                                            })
                                                        }
                                                        if (topicInput.length === 1) {
                                                            topicOpen.splice(0, topicOpen.length)
                                                            sectorOpen.splice(0, sectorOpen.length)
                                                        }
                                                        result.push({
                                                            ...ind,
                                                            sectors: ind.sectors
                                                                .filter(
                                                                    sector =>
                                                                        sector.name
                                                                            .toLowerCase()
                                                                            .startsWith(topicInput.toLowerCase()) ||
                                                                        sector.topics.some(topic =>
                                                                            topic.name
                                                                                .toLowerCase()
                                                                                .startsWith(topicInput.toLowerCase())
                                                                        )
                                                                )
                                                                .map(filteredSector => ({
                                                                    ...filteredSector,
                                                                    topics: filteredSector.topics.filter(topic =>
                                                                        topic.name
                                                                            .toLowerCase()
                                                                            .startsWith(topicInput.toLowerCase())
                                                                    ),
                                                                })),
                                                        })
                                                    }
                                                    return result
                                                }, [])
                                                .map(val => {
                                                    return (
                                                        <>
                                                            <div
                                                                className="d-flex"
                                                                style={{ marginTop: '10px' }}
                                                                key={val.name}
                                                            >
                                                                <>
                                                                    <input
                                                                        type="checkbox"
                                                                        value={val.name}
                                                                        id={'sector' + val.name}
                                                                        checked={selectedSectors.includes(val.name)}
                                                                        onChange={() => {
                                                                            bulkChange(
                                                                                'sector',
                                                                                [val]
                                                                                    .concat(...val.sectors)
                                                                                    .concat(
                                                                                        ...val.sectors.map(sec =>
                                                                                            sec.topics.map(top => top)
                                                                                        )
                                                                                    ),
                                                                                val.name
                                                                            )
                                                                        }}
                                                                    />

                                                                    <label style={{ marginLeft: '10px' }}>
                                                                        {val.name}
                                                                    </label>
                                                                    <span
                                                                        key={val.id}
                                                                        style={{
                                                                            marginLeft: 'auto',
                                                                            cursor: 'pointer',
                                                                            marginRight: '10px',
                                                                            display:
                                                                                val.sectors.length === 0
                                                                                    ? 'none'
                                                                                    : 'block',
                                                                        }}
                                                                    >
                                                                        {val.sectors && val.sectors.length > 0 && (
                                                                            <span
                                                                                onClick={() => {
                                                                                    toggleid(val.id, setSectorOpen)
                                                                                }}
                                                                            >
                                                                                {sectorOpen.includes(val.id) ? (
                                                                                    <BiMinus className="minus" />
                                                                                ) : (
                                                                                    <BiPlus className="plus" />
                                                                                )}
                                                                            </span>
                                                                        )}
                                                                    </span>
                                                                </>
                                                            </div>
                                                            {sectorOpen.includes(val.id) &&
                                                                val.sectors &&
                                                                val.sectors.map(sec => (
                                                                    <>
                                                                        <div
                                                                            className="d-flex"
                                                                            style={{
                                                                                marginTop: '10px',
                                                                                marginLeft: '15px',
                                                                            }}
                                                                            key={sec.name}
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                value={sec.name}
                                                                                id={'sector' + sec.name}
                                                                                checked={selectedSectors.includes(
                                                                                    sec.name
                                                                                )}
                                                                                onChange={() => {
                                                                                    bulkChange(
                                                                                        'sector',
                                                                                        [sec, ...sec.topics],
                                                                                        sec.name
                                                                                    )
                                                                                }}
                                                                            />

                                                                            <label style={{ marginLeft: '10px' }}>
                                                                                {sec.name}
                                                                            </label>
                                                                            <span
                                                                                key={sec.id}
                                                                                style={{
                                                                                    marginLeft: 'auto',
                                                                                    cursor: 'pointer',
                                                                                    marginRight: '10px',
                                                                                    display:
                                                                                        sec.topics.length === 0
                                                                                            ? 'none'
                                                                                            : 'block',
                                                                                }}
                                                                            >
                                                                                {sec.topics && sec.topics.length > 0 && (
                                                                                    <span
                                                                                        onClick={() => {
                                                                                            toggleid(
                                                                                                sec.id,
                                                                                                setTopicOpen
                                                                                            )
                                                                                        }}
                                                                                    >
                                                                                        {topicOpen.includes(sec.id) ? (
                                                                                            <BiMinus className="minus" />
                                                                                        ) : (
                                                                                            <BiPlus className="plus" />
                                                                                        )}
                                                                                    </span>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                        {topicOpen.includes(sec.id) &&
                                                                            sec.topics &&
                                                                            sec.topics.map(top => (
                                                                                <div
                                                                                    className="d-flex"
                                                                                    style={{
                                                                                        marginTop: '10px',
                                                                                        marginLeft: '30px',
                                                                                    }}
                                                                                    key={top.name}
                                                                                >
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        value={top.name}
                                                                                        id={'sector' + top.name}
                                                                                        checked={selectedSectors.includes(
                                                                                            top.name
                                                                                        )}
                                                                                        onChange={() => {
                                                                                            changeCheck(
                                                                                                'sector',
                                                                                                top.name
                                                                                            )
                                                                                        }}
                                                                                    />

                                                                                    <label
                                                                                        style={{
                                                                                            marginLeft: '10px',
                                                                                        }}
                                                                                    >
                                                                                        {top.name}
                                                                                    </label>
                                                                                </div>
                                                                            ))}
                                                                    </>
                                                                ))}
                                                        </>
                                                    )
                                                })}
                                        </div>
                                        <div className="d-flex">
                                            <Button
                                                onClick={() => {
                                                    setTopicSection(!topicSection)
                                                }}
                                                style={{
                                                    backgroundColor: '#fff0',
                                                    color: '#1c2b3e',
                                                    borderRadius: '25px',
                                                    marginRight: '10px',
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    setTopicSection(!topicSection)
                                                    generateQuery(selectedOption)
                                                }}
                                                style={{
                                                    backgroundColor: '#197bbd',
                                                    color: 'white',
                                                    borderRadius: '25px',
                                                    marginLeft: '10px',
                                                }}
                                                disabled={selectedSectors.length > 0 ? false : true}
                                            >
                                                Show Results
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div id="company_drop">
                                <div className="d-flex">
                                    <Button
                                        style={{
                                            background: btnColour ? '#3B7DBA' : '',
                                            color: btnColour ? 'white' : '',
                                            borderRadius: '25px',
                                            fontSize: '10px',
                                            width: '100%',
                                        }}
                                        onClick={() => {
                                            setCompanySection(!companySection)
                                        }}
                                        className="d-flex justify-content-center align-content-center"
                                    >
                                        Company
                                        {selectedCompanies.length !== 0
                                            ? '(' + selectedCompanies.length + ')'
                                            : ''}{' '}
                                        <ExpandMoreIcon style={{ fontSize: '14px' }} />{' '}
                                    </Button>
                                </div>
                                {companySection && (
                                    <div
                                        ref={wrapperRef}
                                        className="region_section"
                                        style={{
                                            width: '300px',
                                            marginTop: '10px',
                                            marginLeft: '-80px',
                                            background: 'white',
                                            position: 'absolute',
                                            padding: '20px',
                                            height: '300px',
                                            zIndex: 1,
                                            borderRadius: '4px',
                                            boxShadow: '0px 0px 2px 2px lightgray',
                                        }}
                                    >
                                        <Input
                                            type="search"
                                            value={companyInput}
                                            onChange={e => {
                                                companySearch(e)
                                            }}
                                        />
                                        <div style={{ height: '200px', overflow: 'auto' }}>
                                            {/* {props.allCompanies.length === 0 && (
                                                    <Circles type="ThreeDots" color="#adadad" height={40} width={40} />
                                                )} */}
                                            <div className="d-flex" style={{ marginTop: '10px' }}>
                                                <input
                                                    type="checkbox"
                                                    id="selectAll"
                                                    checked={checkedCompanies}
                                                    onChange={() => {
                                                        handleSelectAllCompany()
                                                    }}
                                                />
                                                <label style={{ marginLeft: '10px' }}>Select All</label>
                                            </div>
                                            {defaultData
                                                // .filter(item => {
                                                //     if (
                                                //         showType.topics.length === 0 &&
                                                //         showType.industries.length === 0 &&
                                                //         showType.sectors.length === 0 &&
                                                //         companyInput.length === 0
                                                //     ) {
                                                //         return true
                                                //     } else {
                                                //         const isMatchingTopics = item.topics.some(topic =>
                                                //             showType.topics.includes(topic)
                                                //         )
                                                //         const isMatchingIndustries = item.industries.some(industry =>
                                                //             showType.industries.includes(industry)
                                                //         )
                                                //         const isMatchingSectors = item.sectors.some(sector =>
                                                //             showType.sectors.includes(sector)
                                                //         )
                                                //         const isMatchingLabel = item.label
                                                //             .toLowerCase()
                                                //             .includes(companyInput.toLowerCase())
                                                //         if (companyInput.length === 0) {
                                                //             return isMatchingTopics || isMatchingIndustries || isMatchingSectors
                                                //         } else {
                                                //             if (
                                                //                 showType.industries.length === 0 &&
                                                //                 showType.topics.length === 0 &&
                                                //                 showType.sectors.length === 0
                                                //             ) {
                                                //                 return isMatchingLabel
                                                //             } else {
                                                //                 return (
                                                //                     (isMatchingTopics ||
                                                //                         isMatchingIndustries ||
                                                //                         isMatchingSectors) &&
                                                //                     isMatchingLabel
                                                //                 )
                                                //             }
                                                //         }
                                                //     }
                                                // })
                                                .map(val => {
                                                    return (
                                                        <div
                                                            className="d-flex"
                                                            style={{ marginTop: '10px' }}
                                                            key={val.label}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                value={val.label}
                                                                id={'company' + val.value}
                                                                checked={selectedCompanies.includes(val.label)}
                                                                onChange={() => changeCheck('company', val.label)}
                                                            />
                                                            <label style={{ marginLeft: '10px' }}>{val.label}</label>
                                                        </div>
                                                    )
                                                })}
                                        </div>
                                        <div className="d-flex">
                                            <Button
                                                onClick={() => {
                                                    setCompanySection(!companySection)
                                                }}
                                                style={{
                                                    backgroundColor: '#fff0',
                                                    color: '#1c2b3e',
                                                    borderRadius: '25px',
                                                    marginRight: '10px',
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    setCompanySection(!companySection)
                                                    generateQuery(selectedOption)
                                                }}
                                                style={{
                                                    backgroundColor: '#197bbd',
                                                    color: 'white',
                                                    borderRadius: '25px',
                                                    marginLeft: '10px',
                                                }}
                                                disabled={selectedCompanies.length > 0 ? false : true}
                                            >
                                                Show Results
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div id="practice_drop">
                                <div className="d-flex">
                                    <Button
                                        style={{
                                            background: btnColour ? '#3B7DBA' : '',
                                            color: btnColour ? 'white' : '',
                                            borderRadius: '25px',
                                            fontSize: '10px',
                                            // width: '190px',
                                            width: '100%',
                                        }}
                                        onClick={() => {
                                            setPracticeSection(!practiceSection)
                                        }}
                                        className="d-flex justify-content-center align-content-center"
                                    >
                                        Business Events
                                        {selectedCategories.length !== 0
                                            ? '(' +
                                              Number(
                                                  selectedCategories.length -
                                                      props.allCategories.filter(cat =>
                                                          selectedCategories.includes(cat.name)
                                                      ).length
                                              ) +
                                              ')'
                                            : ''}{' '}
                                        <ExpandMoreIcon style={{ fontSize: '14px' }} />{' '}
                                    </Button>
                                </div>
                                {practiceSection && (
                                    <div
                                        ref={wrapperRef}
                                        className="region_section"
                                        style={{
                                            width: '300px',
                                            marginTop: '10px',
                                            marginLeft: '-80px',
                                            background: 'white',
                                            position: 'absolute',
                                            padding: '20px',
                                            height: '300px',
                                            zIndex: 1,
                                            borderRadius: '4px',
                                            boxShadow: '0px 0px 2px 2px lightgray',
                                        }}
                                    >
                                        <Input
                                            type="search"
                                            value={practiceInput}
                                            onChange={e => {
                                                setPracticeInput(e.target.value)
                                            }}
                                        />
                                        <div style={{ height: '200px', overflow: 'auto' }}>
                                            <div className="d-flex" style={{ marginTop: '10px' }}>
                                                <input
                                                    type="checkbox"
                                                    id="selectAll"
                                                    checked={checkedPractices}
                                                    onChange={() => {
                                                        handleSelectAllPractice()
                                                    }}
                                                />
                                                <label style={{ marginLeft: '10px' }}>Select All</label>
                                            </div>
                                            {practiceSubCategory
                                                .filter(function(eachCat) {
                                                    if (
                                                        eachCat.subcategories.filter(subCat =>
                                                            subCat.name
                                                                .toLowerCase()
                                                                .includes(practiceInput.toLowerCase())
                                                        ).length !== 0
                                                    ) {
                                                        if (practiceInput.length > 2) {
                                                            subCategoryOpen.push(eachCat.id)
                                                        }
                                                        if (practiceInput.length === 1) {
                                                            subCategoryOpen.splice(0, subCategoryOpen.length)
                                                        }
                                                        return true
                                                    }
                                                    if (
                                                        eachCat.name
                                                            .toLowerCase()
                                                            .includes(practiceInput.toLocaleLowerCase())
                                                    ) {
                                                        return true
                                                    }
                                                })
                                                .map(val => {
                                                    return (
                                                        <>
                                                            <div
                                                                className="d-flex"
                                                                style={{ marginTop: '10px' }}
                                                                key={val.label}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    id={'topic' + val.name}
                                                                    checked={selectedCategories.includes(val.id)}
                                                                    onChange={() => {
                                                                        bulkChangesub(
                                                                            'category',
                                                                            [val, ...val.subcategories],
                                                                            val.id
                                                                        )
                                                                    }}
                                                                />
                                                                <label style={{ marginLeft: '10px' }}>{val.name}</label>
                                                                <span
                                                                    key={val.id}
                                                                    style={{
                                                                        marginLeft: 'auto',
                                                                        cursor: 'pointer',
                                                                        marginRight: '10px',
                                                                        display:
                                                                            val.subcategories.length === 0
                                                                                ? 'none'
                                                                                : 'block',
                                                                    }}
                                                                >
                                                                    {val.subcategories && val.subcategories.length > 0 && (
                                                                        <span
                                                                            onClick={() => {
                                                                                toggleid(val.id, setSubCategoryOpen)
                                                                            }}
                                                                        >
                                                                            {subCategoryOpen.includes(val.id) ? (
                                                                                <BiMinus className="minus" />
                                                                            ) : (
                                                                                <BiPlus className="plus" />
                                                                            )}
                                                                        </span>
                                                                    )}
                                                                </span>
                                                            </div>
                                                            {subCategoryOpen.includes(val.id) &&
                                                                val.subcategories &&
                                                                val.subcategories
                                                                    .filter(each =>
                                                                        each.name
                                                                            .toLowerCase()
                                                                            .includes(practiceInput.toLocaleLowerCase())
                                                                    )
                                                                    .map(sub => (
                                                                        <div
                                                                            style={{
                                                                                marginLeft: '25px',
                                                                                marginTop: '10px',
                                                                            }}
                                                                            className="d-flex"
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={selectedCategories.includes(
                                                                                    sub.id
                                                                                )}
                                                                                onChange={() =>
                                                                                    changeCheck('category', sub.id)
                                                                                }
                                                                            />
                                                                            <label style={{ marginLeft: '10px' }}>
                                                                                {sub.name}
                                                                            </label>
                                                                        </div>
                                                                    ))}
                                                        </>
                                                    )
                                                })}
                                        </div>
                                        <div className="d-flex">
                                            <Button
                                                onClick={() => {
                                                    setPracticeSection(!practiceSection)
                                                }}
                                                style={{
                                                    backgroundColor: '#fff0',
                                                    color: '#1c2b3e',
                                                    borderRadius: '25px',
                                                    marginRight: '10px',
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    setPracticeSection(!practiceSection)
                                                    generateQuery(selectedOption)
                                                }}
                                                style={{
                                                    backgroundColor: '#197bbd',
                                                    color: 'white',
                                                    borderRadius: '25px',
                                                    marginLeft: '10px',
                                                }}
                                                disabled={selectedCategories.length > 0 ? false : true}
                                            >
                                                Show Results
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    )}

                    <div id="source_drop">
                        <div className="d-flex">
                            <Button
                                style={{
                                    background: btnColour ? '#3B7DBA' : '',
                                    color: btnColour ? 'white' : '',
                                    borderRadius: '25px',
                                    fontSize: '10px',
                                    width: '100%',
                                    // width: '180px',
                                }}
                                onClick={() => {
                                    setSourceSection(!sourceSection)
                                }}
                                className="d-flex justify-content-center align-content-center"
                            >
                                Source{selectedSources.length !== 0 ? '(' + selectedSources.length + ')' : ''}{' '}
                                <ExpandMoreIcon style={{ fontSize: '14px' }} />{' '}
                            </Button>
                        </div>
                        {sourceSection && (
                            <div
                                ref={wrapperRef}
                                className="source_section"
                                style={{
                                    width: '300px',
                                    marginTop: '10px',
                                    marginLeft: '-120px',
                                    background: 'white',
                                    position: 'absolute',
                                    padding: '20px',
                                    height: '300px',
                                    zIndex: 1,
                                    borderRadius: '4px',
                                    boxShadow: '0px 0px 2px 2px lightgray',
                                }}
                            >
                                <Input
                                    type="search"
                                    value={sourceInput}
                                    onChange={e => {
                                        setSourceInput(e.target.value)
                                    }}
                                />
                                <div style={{ height: '200px', overflow: 'auto' }}>
                                    <div className="d-flex" style={{ marginTop: '10px' }}>
                                        <input
                                            type="checkbox"
                                            id="selectAll"
                                            checked={checkedSources}
                                            onChange={() => {
                                                handleSelectAllSources()
                                            }}
                                        />
                                        <label style={{ marginLeft: '10px' }}>Select All</label>
                                    </div>
                                    {props.allSources
                                        .filter(val => val.label.toUpperCase().includes(sourceInput.toUpperCase()))
                                        .map(val => {
                                            return (
                                                <div className="d-flex" style={{ marginTop: '10px' }}>
                                                    <input
                                                        type="checkbox"
                                                        value={val.value}
                                                        id={'topic' + val.value}
                                                        checked={selectedSources.includes(val.value)}
                                                        onChange={() => changeCheck('source', val.value)}
                                                    />
                                                    <label style={{ marginLeft: '10px' }}>{val.label}</label>
                                                </div>
                                            )
                                        })}
                                </div>
                                <div className="d-flex">
                                    <Button
                                        onClick={() => {
                                            setSourceSection(!sourceSection)
                                        }}
                                        style={{
                                            backgroundColor: '#fff0',
                                            color: '#1c2b3e',
                                            borderRadius: '25px',
                                            marginRight: '10px',
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setSourceSection(!sourceSection)
                                            generateQuery(selectedOption)
                                        }}
                                        style={{
                                            backgroundColor: '#197bbd',
                                            color: 'white',
                                            borderRadius: '25px',
                                            marginLeft: '10px',
                                        }}
                                        disabled={selectedSources.length > 0 ? false : true}
                                    >
                                        Show Results
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* <div id="action_drop" style={{}}>
                        <div className="d-flex">
                            <Button
                                style={{
                                    background: btnColour ? '#3B7DBA' : '',
                                    color: btnColour ? 'white' : '',
                                    borderRadius: '25px',
                                    fontSize: '10px',
                                    width: '100%',
                                }}
                                onClick={() => {
                                    setActionSection(!actionSection)
                                }}
                                className="d-flex justify-content-center align-content-center"
                            >
                                Action <ExpandMoreIcon style={{ fontSize: '12px',  }} />{' '}
                            </Button>
                        </div>
                        {actionSection && (
                            <div
                                ref={wrapperRef}
                                className="source_section"
                                style={{
                                    width: '200px',
                                    marginTop: '10px',
                                    marginLeft: '-40px',
                                    background: 'white',
                                    position: 'absolute',
                                    padding: '20px',
                                    borderRadius: '4px',
                                    border: '1px solid gray',
                                    zIndex: 1,
                                }}
                            >
                                <input
                                    style={{ border: 'none', outline: 'none' }}
                                    value={'Leadership'}
                                    disabled
                                    readOnly
                                />
                                <input
                                    style={{ border: 'none', outline: 'none' }}
                                    value={'Client outreach'}
                                    disabled
                                    readOnly
                                />{' '}
                                <input
                                    style={{ border: 'none', outline: 'none' }}
                                    value={'Social Media'}
                                    disabled
                                    readOnly
                                />
                            </div>
                        )}
                    </div> */}
                </div>
            </div>
        </>
    )
}
