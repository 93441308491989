import React, { useEffect } from 'react'
import { Grid, Card } from '@mui/material'
import './MailModal.css'
import { getFullFormattedDate } from '../../../utils/dateHelpers'

export default function NewsBody(props) {
    const { selectedStories, primaryColor = null, secondaryColor = null, newsBodySize = null, isBlakes = null } = props
    const { includeSection, includeBusiness } = props

    const sectionBody = data => {
        return (
            <>
                {data
                    .sort((a, b) => (a.date.split('T')[0] > b.date.split('T')[0] ? -1 : 1))
                    .map(story => (
                        <tr key={story.id}>
                            <td>
                                <table
                                    style={{
                                        borderBottom: '2px solid black',
                                        width: newsBodySize === false ? '' : '690px',
                                    }}
                                >
                                    <tr>
                                        <td style={{ padding: '1rem', width: '40%' }}>
                                            <p>
                                                <a
                                                    href={story.source}
                                                    style={{
                                                        fontWeight: 'bold',
                                                        fontSize: isBlakes ? '10pt' : '15px',
                                                        fontFamily: isBlakes ? 'Georgia' : 'Arial',
                                                        lineHeight: '1.3',
                                                    }}
                                                    target="_blank"
                                                >
                                                    {story.title}
                                                </a>
                                            </p>

                                            <p
                                                style={{
                                                    fontFamily: isBlakes ? 'Georgia' : 'Arial',
                                                    fontSize: isBlakes ? '10pt' : '',
                                                }}
                                            >
                                                {story.source_name}
                                            </p>
                                            <p
                                                style={{
                                                    fontFamily: isBlakes ? 'Georgia' : '',
                                                    fontSize: isBlakes ? '10pt' : '',
                                                }}
                                            >
                                                {getFullFormattedDate(story.date)}
                                            </p>
                                            <p>
                                                {includeBusiness &&
                                                    story.subcategories &&
                                                    story.subcategories.length > 0 &&
                                                    story.subcategories.map(subcategory => (
                                                        <table
                                                            key={subcategory.id}
                                                            style={{ margin: '2px' }}
                                                            className={subcategory.id}
                                                        >
                                                            <tr
                                                                key={subcategory.id}
                                                                style={{
                                                                    background: secondaryColor
                                                                        ? secondaryColor
                                                                        : 'rgb(179, 41, 61)',
                                                                }}
                                                                className="subcategory-color"
                                                            >
                                                                <td
                                                                    style={{
                                                                        lineHeight: '25px',
                                                                        margin: '2px',
                                                                        fontSize: isBlakes ? '10pt' : '12px',
                                                                        color: 'white',
                                                                        padding: '0.25rem',
                                                                        width: '-webkit-fit-content',
                                                                        fontFamily: isBlakes ? 'Georgia' : 'Arial',
                                                                    }}
                                                                    className="subcategory-color"
                                                                >
                                                                    {subcategory.name}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    ))}
                                            </p>
                                        </td>
                                        <td style={{ width: '60%' }}>
                                            <p
                                                style={{
                                                    fontSize: isBlakes ? '10pt' : '15px',
                                                    lineHeight: '1.2',
                                                    fontFamily: isBlakes ? 'Verdana' : 'Arial',
                                                }}
                                            >
                                                {story.snippet}
                                            </p>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    ))}
            </>
        )
    }

    return (
        <>
            {selectedStories.flatMap(e => e.news).length === 0 && (
                <div style={{ textAlign: 'center', paddingTop: '20px' }}>Choose Sections to Preview News</div>
            )}
            {includeSection
                ? selectedStories.map(section => {
                      if (
                          section &&
                          section.news &&
                          section.news.length !== 0 &&
                          section.title &&
                          section.title.trim().length !== 0
                      ) {
                          return (
                              <table style={{ width: newsBodySize === false ? '' : '690px' }}>
                                  <tbody>
                                      {includeSection && (
                                          <tr style={{ background: primaryColor ? primaryColor : 'rgb(49 42 58)' }}>
                                              <td className="title-color">
                                                  <p
                                                      style={{
                                                          color: 'white',
                                                          fontSize: isBlakes ? '18pt' : '20px',
                                                          paddingLeft: '1rem',
                                                          fontFamily: isBlakes ? 'Georgia' : '',
                                                      }}
                                                  >
                                                      {section.title}
                                                  </p>
                                              </td>
                                          </tr>
                                      )}
                                      {sectionBody(section.news)}
                                  </tbody>
                              </table>
                          )
                      }
                  })
                : sectionBody(selectedStories)}
        </>
    )
}
