export const getMonth3Char = monthNum => {
    if (monthNum === 0) {
        return 'Jan'
    } else if (monthNum === 1) {
        return 'Feb'
    } else if (monthNum === 2) {
        return 'Mar'
    } else if (monthNum === 3) {
        return 'Apr'
    } else if (monthNum === 4) {
        return 'May'
    } else if (monthNum === 5) {
        return 'Jun'
    } else if (monthNum === 6) {
        return 'Jul'
    } else if (monthNum === 7) {
        return 'Aug'
    } else if (monthNum === 8) {
        return 'Sep'
    } else if (monthNum === 9) {
        return 'Oct'
    } else if (monthNum === 10) {
        return 'Nov'
    } else if (monthNum === 11) {
        return 'Dec'
    } else if (monthNum === 12) {
        return 'Jan'
    } else if (monthNum === 13) {
        return 'Feb'
    }
}

const getMonthFull = monthNum => {
    if (monthNum === 0) {
        return 'January'
    } else if (monthNum === 1) {
        return 'February'
    } else if (monthNum === 2) {
        return 'March'
    } else if (monthNum === 3) {
        return 'April'
    } else if (monthNum === 4) {
        return 'May'
    } else if (monthNum === 5) {
        return 'June'
    } else if (monthNum === 6) {
        return 'July'
    } else if (monthNum === 7) {
        return 'August'
    } else if (monthNum === 8) {
        return 'September'
    } else if (monthNum === 9) {
        return 'October'
    } else if (monthNum === 10) {
        return 'November'
    } else if (monthNum === 11) {
        return 'December'
    }
}

const getMonthNumFull = monthNum => {
    if (monthNum === 0) {
        return '01'
    } else if (monthNum === 1) {
        return '02'
    } else if (monthNum === 2) {
        return '03'
    } else if (monthNum === 3) {
        return '04'
    } else if (monthNum === 4) {
        return '05'
    } else if (monthNum === 5) {
        return '06'
    } else if (monthNum === 6) {
        return '07'
    } else if (monthNum === 7) {
        return '08'
    } else if (monthNum === 8) {
        return '09'
    } else if (monthNum === 9) {
        return '10'
    } else if (monthNum === 10) {
        return '11'
    } else if (monthNum === 11) {
        return '12'
    }
}

export const getDay3Char = day => {
    if (day === 0) {
        return 'Sun'
    } else if (day === 1) {
        return 'Mon'
    } else if (day === 2) {
        return 'Tue'
    } else if (day === 3) {
        return 'Wed'
    } else if (day === 4) {
        return 'Thu'
    } else if (day === 5) {
        return 'Fri'
    } else if (day === 6) {
        return 'Sat'
    }
}

export const getFullFormattedDate = dateInput => {
    let flag = true
    let date = new Date()
    if (typeof dateInput === 'string') {
        flag = false
        date = dateInput.includes('T') ? dateInput.split('T')[0].split('-') : dateInput.split('-')
    }
    const fullMonth = flag ? getMonthFull(date.getMonth()) : getMonthFull(parseInt(date[1]) - 1)
    const fullDate = flag ? date.getDate() : parseInt(date[2])
    const fullYear = flag ? date.getFullYear() : parseInt(date[0])
    return fullMonth.substring(0, 3) + ' ' + fullDate + '/' + fullYear
}

export const getShortDate = dateInput => {
    const dateObj = new Date(dateInput)
    const shortMonth = getMonth3Char(dateObj.getMonth())
    const fullDate = dateObj.getDate()
    const fullYear = dateObj.getFullYear()

    return shortMonth + ' ' + fullDate + '/' + fullYear.toString().substr(-2)
}
export const getSmallDate = dateInput => {
    const dateObj = new Date(dateInput)
    const shortMonth = getMonth3Char(dateObj.getMonth())
    const fullYear = dateObj.getFullYear()
    return shortMonth + ' ' + fullYear
}

export const getYMD = dateInput => {
    const dateObj = new Date(dateInput)
    const shortMonth = getMonthNumFull(dateObj.getMonth())
    const fullDate = dateObj.getDate()
    const fullYear = dateObj.getFullYear()

    return fullYear + '-' + shortMonth + '-' + (fullDate < 10 ? '0' + fullDate : fullDate)
}

export const calculateDaysBetween = (startDate, endDate) => {
    console.log(startDate)
    console.log(endDate)

    const oneDayInMs = 24 * 60 * 60 * 1000 // Milliseconds in a day
    const start = new Date(startDate) // Ensure startDate is a Date object
    const end = new Date(endDate) // Ensure endDate is a Date object

    const diffInMs = end - start // Difference in milliseconds
    const diffInDays = Math.round(diffInMs / oneDayInMs) // Convert milliseconds to days

    return diffInDays
}
