import React from 'react'
import Check from '../../assets/icons/check.svg'
import './RegionSelectWidget.css'

export default function RegionSelectWidget(props) {
    const { country, handleRegionChange, isStorySelected, headingStyle, boxStyle } = props
    // 1 = United States, 2 = Canada, 3 = All
    return (
        <div className="regionSelect__container">
            <div className="newsCard20__oppHeading">
                <p style={headingStyle}>Country</p>
            </div>

            <div className="regionSelect__boxes">
                <div
                    className={country === 1 || country === 3 ? 'regionSelect__box-active' : 'regionSelect__box'}
                    style={{ ...boxStyle, marginRight: '12px' }}
                    onClick={isStorySelected ? null : () => handleRegionChange(1)}
                >
                    <div className="regionSelect__check">
                        {(country === 1 || country === 3) && (
                            <img src={process.env.REACT_APP_MEDIA_URL + Check} alt="region check mark" />
                        )}
                    </div>
                    <p>United States</p>
                </div>
                <div
                    className={country === 2 || country === 3 ? 'regionSelect__box-active' : 'regionSelect__box'}
                    style={boxStyle}
                    onClick={isStorySelected ? null : () => handleRegionChange(2)}
                >
                    <div className="regionSelect__check">
                        {(country === 2 || country === 3) && (
                            <img src={process.env.REACT_APP_MEDIA_URL + Check} alt="region check mark" />
                        )}
                    </div>
                    <p>Canada</p>
                </div>
            </div>
        </div>
    )
}
