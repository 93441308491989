import axios from 'axios'
import { toast } from 'react-toastify'
import store from '../Store'

const landingPageMappings = store.getState().auth.landingPageMappings
export const redirectUser = (user, history, next = null) => {
    switch (user.user_type) {
        case 'analyst':
            // axios.get(`/accounts/analyst/${user.id}/groups/`).then(response => {
            history.push(`/app/analyst-dashboard/`)
            // })
            break

        case 'client_analyst':
            // history.push('/app/firm-analyst/')
            history.push(`/app/analyst-dashboard/`)
            break

        case 'Power User':
            // if (next) {
            //     history.push(next.replace('?next=', ''))
            // } else {
            //     history.push('/app/assistant/')
            // }
            if (next && typeof next === 'string') {
                const firm_allowed_pages = user.firm_allowed_pages
                const landingPage = firm_allowed_pages ? firm_allowed_pages.includes(user.firm_landing_pages) : null

                if (firm_allowed_pages && landingPage) {
                    if (firm_allowed_pages.includes('Insights Assistant')) {
                        history.push(next.replace('?next=', ''))
                    } else {
                        history.push(`/app/preferences/`)
                    }
                } else {
                    history.push(`/app/preferences/`)
                }
            } else if (user.firm_landing_pages) {
                // if (landingPageMappings[user.firm_landing_pages].includes('/app/assistant/')) {
                //     history.push(`/app/assistant/`)
                // } else if (landingPageMappings[user.firm_landing_pages].includes('/app/preferences/')) {
                //     history.push(`/app/preferences/`)
                // } else if (landingPageMappings[user.firm_landing_pages].includes('/app/search/')) {
                //     history.push(`/app/search/`)
                // } else if (landingPageMappings[user.firm_landing_pages].includes('/app/company/')) {
                //     history.push(`/app/companies/`)
                // } else if (landingPageMappings[user.firm_landing_pages].includes('/app/newsletters/')) {
                //     history.push(`/app/newsletters/`)
                // } else {
                //     history.push(`/app/request/`)
                // }

                const firm_allowed_pages = user.firm_allowed_pages
                const landingPage = firm_allowed_pages ? firm_allowed_pages.includes(user.firm_landing_pages) : null

                if (firm_allowed_pages && landingPage) {
                    if (user.firm_landing_pages === 'Insights Assistant') {
                        history.push(`/app/assistant/`)
                    } else if (user.firm_landing_pages === 'Find and Share') {
                        history.push(`/app/search/`)
                    } else if (user.firm_landing_pages === 'My Companies') {
                        history.push(`/app/companies/`)
                    } else if (user.firm_landing_pages === 'Newsletters') {
                        history.push(`/app/newsletters/`)
                    } else {
                        history.push(`/app/preferences/`)
                    }
                } else {
                    history.push(`/app/preferences/`)
                }
            } else {
                history.push('/')
            }

            break

        // case 'Regular User':
        //     // if (user.subscription_type === 'Direct') {
        //     //     history.push(`/app/direct-dashboard/`)
        //     // }
        //     //  else insights,request,preference
        //     if (user.subscription_type) {
        //         if (next) {
        //             history.push(next.replace('?next=', ''))
        //         } else if (user.landing_page && landingPageMappings[user.landing_page]) {
        //             if (user.login_count === 1) {
        //                 history.push(`/app/preferences/`)
        //             } else {
        //                 if (landingPageMappings[user.landing_page].includes('/app/assistant/')) {
        //                     history.push(`/app/assistant/`)
        //                 } else if (
        //                     !landingPageMappings[user.landing_page].includes('/app/topic/') &&
        //                     !landingPageMappings[user.landing_page].includes('/app/company/')
        //                 ) {
        //                     history.push(landingPageMappings[user.landing_page])
        //                 } else if (landingPageMappings[user.landing_page].includes('/app/company/')) {
        //                     history.push(`/app/companies/`)
        //                 } else if (landingPageMappings[user.landing_page].includes('/app/topic/')) {
        //                     history.push(`/app/sectors/`)
        //                 }
        //             }
        //         } else {
        //             history.push('/')
        //         }
        //     } else {
        //         history.push('/')
        //     }
        //     break

        case 'Regular User':
            if (user.subscription_type) {
                if (next && typeof next === 'string') {
                    const firm_allowed_pages = user.firm_allowed_pages
                    const landingPage = firm_allowed_pages ? firm_allowed_pages.includes(user.firm_landing_pages) : null

                    if (firm_allowed_pages && landingPage) {
                        if (firm_allowed_pages.includes('Insights Assistant')) {
                            history.push(next.replace('?next=', ''))
                        } else {
                            history.push(`/app/preferences/`)
                        }
                    } else {
                        history.push(`/app/preferences/`)
                    }
                } else if (user.firm_landing_pages) {
                    const firm_allowed_pages = user.firm_allowed_pages
                    const landingPage = firm_allowed_pages ? firm_allowed_pages.includes(user.firm_landing_pages) : null
                    // if (firm_allowed_pages[user.firm_landing_pages] === 'Insights Assistant') {
                    //     history.push(`/app/assistant/`)
                    // } else if (firm_allowed_pages[user.firm_landing_pages] === 'My Companies') {
                    //     history.push(`/app/companies/`)
                    // } else {
                    //     history.push(`/app/preferences/`)
                    // }
                    if (firm_allowed_pages && landingPage) {
                        if (user.firm_landing_pages === 'Insights Assistant') {
                            history.push(`/app/assistant/`)
                        } else if (user.firm_landing_pages === 'My Companies') {
                            history.push(`/app/companies/`)
                        } else {
                            history.push(`/app/preferences/`)
                        }
                    } else {
                        history.push(`/app/preferences/`)
                    }
                } else {
                    history.push('/')
                }
            } else {
                history.push('/')
            }
            break

        case 'user':
            history.push(`/`)
            toast.warn('User type error')
            break
        default:
            return history.push('/')
    }
}
