import axios from 'axios'
import { addDays, format, subDays } from 'date-fns'
import React, { useEffect, useState } from 'react'
import BaseScreen from '../BaseScreen/BaseScreen'
import AdminHeader from './components/AdminHeader'
import { NavLink } from 'react-router-dom'

import { Grid, Button, Paper } from '@mui/material'
import { Modal, ModalBody } from 'reactstrap'
import RenderTemplate from '../NewsFeedScreen/components/RenderTemplate'
import './AnalystAdmin.css'

export default function AnalystAdminTemplates() {
    const [newss, setNewss] = useState([])
    const [allTemplates, setAllTemplates] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState({})
    const [openTemplateModal, setOpenTemplateModal] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [htmlContent, setHtmlContent] = useState()
    useEffect(() => {
        axios
            .get('/endusers/templates/')
            .then(response => {
                setAllTemplates(response.data)
            })
            .catch(err => {
                console.log(err)
            })
        axios
            .get(
                `
            /news/get-published-news/?newsStartDate=${format(subDays(new Date(), 365), 't')}&newsEndDate=${format(
                    addDays(new Date(), 1),
                    't'
                )}&newsPulledStartDate&newsPulledEndDate&newsPublishedStartDate=${format(subDays(new Date(), 365), 't')}
            &newsPublishedEndDate=${format(addDays(new Date(), 1), 't')}
            &industries&sectors&companies&subcategories&countries&sent=all&linkedin_groups&pullType&featured&underReview&topics&analysts`
            )
            .then(response => {
                setNewss(response.data.results.data.slice(1, 4))
            })
            .catch(err => console.log(err))
    }, [])

    useEffect(() => {
        if (selectedTemplate.custom_template)
            axios.get(`/news/custom-templates/${selectedTemplate.id}`).then(res => {
                setHtmlContent(res.data)
            })
    }, [selectedTemplate])

    const handleTemplateSelected = (event, template) => {
        setSelectedTemplate(template)
        setOpenTemplateModal(true)
    }

    const news = [{ news: newss, title: 'Test' }]

    const setSize = () => {
        return 140, 150
    }

    return (
        <BaseScreen>
            <AdminHeader currentPage={'templates'} />
            <Grid container direction="column" style={{ paddingRight: '1rem', marginTop: '50px' }}>
                <Grid item>
                    <Paper style={{ padding: '2rem', minHeight: '75vh' }}>
                        <h1 style={{ textAlign: 'center' }}>TEMPLATES</h1>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ marginLeft: '1%', display: 'flex', justifyContent: 'space-between' }}>
                                <div className="input-icons">
                                    <i className="fa fa-search icon" />
                                    <input
                                        type="search"
                                        name="search"
                                        className="input-field"
                                        value={searchValue}
                                        onChange={e => setSearchValue(e.target.value)}
                                        autoComplete="off"
                                        placeholder="Filter by Template"
                                    />
                                </div>
                            </div>

                            <div>
                                <NavLink to="/app/admin/create-template/" className="adminEndUser__newUserButton">
                                    <span>Create New Template+</span>
                                </NavLink>
                            </div>
                        </div>
                        <div className="all_templates" style={{ margin: '10px' }}>
                            {allTemplates
                                .filter(template => template.name.toLowerCase().includes(searchValue.toLowerCase()))
                                .map(template => {
                                    return (
                                        <Button
                                            aria-label="list"
                                            style={{ width: '50%' }}
                                            onClick={event => handleTemplateSelected(event, template)}
                                        >
                                            <Grid container direction="row" spacing={1}>
                                                <Grid
                                                    item
                                                    xs={4}
                                                    style={{
                                                        height: '4rem',
                                                        maxHeight: '20rem',
                                                        maxWidth: '20rem',
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    <img
                                                        src={template.thumbnail}
                                                        style={{ maxWidth: '100%' }}
                                                        alt="thumbnail"
                                                    />
                                                </Grid>
                                                {/* <Grid item style={{ height: '50px' }}> */}
                                                <Grid container direction="column" style={{ marginLeft: '5px' }}>
                                                    <Grid item>
                                                        <h5 align="left" style={{ color: '#1c2b3e' }}>
                                                            {template.name}
                                                        </h5>
                                                    </Grid>
                                                    <Grid item>
                                                        <p align="left" style={{ fontSize: '10px', color: 'black' }}>
                                                            {template.description}
                                                        </p>
                                                    </Grid>
                                                </Grid>
                                                {/* </Grid> */}
                                            </Grid>
                                        </Button>
                                    )
                                })}
                        </div>
                    </Paper>
                </Grid>
            </Grid>

            <Modal
                isOpen={openTemplateModal}
                toggle={() => {
                    setOpenTemplateModal(!openTemplateModal)
                }}
                size="lg"
                style={{ width: '700px' }}
            >
                <ModalBody>
                    {selectedTemplate.custom_template === true ? (
                        <>
                            <div style={{ marginBottom: '25px' }}>
                                <NavLink
                                    style={{ float: 'right' }}
                                    to={{
                                        pathname: '/app/admin/create-template/',
                                        state: { selectedTemplate: selectedTemplate },
                                    }}
                                >
                                    Edit Template{' '}
                                </NavLink>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                        </>
                    ) : (
                        <RenderTemplate
                            setSize={setSize}
                            justPreview={true}
                            selectedStories={selectedTemplate.has_sections ? news : newss}
                            title={selectedTemplate.title}
                            selectedLogo={selectedTemplate.logo}
                            selectedTemplate={selectedTemplate}
                            introText={selectedTemplate.introtext}
                            primaryColor={selectedTemplate.primary_color}
                            secondaryLogo={selectedTemplate.secondary_logo}
                            secondaryColor={selectedTemplate.secondary_color}
                            selectedBg={selectedTemplate.has_background ? selectedTemplate.background_image : null}
                            newsBodySize={false}
                        />
                    )}
                </ModalBody>
            </Modal>
        </BaseScreen>
    )
}
