import React, { useRef, useState } from 'react'
import { BiChevronUp, BiChevronDown } from 'react-icons/bi'
import { Chip, Grid } from '@mui/material'
import { Tooltip } from 'reactstrap'
import InfoIcon from '@mui/icons-material/Info'

export default function PreferenceSectorContainer(props) {
    const wrapperRef = useRef(null)
    const [topicSection, setTopicSection] = useState(false)
    const [topicInput, setTopicInput] = useState('')
    closeExpandable(wrapperRef)

    function closeExpandable(ref) {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setTopicSection(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }
    const {
        sectorInput,
        setsectorInput,
        selectedinput,
        sectorOpen,
        setSectorOpen,
        topicOpen,
        setTopicOpen,
        toggleid,
        selectedSectors,
        selectedData,
        setSelectedData,
        bulkChange,
        handleRemove,
        handleSelectAllTopics,
        allHierarchy,
        checkedTopics,
        setSelectedInput,
        changeCheck,
        tooltip,
        toggle,
    } = props
    return (
        <div className="" style={{ width: '90%' }}>
            <label className="fw-bold d-flex align-items-center" style={{ fontSize: '16px' }}>
                <span>Select topics</span>
                {(window.location.pathname === '/app/preferences/' ||
                    window.location.pathname.includes('/app/admin/enduser/') ||
                    window.location.pathname.includes('app/admin/add-enduser')) && (
                    <span style={{ marginLeft: '10px' }}>
                        <InfoIcon
                            id="user_topics"
                            style={{
                                cursor: 'pointer',
                                display: 'inline-block',
                                color: 'lightgray',
                            }}
                        />
                        <Tooltip
                            placement="top-end"
                            isOpen={tooltip}
                            target="user_topics"
                            toggle={() => toggle('user_topics')}
                            className="custom-tooltip"
                        >
                            Start typing the name of topics of interest. Select the topics (or consider selecting a
                            similar topic) from dropdown or enter the full name of topic.
                        </Tooltip>
                    </span>
                )}
            </label>
            <div className="">
                <div className="mt-2">
                    <div id="sector_drop">
                        <div>
                            <input
                                type="search"
                                style={{
                                    height: '40px',
                                    border: '1.7px solid rgb(218, 218, 218)',
                                    outline: 'none',
                                    borderRadius: '4px',
                                    background: 'white',
                                }}
                                placeholder="Search Topics"
                                value={topicInput}
                                className="form-control"
                                onChange={e => {
                                    setTopicInput(e.target.value)
                                }}
                                onClick={() => {
                                    setTopicSection(true)
                                }}
                                id="topic_search_input"
                                autoComplete="off"
                            />
                        </div>
                        {topicSection && (
                            <div
                                ref={wrapperRef}
                                className="mx-auto"
                                style={{
                                    width: document.getElementById('topic_search_input').offsetWidth + 'px',
                                    marginTop: '5px',
                                    background: 'white',
                                    padding: '20px',
                                    paddingTop: '10px',
                                    height: '300px',
                                    zIndex: 2,
                                    borderRadius: '4px',
                                    boxShadow: '0px 0px 2px 2px lightgray',
                                    position: 'absolute',
                                }}
                            >
                                <div style={{ height: '270px', overflow: 'auto' }}>
                                    {/* <div className="d-flex" style={{ marginTop: '10px' }}>
                                        <input
                                            type="checkbox"
                                            id="selectAll"
                                            checked={checkedTopics}
                                            onChange={() => {
                                                handleSelectAllTopics()
                                            }}
                                        />
                                        <label style={{ marginLeft: '10px' }}>Select All</label>
                                    </div> */}
                                    {allHierarchy
                                        .reduce((result, ind) => {
                                            const filteredSectors = ind.sectors.filter(sec =>
                                                sec.topics.some(top =>
                                                    top.name.toLowerCase().startsWith(topicInput.toLowerCase())
                                                )
                                            )

                                            if (
                                                filteredSectors.length > 0 ||
                                                ind.name.toLowerCase().startsWith(topicInput.toLowerCase()) ||
                                                ind.sectors.find(sec =>
                                                    sec.name.toLowerCase().startsWith(topicInput.toLowerCase())
                                                )
                                            ) {
                                                if (topicInput.length > 2) {
                                                    sectorOpen.push(ind.id)
                                                    filteredSectors.forEach(sec => {
                                                        topicOpen.push(sec.id)
                                                    })
                                                }
                                                if (topicInput.length === 1) {
                                                    topicOpen.splice(0, topicOpen.length)
                                                    sectorOpen.splice(0, sectorOpen.length)
                                                }
                                                result.push({
                                                    ...ind,
                                                    sectors: ind.sectors
                                                        .filter(
                                                            sector =>
                                                                sector.name
                                                                    .toLowerCase()
                                                                    .startsWith(topicInput.toLowerCase()) ||
                                                                sector.topics.some(topic =>
                                                                    topic.name
                                                                        .toLowerCase()
                                                                        .startsWith(topicInput.toLowerCase())
                                                                )
                                                        )
                                                        .map(filteredSector => ({
                                                            ...filteredSector,
                                                            topics: filteredSector.topics.filter(topic =>
                                                                topic.name
                                                                    .toLowerCase()
                                                                    .startsWith(topicInput.toLowerCase())
                                                            ),
                                                        })),
                                                })
                                            }
                                            return result
                                        }, [])
                                        .map(val => {
                                            return (
                                                <>
                                                    <div
                                                        className="d-flex "
                                                        style={{ marginTop: '10px' }}
                                                        key={val.name}
                                                    >
                                                        <div className="">
                                                            <input
                                                                type="checkbox"
                                                                value={val.name}
                                                                id={'sector' + val.name}
                                                                checked={selectedSectors.includes(val.name)}
                                                                onChange={() => {
                                                                    bulkChange(
                                                                        'sector',
                                                                        [val]
                                                                            .concat(...val.sectors)
                                                                            .concat(
                                                                                ...val.sectors.map(sec =>
                                                                                    sec.topics.map(top => top)
                                                                                )
                                                                            ),
                                                                        val.name
                                                                    )
                                                                }}
                                                            />

                                                            <label
                                                                style={{ marginLeft: '10px' }}
                                                                onClick={() => {
                                                                    val.sectors.length !== 0 &&
                                                                        toggleid(val.id, setSectorOpen)
                                                                }}
                                                            >
                                                                {val.name}
                                                            </label>
                                                        </div>
                                                        <div
                                                            className="flex-fill d-flex justify-content-end"
                                                            onClick={() => {
                                                                val.sectors.length !== 0 &&
                                                                    toggleid(val.id, setSectorOpen)
                                                            }}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <span
                                                                key={val.id}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    marginRight: '10px',
                                                                    display:
                                                                        val.sectors.length === 0 ? 'none' : 'block',
                                                                }}
                                                            >
                                                                {val.sectors && val.sectors.length > 0 && (
                                                                    <span>
                                                                        {sectorOpen.includes(val.id) ? (
                                                                            <BiChevronUp />
                                                                        ) : (
                                                                            <BiChevronDown />
                                                                        )}
                                                                    </span>
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {sectorOpen.includes(val.id) &&
                                                        val.sectors &&
                                                        val.sectors.map(sec => (
                                                            <>
                                                                <div
                                                                    className="d-flex"
                                                                    style={{
                                                                        marginTop: '10px',
                                                                        marginLeft: '15px',
                                                                    }}
                                                                    key={sec.name}
                                                                >
                                                                    <div>
                                                                        <input
                                                                            type="checkbox"
                                                                            value={sec.name}
                                                                            id={'sector' + sec.name}
                                                                            checked={selectedSectors.includes(sec.name)}
                                                                            onChange={() => {
                                                                                bulkChange(
                                                                                    'sector',
                                                                                    [sec, ...sec.topics],
                                                                                    sec.name
                                                                                )
                                                                            }}
                                                                        />

                                                                        <label
                                                                            style={{ marginLeft: '10px' }}
                                                                            onClick={() => {
                                                                                sec.topics.length !== 0 &&
                                                                                    toggleid(sec.id, setTopicOpen)
                                                                            }}
                                                                        >
                                                                            {sec.name}
                                                                        </label>
                                                                    </div>
                                                                    <div
                                                                        className="flex-fill d-flex justify-content-end"
                                                                        onClick={() => {
                                                                            sec.topics.length !== 0 &&
                                                                                toggleid(sec.id, setTopicOpen)
                                                                        }}
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                        <span
                                                                            key={sec.id}
                                                                            style={{
                                                                                marginLeft: 'auto',
                                                                                cursor: 'pointer',
                                                                                marginRight: '10px',
                                                                                display:
                                                                                    sec.topics.length === 0
                                                                                        ? 'none'
                                                                                        : 'block',
                                                                            }}
                                                                        >
                                                                            {sec.topics && sec.topics.length > 0 && (
                                                                                <span>
                                                                                    {topicOpen.includes(sec.id) ? (
                                                                                        <BiChevronUp />
                                                                                    ) : (
                                                                                        <BiChevronDown />
                                                                                    )}
                                                                                </span>
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                {topicOpen.includes(sec.id) &&
                                                                    sec.topics &&
                                                                    sec.topics.map(top => (
                                                                        <div
                                                                            className="d-flex"
                                                                            style={{
                                                                                marginTop: '10px',
                                                                                marginLeft: '30px',
                                                                            }}
                                                                            key={top.name}
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                value={top.name}
                                                                                id={'sector' + top.name}
                                                                                checked={selectedSectors.includes(
                                                                                    top.name
                                                                                )}
                                                                                onChange={() => {
                                                                                    changeCheck('sector', top.name)
                                                                                }}
                                                                            />

                                                                            <label
                                                                                className="flex-fill"
                                                                                style={{
                                                                                    marginLeft: '10px',
                                                                                }}
                                                                                onClick={() => {
                                                                                    changeCheck('sector', top.name)
                                                                                }}
                                                                            >
                                                                                {top.name}
                                                                            </label>
                                                                        </div>
                                                                    ))}
                                                            </>
                                                        ))}
                                                </>
                                            )
                                        })}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="mt-2">
                        <Grid item>
                            <Grid container direction="row" spacing={1} style={{ marginTop: '5px' }}>
                                {props.selectedData
                                    .filter(value => value.toLowerCase().includes(selectedinput.toLowerCase()))
                                    .map(value => (
                                        <Grid item>
                                            <Chip
                                                key={value}
                                                label={value}
                                                style={{
                                                    background: '#D4E4FA',
                                                }}
                                                size="small"
                                                title="Custom user"
                                                onDelete={() => handleRemove(props.state, value)}
                                            />
                                        </Grid>
                                    ))}
                            </Grid>
                        </Grid>
                    </div>
                </div>

                {/* <div className="col-12 col-md-5">
                    {props.selectedData.filter(value => value.toLowerCase().includes(selectedinput.toLowerCase()))
                        .length <= 0 && (
                        <p className="mt-2">Search and select topics, industries and sectors of interest.</p>
                    )}
                </div> */}
            </div>
        </div>
    )
}
