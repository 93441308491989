import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { NavLink } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import BaseScreen from '../BaseScreen/BaseScreen'
import AdminHeader from './components/AdminHeader'
import { contactsColumns as columns } from './constants/AnalystAdminConstants'
import { Circles } from 'react-loader-spinner'

const AnalystAdminContacts = () => {
    const [EmailFilterText, setEmailFilterText] = useState('')
    const [lastNameFilterText, setLastNameFilterText] = useState('')
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
    const [allContacts, setAllContacts] = useState([])

    useEffect(() => {
        getAllContacts()
    }, [])

    const handleClearEmail = () => {
        if (EmailFilterText) {
            setResetPaginationToggle(!resetPaginationToggle)
            setEmailFilterText('')
        }
    }
    const handleClearLastName = () => {
        if (lastNameFilterText) {
            setResetPaginationToggle(!resetPaginationToggle)
            setLastNameFilterText('')
        }
    }

    const getAllContacts = () => {
        axios.get('/news/contacts/').then(response => {
            setAllContacts(response.data)
        })
    }

    const filteredContact = allContacts
        .filter(item => item.email && item.email.toLowerCase().includes(EmailFilterText.toLowerCase()))
        .filter(item => item.last_name && item.last_name.toLowerCase().includes(lastNameFilterText.toLowerCase()))

    return (
        <BaseScreen>
            {allContacts.length > 0 ? (
                <>
                    <AdminHeader currentPage={'contacts'} />

                    <div className="adminEndUser__filterSection">
                        <div className="adminEndUser__filters">
                            <div className="adminEndUser__emailFilterContainer">
                                <input
                                    id="search"
                                    type="text"
                                    placeholder="Filter By Email"
                                    className="analystAdmin__filterTextField"
                                    aria-label="Search Input"
                                    value={EmailFilterText}
                                    onChange={e => setEmailFilterText(e.target.value)}
                                    key={'Email Filter'}
                                />
                                <div type="button" onClick={handleClearEmail} className="analystAdmin__ClearButton">
                                    X
                                </div>
                            </div>

                            <div className="adminEndUser__emailFilterContainer">
                                <input
                                    id="search"
                                    type="text"
                                    placeholder="Filter By last name"
                                    className="analystAdmin__filterTextField"
                                    aria-label="Search Input"
                                    value={lastNameFilterText}
                                    onChange={e => setLastNameFilterText(e.target.value)}
                                    key={'last name Filter'}
                                />
                                <div type="button" onClick={handleClearLastName} className="analystAdmin__ClearButton">
                                    X
                                </div>
                            </div>
                        </div>

                        <NavLink to="/app/admin/add-contact/" className="adminEndUser__newUserButton">
                            <span>Add Contact</span>
                        </NavLink>
                    </div>

                    <div className="adminEndUser__body">
                        <DataTable
                            title="Contacts"
                            columns={columns}
                            data={filteredContact}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle}
                            persistTableHead
                        />
                    </div>
                </>
            ) : (
                <div>
                    <Circles key={0} type="Circles" color="#adadad" height={80} width={80} />
                </div>
            )}
        </BaseScreen>
    )
}

export default AnalystAdminContacts
