import React from 'react'

export default function MyRelationships(props) {
    const { onPage, setOnPage, letters, userContacts, getSingleContact, setNameFilter, selectedPerson } = props

    return (
        <div>
            <h3 className="dirDash__columnHeader dirDash__heading">My Relationships</h3>

            <div className="myRelationships__body">
                <input
                    type="search"
                    placeholder="Quick Find"
                    className="myRelationships__searchBox"
                    onChange={ev => setNameFilter(ev.target.value)}
                />

                <div className="myRelationships__contactsContainer">
                    {letters.map(letter => {
                        return (
                            <div key={'letter-header-' + letter}>
                                <div className="myRelationships__letterHeading">{letter}</div>

                                {userContacts.map(person => {
                                    if (person.first_name.substring(0, 1).toUpperCase() === letter) {
                                        return (
                                            <div
                                                className={
                                                    person.id === selectedPerson.id
                                                        ? 'myRelationships__contactName-active'
                                                        : 'myRelationships__contactName'
                                                }
                                                key={'name-' + person.id}
                                            >
                                                <p
                                                    onClick={() => {
                                                        if (props.toggleModal) {
                                                            props.toggleModal()
                                                        }
                                                        getSingleContact(person.id)
                                                    }}
                                                >
                                                    {person.first_name + ' ' + person.last_name}
                                                </p>
                                            </div>
                                        )
                                    } else {
                                        return null
                                    }
                                })}
                            </div>
                        )
                    })}
                </div>

                <div
                    className={
                        onPage === 0
                            ? 'myRelationships__createContactButton'
                            : 'myRelationships__createContactButtonInactive'
                    }
                    onClick={() => setOnPage(0)}
                >
                    <p>+ Create Contact</p>
                </div>
            </div>
        </div>
    )
}
