import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import axios from 'axios'
import { connect } from 'react-redux'
import BaseScreen from '../BaseScreen/BaseScreen'
import AdminHeader from '../AnalystAdmin/components/AdminHeader'
import RegionSelectWidget from '../../components/NewsEditedCard/RegionSelectWidget'
import { uploadDocument, updateDocumentData } from '../../Api/Analyst/documents'

function UploadReportsScreen(props) {
    const [formData, setFormData] = useState({
        title: '',
        industries: [],
        sectors: [],
        company: {},
        firms: [],
        country: null,
        file: {},
    })
    const [allIndustries, setAllIndustries] = useState([])
    const [allSectors, setAllSectors] = useState([])
    const [allCompanies, setAllCompanies] = useState([])
    const [allFirms, setAllFirms] = useState([])
    const [loadDocData, setLoadDocData] = useState(false)

    useEffect(() => {
        if (
            (props.adminAllIndustries.length === 0 && allIndustries.length === 0) ||
            (props.adminAllFirms.length === 0 && allFirms.length === 0)
        ) {
            getIndustriesSectorsVerticals()
            getAllCompanies()
            getAllFirms()
        }
        if (props.match.params.id) {
            setLoadDocData(true)
        }
    }, [allFirms])

    const getIndustriesSectorsVerticals = async () => {
        await axios.get(`/news/all-industries-sectors/`).then(response => {
            const updatedIndustries = []
            response.data.forEach(item => {
                if (item.nick_name) {
                    const tempObj = { ...item }
                    tempObj.label = item.nick_name
                    updatedIndustries.push(tempObj)
                } else {
                    updatedIndustries.push(item)
                }
            })
            setAllIndustries(updatedIndustries.filter(item => item.type === 'Industry'))
            setAllSectors(updatedIndustries.filter(item => item.type === 'Sector'))
        })
    }
    const getAllCompanies = async () => {
        await axios.get(`/accounts/news/companies/`).then(response => {
            setAllCompanies(response.data.companies)
        })
    }
    const getAllFirms = async () => {
        await axios.get(`/analyst/firms/`).then(response => {
            setAllFirms(response.data.firms)
        })
    }
    const getSingleDocument = async () => {
        await axios.get(`/core/all-documents/${props.match.params.id}/`).then(response => {
            let industriesToSet = []
            response.data.industries.forEach(item => {
                let selectedItem
                if (props.adminAllIndustries.length) {
                    selectedItem = props.adminAllIndustries.find(ind => ind.value === item)
                } else {
                    selectedItem = allIndustries.find(ind => ind.value === item)
                }
                industriesToSet.push(selectedItem)
            })

            let sectorsToSet = []
            response.data.sectors.forEach(item => {
                let selectedItem
                if (props.adminAllSectors.length) {
                    selectedItem = props.adminAllSectors.find(ind => ind.value === item)
                } else {
                    selectedItem = allSectors.find(ind => ind.value === item)
                }
                sectorsToSet.push(selectedItem)
            })

            let companyToSet
            if (props.adminAllCompanies.length) {
                companyToSet = props.adminAllCompanies.find(item => response.data.company === item.value)
            } else {
                companyToSet = allCompanies.find(item => response.data.company === item.value)
            }

            let firmsToSet = []
            response.data.firms.forEach(item => {
                let selectedItem
                if (props.adminAllFirms.length) {
                    selectedItem = props.adminAllFirms.find(ind => ind.value === item)
                } else {
                    selectedItem = allFirms.find(ind => ind.value === item)
                }
                firmsToSet.push(selectedItem)
            })

            setFormData({
                id: response.data.id,
                title: response.data.title,
                industries: industriesToSet,
                sectors: sectorsToSet,
                company: !companyToSet ? {} : companyToSet,
                firms: firmsToSet,
                country: response.data.country,
                file: { lastModified: Date.now() },
            })
            setLoadDocData(false)
        })
    }

    const handleRegionChange = country => {
        // 1 = United States, 2 = Canada, 3 = All
        if (formData.country === null) {
            setFormData({ ...formData, country: country })
        } else if (formData.country === 1 && country === 1) {
            setFormData({ ...formData, country: null })
        } else if (formData.country === 2 && country === 2) {
            setFormData({ ...formData, country: null })
        } else if (formData.country === 1 && country === 2) {
            setFormData({ ...formData, country: 3 })
        } else if (formData.country === 2 && country === 1) {
            setFormData({ ...formData, country: 3 })
        } else if (formData.country === 3 && country === 2) {
            setFormData({ ...formData, country: 1 })
        } else if (formData.country === 3 && country === 1) {
            setFormData({ ...formData, country: 2 })
        }
    }

    const handleFileUpload = () => {
        if (props.match.params.id) {
            updateDocumentData(formData)
        } else {
            uploadDocument(formData)
        }
    }

    if (props.match.params.id && loadDocData === true) {
        getSingleDocument()
    }

    return (
        <BaseScreen>
            <AdminHeader currentPage={'addDocument'} />
            <div style={{ backgroundColor: 'white', marginTop: '6%', padding: '15px', width: '99%' }}>
                <h3 className="addNew2__label">Title</h3>
                <div className="addNew2__row">
                    <div className="addNew2__inputContainer width75 height42">
                        <input
                            type="text"
                            className="addNews__input width100"
                            placeholder="Add a document title here"
                            value={formData.title}
                            onChange={ev => setFormData({ ...formData, title: ev.target.value })}
                        />
                    </div>
                </div>

                <div className="addNew2__row">
                    <div className="width75">
                        <h3 className="addNew2__label">Assign to a Firm</h3>
                        <Select
                            isMulti
                            className="width100 addNews2__selectInput"
                            placeholder="Select firm"
                            value={formData.firms}
                            options={
                                props.adminAllFirms.length
                                    ? props.adminAllFirms
                                    : allFirms.sort((a, b) => (a.label > b.label ? 1 : -1))
                            }
                            onChange={value => {
                                if (value === null) {
                                    setFormData({ ...formData, firms: [] })
                                } else {
                                    setFormData({ ...formData, firms: value })
                                }
                            }}
                        />
                    </div>
                </div>

                <div className="addNew2__row">
                    <div className="width75">
                        <h3 className="addNew2__label">Company</h3>
                        <Select
                            className="width100 addNews2__selectInput"
                            placeholder="Select companies"
                            value={formData.company}
                            options={
                                props.adminAllCompanies.length
                                    ? props.adminAllCompanies
                                    : allCompanies.sort((a, b) => (a.label > b.label ? 1 : -1))
                            }
                            onChange={value => setFormData({ ...formData, company: value })}
                        />
                    </div>
                </div>

                <div className="addNew2__row">
                    <div className="width32" style={{ marginRight: '16px' }}>
                        <h3 className="addNew2__label">Industry</h3>
                        <Select
                            isMulti
                            className="width100 addNews2__selectInput"
                            placeholder="Select industries"
                            value={formData.industries}
                            options={
                                props.adminAllIndustries.length
                                    ? props.adminAllIndustries
                                    : allIndustries.sort((a, b) => (a.label > b.label ? 1 : -1))
                            }
                            onChange={value => {
                                if (value === null) {
                                    setFormData({ ...formData, industries: [] })
                                } else {
                                    setFormData({ ...formData, industries: value })
                                }
                            }}
                        />
                    </div>

                    <div className="width32" style={{ marginRight: '16px' }}>
                        <h3 className="addNew2__label">Sector</h3>
                        <Select
                            isMulti
                            className="addNews2__selectInput"
                            placeholder="Select sectors"
                            value={formData.sectors}
                            options={
                                props.adminAllSectors.length
                                    ? props.adminAllSectors
                                    : allSectors.sort((a, b) => (a.label > b.label ? 1 : -1))
                            }
                            onChange={value => {
                                if (value === null) {
                                    setFormData({ ...formData, sectors: [] })
                                } else {
                                    setFormData({ ...formData, sectors: value })
                                }
                            }}
                        />
                    </div>
                </div>

                <div className="addNew2__row">
                    <RegionSelectWidget
                        country={formData.country}
                        handleRegionChange={handleRegionChange}
                        headingStyle={{ fontSize: '20px', marginTop: '18px' }}
                        boxStyle={{ padding: '10px' }}
                    />
                </div>

                {!props.match.params.id && (
                    <div>
                        <h3 className="addNew2__label">Choose File</h3>
                        <div className="addNew2__row">
                            <input
                                type="file"
                                onChange={ev => setFormData({ ...formData, file: ev.target.files[0] })}
                            />
                        </div>
                    </div>
                )}

                {props.match.params.id && (
                    <div>
                        <h3 className="addNew2__label">
                            File Cannot be edited. Create a new document if file needs to change.
                        </h3>
                    </div>
                )}

                <div className="addNews__backAndNextButtonsRow">
                    <button
                        className={
                            formData.title === '' ||
                            !formData.file.lastModified ||
                            formData.firms.length === 0 ||
                            (formData.industries.length === 0 &&
                                formData.sectors.length === 0 &&
                                !formData.company.value)
                                ? 'addNews__nextButton-inactive'
                                : 'addNews__nextButton'
                        }
                        onClick={
                            formData.title === '' ||
                            !formData.file.lastModified ||
                            formData.firms.length === 0 ||
                            (formData.industries.length === 0 &&
                                formData.sectors.length === 0 &&
                                !formData.company.value)
                                ? null
                                : handleFileUpload
                        }
                    >
                        {props.match.params.id ? 'Update Document Data' : 'Upload Document'}
                    </button>
                </div>
            </div>{' '}
        </BaseScreen>
    )
}

const mapStateToProps = state => {
    return {
        adminAllIndustries: state.analystAdmin.industries,
        adminAllSectors: state.analystAdmin.sectors,
        adminAllCompanies: state.analystAdmin.companies,
        adminAllFirms: state.analystAdmin.firms,
    }
}

export default connect(mapStateToProps)(UploadReportsScreen)
