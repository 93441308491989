import React, { useState } from 'react'
import axios from 'axios'
import { NavLink } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap'

export const RenderDocumentTableActions = row => {
    const [modalOpen, setModalOpen] = useState(false)

    const deleteDocument = id => {
        axios.get(`/delete-documents/?ids=${id}`).then(response => {
            setModalOpen(false)
        })
    }

    return (
        <div>
            <span
                style={{ cursor: 'pointer', color: '#2683c1', marginRight: '10px' }}
                onClick={() => setModalOpen(true)}
            >
                Delete
            </span>
            <NavLink to={`/app/admin/add-documents/${row.id}`}>Edit</NavLink>

            <Modal isOpen={modalOpen} className="newsCard20__deleteConfirmModalContainer" size="lg">
                <ModalBody>
                    <h1 style={{ textAlign: 'right', cursor: 'pointer' }} onClick={() => setModalOpen(false)}>
                        X
                    </h1>
                    <div>
                        <h3>
                            Are you sure you want to delete <strong>{row.title}</strong>
                        </h3>
                        <div className="newsCard20__YesNoButtons">
                            <div className="newsCard20__NoButton" onClick={() => setModalOpen(false)}>
                                <p>No</p>
                            </div>
                            <div className="newsCard20__YesButton" onClick={() => deleteDocument(row.id)}>
                                <p>Yes</p>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}
