import React, { useEffect, useRef, useState } from 'react'
import ArrowBackIosNewSharp from '@mui/icons-material/ArrowBackIosNewSharp'
import ArrowForwardIosSharp from '@mui/icons-material/ArrowForwardIosSharp'
import IndustryHierarchyHeaders from './IndustryHierarchyHeaders'

export default function IndustryHeaderSection(props) {
    const { allSectors, selectedSector, setSelectedSector } = props

    const [visibleElements, setVisibleElements] = useState({})
    const [scrollPosition, setScrollPosition] = useState(0)

    const contentRef = useRef(null)

    const calculateMaxScroll = () => {
        const contentWidth = contentRef.current.offsetWidth
        const containerWidth = contentRef.current.parentElement.offsetWidth
        const maxScroll = contentWidth - containerWidth
        return maxScroll >= 0 ? maxScroll : 0
    }

    const scrollLeft = () => {
        const maxScroll = calculateMaxScroll()
        setScrollPosition(Math.max(scrollPosition - 450, 0))
    }

    const scrollRight = () => {
        const maxScroll = calculateMaxScroll()
        setScrollPosition(Math.min(scrollPosition + 450, maxScroll))
    }

    // Recalculate max scroll on resize
    useEffect(() => {
        const handleResize = () => {
            const maxScroll = calculateMaxScroll()
            setScrollPosition(Math.min(scrollPosition, maxScroll))
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [scrollPosition])

    const toggleVisibility = id => {
        setVisibleElements(prevVisibleElements => {
            const isVisible = prevVisibleElements[id]
            return { ...prevVisibleElements, [id]: !isVisible }
        })
    }

    const renderHierarchy = data => {
        return (
            <div
                style={{
                    display: 'flex',
                    height: '50px',
                    background: 'white',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {data.map((item, index) => (
                    <div key={index} style={{ display: 'flex' }}>
                        {item.industries && (
                            <div style={{ display: 'flex' }}>
                                {item.industries.map(industry => {
                                    let combinedArray = []
                                    combinedArray.push(industry)
                                    industry.sectors &&
                                        industry.sectors.forEach(sector => {
                                            combinedArray.push(sector)
                                            if (sector.topics) combinedArray = combinedArray.concat(sector.topics)
                                        })

                                    return (
                                        <IndustryHierarchyHeaders
                                            combinedArray={combinedArray}
                                            selectedSector={selectedSector}
                                            setSelectedSector={setSelectedSector}
                                        />
                                    )
                                })}
                            </div>
                        )}
                        {item.sectors && (
                            <div style={{ display: 'flex' }}>
                                {item.sectors.map(sector => {
                                    let arrayy = []
                                    arrayy.push(sector)
                                    if (sector.topics) arrayy = arrayy.concat(sector.topics)

                                    return (
                                        <IndustryHierarchyHeaders
                                            combinedArray={arrayy}
                                            selectedSector={selectedSector}
                                            setSelectedSector={setSelectedSector}
                                        />
                                    )
                                })}
                            </div>
                        )}
                        {item.topics && (
                            <div style={{ display: 'flex' }}>
                                {item.topics.map(topic => (
                                    <span
                                        style={{
                                            marginRight: '10px',
                                            cursor: 'pointer',
                                            color: topic.id === selectedSector.id ? '#3b7dba' : '',
                                        }}
                                        onClick={() => {
                                            setSelectedSector({ id: topic.id, type: topic.type })
                                        }}
                                    >
                                        {topic.name}
                                        <span
                                            style={{
                                                borderLeft: '1.5px solid lightgray',
                                                marginLeft: '20px',
                                            }}
                                        />
                                    </span>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        )
    }
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <button
                style={{
                    display:
                        contentRef !== null && contentRef.current && contentRef.current.offsetWidth > 800
                            ? 'block'
                            : 'none',
                }}
                onClick={scrollLeft}
            >
                <ArrowBackIosNewSharp />
            </button>

            <div
                style={{
                    width: '100%',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    position: 'relative',
                    margin: 'auto',
                    textAlign:
                        contentRef !== null && contentRef.current && contentRef.current.offsetWidth > 800
                            ? 'center'
                            : 'left',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontSize: 'large',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        top: '0',
                        width: '100%',
                    }}
                />
                <div
                    ref={contentRef}
                    style={{
                        display: 'inline-block',
                        transition: 'transform 0.3s ease',
                        transform: `translateX(-${scrollPosition}px)`,
                    }}
                >
                    {renderHierarchy(allSectors)}
                </div>
            </div>
            <button
                style={{
                    display:
                        contentRef !== null && contentRef.current && contentRef.current.offsetWidth > 800
                            ? 'block'
                            : 'none',
                }}
                onClick={scrollRight}
            >
                <ArrowForwardIosSharp />
            </button>
        </div>
    )
}
