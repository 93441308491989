import React, { useState } from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

export default function ImageUpload(props) {
    const [crop, setCrop] = useState({ aspect: props.width / props.height })
    const [imageRef, setImageRef] = useState()
    const { selectedImage, setSelectedImage } = props

    const onImageLoaded = image => {
        setImageRef(image)
    }

    const onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader()
            reader.addEventListener('load', () => {
                setSelectedImage(reader.result)
                props.outerState(reader.result)
            })
            reader.readAsDataURL(e.target.files[0])
        }
    }

    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement('canvas')
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        canvas.width = crop.width
        canvas.height = crop.height
        const ctx = canvas.getContext('2d')

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        )

        return new Promise((resolve, reject) => {
            const filePrefix = selectedImage.split(';')[0]
            const fileExt = filePrefix.split('/')[1]
            resolve(canvas.toDataURL(`image/${fileExt}`))
        })
    }

    const makeClientCrop = async crop => {
        if (imageRef && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(imageRef, crop, 'newFile.jpeg')
            props.setCroppedImage(croppedImageUrl)
        }
    }

    const onCropComplete = crop => {
        makeClientCrop(crop)
    }

    return (
        <div style={{ display: 'grid' }}>
            <input type="file" accept="image/*" onChange={onSelectFile} />
            {selectedImage && selectedImage !== '' && (
                <ReactCrop
                    src={selectedImage}
                    crop={crop}
                    onChange={newCrop => setCrop(newCrop)}
                    onImageLoaded={onImageLoaded}
                    onComplete={onCropComplete}
                    style={{ maxWidth: `${props.maxWidth}px`, maxHeight: `${props.maxHeight}px` }}
                />
            )}
        </div>
    )
}
