import React, { useState, useEffect } from 'react'
import axios from 'axios'
import DataTable from 'react-data-table-component'
import Select from 'react-select'
import { NavLink } from 'react-router-dom'
import BaseScreen from '../BaseScreen/BaseScreen'
import AdminHeader from './components/AdminHeader'
import { firmDataTableColumns as columns } from './constants/AnalystAdminConstants'
import { Circles } from 'react-loader-spinner'
import store from '../../Store'
import AnalystAdminAddFirm from './AnalystAdminAddFirm'

export default function AnalystAdminFirm() {
    const [allFirms, setAllFirms] = useState([])
    const [countryFilter, setCountryFilter] = useState()
    const [filterText, setFilterText] = useState('')
    const [nameFilterText, setNameFilterText] = useState('')
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)

    const userType = store.getState().auth.user.user_type

    useEffect(() => {
        if (userType === 'Power User') {
            const headerTitle = document.getElementById('header_title')
            headerTitle.textContent = 'Firm Settings'
        } else {
            const headerTitle = document.getElementById('header_title')
            headerTitle.textContent = 'Manage Firms'
        }
    }, [userType])

    useEffect(() => {
        axios.get('/analyst/firms/').then(response => {
            setAllFirms(response.data.firms)
        })
    }, [])

    const filteredItems = allFirms
        .filter(item => item.label && item.label.toLowerCase().includes(filterText.toLowerCase()))
        .filter(item => item.contact_name && item.contact_name.toLowerCase().includes(nameFilterText.toLowerCase()))
        .filter(item => {
            if (countryFilter && countryFilter.label) {
                return item.country === countryFilter.label
            } else {
                return item
            }
        })

    const handleClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle)
            setFilterText('')
        }
        if (nameFilterText) {
            setResetPaginationToggle(!resetPaginationToggle)
            setNameFilterText('')
        }
        setCountryFilter()
    }

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: '#00193C', // Change to your desired header background color
                color: '#FFF', // Change to your desired header text color
                fontSize: '16px', // Change to your desired header font size
                height: '20px',
            },
        },
    }

    return (
        <>
            {userType === 'Power User' ? (
                <>
                    <AnalystAdminAddFirm />
                </>
            ) : (
                <BaseScreen>
                    <div>
                        {allFirms.length > 0 ? (
                            <>
                                <AdminHeader currentPage={'firm'} />

                                <div
                                    className="adminEndUser__filterSection"
                                    style={{ width: '95%', margin: '60px auto auto auto' }}
                                >
                                    <div className="adminEndUser__filters">
                                        <div className="adminEndUser__emailFilterContainer">
                                            <input
                                                id="firm name search"
                                                type="text"
                                                className="analystAdmin__filterTextField"
                                                placeholder="Firm Name Filter"
                                                aria-label="Search Input"
                                                value={filterText}
                                                onChange={e => setFilterText(e.target.value)}
                                                key={'Firm Filter'}
                                            />
                                            <input
                                                id="contact name search"
                                                type="text"
                                                className="analystAdmin__filterTextField"
                                                placeholder="Contact Name Filter"
                                                aria-label="Search Input"
                                                value={nameFilterText}
                                                onChange={e => setNameFilterText(e.target.value)}
                                                key={'Email Filter'}
                                            />
                                            <div
                                                type="button"
                                                onClick={handleClear}
                                                className="analystAdmin__ClearButton"
                                            >
                                                X
                                            </div>
                                        </div>

                                        {/* <Select
                                            name="Country"
                                            options={[
                                                { label: 'United States', value: '1' },
                                                { label: 'Canada', value: '2' },
                                            ]}
                                            placeholder="Country"
                                            onChange={value => setCountryFilter(value)}
                                            value={countryFilter}
                                            className="companiesAutoCompleteBox225NoMarginTop"
                                        /> */}
                                    </div>

                                    <NavLink to="/app/admin/add-firm/" className="adminEndUser__newUserButton">
                                        <span>Add Firm +</span>
                                    </NavLink>
                                </div>

                                <div className="adminEndUser__body">
                                    <DataTable
                                        title="Firms"
                                        columns={columns}
                                        data={filteredItems}
                                        pagination
                                        paginationResetDefaultPage={resetPaginationToggle}
                                        persistTableHead
                                        customStyles={customStyles}
                                    />
                                </div>
                            </>
                        ) : (
                            <div>
                                <Circles key={0} type="Circles" color="#adadad" height={80} width={80} />
                            </div>
                        )}
                    </div>
                </BaseScreen>
            )}
        </>
    )
}
