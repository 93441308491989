import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Input, Button } from 'reactstrap'
import { AiOutlineSave } from 'react-icons/ai'
import { IoIosRefresh } from 'react-icons/io'
import { BiSearch } from 'react-icons/bi'
import './Filter.css'
import { getYMD } from '../../../utils/dateHelpers'
import NoResults from '../../../components/NoResults/NoResults'
import ResultHeader from './ResultsHeader'
import SearchQueryView from '../../../components/NewsEditedCard/SearchQueryView'
import InfiniteScroll from 'react-infinite-scroller'
import { Circles } from 'react-loader-spinner'
import { Grid } from '@mui/material'
import RegionHiearchyMultiSelect from './RegionHiearchyMultiSelect'
import axios from 'axios'

export default function AdvanceSearch(props) {
    const {
        selectedQueries,
        setSelectedQueries,
        advanceSearch,
        clearFilters,
        setSaveModal,
        saveModal,
        editPage,
        advanceResponse,
        isAllSelected,
        setAllSelected,
        expandedView,
        setExpandedView,
        selectedNewsOnly,
        setSelectedNewsOnly,
        selectedStories,
        allCategories,
        allLinkedInGroups,
        removeFromSelection,
        addToSelection,
        handleLoadMore,
        usertype,
        isLoading,
        premeetingPlan,
        setPremeetingPlan,
        currentPath,
    } = props
    const { level, setLevel } = props
    const {
        allRegions,
        allSources,
        allSubCategories,
        allCompanies,
        allTopics,
        startDate,
        allIndustries,
        setAllCompanies,
    } = props

    return (
        <>
            <div
                style={{
                    background: 'white',
                    padding: '20px 10px 10px 10px',
                    marginTop: '-2px',
                    width: '99%',
                }}
                className="drop_down_sections"
            >
                <div className="sections" style={{ display: usertype === 'Regular User' ? 'block' : 'none' }}>
                    <div
                        style={{
                            display: 'flex',
                            marginBottom: '10px',
                        }}
                        id="level-section"
                    >
                        <label className="filter_label">Curation Level</label>
                        <span className="filter_span" style={{ fontSize: '12px' }}>
                            <Input
                                onChange={e => {
                                    setLevel(e.target.value)
                                }}
                                value={level}
                                type="select"
                                style={{
                                    height: '37px',
                                    width: '100px',
                                    fontSize: '10px',
                                    marginTop: '-6px',
                                    fontWeight: '600',
                                    borderRadius: '6px',
                                    color: 'hsl(0,0%,50%)',
                                }}
                            >
                                <option value="level1">Level 1</option>
                                <option value="level2">Level 2</option>
                            </Input>
                        </span>
                    </div>
                </div>
                <div className="sections">
                    <div
                        style={{
                            display: 'flex',
                        }}
                        id="region-section"
                    >
                        <label className="filter_label">Article date</label>
                        <span className="filter_span" style={{ fontSize: '12px' }}>
                            <Input
                                onChange={e => {
                                    setSelectedQueries({
                                        ...selectedQueries,
                                        articleOperator: e.target.value,
                                    })
                                }}
                                value={selectedQueries.articleOperator}
                                type="select"
                                style={{
                                    height: '37px',
                                    width: '100px',
                                    fontSize: '10px',
                                    marginTop: '-6px',
                                    fontWeight: '600',
                                    borderRadius: '6px',
                                    color: 'hsl(0,0%,50%)',
                                }}
                            >
                                <option value="BETWEEN">is between</option>
                                <option value="NOT_BETWEEN">not between</option>
                            </Input>
                        </span>

                        <Input
                            className="inputs_date"
                            type="date"
                            value={
                                selectedQueries.articleDateFrom == ''
                                    ? getYMD(startDate)
                                    : selectedQueries.articleDateFrom
                            }
                            onChange={e => {
                                setSelectedQueries({
                                    ...selectedQueries,
                                    articleDateFrom: e.target.value,
                                })
                            }}
                        />
                        <span
                            className=""
                            style={{
                                fontSize: '12px',
                                textAlign: 'center',
                                width: '100px',
                                marginTop: '10px',
                                marginLeft: '-10px',
                            }}
                        >
                            and
                        </span>
                        <Input
                            className="inputs_date2"
                            type="date"
                            value={
                                selectedQueries.articleDateTo == '' ? getYMD(new Date()) : selectedQueries.articleDateTo
                            }
                            onChange={e => {
                                setSelectedQueries({
                                    ...selectedQueries,
                                    articleDateTo: e.target.value,
                                })
                            }}
                        />
                    </div>
                </div>
                <div className="sections">
                    <div style={{ display: 'flex', marginTop: '10px' }} id="region-section">
                        <label className="filter_label">Country</label>
                        <span className="filter_span" style={{ color: 'gray' }}>
                            <Input
                                onChange={e => {
                                    setSelectedQueries({
                                        ...selectedQueries,
                                        regionOperator: e.target.value,
                                    })
                                }}
                                value={selectedQueries.regionOperator}
                                type="select"
                                style={{
                                    height: '37px',
                                    width: '100px',
                                    fontSize: '10px',
                                    marginTop: '-6px',
                                    fontWeight: '600',
                                    borderRadius: '6px',
                                    color: 'hsl(0,0%,50%)',
                                }}
                            >
                                <option value="IS">is</option>
                                <option value="IS_NOT">is not</option>
                            </Input>
                        </span>
                        <Select
                            isMulti
                            name="region"
                            options={allRegions.sort((a, b) => (a.label > b.label ? 1 : -1))}
                            onChange={value => {
                                setSelectedQueries({ ...selectedQueries, region: value === null ? [] : value })
                            }}
                            value={selectedQueries.region}
                            placeholder="select country"
                            className="inputs"
                        />
                        {/* <RegionHiearchyMultiSelect
                            selectedQueries={selectedQueries}
                            setSelectedQueries={setSelectedQueries}
                            regionHierarchy={regionHierarchy}
                            isFindAndShare={true}
                        /> */}
                    </div>
                </div>
                <div className="sections">
                    <div
                        style={{
                            display: 'flex',

                            marginTop: '10px',
                        }}
                        id="company-section"
                    >
                        <label className="filter_label">Company</label>
                        <span className="filter_span" style={{ color: 'gray' }}>
                            <Input
                                onChange={e => {
                                    setSelectedQueries({ ...selectedQueries, companyOperator: e.target.value })
                                }}
                                value={selectedQueries.companyOperator}
                                type="select"
                                placeholder=""
                                style={{
                                    height: '37px',
                                    width: '100px',
                                    fontSize: '10px',
                                    marginTop: '-6px',
                                    fontWeight: '600',
                                    borderRadius: '6px',
                                    color: 'hsl(0,0%,50%)',
                                }}
                            >
                                <option value="IS">is</option>
                                <option value="ISNOT">is not</option>
                            </Input>
                        </span>
                        <Select
                            isMulti
                            className="inputs"
                            value={selectedQueries.company}
                            placeholder="select company"
                            options={allCompanies.sort((a, b) => (a.label > b.label ? 1 : -1))}
                            onChange={value => {
                                setSelectedQueries({ ...selectedQueries, company: value === null ? [] : value })
                            }}
                            onInputChange={e => {
                                if (e.length > 2) {
                                    axios
                                        .get(`/news/auto-complete/?type=company&string=${encodeURIComponent(e)}`)
                                        .then(res => {
                                            const filteredData = res.data.map(item => ({
                                                label: item.name,
                                                value: item.id,
                                            }))
                                            setAllCompanies(filteredData)
                                        })
                                        .catch(err => console.log(err))
                                } else {
                                    setAllCompanies(allCompanies)
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="sections">
                    <div style={{ display: 'flex', marginTop: '10px' }} id="topic-section">
                        <label className="filter_label">Topic</label>
                        <span className="filter_span" style={{ color: 'gray' }}>
                            <Input
                                onChange={e => {
                                    setSelectedQueries({ ...selectedQueries, topicOperator: e.target.value })
                                }}
                                value={selectedQueries.topicOperator}
                                type="select"
                                style={{
                                    height: '37px',
                                    width: '100px',
                                    fontSize: '10px',
                                    marginTop: '-6px',
                                    fontWeight: '600',
                                    borderRadius: '6px',
                                    color: 'hsl(0,0%,50%)',
                                }}
                            >
                                <option value="IS">is</option>
                                <option value="IS_NOT">is not</option>
                            </Input>
                        </span>
                        <Select
                            isMulti
                            className="inputs"
                            value={selectedQueries.topic}
                            placeholder="select topic"
                            options={allTopics.sort((a, b) => (a.label > b.label ? 1 : -1))}
                            onChange={value => {
                                // setSelectedQueries()
                                setSelectedQueries({ ...selectedQueries, topic: value === null ? [] : value })
                            }}
                        />
                    </div>
                </div>

                <div className="sections">
                    <div style={{ display: 'flex', marginTop: '10px' }} id="industry-section">
                        <label className="filter_label">Industry/Sector</label>
                        <span className="filter_span" style={{ color: 'gray' }}>
                            <Input
                                value={selectedQueries.industryOperator}
                                onChange={e => {
                                    setSelectedQueries({
                                        ...selectedQueries,
                                        industryOperator: e.target.value,
                                    })
                                }}
                                type="select"
                                style={{
                                    height: '37px',
                                    width: '100px',
                                    fontSize: '10px',
                                    marginTop: '-6px',
                                    fontWeight: '600',
                                    borderRadius: '6px',
                                    color: 'hsl(0,0%,50%)',
                                }}
                            >
                                <option value="IS">is</option>
                                <option value="IS_NOT">is not</option>
                            </Input>
                        </span>
                        <Select
                            isMulti
                            className="inputs"
                            value={selectedQueries.sectors}
                            placeholder="select sector"
                            options={allIndustries.sort((a, b) => (a.label > b.label ? 1 : -1))}
                            onChange={value => {
                                setSelectedQueries({ ...selectedQueries, sectors: value === null ? [] : value })
                            }}
                        />
                        {/* <AiOutlineCloseCircle onClick={() => removeVal('industry', 1)} className="close_button" /> */}
                    </div>
                </div>

                <div
                    className="sections"
                    style={
                        {
                            // display: val.includes('category') ? 'block' : 'none',
                        }
                    }
                >
                    <div style={{ display: 'flex', marginTop: '10px' }} id="category-section">
                        <label className="filter_label">Business Events</label>
                        <span className="filter_span" style={{ color: 'gray' }}>
                            <Input
                                value={selectedQueries.categoryOperator}
                                onChange={e => {
                                    setSelectedQueries({
                                        ...selectedQueries,
                                        categoryOperator: e.target.value,
                                    })
                                }}
                                type="select"
                                style={{
                                    height: '37px',
                                    width: '100px',
                                    fontSize: '10px',
                                    marginTop: '-6px',
                                    fontWeight: '600',
                                    borderRadius: '6px',
                                    color: 'hsl(0,0%,50%)',
                                }}
                            >
                                <option value="IS">is</option>
                                <option value="IS_NOT">is not</option>
                            </Input>
                        </span>

                        <Select
                            isMulti
                            className="inputs"
                            value={selectedQueries.category}
                            placeholder="select business events"
                            options={allSubCategories.sort((a, b) => (a.label > b.label ? 1 : -1))}
                            onChange={value => {
                                setSelectedQueries({
                                    ...selectedQueries,
                                    category: value === null ? [] : value,
                                })
                            }}
                        />
                    </div>
                </div>

                <div className="sections">
                    <div
                        style={{
                            display: 'flex',
                            marginTop: '15px',
                        }}
                        id="newstitle-section"
                    >
                        <label className="filter_label">News title</label>
                        <span className="filter_span" style={{ color: 'gray' }}>
                            <Input
                                value={selectedQueries.newsTitleOperator}
                                onChange={e => {
                                    setSelectedQueries({
                                        ...selectedQueries,
                                        newsTitleOperator: e.target.value,
                                    })
                                }}
                                type="select"
                                style={{
                                    height: '37px',
                                    width: '100px',
                                    fontSize: '10px',
                                    marginTop: '-6px',
                                    fontWeight: '600',
                                    borderRadius: '6px',
                                    color: 'hsl(0,0%,50%)',
                                }}
                            >
                                <option value="CONTAINS">contains</option>
                                <option value="CONTAINS_NOT">doesn't contains</option>
                            </Input>
                        </span>
                        <Input
                            className="inputs_news"
                            type="text"
                            value={selectedQueries.newsTitle}
                            placeholder="Enter news title"
                            onChange={e => {
                                setSelectedQueries({ ...selectedQueries, newsTitle: e.target.value })
                            }}
                        />
                    </div>
                </div>

                <div className="sections">
                    <div
                        style={{
                            display: 'flex',
                            marginTop: '15px',
                        }}
                        id="newscontent-section"
                    >
                        <label className="filter_label">News Content</label>
                        <span className="filter_span" style={{ color: 'gray' }}>
                            <Input
                                value={selectedQueries.newsContentOperator}
                                onChange={e => {
                                    setSelectedQueries({
                                        ...selectedQueries,
                                        newsContentOperator: e.target.value,
                                    })
                                }}
                                type="select"
                                style={{
                                    height: '37px',
                                    width: '100px',
                                    fontSize: '10px',
                                    marginTop: '-6px',
                                    fontWeight: '600',
                                    borderRadius: '6px',
                                    color: 'hsl(0,0%,50%)',
                                }}
                            >
                                <option value="CONTAINS">contains</option>
                                <option value="CONTAINS_NOT">doesn't contains</option>
                            </Input>
                        </span>
                        <Input
                            value={selectedQueries.newsContent}
                            className="inputs_news"
                            type="text"
                            placeholder="Enter news content"
                            onChange={e => setSelectedQueries({ ...selectedQueries, newsContent: e.target.value })}
                        />
                        {/* <AiOutlineCloseCircle onClick={() => removeVal('newscontent', 4)} className="close_button" /> */}
                    </div>
                </div>

                <div className="sections">
                    <div style={{ display: 'flex', marginTop: '15px' }} id="source-section">
                        <label className="filter_label">Source</label>
                        <span className="filter_span" style={{ color: 'gray' }}>
                            <Input
                                value={selectedQueries.sourceOperator}
                                onChange={e => {
                                    setSelectedQueries({
                                        ...selectedQueries,
                                        sourceOperator: e.target.value,
                                    })
                                }}
                                type="select"
                                style={{
                                    height: '37px',
                                    width: '100px',
                                    fontSize: '10px',
                                    marginTop: '-5px',
                                    fontWeight: '600',
                                    borderRadius: '6px',
                                    color: 'hsl(0,0%,50%)',
                                    // marginTop: '2px',
                                }}
                            >
                                <option value="is">is</option>
                                <option value="isnot">is not</option>
                            </Input>
                        </span>
                        <Select
                            isMulti
                            name="source"
                            placeholder="select source"
                            options={allSources.sort((a, b) => (a.label > b.label ? 1 : -1))}
                            onChange={value => {
                                setSelectedQueries({ ...selectedQueries, source: value === null ? [] : value })
                            }}
                            value={selectedQueries.source}
                            className="inputs"
                        />
                        {/* <AiOutlineCloseCircle onClick={() => removeVal('source', 5)} className="close_button" /> */}
                    </div>
                </div>
                <div className="btn_space d-flex " style={{ marginTop: '' }}>
                    <Button
                        className="btn_color btn_text"
                        style={{
                            marginLeft: '40px',
                        }}
                        onClick={advanceSearch}
                    >
                        Search
                        <span style={{ fontSize: '18px', marginLeft: '5px' }}>
                            <BiSearch />
                        </span>
                    </Button>

                    <Button
                        className="reset"
                        style={{ marginLeft: '15px', backgroundColor: 'white', color: '#197bbd' }}
                        onClick={e => clearFilters(e)}
                    >
                        Reset
                        <span style={{ fontSize: '18px' }}>
                            <IoIosRefresh />
                        </span>
                    </Button>

                    <Button
                        className="btn_color btn_text"
                        style={{ marginLeft: 'auto', marginRight: '50px' }}
                        onClick={() => {
                            setSaveModal(!saveModal)
                        }}
                    >
                        {!editPage ? 'Save' : 'Update'}
                        <span style={{ fontSize: '18px', marginLeft: '5px' }}>
                            <AiOutlineSave />
                        </span>
                    </Button>
                </div>
            </div>
            <div>
                {advanceResponse &&
                advanceResponse.data &&
                advanceResponse.data.data &&
                (advanceResponse.data.data.length > 0 ||
                    advanceResponse.data.data.filter(
                        val => (val.title && val.title.length > 0) || (val.title && val.title !== '') || !val.title
                    ).length > 0) ? (
                    <div style={{ width: '92%' }}>
                        <ResultHeader
                            style={{ fontSize: '20px' }}
                            isBasicSearchPage={true}
                            allSelected={isAllSelected}
                            setAllSelected={setAllSelected}
                            resultLength={
                                advanceResponse.data.next -
                                advanceResponse.data.data.filter(
                                    val =>
                                        (val.title && val.title.length) < 0 ||
                                        (val.title && val.title === '') ||
                                        !val.title
                                ).length
                            }
                            resultCount={
                                advanceResponse.data.total -
                                advanceResponse.data.data.filter(
                                    val =>
                                        (val.title && val.title.length < 0) ||
                                        (val.title && val.title === '') ||
                                        !val.title
                                ).length
                            }
                            expanded={expandedView}
                            setExpanded={setExpandedView}
                            selectedNewsOnly={selectedNewsOnly}
                            setSelectedNewsOnly={setSelectedNewsOnly}
                            premeetingPlan={premeetingPlan}
                            setPremeetingPlan={setPremeetingPlan}
                            selectedStoriesLength={selectedStories.length}
                        />
                        <Grid item>
                            {selectedNewsOnly ? (
                                selectedStories.map(story => {
                                    return (
                                        <SearchQueryView
                                            isFindAndShare={true}
                                            key={story.id}
                                            EachrawNews={story}
                                            isBasicSearch={true}
                                            selectedStories={selectedStories}
                                            isStorySelected={
                                                selectedStories.findIndex(arr => arr.id === story.id) === -1
                                                    ? false
                                                    : true
                                            }
                                            addToSelection={addToSelection}
                                            allCategories={allCategories}
                                            allSubCategories={allSubCategories}
                                            allIndustries={allIndustries}
                                            allCompanies={allCompanies}
                                            setAllCompanies={setAllCompanies}
                                            allRegions={allRegions}
                                            allLinkedInGroups={allLinkedInGroups}
                                            removeFromSelection={removeFromSelection}
                                            allTopics={allTopics}
                                            collapsed={!expandedView}
                                            usertype={usertype}
                                            selectAll={isAllSelected}
                                            currentPath={currentPath}
                                            selectedNewsOnly={selectedNewsOnly}
                                        />
                                    )
                                })
                            ) : (
                                <>
                                    <InfiniteScroll
                                        pageStart={0}
                                        loadMore={handleLoadMore}
                                        hasMore={advanceResponse.data.next < advanceResponse.data.total}
                                        loader={
                                            <Circles key={0} type="Circles" color="#adadad" height={80} width={80} />
                                        }
                                    >
                                        {advanceResponse.data.data.map(story => {
                                            return (
                                                <SearchQueryView
                                                    isFindAndShare={true}
                                                    key={story.id}
                                                    EachrawNews={story}
                                                    isBasicSearch={true}
                                                    selectedStories={selectedStories}
                                                    isStorySelected={
                                                        selectedStories.findIndex(arr => arr.id === story.id) === -1
                                                            ? false
                                                            : true
                                                    }
                                                    addToSelection={addToSelection}
                                                    allCategories={allCategories}
                                                    allSubCategories={allSubCategories}
                                                    allIndustries={allIndustries}
                                                    allCompanies={allCompanies}
                                                    setAllCompanies={setAllCompanies}
                                                    allRegions={allRegions}
                                                    allLinkedInGroups={allLinkedInGroups}
                                                    removeFromSelection={removeFromSelection}
                                                    allTopics={allTopics}
                                                    collapsed={!expandedView}
                                                    usertype={usertype}
                                                    selectAll={isAllSelected}
                                                    currentPath={currentPath}
                                                    selectedNewsOnly={selectedNewsOnly}
                                                />
                                            )
                                        })}
                                    </InfiniteScroll>
                                </>
                            )}
                        </Grid>
                    </div>
                ) : isLoading ? (
                    <div style={{ marginLeft: '40%', marginTop: '10px' }}>
                        <Circles key={0} type="Circles" color="#adadad" height={80} width={80} />
                    </div>
                ) : (
                    <div style={{ marginLeft: '36%', marginTop: '3%' }}>
                        <NoResults />
                    </div>
                )}
            </div>
        </>
    )
}
