import React, { useEffect, useState } from 'react'
import { MdModeEdit } from 'react-icons/md'
import DataTable from 'react-data-table-component'
import './Filter.css'
import axios from 'axios'

export default function PredefinedQueries(props) {
    const {
        setEditPage,
        setEditId,
        setActiveTab,
        setEditQueryName,
        setIsQueryEditorJson,
        setEditorQuery,
        setDirectRun,
        preeditPage,
        setPreeditPage,
    } = props

    const [predefinedResults, setPredefinedResults] = useState([])
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        getPredefinedQueries()
    }, [])

    const getPredefinedQueries = () => {
        axios
            .get('/news/predefined-queries/')
            .then(res => {
                setPredefinedResults(res.data.results)
            })
            .catch(err => console.log(err))
    }

    const [search, setSearch] = useState('')

    const predefinedEdit = row => {
        setActiveTab(3)
        setEditQueryName(row.name)
        setEditId(row.id)
        setEditPage(false)
        setPreeditPage(true)
        setDirectRun(false)
        setIsQueryEditorJson(row.query_string)
        setEditorQuery(row.query)
    }

    let columns = [
        {
            name: <p style={{ fontSize: '16px', fontWeight: '700', color: '#1c2b3e' }}>Name</p>,
            selector: row => row.name,
            sortable: true,
            width: '30%',
        },
        {
            name: <p style={{ fontSize: '16px', fontWeight: '700', color: '#1c2b3e' }}>Query</p>,
            selector: row => row.query_string,
            sortable: true,
            width: '45%',
        },
        {
            name: <p style={{ fontSize: '16px', fontWeight: '700', color: '#1c2b3e' }}>Action</p>,
            selector: row => (
                <div
                    className="d-flex"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        predefinedEdit(row)
                    }}
                >
                    <MdModeEdit className="edit_action_btn" />
                </div>
            ),
        },
    ]

    return (
        <div style={{ width: '99%', background: 'white' }}>
            <DataTable
                style={{ paddingLeft: '20px' }}
                columns={columns}
                data={
                    search === ''
                        ? predefinedResults
                        : predefinedResults.filter(each => each.name.toLowerCase().includes(search.toLowerCase()))
                }
                pagination
                fixedHeader
                noHeader={true}
                subHeader
                paginationTotalRows={predefinedResults.count}
                onChangePage={page => {
                    const isNext = page >= currentPage

                    if (predefinedResults.next !== null && isNext) {
                        axios
                            .get(predefinedResults.next)
                            .then(res => {
                                setPredefinedResults({
                                    count: res.data.count,
                                    next: res.data.next,
                                    data: [...predefinedResults.data, ...res.data.results],
                                })
                                setCurrentPage(page)
                            })
                            .catch(err => console.log(err))
                    }
                }}
                subHeaderComponent={
                    <input
                        type="search"
                        placeholder="Search here"
                        className="w-25 form-control"
                        value={search}
                        onChange={e => {
                            setSearch(e.target.value)
                        }}
                    />
                }
            />
        </div>
    )
}
