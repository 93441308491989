import React, { useEffect, useState } from 'react'
import BaseScreen from '../BaseScreen/BaseScreen'
import { subDays, addDays, format } from 'date-fns'
import { Circles } from 'react-loader-spinner'
import '../AnalystDashboard/AnalystDashboard.css'
import { NavLink } from 'react-router-dom'
import axios from 'axios'

export default function FirmAnalystDashboard(params) {
    const [newsCount, setNewsCount] = useState(-1)
    const [usersCount, setUsersCount] = useState(-1)
    const [templatesCount, setTemplatesCount] = useState(-1)

    useEffect(() => {
        axios.get('/endusers/templates/').then(response => {
            setTemplatesCount(response.data.length)
        })
        axios.get('/endusers/all-endusers/').then(response => {
            setUsersCount(response.data.length)
        })
        axios
            .get(
                `
                /news/get-published-news/?newsStartDate=${format(subDays(new Date(), 365), 't')}&newsEndDate=${format(
                    addDays(new Date(), 1),
                    't'
                )}&newsPulledStartDate&newsPulledEndDate&newsPublishedStartDate=${format(subDays(new Date(), 7), 't')}
                &newsPublishedEndDate=${format(addDays(new Date(), 1), 't')}
                &industries&sectors&companies&subcategories&countries&sent=notSent&linkedin_groups&pullType&featured&underReview&topics&analysts`
            )
            .then(response => {
                setNewsCount(response.data.count)
            })
    })

    return (
        <BaseScreen>
            <div className="analystDashboard__headerSection">
                <h1 className="analystDashboard__welcome">Dashboard</h1>
            </div>
            <div className="analystDashboard__cardsContainer">
                <NavLink to="/app/published-news/" className="analystDashboard__statCard">
                    <h3>Unpublished News Items</h3>
                    {newsCount === -1 ? (
                        <Circles key={0} type="Grid" color="#adadad" height={80} width={60} />
                    ) : (
                        <p>{newsCount}</p>
                    )}
                </NavLink>

                <NavLink to="/app/admin/enduser/" className="analystDashboard__statCard">
                    <h3>Active Users</h3>
                    {usersCount === -1 ? (
                        <Circles key={0} type="Grid" color="#adadad" height={80} width={60} />
                    ) : (
                        <p>{usersCount}</p>
                    )}
                </NavLink>

                <NavLink to="/app/admin/templates/" className="analystDashboard__statCard">
                    <h3>Templates</h3>
                    {templatesCount === -1 ? (
                        <Circles key={0} type="Grid" color="#adadad" height={80} width={60} />
                    ) : (
                        <p>{templatesCount}</p>
                    )}
                </NavLink>

                {/* <NavLink to="#" className="analystDashboard__statCard">
                    <h3>Give Feedback</h3>
                </NavLink> */}
            </div>
        </BaseScreen>
    )
}
