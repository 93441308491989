import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import { Button } from 'reactstrap'
import { toast } from 'react-toastify'
import Select from 'react-select'

const options = Array.from({ length: 30 }, (_, i) => ({ value: i + 1, label: `${i + 1}` }))

const defaultOption = { value: 1, label: '1' }
const customStyles = {
    control: (provided, state) => ({
        ...provided,
        background: '#fff',
        borderColor: '#9e9e9e',
        minHeight: '30px',
        height: '30px',
        boxShadow: state.isFocused ? null : null,
        marginTop: '-5px',
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: '30px',
    }),
    input: (provided, state) => ({
        ...provided,
        marginLeft: '30px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
    }),
    placeholder: provided => ({
        ...provided,
        fontSize: '10px',
        alignItems: 'center',
    }),
}

export default function NewNewsPullButton() {
    useEffect(() => {
        const headerTitle = document.getElementById('header_title')
        headerTitle.textContent = 'News Pull'
    }, [])
    const [expanded, setExpanded] = useState(false)
    const [sectorsExpanded, setSectorsExpanded] = useState(false)
    const [companiesExpanded, setCompaniesExpanded] = useState(false)
    const [sourceExpanded, setSoureceExpanded] = useState(false)
    const [buttonDisplay, setButtonDisplay] = useState(true)
    const [buttonText, setButtonText] = useState('Pull News')
    const [companyButtonText, setCompanyButtonText] = useState('Pull News')
    const [sectorButtonText, setSectorButtonText] = useState('Pull News')
    const [date, setDate] = useState(defaultOption)
    const [companyDate, setCompanyDate] = useState(defaultOption)
    const [selectedNormalDate, setSelectedNormalDate] = useState(1)

    const [allSectorsTerms, setAllSectorsTerms] = useState([])
    const [allCompanies, setAllCompanies] = useState([])
    const [allSources, setAllSources] = useState([])

    const [selectedIndustries, setSelectedIndustries] = useState([])
    const [selectedSectors, setSelectedSectors] = useState([])
    const [selectedTopics, setSelectedTopics] = useState([])
    const [selectedCompanies, setSelectedCompanies] = useState([])
    const [selectedSources, setSelectedSources] = useState([])

    const [companyTextFilter, setCompanyTextFilter] = useState('')
    const [sectorTextFilter, setSectorTextFilter] = useState('')
    const [sourceTextFilter, setSourceTextFilter] = useState('')
    const [showType, setShowType] = useState('')
    const [showCompanies, setShowCompanies] = useState([])
    const wrapperRef = useRef(null)
    closeExpandable(wrapperRef)

    const stateMap = {
        Industry: [selectedIndustries, setSelectedIndustries],
        Sector: [selectedSectors, setSelectedSectors],
        company: [selectedCompanies, setSelectedCompanies],
        Topic: [selectedTopics, setSelectedTopics],
        source: [selectedSources, setSelectedSources],
    }

    const getIndustriesAndCompanies = async () => {
        axios.get(`/news/all-companies/`).then(response => {
            setAllCompanies(response.data)
        })
        axios
            .get(`/news/all-industries-sectors/`, {
                params: {
                    all: 1,
                },
            })
            .then(response => {
                setAllSectorsTerms(response.data)
            })
    }

    const getAllSources = () => {
        axios
            .get('/news/sources/')
            .then(res => {
                setAllSources(res.data)
            })
            .catch(err => console.log(err))
    }

    const changeCheck = (type, id) => {
        const [selectedItems, setSelectedItems] = stateMap[type]
        const isItemSelected = selectedItems.includes(id)

        if (isItemSelected) {
            const filteredItems = selectedItems.filter(item => item !== id)
            setSelectedItems(filteredItems)
        } else {
            setSelectedItems([...selectedItems, id])
        }
        setButtonDisplay(false)
    }

    const newsPull = newsType => {
        let apiUrl = ''
        let queryParams = ''

        switch (newsType) {
            case 'sector':
                setSectorButtonText('Pulling...')
                queryParams = `companies${
                    selectedCompanies.length === 0 ? selectedCompanies : '=' + selectedCompanies
                }&days=${selectedNormalDate}&industries${
                    selectedIndustries.length === 0 ? selectedIndustries : '=' + selectedIndustries
                }&sectors${selectedSectors.length === 0 ? selectedSectors : '=' + selectedSectors}&topics${
                    selectedTopics.length === 0 ? selectedTopics : '=' + selectedTopics
                }`
                apiUrl = `/news/pull-company-topic-news/?${queryParams}`
                break
            default:
                setButtonText('Pulling...')
                queryParams = `sources=${selectedSources}&days=${date.value}`
                apiUrl = `/news/pull-source-news/?${queryParams}`
        }

        axios
            .get(apiUrl)
            .then(response => {
                setSectorButtonText('Done')
                setButtonText('Done')
                setButtonDisplay(true)
            })
            .catch(error => {
                toast.warn('error', error)
                setButtonText('Error')
            })
    }

    function closeExpandable(ref) {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setExpanded(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }

    const [checkedSources, setCheckedSources] = useState(false)
    const [checkedSectors, setCheckedSectors] = useState(false)
    const [checkedCompanies, setCheckedCompanies] = useState(false)

    const handleSelectAllSources = () => {
        if (checkedSources) {
            setSelectedSources([])
            setButtonDisplay(true)
            setButtonText('Pull News')
        } else {
            const allSourceLabels = allSources
                .filter(source => source.label.toUpperCase().includes(sourceTextFilter.toUpperCase()))
                .map(source => source.value)
            setSelectedSources(allSourceLabels)
            setButtonDisplay(false)
            setButtonText('Pull News')
        }
        setCheckedSources(!checkedSources)
    }

    const handleSelectAllCompanies = () => {
        if (checkedCompanies) {
            setSelectedCompanies([])
            setButtonDisplay(true)
            setButtonText('Pull News')
        } else {
            setSelectedCompanies(
                allCompanies
                    .filter(option =>
                        selectedSectors.length === 0 && selectedIndustries.length === 0 && selectedTopics.length === 0
                            ? //  companyTextFilter.length > 2
                              //     ?
                              option.label
                            : // : option.label.toLowerCase().includes(companyTextFilter.toLowerCase())
                              option.sectors.some(sector => selectedSectors.includes(sector)) ||
                              option.industries.some(industry => selectedIndustries.includes(industry)) ||
                              option.topics.some(topic => selectedTopics.includes(topic))
                    )
                    .map(item => item.value)
            )
            setButtonDisplay(false)
            setButtonText('Pull News')
        }
        setCheckedCompanies(!checkedCompanies)
    }

    const handleSelectAllSectors = () => {
        if (checkedSectors) {
            setSelectedIndustries([])
            setSelectedSectors([])
            setSelectedTopics([])
            setButtonDisplay(true)
            setButtonText('Pull News')
        } else {
            const filterText = sectorTextFilter.toLowerCase()

            const filterOptions = options =>
                options.filter(option => option.label.toLowerCase().includes(filterText)).map(option => option.value)

            setSelectedIndustries(filterOptions(allSectorsTerms.filter(item => item.type === 'Industry')))
            setSelectedSectors(filterOptions(allSectorsTerms.filter(item => item.type === 'Sector')))
            setSelectedTopics(filterOptions(allSectorsTerms.filter(item => item.type === 'Topic')))

            setButtonDisplay(false)
            setButtonText('Pull News')
        }
        setCheckedSectors(!checkedSectors)
    }

    const companySearch = ev => {
        setCompanyTextFilter(ev.target.value)

        if (ev.target.value.length > 2) {
            axios
                .get(`/news/auto-complete/?type=company&string=${encodeURIComponent(ev.target.value)}`)
                .then(res => {
                    const filteredData = res.data.map(item => ({
                        label: item.name,
                        value: item.id,
                    }))
                    setAllCompanies(filteredData)
                })
                .catch(err => console.log(err))
        } else {
            setAllCompanies(allCompanies)
        }
    }
    return (
        <div className="analystDashboard__buttonContainer" style={{ marginLeft: '20px' }}>
            <Button
                style={{ width: '190px', borderRadius: ' 2px 2px 1px 1px', boxShadow: '0px 0px 1px 1px lightgray' }}
                className="filterHeader__searchButton "
                onClick={() => {
                    setExpanded(!expanded)
                    if (sectorsExpanded === true) {
                        setSectorsExpanded(false)
                    }
                    if (companiesExpanded === true) {
                        setCompaniesExpanded(false)
                    }
                    if (allSectorsTerms.length === 0) {
                        getIndustriesAndCompanies()
                    }
                    if (allSources.length === 0) {
                        getAllSources()
                    }
                }}
            >
                <p>Pull News Menu &#9660;</p>
            </Button>

            {expanded && (
                <div
                    className="newsPullButtonExpanded"
                    style={{ boxShadow: '0px 0px 1px 1px lightgray' }}
                    ref={wrapperRef}
                >
                    <div
                        className="pullButton"
                        style={{ border: 'thin solid #d1cccc' }}
                        onClick={() => {
                            setSectorsExpanded(!sectorsExpanded)
                            setCompaniesExpanded(!companiesExpanded)
                            setSoureceExpanded(false)
                        }}
                    >
                        <p>Sectors</p>
                    </div>
                    <div
                        className="pullButton"
                        onClick={() => {
                            setSectorsExpanded(false)
                            setCompaniesExpanded(false)
                            setSoureceExpanded(!sourceExpanded)
                        }}
                        style={{ border: 'thin solid #d1cccc' }}
                    >
                        <p>Source</p>
                    </div>

                    {sectorsExpanded && (
                        <>
                            <button
                                className="filterHeader__searchButton pullSubMenu  whiteText"
                                style={{
                                    width: '535px',
                                    marginLeft: '0.8%',
                                    opacity:
                                        selectedIndustries.length +
                                            selectedSectors.length +
                                            selectedTopics.length +
                                            selectedCompanies.length ===
                                        0
                                            ? 0.7
                                            : 1.0,
                                }}
                                // onClick={() => newsPull('sector')}
                                disabled={
                                    selectedIndustries.length +
                                        selectedSectors.length +
                                        selectedTopics.length +
                                        selectedCompanies.length ===
                                    0
                                }
                            >
                                <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                                    <span
                                        className="newsPullButtons__selectOuter"
                                        style={{ marginTop: '2%', margin: '10px 10px 10px 30px' }}
                                    >
                                        <label style={{ color: 'white', marginRight: '30px' }}>Date Since : </label>

                                        <select
                                            style={{ width: '55px', outline: 'none' }}
                                            value={selectedNormalDate}
                                            onChange={e => {
                                                setSelectedNormalDate(e.target.value)
                                            }}
                                        >
                                            {[...Array(30)].map((_, index) => (
                                                <option key={index} value={index + 1}>
                                                    {index + 1}
                                                </option>
                                            ))}
                                        </select>
                                    </span>
                                    <span
                                        style={{ margin: '10px 20px 10px 10px', width: '270px' }}
                                        onClick={() => newsPull('sector')}
                                    >
                                        {sectorButtonText}
                                    </span>
                                </div>
                            </button>
                            <div
                                className="pullSubMenu"
                                style={{ display: 'flex', boxShadow: '0px 0px 1px 1px lightgray', marginLeft: '2px' }}
                            >
                                <div style={{ width: '265px' }}>
                                    <input
                                        type="search"
                                        placeholder="Search Sectors"
                                        className="myRelationships__searchBox"
                                        style={{ width: '100%', outline: 'none' }}
                                        onChange={ev => setSectorTextFilter(ev.target.value)}
                                    />

                                    {/* <div
                                        className="newsPullButtons__selectOuter"
                                        style={{ marginTop: '2%', margin: '10px' }}
                                    >
                                        <label>Date Since : </label>

                                        <Select
                                            placeholder="Select Date"
                                            value={date}
                                            onChange={e => {
                                                setDate(e)
                                            }}
                                            styles={customStyles}
                                            options={options}
                                        />
                                    </div> */}

                                    <div
                                        className="newsPullButtons__selectedNumber"
                                        style={{
                                            margin: 'auto',
                                            width: '93%',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <label>Sectors Selected :</label>
                                        <div>
                                            {selectedIndustries.length + selectedSectors.length + selectedTopics.length}{' '}
                                            / {allSectorsTerms.length}
                                        </div>
                                    </div>

                                    <div>
                                        <input
                                            id="all_sectors"
                                            type="checkbox"
                                            className="pullCheckBox"
                                            checked={checkedSectors}
                                            onChange={() => {
                                                handleSelectAllSectors()
                                            }}
                                        />
                                        <label htmlFor={'sector'}>Select All</label>
                                    </div>
                                    <div style={{ height: '340px', overflowX: 'hidden' }}>
                                        {allSectorsTerms
                                            .filter(item =>
                                                item.label.toLowerCase().includes(sectorTextFilter.toLowerCase())
                                            )
                                            .sort((a, b) => a.label.localeCompare(b.label))
                                            .map(item => {
                                                const [selected, setSelected] = stateMap[item.type]
                                                return (
                                                    <div key={`${item.type}-${item.value}`}>
                                                        <input
                                                            id={`${item.type}-${item.value}`}
                                                            type="checkbox"
                                                            className="pullCheckBox"
                                                            checked={selected.includes(item.value)}
                                                            onChange={() => {
                                                                changeCheck(item.type, item.value)
                                                                setShowCompanies(item.value)
                                                                setShowType(
                                                                    item.type === 'Industry'
                                                                        ? 'industries'
                                                                        : item.type === 'Sector'
                                                                        ? 'sectors'
                                                                        : item.type === 'Topic'
                                                                        ? 'topics'
                                                                        : ''
                                                                )
                                                            }}
                                                            onClick={() => setButtonText('Pull News')}
                                                        />
                                                        <label htmlFor={`${item.type}-${item.value}`}>
                                                            {item.nick_name || item.label.length > 25
                                                                ? item.label.substr(0, 25) + '...'
                                                                : item.label}
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        marginLeft: '270px',
                                        // marginTop: '35px',
                                        width: '265px',
                                        background: 'white',
                                        boxShadow: '0px 0px 1.5px 1.5px lightgray',
                                    }}
                                >
                                    <input
                                        type="search"
                                        placeholder="Type to search companies"
                                        style={{ width: '100%', outline: 'none' }}
                                        className="myRelationships__searchBox"
                                        onChange={ev => companySearch(ev)}
                                    />

                                    <div
                                        className="newsPullButtons__selectedNumber"
                                        style={{
                                            margin: 'auto',
                                            width: '93%',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <label>Selected Companies :</label>
                                        <div>
                                            {selectedCompanies.length} / {allCompanies.length}
                                        </div>
                                    </div>

                                    <div>
                                        <input
                                            id="all_company"
                                            type="checkbox"
                                            className="pullCheckBox"
                                            checked={checkedCompanies}
                                            onChange={() => {
                                                handleSelectAllCompanies()
                                            }}
                                        />
                                        <label htmlFor={'company'}>Select All</label>
                                    </div>
                                    <div style={{ height: '340px', overflowX: 'hidden' }}>
                                        {allCompanies
                                            .filter(item => {
                                                const isTextMatch =
                                                    companyTextFilter.length === 0 &&
                                                    (selectedIndustries.length > 0 ||
                                                        selectedSectors.length > 0 ||
                                                        selectedTopics.length > 0)
                                                        ? //  item[showType].includes(showCompanies)
                                                          item.sectors.some(sector =>
                                                              selectedSectors.includes(sector)
                                                          ) ||
                                                          item.industries.some(industry =>
                                                              selectedIndustries.includes(industry)
                                                          ) ||
                                                          item.topics.some(topic => selectedTopics.includes(topic))
                                                        : //  companyTextFilter.length > 2
                                                          // ?
                                                          item.label
                                                // : item.label
                                                //       .toLowerCase()
                                                //       .includes(companyTextFilter.toLowerCase())

                                                return isTextMatch
                                            })
                                            .sort((a, b) => (a.label > b.label ? 1 : -1))
                                            .map(item => {
                                                const { value, label, nick_name } = item
                                                return (
                                                    <div key={'company' + value}>
                                                        <input
                                                            id={'company' + value}
                                                            type="checkbox"
                                                            className="pullCheckBox"
                                                            checked={selectedCompanies.includes(value)}
                                                            onChange={() => changeCheck('company', value)}
                                                            onClick={() => setButtonText('Pull News')}
                                                        />
                                                        <label htmlFor={'company' + value}>
                                                            {nick_name || label.length > 25
                                                                ? label.substr(0, 25) + '...'
                                                                : label}
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {sourceExpanded && (
                        <div
                            className="pullSubMenu"
                            style={{ display: 'flex', boxShadow: '0px 0px 1px px lightgray', marginLeft: '2px' }}
                        >
                            <div
                                style={{
                                    // position: 'absolute',
                                    width: '265px',
                                    background: 'white',
                                    boxShadow: '0px 0px 1.5px 1.5px lightgray',
                                }}
                            >
                                <button
                                    className="filterHeader__searchButton  whiteText"
                                    style={{ width: '100%', opacity: selectedSources.length === 0 ? 0.7 : 1.0 }}
                                    onClick={() => newsPull('source')}
                                    disabled={selectedSources.length === 0}
                                >
                                    {buttonText}
                                </button>
                                <input
                                    type="search"
                                    placeholder="Search Sources"
                                    style={{ width: '100%', outline: 'none' }}
                                    className="myRelationships__searchBox"
                                    onChange={ev => setSourceTextFilter(ev.target.value)}
                                />
                                <div
                                    className="newsPullButtons__selectOuter"
                                    style={{ marginTop: '2%', margin: '10px' }}
                                >
                                    <label>Date Since : </label>

                                    <Select
                                        value={date}
                                        onChange={e => {
                                            setDate(e)
                                        }}
                                        styles={customStyles}
                                        options={options}
                                    />
                                </div>
                                <div
                                    className="newsPullButtons__selectedNumber"
                                    style={{
                                        margin: 'auto',
                                        width: '93%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <label>Selected Sources :</label>
                                    <div>
                                        {selectedSources.length} / {allSources.length}
                                    </div>
                                </div>
                                <div>
                                    <input
                                        id="all_sources"
                                        type="checkbox"
                                        className="pullCheckBox"
                                        checked={checkedSources}
                                        onChange={() => {
                                            handleSelectAllSources()
                                        }}
                                    />
                                    <label htmlFor={'source'}>Select All</label>
                                </div>
                                <div style={{ height: '340px', overflowX: 'hidden' }}>
                                    {allSources
                                        .filter(item => {
                                            const isTextMatch = item.label
                                                .toLowerCase()
                                                .includes(sourceTextFilter.toLowerCase())
                                            return isTextMatch
                                        })
                                        .sort((a, b) => (a.label > b.label ? 1 : -1))
                                        .map(item => {
                                            const { value, label, nick_name } = item
                                            return (
                                                <div key={'source' + value}>
                                                    <input
                                                        id={'source' + value}
                                                        type="checkbox"
                                                        className="pullCheckBox"
                                                        checked={selectedSources.includes(value)}
                                                        onChange={() => changeCheck('source', value)}
                                                        onClick={() => setButtonText('Pull News')}
                                                    />
                                                    <label htmlFor={'source' + value}>
                                                        {nick_name || label.length > 25
                                                            ? label.substr(0, 25) + '...'
                                                            : label}
                                                    </label>
                                                </div>
                                            )
                                        })}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
