import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import './Header.css'
import { Button } from 'reactstrap'
import MenuModal from '../MenuModal/MenuModal'
import MenuModalDirect from '../MenuModal/MenuModalDirect'
import Logo from '../Logo/Logo'
import { FiLogOut, FiLogIn } from 'react-icons/fi'
import { getTrans } from '../../utils'
import { logoutUser } from '../../actions/AuthActions'
import { AiFillWarning } from 'react-icons/ai'

class Header extends PureComponent {
    render() {
        const { isLoggedIn, logoutUser, user, currectPath } = this.props

        return (
            <div
                className={
                    this.props.currectPath !== '/app/assistant/'
                        ? 'header position-sticky'
                        : 'assistant-header position-sticky'
                }
                style={
                    user.subscription_type === 'Direct' && user.user_type === 'Regular User'
                        ? { backgroundColor: '#061C3F' }
                        : null
                }
            >
                {/* <Logo
                    user={user}
                    white={user.subscription_type === 'Direct' && user.user_type === 'Regular User' ? true : false}
                /> */}
                <div style={{ fontSize: '22px', fontWeight: 300 }} id="header_title" />
                {/* <div>
                    <h3 className="warning-message">
                        <AiFillWarning style={{ fontSize: '20px', marginBottom: '7px' }} /> A Major push happening now
                        to production. Please expect a downtime of 24 hours
                    </h3>
                </div> */}
                {/* <div className="d-none d-lg-flex d-xl-flex">
                    {isLoggedIn ? (
                        <Button
                            onClick={logoutUser}
                            color="link"
                            className="btn d-inline-flex align-items-center"
                            style={{ textDecoration: 'none' }}
                        >
                            <span className="sidebar-icon">
                                <FiLogOut className="nav-icon" fontSize="17px" />
                            </span>
                            <strong>{getTrans('Log out')}</strong>
                        </Button>
                    ) : (
                        <Link to="/" className="btn btn-primary-dark d-inline-flex align-items-center">
                            <strong>{getTrans('Log in')}</strong>
                            <span className="sidebar-icon">
                                <FiLogIn className="nav-icon ml-2" fontSize="17px" />
                            </span>
                        </Link>
                    )} */}
                {/*<div className="letter-avatar d-none d-lg-flex d-xl-flex">*/}
                {/*<span className="letter-avatar--txt">DS</span>*/}
                {/*</div>*/}
                {/* </div> */}

                {user.subscription_type === 'Direct' && user.user_type === 'Regular User' && (
                    <div className="d-lg-none d-xl-none header__hamburgerContainer">
                        <MenuModalDirect {...this.props} />
                    </div>
                )}

                {user.subscription_type !== 'Direct' && (
                    <div className="d-lg-none d-xl-none header__hamburgerContainer">
                        <MenuModal />
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        isLoggedIn: state.auth.isLoggedIn,
    }
}

export default connect(mapStateToProps, { logoutUser })(Header)
