import React from 'react'
import { toast } from 'react-toastify'
import ImportThankYou from './ImportThankYou'

export default function ImportManual(props) {
    const { newContactForm, setNewContactForm, addContactManual, thankYou } = props

    const validateEmail = mail => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
            return true
        } else {
            return false
        }
    }

    const formValidate = () => {
        if (
            newContactForm.firstName === '' ||
            newContactForm.lastName === '' ||
            validateEmail(newContactForm.email) === false ||
            newContactForm.company === ''
        ) {
            toast.warn('First Name, Last Name, company and valid email are required')
        } else {
            addContactManual()
        }
    }

    if (thankYou) {
        return (
            <ImportThankYou
                setManualModelOpen={props.setManualModelOpen}
                user={props.user}
                getAllUserContacts={props.getAllUserContacts}
            />
        )
    }

    return (
        <div className="importModal__container">
            <div className="importModal__heading">
                <h2>Add your contacts one by one</h2>
                <p>*Those fields are required</p>
            </div>

            <div className="importModal__row importContacts__hideMobile">
                <div className="importModal__headingCell" style={{ width: '15%' }}>
                    First Name
                </div>
                <div className="importModal__headingCell" style={{ width: '15%' }}>
                    Last Name
                </div>
                <div className="importModal__headingCell" style={{ width: '25%' }}>
                    Email
                </div>
                <div className="importModal__headingCell" style={{ width: '15%' }}>
                    Company
                </div>
                <div className="importModal__headingCell" style={{ width: '20%' }}>
                    Designation
                </div>
            </div>

            <div className="importModal__row">
                <input
                    type="text"
                    placeholder="First Name *"
                    value={newContactForm.firstName}
                    className="importModal__inputCell importModal__15wide"
                    onChange={ev => setNewContactForm({ ...newContactForm, firstName: ev.target.value })}
                />

                <input
                    type="text"
                    placeholder="Last Name *"
                    value={newContactForm.lastName}
                    className="importModal__inputCell importModal__15wide"
                    onChange={ev => setNewContactForm({ ...newContactForm, lastName: ev.target.value })}
                />

                <input
                    type="text"
                    placeholder="Email *"
                    value={newContactForm.email}
                    className="importModal__inputCell importModal__25wide"
                    onChange={ev => setNewContactForm({ ...newContactForm, email: ev.target.value })}
                />

                <input
                    type="text"
                    placeholder="Company *"
                    value={newContactForm.company}
                    className="importModal__inputCell importModal__15wide"
                    onChange={ev => setNewContactForm({ ...newContactForm, company: ev.target.value })}
                />

                <input
                    type="text"
                    placeholder="Designation"
                    value={newContactForm.title}
                    className="importModal__inputCell importModal__20wide"
                    onChange={ev => setNewContactForm({ ...newContactForm, title: ev.target.value })}
                />

                <div className="importModal__plusButton" onClick={formValidate}>
                    <span>+</span>
                </div>
            </div>

            {props.tempContacts.length > 0 && (
                <div>
                    <div className="importModal__heading" style={{ marginTop: '16px' }}>
                        <h2>Imported Contacts</h2>
                    </div>
                    {props.tempContacts.map(contact => {
                        return (
                            <div className="importModal__row">
                                <div className="importModal__inputCell importModal__15wide">
                                    <span>{contact.firstName}</span>
                                </div>
                                <div className="importModal__inputCell importModal__15wide">
                                    <span>{contact.lastName}</span>
                                </div>
                                <div className="importModal__inputCell importModal__25wide">
                                    <span>{contact.email}</span>
                                </div>
                                <div className="importModal__inputCell importModal__15wide">
                                    <span>{contact.company}</span>
                                </div>
                                <div className="importModal__inputCell importModal__20wide">
                                    <span>{contact.title}</span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}
