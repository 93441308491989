import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import LoginScreen from './screens/LoginScreen/LoginScreen'
import RelationshipScreen from './screens/RelationshipScreen/RelationshipScreen'
import AnalystDashboard from './screens/AnalystDashboard/AnalystDashboard'
import RawNews from './screens/NewsFeedScreen/RawNews'
import AddNews from './screens/AddNews/AddNews'
import PublishedNews from './screens/NewsFeedScreen/PublishedNews'
import UploadReportsScreen from './screens/UploadReportsScreen/UploadReportsScreen'
import AnalystAdminEnduser from './screens/AnalystAdmin/AnalystAdminEnduser'
import AnalystAdminAddUser from './screens/AnalystAdmin/AnalystAdminAddUser'
import AnalystAdminFirm from './screens/AnalystAdmin/AnalystAdminFirm'
import AnalystAdminAddFirm from './screens/AnalystAdmin/AnalystAdminAddFirm'
import AnalystAdminSector from './screens/AnalystAdmin/AnalystAdminSector'
import AnalystAdminAddSector from './screens/AnalystAdmin/AnalystAdminAddSector'
import AnalystAdminDocuments from './screens/AnalystAdmin/AnalystAdminDocuments'
import AnalystAdminContacts from './screens/AnalystAdmin/AnalystAdminContacts'
import AnalystAdminAddContact from './screens/AnalystAdmin/AnalystAdminAddContact'
import ForgotPasswordScreen from './screens/ForgotPasswordScreen/ForgotPasswordScreen'
import ResetPasswordScreen from './screens/ResetPasswordScreen/ResetPasswordScreen'
import SetPasswordScreen from './screens/SetPasswordScreen/SetPasswordScreen'
import PasswordResetConfirmationScreen from './screens/PasswordResetConfirmationScreen/PasswordResetConfirmationScreen'
import TermsScreen from './screens/TermsScreen/TermsScreen'
import PrivacyScreen from './screens/PrivacyScreen/PrivacyScreen'
import FaqScreen from './screens/FaqScreen/FaqScreen'
import UserSignup from './screens/UserSignup/UserSignupMain'
import Unsubscribe from './screens/Unsubscribe/Unsubscribe'
import { toast, ToastContainer } from 'react-toastify'
import AppWrapper from './AppWrapper'
import DirectUserDashboard from './screens/DirectUserDashboard/DirectUserDashboard'
import TokenPage from './screens/TokenPage'
import FirmAnalystDashboard from './screens/FirmAnalyst/FirnAnalystDashboard'
import AnalystAdminTemplates from './screens/AnalystAdmin/AnalystAdminTemplates'
import AnalystCreateTemplate from './screens/AnalystAdmin/constants/AnalystCreateTemplate'
import SearchComponent from './screens/NewsFeedScreen/SearchComponent'
import MyRecommendations from './screens/NewsFeedScreen/components/MyRecommendations'
import BingSearch from './screens/NewsFeedScreen/components/BingSearch'
import FreemiumSignup from './screens/UserSignup/FreemiumSignup'
import NewsLetterTemplate from './screens/NewsFeedScreen/components/NewsLetterTemplate'
import SaasScreen from './screens/SaasScreen/SaasScreen'
import MyTopicScreen from './screens/IndustryScreen/MyTopicScreen'
import MyCompanyScreen from './screens/CompanyScreen/components/MyCompanyScreen'
import MyPreference from './screens/AnalystAdmin/MyPreference'
import InsightsAssistant from './screens/NewsFeedScreen/components/InsightsAssistant'
import UserDashboard from './screens/NewsFeedScreen/components/UserDashboard'
import MyRequests from './screens/NewsFeedScreen/components/MyRequests'
import NewNewsLetters from './screens/NewsFeedScreen/components/NewNewsletters'

const AppRouter = props => {
    return (
        <Router>
            <div>
                <ToastContainer hideProgressBar={true} autoClose={2500} position={toast.POSITION.BOTTOM_LEFT} />
                <Route path="/" exact component={LoginScreen} />
                <Route path="/forgot-password" exact component={ForgotPasswordScreen} />
                <Route path="/account/password-reset" exact component={ResetPasswordScreen} />
                <Route path="/set-password/:userId/:token" exact component={SetPasswordScreen} />
                <Route path="/reset-password-confirmation" exact component={PasswordResetConfirmationScreen} />
                <Route path="/terms" exact component={TermsScreen} />
                <Route path="/privacy" exact component={PrivacyScreen} />
                <Route path="/saas" exact component={SaasScreen} />
                <Route path="/signup" exact component={LoginScreen} />
                <Route path="/email-unsubscribe" exact component={Unsubscribe} />
                <Route path="/ChimeraToken.txt" exact component={TokenPage} />
                <Route
                    path="/app"
                    render={({ match: { url } }) =>
                        props.isLoggedIn ? (
                            <AppWrapper>
                                {/* End User View */}
                                <Route path={`${url}/sectors`} exact component={MyTopicScreen} {...props} />
                                <Route path={`${url}/companies`} exact component={MyCompanyScreen} {...props} />
                                <Route path={`${url}/competitors`} exact component={MyCompanyScreen} {...props} />

                                <Route
                                    path={`${url}/relationship/:id`}
                                    exact
                                    component={RelationshipScreen}
                                    {...props}
                                />
                                <Route path={`${url}/faq`} exact component={FaqScreen} {...props} />

                                {/* End user Direct Dashboard */}
                                <Route
                                    path={`${url}/direct-dashboard/`}
                                    exact
                                    component={DirectUserDashboard}
                                    {...props}
                                />
                                {/* Firm Analyst View */}
                                <Route
                                    path={`${url}/firm-analyst/`}
                                    exact
                                    component={FirmAnalystDashboard}
                                    {...props}
                                />
                                {/* Analyst View */}
                                <Route
                                    path={`${url}/analyst-dashboard/`}
                                    exact
                                    component={AnalystDashboard}
                                    {...props}
                                />
                                <Route path={`${url}/raw-news/`} exact component={RawNews} {...props} />

                                <Route path={`${url}/add-news`} exact component={AddNews} {...props} />
                                <Route path={`${url}/published-news/`} exact component={PublishedNews} {...props} />
                                {/* Analyst Opensearch */}
                                <Route path={`${url}/raw-search/`} exact component={SearchComponent} {...props} />
                                <Route path={`${url}/publish-search/`} exact component={SearchComponent} {...props} />
                                <Route
                                    path={`${url}/newsletter-template/`}
                                    exact
                                    component={NewsLetterTemplate}
                                    {...props}
                                />
                                <Route
                                    path={`${url}/create-template/`}
                                    exact
                                    component={AnalystCreateTemplate}
                                    {...props}
                                />
                                {/* Insights */}
                                <Route
                                    path={`${url}/recommendations/`}
                                    exact
                                    component={MyRecommendations}
                                    {...props}
                                />
                                <Route path={`${url}/search/`} exact component={SearchComponent} {...props} />
                                <Route path={`${url}/preferences/`} exact component={MyPreference} {...props} />

                                <Route
                                    path={`${url}/admin/enduser/`}
                                    exact
                                    component={AnalystAdminEnduser}
                                    {...props}
                                />
                                <Route path={`${url}/bingsearch/`} exact component={BingSearch} {...props} />
                                <Route
                                    path={`${url}/admin/add-enduser/`}
                                    exact
                                    component={AnalystAdminAddUser}
                                    {...props}
                                />
                                <Route path={`${url}/admin/enduser/:id`} exact component={MyPreference} {...props} />
                                <Route path={`${url}/admin/firm/`} exact component={AnalystAdminFirm} {...props} />
                                <Route
                                    path={`${url}/admin/add-firm/`}
                                    exact
                                    component={AnalystAdminAddFirm}
                                    {...props}
                                />
                                <Route
                                    path={`${url}/admin/add-firm/:id`}
                                    exact
                                    component={AnalystAdminAddFirm}
                                    {...props}
                                />
                                <Route path={`${url}/admin/sector/`} exact component={AnalystAdminSector} {...props} />
                                <Route
                                    path={`${url}/admin/add-sector/`}
                                    exact
                                    component={AnalystAdminAddSector}
                                    {...props}
                                />
                                <Route
                                    path={`${url}/admin/add-sector/:type/:id`}
                                    exact
                                    component={AnalystAdminAddSector}
                                    {...props}
                                />
                                <Route
                                    path={`${url}/admin/documents/`}
                                    exact
                                    component={AnalystAdminDocuments}
                                    {...props}
                                />
                                <Route
                                    path={`${url}/admin/add-documents/`}
                                    exact
                                    component={UploadReportsScreen}
                                    {...props}
                                />
                                <Route
                                    path={`${url}/admin/add-documents/:id`}
                                    exact
                                    component={UploadReportsScreen}
                                    {...props}
                                />
                                <Route
                                    path={`${url}/admin/contacts/`}
                                    exact
                                    component={AnalystAdminContacts}
                                    {...props}
                                />
                                <Route
                                    path={`${url}/admin/add-contact/`}
                                    exact
                                    component={AnalystAdminAddContact}
                                    {...props}
                                />
                                <Route
                                    path={`${url}/admin/add-contact/:id`}
                                    exact
                                    component={AnalystAdminAddContact}
                                    {...props}
                                />
                                <Route
                                    path={`${url}/admin/templates/`}
                                    exact
                                    component={AnalystAdminTemplates}
                                    {...props}
                                />
                                <Route
                                    path={`${url}/admin/create-template/`}
                                    exact
                                    component={AnalystCreateTemplate}
                                    {...props}
                                />
                                <Route path={`${url}/assistant/`} exact component={InsightsAssistant} {...props} />
                                <Route path={`${url}/dashboard/`} exact component={UserDashboard} {...props} />
                                <Route path={`${url}/request/`} exact component={MyRequests} {...props} />
                                <Route path={`${url}/newsletters/`} exact component={MyRequests} {...props} />
                            </AppWrapper>
                        ) : (
                            <AppWrapper>
                                <Route path="/" exact component={LoginScreen} />
                            </AppWrapper>
                        )
                    }
                />
            </div>
        </Router>
    )
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.auth.isLoggedIn,
    }
}

export default connect(mapStateToProps)(AppRouter)
