import React, { useEffect, useRef, useState } from 'react'
import { Input, Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import './Filter.css'
import axios from 'axios'
import { toast } from 'react-toastify'
import { getCookie } from '../../../utils'
import store from '../../../Store'
import { getYMD } from '../../../utils/dateHelpers'
import AdvanceSearch from './AdvanceSearch'
import QuerySearch from './QuerySearch'
import { Autocomplete, TextField, createFilterOptions } from '@mui/material'
import SavedQueriesModal from './SavedQueriesModal'

const token = getCookie('csrftoken')
axios.defaults.headers.common['X-CSRFToken'] = token
const usertype = store.getState().auth.user.user_type
const currentPath = window.location.pathname

export default function PowerSearchTab(props) {
    const usertype = store.getState().auth.user.user_type

    useEffect(() => {
        if (props.activeTab === 3) {
            setIsQueryEditor(true)
        } else {
            setIsQueryEditor(false)
        }
    })
    const { selectedQueries, setSelectedQueries } = props
    const { selectedStories, setSelectedStories } = props
    const { level, setLevel } = props
    const { allCategories } = props
    const { editPage, setEditPage } = props
    const { editId, setEditId } = props
    const { editqueryName, setEditQueryName } = props
    const { directRun, setDirectRun, runQuery, setRunQuery } = props
    const { isQueryEditor, setIsQueryEditor } = props
    const { isQueryEditorJson, setIsQueryEditorJson } = props
    const { editorQuery, setEditorQuery } = props
    const { advanceResponse, setAdvanceResponse, premeetingPlan, setPremeetingPlan, regionHierarchy } = props
    const {
        selectedSection,
        setSelectedSection,
        selectedUsers,
        setSelectedUsers,
        emailSubject,
        setEmailSubject,
        includeBusiness,
        setIncludeBusiness,
        includeSection,
        setIncludeSection,
    } = props
    const {
        allRegions,
        allTopics,
        allCompanies,
        setAllCompanies,
        allIndustries,
        allSubCategories,
        allSources,
        addToSelection,
        removeFromSelection,
        apiLevel,
        currentPath,
        setSelectedCompanies,
    } = props
    const {
        allCollectionName,
        collectionName,
        setCollectionName,
        collectionDescription,
        setCollectionDescription,
        collectionUsers,
        setCollectionUsers,
        allCollectionUsers,
        collectionShared,
        setCollectionShared,
        // saveModal,
        // setSaveModal,
        collectionPrivacy,
        setCollectionPrivacy,
        collectionShareList,
        // collectionModal,
        // setCollectionModal,
        collectionId,
        getAllCollections,
    } = props
    const { selectedLevel, setSelectedLevel } = props
    const filter = createFilterOptions()

    const [isLoading, setIsLoading] = useState(false)
    const filtersToAdd =
        selectedLevel === 'level0'
            ? ['Article date', 'Newstitle', 'NewsContent', 'Source']
            : [
                  'Article date',
                  'Region',
                  'Company',
                  'Country',
                  'Topic',
                  'Province',
                  'Category',
                  'Sectors',
                  'Source',
                  'Newstitle',
                  'NewsContent',
              ]

    const { preeditPage, setPreeditPage } = props
    const { getAllCompanies } = props
    useEffect(() => {
        if (directRun) {
            advanceSearch()
        }
        if (runQuery) {
            querySearch()
        }
    }, [])

    const [expandedView, setExpandedView] = useState(false)
    const [isAllSelected, setAllSelected] = useState(false)
    const [selectedNewsOnly, setSelectedNewsOnly] = useState(false)
    const [allLinkedInGroups, setAllLinkedInGroups] = useState([])
    useEffect(() => {
        isAllSelected
            ? setSelectedStories(current => [
                  ...current,
                  ...advanceResponse.data.data.filter(each => !selectedStories.includes(each)),
              ])
            : setSelectedStories([])
    }, [isAllSelected])

    const [saveModal, setSaveModal] = useState(false)
    const [saveQueryName, setSaveQueryName] = useState('')
    const [collectionModal, setCollectionModal] = useState(false)

    //dates
    var startDate = new Date()
    startDate.setDate(startDate.getDate() - 29)

    const handleLoadMore = () => {
        let data = {
            next: advanceResponse.data.next,
            size: advanceResponse.data.size,
            total: advanceResponse.data.total,
            scroll_id: advanceResponse.data.scroll_id,
        }
        axios.post('/news/scroll/', data).then(response => {
            let newsBefore = [...advanceResponse.data.data, ...response.data.data]
            setAdvanceResponse({
                ...response,
                data: {
                    total: response.data.total,
                    next: response.data.next,
                    scroll_id: response.data.scroll_id,
                    data: newsBefore,
                },
            })
        })
    }

    const advanceSearch = () => {
        setIsLoading(true)
        const payLoad = {
            filters: {
                source: {
                    operator: selectedQueries.sourceOperator,
                    values: selectedQueries.source.map(val => val.value),
                },
                [currentPath === '/app/search/'
                    ? 'articleDate'
                    : apiLevel === 'level1'
                    ? 'pull_date'
                    : 'published_date']: {
                    operator: selectedQueries.articleOperator,
                    from: selectedQueries.articleDateFrom !== '' ? selectedQueries.articleDateFrom : getYMD(startDate),
                    to: selectedQueries.articleDateTo !== '' ? selectedQueries.articleDateTo : getYMD(new Date()),
                },
                countries: {
                    operator: selectedQueries.regionOperator,
                    values: selectedQueries.region.map(value => value.label),
                },
                sectors: {
                    operator: selectedQueries.industryOperator,
                    values: selectedQueries.sectors.map(val => val.label),
                },
                company: {
                    operator: selectedQueries.companyOperator,
                    values: selectedQueries.company.map(val => val.label),
                },
                category: {
                    operator: selectedQueries.categoryOperator,
                    values: selectedQueries.category.map(val => val.label),
                },
                topics: {
                    operator: selectedQueries.topicOperator,
                    values: selectedQueries.topic.map(val => val.label),
                },
                newsTitle: {
                    operator: selectedQueries.newsTitleOperator,
                    value: selectedQueries.newsTitle,
                    fuzziness: 3,
                },
                newsContent: {
                    operator: selectedQueries.newsContentOperator,
                    regex: selectedQueries.newsContent,
                },
            },
            search_type: 'advanced',
            next: 0, // For Next Batch
            size: 15, // Batch size
        }
        axios
            .post(`/news/${currentPath === '/app/search/' ? level : apiLevel}/search/`, payLoad)
            .then(res => {
                setAdvanceResponse(res)
                setIsLoading(false)
            })
            .catch(err => console.log(err) && setIsLoading(false))

        const payLoads = {
            filters: {
                source: {
                    operator: selectedQueries.sourceOperator,
                    values: selectedQueries.source.map(val => val.value),
                },
                articleDate: {
                    operator: selectedQueries.articleOperator,
                    from: selectedQueries.articleDateFrom,
                    to: selectedQueries.articleDateTo,
                },
                countries: {
                    operator: selectedQueries.regionOperator,
                    values: selectedQueries.region.map(val => val.label),
                },
                sectors: {
                    operator: selectedQueries.industryOperator,
                    values: selectedQueries.sectors.map(val => val.label),
                },
                company: {
                    operator: selectedQueries.companyOperator,
                    values: selectedQueries.company.map(val => val.label),
                },
                category: {
                    operator: selectedQueries.categoryOperator,
                    values: selectedQueries.category.map(val => val.label),
                },
                topics: {
                    operator: selectedQueries.topicOperator,
                    values: selectedQueries.topic.map(val => val.label),
                },
                newsTitle: {
                    operator: selectedQueries.newsTitleOperator,
                    value: selectedQueries.newsTitle,
                    fuzziness: 3,
                },
                newsContent: {
                    operator: selectedQueries.newsContentOperator,
                    regex: selectedQueries.newsContent
                        ? selectedQueries.newsContent + '*'
                        : selectedQueries.newsContent,
                },
            },
            search_type: 'advanced',
            next: 0, // For Next Batch
            size: 15, // Batch size
        }

        const saveAdavnceSearchQuery = {
            query_name: editqueryName,
            query: payLoads,
            section: selectedSection === null ? '' : selectedSection,
            recipients: selectedUsers.length === 0 ? [] : selectedUsers.map(val => val.email),
            subject: emailSubject,
            has_section: includeSection,
            include_subcategory: includeBusiness,
            curation_level: selectedLevel,
            collection_name: collectionName && collectionName.label ? collectionName.label : collectionName,
            collection_description: collectionDescription,
            collection_privacy: collectionPrivacy && collectionPrivacy.value,
            collection_users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
            collection: collectionName && collectionName.value ? collectionName.value : collectionId,
        }

        if (editPage === true || directRun === true) {
            axios
                .put(`/news/search-query/${editId}/`, saveAdavnceSearchQuery)
                .then(res => {
                    console.log(res)
                    setSelectedSection('')
                    setSelectedUsers([])
                    setEmailSubject('')
                    setIncludeBusiness(false)
                    setIncludeSection(false)
                    setCollectionModal(false)
                    setSaveModal(false)
                    getAllCollections()
                })
                .catch(err => console.log(err))
        }
    }

    const generateQuery = () => {
        const payLoad = {
            filters: {
                source: {
                    operator: selectedQueries.sourceOperator,
                    values: selectedQueries.source.map(val => val.value),
                },
                articleDate: {
                    operator: selectedQueries.articleOperator,
                    from: selectedQueries.articleDateFrom,
                    to: selectedQueries.articleDateTo,
                },
                countries: {
                    operator: selectedQueries.regionOperator,
                    values: selectedQueries.region.map(val => val.label),
                },
                sectors: {
                    operator: selectedQueries.industryOperator,
                    values: selectedQueries.sectors.map(val => val.label),
                },
                company: {
                    operator: selectedQueries.companyOperator,
                    values: selectedQueries.company.map(val => val.label),
                },
                category: {
                    operator: selectedQueries.categoryOperator,
                    values: selectedQueries.category.map(val => val.label),
                },
                topics: {
                    operator: selectedQueries.topicOperator,
                    values: selectedQueries.topic.map(val => val.label),
                },
                newsTitle: {
                    operator: selectedQueries.newsTitleOperator,
                    value: selectedQueries.newsTitle,
                    fuzziness: 3,
                },
                newsContent: {
                    operator: selectedQueries.newsContentOperator,
                    regex: selectedQueries.newsContent
                        ? selectedQueries.newsContent + '*'
                        : selectedQueries.newsContent,
                },
            },
            search_type: 'advanced',
            next: 0, // For Next Batch
            size: 15, // Batch size
        }

        const saveAdvanceSearchQuery = {
            query_name: editPage ? editqueryName : saveQueryName,
            query: payLoad,
            section: selectedSection === null ? '' : selectedSection,
            recipients: selectedUsers.length === 0 ? '' : selectedUsers.map(val => val.email),
            subject: emailSubject,
            has_section: includeSection,
            include_subcategory: includeBusiness,
            curation_level: selectedLevel,
            collection_name: collectionName && collectionName.label ? collectionName.label : collectionName,
            collection_description: collectionDescription,
            collection_privacy: collectionPrivacy && collectionPrivacy.value,
            collection_users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
        }
        const collectionPayload = {
            name: collectionName,
            description: collectionDescription,
            shared: collectionPrivacy && collectionPrivacy.value,
            users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
        }
        const SaveAdvanceQuery = () => {
            axios
                .post('/news/search-query/', saveAdvanceSearchQuery)
                .then(res => {
                    toast('Query has been successfully saved')
                    clearFilters()
                    setSaveQueryName('')
                    setSelectedSection('')
                    setSelectedUsers([])
                    setEmailSubject('')
                    setIncludeBusiness(false)
                    setIncludeSection(false)
                    setSaveModal(false)
                    setCollectionModal(false)
                    getAllCollections()
                })
                .catch(err => console.log(err))
        }
        if (
            editPage === false &&
            saveQueryName.length !== 0 &&
            (selectedQueries.articleDateFrom !== '' ||
                selectedQueries.articleDateTo !== '' ||
                selectedQueries.region.length !== 0 ||
                selectedQueries.company.length !== 0 ||
                selectedQueries.topic.length !== 0 ||
                selectedQueries.sectors.length !== 0 ||
                selectedQueries.category.length !== 0 ||
                selectedQueries.newsTitle !== '' ||
                selectedQueries.newsContent !== '' ||
                selectedQueries.source.length !== 0)
        ) {
            if (collectionModal) {
                axios
                    .post('/news/collection/', collectionPayload)
                    .then(res => {
                        if (res.data && res.data.id) {
                            saveAdvanceSearchQuery.collection = res.data.id
                            SaveAdvanceQuery()
                        }
                    })
                    .catch(err => console.log(err))
            } else {
                saveAdvanceSearchQuery.collection =
                    collectionName && collectionName.value ? collectionName.value : collectionId
                SaveAdvanceQuery()
            }
        }

        // edit saved query

        const updateAdvanceQuery = () => {
            axios
                .put(`/news/search-query/${editId}/`, saveAdvanceSearchQuery)
                .then(res => {
                    console.log(res)
                    setSaveModal(false)
                    toast('Query has been successfully updated')
                    setEditId('')
                    setSaveQueryName('')
                    setEditPage(false)
                    clearFilters()
                    setCollectionModal(false)
                    getAllCollections()
                })
                .catch(err => console.log(err))
        }
        if (
            editPage === true &&
            (selectedQueries.articleDateFrom !== '' ||
                selectedQueries.articleDateTo !== '' ||
                selectedQueries.region.length !== 0 ||
                selectedQueries.company.length !== 0 ||
                selectedQueries.topic.length !== 0 ||
                selectedQueries.sectors.length !== 0 ||
                selectedQueries.category.length !== 0 ||
                selectedQueries.newsTitle !== '' ||
                selectedQueries.newsContent !== '' ||
                selectedQueries.source.length !== 0)
        ) {
            if (collectionModal) {
                axios
                    .post('/news/collection/', collectionPayload)
                    .then(res => {
                        if (res.data && res.data.id) {
                            saveAdvanceSearchQuery.collection = res.data.id
                            updateAdvanceQuery()
                        }
                    })
                    .catch(err => console.log(err))
            } else {
                saveAdvanceSearchQuery.collection =
                    collectionName && collectionName.value ? collectionName.value : collectionId
                updateAdvanceQuery()
            }
        }
    }

    ///querySearch
    const querySearch = () => {
        setAdvanceResponse({ data: [], next: null })
        setIsLoading(true)
        const companyRegex = /"([^"]*)"/g
        const matches = isQueryEditorJson.match(companyRegex)

        const companies = matches ? matches.filter(match => match !== '') : []

        const outputArray = companies.map(element => element.replace(/"/g, ''))

        setSelectedCompanies(allCompanies.map(val => val.label).filter(company => outputArray.includes(company)))

        const payload = {
            query: isQueryEditorJson,
            search_type: 'query',
            next: 0,
            size: 15,
        }

        axios
            .post(
                `/news/${
                    (usertype !== 'Regular User' && currentPath === '/app/search/') ||
                    currentPath === '/app/raw-search/'
                        ? selectedLevel
                        : apiLevel
                }/search/`,
                payload
            )
            .then(res => {
                setAdvanceResponse([])
                setAdvanceResponse(res)
            })
            .catch(err => console.log(err))
            .finally(() => {
                setIsLoading(false)
            })
    }

    ///savequerySearch
    const saveQuerySearch = () => {
        const payLoad = {
            filters: {
                source: {
                    operator: selectedQueries.sourceOperator,
                    values: selectedQueries.source.map(val => val.value),
                },
                articleDate: {
                    operator: selectedQueries.articleOperator,
                    from: selectedQueries.articleDateFrom,
                    to: selectedQueries.articleDateTo,
                },
                countries: {
                    operator: selectedQueries.regionOperator,
                    values: selectedQueries.region.map(val => val.label),
                },
                sectors: {
                    operator: selectedQueries.industryOperator,
                    values: selectedQueries.sectors.map(val => val.label),
                },
                company: {
                    operator: selectedQueries.companyOperator,
                    values: selectedQueries.company.map(val => val.label),
                },
                category: {
                    operator: selectedQueries.categoryOperator,
                    values: selectedQueries.category.map(val => val.label),
                },
                topics: {
                    operator: selectedQueries.topicOperator,
                    values: selectedQueries.topic.map(val => val.label),
                },
                newsTitle: {
                    operator: selectedQueries.newsTitleOperator,
                    value: selectedQueries.newsTitle,
                    fuzziness: 3,
                },
                newsContent: {
                    operator: selectedQueries.newsContentOperator,
                    regex: '*' + selectedQueries.newsContent + '*',
                },
            },
            search_type: 'advanced',
            next: 0, // For Next Batch
            size: 15, // Batch size
        }
        const collectionPayload = {
            name: collectionName,
            description: collectionDescription,
            shared: collectionPrivacy && collectionPrivacy.value,
            users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
        }

        if (editPage === false) {
            const saveQuery = {
                query_name: editPage ? editqueryName : saveQueryName,
                // query: payLoad,
                query_string: isQueryEditorJson,
                section: '',
                subject: emailSubject,
                has_section: includeSection,
                include_subcategory: includeBusiness,
                curation_level: selectedLevel,
                collection_name: collectionName && collectionName.label ? collectionName.label : collectionName,
                collection_description: collectionDescription,
                collection_privacy: collectionPrivacy && collectionPrivacy.value,
                collection_users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
            }
            const updateSaveQuery = () => {
                axios
                    .post('/news/search-query/', saveQuery)
                    .then(res => {
                        setSaveModal(!saveModal)
                        toast('Query has been successfully saved')
                        clearFilters()
                        setSaveQueryName('')
                        setIsQueryEditorJson('')
                        setSelectedSection('')
                        setSaveModal(false)
                        setSelectedUsers([])
                        setEmailSubject('')
                        setIncludeBusiness(false)
                        setIncludeSection(false)
                        setSaveModal(false)
                        setCollectionModal(false)
                        getAllCollections()
                    })
                    .catch(err => console.log(err))
            }
            if (saveQueryName !== '') {
                if (collectionModal) {
                    axios
                        .post('/news/collection/', collectionPayload)
                        .then(res => {
                            if (res.data && res.data.id) {
                                saveQuery.collection = res.data.id
                                updateSaveQuery()
                            }
                        })
                        .catch(err => console.log(err))
                } else {
                    saveQuery.collection = collectionName && collectionName.value ? collectionName.value : collectionId
                    updateSaveQuery()
                }
            }
        }

        if (editPage === true) {
            const saveEditQuery = {
                // query: editorQuery,
                query_name: editqueryName,
                query_string: isQueryEditorJson,
                section: selectedSection === null ? '' : selectedSection,
                recipients: selectedUsers.length === 0 ? [] : selectedUsers.map(val => val.email),
                subject: emailSubject,
                has_section: includeSection,
                include_subcategory: includeBusiness,
                curation_level: selectedLevel,
                collection_name: collectionName && collectionName.label ? collectionName.label : collectionName,
                collection_description: collectionDescription,
                collection_privacy: collectionPrivacy && collectionPrivacy.value,
                collection_users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
                collection: collectionName && collectionName.value ? collectionName.value : collectionId,
            }

            const updateSaveEditQuery = () => {
                axios
                    .put(`/news/search-query/${editId}/`, saveEditQuery)
                    .then(res => {
                        toast('Query has been successfully updated')
                        setAdvanceResponse({ data: [], next: null })
                        setEditPage(false)
                        setIsQueryEditorJson('')
                        setSelectedSection('')
                        setSelectedUsers([])
                        setEmailSubject('')
                        setIncludeBusiness(false)
                        setIncludeSection(false)
                        setSaveModal(false)
                        setCollectionModal(false)
                        getAllCollections()
                    })
                    .catch(err => console.log(err))
            }
            if (collectionModal) {
                axios.post('/news/collection/', collectionPayload).then(res => {
                    if (res.data && res.data.id) {
                        saveEditQuery.collection = res.data.id
                        updateSaveEditQuery()
                    }
                })
            } else {
                updateSaveEditQuery()
            }
        }
    }

    ///updatequerysearch
    const updateQuerySearch = () => {
        const saveEditQuery = {
            // query: editorQuery,
            query_name: editqueryName,
            query_string: isQueryEditorJson,
            section: selectedSection === null ? '' : selectedSection,
            recipients: selectedUsers.length === 0 ? [] : selectedUsers.map(val => val.email),
            subject: emailSubject,
            has_section: includeSection,
            include_subcategory: includeBusiness,
            curation_level: selectedLevel,
            collection_name: collectionName && collectionName.label ? collectionName.label : collectionName,
            collection_description: collectionDescription,
            collection_privacy: collectionPrivacy && collectionPrivacy.value,
            collection_users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
            collection: collectionName && collectionName.value ? collectionName.value : collectionId,
        }
        axios
            .put(`/news/search-query/${editId}/`, saveEditQuery)
            .then(res => {
                toast('Query has been successfully updated')
                setAdvanceResponse({ data: [], next: null })
                setEditPage(false)
                setIsQueryEditorJson('')
                setSelectedSection('')
                setSelectedUsers([])
                setEmailSubject('')
                setIncludeBusiness(false)
                setIncludeSection(false)
                setSaveModal(false)
                setCollectionModal(false)
                getAllCollections()
            })
            .catch(err => console.log(err))
    }

    ////predefined query

    const predefinedSearch = () => {
        const saveEditQuery = {
            // query: editorQuery,
            query_name: editPage ? editqueryName : preeditPage ? saveQueryName : '',
            query_string: isQueryEditorJson,
            section: selectedSection === null ? '' : selectedSection,
            recipients: selectedUsers.length === 0 ? [] : selectedUsers.map(val => val.email),
            subject: emailSubject,
            has_section: includeSection,
            include_subcategory: includeBusiness,
            curation_level: selectedLevel,
            collection_name: collectionName && collectionName.label ? collectionName.label : collectionName,
            collection_description: collectionDescription,
            collection_privacy: collectionPrivacy && collectionPrivacy.value,
            collection_users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
        }
        axios
            .post('/news/search-query/', saveEditQuery)
            .then(res => {
                toast('Query has been successfully updated')
                setAdvanceResponse({ data: [], next: null })
                setPreeditPage(false)
                setIsQueryEditorJson('')
                setSelectedSection('')
                setSelectedUsers([])
                setEmailSubject('')
                setIncludeBusiness(false)
                setIncludeSection(false)
            })
            .catch(err => console.log(err))
    }

    const clearFilters = e => {
        setEditPage(false)
        setDirectRun(false)
        setRunQuery(false)
        setAdvanceResponse({ data: [], next: null })
        setSelectedQueries({
            ...selectedQueries,
            articleDateFrom: '',
            articleOperator: 'BETWEEN',
            articleDateTo: '',
            region: [],
            regionOperator: 'IS',
            sectors: [],
            industryOperator: 'IS',
            company: [],
            companyOperator: 'IS',
            category: [],
            categoryOperator: 'IS',
            topic: [],
            topicOperator: 'IS',
            newsTitle: '',
            newsTitleOperator: 'CONTAINS',
            newsContent: '',
            newsContentOperator: 'CONTAINS',
            source: [],
            sourceOperator: 'IS',
        })
    }

    return (
        <>
            <div>
                {!isQueryEditor ? (
                    <AdvanceSearch
                        advanceResponse={advanceResponse}
                        isAllSelected={isAllSelected}
                        setAllSelected={setAllSelected}
                        expandedView={expandedView}
                        setExpandedView={setExpandedView}
                        selectedNewsOnly={selectedNewsOnly}
                        setSelectedNewsOnly={setSelectedNewsOnly}
                        allCategories={allCategories}
                        allLinkedInGroups={allLinkedInGroups}
                        selectedStories={selectedStories}
                        addToSelection={addToSelection}
                        removeFromSelection={removeFromSelection}
                        usertype={usertype}
                        isLoading={isLoading}
                        handleLoadMore={handleLoadMore}
                        selectedQueries={selectedQueries}
                        level={level}
                        setLevel={setLevel}
                        setSelectedQueries={setSelectedQueries}
                        startDate={startDate}
                        allRegions={allRegions}
                        allCompanies={allCompanies}
                        setAllCompanies={setAllCompanies}
                        allTopics={allTopics}
                        allIndustries={allIndustries}
                        allSubCategories={allSubCategories}
                        allSources={allSources}
                        advanceSearch={advanceSearch}
                        clearFilters={clearFilters}
                        saveModal={saveModal}
                        setSaveModal={setSaveModal}
                        editPage={editPage}
                        premeetingPlan={premeetingPlan}
                        setPremeetingPlan={setPremeetingPlan}
                        regionHierarchy={regionHierarchy}
                        currentPath={currentPath}
                        getAllCompanies={getAllCompanies}
                    />
                ) : (
                    <QuerySearch
                        setAdvanceResponse={setAdvanceResponse}
                        advanceResponse={advanceResponse}
                        isAllSelected={isAllSelected}
                        setAllSelected={setAllSelected}
                        expandedView={expandedView}
                        setExpandedView={setExpandedView}
                        selectedNewsOnly={selectedNewsOnly}
                        setSelectedNewsOnly={setSelectedNewsOnly}
                        allCategories={allCategories}
                        allLinkedInGroups={allLinkedInGroups}
                        selectedStories={selectedStories}
                        setSelectedStories={setSelectedStories}
                        addToSelection={addToSelection}
                        removeFromSelection={removeFromSelection}
                        usertype={usertype}
                        isLoading={isLoading}
                        handleLoadMore={handleLoadMore}
                        isQueryEditor={isQueryEditor}
                        isQueryEditorJson={isQueryEditorJson}
                        setIsQueryEditorJson={setIsQueryEditorJson}
                        filtersToAdd={filtersToAdd}
                        querySearch={querySearch}
                        updateQuerySearch={updateQuerySearch}
                        saveModal={saveModal}
                        setSaveModal={setSaveModal}
                        allRegions={allRegions}
                        allCompanies={allCompanies}
                        allTopics={allTopics}
                        allIndustries={allIndustries}
                        allSubCategories={allSubCategories}
                        allSources={allSources}
                        editPage={editPage}
                        setEditPage={setEditPage}
                        setSelectedLevel={setSelectedLevel}
                        selectedLevel={selectedLevel}
                        preeditPage={preeditPage}
                        setPreeditPage={setPreeditPage}
                        predefinedSearch={predefinedSearch}
                        premeetingPlan={premeetingPlan}
                        setPremeetingPlan={setPremeetingPlan}
                        currentPath={currentPath}
                        getAllCompanies={getAllCompanies}
                        setAllCompanies={setAllCompanies}
                        regionHierarchy={regionHierarchy}
                    />
                )}

                {/* <Modal
                    isOpen={saveModal}
                    toggle={() => {
                        setSaveModal(!saveModal)
                    }}
                    autoFocus={false}
                >
                    <ModalHeader>Save Query Modal</ModalHeader>
                    <ModalBody>
                        <Input
                            type="text"
                            value={editPage ? editqueryName : saveQueryName}
                            onChange={e => {
                                editPage ? setEditQueryName(e.target.value) : setSaveQueryName(e.target.value)
                            }}
                            autoFocus={true}
                        />
                        <div
                            style={{
                                display: 'flex',
                                margin: 'auto',
                                paddingTop: '20px',
                                marginLeft: '269px',
                            }}
                        >
                            <Button
                                className="Cancel_btn"
                                onClick={() => {
                                    setSaveModal(false)
                                }}
                            >
                                Cancel
                            </Button>
                            <Button onClick={!isQueryEditor ? generateQuery : saveQuerySearch} className="save_btn">
                                Save
                            </Button>
                        </div>
                    </ModalBody>
                </Modal> */}
                {/* <SavedQueriesModal
                    collectionDescription={collectionDescription}
                    setCollectionDescription={setCollectionDescription}
                    collectionShared={collectionShared}
                    setCollectionShared={setCollectionShared}
                    allCollectionUsers={allCollectionUsers}
                    collectionUsers={collectionUsers}
                    setCollectionUsers={setCollectionUsers}
                    collectionName={collectionName}
                    setCollectionName={setCollectionName}
                    allCollectionName={allCollectionName}
                    editPage={editPage}
                    isQueryEditor={isQueryEditor}
                    openModal={saveModal}
                    setOpenModal={setSaveModal}
                    generateQuery={generateQuery}
                    saveQuerySearch={saveQuerySearch}
                    editqueryName={editqueryName}
                    setEditQueryName={setEditQueryName}
                    saveQueryName={saveQueryName}
                    setSaveQueryName={setSaveQueryName}
                    collectionPrivacy={collectionPrivacy}
                    setCollectionPrivacy={setCollectionPrivacy}
                    collectionShareList={collectionShareList}
                    collectionModal={collectionModal}
                    setCollectionModal={setCollectionModal}
                    isPowerSearch={true}
                /> */}
                <>
                    <Modal
                        isOpen={saveModal}
                        toggle={() => {
                            setSaveModal(!saveModal)
                        }}
                        autoFocus={false}
                    >
                        <ModalHeader style={{ width: '100%' }}>
                            <div style={{ marginTop: '10px' }} className="d-flex justify-content-between">
                                <div style={{ marginLeft: '10px' }}> Save Query Modal</div>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <TextField
                                value={editPage ? editqueryName : saveQueryName}
                                onChange={e => {
                                    editPage ? setEditQueryName(e.target.value) : setSaveQueryName(e.target.value)
                                }}
                                label="Query Name"
                                variant="outlined"
                                sx={{ width: '450px', height: '60px' }}
                            />
                            <Autocomplete
                                sx={{ width: '450px', marginTop: '20px' }}
                                options={allCollectionName}
                                autoHighlight
                                value={collectionName}
                                getOptionLabel={option => option.label}
                                renderOption={(props, option) => (
                                    <li
                                        {...props}
                                        style={{
                                            color: option.value === 'Add into New Newsletter' ? '#197bbd' : 'black',
                                        }}
                                    >
                                        {option.label}
                                    </li>
                                )}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        // sx={{ height: '40px' }}
                                        // style={{ height: 40 }}
                                        variant="outlined"
                                        label="Choose a Collection"
                                    />
                                )}
                                onChange={(event, value) => {
                                    if (value !== null && value.value === 'Add into New Newsletter') {
                                        setCollectionModal(true)
                                        setSaveModal(false)
                                        setCollectionName(null)
                                    } else {
                                        setCollectionName(value)
                                    }
                                }}
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    marginLeft: 'auto',
                                    paddingTop: '20px',
                                    marginRight: '15px',
                                    // marginLeft: '269px',
                                }}
                            >
                                <Button
                                    className="Cancel_btn"
                                    onClick={() => {
                                        setSaveModal(false)
                                        // setCollectionModal(false)
                                    }}
                                >
                                    Cancel
                                </Button>

                                <Button onClick={!isQueryEditor ? generateQuery : saveQuerySearch} className="save_btn">
                                    Save
                                </Button>
                            </div>
                        </ModalBody>
                    </Modal>
                    <Modal
                        isOpen={collectionModal}
                        toggle={() => {
                            setCollectionModal(!collectionModal)
                        }}
                        // size="lg"
                    >
                        <ModalHeader style={{ width: '100%' }}>
                            <div style={{ marginTop: '10px' }} className="d-flex justify-content-between">
                                <div style={{ marginLeft: '10px' }}>Collection Details</div>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <TextField
                                value={collectionName}
                                onChange={e => {
                                    setCollectionName(e.target.value)
                                }}
                                label="Title"
                                variant="outlined"
                                sx={{ width: '450px' }}
                            />
                            <TextField
                                value={collectionDescription}
                                onChange={e => {
                                    setCollectionDescription(e.target.value)
                                }}
                                label="Description"
                                variant="outlined"
                                sx={{ width: '450px', marginTop: '20px' }}
                            />
                            <Autocomplete
                                sx={{ width: '450px', marginTop: '20px' }}
                                options={collectionShareList}
                                autoHighlight
                                value={collectionPrivacy}
                                getOptionLabel={option => option.label}
                                // renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        style={{ borderRadius: '10px' }}
                                        variant="outlined"
                                        label="Shareable"
                                    />
                                )}
                                defaultValue={collectionShareList[0]} // Set your default value here
                                onChange={(event, value) => {
                                    console.log(value)
                                    // setCollectionName(value)
                                    if (value !== null) {
                                        setCollectionPrivacy(value)
                                    }
                                }}
                            />
                            {collectionPrivacy && collectionPrivacy.value === false && (
                                <Autocomplete
                                    multiple
                                    sx={{ width: '450px', marginTop: '20px' }}
                                    options={allCollectionUsers}
                                    autoHighlight
                                    value={collectionUsers}
                                    getOptionLabel={option => option.email}
                                    renderOption={(props, option) => <li {...props}>{option.email}</li>}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            // sx={{ height: '40px' }}
                                            style={{ borderRadius: '10px' }}
                                            variant="outlined"
                                            label="Users"
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        console.log(value)
                                        setCollectionUsers(value)
                                    }}
                                />
                            )}
                            <div
                                style={{
                                    display: 'flex',
                                    margin: 'auto',
                                    paddingTop: '20px',
                                    marginLeft: '269px',
                                }}
                            >
                                <Button
                                    className="Cancel_btn"
                                    onClick={() => {
                                        setCollectionModal(false)
                                    }}
                                >
                                    Cancel
                                </Button>

                                <Button onClick={!isQueryEditor ? generateQuery : saveQuerySearch} className="save_btn">
                                    Save
                                </Button>
                            </div>
                        </ModalBody>
                    </Modal>
                </>
            </div>
        </>
    )
}
