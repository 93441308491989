import React, { useState } from 'react'
import axios from 'axios'
//import SearchWhite from '../../../assets/icons/search-white.svg'
import PasswordEyeActive from '../../../assets/icons/password-eye-active.svg'
import PasswordEyeInActive from '../../../assets/icons/password-eye-inactive.svg'
import './Step1SelectSector.css'

export default function Step1form(props) {
    const [emailExists, setEmailExists] = useState(false)
    const [formValid, setFormValid] = useState({
        first_name: true,
        last_name: true,
        email: true,
        country: true,
        password: true,
        password_confirm: true,
    })
    const [passwordRevealed, setPasswordRevealed] = useState(false)
    const { userForm, setUserForm, allCategories } = props

    const formValidatePage1 = () => {
        const formTester = {
            first_name: true,
            last_name: true,
            email: true,
            country: true,
            password: true,
            password_confirm: true,
        }

        const validateEmail = mail => {
            if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
                return true
            } else {
                return false
            }
        }

        if (userForm.first_name === '') {
            formTester.first_name = false
        } else {
            formTester.first_name = true
        }

        if (userForm.last_name === '') {
            formTester.last_name = false
        } else {
            formTester.last_name = true
        }

        if (!validateEmail(userForm.email)) {
            formTester.email = false
        } else {
            formTester.email = true
        }

        if (userForm.country === '') {
            formTester.country = false
        } else {
            formTester.country = true
        }

        if (userForm.password.length < 8) {
            formTester.password = false
        } else {
            formTester.password = true
        }

        if (userForm.password_confirm === '' || userForm.password !== userForm.password_confirm) {
            formTester.password_confirm = false
        } else {
            formTester.password_confirm = true
        }

        if (
            formTester.first_name === true &&
            formTester.last_name === true &&
            formTester.email === true &&
            formTester.country === true &&
            emailExists === false &&
            formTester.password === true &&
            formTester.password_confirm === true
        ) {
            props.getSubcategoriesFromPracticeArea()
            props.setPage(2)
        } else {
            //fails validation
            setFormValid(formTester)

            //for development to skip pg 1 validation - remove after
            //props.getSubcategoriesFromPracticeArea()
            //props.setPage(2)
        }
    }

    const checkEmailExists = email => {
        axios.get(`/endusers/check-email?email=${email}`).then(response => {
            if (response.data.message === true) {
                setEmailExists(true)
            } else {
                setEmailExists(false)
            }
        })
    }

    return (
        <div className="signup__step1Container">
            <p className="signup__selectYourSector">Fill personal information</p>

            <div className="signup__formRow" style={{ marginTop: 0 }}>
                <div className="signup__formElement-left">
                    <p className="signup__label">First Name</p>
                    <input
                        type="text"
                        placeholder="User's first name"
                        className="signup__input"
                        style={formValid.first_name === true ? null : { border: 'solid 1.5px #e25b5a' }}
                        name="first name"
                        value={userForm.first_name}
                        onChange={ev =>
                            setUserForm({
                                ...userForm,
                                first_name: ev.target.value,
                            })
                        }
                    />
                    <p className={formValid.first_name === true ? 'signup__underText' : 'signup__underError'}>
                        Required Field
                    </p>
                </div>

                <div className="signup__formElement-right">
                    <p className="signup__label">Last Name</p>
                    <input
                        type="text"
                        placeholder="User's last name"
                        className="signup__input"
                        style={formValid.last_name === true ? null : { border: 'solid 1.5px #e25b5a' }}
                        name="last name"
                        value={userForm.last_name}
                        onChange={ev =>
                            setUserForm({
                                ...userForm,
                                last_name: ev.target.value,
                            })
                        }
                    />
                    <p className={formValid.last_name ? 'signup__underText' : 'signup__underError'}>Required Field</p>
                </div>
            </div>

            <div className="signup__formRow">
                <div className="signup__formElement-left" style={{ width: '70%' }}>
                    <p className="signup__label">Email</p>
                    <input
                        type="text"
                        placeholder="Will be used to log in"
                        className="signup__input"
                        style={formValid.email === true ? null : { border: 'solid 1.5px #e25b5a' }}
                        name="email"
                        value={userForm.email}
                        onChange={ev =>
                            setUserForm({
                                ...userForm,
                                email: ev.target.value,
                            })
                        }
                        onBlur={ev => checkEmailExists(ev.target.value)}
                    />
                    <p className={formValid.email ? 'signup__underText' : 'signup__underError'}>Required Field</p>
                    {emailExists && (
                        <div>
                            <p className="signup__underError">
                                This email is in use click <a href="/"> here to login</a>
                            </p>
                            <p className="signup__underError">
                                Has your firm set up your account{' '}
                                <a href="/forgot-password/?firm-user"> set password</a>
                            </p>
                        </div>
                    )}
                </div>

                <div className="signup__formElement-right" style={{ width: '30%' }}>
                    <p className="signup__label">Country</p>
                    <select
                        value={userForm.country}
                        className="signup__dropdown-full"
                        style={formValid.country === true ? null : { border: 'solid 1.5px #e25b5a' }}
                        name="country"
                        onChange={ev =>
                            setUserForm({
                                ...userForm,
                                country: ev.target.value,
                            })
                        }
                    >
                        <option value="" disabled>
                            -----
                        </option>
                        <option value="Canada">Canada</option>
                        <option value="USA">United States</option>
                    </select>
                    <p className={formValid.country ? 'signup__underText' : 'signup__underError'}>Required Field</p>
                </div>
            </div>

            <div className="signup__formRow">
                <div className="signup__formElement-full">
                    <p className="signup__label">Password</p>
                    <span className="signup__passwordContainer">
                        <input
                            type={passwordRevealed ? 'text' : 'password'}
                            className="signup__input"
                            style={formValid.password === true ? null : { border: 'solid 1.5px #e25b5a' }}
                            placeholder="use a combination of letters and numbers"
                            name="password"
                            value={userForm.password}
                            onChange={ev =>
                                setUserForm({
                                    ...userForm,
                                    password: ev.target.value,
                                })
                            }
                        />
                        <img
                            src={passwordRevealed ? PasswordEyeActive : PasswordEyeInActive}
                            alt="click to reveal / hide password"
                            className="signup__passwordEye"
                            onClick={() => setPasswordRevealed(!passwordRevealed)}
                        />
                    </span>
                    <p className={formValid.password ? 'signup__underText' : 'signup__underError'}>
                        Must have at least 8 characters
                    </p>
                </div>
            </div>
            <div className="signup__formRow">
                <div className="signup__formElement-full">
                    <p className="signup__label">Confirm Password</p>
                    <input
                        type={passwordRevealed ? 'text' : 'password'}
                        className="signup__input"
                        style={formValid.password_confirm === true ? null : { border: 'solid 1.5px #e25b5a' }}
                        placeholder="Use the same as above"
                        name="confirm password"
                        value={userForm.password_confirm}
                        onChange={ev =>
                            setUserForm({
                                ...userForm,
                                password_confirm: ev.target.value,
                            })
                        }
                    />
                    <p className={formValid.password_confirm ? 'signup__underText' : 'signup__underError'}>
                        Must match password
                    </p>
                </div>
            </div>

            <div className="signup__formRow">
                <div className="signup__formElement-full">
                    <p className="signup__label">Practice Area</p>
                    <select
                        name="role"
                        className="signup__dropdown-full"
                        value={userForm.practice_area}
                        onChange={ev => {
                            setUserForm({
                                ...userForm,
                                practice_area: Number(ev.target.value),
                            })
                        }}
                    >
                        {allCategories.map(area => {
                            return (
                                <option value={area.id} key={area.id}>
                                    {area.name}
                                </option>
                            )
                        })}
                    </select>
                </div>
            </div>

            <div className="signup__nextButton signup__nextButton-100" onClick={formValidatePage1}>
                <p>Next</p>
            </div>
        </div>
    )
}
