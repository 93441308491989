import axios from 'axios'
import { getCookie } from './'
import { LOGIN, USER_TYPE } from '../api'
import store from '../Store'
import { LOGIN_USER } from '../actions/types'
import dotProp from 'dot-prop-immutable'
import { redirectUser } from './handleRedirects'

export const loginFunction = (values, history, location) => {
    return (
        axios
            .post(LOGIN, values)
            .then(response => {
                const token = getCookie('csrftoken')
                axios.defaults.headers.common['X-CSRFToken'] = token

                // axios.get(USER_TYPE).then(userTypeResponse => {
                //     axios.get('/accounts/auth/user/').then(userResponse => {
                const user = {
                    ...response.data,
                }
                const from = dotProp.get(location, 'state.from')
                store.dispatch({
                    type: LOGIN_USER,
                    payload: user,
                })
                if (from) {
                    history.push(from)
                } else {
                    redirectUser(user, history, location.search)
                }
            })
            // })
            // })
            .catch(error => {
                console.log('login error', error.response)
                return error.response.data
            })
    )
}
