import React, { useEffect, useRef, useState } from 'react'
import './Filter.css'
import axios from 'axios'
import { AiOutlineDelete, AiFillSchedule } from 'react-icons/ai'
import { BiSearch } from 'react-icons/bi'
import { MdModeEdit } from 'react-icons/md'
import { AiOutlineSave } from 'react-icons/ai'
import { toast } from 'react-toastify'
import DataTable from 'react-data-table-component'
import EmojiPicker from 'emoji-picker-react'
import CancelIcon from '@mui/icons-material/Cancel'
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap'
import {
    Select,
    Chip,
    MenuItem,
    Grid,
    Autocomplete,
    TextField,
    createFilterOptions,
    Switch,
    Paper,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material'
import { Cron } from 'react-js-cron'
import 'react-js-cron/dist/styles.css'
import store from '../../../Store'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ListItemIcon from '@mui/material/ListItemIcon'
import ImageIcon from '@mui/icons-material/Image'
import DeleteNews from './DeleteNews'
import { FaFolderPlus, FaPlay } from 'react-icons/fa'
import { typeOf } from 'react-is'
import { FcOpenedFolder } from 'react-icons/fc'
import { CgNotes } from 'react-icons/cg'
import { LastCronValue } from '../../../utils/dashboardStrings'

const filter = createFilterOptions()

const sections = [
    { label: 'Business Events', value: 'subcategories' },
    { label: 'Company', value: 'companies' },
    { label: 'Industry', value: 'industries' },
    { label: 'Sector', value: 'sectors' },
    { label: 'Topic', value: 'topics' },
]

export default function SavedQueries(props) {
    const userId = store.getState().auth.user.id
    const userEmail = store.getState().auth.user.email
    const [allowedDropdowns, setAllowedDropdowns] = useState(['period', 'months', 'month-days', 'week-days', 'hours'])

    const {
        selectedQueries,
        setSelectedQueries,
        setActiveTab,
        usertype,
        selectedSection,
        setSelectedSection,
        selectedUsers,
        setSelectedUsers,
        emailSubject,
        setEmailSubject,
        includeSection,
        setIncludeSection,
        includeBusiness,
        setIncludeBusiness,
        selectedLevel,
        setSelectedLevel,
        currentPath,
        getAllCollections,
    } = props
    const { editPage, setEditPage } = props
    const { editQueryName, setEditQueryName } = props
    const { directRun, setDirectRun } = props
    const { isQueryEditor, setIsQueryEditor } = props
    const { isQueryEditorJson, setIsQueryEditorJson } = props
    const { editorQuery, setEditorQuery, setRunQuery } = props
    const { allRegions, allCompanies, allSubCategories, allTopics, allIndustries, allSources } = props
    const { setBasicSearchInput } = props
    const { handleQuickPopulate } = props
    const {
        collectionId,
        setCollectionId,
        collectionDescription,
        setCollectionDescription,
        collectionName,
        setCollectionName,
        collectionPrivacy,
        setCollectionPrivacy,
        collectionUsers,
        setCollectionUsers,
        allCollectionName,
        allCollectionUsers,
        collectionShareList,
    } = props
    const [search, setSearch] = useState('')
    const [templates, setTemplates] = useState([])

    const [users, setUsers] = useState([])
    const [searchQueries, setSearchQueries] = useState([])
    const [selectedValues, setSelectedValues] = useState({})
    const [scheduleModel, setScheduleModal] = useState(false)
    const [scheduleContent, setScheduleContent] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [cronTabvalue, setCronTabValue] = useState('0 0 * * 1')
    const [isEnabled, setIsEnabled] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [deleteId, setDeleteId] = useState({ id: null, query: '' })
    const [selectedCollection, setSelectedCollection] = useState(null)
    const [editCollection, setEditCollection] = useState(false)
    const [deleteCollection, setDeleteCollection] = useState(false)
    const [inputStr, setInputStr] = useState('')
    const [showPicker, setShowPicker] = useState(false)
    const [collectionQueries, setCollectionQueries] = useState([])
    const [nonCollectionQueries, setNonCollectionQueries] = useState([])

    const { editId, setEditId } = props
    useEffect(() => {
        getSearchQueries()
        getUsers()
        getTemplates()
    }, [])

    const onEmojiClick = (event, emojiObject) => {
        setInputStr(event.emoji)
        setShowPicker(false)
    }

    const getUsers = () => {
        axios.get('/endusers/firm-users/').then(res => {
            const filter = [userEmail]
            filter.forEach(email => {
                const existingUser = res.data.find(user => user.email === email)

                if (!existingUser) {
                    const newUser = {
                        id: email,
                        email: email,
                        full_name: email,
                    }

                    res.data.push(newUser)
                }
            })
            res.data.sort((a, b) => (a.email === userEmail ? -1 : b.email === userEmail ? 1 : 0))

            setUsers(res.data)
        })
    }

    const getTemplates = () => {
        axios.get('/endusers/templates/').then(response => {
            setTemplates(
                response.data
                    .filter(each => each.custom_template === false)
                    .map(each => {
                        return {
                            value: each.id,
                            label: each.name,
                            imageUrl: each.thumbnail,
                            has_sections: each.has_sections,
                        }
                    })
            )
        })
    }

    const saveChanges = row => {
        const payload = {
            id: row.id,
            query: row.query,
            query_name: row.query_name,
            template: selectedTemplate && selectedTemplate.value ? selectedTemplate.value : null,
            user_recipients: selectedUsers.map(val => val.id).filter(val => !isNaN(val) && typeof val === 'number'),
            last_runtime: row.last_runtime,
            scheduled: isEnabled,
            cron_expression: LastCronValue(cronTabvalue, '-'),
            recipients: selectedUsers.map(val => val.email),
            newsletter: 'saved_search_newsletter',
            section:
                selectedTemplate && selectedTemplate.has_sections && selectedSection ? selectedSection.value : null,
            query_string: row.query_string,
            quick_search_query: row.quick_search_query,
            subject: emailSubject,
            has_section: includeSection,
            include_subcategory: includeBusiness,
            curation_level: selectedLevel,
        }
        if (isEnabled) {
            if (
                selectedTemplate &&
                selectedTemplate.value &&
                selectedUsers.length > 0 &&
                emailSubject !== ''
                //  &&
                // ((selectedTemplate.has_sections && selectedSection) || !selectedTemplate.has_sections)
            ) {
                if (row.apscheduler_id !== null) {
                    payload.apscheduler_id = row.apscheduler_id
                }
                axios
                    .put(`/news/search-query/${row.id}/`, payload)
                    .then(res => {
                        toast('Query has been successfully updated')
                        setScheduleModal(!scheduleModel)
                        getSearchQueries()
                    })
                    .catch(err => console.log(err))
            } else {
                toast.warn('Please fill out all the fields.')
            }
        } else {
            if (row.apscheduler_id !== null) {
                payload.apscheduler_id = row.apscheduler_id
            }
            axios
                .put(`/news/search-query/${row.id}/`, payload)
                .then(res => {
                    toast('Query has been successfully updated')
                    setScheduleModal(!scheduleModel)
                    getSearchQueries()
                })
                .catch(err => console.log(err))
        }
    }

    const getSearchQueries = () => {
        axios
            .get('/news/collection/')
            .then(res => {
                const data = res.data.collections

                setCollectionQueries(data)
                const noncollectiondata = res.data.queries.filter(val =>
                    currentPath === '/app/publish-search/'
                        ? val.curation_level === 'level2' || val.curation_level === null
                        : currentPath === '/app/raw-search/'
                        ? val.curation_level !== 'level2' || val.curation_level === null
                        : val.quick_search_query !== null || val.query_string !== null
                )

                setNonCollectionQueries(noncollectiondata)
            })
            .catch(err => console.log(err))
    }

    const editIconHandle = value => {
        if (value.section) {
            setSelectedSection(value.section)
        }
        if (value.subject) {
            setEmailSubject(value.subject)
        }
        if (value.has_section) {
            setIncludeSection(value.has_section)
        }
        if (value.include_subcategory) {
            setIncludeBusiness(value.include_subcategory)
        }
        if (value.curation_level) {
            setSelectedLevel(value.curation_level)
        } else {
            setSelectedLevel(
                currentPath === '/app/search/' || currentPath === '/app/publish-search/' ? 'level2' : 'level1'
            )
        }
        if (value.collection) {
            setCollectionId(value.collection)
        }
        if (value.collection_name) {
            setCollectionName(allCollectionName.filter(val => val.label === value.collection_name)[0])
        }
        if (value.collection_description) {
            setCollectionDescription(value.collection_description)
        }
        if (value.collection_privacy) {
            setCollectionPrivacy(collectionShareList.filter(val => val.value === value.collection_privacy)[0])
        }
        if (value.collection_users) {
            setCollectionUsers(allCollectionUsers.filter(user => value.collection_users.some(f => f.value === user.id)))
        }
        if (value.recipients) {
            const resultArray = value.recipients.split(',')
            const filter = resultArray
            filter.forEach(email => {
                const existingUser = users.find(user => user.email === email)
                if (!existingUser) {
                    const newUser = {
                        id: email,
                        email: email,
                        full_name: email,
                    }
                    users.push(newUser)
                }
            })
            const currentUser = users.filter(val => resultArray.includes(val.email))
            if (currentUser.length > 0) {
                setSelectedUsers(currentUser)
            }
        }
        if (value.quick_search_query !== null && value.query_string === null) {
            setActiveTab(0)
            setEditPage(true)
            setDirectRun(false)
            setEditId(value.id)
            setEditQueryName(value.query_name)
            setBasicSearchInput(value.quick_search_query)
            setSelectedSection(value.section)
            setIncludeBusiness(value.include_subcategory)
            setIncludeSection(value.has_section)
            const populate = value.query.filters

            const sourcesFromSaved = populate.source ? populate.source.values.map(val => val) : []
            const regionfromSaved = populate.countries ? populate.countries.values.map(val => val) : []
            const companyfromSaved = populate.company ? populate.company.values.map(val => val) : []
            const sectorFromSaved = populate.sectors ? populate.sectors.values.map(val => val) : []
            const categoryFromSaved = populate.category ? populate.category.values.map(val => val) : []
            const topicFromSaved = populate.topics ? populate.topics.values.map(val => val) : []
            const dateFromSaved = populate.date ? populate.date : '29'

            if (value.recipients) {
                const resultArray = value.recipients.split(',')
                const filter = resultArray
                filter.forEach(email => {
                    const existingUser = users.find(user => user.email === email)

                    if (!existingUser) {
                        const newUser = {
                            id: email,
                            email: email,
                            full_name: email,
                        }

                        users.push(newUser)
                    }
                })
                const currentUser = users.filter(val => resultArray.includes(val.email))

                if (currentUser.length > 0) {
                    setSelectedUsers(currentUser)
                }
            }

            handleQuickPopulate(
                sourcesFromSaved,
                regionfromSaved,
                companyfromSaved,
                sectorFromSaved,
                categoryFromSaved,
                topicFromSaved,
                dateFromSaved
            )
        } else {
            if (usertype === 'Regular User' || usertype === 'Power User') {
                editQuery(value)
            } else if (value.query_string === null && value.quick_search_query === null) {
                advanceSearchPopulate(value)
            } else if (value.query_string !== null && value.quick_search_query === null) {
                editQuery(value)
            }
        }
    }

    const advanceSearchPopulate = value => {
        setActiveTab(1)
        setEditPage(true)
        setIsQueryEditor(false)
        setDirectRun(false)
        setEditId(value.id)
        setEditQueryName(value.query_name)
        const populate = value.query.filters
        const sourcesFromSaved = populate.source ? populate.source.values.map(val => val) : []
        const regionfromSaved = populate.countries ? populate.countries.values.map(val => val) : []
        const companyfromSaved = populate.company ? populate.company.values.map(val => val) : []
        const sectorsFromSaved = populate.sectors ? populate.sectors.values.map(val => val) : []
        const categoryFromSaved = populate.category ? populate.category.values.map(val => val) : []
        const topicFromSaved = populate.topics ? populate.topics.values.map(val => val) : []
        const savedNewsContent = populate.newsContent.regex
        const savedRegion = allRegions.filter(val => regionfromSaved.includes(val.label))
        const savedCompany = allCompanies.filter(val => companyfromSaved.includes(val.label))
        const savedTopic = allTopics.filter(val => topicFromSaved.includes(val.label))
        const savedCategory = allSubCategories.filter(val => categoryFromSaved.includes(val.label))
        const savedSectors = allIndustries.filter(val => sectorsFromSaved.includes(val.label))
        const savedSources = allSources.filter(val => sourcesFromSaved.includes(val.value.toUpperCase()))

        setSelectedQueries({
            ...selectedQueries,
            articleDateFrom: populate.articleDate.from,
            articleOperator: populate.articleDate.operator,
            articleDateTo: populate.articleDate.to,
            region: savedRegion,
            regionOperator: populate.countries.operator,
            sectors: savedSectors,
            industryOperator: populate.sectors.operator,
            company: savedCompany,
            companyOperator: populate.company.operator,
            category: savedCategory,
            categoryOperator: populate.category.operator,
            topic: savedTopic,
            topicOperator: populate.topics.operator,
            newsTitle: populate.newsTitle.value,
            newsTitleOperator: populate.newsTitle.operator,
            newsContent: savedNewsContent.substring(0, savedNewsContent.length - 1),
            newsContentOperator: populate.newsContent.operator,
            source: savedSources,
            sourceOperator: populate.source.operator,
        })
    }

    //directRun
    const directRunHandle = value => {
        if (value.section) {
            setSelectedSection(value.section)
        }
        if (value.subject) {
            setEmailSubject(value.subject)
        }
        if (value.has_section) {
            setIncludeSection(value.has_section)
        }
        if (value.include_subcategory) {
            setIncludeBusiness(value.include_subcategory)
        }
        if (value.curation_level) {
            setSelectedLevel(value.curation_level)
        } else {
            setSelectedLevel(
                currentPath === '/app/search/' || currentPath === '/app/publish-search/' ? 'level2' : 'level1'
            )
        }
        if (value.collection) {
            setCollectionId(value.collection)
        }
        if (value.collection_name) {
            setCollectionName(allCollectionName.filter(val => val.label === value.collection_name)[0])
        }
        if (value.collection_description) {
            setCollectionDescription(value.collection_description)
        }
        if (value.collection_privacy) {
            setCollectionPrivacy(collectionShareList.filter(val => val.value === value.collection_privacy)[0])
        }
        if (value.collection_users) {
            setCollectionUsers(allCollectionUsers.filter(user => value.collection_users.some(f => f.value === user.id)))
        }
        if (value.recipients) {
            const resultArray = value.recipients.split(',')
            const filter = resultArray
            filter.forEach(email => {
                const existingUser = users.find(user => user.email === email)
                if (!existingUser) {
                    const newUser = {
                        id: email,
                        email: email,
                        full_name: email,
                    }
                    users.push(newUser)
                }
            })
            const currentUser = users.filter(val => resultArray.includes(val.email))
            if (currentUser.length > 0) {
                setSelectedUsers(currentUser)
            }
        }
        if (value.quick_search_query !== null && value.query_string === null) {
            setActiveTab(0)
            setEditPage(true)
            setDirectRun(true)
            setEditQueryName(value.query_name)
            setBasicSearchInput(value.quick_search_query)
            setEditId(value.id)
            setSelectedSection(value.section)
            setIncludeBusiness(value.include_subcategory)
            setIncludeSection(value.has_section)
            const populate = value.query.filters

            const sourcesFromSaved = populate.source ? populate.source.values.map(val => val) : []
            const regionfromSaved = populate.countries ? populate.countries.values.map(val => val) : []
            const companyfromSaved = populate.company ? populate.company.values.map(val => val) : []
            const sectorFromSaved = populate.sectors ? populate.sectors.values.map(val => val) : []
            const categoryFromSaved = populate.category ? populate.category.values.map(val => val) : []
            const topicFromSaved = populate.topics ? populate.topics.values.map(val => val) : []
            const dateFromSaved = populate.date ? populate.date : '29'

            handleQuickPopulate(
                sourcesFromSaved,
                regionfromSaved,
                companyfromSaved,
                sectorFromSaved,
                categoryFromSaved,
                topicFromSaved,
                dateFromSaved
            )
        } else {
            if (usertype === 'Regular User') {
                runQuery(value)
            } else if (value.query_string === null && value.quick_search_query === null) {
                directRunResult(value)
            } else if (value.query_string !== null && value.quick_search_query === null) {
                runQuery(value)
            }
        }
    }

    const directRunResult = value => {
        setActiveTab(1)
        setIsQueryEditor(false)
        setEditPage(true)
        setDirectRun(true)
        setEditId(value.id)
        setEditQueryName(value.query_name)
        const populate = value.query.filters
        const sourcesFromSaved = populate.source ? populate.source.values.map(val => val) : []
        const regionfromSaved = populate.countries ? populate.countries.values.map(val => val) : []
        const companyfromSaved = populate.company ? populate.company.values.map(val => val) : []
        const sectorsFromSaved = populate.sectors ? populate.sectors.values.map(val => val) : []
        const categoryFromSaved = populate.category ? populate.category.values.map(val => val) : []
        const topicFromSaved = populate.topics ? populate.topics.values.map(val => val) : []
        const savedNewsContent = populate.newsContent.regex
        const savedRegion = allRegions.filter(val => regionfromSaved.includes(val.label))
        const savedCompany = allCompanies.filter(val => companyfromSaved.includes(val.label))
        const savedTopic = allTopics.filter(val => topicFromSaved.includes(val.label))
        const savedCategory = allSubCategories.filter(val => categoryFromSaved.includes(val.label))
        const savedSectors = allIndustries.filter(val => sectorsFromSaved.includes(val.label))
        const savedSources = allSources.filter(val => sourcesFromSaved.includes(val.value.toUpperCase()))

        setSelectedQueries({
            ...selectedQueries,
            articleDateFrom: populate.articleDate.from,
            articleOperator: populate.articleDate.operator,
            articleDateTo: populate.articleDate.to,
            region: savedRegion,
            regionOperator: populate.countries.operator,
            sectors: savedSectors,
            industryOperator: populate.sectors.operator,
            company: savedCompany,
            companyOperator: populate.company.operator,
            category: savedCategory,
            categoryOperator: populate.category.operator,
            topic: savedTopic,
            topicOperator: populate.topics.operator,
            newsTitle: populate.newsTitle.value,
            newsTitleOperator: populate.newsTitle.operator,
            newsContent: savedNewsContent.substring(0, savedNewsContent.length - 1),
            newsContentOperator: populate.newsContent.operator,
            source: savedSources,
            sourceOperator: populate.source.operator,
        })
    }

    ///delete query
    const deleteSavedQuery = () => {
        axios
            .delete(`/news/search-query/${deleteId.id}/`)
            .then(res => {
                getSearchQueries()
                toast('Query was successfully deleted.')
            })
            .catch(err => console.log(err))
            .finally(() => {
                setDeleteModalOpen(false)
            })
    }

    const deleteSavedCollection = () => {
        axios
            .delete(`/news/collection/${collectionId}/`)
            .then(res => {
                getSearchQueries()
                getAllCollections()
                toast('Collection was successfully deleted.')
            })
            .catch(err => console.log(err))
            .finally(() => {
                setDeleteCollection(false)
            })
    }

    ///edit query
    const editQuery = row => {
        setActiveTab(3)
        setEditQueryName(row.query_name)
        setEditId(row.id)
        // setIsQueryEditor(true)
        setEditPage(true)
        setDirectRun(false)
        setRunQuery(false)
        setIsQueryEditorJson(row.query_string)
        setEditorQuery(row.query)
    }

    const runQuery = row => {
        setActiveTab(3)
        setEditPage(true)
        setDirectRun(false)
        setIsQueryEditorJson(row.query_string)
        setEditorQuery(row.query)
        setRunQuery(true)
        setEditId(row.id)
        setEditQueryName(row.query_name)
    }

    const scheduling = row => {
        setScheduleContent(row)
        setScheduleModal(!scheduleModel)
        setTimeout(() => {
            const parentElement = document.querySelector('.react-js-cron-hours')
            const spanElement = parentElement.querySelector('span')

            if (parentElement && spanElement && spanElement.textContent.trim() === 'at') {
                spanElement.innerHTML = 'at hour'
            }
            setIsEnabled(row.scheduled)
            const currentTemplate = templates.filter(val => val.value === row.template)
            if (currentTemplate.length > 0) {
                setSelectedTemplate(currentTemplate[0])
            } else {
                setSelectedTemplate(null)
            }
            if (row.section) {
                const currentsection = sections.filter(val => val.value === row.section)
                setSelectedSection(currentsection[0])
            } else {
                setSelectedSection(null)
            }
            if (row.has_section) {
                setIncludeSection(true)
            } else {
                setIncludeSection(false)
            }
            if (row.include_subcategory) {
                setIncludeBusiness(true)
            } else {
                setIncludeBusiness(false)
            }
            if (row.subject) {
                if (row.subject !== '') {
                    setEmailSubject(row.subject)
                } else {
                    setEmailSubject(`${row.query_name + '-insights'}`)
                }
            } else {
                setEmailSubject(`${row.query_name + '-insights'}`)
            }
            if (row.recipients) {
                const resultArray = row.recipients.split(',')

                const filter = resultArray

                filter.forEach(email => {
                    const existingUser = users.find(user => user.email === email)

                    if (!existingUser) {
                        const newUser = {
                            id: email,
                            email: email,
                            full_name: email,
                        }

                        users.push(newUser)
                    }
                })

                const currentUser = users.filter(val => resultArray.includes(val.email))

                if (currentUser.length > 0) {
                    setSelectedUsers(currentUser)
                }
            } else {
                setSelectedUsers([])
            }
            if (row.cron_expression) {
                setCronTabValue(LastCronValue(row.cron_expression, '+'))
            } else {
                setCronTabValue('0 0 * * 1')
            }
        }, scheduleModel)
    }

    const Collection = ({ collection }) => {
        const [showQueries, setShowQueries] = useState(false)

        const toggleQueries = () => {
            setShowQueries(!showQueries)
        }

        return (
            <div>
                <div onClick={toggleQueries}>
                    <ToggleButton
                        style={{
                            width: '100%',
                            padding: '10px',
                            textAlign: 'left',
                            display: 'block',
                        }}
                    >
                        <div className="d-flex align-items-center">
                            {showQueries ? (
                                <span>
                                    {/* <FaPlay style={{ fontSize: '12px', transform: 'rotate(90deg)' }} />
                                    <FaFolderPlus style={{ fontSize: '24px', marginLeft: '5px' }} /> */}
                                    <FcOpenedFolder style={{ fontSize: '24px', marginLeft: '5px' }} />
                                </span>
                            ) : (
                                <span>
                                    {/* <FaPlay  /> */}
                                    <FcOpenedFolder style={{ fontSize: '24px', marginLeft: '5px' }} />
                                    {/* <FaFolderPlus style={{ fontSize: '24px', marginLeft: '5px' }} /> */}
                                </span>
                            )}
                            <span className="col-4" style={{ marginLeft: '10px' }}>
                                {collection.name}
                                <span>({collection.queries.length})</span>
                            </span>
                            {/* edit or delete */}
                            <span className="col" style={{ marginLeft: '175px' }}>
                                {collection.is_editable === true && (
                                    <>
                                        <span
                                            onClick={() => {
                                                setCollectionName(collection.name)
                                                setCollectionDescription(collection.description)
                                                setCollectionPrivacy(
                                                    collectionShareList.filter(
                                                        val => val.value === collection.shared
                                                    )[0]
                                                )
                                                setCollectionId(collection.id)
                                                setCollectionUsers(
                                                    allCollectionUsers.filter(item =>
                                                        collection.users.includes(item.id)
                                                    )
                                                )
                                                setEditCollection(!editCollection)
                                            }}
                                        >
                                            <MdModeEdit className={'edit_action_btn'} title="edit" />{' '}
                                        </span>
                                        <span
                                            onClick={() => {
                                                setCollectionName(collection.name)
                                                setCollectionId(collection.id)
                                                setDeleteCollection(!deleteCollection)
                                            }}
                                        >
                                            <AiOutlineDelete className={'delete_action_btn'} title="delete" />{' '}
                                        </span>
                                    </>
                                )}
                            </span>
                        </div>
                    </ToggleButton>
                </div>
                {(showQueries || search.trim() !== '') && (
                    <>
                        {collection.queries
                            .filter(each => each.query_name.toLowerCase().includes(search.toLowerCase()))
                            .map(row => (
                                <ToggleButton
                                    style={{
                                        width: '100%',
                                        padding: '10px',
                                        textAlign: 'left',
                                        display: 'block',
                                    }}
                                    onClick={() => {
                                        setCollectionName(
                                            allCollectionName.filter(val => val.label === collection.name)[0]
                                        )
                                        setCollectionId(collection.id)
                                        setCollectionDescription(collection.description)
                                        setCollectionPrivacy(
                                            collectionShareList.filter(val => val.value === collection.shared)[0]
                                        )
                                        setCollectionUsers(
                                            allCollectionUsers.filter(item => collection.users.includes(item.id))
                                        )
                                    }}
                                >
                                    <div className="d-flex align-items-center" style={{ marginLeft: '10px' }}>
                                        <CgNotes style={{ color: 'black' }} />

                                        <span className="col-4" style={{ marginLeft: '15px' }}>
                                            {row.query_name}
                                        </span>
                                        {/* edit or delete */}
                                        <span className="col" style={{ marginLeft: '135px' }}>
                                            <>
                                                <AiFillSchedule
                                                    onClick={() => scheduling(row)}
                                                    style={{
                                                        color:
                                                            row.scheduled === true &&
                                                            (row.recipients !== null &&
                                                            row.recipients
                                                                .split(',')
                                                                .map(val => userEmail.includes(val))[0] === true
                                                                ? true
                                                                : false)
                                                                ? 'green'
                                                                : 'gray',
                                                    }}
                                                    className="scheduLe_action_btn"
                                                    title="scheduling"
                                                />
                                                <BiSearch
                                                    className="search_action_btn"
                                                    onClick={() => {
                                                        directRunHandle(row)
                                                    }}
                                                    title="directrun"
                                                />
                                                <MdModeEdit
                                                    className="edit_action_btn"
                                                    onClick={() => {
                                                        editIconHandle(row)
                                                    }}
                                                    title="edit"
                                                />
                                                <AiOutlineDelete
                                                    className="delete_action_btn"
                                                    onClick={() => {
                                                        setDeleteModalOpen(!deleteModalOpen)
                                                        setDeleteId({
                                                            id: row.id,
                                                            query: row.query_name,
                                                        })
                                                    }}
                                                    title="delete"
                                                />
                                            </>
                                        </span>
                                    </div>
                                </ToggleButton>
                            ))}
                    </>
                )}
            </div>
        )
    }

    const collections = collectionQueries

    // Filter collections with queries
    const collectionsWithQueries = collections.filter(collection => collection.queries.length > 0)

    return (
        <div style={{ width: '99%', background: 'white' }}>
            {collectionQueries.length === 0 && nonCollectionQueries.length === 0 && (
                <div className="d-flex justify-content-center bg-white">
                    <p style={{ marginTop: '20px' }}>There are no records to display</p>
                </div>
            )}
            {(collectionQueries.length !== 0 || nonCollectionQueries.length !== 0) && (
                <div className="d-flex justify-content-between align-items-center" style={{ width: '98%' }}>
                    <div />

                    <input
                        type="search"
                        placeholder="Search here"
                        className="w-25 form-control"
                        value={search}
                        onChange={e => {
                            setSearch(e.target.value)
                        }}
                    />
                </div>
            )}
            {(collectionQueries.length !== 0 || nonCollectionQueries.length !== 0) && (
                <div style={{ marginTop: '15px' }}>
                    {collectionsWithQueries.map((collection, index) => (
                        <Collection key={index} collection={collection} />
                    ))}
                    {nonCollectionQueries
                        .filter(each => each.query_name.toLowerCase().includes(search.toLowerCase()))
                        .map(row => (
                            <>
                                <ToggleButton
                                    style={{
                                        width: '100%',
                                        padding: '10px',
                                        textAlign: 'left',
                                        display: 'block',
                                    }}
                                >
                                    <div className="d-flex align-items-center" style={{ marginLeft: '10px' }}>
                                        <CgNotes />
                                        <span className="col-4" style={{ marginLeft: '15px' }}>
                                            {row.query_name}
                                        </span>
                                        {/* edit or delete */}
                                        <span className="col" style={{ marginLeft: '135px' }}>
                                            <>
                                                <AiFillSchedule
                                                    onClick={() => scheduling(row)}
                                                    style={{
                                                        color:
                                                            row.scheduled === true &&
                                                            (row.recipients !== null &&
                                                            row.recipients
                                                                .split(',')
                                                                .map(val => userEmail.includes(val))[0] === true
                                                                ? true
                                                                : false)
                                                                ? 'green'
                                                                : 'gray',
                                                    }}
                                                    className="scheduLe_action_btn"
                                                    title="scheduling"
                                                />
                                                <BiSearch
                                                    className="search_action_btn"
                                                    onClick={() => {
                                                        directRunHandle(row)
                                                    }}
                                                    title="directrun"
                                                />
                                                <MdModeEdit
                                                    className="edit_action_btn"
                                                    onClick={() => {
                                                        editIconHandle(row)
                                                    }}
                                                    title="edit"
                                                />
                                                <AiOutlineDelete
                                                    className="delete_action_btn"
                                                    onClick={() => {
                                                        setDeleteModalOpen(!deleteModalOpen)
                                                        setDeleteId({
                                                            id: row.id,
                                                            query: row.query_name,
                                                        })
                                                    }}
                                                    title="delete"
                                                />
                                            </>
                                        </span>
                                    </div>
                                </ToggleButton>
                            </>
                        ))}
                </div>
            )}
            <DeleteNews
                deleteModalOpen={deleteModalOpen}
                queryName={deleteId.query}
                setDeleteModalOpen={setDeleteModalOpen}
                savedQueryDelete={true}
                handleBatchDeleteStories={deleteSavedQuery}
            />
            <div style={{ padding: '10px 20px 20px 20px' }}>
                {/* <input className="input-style" value={inputStr} onChange={e => setInputStr(e.target.value)} />
                <img
                    className="emoji-icon"
                    src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg"
                    onClick={() => setShowPicker(val => !val)}
                />
                {showPicker && <EmojiPicker onEmojiClick={onEmojiClick} />} */}
            </div>
            <Modal
                isOpen={scheduleModel}
                toggle={() => {
                    setScheduleModal(!scheduleModel)
                }}
                size="lg"
            >
                <div style={{ padding: '10px', background: 'black' }} className="d-flex justify-content-between">
                    <h3 style={{ paddingLeft: '2%', color: 'white', fontWeight: 600 }}>Scheduling NewsLetter</h3>

                    <span
                        style={{ paddingRight: '2%', cursor: 'pointer' }}
                        onClick={() => {
                            setScheduleModal(!scheduleModel)
                        }}
                    >
                        <CancelIcon style={{ color: 'white' }} />
                    </span>
                </div>
                <ModalBody>
                    <div
                        style={{
                            width: '320px',
                            border: '1px solid lightgray',
                            height: '50px',
                            marginLeft: '10px',
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <div className="form-check form-switch" style={{ marginLeft: '10px' }}>
                            <input
                                style={{ cursor: 'pointer' }}
                                title="enabled/disabled of scheduling"
                                className="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckChecked"
                                value={isEnabled}
                                checked={isEnabled ? true : false}
                                onChange={e => {
                                    setIsEnabled(current => !current)
                                }}
                            />
                            <label className="form-check-label text-black" htmlFor="flexSwitchCheckChecked">
                                {isEnabled ? 'Scheduling Enabled' : 'Scheduling Disabled'}
                            </label>
                        </div>
                    </div>

                    <div className="d-flex" style={{ padding: '10px' }}>
                        <div>
                            <div style={{ marginTop: '5px' }}>
                                <TextField
                                    disabled={isEnabled ? false : true}
                                    sx={{ width: '320px' }}
                                    multiline
                                    rows={3}
                                    value={emailSubject}
                                    onChange={e => {
                                        setEmailSubject(e.target.value)
                                    }}
                                    variant="outlined"
                                    label="Email subject"
                                />
                            </div>
                            <div style={{ marginTop: '15px' }}>
                                <Autocomplete
                                    disabled={isEnabled ? false : true}
                                    sx={{ width: '320px' }}
                                    options={templates}
                                    autoHighlight
                                    value={selectedTemplate}
                                    getOptionLabel={option => option.label}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            <img
                                                src={option.imageUrl}
                                                alt={option.label}
                                                style={{ width: '24px', marginRight: '8px' }}
                                            />
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            sx={{ height: '40px' }}
                                            style={{ height: 40 }}
                                            label="Choose a newsletter"
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        setSelectedTemplate(value)
                                        if (value === null || value.has_sections === false) {
                                            setIncludeSection(false)
                                        }
                                    }}
                                />
                            </div>

                            {/* Includes Sections and Business Events */}
                            <div style={{ marginTop: '25px' }}>
                                <div className="form-check form-switch">
                                    <label className="form-check-label text-black">
                                        {'Include Business Events in the Newsletter'}
                                    </label>
                                    <input
                                        style={{ cursor: 'pointer' }}
                                        title="include/exclude of business events in the newsletter"
                                        className="form-check-input"
                                        type="checkbox"
                                        value={includeBusiness}
                                        checked={includeBusiness ? true : false}
                                        onChange={e => {
                                            setIncludeBusiness(current => !current)
                                        }}
                                        disabled={isEnabled ? false : true}
                                    />
                                </div>
                                {selectedTemplate && selectedTemplate.has_sections && (
                                    <>
                                        <div className="form-check form-switch">
                                            <label className="form-check-label text-black">
                                                {'Include Section in the Newsletter'}
                                            </label>
                                            <input
                                                style={{ cursor: 'pointer' }}
                                                title="include/exlude of Section in the newsletter"
                                                className="form-check-input"
                                                type="checkbox"
                                                value={includeSection}
                                                checked={includeSection ? true : false}
                                                onChange={e => {
                                                    setIncludeSection(current => !current)
                                                }}
                                                disabled={isEnabled ? false : true}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>

                            {/* section */}
                            {selectedTemplate !== null && selectedTemplate.has_sections && includeSection && (
                                <div style={{ marginTop: '10px' }}>
                                    <Autocomplete
                                        disabled={isEnabled ? false : true}
                                        sx={{ width: '320px' }}
                                        options={sections}
                                        autoHighlight
                                        aria-required={selectedTemplate.has_sections ? true : false}
                                        value={selectedSection}
                                        getOptionLabel={option => option.label}
                                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                sx={{ height: '40px' }}
                                                style={{ height: 40 }}
                                                label="Choose a section type"
                                            />
                                        )}
                                        onChange={(event, value) => {
                                            setSelectedSection(value)
                                            if (value === null) {
                                                setIncludeSection(false)
                                            }
                                        }}
                                    />
                                </div>
                            )}
                            {/* users */}
                            <div
                                style={{
                                    marginTop: includeSection ? '25px' : '15px',
                                }}
                            >
                                <Autocomplete
                                    disabled={isEnabled ? false : true}
                                    sx={{ width: '320px' }}
                                    multiple
                                    options={users}
                                    value={selectedUsers}
                                    getOptionLabel={option => {
                                        if (option.label) {
                                            return option.label
                                        }

                                        return option.email
                                    }}
                                    onChange={(event, newValue) => {
                                        if (newValue && newValue.length) {
                                            const results = []

                                            newValue.forEach(value => {
                                                if (typeof value === 'string') {
                                                    function ValidateEmail(mail) {
                                                        return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)
                                                    }

                                                    if (!ValidateEmail(value)) {
                                                        toast.error(
                                                            `You have entered an invalid email address! ${value}`
                                                        )
                                                        return
                                                    }

                                                    return results.push({
                                                        email: value,
                                                    })
                                                }

                                                return results.push(value)
                                            })

                                            setSelectedUsers(results)
                                        }
                                    }}
                                    renderInput={params => (
                                        <TextField {...params} variant="outlined" label="Recipients" />
                                    )}
                                    renderTags={() => null}
                                    freeSolo
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params)
                                        const { inputValue } = params
                                        const isExisting = options.some(option => inputValue === option.email)
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                id: `Add email :   ${inputValue}`,
                                                email: inputValue,
                                            })
                                        }

                                        return filtered
                                    }}
                                    renderOption={(props, option) => (
                                        <li
                                            {...props}
                                            style={{
                                                color: option.email === userEmail ? '#197bbd' : '',
                                                title: option.email === userEmail ? 'User' : 'exiting user',
                                            }}
                                        >
                                            <span>{option.label || option.email}</span>
                                            {usertype !== 'Regular User' && option.user_category === 'LiteUser' && (
                                                <span
                                                    className="Lite"
                                                    style={{
                                                        marginLeft: '5px',
                                                        paddingLeft: '5px',
                                                        fontSize: 'small',
                                                        background: 'skyblue',
                                                        padding: '2px 5px',
                                                        borderRadius: '5px',
                                                    }}
                                                >
                                                    Lite*
                                                </span>
                                            )}
                                        </li>
                                    )}
                                />
                            </div>
                        </div>

                        {selectedUsers.length > 0 && (
                            <div
                                style={{
                                    marginTop: '-60px',
                                    marginLeft: '20px',
                                    padding: '10px',
                                    width: '400px',
                                    height:
                                        selectedTemplate !== null && selectedTemplate.has_sections ? '377px' : '300px',
                                    overflow: 'auto',
                                    border: '1px solid lightgray',
                                    borderRadius: '4px',
                                }}
                            >
                                <label style={{ fontSize: '16px' }}>Selected Users</label>
                                <Grid item>
                                    <Grid
                                        container
                                        direction="row"
                                        spacing={1}
                                        style={{ marginTop: '5px', borderTop: '1px solid lightgray' }}
                                    >
                                        {selectedUsers.map(user => (
                                            <Grid item>
                                                <Chip
                                                    key={user.id}
                                                    label={user.email}
                                                    style={{
                                                        background: typeof user.id === 'number' ? '' : '#cdc8c7',
                                                    }}
                                                    title={
                                                        typeof user.id === 'number' ? 'Existing user' : 'Custom user'
                                                    }
                                                    disabled={isEnabled ? false : true}
                                                    onDelete={() =>
                                                        setSelectedUsers(
                                                            selectedUsers.filter(currUser => currUser.id !== user.id)
                                                        )
                                                    }
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                    </div>
                    <div className="d-flex justify-content-between" style={{ padding: '10px' }}>
                        <div className="cron_tab" style={{ marginLeft: '5px' }}>
                            <label style={{ fontSize: '16px' }}>Select Schedule Timing</label>
                            <div style={{ marginTop: '10px' }}>
                                <Cron
                                    value={cronTabvalue === '* * * * *' ? '0 0 * * 1' : cronTabvalue}
                                    setValue={(newValue, metadata) => {
                                        if (metadata.selectedPeriod === 'month') {
                                            setAllowedDropdowns(['period', 'months', 'month-days', 'hours'])
                                            const updatedCronValue = newValue.replace(/(\S+)\s*$/, '*')
                                            setCronTabValue(updatedCronValue)
                                        } else {
                                            setAllowedDropdowns([
                                                'period',
                                                'months',
                                                'month-days',
                                                'week-days',
                                                'hours',
                                            ])
                                            setCronTabValue(newValue)
                                        }
                                    }}
                                    mode="single"
                                    isClearable={false}
                                    allowedDropdowns={allowedDropdowns}
                                    allowedPeriods={['month', 'week', 'day']}
                                    disabled={isEnabled ? false : true}
                                />
                            </div>
                        </div>
                    </div>

                    <ModalFooter style={{ padding: '0' }}>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                saveChanges(scheduleContent)
                            }}
                            title="save"
                        >
                            Save
                            <span style={{ fontSize: '18px', marginLeft: '5px' }}>
                                <AiOutlineSave />
                            </span>
                        </button>
                    </ModalFooter>
                </ModalBody>
            </Modal>
            <Modal
                isOpen={editCollection}
                toggle={() => {
                    setEditCollection(!editCollection)
                }}
            >
                <div style={{ padding: '10px', background: 'black' }} className="d-flex justify-content-between">
                    <h3 style={{ paddingLeft: '2%', color: 'white', fontWeight: 600 }}>Edit Collection</h3>

                    <span
                        style={{ paddingRight: '2%', cursor: 'pointer' }}
                        onClick={() => {
                            setEditCollection(!editCollection)
                        }}
                    >
                        <CancelIcon style={{ color: 'white' }} />
                    </span>
                </div>
                <ModalBody>
                    <TextField
                        value={collectionName}
                        onChange={e => {
                            setCollectionName(e.target.value)
                        }}
                        label="Title"
                        variant="outlined"
                        sx={{ width: '450px', marginTop: '20px' }}
                    />

                    {/* <button style={{ width: '60px', height: '60px', borderRadius: '50%', background: 'lightgray' }}>
                        {inputStr !== '' ? inputStr : '+'}
                    </button> */}

                    <TextField
                        value={collectionDescription}
                        onChange={e => {
                            setCollectionDescription(e.target.value)
                        }}
                        label="Description"
                        variant="outlined"
                        sx={{ width: '450px', marginTop: '20px' }}
                    />
                    <Autocomplete
                        sx={{ width: '450px', marginTop: '20px' }}
                        options={collectionShareList}
                        autoHighlight
                        value={collectionPrivacy}
                        getOptionLabel={option => option.label}
                        // renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        renderInput={params => (
                            <TextField
                                {...params}
                                style={{ borderRadius: '10px' }}
                                variant="outlined"
                                label="Privacy"
                            />
                        )}
                        onChange={(event, value) => {
                            if (value !== null) {
                                setCollectionPrivacy(value)
                            }
                        }}
                    />
                    {collectionPrivacy && collectionPrivacy.value === false && (
                        <Autocomplete
                            multiple
                            sx={{ width: '450px', marginTop: '20px' }}
                            options={allCollectionUsers}
                            autoHighlight
                            value={collectionUsers}
                            getOptionLabel={option => option.email}
                            renderOption={(props, option) => <li {...props}>{option.email}</li>}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    // sx={{ height: '40px' }}
                                    style={{ borderRadius: '10px' }}
                                    variant="outlined"
                                    label="Users"
                                />
                            )}
                            onChange={(event, value) => {
                                setCollectionUsers(value)
                            }}
                        />
                    )}
                    <div
                        style={{
                            display: 'flex',
                            margin: 'auto',
                            paddingTop: '20px',
                            marginLeft: '269px',
                        }}
                    >
                        <Button
                            className="Cancel_btn"
                            onClick={() => {
                                setEditCollection(false)
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            onClick={() => {
                                const payload = {
                                    name: collectionName,
                                    description: collectionDescription,
                                    shared: collectionPrivacy.value,
                                    users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
                                }
                                axios
                                    .put(`/news/collection/${collectionId}/`, payload)
                                    .then(res => {
                                        getSearchQueries()
                                        getAllCollections()
                                        setEditCollection(false)
                                        toast('Collection successfully updated')
                                    })
                                    .catch(err => console.log(err))
                            }}
                            className="save_btn"
                        >
                            Save
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
            {deleteCollection && (
                <DeleteNews
                    deleteModalOpen={deleteCollection}
                    queryName={collectionName}
                    setDeleteModalOpen={setDeleteCollection}
                    savedQueryDelete={true}
                    handleBatchDeleteStories={deleteSavedCollection}
                />
            )}
        </div>
    )
}
