import React, { useState } from 'react'
import axios from 'axios'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import ImportMenu from './importComponents/ImportMenu'
import ImportManual from './importComponents/ImportManual'
import { toast } from 'react-toastify'

export default function ImportContacts(props) {
    const [importPage, setImportPage] = useState('menu')
    const [manualModelOpen, setManualModelOpen] = useState(true)
    const [newContactForm, setNewContactForm] = useState({
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        title: '',
        linkedIn: '',
    })
    const [tempContacts, setTempContacts] = useState([])
    const [thankYou, setThankYou] = useState(false)

    const addContactManual = () => {
        let contactToSend = {
            firstName: newContactForm.firstName,
            lastName: newContactForm.lastName,
            email: newContactForm.email,
            company: newContactForm.company,
            linkedin: newContactForm.linkedIn,
            title: newContactForm.title,
        }
        setTempContacts([...tempContacts, newContactForm])
        axios
            .post('/endusers/contact/new/', contactToSend)
            .then(response => {
                toast('Contact Added')
                setNewContactForm({
                    firstName: '',
                    lastName: '',
                    email: '',
                    company: '',
                    title: '',
                    linkedIn: '',
                })
            })
            .catch(err => {
                toast.error('Error adding contact')
                console.log(err)
            })
    }

    return (
        <div className="importContacts__body">
            <div className="importContacts__selectionBox">
                {importPage === 'menu' && (
                    <div>
                        <ImportMenu setImportPage={setImportPage} setManualModelOpen={setManualModelOpen} />
                    </div>
                )}

                <Modal isOpen={manualModelOpen} fullscreen>
                    <ModalHeader
                        close={
                            <button className="close" onClick={() => setManualModelOpen(false)}>
                                ×
                            </button>
                        }
                    />
                    <ModalBody style={{ padding: 0, overflowY: 'scroll' }}>
                        <ImportManual
                            setImportPage={setImportPage}
                            newContactForm={newContactForm}
                            setNewContactForm={setNewContactForm}
                            addContactManual={addContactManual}
                            setManualModelOpen={setManualModelOpen}
                            tempContacts={tempContacts}
                            getAllUserContacts={props.getAllUserContacts}
                            user={props.user}
                            thankYou={thankYou}
                            setThankYou={setThankYou}
                        />
                    </ModalBody>
                    {!thankYou && (
                        <ModalFooter>
                            <div className="addNews__backAndNextButtonsRow">
                                <button
                                    style={{ width: '150px', marginRight: '16px' }}
                                    className="addNews__nextButton"
                                    onClick={() => setManualModelOpen(false)}
                                >
                                    Close
                                </button>

                                <button
                                    style={{ width: '60%' }}
                                    className={
                                        tempContacts.length === 0
                                            ? 'addNews__nextButton-inactive'
                                            : 'addNews__nextButton'
                                    }
                                    onClick={
                                        tempContacts.length === 0
                                            ? null
                                            : () => {
                                                  setManualModelOpen(false)
                                                  toast('Contacts Updated')
                                                  props.getAllUserContacts()
                                              }
                                    }
                                >
                                    Confirm
                                </button>
                            </div>
                        </ModalFooter>
                    )}
                </Modal>
            </div>
        </div>
    )
}
