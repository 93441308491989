import React, { useEffect, useState } from 'react'
import axios from 'axios'
import PasswordEyeActive from '../../../assets/icons/password-eye-active.svg'
import PasswordEyeInActive from '../../../assets/icons/password-eye-inactive.svg'
import './Step1SelectSector.css'
import AsyncSelect from 'react-select/async'
import Creatable from 'react-select/creatable'
import Select from 'react-select'

import { CircularProgress } from '@mui/material'
import { validatePassword } from '../../../utils/Passwordvalidation'
import RegionHiearchyMultiSelect from '../../NewsFeedScreen/components/RegionHiearchyMultiSelect'
import { Input } from 'reactstrap'
import { IoMdEye, IoMdEyeOff } from 'react-icons/io'

export default function FreemiumForm(props) {
    const [emailExists, setEmailExists] = useState(false)
    const [passExists, setPassExists] = useState(false)
    const [confirmPassExists, setConfirmPassExists] = useState(false)
    const [nameExists, setNameExists] = useState(false)
    const [formValid, setFormValid] = useState({
        first_name: true,
        // last_name: true,
        email: true,
        // country: true,
        password: true,
        // password_confirm: true,
        // topic: true,
        // news_type: true,
        // role: true,
    })

    const [roleInput, setRoleInput] = useState(false)
    const [regionInput, setRegionInput] = useState(false)
    const [topicInput, setTopicInput] = useState(false)
    const [practiceInput, setPracticeInput] = useState(false)
    const [passwordRevealed, setPasswordRevealed] = useState(false)
    const [passwordErrorMEssage, setPasswordErrorMessage] = useState('')
    const [nameErrorMessage, setNameErrorMessage] = useState('Required')
    const [emailEmpty, setEmailEmpty] = useState(false)
    const [regionHierarchy, setRegionHierarchy] = useState([])

    const { userForm, setUserForm, isLoader, setIsLoader } = props

    const formValidatePage = () => {
        checkFirstName(userForm.first_name)
        checkPassword(userForm.password)
        checkEmailExists(userForm.email)

        const formTester = {
            first_name: true,
            // last_name: true,
            email: true,
            // country: true,
            password: true,
            // password_confirm: true,
            // news_type: true,
            // role: true,
        }

        const validateEmail = mail => {
            if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
                return true
            } else {
                return false
            }
        }

        if (userForm.first_name === '') {
            formTester.first_name = false
        } else {
            formTester.first_name = true
        }

        // if (userForm.last_name === '') {
        //     formTester.last_name = false
        // } else {
        //     formTester.last_name = true
        // }

        if (!validateEmail(userForm.email)) {
            formTester.email = false
        } else {
            formTester.email = true
        }

        // if (userForm.country === '') {
        //     formTester.country = false
        // } else {
        //     formTester.country = true
        // }

        if (userForm.password.length < 8) {
            formTester.password = false
        } else {
            formTester.password = true
        }

        // if (userForm.password_confirm === '' || userForm.password !== userForm.password_confirm) {
        //     formTester.password_confirm = false
        // } else {
        //     formTester.password_confirm = true
        // }

        // if (userForm.news_type.length === 0) {
        //     formTester.news_type = false
        // } else {
        //     formTester.news_type = true
        // }

        if (
            formTester.first_name === true &&
            nameExists === false &&
            // formTester.last_name === true &&
            formTester.email === true &&
            // formTester.country === true &&
            emailExists === false &&
            passExists === false &&
            // confirmPassExists === false &&
            formTester.password === true
            // formTester.password_confirm === true
            // formTester.news_type === true
        ) {
            setIsLoader(true)
            props.createNewUser()
        } else {
            setFormValid(formTester)
        }
    }

    const checkEmailExists = email => {
        axios.get(`/endusers/check-email?email=${email}`).then(response => {
            if (response.data.message === true) {
                setEmailExists(true)
                setEmailEmpty(false)
            } else {
                setEmailExists(false)
                if (email.length <= 0) {
                    setEmailEmpty(true)
                } else {
                    setEmailEmpty(false)
                }
            }
        })
    }

    const checkFirstName = name => {
        if (name.length <= 0) {
            setNameExists(true)
        } else {
            setNameExists(false)
        }
    }

    const checkPassword = password => {
        const errorMessage = validatePassword(password)
        if (errorMessage) {
            setPasswordErrorMessage(errorMessage)
            setPassExists(true)
        } else {
            setPassExists(false)
        }
    }

    const checkConfirmPassword = password => {
        if (userForm.password !== password) {
            setConfirmPassExists(true)
        } else {
            setConfirmPassExists(false)
        }
    }

    const topicChange = selected => {
        if (selected !== null && selected.length > 0) {
            setUserForm({ ...userForm, news_type: selected })
            if (selected.map(item => item.label).includes('Other')) {
                setTopicInput(true)
            } else {
                setTopicInput(false)
            }
        } else {
            setUserForm({ ...userForm, news_type: [] })
        }
    }

    const [regions, setRegions] = useState([])
    const [roles, setRoles] = useState([])
    const [newsTypes, setNewsTypes] = useState([])
    const [practices, setPractices] = useState([])

    useEffect(() => {
        getAllRegions()
        getAllRoles()
        getNewsType()
        getAllPractices()
        getRegionHierarchy()
    }, [])

    const getAllRegions = () => {
        axios.get(`/news/all-regions/`).then(response => {
            response.data.push({ value: 'Other', label: 'Other', type: 'Region' })
            setRegions(response.data)
        })
    }

    const getAllRoles = () => {
        axios.get(`/accounts/user-roles/`).then(response => {
            response.data.push({ id: 'Other', role: 'Other', type: 'Role' })
            let remappedrole = response.data.map(item => {
                return { value: item.id, label: item.role, type: 'Role' }
            })
            setRoles(remappedrole)
        })
    }

    const getAllPractices = () => {
        axios.get('/endusers/all-practice-areas').then(response => {
            let remappedpractice = response.data.results.map(item => {
                return { value: item.id, label: item.name, type: 'Practice' }
            })
            setPractices(remappedpractice)
        })
    }

    const getNewsType = () => {
        Promise.all([
            // axios.get('/news/topics/').then(response =>
            //     response.data.map(item => ({
            //         value: item.id,
            //         label: item.name,
            //         type: 'topic',
            //     }))
            // ),
            axios.get('/news/all-industries-sectors/').then(response =>
                response.data.map(item => ({
                    value: item.value,
                    label: item.label,
                    type: item.type,
                }))
            ),
            //categories
            axios.get('/news/categories/').then(response =>
                response.data.results
                    .filter(item => item.subcategories.length > 0)
                    .map(item => ({
                        value: item.id,
                        label: item.name,
                        type: 'category',
                        subcategories: item.subcategories,
                    }))
            ),
            //subcategories
            // axios.get('/news/all-subcategories/').then(response =>
            //     response.data.map(item => ({
            //         value: item.value,
            //         label: item.label,
            //         type: 'Subcategory',
            //     }))
            // ),
        ])
            .then(results => {
                const combinedOptions = results.reduce(
                    (accumulator, currentArray) => [...accumulator, ...currentArray],
                    []
                )
                setNewsTypes(combinedOptions)
            })
            .catch(error => {
                console.error('Error fetching data:', error)
            })
    }

    const getRegionHierarchy = async () => {
        await axios.get('/news/region-countries-provinces/').then(response => {
            const newItem = {
                id: '',
                name: 'Other',
                countries: [],
                type: 'Region',
            }
            response.data.push(newItem)
            setRegionHierarchy(
                response.data.map(item => ({
                    ...item,
                    id: item.type + '_' + item.id,
                    countries: item.countries
                        ? item.countries.map(cty => ({
                              ...cty,
                              id: cty.type + '_' + cty.id,
                              provinces: cty.provinces
                                  ? cty.provinces.map(pro => ({
                                        ...pro,
                                        id: pro.type + '_' + pro.id,
                                    }))
                                  : [],
                          }))
                        : [],
                }))
            )
        })
    }

    const NoOptionsMessage = () => {
        return (
            <div style={{ height: '40px', color: 'red', textAlign: 'center' }}>
                Please reach Kaitongo to add your own news type{' '}
            </div>
        )
    }

    const handleChange = (newValue, text) => {
        if (newValue !== null) {
            if (newValue.__isNew__) {
                const newOption = { value: 'Other', label: 'Other' }
                if (text === 'role') {
                    setUserForm({ ...userForm, role: newOption })
                    setRoleInput(true)
                }
                if (text === 'country') {
                    setUserForm({ ...userForm, country: newOption })
                    setRegionInput(true)
                }
                if (text === 'practice') {
                    setUserForm({ ...userForm, practice_area: newOption })
                    setPracticeInput(true)
                }
            } else {
                if (text === 'role') {
                    setUserForm({ ...userForm, role: newValue })
                }
                if (text === 'country') {
                    setUserForm({ ...userForm, country: newValue })
                }
                if (text === 'practice') {
                    setUserForm({ ...userForm, practice_area: newValue })
                }
                if (newValue.value === 'Other') {
                    if (text === 'role') {
                        setRoleInput(true)
                    }
                    if (text === 'country') {
                        setRegionInput(true)
                    }
                    if (text === 'practice') {
                        setPracticeInput(true)
                    }
                } else {
                    if (text === 'role') {
                        setRoleInput(false)
                    }
                    if (text === 'country') {
                        setRegionInput(false)
                    }
                    if (text === 'practice') {
                        setPracticeInput(false)
                    }
                }
            }
        } else {
            setUserForm({ ...userForm, role: [] })
            setUserForm({ ...userForm, country: [] })
            setUserForm({ ...userForm, practice_area: [] })
        }
    }

    const filterOption = (option, inputValue) => {
        return option.label.toLowerCase().startsWith(inputValue.toLowerCase())
    }
    return (
        <div className="signup__step1Container">
            {/* <p className="signup__selectYourSector">Sign up</p> */}
            <h2 className="m-25" style={{ textAlign: 'center' }}>
                Sign up
            </h2>

            <div className="signup__formRow" style={{ marginTop: '20px' }}>
                <div className="signup__formElement">
                    <p className="signup__label">
                        Email<span style={{ color: 'red' }}>*</span>
                    </p>
                    <Input
                        type="text"
                        placeholder="Email"
                        className="signup__input"
                        name="email"
                        value={userForm.email}
                        onChange={ev =>
                            setUserForm({
                                ...userForm,
                                email: ev.target.value,
                            })
                        }
                        onBlur={ev => checkEmailExists(ev.target.value)}
                        autoComplete="off"
                    />
                    {emailEmpty && <p className="signup__underError">Required Field</p>}
                    {emailExists && (
                        <div>
                            <p className="signup__underError">
                                This email is in use click <a href="/"> here to login</a>
                            </p>
                            {/* <p className="signup__underError">
                                Has your firm set up your account{' '}
                                <a href="/forgot-password/?firm-user"> set password</a>
                            </p> */}
                        </div>
                    )}
                </div>
                {/* <div className="signup__formElement-right">
                    <p className="signup__label">Role</p>
                    <Creatable
                        options={roles.sort((a, b) => (a.label > b.label ? 1 : -1))}
                        value={userForm.role}
                        // menuPlacement="top"
                        placeholder="Select Role"
                        styles={{
                            placeholder: provided => ({
                                ...provided,
                                fontSize: '13px',
                            }),
                        }}
                        onChange={value => {
                            handleChange(value, 'role')
                        }}
                        isClearable
                        formatCreateLabel={inputValue => `Other`}
                    />
                </div> */}

                {/* <div className="signup__formElement-right">
                    <p className="signup__label">
                        Country<span style={{ color: 'red' }}>*</span>
                    </p>
                <Creatable
                        options={regions.sort((a, b) => (a.label > b.label ? 1 : -1))}
                        value={userForm.country}
                        placeholder="Start Typing to Find Your Country"
                        styles={{
                            placeholder: provided => ({
                                ...provided,
                                fontSize: '13px',
                            }),
                        }}
                        onChange={value => {
                            handleChange(value, 'country')
                        }}
                        formatCreateLabel={inputValue => `Other`}
                    />
                <RegionHiearchyMultiSelect
                        regionHierarchy={regionHierarchy}
                        selectedQueries={userForm}
                        setSelectedQueries={setUserForm}
                        isSignUp={true}
                        setRegionInput={setRegionInput}
                    />
                    <p className={formValid.country ? 'signup__underText' : 'signup__underError'}>Required Field</p>
                </div> */}
            </div>
            <div className="signup__formRow">
                <div className="signup__formElement">
                    <p className="signup__label">
                        Name<span style={{ color: 'red' }}>*</span>
                    </p>
                    <Input
                        type="text"
                        placeholder="Name"
                        className="signup__input"
                        name="first name"
                        value={userForm.first_name}
                        onChange={ev =>
                            setUserForm({
                                ...userForm,
                                first_name: ev.target.value,
                            })
                        }
                        onBlur={ev => checkFirstName(ev.target.value)}
                        autoComplete="off"
                    />
                    {nameExists && <p className="signup__underError">{nameErrorMessage}</p>}
                </div>

                {/* <div className="signup__formElement-right">
                    <p className="signup__label">
                        Last Name<span style={{ color: 'red' }}>*</span>
                    </p>
                    <input
                        type="text"
                        placeholder="User's last name"
                        className="signup__input"
                        name="last name"
                        value={userForm.last_name}
                        onChange={ev =>
                            setUserForm({
                                ...userForm,
                                last_name: ev.target.value,
                            })
                        }
                    />
                    <p className={formValid.last_name ? 'signup__underText' : 'signup__underError'}>Required Field</p>
                </div> */}
            </div>

            <div className="signup__formRow">
                <div className="signup__formElement-left" />
                {/* <div className="signup__formElement-right">
                    {regionInput && (
                        <input
                            type="text"
                            placeholder="Your Country"
                            className="signup__input"
                            name="role"
                            style={{ marginTop: '1%' }}
                            value={userForm.others.country}
                            onChange={ev =>
                                setUserForm({
                                    ...userForm,
                                    others: {
                                        ...userForm.others,
                                        country: ev.target.value,
                                    },
                                })
                            }
                        />
                    )}
                </div> */}
                {/* <div className="signup__formElement-right">
                    {roleInput && (
                        <input
                            type="text"
                            placeholder="Your role"
                            className="signup__input"
                            name="role"
                            style={{ marginTop: '1%' }}
                            value={userForm.others.userroles}
                            onChange={ev =>
                                setUserForm({
                                    ...userForm,
                                    others: {
                                        ...userForm.others,
                                        userroles: ev.target.value,
                                    },
                                })
                            }
                        />
                    )}
                </div> */}
            </div>

            <div className="signup__formRow">
                <div className="signup__formElement">
                    <p className="signup__label">
                        Password<span style={{ color: 'red' }}>*</span>
                    </p>
                    <span className="signup__passwordContainer">
                        <Input
                            type={passwordRevealed ? 'text' : 'password'}
                            className="signup__input"
                            placeholder="use a combination of letters and numbers"
                            name="password"
                            value={userForm.password}
                            onChange={ev =>
                                setUserForm({
                                    ...userForm,
                                    password: ev.target.value,
                                })
                            }
                            onBlur={ev => checkPassword(ev.target.value)}
                            autocomplete="off"
                        />
                        <div
                            onClick={() => setPasswordRevealed(!passwordRevealed)}
                            className="btn btn-link  d-none d-sm-block"
                            type="button"
                        >
                            {passwordRevealed ? (
                                <IoMdEye className="pass-hidden" />
                            ) : (
                                <IoMdEyeOff className="pass-hidden" />
                            )}
                        </div>
                    </span>
                    {passExists && (
                        <p className="signup__underError mt-pass" style={{ lineHeight: '0px' }}>
                            {passwordErrorMEssage}
                        </p>
                    )}
                </div>
            </div>

            {/* <div className="signup__formRow">
                <div className="signup__formElement">
                    <p className="signup__label">
                        Confirm Password<span style={{ color: 'red' }}>*</span>
                    </p>
                    <input
                        type={passwordRevealed ? 'text' : 'password'}
                        className="signup__input"
                        placeholder="Use the same as password"
                        name="confirm password"
                        value={userForm.password_confirm}
                        onChange={ev =>
                            setUserForm({
                                ...userForm,
                                password_confirm: ev.target.value,
                            })
                        }
                        onBlur={ev => checkConfirmPassword(ev.target.value)}
                    />
                    {confirmPassExists && <p className="signup__underError">Must match password</p>}
                </div>
            </div> */}

            {/* <div className="signup__formRow">
                <div className="signup__formElement-left">
                    <p className="signup__label">
                        Types of News<span style={{ color: 'red' }}>*</span>
                    </p>
                    <Select
                        isMulti
                        options={newsTypes.sort((a, b) => (a.label > b.label ? 1 : -1))}
                        placeholder="Start Typing to Find Your News Type"
                        styles={{
                            placeholder: provided => ({
                                ...provided,
                                fontSize: '13px',
                            }),
                        }}
                        value={userForm.news_type}
                        onChange={value => {
                            topicChange(value)
                        }}
                        filterOption={filterOption}
                        components={{ NoOptionsMessage }}
                    />
                    <p className={formValid.news_type ? 'signup__underText' : 'signup__underError'}>Required Field</p>
                </div>
                <div className="signup__formElement-right">
                    <p className="signup__label">Practice Area</p>
                    <Creatable
                        options={practices.sort((a, b) => (a.label > b.label ? 1 : -1))}
                        value={userForm.practice_area}
                        // menuPlacement="top"
                        placeholder="Start Typing to Find Your Practice Area"
                        styles={{
                            placeholder: provided => ({
                                ...provided,
                                fontSize: '13px',
                            }),
                        }}
                        onChange={value => {
                            handleChange(value, 'practice')
                        }}
                        isClearable
                        formatCreateLabel={inputValue => `Other`}
                    />
                </div>
            </div>

            <div className="signup__formRow">
                <div className="signup__formElement-left" style={{ width: '100%' }}>
                    {topicInput && (
                        <input
                            type="text"
                            placeholder="Your news type"
                            className="signup__input"
                            name="role"
                            style={{ marginTop: '1%' }}
                            value={userForm.others.topic}
                            onChange={ev =>
                                setUserForm({
                                    ...userForm,
                                    others: {
                                        ...userForm.others,
                                        topic: ev.target.value,
                                    },
                                })
                            }
                        />
                    )}
                </div>
            </div>

            <div className="signup__formRow">
                <div className="signup__formElement-left" />

                <div className="signup__formElement-right">
                    {practiceInput && (
                        <input
                            type="text"
                            placeholder="Your practicearea"
                            className="signup__input"
                            name="practice"
                            style={{ marginTop: '1%' }}
                            value={userForm.others.practicearea}
                            onChange={ev =>
                                setUserForm({
                                    ...userForm,
                                    others: {
                                        ...userForm.others,
                                        practicearea: ev.target.value,
                                    },
                                })
                            }
                        />
                    )}
                </div>
            </div> */}
            <p style={{ marginLeft: '20px', fontSize: '14px' }}>
                <span style={{ color: 'red' }}>*</span> <span>Indicates required field</span>
            </p>

            <button
                className="btn d-flex"
                onClick={formValidatePage}
                disabled={isLoader ? true : false}
                style={{
                    // width: '150px',
                    textAlign: 'center',
                    background: '#05183d',
                    color: 'white',
                    margin: 'auto',
                    justifyContent: 'center',
                }}
            >
                {isLoader && (
                    <CircularProgress
                        style={{ color: 'white', fontSize: '12px', width: '20px', height: '20px', marginRight: '10px' }}
                    />
                )}
                Register
            </button>
        </div>
    )
}
