import React from 'react'
import Checked from '../../../assets/icons/checkbox_checked.png'
import UnChecked from '../../../assets/icons/checkbox_unchecked.png'
import Refresh from '../../../assets/icons/refresh-button.svg'

export default function SectorChecklist(props) {
    const { Companies, Conferences, Deals, Featured_Companies, Associations, Query } = props.checkList
    return (
        <div className="sectorChecklist__container">
            <p className="sectorChecklist__heading">Click on the item name to open the admin page</p>

            <div className="sectorChecklist__refreshButton" onClick={props.getChecklist}>
                <img src={Refresh} alt="refresh checklist" />
                <p>Refresh Checklist</p>
            </div>

            <div className="sectorChecklist__row">
                <img src={Associations ? Checked : UnChecked} alt="check" />
                <a href="/admin/" target="_blank">
                    <p>Add Associations</p>
                </a>
            </div>

            <div className="sectorChecklist__row">
                <img src={Featured_Companies ? Checked : UnChecked} alt="check" />
                <a href="/admin/" target="_blank">
                    <p>Add Featured Companies</p>
                </a>
            </div>

            <div className="sectorChecklist__row">
                <img src={Companies ? Checked : UnChecked} alt="check" />
                <a href="/admin/news/company/" target="_blank">
                    <p>Add Companies</p>
                </a>
            </div>

            <div className="sectorChecklist__row">
                <img src={Conferences ? Checked : UnChecked} alt="check" />
                <a href="/admin/core/conference/" target="_blank">
                    <p>Add Conferences</p>
                </a>
            </div>

            <div className="sectorChecklist__row">
                <img src={Deals ? Checked : UnChecked} alt="check" />
                <a href="/admin/news/competitionnews/" target="_blank">
                    <p>Add Deals</p>
                </a>
            </div>

            <div className="sectorChecklist__row">
                <img src={Query ? Checked : process.env.REACT_APP_MEDIA_URL + UnChecked} alt="check" />
                <a href="/admin/news/competitionnews/" target="_blank">
                    <p>Add Query</p>
                </a>
            </div>
        </div>
    )
}
