import React, { useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

const ConversationStarters = props => {
    const { content, index } = props
    const [openConversation, setOpenConversation] = useState(false)

    const toggleConversation = () => {
        setOpenConversation(!openConversation)
    }

    return (
        <>
            <div
                key={index}
                style={{
                    width: '95%',
                    margin: 'auto',
                    fontSize: '14px',
                    padding: '15px 0px 0px 0px',
                }}
            >
                <div
                    style={{ borderLeft: '3px solid rgb(25, 118, 210)', padding: '5px 0px 5px 10px' }}
                    // className="hover_news"
                >
                    <div
                        style={{ fontWeight: 'bold', cursor: 'pointer', fontSize: '15px' }}
                        className="d-flex align-items-center"
                        onClick={toggleConversation}
                    >
                        <span
                            style={{
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: 'vertical',
                            }}
                        >
                            {content.Name}
                        </span>
                        <span style={{ marginLeft: 'auto' }}>
                            {openConversation ? (
                                <KeyboardArrowDownIcon style={{ fontSize: '20px' }} />
                            ) : (
                                <KeyboardArrowRightIcon style={{ fontSize: '20px' }} />
                            )}
                        </span>
                    </div>
                    <div
                        style={{
                            overflow: 'hidden',
                            display: openConversation ? 'block' : '-webkit-box',
                            WebkitLineClamp: openConversation ? 'unset' : 2,
                            WebkitBoxOrient: 'vertical',
                            fontSize: '14px',
                            color: 'gray',
                            marginTop: '10px',
                        }}
                    >
                        {content.Summary}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ConversationStarters
