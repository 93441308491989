import React from 'react'
import { Grid } from '@mui/material'
import './MailModal.css'
import NewsBody from './NewsBody'
import TemplateFooter from './TemplateFooter'

export default function BLGPreview(props) {
    const {
        selectedStories,
        primaryColor,
        secondaryColor,
        title,
        primaryLogo,
        secondaryLogo,
        selectedBg,
        setSize,
        newsBodySize,
        includeSection,
        includeBusiness,
    } = props

    const logoRef = React.useRef()
    const secLogoRef = React.useRef()
    const getSize = logo => {
        if (logo === 'primary') {
            setSize(logoRef.current.offsetWidth, logoRef.current.offsetHeight, logo)
        } else {
            setSize(secLogoRef.current.offsetWidth, secLogoRef.current.offsetHeight, logo)
        }
    }
    return (
        <Grid container direction="column" style={{ fontFamily: 'Arial' }}>
            <Grid item>
                <table
                    background={selectedBg}
                    style={{
                        width: '670px',
                        height: '210px',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '100% 100%',
                    }}
                >
                    <tbody>
                        <tr>
                            <td>
                                <div
                                    style={{
                                        padding: '5px',
                                        height: '110px',
                                        width: '200px',
                                        // maxWidth: '200px',
                                        // maxHeight: '110px',
                                    }}
                                >
                                    {primaryLogo && (
                                        <img
                                            src={primaryLogo}
                                            onLoad={e => getSize('primary')}
                                            onChange={e => getSize('primary')}
                                            alt="left-logo"
                                            ref={ref => (logoRef.current = ref)}
                                            style={{
                                                maxHeight: '100%',
                                                maxWidth: '100%',
                                            }}
                                        />
                                    )}
                                </div>
                            </td>
                            <td align="right">
                                <div
                                    style={{
                                        padding: '5px',
                                        height: '110px',
                                        width: '200px',
                                        // maxWidth: '200px',
                                        // maxHeight: '110px',
                                    }}
                                >
                                    {secondaryLogo && (
                                        <img
                                            src={secondaryLogo}
                                            ref={ref => (secLogoRef.current = ref)}
                                            onLoad={e => getSize('secondary')}
                                            onChange={e => getSize('secondary')}
                                            alt="right logo"
                                            style={{
                                                maxHeight: '100%',
                                                maxWidth: '100%',
                                            }}
                                        />
                                    )}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <h2 style={{ color: 'white', textAlign: 'right', paddingRight: '20px' }}>
                                    <strong> {title} </strong>
                                </h2>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Grid>
            <NewsBody
                newsBodySize={newsBodySize}
                selectedStories={selectedStories}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                includeSection={includeSection}
                includeBusiness={includeBusiness}
            />
            <TemplateFooter />
        </Grid>
    )
}
