import React, { PureComponent } from 'react'
import styled from 'styled-components/macro'
import Header from '../../components/Header/Header'

class TermsScreen extends PureComponent {
    render() {
        return (
            <Wrapper>
                <Header />
                <div className="static-container">
                    <div className="main-title">
                        <h1>Product License Agreement And End User License Agreement</h1>
                    </div>

                    <div
                        className="static-content"
                        style={{ background: 'white', fontFamily: 'sans-serif', textAlign: 'justify' }}
                    >
                        <p style={{ textAlign: 'end' }}>
                            <b>Last Updated:</b> March 27, 2023
                        </p>
                        <h1 style={{ paddingTop: '10px' }}>Website Terms of Use</h1>
                        <p>
                            Welcome to Kaitongo Inc. When you visit, view, use, or access our website{' '}
                            <a href="https://insights.kaitongo.com/">https://insights.kaitongo.com/</a> and any
                            applicable subdomains thereof, or any applications, mobile applications, functionalities,
                            content, materials, or other online services provided by Kaitongo Inc. (collectively, the
                            “Platform”), whether as a guest or a registered user, you are agreeing to the following
                            terms and conditions (“Terms of Use”), so please take a few minutes to read over the Terms
                            of Use below.
                        </p>
                        <b>Acceptance of Terms of Use</b>
                        <p>
                            If you do not agree to these Terms of Use, you are not permitted to use the Platform.
                            Kaitongo can change these Terms of Use at any time without any notice to you. It is your
                            responsibility to review these Terms of Use, as it creates a binding legal agreement between
                            you and Kaitongo. If you use the Platform after we have changed any of the Terms of Use, you
                            are agreeing to all of the changes. To the extent that you or your employer (to the extent
                            you are using the Platform in your capacity as an agent of your employer) has entered into
                            another written agreement with Kaitongo that contains terms that directly conflict with any
                            terms of these Terms of Use, then the conflicted terms set forth in such other agreement
                            will control.
                        </p>
                        <b>Eligibility</b>
                        <p>
                            Any access or use of the Platform by anyone under the age of 18 is prohibited. By agreeing
                            to these Terms of Use, you represent and warrant that you are 18 years of age or older and
                            have the legal capacity to enter into and abide by these Terms of Use.
                        </p>
                        <b>Privacy</b>
                        <p>
                            Any personal information submitted in connection with your use of the Platform is subject to
                            our privacy policy found at{' '}
                            <a href="https://insights.kaitongo.com/privacy">https://insights.kaitongo.com/privacy</a>{' '}
                            (the “Privacy Policy”), which is hereby incorporated by this reference. We will use
                            information about you that we obtain either directly from you or that we obtain through your
                            use of the Platform in accordance with our Privacy Policy solely for the purpose of
                            providing and enhancing the Platform.{' '}
                        </p>
                        <b>Account and Security</b>
                        <p>
                            You may need to set up an account in order to use some of the features of the Platform.
                            During the account setup process, it is important that you provide accurate and complete
                            information. This means that you cannot set up an account using a name or contact
                            information that does not apply to you, and you must provide accurate and current
                            information on all registration forms that are part of the Platform. By accessing any
                            account associated with the Platform, you confirm that you have received authorization from
                            Kaitongo and are using only the login credentials issued to you by Kaitongo or your firm
                            administrator working with Kaitongo. It is strictly prohibited to use anyone else's account
                            without prior written permission from Kaitongo. You are solely responsible for your account
                            and any activity that occurs on it, including safeguarding your password. If you suspect
                            that your account is being used without your consent, please notify us immediately.
                            Transferring your account to someone else is not permitted and could result in legal
                            consequences. Kaitongo is not responsible for any losses or damages that may result from
                            unauthorized use of your account.
                        </p>
                        <b>Proprietary Materials </b>
                        <p>
                            The Platform contains copyrighted materials, trademarks, proprietary and confidential
                            information, and intellectual property of Kaitongo and licensors of Kaitongo (collectively,
                            “Proprietary Materials”), including without limitation source code, video, text, software,
                            photos, graphics, images, music, and sound. You agree not to modify, publish, transmit,
                            participate in the transfer or sale of, create derivative works of, or in any way exploit,
                            in whole or in part, any Proprietary Materials. Proprietary Materials may only be accessed
                            through the Platform, and not by or from any other site or means. The right of access to the
                            Platform does not grant to you any right to download or store any Proprietary Materials in
                            any medium.
                        </p>
                        <p>
                            Kaitongo reserves all intellectual property rights to the Proprietary Materials, other than
                            as specifically granted under the applicable license granted you under these Terms of Use.
                            No posting, copying, transmission, retransmission, distribution, redistribution,
                            publication, republication, decompilation, disassembling, reverse engineering, or otherwise
                            reproducing, storing, transmitting, modifying, or commercially exploiting any Proprietary
                            Materials in any form or by any means, for any purpose, is permitted without our express
                            written permission.
                        </p>
                        <p>
                            The entire Platform is protected by copyright. Complying with all applicable copyright laws
                            is your responsibility. If you give feedback on the Platform, for example recommendations
                            for improvements or features, such feedback will be deemed non-confidential and
                            non-proprietary, and implementation of that feedback is owned by us and may become part of
                            the Platform without compensation to you. We reserve all rights in and to the Platform
                            unless we expressly state otherwise.{' '}
                        </p>
                        <p>
                            Other trademarks, service marks, and logos used on the Platform are the trademarks, service
                            marks, or logos of their respective owners. Kaitongo is using these third-party trademarks,
                            service marks, or logos with the permission of the trademark owner or for identification
                            purposes only.
                        </p>
                        <p>
                            We may, in appropriate circumstances and at our sole discretion, remove or disable access to
                            any materials on the Platform that we believe (or are notified) may infringe on the rights
                            of others.
                        </p>
                        <b>User Generated Content</b>
                        <p>
                            In these Terms of Use, “User Generated Content” means material (including without limitation
                            text, images, audio material, video material, and audio-visual material) that you submit to
                            the Platform, for whatever purpose
                        </p>
                        <p>
                            You grant to us a worldwide, irrevocable, non-exclusive, royalty-free license to use,
                            reproduce, adapt, publish, translate, communicate, publicly perform, publicly display, and
                            distribute your User Generated Content. You also grant to us the right to sub-license these
                            rights, and the right to bring an action for infringement of these rights. The rights you
                            grant in this license are for the limited purpose of operating, promoting, and improving the
                            Platform.
                        </p>
                        <p>
                            Your User Generated Content must not be illegal or unlawful, must not infringe any third
                            party’s legal rights, and must not be capable of giving rise to legal action whether against
                            you or us or a third party (in each case under any applicable law). It is strictly
                            prohibited to submit User Generated Content to the Platform if it is or has been subject to
                            threat of or actual legal proceedings or other similar complaints.
                        </p>
                        <p>
                            We reserve the right to edit or remove any material submitted to the Platform, or stored on
                            our servers, or hosted or published upon the Platform. Notwithstanding our rights under
                            these Terms of Use in relation to User Generated Content, we do not undertake to monitor the
                            submission of such content to, or the publication of such content on, the Platform.
                        </p>
                        <b>Acceptable Use </b>
                        <p>You must not:</p>
                        <p>
                            <ul>
                                <li>
                                    Use the Platform in any way that causes, could be reasonably expected to cause, or
                                    is intended to cause damage to the Platform or impairment of the availability or
                                    accessibility of the Platform; or in any way which is unlawful, illegal, fraudulent,
                                    or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful
                                    purpose or activity.
                                </li>
                                <li>
                                    Use the Platform to copy, store, host, transmit, send, use, publish, or distribute
                                    any material which consists of (or is linked to) any spyware, computer virus, Trojan
                                    horse, worm, keystroke logger, rootkit, or other malicious computer software.
                                </li>
                                <li>
                                    Conduct any systematic or automated data collection activities (including without
                                    limitation scraping, data mining, data extraction, and data harvesting) on or in
                                    relation to our Platform without our express written consent.
                                </li>
                                <li>
                                    Republish material from the Platform (including republication on another website).
                                </li>
                                <li>Sell, rent, or sub-license material from the Platform.</li>
                                <li>
                                    Develop, support, or use software, devices, scripts, robots, or any other means or
                                    processes (including crawlers, browser plugins and add-ons or any other technology)
                                    to scrape the Platform or otherwise copy profiles and other data from the Platform.
                                </li>
                                <li>
                                    Remove or modify any copyright, trademark, legal notices, or other proprietary
                                    notations from the Proprietary Materials or any other content available on the
                                    Platform.
                                </li>
                                <li>
                                    Violate or attempt to violate the Platform’s security mechanisms, attempt to gain
                                    unauthorized access to the Platform or assist others to do so, or otherwise breach
                                    the security of the Platform or corrupt the Platform in any way.
                                </li>
                            </ul>
                        </p>
                        <p>
                            We reserve the right to monitor use of the Platform and to suspend, revoke, deny, disable,
                            or terminate your access if you violate any provisions of these Terms of Use or if your or
                            behavior exceeds reasonable limits, as determined in our sole discretion.
                        </p>
                        <b>Warranty Disclaimer</b>
                        <p>
                            WE DO NOT WARRANT THE COMPLETENESS OR ACCURACY OF THE INFORMATION PUBLISHED ON THE PLATFORM;
                            NOR DO WE COMMIT TO ENSURING THAT THE PLATFORM REMAINS AVAILABLE OR THAT THE MATERIAL ON THE
                            PLATFORM IS KEPT UP-TO-DATE.{' '}
                        </p>
                        <p>
                            USE OF THE PLATFORM IS AT YOUR OWN RISK. THE PLATFORM IS PROVIDED ON A “AS IS” AND “AS
                            AVAILABLE” BASIS. KAITONGO AND ITS AFFILIATES, SUPPLIERS AND PARTNERS EXPRESSLY DISCLAIM ALL
                            WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
                            IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                            NON-INFRINGEMENT.
                        </p>
                        <p>
                            KAITONGO AND ITS AFFILIATES, SUPPLIERS AND PARTNERS MAKE NO WARRANTY THAT (i) THE PLATFORM
                            OR ANY PRODUCTS PURCHASED THROUGH THE PLATFORM WILL MEET YOUR REQUIREMENTS; (ii) THE
                            PLATFORM WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; OR (iii) THAT THERE WILL BE
                            NO ERRORS IN THE PLATFORM OR THAT KAITONGO WILL FIX ANY ERRORS. ANY MATERIALS OBTAINED
                            THROUGH USE OF THE PLATFORM ARE OBTAINED AT YOUR OWN DISCRETION AND RISK AND KAITONGO SHALL
                            NOT BE RESPONSIBLE FOR ANY DAMAGE CAUSED TO YOUR COMPUTER OR DATA OR FOR ANY BUGS, VIRUSES,
                            TROJAN HORSES, OR OTHER DESTRUCTIVE CODE RESULTING FROM USE OF THE PLATFORM OR ANY CONTENT
                            OBTAINED FROM THE PLATFORM.
                        </p>
                        <p>
                            SOME STATES DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT
                            APPLY TO YOU. YOU MAY ALSO HAVE OTHER LEGAL RIGHTS, WHICH VARY FROM STATE TO STATE.
                        </p>
                        <b>Links to Other Sites</b>
                        <p>
                            The Platform may contain links to other websites that we do not own or control. We are not
                            responsible for any of these other websites and any links to these other websites should not
                            be interpreted as an endorsement of any company, content, or products. We are not
                            responsible to you for any aspect of these other websites, including their content, privacy
                            policies, or anything else. You may be exposed to things on other websites that you do not
                            like or that you find offensive. We are not responsible for this, either. You must use your
                            own discretion when you go to other websites. You should also read the terms and conditions
                            and privacy policies of these other websites.
                        </p>
                        <b>General Terms</b>
                        <p>
                            <b>Non-Waiver.</b> Failure by Kaitongo to enforce any provision(s) of these Terms of Use
                            will not be construed as a waiver of any provision or right.
                        </p>
                        <p>
                            <b>Severability.</b> If any provision of these Terms is found to be unenforceable or invalid
                            under any applicable law, such unenforceability or invalidity shall not render these Terms
                            unenforceable or invalid as a whole, and such provisions shall be deleted without affecting
                            the remaining provisions herein.
                        </p>
                        <p>
                            <b>Governing Law & Jurisdiction.</b> These Terms will be governed by and construed in
                            accordance with the laws of the Province of Ontario and the federal laws of Canada
                            applicable therein, and you submit to the non-exclusive jurisdiction of the courts in the
                            Province of Ontario for the resolution of any disputes.
                        </p>
                        <p>
                            <b>Compliance with Legal Requests.</b> Without limiting the foregoing, we have the right to
                            fully cooperate with any valid legal process from a law enforcement authority with
                            jurisdiction that requests or directs us to disclose customer data or other information on
                            the Platform. YOU WAIVE AND HOLD HARMLESS US AND OUR AFFILIATES, LICENSEES, AND SERVICE
                            PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY US OR ANY OF THE FOREGOING
                            PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A
                            CONSEQUENCE OF INVESTIGATIONS BY EITHER US, SUCH PARTIES, OR LAW ENFORCEMENT AUTHORITIES.
                        </p>
                        <p>
                            <b>Limitation of Liability.</b> TO THE FULLEST EXTENT PERMITTED UNDER LAW, KAITONGO AND ITS
                            AFFILIATES, SUPPLIERS AND PARTNERS HAVE NO OBLIGATION OR LIABILITY (WHETHER ARISING IN
                            CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR OTHERWISE) FOR ANY
                            INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OR LIABILITIES (INCLUDING,
                            BUT NOT LIMITED TO, ANY LOSS OF DATA, REVENUE OR PROFIT) ARISING FROM OR RELATED TO YOUR USE
                            OF THE PLATFORM OR ANY CONTENT PROVIDED BY OR THROUGH THE PLATFORM, EVEN IF WE HAVE BEEN
                            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES IN ADVANCE. SOME STATES DO NOT ALLOW THE
                            LIMITATION OR EXCLUSION OF INCIDENTAL, CONSEQUENTIAL OR OTHER TYPES OF DAMAGES, SO SOME OF
                            THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. NOTWITHSTANDING ANYTHING TO THE CONTRARY
                            CONTAINED HEREIN, KAITONGO’S LIABILITY AND THE LIABILITY OF EACH OF ITS OFFICERS, DIRECTORS,
                            INVESTORS, EMPLOYEES, AGENTS, ADVERTISERS, LICENSORS, SUPPLIERS, SERVICE PROVIDERS AND OTHER
                            CONTRACTORS TO YOU OR ANY THIRD PARTIES UNDER ANY CIRCUMSTANCE IS LIMITED TO A MAXIMUM
                            AMOUNT OF $100.
                        </p>
                        <p>
                            <b>Indemnity.</b> You agree to indemnify and hold harmless Kaitongo and its affiliates,
                            suppliers, partners, officers, agents, and employees from and against any claim, demand,
                            losses, damages or expenses (including reasonable attorney’s fees) arising from any User
                            Generated Content, your use of the Platform, your connection to the Platform, your violation
                            of these Terms of Use or your violation of any rights of any third-party.{' '}
                        </p>
                        <p>
                            <b>Equitable Relief.</b> You acknowledge that a breach of these Terms of Use may cause us
                            irreparable damage, for which the award of damages would not be adequate compensation.
                            Consequently, you agree that we may institute an action to enjoin you from any and all acts
                            in violation of those provisions, which remedy will be cumulative and not exclusive, and we
                            may seek the entry of an injunction enjoining any breach or threatened breach of those
                            provisions, in addition to any other relief to which we may be entitled at law or in equity.
                        </p>
                        <p>
                            <b>Assignment.</b> We are permitted to assign, transfer, and subcontract our rights and/or
                            obligations under these Terms without any notification or consent to you. You are not
                            permitted to assign, transfer, or subcontract any of your rights and/or obligations under
                            these Terms.{' '}
                        </p>
                    </div>
                </div>
            </Wrapper>
        )
    }
}

export default TermsScreen
const Wrapper = styled.div`
    section {
        margin-bottom: 2rem;
    }

    h1 {
        color: black;
    }

    hr {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    a {
        text-decoration: underline;
        font-weight: bold;
    }
`
