import React, { useRef } from 'react'
import JoditEditor from 'jodit-react'

export default function OppRichTextEditor(props) {
    const editor = useRef(null)

    const config = {
        readonly: false, // all options from https://xdsoft.net/jodit/doc/
    }

    return (
        <JoditEditor
            ref={editor}
            value={props.value}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onBlur={newContent => props.outerOnChange(props.oppId, newContent)}
            // preferred to use only this option to update the content for performance reasons
            onChange={newContent => {}}
        />
    )
}
