import React, { useEffect } from 'react'
import { getFullFormattedDate } from '../../../utils/dateHelpers'
import './MailModal.css'

export default function F3LawPreview(props) {
    const {
        introText,
        selectedStories,
        primaryColor,
        secondaryColor,
        selectedLogo,
        setSize,
        includeSection,
        includeBusiness,
        hyperlinkSource,
        hyperlinkTitle,
        subjectLine,
    } = props
    const logoRef = React.useRef()
    const getSize = () => {
        setSize(logoRef.current.offsetWidth, logoRef.current.offsetHeight, 'primary')
    }

    const newSectionBody = data => {
        return (
            <>
                {data
                    .sort((a, b) => (a.date.split('T')[0] > b.date.split('T')[0] ? -1 : 1))
                    .map((story, index) => {
                        return (
                            <>
                                <div style={{ padding: index !== 0 ? '0px 20px 10px 20px' : '10px 20px 0px 20px' }}>
                                    {includeBusiness && story.subcategories && story.subcategories.length > 0 && (
                                        <div style={{ display: 'flex' }}>
                                            {story.subcategories.map(subcategory => {
                                                return (
                                                    <i
                                                        key={subcategory.id}
                                                        style={{
                                                            margin: '2px',
                                                            fontSize: '12px',
                                                            backgroundColor: '#d4e4fa',
                                                            padding: '2px 10px',
                                                            width: '-webkit-fit-content',
                                                            borderRadius: '25px',
                                                        }}
                                                    >
                                                        {subcategory.name}
                                                    </i>
                                                )
                                            })}
                                        </div>
                                    )}
                                    <div>
                                        <div style={{ fontSize: '18px', paddingTop: '10px' }}>{story.title} </div>
                                        <div
                                            style={{
                                                fontSize: '13px',
                                                paddingTop: '10px',
                                                lineHeight: 1.5,
                                            }}
                                        >
                                            {story.snippet}
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            fontSize: '14px',
                                            padding: '10px 0px',
                                        }}
                                    >
                                        {story.source_name && (
                                            <span style={{ fontWeight: 700 }}>
                                                <span>{story.source_name}</span>
                                                <span style={{ padding: '0px 2px' }}>·</span>
                                            </span>
                                        )}
                                        <span>{getFullFormattedDate(story.date)}</span>
                                    </div>

                                    {index !== data.length - 1 && (
                                        <div style={{ paddingTop: '0px' }}>
                                            <hr />
                                        </div>
                                    )}
                                </div>
                            </>
                        )
                    })}
            </>
        )
    }
    return (
        <div style={{ width: '700px', margin: 'auto', display: 'flex' }}>
            <div className="mailModal__emailContainer" style={{ background: 'white' }}>
                <div style={{ height: '350px' }}>
                    <img
                        style={{ width: '100%', height: '350px' }}
                        src="https://kaitongo-static.s3.amazonaws.com/dev/static/f3_banner.png"
                    />
                    <div style={{ fontSize: '34px', marginLeft: '140px', marginTop: '-200px', color: 'white' }}>
                        {subjectLine}
                    </div>
                </div>
                <div style={{ textAlign: 'center', background: 'white', padding: '15px' }}>
                    {getFullFormattedDate(Date.now())}
                </div>

                {selectedStories.flatMap(e => e.news).length === 0 && (
                    <div style={{ textAlign: 'center', padding: '20px', background: 'white' }}>
                        Choose Sections to Preview News
                    </div>
                )}
                {includeSection
                    ? selectedStories.map(section => {
                          if (
                              section &&
                              section.news &&
                              section.news.length !== 0 &&
                              section.title.trim().length !== 0
                          ) {
                              return (
                                  <div
                                      style={{
                                          width: '100%',
                                          boxSizing: 'border-box',
                                          fontFamily: 'Helvetica',
                                          background: 'white',
                                      }}
                                  >
                                      <div>
                                          <div>
                                              {includeSection && (
                                                  <div
                                                      style={{
                                                          fontSize: '16px',
                                                          background: '#99cc00',
                                                          color: '#ffffff',
                                                          padding: '15px 10px',
                                                      }}
                                                  >
                                                      <span>{section.title}</span>
                                                  </div>
                                              )}
                                              {newSectionBody(section.news)}
                                          </div>
                                      </div>
                                  </div>
                              )
                          }
                      })
                    : newSectionBody(selectedStories)}
                <div
                    style={{
                        fontSize: '8px',
                        fontFamily: 'Roboto',
                        margin: 'auto',
                        textAlign: 'center',
                        background: '#99cc00',
                        color: '#ffffff',
                    }}
                >
                    <div style={{ width: '40%', margin: 'auto', padding: '25px 0px 15px 0px' }}>
                        <div>Have questions or feedback? Email us at support@kaitongo.com.</div>
                        <div>You're receiving this email because you're subscribed to Kaitongo alerts.</div>
                        <div>You can update your preferences or unsubscribe from this list.</div>
                    </div>
                    <div>
                        <img
                            width="20"
                            height="20"
                            src="https://kaitongo-static.s3.amazonaws.com/dev/static/kaitongo-k-black.png"
                            alt="Image"
                        />
                    </div>
                    <div style={{ width: '25%', margin: 'auto', padding: '20px 0px 15px 0px' }}>
                        Kaitongo: Actionable intelligence for businesses. See more insights at Kaitongo.
                    </div>
                </div>
            </div>
        </div>
    )
}
