import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Form, Formik, Field } from 'formik'
// import { Button, Input } from 'reactstrap'
import { Button } from '@mui/material'
import { newUserValidate } from './AnalystAdminValidation'
import BaseScreen from '../BaseScreen/BaseScreen'
import { addUserFields as fields } from './constants/AnalystAdminConstants'
import store from '../../Store'
import { getCookie } from '../../utils'
import { Circles } from 'react-loader-spinner'
import { useHistory } from 'react-router-dom'
import RegionHiearchyMultiSelect from '../NewsFeedScreen/components/RegionHiearchyMultiSelect'
import { Autocomplete, Box, Chip, Grid, Tab, Tabs, TextField, createFilterOptions } from '@mui/material'
import 'react-js-cron/dist/styles.css'
import './MyPreference.css'
import PreferenceContainers from './PreferenceContainers'
import PreferenceSectorContainer from './PreferenceSectorContainer'
import CronTab from './CronTab'
import { NavLink } from 'react-router-dom'
import { FaArrowLeft } from 'react-icons/fa'
// import Tour from 'reactour'
import { LOGIN_USER } from '../../actions/types'
import { Steppers } from '../NewsFeedScreen/components/Steppers'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { MdNavigateNext } from 'react-icons/md'
import { Tooltip } from 'reactstrap'
import InfoIcon from '@mui/icons-material/Info'
import { LastCronValue } from '../../utils/dashboardStrings'
import NewRegionHierarchy from '../NewsFeedScreen/components/NewRegionHierarchy'
const qs = require('query-string')

export default function MyPreference(props) {
    const history = useHistory()

    const [allPracticeAreas, setAllPracticeAreas] = useState([])
    const [selectedPracticeArea, setSelectedPracticeArea] = useState({})
    const [selectedSectors, setSelectedSectors] = useState([])
    const [selectedIndustries, setSelectedIndustries] = useState([])
    const [selectedCompanies, setSelectedCompanies] = useState([])
    const [pageError, setPageError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [emailExists, setEmailExists] = useState(false)
    const [userInfo, setUserInfo] = useState({})
    const [onEditPage, setOnEditPage] = useState(false)
    const userEmail = store.getState().auth.user.email
    const userType = store.getState().auth.user.user_type
    const userFirm = store.getState().auth.user.firm_id

    const [selectedSubCats, setSelectedSubCats] = useState([])
    const [selectedTopics, setSelectedTopics] = useState([])
    const [allCompetitors, setAllCompetitors] = useState([])
    const [selectedCompetitors, setSelectedCompetitors] = useState([])
    const [userId, setUserId] = useState()
    const [isloader, setisloader] = useState(false)
    const [formChanged, setFormChanged] = useState(false)
    const [role, setRole] = useState([])
    const [regionHierarchy, setRegionHierarchy] = useState([])
    const [selectedCountry, setSelectedCountry] = useState([])
    const [defaultCountry, setDefaultCountry] = useState([])
    const [activeTab, setActiveTab] = useState(0)
    const [input, setInput] = useState('')
    const [selectedinput, setSelectedInput] = useState('')
    const [allFirms, setAllFirms] = useState([])
    const [selUSer, setSelUser] = useState('Regular User')
    const [defaultUser, setDefaultUser] = useState(null)

    const [sectorOpen, setSectorOpen] = useState([])
    const [topicOpen, setTopicOpen] = useState([])
    const [sectorInput, setsectorInput] = useState('')
    const [checkedTopics, setCheckedTopics] = useState(false)
    const [allHierarchy, setAllHierarchy] = useState([])
    const [cronTabValue, setCronTabValue] = useState('0 0 * * 1')
    const [editCron, setEditCron] = useState(null)
    const [isEnabled, setIsEnabled] = useState(false)
    const [lastScheduled, setLastScheduled] = useState(null)
    const [nextScheduled, setNextScheduled] = useState(null)
    const [emailSubject, setEmailSubject] = useState('Recommendation Newsletter')
    const [isTourOpen, setIsTourOpen] = useState(false)
    const [currentStep, setCurrentStep] = useState(0)
    const [recommendations, setRecommendations] = useState(null)
    const [tooltips, setTooltips] = useState({
        user_info_tooltip: false,
        user_companies: false,
        user_topics: false,
        user_role: false,
        user_practice_area: false,
    })
    const [allSubCats, setAllSubCats] = useState([])
    const [allWithoutHierarchy, setAllWithoutHierarchy] = useState([])
    const [allSavedSearches, setAllSavedSearches] = useState([])
    const [selectSavedSearches, setSelectSavedSearches] = useState([])
    const [thoughtCronTabValue, setThoughtCronTabValue] = useState('0 0 * * 1')
    const [thoughtEditCron, setThoughtEditCron] = useState(null)
    const [thoughtIsEnabled, setThoughtIsEnabled] = useState(false)
    const [digestCronTabValue, setDigestCronTabValue] = useState('0 0 * * 1')
    const [digestEditCron, setDigestEditCron] = useState(null)
    const [digestIsEnabled, setDigestIsEnabled] = useState(false)
    const [checkEnabled, setCheckEnabled] = useState({
        social: {
            enabled: false,
            cron: '0 0 * * 1',
        },
        thought: {
            enabled: false,
            cron: '0 0 * * 1',
        },
        digest: {
            enabled: false,
            cron: '0 0 * * 1',
        },
    })
    const [receiveDetailedReport, setReceiveDetailedReport] = useState(false)

    const tooltipStyle = {
        backgroundColor: '#333', // Background color
        color: '#fff', // Text color
    }

    const toggle = tooltip => {
        setTooltips({ ...tooltips, [tooltip]: !tooltips[tooltip] })
    }

    const allIndustriesName = allHierarchy.map(val => val)
    const path = props.match.path

    const allSectorsName = allHierarchy.map(val => val.sectors).reduce((acc, curr) => acc.concat(curr), [])

    const allTopicsName = allHierarchy
        .map(val => val.sectors.map(sec => sec.topics.map(top => top)))
        .reduce((acc, curr) => acc.concat(curr), [])
        .reduce((acc, curr) => acc.concat(curr), [])

    const handleSelectAllTopics = () => {
        if (checkedTopics) {
            setSelectedSectors([])
        } else {
            const filteredItems = allHierarchy.filter(ind => {
                const matchingSectors = ind.sectors.filter(sec =>
                    sec.topics.some(topic => topic.name.toLowerCase().includes(sectorInput.toLowerCase()))
                )

                return (
                    ind.name.toLowerCase().includes(sectorInput.toLowerCase()) ||
                    matchingSectors.length > 0 ||
                    ind.sectors.some(sec => sec.name.toLowerCase().includes(sectorInput.toLowerCase()))
                )
            })

            const filteredTopicLabels = filteredItems.reduce((result, val) => {
                result.push(val.name)

                val.sectors.forEach(sector => {
                    result.push(sector.name)

                    sector.topics.forEach(topic => {
                        if (topic.name.toLowerCase().includes(sectorInput.toLowerCase())) {
                            result.push(topic.name)
                        }
                    })
                })

                return result
            }, [])
            setSelectedSectors(filteredTopicLabels)
        }
        setCheckedTopics(!checkedTopics)
    }

    useEffect(() => {
        if (path === '/app/preferences/') {
            const id = store.getState().auth.user.id
            setUserId(id)
            const headerTitle = document.getElementById('header_title')
            headerTitle.textContent = 'My Preferences'
            // const logged_in = store.getState().auth.user.login_count
            // if (logged_in === 1) {
            //     setIsTourOpen(true)
            // } else {
            //     setIsTourOpen(false)
            // }
        } else {
            const id = props.match.params.id
            setUserId(id)
            const headerTitle = document.getElementById('header_title')
            headerTitle.textContent = 'Edit User'
        }
        getRoleSearch()
        getRegionHierarchy()
        getAllHieararchySector()
        getAllFirms()
        getAllCompanies()
    }, [store.getState().auth.user.id])
    const { query } = qs.parseUrl(props.location.search)

    useEffect(() => {
        getInitialFetchData()
        setisloader(true)
    }, [userId, defaultCountry.length > 0])

    const getInitialFetchData = async () => {
        await axios
            .get('/news/search-query/')
            .then(res => {
                const savedData = res.data
                    .filter(val => val.firm === userFirm)
                    .map(val => {
                        return { value: val.id, label: val.query_name }
                    })

                setAllSavedSearches(savedData)
            })
            .catch(err => console.log(err))
        let allPracticeAreasTemp
        await axios
            .get('/news/practice-areas/')
            .then(response => {
                allPracticeAreasTemp = response.data
                setAllPracticeAreas(response.data)
            })
            .catch(err => console.log(err))

        if (userId !== null && userId !== undefined && userId !== '') {
            setOnEditPage(true)
            await axios
                .get(`/endusers/all-endusers/?pk=${userId}`)
                .then(async userResponse => {
                    setisloader(false)
                    setUserInfo(userResponse.data)
                    const addType = (items, type) => items.map(item => ({ ...item, type }))

                    const combinedData = [
                        ...addType(userResponse.data.sectors, 'sectors'),
                        ...addType(userResponse.data.industries, 'industries'),
                        ...addType(userResponse.data.topics, 'topics'),
                        // ...addType(userResponse.data.subcategories, 'subcategories'),
                    ]

                    setSelectedSectors(combinedData)
                    // setSelectedIndustries(userResponse.data.industries.map(v => v.label))
                    // setSelectedTopics(userResponse.data.topics.map(v => v.value))
                    setSelectedCompanies(userResponse.data.companies)
                    setSelUser(userResponse.data.user_type)
                    setDefaultUser(userResponse.data.user_type)
                    const uniqueData = [
                        ...allCompetitors,
                        ...userResponse.data.companies,
                        ...userResponse.data.competitors,
                    ]

                    const arrayFromSet = new Set(uniqueData)
                    const dataarray = [...arrayFromSet]
                    const unique = Array.from(new Set(dataarray.map(item => item.value))).map(value => {
                        return dataarray.find(item => item.value === value)
                    })
                    setAllCompetitors(unique)
                    const userPracticeArea = allPracticeAreasTemp.find(
                        item => item.name === userResponse.data.practice_area
                    )
                    setSelectedPracticeArea({
                        value: userPracticeArea ? userPracticeArea.id : 0,
                        label: userPracticeArea ? userPracticeArea.name : '',
                    })
                    // setSelectedSubCats(userResponse.data.subcategories.map(v => v.value))
                    setSelectedCompetitors(userResponse.data.competitors)
                    setReceiveDetailedReport(userResponse.data.receive_detailed_report)

                    setSelectSavedSearches(userResponse.data.saved_search_queries)
                    // return {
                    //     id: val.value,
                    //     name: val.label,
                    //     type: 'Country',
                    // }
                    const addCountry = userResponse.data.country.map(val => {
                        return {
                            value: 'Country_' + val.value,
                            label: val.label,
                            type: 'Country',
                        }
                    })
                    const addRegion = userResponse.data.regions.map(val => {
                        return {
                            value: 'Region_' + val.value,
                            label: val.label,
                            type: 'Region',
                        }
                    })

                    const addProvince = userResponse.data.provinces.map(val => {
                        return {
                            value: 'Province_' + val.value,
                            label: val.label,
                            type: 'Province',
                        }
                    })
                    const addingAll = [...addRegion, ...addCountry, ...addProvince]

                    setDefaultCountry(addingAll)

                    setSelectedCountry(addingAll)

                    const scheduled_newsletters = userResponse.data && userResponse.data.scheduled_newsletters
                    if (scheduled_newsletters) {
                        const {
                            social_post_recommendations,
                            thought_leadership,
                            kaitongo_digest,
                        } = scheduled_newsletters

                        const handleNewsletter = (
                            newsletter,
                            setCronTabValue,
                            setIsEnabled,
                            setCheckEnabled,
                            setEditCron,
                            key
                        ) => {
                            if (newsletter) {
                                const { cron_expression, apscheduler_id } = newsletter
                                if (cron_expression) {
                                    setCronTabValue(LastCronValue(cron_expression, '+'))
                                    setIsEnabled(true)
                                    setCheckEnabled(prevState => ({
                                        ...prevState,
                                        [key]: { enabled: true, cron: LastCronValue(cron_expression, '+') },
                                    }))
                                } else {
                                    setIsEnabled(false)
                                    setCheckEnabled(prevState => ({
                                        ...prevState,
                                        [key]: { ...prevState[key], enabled: false },
                                    }))
                                }
                                setEditCron(apscheduler_id ? apscheduler_id : null)
                            }
                        }

                        handleNewsletter(
                            social_post_recommendations,
                            setCronTabValue,
                            setIsEnabled,
                            setCheckEnabled,
                            setEditCron,
                            'social'
                        )
                        handleNewsletter(
                            thought_leadership,
                            setThoughtCronTabValue,
                            setThoughtIsEnabled,
                            setCheckEnabled,
                            setThoughtEditCron,
                            'thought'
                        )
                        handleNewsletter(
                            kaitongo_digest,
                            setDigestCronTabValue,
                            setDigestIsEnabled,
                            setCheckEnabled,
                            setDigestEditCron,
                            'digest'
                        )
                    }

                    if (userResponse.data.subject) {
                        setEmailSubject(userResponse.data.subject)
                    }
                })
                .catch(err => console.log(err))
        }
    }

    const getRoleSearch = () => {
        axios
            .get(`/accounts/user-roles/`)
            .then(response => {
                setRole(response.data)
            })
            .catch(err => console.log(err))
    }

    const getRegionHierarchy = () => {
        axios.get('/news/region-countries-provinces/').then(response => {
            const regions = response.data.map(item => ({
                ...item,
                id: item.type + '_' + item.id,
                countries: item.countries
                    ? item.countries.map(cty => ({
                          ...cty,
                          id: cty.type + '_' + cty.id,
                          provinces: cty.provinces
                              ? cty.provinces.map(pro => ({
                                    ...pro,
                                    id: pro.type + '_' + pro.id,
                                }))
                              : [],
                      }))
                    : [],
            }))
            const data = regions

            const customSort = (a, b) => {
                if (a.name === 'North America') {
                    return -1
                } else if (b.name === 'North America') {
                    return 1
                }
                return a.name.localeCompare(b.name)
            }

            const sortedData = data.sort((a, b) => customSort(a, b))

            sortedData.forEach(region => {
                region.countries.sort((a, b) => a.name.localeCompare(b.name))
            })

            setRegionHierarchy(sortedData)
        })
    }

    const validateEmail = (mail, defaultmail) => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
            if (mail !== defaultmail) {
                checkEmailExists(mail)
            } else {
                setEmailExists(false)
            }
            return setEmailError(false)
        } else {
            return setEmailError(true)
        }
    }

    const checkEmailExists = email => {
        axios
            .get(`/endusers/check-email?email=${email}`)
            .then(response => {
                if (response.data.message === true) {
                    setEmailExists(true)
                } else {
                    setEmailExists(false)
                }
            })
            .catch(err => console.log(err))
    }

    const getAllHieararchySector = () => {
        axios
            .get('/news/industries-sectors-topics/')
            .then(industriesResponse => {
                const result = industriesResponse.data.flatMap(industry => [
                    { value: industry.id, label: industry.name, type: 'industries' },
                    ...industry.sectors.flatMap(sector => [
                        { value: sector.id, label: sector.name, type: 'sectors' },
                        ...sector.topics.map(topic => ({
                            value: topic.id,
                            label: topic.name,
                            type: 'topics',
                        })),
                    ]),
                ])

                return axios.get('/news/all-subcategories/').then(subCategoriesResponse => {
                    const subCategories = subCategoriesResponse.data.map(val => ({
                        value: val.value,
                        label: val.label,
                        type: 'subcategories',
                    }))

                    // Combine results and subCategories
                    // const combinedResults = [...result, ...subCategories]
                    const combinedResults = [...result]

                    // Create a Set to ensure unique values
                    const uniqueResults = Array.from(new Set(combinedResults.map(item => JSON.stringify(item)))).map(
                        item => JSON.parse(item)
                    )

                    // setAllSubCats(subCategories)
                    setAllWithoutHierarchy(uniqueResults)
                    setAllHierarchy(industriesResponse.data)
                })
            })
            .catch(err => console.log(err))
    }

    const getAllFirms = () => {
        axios
            .get('/analyst/firms/')
            .then(response => {
                setAllFirms(response.data.firms)
            })
            .catch(err => console.log(err))
    }

    const getAllCompanies = () => {
        axios
            .get('/news/all-companies/')
            .then(response => {
                let remappedTopics = response.data.map(item => {
                    return { value: item.value, label: item.label }
                })
                setAllCompetitors(remappedTopics)
            })
            .catch(err => console.log(err))
    }

    const getSubcategoriesFromPracticeArea = ev => {
        let selectedPracticeTemp
        selectedPracticeTemp = allPracticeAreas.find(item => item.id === Number(ev.target.value))
        setSelectedPracticeArea({
            label: selectedPracticeTemp.name,
            value: Number(ev.target.value),
        })
        // setSelectedSubCats([])
        // axios
        //     .get(`/news/subcategories-from-practice-area/?practiceAreas=${ev.target.value}`)
        //     .then(res => {
        //         setSelectedSubCats(res.data.results)
        //     })
        //     .catch(err => console.log(err))
    }

    const onSubmit = values => {
        if (values.email === '' || values.first_name === '') {
            toast.warn('Some mandatory fields missing.')
        } else if (emailError) {
            toast.warn('Email invalid')
        } else if (emailExists) {
            toast.warn('Email already exists')
        } else {
            const userSubmitObject = { ...values }
            if (userSubmitObject.role === 'null') {
                userSubmitObject.role = null
            }
            if (onEditPage) {
                userSubmitObject.id = userId
            }
            if (userSubmitObject.is_active === 'true') userSubmitObject.is_active = true
            if (userSubmitObject.is_active === 'false') userSubmitObject.is_active = false

            if (userType === 'analyst' || userType === 'client_analyst') {
                userSubmitObject.firm = Number(values.firm)
            }

            if (userSubmitObject.user_type) {
                userSubmitObject.user_type = selUSer
            }
            userSubmitObject.receive_detailed_report = receiveDetailedReport

            userSubmitObject.sectors = selectedSectors.filter(val => val.type === 'sectors').map(val => val.value)
            userSubmitObject.industries = selectedSectors.filter(val => val.type === 'industries').map(val => val.value)
            userSubmitObject.topics = selectedSectors.filter(val => val.type === 'topics').map(val => val.value)
            // userSubmitObject.subcategories = selectedSectors
            //     .filter(val => val.type === 'subcategories')
            //     .map(val => val.value)
            userSubmitObject.subcategories = []
            userSubmitObject.companies = selectedCompanies.map(ele => ele.value)
            userSubmitObject.practice_area = selectedPracticeArea.value
            userSubmitObject.saved_search_queries = selectSavedSearches.map(val => val.value)
            // userSubmitObject.subcategories = selectedSubCats.map(val => val.value)
            userSubmitObject.competitors = selectedCompetitors.map(ele => ele.value)
            userSubmitObject.country = selectedCountry
                .filter(value => value.type === 'Country')
                .map(value => {
                    return parseInt(value.value.split('_')[1])
                })
            userSubmitObject.regions = selectedCountry
                .filter(value => value.type === 'Region')
                .map(value => {
                    return parseInt(value.value.split('_')[1])
                })
            userSubmitObject.provinces = selectedCountry
                .filter(value => value.type === 'Province')
                .map(value => {
                    return parseInt(value.value.split('_')[1])
                })
            const token = getCookie('csrftoken')
            axios.defaults.headers.common['X-CSRFToken'] = token
            axios
                .post('/accounts/create-user/', userSubmitObject, {
                    headers: { 'X-CSRFToken': token },
                })
                .then(response => {
                    let scheduling = {
                        scheduled_newsletter: {},
                        recipients: [values.email],
                        user: userId,
                        // subject: emailSubject,
                    }

                    const socialCheck =
                        isEnabled !== checkEnabled.social.enabled || cronTabValue !== checkEnabled.social.cron
                    const thoughtCheck =
                        thoughtIsEnabled !== checkEnabled.thought.enabled ||
                        thoughtCronTabValue !== checkEnabled.thought.cron
                    const digestCheck =
                        digestIsEnabled !== checkEnabled.digest.enabled ||
                        digestCronTabValue !== checkEnabled.digest.cron

                    function setNewsletterDetails(type, cron, cronValue, enabled, check, subject) {
                        if (check) {
                            scheduling.scheduled_newsletter[type] = scheduling.scheduled_newsletter[type] || {}
                            if (cron !== null) {
                                scheduling.scheduled_newsletter[type].apscheduler_id = cron
                            }

                            const newCronExpression = LastCronValue(cronValue, '-')

                            scheduling.scheduled_newsletter[type].cron_expression = newCronExpression
                            scheduling.scheduled_newsletter[type].enabled = enabled
                            scheduling.scheduled_newsletter[type].subject = subject
                        }
                    }

                    setNewsletterDetails(
                        'social_post_recommendations',
                        editCron,
                        cronTabValue,
                        isEnabled,
                        socialCheck,
                        'Kaitongo Social Media Post Recommendation'
                    )
                    setNewsletterDetails(
                        'thought_leadership',
                        thoughtEditCron,
                        thoughtCronTabValue,
                        thoughtIsEnabled,
                        thoughtCheck,
                        'Kaitongo Thought Leadership Content Ideas'
                    )
                    setNewsletterDetails(
                        'kaitongo_digest',
                        digestEditCron,
                        digestCronTabValue,
                        digestIsEnabled,
                        digestCheck,
                        'Kaitongo Digest - Your Personalized News Digest'
                    )

                    axios.post(`/core/schedule-newsletter/`, scheduling).then(res => {})

                    if (isTourOpen) {
                        const user = store.getState().auth.user
                        user.login_count = 2
                        store.dispatch({
                            type: LOGIN_USER,
                            payload: user,
                        })
                        history.push('/app/assistant/')
                    }
                    if (recommendations > 2) {
                        setRecommendations(null)
                    }
                    if (userType === 'Regular User') {
                        toast('Preferences Updated')
                    } else {
                        toast('User has been successfully updated.')
                    }

                    if (userType === 'Power User' && defaultUser !== selUSer && userEmail === userSubmitObject.email) {
                        history.push('/')
                    }
                })
                .catch(err => {
                    console.log(err)
                    toast.warn('Error creating user-', err.message)
                })
        }
    }

    if (isloader) {
        return (
            <BaseScreen>
                <div style={{ marginLeft: '10px' }}>
                    <Circles key={0} type="Grid" color="#adadad" height={80} width={80} />
                </div>
            </BaseScreen>
        )
    }

    const stateMap = {
        Industry: [selectedIndustries, setSelectedIndustries],
        sector: [selectedSectors, setSelectedSectors],
        company: [selectedCompanies, setSelectedCompanies],
        topic: [selectedTopics, setSelectedTopics],
        competitor: [selectedCompetitors, setSelectedCompetitors],
        business: [selectedSubCats, setSelectedSubCats],
        savedsearch: [selectSavedSearches, setSelectSavedSearches],
    }

    const handleToggle = (type, id) => {
        const [selectedItems, setSelectedItems] = stateMap[type]
        // const isItemSelected = selectedItems.filter(item => item.value == id.value)
        // if (isItemSelected.length) {
        //     const filteredItems = selectedItems.filter(item => item.value !== id.value)
        //     setSelectedItems(filteredItems)
        // } else {
        //     setSelectedItems([...selectedItems, id])
        //     console.log("append ",[...selectedItems, id]);
        // }
        setSelectedItems([...id])
    }

    const handleRemoveTopic = (type, id) => {
        const [selectedItems, setSelectedItems] = stateMap[type]
        setSelectedItems(selectedItems.filter(val => val !== id))
    }

    const handleRemove = (type, id) => {
        const [selectedItems, setSelectedItems] = stateMap[type]
        setSelectedItems(selectedItems.filter(val => val.value !== id))
    }

    const bulkChange = (type, data, name) => {
        const [selectedItems, setSelectedItems] = stateMap[type]

        let result = selectedItems

        const isSelectedItem = selectedItems.find(item => item === name)
        data.forEach(list => {
            if (isSelectedItem) {
                result = result.filter(item => item !== list.name)
            } else {
                result.push(list.name)
            }
        })

        setSelectedItems([...new Set([...result])])
    }

    const changeCheck = (type, id, typeOf = null, value = null) => {
        const [selectedItems, setSelectedItems] = stateMap[type]
        const isItemSelected = selectedItems.includes(id)
        if (isItemSelected) {
            const filteredItems = selectedItems.filter(item => item !== id)
            setSelectedItems(filteredItems)
        } else {
            setSelectedItems([...selectedItems, id])
        }
    }

    const toggleid = (Id, type) => {
        type(prevExpandedValues => {
            if (prevExpandedValues.includes(Id)) {
                return prevExpandedValues.filter(id => id !== Id)
            } else {
                return [...prevExpandedValues, Id]
            }
        })
    }

    const style = {
        background: 'black',
        color: 'white',
    }
    const steps = [
        {
            selector: '.tour-sidebar',
            content: () => {
                setCurrentStep(0)
                return (
                    <div>
                        <p>Select your preferrences to receive personalized articles tailored to your interests.</p>
                    </div>
                )
            },
            style: style,
        },
        {
            selector: '.tour-profile',
            content: () => {
                setActiveTab(0)
                setCurrentStep(1)

                return (
                    <div>
                        <p>
                            Personalize your experience by sharing regions, countries, and practice areas of interest.
                        </p>
                    </div>
                )
            },
            style: style,
        },
        {
            selector: '.tour-schedule',
            content: () => {
                setActiveTab(0)
                setCurrentStep(2)

                return (
                    <div>
                        <p>Schedule your newsletter to receive recommendations at your preferred time and frequency</p>
                    </div>
                )
            },
            style: style,
        },
        {
            selector: '.tour-preference',

            content: () => {
                setCurrentStep(3)

                return (
                    <div>
                        <p>Select any tab and select preferences for more news.</p>
                    </div>
                )
            },
            style: style,
        },
        {
            selector: '.tour-company',
            content: () => {
                setActiveTab(1)
                setCurrentStep(4)

                return (
                    <div>
                        <p>
                            To receive news on My Companies Page, please select at least one company from the Companies
                            menu.
                        </p>
                    </div>
                )
            },
            style: style,
        },
        {
            selector: '.tour-topic',
            content: () => {
                setActiveTab(2)
                setCurrentStep(5)

                return (
                    <div>
                        <p>
                            To receive news on My Topics Page, select at least one industry, sector, or topic from the
                            menu.
                        </p>
                    </div>
                )
            },
            style: style,
        },
        {
            selector: '.tour-save',
            content: () => {
                setCurrentStep(6)

                return (
                    <div>
                        <p>At long last, hit that Save button to officially update your preferences</p>
                    </div>
                )
            },
            style: style,
        },
    ]
    const closeTour = () => {
        setIsTourOpen(false)
    }
    const accentColor = '#3b7dba'

    return (
        <BaseScreen>
            <div>
                {/* <Tour
                    steps={steps}
                    isOpen={isTourOpen}
                    rounded={5}
                    accentColor={accentColor}
                    onRequestClose={closeTour}
                    showButtons={
                        (selectedSectors.length !== 0 || activeTab !== 2) &&
                        (selectedCompanies.length !== 0 || activeTab !== 1)
                            ? true
                            : false
                    }
                    disableKeyboardNavigation={true}
                    showCloseButton={currentStep === 6 ? false : true}
                    disableOverlay={true}
                    disableInteraction={false}
                    disableFocusLock={true}
                /> */}

                <div style={{ width: '100%', marginTop: '-23px' }}>
                    <div style={{ width: '100%', padding: '5px 40px 5px 15px' }}>
                        <div className="d-flex align-items-center justify-content-between">
                            {path !== '/app/preferences/' && (
                                <NavLink
                                    style={{ 'text-decoration': 'none', marginTop: '17px' }}
                                    to="/app/admin/enduser/"
                                >
                                    <div
                                        style={{
                                            color: 'black',
                                            fontWeight: 500,
                                            textTransform: 'none',
                                        }}
                                        className="d-flex align-items-center"
                                    >
                                        <FaArrowLeft style={{ fontSize: '16px' }} />
                                        <label
                                            style={{
                                                fontSize: '20px',
                                                fontWeight: '500',
                                                marginLeft: '10px',
                                            }}
                                        >
                                            Edit User
                                        </label>
                                    </div>
                                </NavLink>
                            )}
                            <div />
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        width: '99%',
                        color: 'black',
                    }}
                    className={path === '/app/preferences/' ? 'preference_padding' : 'edit_preference_padding'}
                >
                    <Formik
                        enableReinitialize
                        initialValues={
                            onEditPage
                                ? {
                                      sectors: userInfo.sectors,
                                      industries: userInfo.industries,
                                      first_name: userInfo.first_name,
                                      last_name: userInfo.last_name,
                                      email: userInfo.email,
                                      firm: userInfo.firm,
                                      //   country: userInfo.country,
                                      role: userInfo.role,
                                      subscriptionType: userInfo.subscription_type,
                                      title: userInfo.title,
                                      is_active: userInfo.is_active,
                                      practice_area: userInfo.practice_area,
                                      topics: userInfo.topics,
                                      user_type: userInfo.user_type,
                                  }
                                : {
                                      first_name: '',
                                      last_name: '',
                                      email: '',
                                      firm: query.firm ? query.firm : null,
                                      //   country: query.country ? query.country : 0,
                                      role: query.role ? query.role : 0,
                                      subscriptionType: query.subType ? query.subType : 'Firm',
                                      title: '',
                                      is_active: false,
                                      user_type: query.user_type ? query.user_type : 'Regular User',
                                  }
                        }
                        onSubmit={onSubmit}
                    >
                        {props => {
                            const { initialValues, values } = props

                            // if (
                            //     JSON.stringify(
                            //         initialValues.industries &&
                            //             initialValues.industries.sort((a, b) => a.value - b.value).map(val => val.value)
                            //     ) !==
                            //         JSON.stringify(
                            //             selectedIndustries &&
                            //                 selectedIndustries.sort((a, b) => a.value - b.value).map(val => val.value)
                            //         ) ||
                            //     JSON.stringify(
                            //         initialValues.sectors &&
                            //             initialValues.sectors.sort((a, b) => a.value - b.value).map(val => val.value)
                            //     ) !==
                            //         JSON.stringify(
                            //             selectedSectors &&
                            //                 selectedSectors.sort((a, b) => a.value - b.value).map(val => val.value)
                            //         ) ||
                            //     JSON.stringify(
                            //         initialValues.topics &&
                            //             initialValues.topics.sort((a, b) => a.value - b.value).map(val => val.value)
                            //     ) !==
                            //         JSON.stringify(
                            //             selectedTopics &&
                            //                 selectedTopics.sort((a, b) => a.value - b.value).map(val => val.value)
                            //         ) ||
                            //     JSON.stringify(
                            //         initialValues.competitors &&
                            //             initialValues.competitors
                            //                 .sort((a, b) => a.value - b.value)
                            //                 .map(val => val.value)
                            //     ) !==
                            //         JSON.stringify(
                            //             selectedCompetitors &&
                            //                 selectedCompetitors.sort((a, b) => a.value - b.value).map(val => val.value)
                            //         ) ||
                            //     JSON.stringify(
                            //         userInfo.companies.sort((a, b) => a.value - b.value).map(val => val.value)
                            //     ) !==
                            //         JSON.stringify(
                            //             selectedCompanies.sort((a, b) => a.value - b.value).map(val => val.value)
                            //         ) ||
                            //     JSON.stringify(
                            //         userInfo.subcategories.sort((a, b) => a.value - b.value).map(val => val.value)
                            //     ) !==
                            //         JSON.stringify(
                            //             selectedSubCats.sort((a, b) => a.value - b.value).map(val => val.value)
                            //         ) ||
                            //     //fields
                            //     initialValues.first_name !== values.first_name ||
                            //     initialValues.last_name !== values.last_name ||
                            //     initialValues.title !== values.title ||
                            //     initialValues.email !== values.email ||
                            //     // initialValues.country !== values.country ||
                            //     JSON.stringify(defaultCountry.sort((a, b) => a.id - b.id).map(val => val.id)) !==
                            //         JSON.stringify(
                            //             selectedCountry.map(value => {
                            //                 return parseInt(value.value.split('_')[1])
                            //             })
                            //         ) ||
                            //     initialValues.practice_area !== values.practice_area ||
                            //     initialValues.role !== Number(values.role)
                            // ) {
                            //     setFormChanged(true)
                            // } else {
                            //     setFormChanged(false)
                            // }
                            return (
                                <Form>
                                    <div
                                        className="d-flex align-items-center justify-content-end"
                                        style={{ width: 'auto', paddingBottom: '15px' }}
                                    >
                                        <Button
                                            type="submit"
                                            className="tour-save adminEndUser__submitButton"
                                            style={{
                                                width: 'max-content',
                                                height: '40px',
                                                backgroundColor: '#186ADE',
                                                color: 'white',
                                                position: 'fixed',
                                                top: '85px',
                                                textTransform: 'none',
                                                zIndex: 3,
                                            }}
                                        >
                                            {/* {userType === 'Regular User' ? 'Save Preferences' : 'Edit User'} */}
                                            Save
                                        </Button>
                                    </div>
                                    <>
                                        <div
                                            className="tour-profile bg-white"
                                            style={{ borderRadius: '10px', boxShadow: '0px 2px 2px 0px #00000024' }}
                                        >
                                            <div
                                                style={{
                                                    padding: '20px 0px 0px 40px',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        fontSize: '20px',
                                                        fontWeight: '500',
                                                    }}
                                                >
                                                    <span> User Information</span>
                                                    <span style={{ marginLeft: '10px' }}>
                                                        <InfoIcon
                                                            id="user_info_tooltip"
                                                            style={{
                                                                cursor: 'pointer',
                                                                display: 'inline-block',
                                                            }}
                                                            className="tooltip_icon"
                                                        />
                                                        <Tooltip
                                                            placement="top-end"
                                                            isOpen={tooltips.user_info_tooltip}
                                                            target="user_info_tooltip"
                                                            toggle={() => toggle('user_info_tooltip')}
                                                            className="custom-tooltip"
                                                        >
                                                            Species the searches, topics and companies that interest the
                                                            user.This will help tailer their content feed and overall
                                                            experience
                                                        </Tooltip>
                                                    </span>
                                                </div>
                                                <label
                                                    style={{
                                                        fontSize: '16px',
                                                        fontWeight: '300',
                                                        color: 'lightgray',
                                                    }}
                                                >
                                                    Enter the user's details
                                                </label>
                                            </div>
                                            <div
                                                className="d-md-flex d-sm-block"
                                                style={{
                                                    justifyContent: 'space-between',
                                                    padding: '20px 0px 20px 40px',
                                                }}
                                            >
                                                <div className="responsive-width">
                                                    <label
                                                        style={{
                                                            fontSize: '16px',
                                                            fontWeight: '400',
                                                            marginBottom: '10px',
                                                        }}
                                                    >
                                                        Name
                                                    </label>
                                                    <br />
                                                    <Field
                                                        type={fields.firstName.type}
                                                        name={fields.firstName.name}
                                                        id={fields.firstName.name}
                                                        placeholder={'Enter Name'}
                                                        style={{
                                                            marginRight: '20px',
                                                            width: '90%',
                                                            height: '40px',
                                                            borderRadius: '4px',
                                                            background: 'white',
                                                            border: '1px solid hsl(0,0%,80%)',
                                                            outline: 'none',
                                                            fontSize: '14px',
                                                            paddingLeft: '2%',
                                                        }}
                                                        className="adminEndUser__textInput"
                                                    />
                                                </div>
                                                <div className="responsive-width mt-3 mt-sm-0">
                                                    <label
                                                        style={{
                                                            fontSize: '16px',
                                                            fontWeight: '400',
                                                            marginBottom: '10px',
                                                        }}
                                                    >
                                                        Email
                                                    </label>
                                                    <br />
                                                    <Field
                                                        type={fields.email.type}
                                                        name={fields.email.name}
                                                        id={fields.email.name}
                                                        onBlur={ev => validateEmail(ev.target.value, userInfo.email)}
                                                        placeholder={'Enter Email'}
                                                        style={{
                                                            marginRight: '20px',
                                                            width: '90%',
                                                            height: '40px',
                                                            borderRadius: '4px',
                                                            background: 'white',
                                                            border: '1px solid hsl(0,0%,80%)',
                                                            outline: 'none',
                                                            fontSize: '14px',
                                                            paddingLeft: '2%',
                                                        }}
                                                        className="adminEndUser__textInput"
                                                    />
                                                    <br />
                                                    {emailError && (
                                                        <div className="adminEndUser__error">Email not valid</div>
                                                    )}
                                                    {emailExists && (
                                                        <div className="adminEndUser__error">Email already exists</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className="d-md-flex d-sm-block"
                                                style={{ padding: '0px 0px 20px 40px' }}
                                            >
                                                {/* {selUSer === 'Regular User' && ( */}
                                                <div className="responsive-width">
                                                    <label
                                                        style={{
                                                            fontSize: '16px',
                                                            fontWeight: '400',
                                                            marginBottom: '10px',
                                                        }}
                                                        className="d-flex align-items-center"
                                                    >
                                                        Role
                                                        <span style={{ marginLeft: '10px' }}>
                                                            <InfoIcon
                                                                id="user_role"
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    display: 'inline-block',
                                                                }}
                                                                className="tooltip_icon"
                                                            />
                                                            <Tooltip
                                                                placement="top-end"
                                                                isOpen={tooltips.user_role}
                                                                target="user_role"
                                                                toggle={() => toggle('user_role')}
                                                                className="custom-tooltip"
                                                            >
                                                                Choose the role that best describes user's position
                                                                within the organization. Role is used to customize GPT
                                                                prompts for some of the outputs such as Thought
                                                                Leadership and Social Posts.
                                                            </Tooltip>
                                                        </span>
                                                    </label>

                                                    <Field
                                                        name={fields.role.name}
                                                        as={fields.role.type}
                                                        id={fields.role.name}
                                                        placeholder={'Select Role'}
                                                        style={{
                                                            width: '90%',
                                                            height: '40px',
                                                            borderRadius: '4px',
                                                            background: 'white',
                                                            border: '1px solid hsl(0,0%,80%)',
                                                            outline: 'none',
                                                            fontSize: '14px',
                                                            paddingLeft: '2%',
                                                        }}
                                                        className="adminEndUser__textInput"
                                                    >
                                                        <option value="null">Select Role</option>
                                                        {role.map(val => {
                                                            return (
                                                                <option key={'role-options-' + val.id} value={val.id}>
                                                                    {val.role}
                                                                </option>
                                                            )
                                                        })}
                                                    </Field>
                                                </div>
                                                {/* )} */}

                                                <div className="responsive-width mt-3 mt-sm-0">
                                                    <label
                                                        style={{
                                                            fontSize: '16px',
                                                            fontWeight: '400',
                                                            marginBottom: '10px',
                                                        }}
                                                        className="d-flex align-items-center"
                                                    >
                                                        Practice Area
                                                        <span style={{ marginLeft: '10px' }}>
                                                            <InfoIcon
                                                                id="user_practice_area"
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    display: 'inline-block',
                                                                }}
                                                                className="tooltip_icon"
                                                            />
                                                            <Tooltip
                                                                placement="top-end"
                                                                isOpen={tooltips.user_practice_area}
                                                                target="user_practice_area"
                                                                toggle={() => toggle('user_practice_area')}
                                                                className="custom-tooltip"
                                                            >
                                                                Select a practice area that is closest to your
                                                                specialization - this setting is used to customize
                                                                prompts for GPT.
                                                            </Tooltip>
                                                        </span>
                                                    </label>
                                                    <Field
                                                        name={fields.practiceArea.name}
                                                        as={fields.practiceArea.type}
                                                        id={fields.practiceArea.name}
                                                        placeholder={'Select Practice Area'}
                                                        style={{
                                                            width: '90%',
                                                            height: '40px',
                                                            borderRadius: '4px',
                                                            background: 'white',
                                                            border: '1px solid hsl(0,0%,80%)',
                                                            outline: 'none',
                                                            fontSize: '14px',
                                                            paddingLeft: '2%',
                                                        }}
                                                        className="adminEndUser__textInput"
                                                        onChange={getSubcategoriesFromPracticeArea}
                                                        value={selectedPracticeArea.value}
                                                    >
                                                        <option disabled value="0">
                                                            Practice Area
                                                        </option>
                                                        {allPracticeAreas
                                                            .sort((a, b) => a.name.localeCompare(b.name))
                                                            .map(area => {
                                                                return (
                                                                    <option
                                                                        style={{ height: '40px' }}
                                                                        key={'area-options-' + area.id}
                                                                        value={area.id}
                                                                    >
                                                                        {area.name}
                                                                    </option>
                                                                )
                                                            })}
                                                    </Field>
                                                </div>
                                            </div>

                                            <div
                                                className="d-md-flex d-sm-block"
                                                style={{ padding: '0px 0px 20px 40px' }}
                                            >
                                                <div className="responsive-width">
                                                    <label
                                                        style={{
                                                            fontSize: '16px',
                                                            fontWeight: '400',
                                                            marginBottom: '10px',
                                                        }}
                                                    >
                                                        Region/Country
                                                    </label>
                                                    <br />

                                                    {/* <RegionHiearchyMultiSelect
                                                        regionHierarchy={regionHierarchy}
                                                        selectedQueries={selectedCountry}
                                                        setSelectedQueries={setSelectedCountry}
                                                        isMyPreference={true}
                                                        selectedPreference={defaultCountry}
                                                    /> */}
                                                    <NewRegionHierarchy
                                                        regionHierarchy={regionHierarchy}
                                                        selectedOptions={selectedCountry}
                                                        setSelectedOptions={setSelectedCountry}
                                                        isMypreference={true}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="d-md-flex d-sm-block mt-3 mt-sm-0"
                                                style={{ padding: '0px 0px 20px 40px' }}
                                            >
                                                {userType !== 'Regular User' && (
                                                    <div className="responsive-width">
                                                        <label
                                                            style={{
                                                                fontSize: '16px',
                                                                fontWeight: '400',
                                                                marginBottom: '10px',
                                                            }}
                                                        >
                                                            User Category
                                                        </label>
                                                        <br />
                                                        <Field
                                                            name={fields.user_type.name}
                                                            as={fields.user_type.type}
                                                            id={fields.user_type.name}
                                                            placeholder={'Select User Category'}
                                                            style={{
                                                                width: '90%',
                                                                height: '40px',
                                                                borderRadius: '4px',
                                                                background: 'white',
                                                                border: '1px solid hsl(0,0%,80%)',
                                                                outline: 'none',
                                                                fontSize: '14px',
                                                            }}
                                                            className="adminAddFirm__textInput"
                                                            onChange={e => {
                                                                setSelUser(e.target.value)
                                                            }}
                                                            value={selUSer}
                                                        >
                                                            <option value="Lite User">Lite User</option>
                                                            <option value="Power User">Power User</option>
                                                            <option value="Regular User">Regular User</option>
                                                        </Field>
                                                    </div>
                                                )}
                                                {userType !== 'Regular User' && path !== '/app/preferences/' && (
                                                    <div className="responsive-width">
                                                        <label
                                                            style={{
                                                                fontSize: '16px',
                                                                fontWeight: '400',
                                                                marginBottom: '10px',
                                                            }}
                                                        >
                                                            Active
                                                        </label>
                                                        <br />
                                                        <Field
                                                            name={fields.is_active.name}
                                                            as={fields.is_active.type}
                                                            id={fields.is_active.name}
                                                            placeholder={'Select Active'}
                                                            style={{
                                                                width: '90%',
                                                                height: '40px',
                                                                borderRadius: '4px',
                                                                background: 'white',
                                                                border: '1px solid hsl(0,0%,80%)',
                                                                outline: 'none',
                                                                fontSize: '14px',
                                                            }}
                                                            className="adminAddFirm__textInput"
                                                        >
                                                            <option value="false">Not Active</option>
                                                            <option value="true">Active</option>
                                                        </Field>
                                                    </div>
                                                )}
                                            </div>

                                            <div
                                                className="d-md-flex d-sm-block mt-3 mt-sm-0"
                                                style={{ padding: '0px 0px 20px 40px' }}
                                            >
                                                {(userType === 'analyst' || userType === 'client_analyst') && (
                                                    <div className="responsive-width">
                                                        <label
                                                            style={{
                                                                fontSize: '16px',
                                                                fontWeight: '400',
                                                                marginBottom: '10px',
                                                            }}
                                                        >
                                                            Firm
                                                        </label>
                                                        <br />
                                                        <Field
                                                            name={fields.firm.name}
                                                            as={fields.firm.type}
                                                            id={fields.firm.name}
                                                            placeholder={'Select Firm'}
                                                            style={{
                                                                width: '90%',
                                                                height: '40px',
                                                                borderRadius: '4px',
                                                                background: 'white',
                                                                border: '1px solid hsl(0,0%,80%)',
                                                                outline: 'none',
                                                                fontSize: '14px',
                                                            }}
                                                            className="adminEndUser__textInput"
                                                        >
                                                            <option disabled value="0">
                                                                Firm
                                                            </option>
                                                            {allFirms &&
                                                                allFirms.length > 0 &&
                                                                allFirms.map(firm => {
                                                                    return (
                                                                        <option
                                                                            key={'firm-options-' + firm.value}
                                                                            value={firm.value}
                                                                        >
                                                                            {firm.label}
                                                                        </option>
                                                                    )
                                                                })}
                                                        </Field>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </>

                                    <div>
                                        <div
                                            className="mt-4 bg-white pre-padding"
                                            style={{
                                                borderRadius: '10px',
                                                boxShadow: '0px 2px 2px 0px #00000024',
                                            }}
                                        >
                                            <div className="bg-white">
                                                <div
                                                    style={{
                                                        fontSize: '20px',
                                                        fontWeight: '500',
                                                    }}
                                                >
                                                    <span> User Preferences</span>
                                                </div>
                                                <label
                                                    style={{
                                                        fontSize: '16px',
                                                        fontWeight: '300',
                                                        color: 'lightgray',
                                                    }}
                                                >
                                                    Assign user preferences here. This will help us deliver tailored
                                                    news, thought leadership topics and social media recommendations to
                                                    user inbox. 
                                                </label>
                                            </div>

                                            <div className="d-md-flex d-sm-block mt-4 align-items-center justify-content-between">
                                                {(allSavedSearches.length > 0 || selectSavedSearches.length > 0) && (
                                                    <div className="responsive-width">
                                                        <PreferenceContainers
                                                            header={'Assign Saved Search'}
                                                            subHeader={'Selected saved search'}
                                                            placeHolder={'Select from list'}
                                                            title={'select saved search that you are interested in.'}
                                                            data={allSavedSearches}
                                                            selectedData={selectSavedSearches}
                                                            setSelectedData={setSelectSavedSearches}
                                                            input={input}
                                                            setInput={setInput}
                                                            selectedinput={selectedinput}
                                                            setSelectedInput={setSelectedInput}
                                                            state={'savedsearch'}
                                                            handleToggle={handleToggle}
                                                            handleRemove={handleRemove}
                                                            toggle={toggle}
                                                        />
                                                    </div>
                                                )}

                                                <div className="responsive-width d-flex align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        id="myCheckbox"
                                                        checked={receiveDetailedReport}
                                                        onChange={() => {
                                                            setReceiveDetailedReport(!receiveDetailedReport)
                                                        }}
                                                        style={{
                                                            transform: 'scale(1.5)',
                                                            margin: '10px',
                                                            accentColor: 'rgb(24, 106, 222)',
                                                        }}
                                                    />
                                                    <label
                                                        htmlFor="myCheckbox"
                                                        style={{ fontSize: '16px', fontWeight: 400 }}
                                                    >
                                                        Deliver detailed reports without manual check
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="d-md-flex d-sm-block mt-4">
                                                <div className="responsive-width">
                                                    <PreferenceContainers
                                                        header={'Companies of Interest'}
                                                        subHeader={'Selected Companies'}
                                                        placeHolder={'Search and Select Companies of Interest'}
                                                        title={
                                                            'Search and select companies that you are interested in.'
                                                        }
                                                        data={allCompetitors}
                                                        selectedData={selectedCompanies}
                                                        setSelectedData={setSelectedCompanies}
                                                        input={input}
                                                        setInput={setInput}
                                                        selectedinput={selectedinput}
                                                        setSelectedInput={setSelectedInput}
                                                        state={'company'}
                                                        handleToggle={handleToggle}
                                                        handleRemove={handleRemove}
                                                        tooltip={tooltips.user_companies}
                                                        toggle={toggle}
                                                    />
                                                </div>

                                                {/* <div className='responsive-width'>
                                                    <PreferenceSectorContainer
                                                        header={'All Topics'}
                                                        subHeader={'Selected Topics'}
                                                        placeHolder={'Search Topics'}
                                                        selectedData={selectedSectors}
                                                        selectedSectors={selectedSectors}
                                                        setSelectedData={setSelectedSectors}
                                                        input={input}
                                                        selectedinput={selectedinput}
                                                        setSelectedInput={setSelectedInput}
                                                        allHierarchy={allHierarchy}
                                                        checkedTopics={checkedTopics}
                                                        setInput={setInput}
                                                        sectorInput={sectorInput}
                                                        setsectorInput={setsectorInput}
                                                        state={'sector'}
                                                        sectorOpen={sectorOpen}
                                                        setSectorOpen={setSectorOpen}
                                                        topicOpen={topicOpen}
                                                        setTopicOpen={setTopicOpen}
                                                        toggleid={toggleid}
                                                        bulkChange={bulkChange}
                                                        handleRemove={handleRemoveTopic}
                                                        changeCheck={changeCheck}
                                                        handleSelectAllTopics={handleSelectAllTopics}
                                                        tooltip={tooltips.user_topics}
                                                        toggle={toggle}
                                                    />
                                                </div> */}
                                                <div className="responsive-width mt-3 mt-sm-0">
                                                    <PreferenceContainers
                                                        header={'Topics'}
                                                        subHeader={'Selected Topics'}
                                                        placeHolder={'Search and Select Topics of Interest'}
                                                        title={'Search and select topics that you are interested in.'}
                                                        data={allWithoutHierarchy}
                                                        selectedData={selectedSectors}
                                                        setSelectedData={setSelectedSectors}
                                                        input={input}
                                                        setInput={setInput}
                                                        selectedinput={selectedinput}
                                                        setSelectedInput={setSelectedInput}
                                                        state={'sector'}
                                                        handleToggle={handleToggle}
                                                        handleRemove={handleRemove}
                                                        tooltip={tooltips.user_topics}
                                                        toggle={toggle}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div
                                            className="mt-4 bg-white"
                                            style={{ borderRadius: '10px', boxShadow: '0px 2px 2px 0px #00000024' }}
                                        >
                                            <div className="bg-white pre-padding" style={{ borderRadius: '10px' }}>
                                                <div
                                                    style={{
                                                        fontSize: '20px',
                                                        fontWeight: '500',
                                                    }}
                                                >
                                                    <span> Select Products</span>
                                                </div>
                                                <label
                                                    style={{
                                                        fontSize: '16px',
                                                        fontWeight: '300',
                                                        color: 'lightgray',
                                                    }}
                                                >
                                                    Select which type of emails you want in the user inbox: Kaitongo
                                                    Digest, Thought Leadership Ideas, Social Media Content.{' '}
                                                </label>
                                            </div>
                                            <div className="d-md-flex d-sm-block align-items-center">
                                                <div className="responsive_cron mt-3">
                                                    <div
                                                        className="crontab_container"
                                                        style={{
                                                            border: '1px solid lightgray',
                                                            borderRadius: '5px',
                                                            padding: '20px',
                                                            height: '180px',
                                                        }}
                                                        title="Click the Select button to allow newsletter scheduling."
                                                    >
                                                        <div
                                                            style={{
                                                                fontSize: '18px',
                                                                fontWeight: '500',
                                                            }}
                                                        >
                                                            Kaitongo Digest
                                                        </div>
                                                        <div
                                                            style={{
                                                                fontSize: '14px',
                                                                fontWeight: 400,
                                                            }}
                                                        >
                                                            Get tailored news updates straight to your inbox{' '}
                                                        </div>

                                                        <Button
                                                            style={{
                                                                cursor: 'pointer',
                                                                borderRadius: '5px',
                                                                fontWeight: 500,
                                                                textTransform: 'none',
                                                                display: 'flex',
                                                                margin: 'auto',
                                                                padding: '3px 15px',
                                                                fontSize: '14px',
                                                            }}
                                                            className={
                                                                digestIsEnabled
                                                                    ? 'crontab_button_enable mt-3'
                                                                    : 'crontab_button_disable mt-3'
                                                            }
                                                            onClick={() => {
                                                                setDigestIsEnabled(true)
                                                            }}
                                                        >
                                                            {digestIsEnabled ? 'Selected' : 'Select +'}
                                                        </Button>

                                                        {digestIsEnabled && (
                                                            <Button
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    borderRadius: '5px',
                                                                    fontWeight: 500,
                                                                    textTransform: 'none',
                                                                    display: 'flex',
                                                                    margin: 'auto',
                                                                    display: 'none',
                                                                    padding: '3px 15px',
                                                                    fontSize: '14px',
                                                                }}
                                                                className="crontab_remove_button mt-2"
                                                                onClick={() => {
                                                                    setDigestIsEnabled(false)
                                                                }}
                                                            >
                                                                {'remove x'}
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        margin: 'auto',
                                                        padding: '20px 0px 20px 10px',
                                                        color: !digestIsEnabled ? 'lightgray' : 'black',
                                                    }}
                                                    className="responsive-width"
                                                >
                                                    <div
                                                        style={{
                                                            fontSize: '20px',
                                                            fontWeight: '500',
                                                            marginLeft: '20px',
                                                        }}
                                                    >
                                                        Kaitongo Digest Email Frequency
                                                    </div>

                                                    <CronTab
                                                        isEnabled={digestIsEnabled}
                                                        setIsEnabled={setDigestIsEnabled}
                                                        cronTabValue={digestCronTabValue}
                                                        setCronTabValue={setDigestCronTabValue}
                                                        isMypreference={true}
                                                    />
                                                </div>
                                            </div>

                                            <div className="d-md-flex d-sm-block align-items-center">
                                                <div className="responsive_cron">
                                                    <div
                                                        className="crontab_container"
                                                        style={{
                                                            border: '1px solid lightgray',
                                                            borderRadius: '5px',
                                                            padding: '20px',
                                                            height: '180px',
                                                        }}
                                                        title="Click the Select button to allow newsletter scheduling."
                                                    >
                                                        <div
                                                            style={{
                                                                fontSize: '18px',
                                                                fontWeight: '500',
                                                            }}
                                                        >
                                                            Thought Leadership
                                                        </div>
                                                        <div
                                                            style={{
                                                                fontSize: '14px',
                                                                fontWeight: 400,
                                                            }}
                                                        >
                                                            Get thought leadership content and ideas
                                                        </div>

                                                        <Button
                                                            style={{
                                                                cursor: 'pointer',
                                                                borderRadius: '5px',
                                                                fontWeight: 500,
                                                                textTransform: 'none',
                                                                display: 'flex',
                                                                margin: 'auto',
                                                                padding: '3px 15px',
                                                                fontSize: '14px',
                                                            }}
                                                            className={
                                                                thoughtIsEnabled
                                                                    ? 'crontab_button_enable mt-3'
                                                                    : 'crontab_button_disable mt-3'
                                                            }
                                                            onClick={() => {
                                                                setThoughtIsEnabled(true)
                                                            }}
                                                        >
                                                            {thoughtIsEnabled ? 'Selected' : 'Select +'}
                                                        </Button>

                                                        {thoughtIsEnabled && (
                                                            <Button
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    borderRadius: '5px',
                                                                    fontWeight: 500,
                                                                    textTransform: 'none',
                                                                    display: 'flex',
                                                                    margin: 'auto',
                                                                    display: 'none',
                                                                    padding: '3px 15px',
                                                                    fontSize: '14px',
                                                                }}
                                                                className="crontab_remove_button mt-2"
                                                                onClick={() => {
                                                                    setThoughtIsEnabled(false)
                                                                }}
                                                            >
                                                                {'remove x'}
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        margin: 'auto',
                                                        padding: '20px 0px 20px 10px',
                                                        color: !thoughtIsEnabled ? 'lightgray' : 'black',
                                                    }}
                                                    className="responsive-width"
                                                >
                                                    <div
                                                        style={{
                                                            fontSize: '20px',
                                                            fontWeight: '500',
                                                            marginLeft: '20px',
                                                        }}
                                                    >
                                                        Kaitongo Thought Leadership Frequency
                                                    </div>

                                                    <CronTab
                                                        isEnabled={thoughtIsEnabled}
                                                        setIsEnabled={setThoughtIsEnabled}
                                                        cronTabValue={thoughtCronTabValue}
                                                        setCronTabValue={setThoughtCronTabValue}
                                                        isMypreference={true}
                                                    />
                                                </div>
                                            </div>

                                            <div className="d-md-flex d-sm-block align-items-center">
                                                <div className="responsive_cron">
                                                    <div
                                                        className="crontab_container"
                                                        style={{
                                                            border: '1px solid lightgray',
                                                            borderRadius: '5px',
                                                            padding: '20px',
                                                            height: '180px',
                                                        }}
                                                        title="Click the Select button to allow newsletter scheduling."
                                                    >
                                                        <div
                                                            style={{
                                                                fontSize: '18px',
                                                                fontWeight: '500',
                                                            }}
                                                        >
                                                            Social Media Content
                                                        </div>
                                                        <div
                                                            style={{
                                                                fontSize: '14px',
                                                                fontWeight: 400,
                                                            }}
                                                        >
                                                            Get relevant, ready-to-post LinkedIn posts
                                                        </div>

                                                        <Button
                                                            style={{
                                                                cursor: 'pointer',
                                                                borderRadius: '5px',
                                                                fontWeight: 500,
                                                                textTransform: 'none',
                                                                display: 'flex',
                                                                margin: 'auto',
                                                                padding: '3px 15px',
                                                                fontSize: '14px',
                                                            }}
                                                            className={
                                                                isEnabled
                                                                    ? 'crontab_button_enable mt-3'
                                                                    : 'crontab_button_disable mt-3'
                                                            }
                                                            onClick={() => {
                                                                setIsEnabled(true)
                                                            }}
                                                        >
                                                            {isEnabled ? 'Selected' : 'Select +'}
                                                        </Button>

                                                        {isEnabled && (
                                                            <Button
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    borderRadius: '5px',
                                                                    fontWeight: 500,
                                                                    textTransform: 'none',
                                                                    display: 'flex',
                                                                    margin: 'auto',
                                                                    display: 'none',
                                                                    padding: '3px 15px',
                                                                    fontSize: '14px',
                                                                }}
                                                                className="crontab_remove_button mt-2"
                                                                onClick={() => {
                                                                    setIsEnabled(false)
                                                                }}
                                                            >
                                                                {'remove x'}
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        margin: 'auto',
                                                        padding: '20px 0px 20px 10px',
                                                        color: !isEnabled ? 'lightgray' : 'black',
                                                    }}
                                                    className="responsive-width"
                                                >
                                                    <div
                                                        style={{
                                                            fontSize: '20px',
                                                            fontWeight: '500',
                                                            marginLeft: '20px',
                                                        }}
                                                    >
                                                        Kaitongo Social Media Content Frequency
                                                    </div>

                                                    <CronTab
                                                        isEnabled={isEnabled}
                                                        setIsEnabled={setIsEnabled}
                                                        cronTabValue={cronTabValue}
                                                        setCronTabValue={setCronTabValue}
                                                        isMypreference={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </div>
        </BaseScreen>
    )
}
