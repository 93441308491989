import { SET_LOADING } from './types'

export const setLoadingTrue = () => {
    return dispatch => {
        dispatch({
            type: SET_LOADING,
            payload: true,
        })
    }
}
export const setLoadingFalse = () => {
    return dispatch => {
        dispatch({
            type: SET_LOADING,
            payload: false,
        })
    }
}
