//expects an array of ids or object with value
export const getIndustryString = industries => {
    let urlString
    if (industries.length > 0) {
        urlString = 'industries='
        industries.forEach((arr, index) => {
            //it is an object or a number
            let indId
            if (arr.value) {
                indId = arr.value
            } else {
                indId = arr
            }

            if (index !== industries.length - 1) {
                urlString = urlString.concat(`${indId},`)
            } else {
                urlString = urlString.concat(`${indId}`)
            }
        })
    } else {
        urlString = 'industries'
    }
    return urlString
}

//expects an array of ids or object with value
export const getSectorString = sectors => {
    let urlString
    if (sectors.length > 0) {
        urlString = 'sectors='
        sectors.forEach((arr, index) => {
            //it is an object or a number
            let indId
            if (arr.value) {
                indId = arr.value
            } else {
                indId = arr
            }

            if (index !== sectors.length - 1) {
                urlString = urlString.concat(`${indId},`)
            } else {
                urlString = urlString.concat(`${indId}`)
            }
        })
    } else {
        urlString = 'sectors'
    }
    return urlString
}

export const getCompanyString = companies => {
    let urlString
    if (companies.length > 0) {
        urlString = 'companies='
        companies.forEach((arr, index) => {
            if (index !== companies.length - 1) {
                urlString = urlString.concat(`${arr.value},`)
            } else {
                urlString = urlString.concat(`${arr.value}`)
            }
        })
    } else {
        urlString = 'companies'
    }
    return urlString
}

//expects an object with value as number 1, 2, or 3
export const getRegionString = countries => {
    let urlString
    if (countries.length > 0) {
        urlString = 'countries='
        countries.forEach((arr, index) => {
            if (index !== countries.length - 1) {
                urlString = urlString.concat(`${arr.value},`)
            } else {
                urlString = urlString.concat(`${arr.value}`)
            }
        })
    } else {
        urlString = 'countries'
    }
    return urlString
}

export const getRegString = regions => {
    let urlString
    if (regions.length > 0) {
        urlString = 'regions='
        regions.forEach((arr, index) => {
            if (index !== regions.length - 1) {
                urlString = urlString.concat(`${arr.value},`)
            } else {
                urlString = urlString.concat(`${arr.value}`)
            }
        })
    } else {
        urlString = 'regions'
    }
    return urlString
}

export const getProvinceString = provinces => {
    let urlString
    if (provinces.length > 0) {
        urlString = 'provinces='
        provinces.forEach((arr, index) => {
            if (index !== provinces.length - 1) {
                urlString = urlString.concat(`${arr.value},`)
            } else {
                urlString = urlString.concat(`${arr.value}`)
            }
        })
    } else {
        urlString = 'provinces'
    }
    return urlString
}

export const getSourceString = sources => {
    let urlString
    if (sources.length > 0) {
        urlString = 'sources='
        sources.forEach((arr, index) => {
            if (index !== sources.length - 1) {
                urlString = urlString.concat(`${arr.value},`)
            } else {
                urlString = urlString.concat(`${arr.value}`)
            }
        })
    } else {
        urlString = 'sources'
    }
    return urlString
}

export const getSubcategoriesString = (subcategories, industries) => {
    if (industries.length === 0 || subcategories.length === 0) {
        return 'subcategories&industries'
    }
    let subCatArray = []
    subcategories.forEach(sub => {
        subCatArray.push(sub.id)
    })
    let subCatString = subCatArray.join(',')
    let indString = industries.join(',')
    return `subcategories=${subCatString}&industries=${indString}`
}

export const getSubcategoriesIdsString = subcategories => {
    if (subcategories.length === 0) {
        return ''
    } else {
        const subCatIdsArray = subcategories.map(subCat => subCat.value)
        return `=${subCatIdsArray.join(',')}`
    }
}

// util functions
export const opportunityTypeChecked = (opportunityTypes, id) => {
    return opportunityTypes.some(function(el) {
        return el.id === id
    })
}

export const getPullTypeString = pullType => {
    if (pullType === 'all') {
        return 'pullType'
    } else {
        return 'pullType=' + pullType
    }
}

//expect an array of film objects
export const getLawFirmString = firms => {
    if (firms.length === 0) {
        return 'lawfirms'
    } else {
        let firmsMapped = firms.map(item => item.value)
        console.log(firmsMapped)
        return 'lawfirms=' + firmsMapped.join(',')
    }
}

export const getTopicsString = topics => {
    let urlString
    if (topics.length > 0) {
        urlString = 'topics='
        topics.forEach((arr, index) => {
            if (index !== topics.length - 1) {
                urlString = urlString.concat(`${arr.value},`)
            } else {
                urlString = urlString.concat(`${arr.value}`)
            }
        })
    } else {
        urlString = 'topics'
    }
    return urlString
}

export const getReviewString = review => {
    if (review === 'both') {
        return ''
    } else if (review === 'notUnderReview') {
        return '=notUnderReview'
    } else if (review === 'underReview') {
        return '=underReview'
    }
}

export const getSearchString = string => {
    if (string === '') {
        return 'searchText'
    } else {
        return `searchText=${string.toLowerCase()}`
    }
}
