import React, { PureComponent } from 'react'
import './MenuModal.css'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import withModal from '../../hoc/withModal'
import { FiMenu } from 'react-icons/fi'
import Sidebar from '../Sidebar/Sidebar'
import KLogo from '../../assets/images/K-logo.png'

class MenuModal extends PureComponent {
    render() {
        const { isModalOpen, toggleModal } = this.props
        return (
            <div>
                <span className="menu-modal-icon" onClick={toggleModal}>
                    <FiMenu className="nav-icon" fontSize="27px" />
                </span>
                <Modal isOpen={isModalOpen} toggle={toggleModal} className="menu-modal">
                    <ModalHeader toggle={toggleModal}>
                        <img src={process.env.REACT_APP_MEDIA_URL + KLogo} alt="" className="img-fluid" />
                    </ModalHeader>
                    <ModalBody>
                        <Sidebar className="modal-nav" />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggleModal}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default withModal(MenuModal)
