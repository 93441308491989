import React, { PureComponent } from 'react'
import BaseScreen from '../BaseScreen/BaseScreen'
import LoginGraphic from './graphics/login.JPG'
import MobileGraphic from './graphics/mobile.png'
import './FaqScreen.css'

class FaqScreen extends PureComponent {
    render() {
        return (
            <BaseScreen>
                <div className="faq__container">
                    <h1 className="faq__heading">Frequently Asked Questions </h1>

                    <div className="faq__ContentsContainer">
                        <h1 className="faq__heading">Contents</h1>
                        <p className="faq__ContentsSection">
                            <a href="#gettingStarted">Getting Started</a>
                        </p>
                        <p className="faq__ContentsSubSection">
                            <a href="#howDoIAccess">How do I access Kaitongo</a>
                        </p>
                        <p className="faq__ContentsSubSection">
                            <a href="#forgotMyLogin">I forgot my login / password</a>
                        </p>

                        <p className="faq__ContentsSection">
                            <a href="#learnMore">Learning about Kaitongo</a>
                        </p>
                        <p className="faq__ContentsSubSection">
                            <a href="#whatIsKaitongo">What is Kaitongo?</a>
                        </p>
                        <p className="faq__ContentsSubSection">
                            <a href="#howToUse">How do I use the Kaitongo app?</a>
                        </p>
                        <p className="faq__ContentsSubSubSection">
                            <a href="#howToSubscribe">How can I subscribe to more sectors?</a>
                        </p>
                        <p className="faq__ContentsSubSubSection">
                            <a href="#contentForLater">Can I save content items for later?</a>
                        </p>
                        <p className="faq__ContentsSubSubSection">
                            <a href="#originalContent">How do I view the full original content?</a>
                        </p>
                        <p className="faq__ContentsSubSubSection">
                            <a href="#twitter">
                                Below each content item, I see Twitter, LinkedIn and Email icons. What are those for?
                            </a>
                        </p>
                        <p className="faq__ContentsSubSubSection">
                            <a href="#lastTime">What happened to the content I saw last time I visited this app?</a>
                        </p>

                        <p className="faq__ContentsSubSection">
                            <a href="#helpMeUnderstand">Help me understand what I am seeing in Kaitongo?</a>
                        </p>
                        <p className="faq__ContentsSubSubSection">
                            <a href="#categories">How were the categories and subcategories selected?</a>
                        </p>
                        <p className="faq__ContentsSubSubSection">
                            <a href="#blueNews">
                                When I go to the News and Opportunity tab, I see content marked in blue and yellow
                                buttons across each item. What is this?
                            </a>
                        </p>
                        <p className="faq__ContentsSubSubSection">
                            <a href="#oneCompany">Why is there only one company listed on the My Companies page?</a>
                        </p>
                        <p className="faq__ContentsSubSubSection">
                            <a href="#conferenceDetails">
                                How can I find additional details about conferences that interest me?
                            </a>
                        </p>
                        <p className="faq__ContentsSubSubSection">
                            <a href="#conferenceCoverage">
                                What is the coverage period of conferences and how often do you update this page?
                            </a>
                        </p>
                        <p className="faq__ContentsSubSubSection">
                            <a href="#conferenceAdditional">
                                How do I find out additional details about a conference of interest?
                            </a>
                        </p>

                        <p className="faq__ContentsSection">
                            <a href="#trialProgram">What is the trial program?</a>
                        </p>
                        <p className="faq__ContentsSection">
                            <a href="#faqContactUs">Contact Us</a>
                        </p>
                        <p className="faq__ContentsSection">
                            <a href="#whatDoesItMean">What does Kaitongo mean?</a>
                        </p>

                        <p className="faq__ContentsSection" style={{ marginTop: '1rem' }}>
                            At Kaitongo, we believe in partnering with our clients. Feel free to reach out directly with
                            any questions or feedback at{' '}
                            <a href="mailto: support@kaitongo.com">support@kaitongo.com. </a>
                        </p>
                        <hr />
                    </div>

                    <div id="gettingStarted" className="anchorOffset" />
                    <div>
                        <h1 className="faq__heading">Getting Started</h1>

                        <div id="howDoIAccess" className="anchorOffset" />
                        <h1 className="faq__subHeading">How do I access Kaitongo? </h1>
                        <p>if you are already subscribed, two options: </p>
                        <p>
                            <strong>Email:</strong>
                        </p>
                        <p>
                            From one of our recent Kaitongo Insights emails, simply click on the header to bring you
                            into your personalized platform.{' '}
                        </p>
                        <p>
                            <strong>Platform:</strong>
                        </p>
                        <p>
                            Visit: <a href="https://app.kaitongo.com/"> https://app.kaitongo.com/ </a>
                        </p>
                        <p>Enter your username and password into the two boxes, then click the "Login" button. </p>
                        <div className="faq__graphicContainer">
                            <img
                                src={process.env.REACT_APP_MEDIA_URL + LoginGraphic}
                                alt="login screen"
                                className="faq__loginGraphic faq__325"
                            />
                        </div>
                        <p>
                            <i>
                                {' '}
                                For ease of future access, you can select the "Remember me" tickbox to skip this login
                                page.{' '}
                            </i>
                        </p>

                        <div id="forgotMyLogin" className="anchorOffset" />
                        <h1 className="faq__subHeading">I forgot my login/password</h1>
                        <p>
                            f you cannot remember your login details, click on the "Forgot password?" link below the
                            Sign In button, and it will generate a reset password email to the email address we have in
                            our database.{' '}
                        </p>
                        <ul>
                            <li>
                                If you have any problems, let us know:{' '}
                                <a href="mailto:support@kaitongo.com">support@kaitongo.com</a>{' '}
                            </li>
                        </ul>
                    </div>

                    <div id="learnMore" className="anchorOffset" />
                    <div>
                        <h1 className="faq__heading">Learning about Kaitongo</h1>
                        <div id="whatIsKaitongo" className="anchorOffset" />
                        <h1 className="faq__subHeading">What is Kaitongo? </h1>
                        <p>
                            Kaitongo leverages AI and human intelligence to help you take industry and client
                            intelligence and translate it to real-life business value.{' '}
                        </p>
                        <div className="faq__graphicContainer">
                            <img
                                src={process.env.REACT_APP_MEDIA_URL + MobileGraphic}
                                alt="login screen"
                                className="faq__loginGraphic faq__500"
                            />
                        </div>
                        <p>
                            In the professional services world, billings/revenue generation are a direct result of depth
                            of a client relationship. Client experience is significantly improved by:{' '}
                        </p>
                        <ul>
                            <li>Having more meaningful, insightful client interactions </li>
                            <li>Actively demonstrating thought leadership on issues relevant to client markets. </li>
                        </ul>
                        <p>
                            <strong>
                                {' '}
                                We know that it takes approximately twelve contact points with a potential client to get
                                a meeting. Kaitongo empowers our users to make those contact points more meaningful.
                            </strong>{' '}
                            Our clients use us to:{' '}
                        </p>
                        <ul>
                            <li>News and related action statements </li>
                            <li>
                                Sector information, including definition and key statistics such as IPOs and financings{' '}
                            </li>
                            <li>Upcoming conferences </li>
                            <li>Recent transactions </li>
                            <li>List of sector specific associations </li>
                        </ul>
                        <p>
                            <strong>
                                Kaitongo helps you turn insights into conversations and intelligence into action.
                            </strong>
                        </p>

                        <div id="howToUse" className="anchorOffset" />
                        <h1 className="faq__subHeading">How do I use the Kaitongo app?</h1>

                        <div id="howToSubscribe" className="anchorOffset" />
                        <h2>How can I subscribe to more sectors? </h2>
                        <p>
                            Send us your request at <a href="mailto:client@kaitongo.com"> client@kaitongo.com</a> with
                            details of which sectors you would like to add, and we will get back to you.{' '}
                        </p>

                        <div id="contentForLater" className="anchorOffset" />
                        <h2>Can I save content items for later?</h2>
                        <p>Yes. Simply: </p>
                        <ol>
                            <li>
                                Click on Bookmark button on Industry page or “Save Opportunity” on the News and
                                Opportunity page{' '}
                            </li>
                            <li>
                                View such items by clicking “Saved” under “News and Opportunity” from the navigation bar
                                on the left{' '}
                            </li>
                            <li>
                                Items in your “Saved” folder will be retained while the rest will be continuously
                                replaced with newer items.{' '}
                            </li>
                        </ol>

                        <div id="originalContent" className="anchorOffset" />
                        <h2>How do I view the full original content? </h2>
                        <p>
                            You can click on the headline of the content item to access the full text from the original
                            source, which opens the original source article in a separate browser tab.{' '}
                        </p>

                        <div id="twitter" className="anchorOffset" />
                        <h2>Below each content item, I see Twitter, LinkedIn and Email icons. What are those for? </h2>
                        <p>
                            These icon buttons allow you to share the content. LinkedIn and Twitter buttons let you
                            share the content via the respective social media platform. Clicking on the button will take
                            you to the LinkedIn or Twitter account logged into on the device you are using. Please make
                            sure to log in on the respective platform before clicking the action button.{' '}
                        </p>
                        <p>The email button lets you share the particular content item through your email client. </p>

                        <div id="lastTime" className="anchorOffset" />
                        <h2>What happened to the content I saw last time I visited this app? </h2>
                        <p>We strive to keep the most relevant content available for your view in this app. </p>
                        <p>
                            In many instances, you can still find this content. For example, under the News and
                            Opportunity tab you can find all the previous content.
                        </p>
                        <ol>
                            <li>Click “News and Opportunity” from the navigation bar on the top left </li>
                            <li>Scroll through the recent content items and their corresponding actions </li>
                            <li>
                                Each item is categorized according to the topics addressed and the action statement it
                                represents{' '}
                            </li>
                            <li>
                                Click on the buttons at the top of the page, above news items, to switch to other
                                sectors in your subscription{' '}
                            </li>
                        </ol>
                        <p>
                            We are assessing whether a search feature is of use on this page. Any feedback you provide
                            will be immensely useful.{' '}
                        </p>
                    </div>

                    <div id="helpMeUnderstand" className="anchorOffset" />
                    <div>
                        <h1 className="faq__heading">Help me understand what I am seeing in Kaitongo? </h1>

                        <div id="categories" className="anchorOffset" />
                        <h2>How were the categories and subcategories selected? </h2>
                        <p>
                            We developed a proprietary decision tree system that is the basis for the insights extracted
                            and actionable cues published. We selected these categories and subcategories after careful
                            discussions with lawyers, law librarians, and business analysts. We are open to inputs and
                            feedback on improving these tags. Please email{' '}
                            <a href="mailto:content@kaitongo.com"> content@kaitongo.com</a> with any comments you may
                            have on specific tags.{' '}
                        </p>

                        <div id="blueNews" className="anchorOffset" />
                        <h2>
                            When I go to the News and Opportunity tab, I see content marked in blue and yellow buttons
                            across each item. What is this?{' '}
                        </h2>
                        <p>
                            The buttons represent the category and sub-category tags assigned to the content. Blue
                            represents broad categories and yellow indicates sub-categories{' '}
                        </p>

                        <div id="oneCompany" className="anchorOffset" />
                        <h2>Why is there only one company listed on the MyCompanies page? </h2>
                        <p>
                            We know that users would like to have curated company content as well as sector content. We
                            are not offering company coverage during the free trial period. A single company is featured
                            on the “My Companies” page so you can see the kind of coverage available by clicking on the
                            feature company. Please contact us at{' '}
                            <a href="mailto:support@kaitongo.com"> support@kaitongo.com</a> to discuss your options for
                            company coverage.{' '}
                        </p>

                        <div id="conferenceDetails" className="anchorOffset" />
                        <h2>How can I find additional details about conferences that interest me? </h2>
                        <p>
                            You can find the complete list of conferences and their details under the “Conferences” tab
                            in the top left-hand corner of the navigation bar. Simply select the sector, and then you
                            will be able to see the current and upcoming conferences.{' '}
                        </p>

                        <div id="conferenceCoverage" className="anchorOffset" />
                        <h2>What is the coverage period of conferences and how often do you update this page? </h2>
                        <p>
                            We select conferences scheduled to take place in the next 18 months, and we update these on
                            a monthly basis. As the conference date passes, the conference is removed from the page.{' '}
                        </p>

                        <div id="conferenceAdditional" className="anchorOffset" />
                        <h2>How do I find out additional details about a conference of interest? </h2>
                        <p>
                            You can click on the name of the conference to access the official website, which opens in a
                            separate browser tab.{' '}
                        </p>
                    </div>

                    <h1 id="trialProgram" className="faq__heading">
                        What is the trial program?{' '}
                    </h1>
                    <p>
                        We are offering this free trial for 90 days in order to ensure this product is delivering to our
                        client's needs. Our free trial typically lasts for 90 days, but reach out to your support
                        representative or email <a href="mailto:support@kaitongo.com"> support@kaitongo.com</a> if you
                        are not sure how much time you have remaining.{' '}
                    </p>

                    <h1 id="faqContactUs" className="faq__heading">
                        Contact Us{' '}
                    </h1>
                    <p>Would be great to hear from you! </p>
                    <p>Here's how you can reach us: </p>
                    <p>
                        <strong> Email:</strong> <a href="mailto:support@kaitongo.com">support@kaitongo.com</a>
                    </p>

                    <h1 id="whatDoesItMean" className="faq__heading">
                        What does Kaitongo mean?
                    </h1>
                    <p>For those curious about the name:</p>
                    <p>
                        Kaitongo is a combination of <strong>Kaitono</strong>, the Maori word for Client, and{' '}
                        <strong>Iungo</strong>, the Latin word for connect.{' '}
                    </p>
                </div>
            </BaseScreen>
        )
    }
}

export default FaqScreen
