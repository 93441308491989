import React, { PureComponent } from 'react'
import { getTrans } from '../../utils'
import AuthWrapper from '../../wrappers/AuthWrapper/AuthWrapper'
import { validateForm } from '../../utils/validateForm'
import { Form, Formik } from 'formik'
import RenderField from '../../form-elements/RenderField'
import { Button } from 'reactstrap'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import dotProp from 'dot-prop-immutable'
import '../../wrappers/AuthWrapper/AuthWrapper.css'
import Logo from '../../components/Logo/Logo'
import '../../wrappers/AuthWrapper/AuthWrapper.css'
import LoginFooter from '../../wrappers/LoginFooter'
const fields = [
    {
        label: 'Email',
        value: '',
        name: 'email',
        component: 'charField',
        placeholder: 'Enter your email',
        type: 'email',
        required: true,
    },
]

class ForgotPasswordScreen extends PureComponent {
    state = {
        nonFieldErrors: [],
        successMessage: '',
    }

    onSubmit = (values, { setSubmitting, setErrors }) => {
        setSubmitting(false)
        axios
            .post('/accounts/password-reset/', values)
            .then(response => {
                console.log('reset password response', response)
                this.setState({
                    successMessage: getTrans('You will receive an email with a link to reset your password.'),
                })
            })
            .catch(error => {
                const errorData = dotProp.get(error, 'response.data') || {}
                setErrors(errorData)
            })
    }

    render() {
        let userEmail = ''
        if (this.props.location.search.includes('email')) {
            userEmail = this.props.location.search.split('=')[1]
        }

        return (
            <AuthWrapper title={getTrans('Recover your password')}>
                <Formik
                    initialValues={{
                        email: userEmail,
                        type: 'forgot',
                    }}
                    validate={values => validateForm(values, fields)}
                    onSubmit={this.onSubmit}
                    render={({ status, isSubmitting, ...formProps }) => (
                        <Form>
                            {fields.map(field => {
                                return RenderField(field, formProps)
                            })}
                            {this.state.nonFieldErrors.map(error => (
                                <div className="input-feedback" key={error}>
                                    {error}
                                </div>
                            ))}
                            <div>{this.state.successMessage}</div>
                            <div className="text-right">
                                <Button
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                    style={{ width: '100%', backgroundColor: '#1b264c' }}
                                >
                                    <p className="loginForm__buttonText">
                                        {this.state.successMessage
                                            ? getTrans('RESEND RECOVERY EMAIL')
                                            : getTrans('RECOVER NOW')}
                                    </p>
                                </Button>
                            </div>
                        </Form>
                    )}
                />

                {/*<DefaultForm*/}
                {/*onSubmit={this.onSubmitClick}*/}
                {/*formName="forgot-password"*/}
                {/*submitLabel={getTrans('Send')}*/}
                {/*fields={fields}*/}
                {/*/>*/}
            </AuthWrapper>
        )
    }
}

export default withRouter(ForgotPasswordScreen)
