import React, { useState, useEffect, useRef } from 'react'
import DropArrow from '../../assets/icons/carret-down.svg'
import { AiOutlineClose } from 'react-icons/ai'

export default function CategoriesDropDown(props) {
    const [expanded, setexpanded] = useState(false)
    const [selectedSubCategories, SetSelectedSubCategories] = useState([])
    const { isStorySelected, isFindAndShare, removeSubCategory } = props
    const [filterText, setFilterText] = useState('')
    const wrapperRef = useRef(null)
    const focusRef = useRef()

    const categoryinput = (
        <React.Fragment>
            <input
                type="text"
                ref={focusRef}
                placeholder="Select SubCategories"
                value={filterText}
                onChange={e => {
                    setFilterText(e.target.value)
                    // setexpanded(!expanded)
                }}
                onClick={isStorySelected ? null : () => setexpanded(!expanded)}
                style={{
                    width: '-webkit-fill-available',
                    border: 'none',
                    outline: 'none',
                }}
            />
        </React.Fragment>
    )

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setexpanded(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [wrapperRef])

    useEffect(() => {
        let arrTemp = []
        if (!isFindAndShare) {
            props.subCategory && props.subCategory.forEach(sub => arrTemp.push(sub.id))
            SetSelectedSubCategories(arrTemp)
        }
    }, [props.subCategory])

    if (!isFindAndShare && !props.subCategory) {
        return <div>Category Error</div>
    }

    const categoryMap = (
        <React.Fragment>
            {/* {isFindAndShare &&
                props.category.map(practiceArea => {
                    return (
                        <div key={practiceArea} className="categoryTagAlt">
                            <span>{practiceArea.name}</span>
                        </div>
                    )
                })} */}
            {isFindAndShare &&
                props.subcategory.map(val => {
                    return (
                        <div key={val} className={isFindAndShare ? 'subcategoryTag' : 'subcategoryTagAlt'}>
                            <span>{val.name}</span>
                        </div>
                    )
                })}
        </React.Fragment>
    )

    const subcategoryMap =
        props.subCategory &&
        props.subCategory.map(sub => {
            return (
                <React.Fragment key={sub.id}>
                    <div className="subcategoryTagAlt">
                        <span>
                            <span>{sub.name}</span>
                            {!isFindAndShare && (
                                <span
                                    onClick={
                                        isStorySelected ? null : () => props.handleSubCategoryChange(sub.name, sub.id)
                                    }
                                    className="categoryX"
                                >
                                    {' x'}{' '}
                                </span>
                            )}
                        </span>
                    </div>
                </React.Fragment>
            )
        })

    const selectablesMap =
        props.allSubCategoriesOnly &&
        props.allSubCategoriesOnly
            .filter(sub => sub.label.toLowerCase().includes(filterText.toLowerCase()))
            .map(sub => {
                return (
                    <div
                        className="multiSelectDropDown__subCat"
                        key={sub.value}
                        style={
                            selectedSubCategories.find(arr => arr === sub.value) ? { backgroundColor: '#f4f4f4' } : null
                        }
                        onClick={
                            isStorySelected
                                ? null
                                : () => {
                                      props.handleSubCategoryChange(sub.label, sub.value)
                                      setFilterText('')
                                      focusRef.current.focus()
                                  }
                        }
                    >
                        <p style={selectedSubCategories.find(arr => arr === sub.label) ? { color: '#197bbd' } : null}>
                            {sub.label}
                        </p>
                    </div>
                )
            })

    return (
        <div style={props.extraStyle} className={isFindAndShare ? 'category_container' : ''}>
            {!isFindAndShare ? (
                <div className={!props.isAddNews ? 'categoryWidget__labelContainer' : ''}>
                    {!props.isAddNews && <p>Business Events</p>}
                </div>
            ) : (
                <div className="newsCard20__oppHeading p">
                    <p className="newsCard20__oppHeading">Business Events</p>
                </div>
            )}
            <div className="multiSelectDropDown__ContainerAlt" style={expanded ? { zIndex: '2' } : null}>
                <div className="multiSelectDropDown__headingBoxAlt">
                    <div
                        className="newsCard20__categoriesContainerAlt"
                        style={{ margin: isFindAndShare ? '' : '0 4px' }}
                    >
                        {subcategoryMap && subcategoryMap.length === 0 ? (
                            !isFindAndShare && categoryinput
                        ) : (
                            <>
                                {isFindAndShare ? categoryMap : subcategoryMap}
                                {!isFindAndShare && categoryinput}
                            </>
                        )}
                    </div>
                    {!isFindAndShare && (
                        <div className="multiSelectDropDown__caratAlt" onClick={removeSubCategory}>
                            <AiOutlineClose style={{ fontSize: 'larger', color: '#197bbd' }} />
                        </div>
                    )}
                </div>

                {expanded && (
                    <div className="multiSelectDropDown__dropdownExpandedBoxAlt" ref={wrapperRef}>
                        {selectablesMap}
                    </div>
                )}
            </div>
        </div>
    )
}
