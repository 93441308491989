import React, { useState } from 'react'

const ImageComponent = ({ imageUrl, defaultImage, className, emailNewsLoader }) => {
    const [isImageError, setIsImageError] = useState(false)

    const handleImageError = () => {
        setIsImageError(true)
    }

    return (
        <div>
            <img
                className={className}
                src={isImageError ? defaultImage : imageUrl}
                alt="deafult-imag"
                onError={handleImageError}
                style={{ height: !emailNewsLoader ? '250px' : '150px' }}
            />
        </div>
    )
}

export default ImageComponent
