import React, { useEffect, useState } from 'react'
import BaseScreen from '../BaseScreen/BaseScreen'
import axios from 'axios'
import WhatsHappeningInMyIndustryV2 from '../../components/IndustryPage/WhatsHappeningInMyIndustryV2'
import IndustryAtAGlance from '../../components/IndustryPage/IndustryAtAGlance'
import { Circles } from 'react-loader-spinner'
import PreMeetingBriefModal from '../NewsFeedScreen/components/PreMeetingBriefModal'
import { getCookie } from '../../utils'
import IndustryHeaderSection from './IndustryHeaderSection'

export default function MyTopicScreen() {
    const [allSectors, setAllSectors] = useState([])
    const [selectedSector, setSelectedSector] = useState({ id: null, type: null })
    const [sectorNews, setSectorNews] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isNewsLoader, setIsNewsLoader] = useState(true)
    const [sectorInformation, setSectorInformation] = useState()
    const [newsGraph, setNewsGraph] = useState([])
    const [premeetingPreview, setPremeetingPreview] = useState(false)
    const [premeetingData, setPremeetingData] = useState('')
    const [preMeetingLoader, setPreMeetingLoader] = useState(false)
    const [featuredNews, setFeaturedNews] = useState([])
    const token = getCookie('csrftoken')

    useEffect(() => {
        axios
            .get('/endusers/enduser-taxonomies/')
            .then(response => {
                setAllSectors(response.data)
                if (response.data.length > 0) {
                    const defaultData = response.data
                    if (defaultData[0].industries.length > 0) {
                        setSelectedSector({
                            id: defaultData[0].industries[0].id,
                            type: defaultData[0].industries[0].type,
                        })
                    } else if (defaultData[1].sectors.length > 0) {
                        setSelectedSector({ id: defaultData[1].sectors[0].id, type: defaultData[1].sectors[0].type })
                    } else if (defaultData[2].topics.length > 0) {
                        setSelectedSector({ id: defaultData[2].topics[0].id, type: defaultData[2].topics[0].type })
                    } else {
                        setIsLoading(false)
                    }
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    useEffect(() => {
        if (selectedSector.id) {
            setIsLoading(true)
            setIsNewsLoader(true)
            getSectorInfoNews()
        }
    }, [selectedSector.id])

    const getSectorInfoNews = () => {
        axios
            .get(`/news/my-topics/?type=${selectedSector.type}&id=${selectedSector.id}`)
            .then(res => {
                setSectorInformation(res.data)
                setSectorNews(res.data)
                setNewsGraph(res.data.chartData)
                axios
                    .get(`/news/topics/featured-companies/?type=${selectedSector.type}&id=${selectedSector.id}`)
                    .then(res => {
                        setFeaturedNews(res.data)
                    })
                    .catch(res => console.log(res))
            })
            .catch(err => console.log(err))
            .finally(() => {
                setIsLoading(false)
                setIsNewsLoader(false)
            })
    }

    const SectorsMap = allSectors.map(({ id, name, type }) => {
        return (
            <div
                key={type + '_' + id}
                onClick={() => {
                    setSelectedSector({ id: id, type: type })
                }}
                style={{
                    marginRight: '30px',
                    fontSize: '16px',
                    color: selectedSector.id === id && selectedSector.type === type ? '#3b7dba' : 'black',
                    fontWeight: selectedSector.id === id && selectedSector.type === type ? 'bold' : 'normal',
                    cursor: 'pointer',
                }}
            >
                {name}
            </div>
        )
    })

    let interval

    const getPreMeetingData = () => {
        setPremeetingPreview(true)

        setTimeout(() => {
            setPreMeetingLoader(true)
        }, 100)
        setTimeout(() => {
            const search = document.getElementById('pregenerating')
            if (search) {
                const searchPlaceholder = 'Generating...'
                let i = 0
                let placeholder = ''
                typing()
                function typing() {
                    clearInterval(interval)
                    interval = setInterval(() => {
                        if (search) {
                            placeholder += searchPlaceholder[i]
                            i++
                            search.value = placeholder
                            search.scrollTop = search.scrollHeight
                            if (i > searchPlaceholder.length - 1) {
                                clearInterval(interval)
                            }
                        }
                    }, 50)
                }
            }
        }, 500)

        const pay = { id: selectedSector.id, type: selectedSector.type }

        axios
            .post(`/news/premeeting-topic/premium/`, pay)
            .then(res => {
                setPremeetingData(res.data)
            })
            .catch(err => console.log(err))
            .finally(() => {
                setPreMeetingLoader(false)
            })
    }

    return (
        <BaseScreen>
            {allSectors.length > 0 && selectedSector.id !== null && (
                <>
                    <div className="indSelect__container">
                        <div className="indSelect__content">
                            <IndustryHeaderSection
                                allSectors={allSectors}
                                setSelectedSector={setSelectedSector}
                                selectedSector={selectedSector}
                            />
                        </div>
                    </div>
                    <div className="Industry_container">
                        <>
                            {!isLoading && (
                                <div>
                                    {newsGraph && (
                                        <div style={{ margin: '5% auto auto auto', width: '95%' }}>
                                            <IndustryAtAGlance
                                                Overview={sectorInformation}
                                                newsGraph={newsGraph}
                                                getPreMeetingData={getPreMeetingData}
                                                type={selectedSector.type}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}

                            {!isNewsLoader && (
                                <div>
                                    {sectorInformation &&
                                    sectorNews &&
                                    sectorNews.data &&
                                    sectorNews.data.length > 0 ? (
                                        <div style={{ width: '98%', marginLeft: '8px' }}>
                                            <WhatsHappeningInMyIndustryV2
                                                news={sectorNews}
                                                setNews={setSectorNews}
                                                isSector={true}
                                                name={sectorInformation.name}
                                                type={selectedSector.type}
                                            />
                                            <WhatsHappeningInMyIndustryV2
                                                news={featuredNews}
                                                setNews={setFeaturedNews}
                                                isFeatured={true}
                                                name={sectorInformation.name}
                                                type={selectedSector.type}
                                            />
                                        </div>
                                    ) : (
                                        <div style={{ marginTop: '2%' }}>
                                            <h1 style={{ textAlign: 'center' }}>
                                                No recent news for current preference. To change preference, Click My
                                                Preferences and update Topics/Sectors/Industries.
                                            </h1>
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    </div>
                </>
            )}

            {!isLoading && selectedSector.id === null && (
                <h1 style={{ textAlign: 'center' }}>
                    To see Topic news, click My Preferences and select Topics/Sectors/Industries.
                </h1>
            )}
            {(isLoading || isNewsLoader) && selectedSector.id !== null && (
                <div style={{ marginTop: allSectors.length === 0 ? '' : '5%' }}>
                    <Circles key={0} type="Grid" color="#adadad" height={80} width={80} />
                </div>
            )}

            <PreMeetingBriefModal
                premeetingData={premeetingData}
                setPremeetingPreview={setPremeetingPreview}
                premeetingPreview={premeetingPreview}
                preMeetingLoader={preMeetingLoader}
                companyInformation={sectorInformation}
                token={token}
            />
        </BaseScreen>
    )
}
