import React from 'react'
import { getTrans } from '../utils'
import { Link } from 'react-router-dom'

export default function LoginFooter() {
    return (
        <div style={{ position: 'absolute', marginTop: '85%' }}>
            <span className="loginForm__needHelpContactUs">
                <h3 className="loginForm__noAccount-firm f-14" style={{ marginTop: '10px' }}>
                    Need Help? Contact us at&nbsp;
                    <br />
                    <a
                        href="mailto:support@kaitongo.com"
                        className="loginForm__signUp-firm f-14"
                        style={{ color: 'white' }}
                    >
                        <u>support@kaitongo.com</u>
                    </a>
                </h3>
            </span>

            <div className="loginForm__legalRow">
                <Link to="/terms" className="mr-4 f-12" style={{ color: 'white' }}>
                    {getTrans('Terms')}
                </Link>{' '}
                <span
                    style={{
                        fontWeight: 300,
                        marginTop: '6px',
                        position: 'absolute',
                        height: '13px',
                        marginLeft: '5px',
                        marginRight: '5PX',
                        border: '1px solid rgba(255,255,255,0.4)',
                    }}
                />{' '}
                <Link to="/privacy" className="mr-4 f-12" style={{ marginLeft: '15px', color: 'white' }}>
                    {getTrans('Privacy')}
                </Link>{' '}
                <span
                    style={{
                        fontWeight: 300,
                        marginTop: '6px',
                        marginLeft: '5px',
                        position: 'absolute',
                        height: '13px',
                        border: '1px solid rgba(255,255,255,0.4)',
                    }}
                />{' '}
                <Link to="/saas" className="mr-4 f-12" style={{ marginLeft: '15px', color: 'white' }}>
                    {getTrans('SaaS')}
                </Link>
            </div>
            <div className="loginForm__legalRow">
                <span className="mr-4 f-10" style={{ fontSize: '10px' }}>
                    {getTrans('© 2023 Kaitongo Inc.')}
                </span>
            </div>
        </div>
    )
}
