import React, { useEffect } from 'react'

export default function CustomLoader(props) {
    const { progressContent } = props
    return (
        <div
            style={{
                fontWeight: 400,
                cursor: 'pointer',
                margin: '5px',
            }}
            className="font d-flex align-items-center"
        >
            <span className="round_loader" />{' '}
            <span style={{ marginLeft: '10px' }}>{progressContent ? progressContent : 'Working on it...'}</span>
        </div>
    )
}
