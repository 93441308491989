import axios from 'axios'
import { Modal, ModalBody } from 'reactstrap'
import React, { useState, useEffect } from 'react'
import { Button } from 'reactstrap'
import { toast } from 'react-toastify'
import { getCookie } from '../../../utils/index'
import SaveIcon from '@mui/icons-material/Save'
import ViewListIcon from '@mui/icons-material/ViewList'

export default function SaveViews(props) {
    const [saveModal, setSaveModal] = useState(false)
    const [viewModal, setViewModal] = useState(false)
    const [viewName, setViewName] = useState('')
    const [isShared, setIsShared] = useState(false)
    const [teamViews, setTeamViews] = useState([])
    const [ownViews, setOwnViews] = useState([])
    const token = getCookie('csrftoken')
    let selectedFilters = props.data

    let filtersToSave = {
        industries: [],
        sectors: [],
        companies: [],
        country: [],
        sources: '',
        subcategories: [],
        sent: 'notSent',
        deleted: 'notDeleted',
        featured: 'All',
        topics: [],
        underreview: 'both',
        searchtext: '',
        name: '',
        is_public: 0,
        // description: 'none'
    }

    const consolidateFilters = () => {
        if (selectedFilters.companies.length) {
            filtersToSave.companies = []
            selectedFilters.companies.forEach(element => {
                filtersToSave.companies.push(element.value)
            })
        }

        if (selectedFilters.industries.length) {
            filtersToSave.industries = []
            filtersToSave.sectors = []
            selectedFilters.industries.forEach(element => {
                if (element.type === 'Sector') {
                    filtersToSave.sectors.push(element.value)
                }
                if (element.type === 'Industry') {
                    filtersToSave.industries.push(element.value)
                }
            })
        }

        if (selectedFilters.subcategories.length) {
            filtersToSave.subcategories = []
            selectedFilters.subcategories.forEach(element => {
                filtersToSave.subcategories.push(element.value)
            })
        }
        if (selectedFilters.topics.length) {
            filtersToSave.topics = []
            selectedFilters.topics.forEach(element => {
                filtersToSave.topics.push(element.value)
            })
        }
        if (selectedFilters.sources.length) {
            filtersToSave.sources = ''
            selectedFilters.sources.forEach(element => {
                filtersToSave.sources += element.value
                filtersToSave.sources += ','
            })
        }
        filtersToSave.searchtext = selectedFilters.textSearch
        filtersToSave.sent = selectedFilters.sent.value
        filtersToSave.featured = selectedFilters.featured.value
        filtersToSave.country = selectedFilters.countries.map(each => each.value)
        filtersToSave.deleted = selectedFilters.deleted.value
        filtersToSave.underreview = selectedFilters.underReview.value
    }

    useEffect(() => {
        if (viewModal) {
            axios.get('/accounts/customviews/', { headers: { 'X-CSRFToken': token } }).then(response => {
                setOwnViews(response.data.ownviews)
                setTeamViews(response.data.teamviews)
            })
        }
    }, [viewModal])

    const submitView = () => {
        if (viewName === '') {
            toast('Enter View Name')
        } else {
            consolidateFilters()
            const resultArray = filtersToSave.country
                .filter(val => val.includes('Country'))
                .map(item => {
                    return parseInt(item.split('_')[1])
                })
            filtersToSave.country = resultArray
            filtersToSave.name = viewName
            filtersToSave.is_public = isShared === false ? false : true
            axios
                .post('/accounts/customviews/', filtersToSave, { headers: { 'X-CSRFToken': token } })
                .then(response => {
                    if (response.status === 201) {
                        toast('View Saved')
                        setSaveModal(false)
                    } else {
                        toast('Unable to Save')
                    }
                })
        }
    }

    const ApplyView = (e, id) => {
        e.preventDefault()
        axios
            .get(`/accounts/customviews/${id}/`, { headers: { 'X-CSRFToken': token } })
            .then(response => {
                const sel = response.data.region_hierarchy.regions.map(item => ({
                    ...item,
                    id: item.id,
                    type: 'Region',
                    countries: item.countries
                        ? item.countries.map(cty => ({
                              ...cty,
                              id: cty.id,
                              type: 'Country',
                              provinces: cty.provinces
                                  ? cty.provinces.map(pro => ({
                                        ...pro,
                                        id: pro.id,
                                        type: 'Province',
                                    }))
                                  : [],
                          }))
                        : [],
                }))

                const selReg = []
                sel.forEach(reg => {
                    const isRegionExpanded = reg.isExpanded || false

                    selReg.push({
                        value: reg.type + '_' + reg.id,
                        label: (
                            <div>
                                <div className="expand-icon">
                                    {reg.name}
                                    <span
                                        style={{
                                            display: reg.countries.length === 0 ? 'none' : '',
                                            marginLeft: '10px',
                                            textAlign: 'end',
                                            marginRight: 'auto',
                                            fontWeight: 600,
                                        }}
                                    >
                                        {/* {isRegionExpanded > 0 ? '-' : '+'} */}
                                    </span>
                                </div>{' '}
                            </div>
                        ),
                        type: reg.type,
                        isExpanded: true,
                    })

                    reg.countries.forEach(cty => {
                        const isCountryExpanded = cty.isExpanded || false

                        selReg.push({
                            value: cty.type + '_' + cty.id,
                            label: (
                                <div>
                                    <div className="expand-icon">
                                        {cty.name}
                                        <span
                                            style={{
                                                display: cty.provinces.length === 0 ? 'none' : '',
                                                marginLeft: '10px',
                                                fontWeight: 600,
                                            }}
                                        >
                                            {/* {isCountryExpanded > 0 ? '-' : '+'} */}
                                        </span>
                                    </div>{' '}
                                </div>
                            ),
                            type: cty.type,
                            isExpanded: true,
                        })

                        cty.provinces.forEach(pro => {
                            // allReg.push({
                            //     value: pro.id,
                            //     label: pro.name,
                            //     type: 'PROVINCE',
                            // })
                            selReg.push({
                                value: pro.type + '_' + pro.id,
                                label: (
                                    <div>
                                        <div className="expand-icon">
                                            {pro.name}
                                            <span
                                                style={{
                                                    // display: cty.provinces.length === 0 ? 'none' : '',
                                                    marginLeft: '10px',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {/* {isCountryExpanded > 0 ? '-' : '+'} */}
                                            </span>
                                        </div>{' '}
                                    </div>
                                ),
                                type: pro.type,
                                isExpanded: true,
                            })
                        })
                    })
                })

                const selectedLabels = selReg.map(option => option.value)
                const selectedRegionOptions = selReg
                    .filter(option => selectedLabels.includes(option.value))
                    .map(option => option)

                response.data.country = selectedRegionOptions
                props.updateFilters(response.data)
            })
            .then(setViewModal(false))
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
                <Button style={{ margin: '5px' }} onClick={() => setSaveModal(true)}>
                    Save View {<SaveIcon />}
                </Button>
            </div>
            <div>
                <Button style={{ margin: '5px' }} onClick={() => setViewModal(true)}>
                    All Views {<ViewListIcon />}{' '}
                </Button>
            </div>

            <Modal isOpen={saveModal} toggle={() => setSaveModal(!saveModal)} backdrop="static">
                <ModalBody>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header" style={{ width: '100% ' }}>
                                    <h5>Save Custom View</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={viewName}
                                                    onChange={e => setViewName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label />
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="flexSwitchCheckChecked"
                                                        value={isShared}
                                                        onChange={e => setIsShared(current => !current)}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexSwitchCheckChecked"
                                                    >
                                                        Share to Everyone
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row p-4">
                                        <div className="col-md-10 text-end">
                                            <div className="form-group">
                                                <button
                                                    className="btn btn-sm btn-dark"
                                                    onClick={e => setSaveModal(false)}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-md-2 text-center">
                                            <div className="form-group">
                                                <button className="btn btn-sm btn-primary" onClick={submitView}>
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={viewModal} toggle={() => setViewModal(!viewModal)} size="lg">
                <ModalBody>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header" style={{ width: '100%' }}>
                                    <h5>Publish to Platform Saved Views</h5>
                                </div>

                                <div className="card-body scroll">
                                    <div className="row">
                                        <div className="col-md-6 text-center">
                                            <h6>Teamwide Views</h6>
                                        </div>
                                        <div className="col-md-6 text-center">
                                            <h6>Created for me</h6>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="list-group">
                                                {teamViews.map(item => (
                                                    <a
                                                        key={item.id}
                                                        href="#"
                                                        className="list-group-item list-group-item-action"
                                                        onClick={event => ApplyView(event, item.id)}
                                                    >
                                                        {item.name}
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="list-group">
                                                {ownViews.map(item => (
                                                    <a
                                                        key={item.id}
                                                        href="#"
                                                        className="list-group-item list-group-item-action"
                                                        onClick={event => ApplyView(event, item.id)}
                                                    >
                                                        {item.name}
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}
