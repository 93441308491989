import React, { useEffect } from 'react'
import BaseScreen from '../../BaseScreen/BaseScreen'
import Grape from '../../NewsFeedScreen/components/Grapejs'
import AdminHeader from '../components/AdminHeader'

export default function AnalystCreateTemplate() {
    return (
        <BaseScreen>
            <AdminHeader currentPage={'createtemplates'} style={{ height: '20px' }} />
            <div style={{ marginTop: '150px' }}>
                <Grape />
            </div>
        </BaseScreen>
    )
}
