import React from 'react'

export default function RangeInput(props) {
    const { selectedValue, setSelectedValue, min, max, step, dividedBY } = props
    const handleIncrement = () => {
        const newValue = selectedValue + step
        if (newValue <= 100) {
            setSelectedValue(newValue)
        }
    }

    const handleDecrement = () => {
        const newValue = selectedValue - step
        if (newValue >= 10) {
            setSelectedValue(newValue)
        }
    }
    return (
        <div className="d-flex justify-content-between align-items-center">
            <button onClick={handleDecrement}>-</button>

            <input
                type="range"
                class="form-range"
                value={selectedValue}
                min={min}
                max={max}
                step={step}
                onChange={e => {
                    console.log(e.target.value)
                    setSelectedValue(Number(e.target.value))
                    const result = 110 - Number(e.target.value)
                    console.log(`If I choose ${Number(e.target.value)}, the result is ${result / dividedBY}`)
                }}
            />
            <button onClick={handleIncrement}>+</button>
        </div>
    )
}
