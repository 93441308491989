import axios from 'axios'
import html2pdf from 'html2pdf.js'
import React, { useEffect, useState } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import { FaFileDownload } from 'react-icons/fa'
import { FiMail } from 'react-icons/fi'
import { toast } from 'react-toastify'
import { Button, Form, Input, Label, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import { getCookie } from '../../../utils'

export default function NewPreMeetingBriefModal(props) {
    const [showSpinner, setShowSpinner] = useState(false)
    const [emailModal, setEmailModal] = useState(false)

    const {
        premeetingData,
        premeetingPreview,
        setPremeetingPreview,
        preMeetingLoader,
        companyInformation,
        isFindAndShare,
        conversationStarters,
        strategicPriorities,
    } = props

    const token = getCookie('csrftoken')
    const pdfDownLoad = () => {
        const element = document.getElementById('premeeting_content')

        // Configuration for html2pdf
        const options = {
            margin: 10,
            filename: 'downloaded_pdf.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        }

        html2pdf()
            .set(options)
            .from(element)
            .save()
    }

    const sendingMail = e => {
        e.preventDefault()
        setShowSpinner(true)
        const element = document.getElementById('premeeting_content')

        const htmlContent = element.innerHTML
        const singleLineString = htmlContent.replace(/\s+/g, ' ').trim()

        let formData = new FormData()
        let blob = new Blob([singleLineString], { type: 'text/html' })
        formData.append('htmlcontent', blob)
        formData.append('email', e.target.email.value)

        axios
            .post('/endusers/custom-newsletters/', formData, {
                headers: { 'X-CSRFToken': token, 'Content-Type': 'multipart/form-data' },
            })
            .then(res => {
                if (res.status === 200) {
                    setEmailModal(false)
                    setShowSpinner(false)
                    toast('Email sent')
                }
            })
            .catch(err => {
                toast('Email sending failed')
                setShowSpinner(false)
            })
    }

    return (
        <>
            <Modal isOpen={premeetingPreview} toggle={() => setPremeetingPreview(false)} size="xl">
                <div
                    className="modal_header_black"
                    style={{
                        background: 'white',
                        margin: '5px 15px 0px 15px',
                        padding: '5px 0px',
                        borderBottom: '1.5px solid lightgray',
                        color: 'black',
                        fontSize: '14px',
                    }}
                >
                    <b>Premeeting Summary</b>

                    <div>
                        {((premeetingData && premeetingData.news) ||
                            conversationStarters.length > 0 ||
                            strategicPriorities.length > 0) && (
                            <>
                                <span onClick={pdfDownLoad} className="modal_close_btn" style={{ marginRight: '20px' }}>
                                    <FaFileDownload />
                                </span>
                                <span
                                    onClick={() => setEmailModal(!emailModal)}
                                    className="modal_close_btn"
                                    style={{ marginRight: '20px' }}
                                >
                                    <FiMail />
                                </span>
                            </>
                        )}
                        <span
                            onClick={() => {
                                setPremeetingPreview(false)
                            }}
                            className="modal_close_btn"
                        >
                            <AiFillCloseCircle />
                        </span>
                    </div>
                </div>
                {preMeetingLoader ? (
                    <ModalBody>
                        <input
                            style={{
                                width: '97%',
                                height: '30px',
                                outline: 'none',
                                border: 'none',
                                fontSize: '14px',
                            }}
                            id="pregenerating"
                            value={''}
                        />
                    </ModalBody>
                ) : (
                    <ModalBody>
                        <div id="premeeting_content" className="premeeting_content" style={{ color: 'black' }}>
                            {conversationStarters.length > 0 && (
                                <div style={{ fontSize: '14px' }} className="conversation_starters">
                                    <h2 style={{ color: '#e56447', fontSize: '14px' }}>
                                        Conversation Starters: For your meeting with{' '}
                                        {companyInformation && companyInformation.name}
                                    </h2>
                                    {conversationStarters.map(val => (
                                        <div style={{ marginTop: '5px' }}>
                                            <div style={{ fontWeight: 'bold' }}>{val.Name}</div>
                                            <div style={{ paddingBottom: '5px' }}>{val.Summary}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {premeetingData && premeetingData.summary ? (
                                <div
                                    className="summary_part"
                                    style={{ marginTop: conversationStarters.length > 0 ? '10px' : '' }}
                                >
                                    <h2 style={{ color: '#e56447', fontSize: '14px' }}>Summary</h2>
                                    <p style={{ fontSize: '14px' }}>{premeetingData.summary}</p>
                                </div>
                            ) : (
                                <div className="summary_part">
                                    <h2>No News Available</h2>
                                </div>
                            )}

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                {premeetingData && premeetingData.news && premeetingData.news.companyNews && (
                                    <div className="company_news" style={{ marginRight: '2%' }}>
                                        <div style={{ color: 'black' }}>
                                            {premeetingData.news.companyNews.length > 0 && (
                                                <h2 style={{ color: '#e56447', fontSize: '14px' }}>
                                                    Relevant News: For your meeting with{' '}
                                                    {companyInformation && companyInformation.name}
                                                </h2>
                                            )}
                                            {premeetingData.news.companyNews.length > 0 &&
                                                premeetingData.news.companyNews.map(val => (
                                                    <div style={{ marginTop: '5px' }}>
                                                        <a
                                                            href={val.sourceUrl}
                                                            target="_blank"
                                                            style={{ color: 'black', fontSize: '14px' }}
                                                        >
                                                            <b>{val.title}</b>
                                                        </a>
                                                        <div
                                                            style={{
                                                                textAlign: 'justify',
                                                                color: 'black',
                                                                fontSize: '14px',
                                                            }}
                                                        >
                                                            {val.snippet}
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                fontSize: '14px',
                                                            }}
                                                        >
                                                            <a
                                                                target="_blank"
                                                                href={val.source_url ? val.source_url : ''}
                                                                style={{ textDecoration: 'none' }}
                                                            >
                                                                <span style={{ color: '#e56447' }}>
                                                                    {val.source_url &&
                                                                        val.source_url
                                                                            .match(
                                                                                /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/gim
                                                                            )[0]
                                                                            .replace(
                                                                                /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?/gim,
                                                                                ''
                                                                            )}
                                                                </span>
                                                            </a>
                                                            {val.articleDate && val.source_url && (
                                                                <span style={{ color: '#999999', marginLeft: '5px' }}>
                                                                    <>
                                                                        <span>&bull;</span> <i>{val.articleDate}</i>
                                                                    </>
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                )}
                            </div>

                            {strategicPriorities.length > 0 && (
                                <div style={{ fontSize: '14px', marginTop: '15px' }} className="conversation_starters">
                                    <h2 style={{ color: '#e56447', fontSize: '14px' }}>
                                        Legal Considerations: For your meeting with{' '}
                                        {companyInformation && companyInformation.name}
                                    </h2>

                                    {strategicPriorities.map(val => (
                                        <div style={{ marginTop: '5px' }}>
                                            <div style={{ fontWeight: 'bold' }}>{val.Name}</div>
                                            <div style={{ paddingBottom: '5px' }}>{val.Summary}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </ModalBody>
                )}
            </Modal>

            <Modal isOpen={emailModal} toggle={() => setEmailModal(!emailModal)}>
                <ModalHeader>
                    <strong>Email</strong>
                </ModalHeader>

                <ModalBody>
                    <Form onSubmit={e => sendingMail(e)}>
                        <Label>Email-ID</Label>
                        <Input type="email" name="email" />
                        <Button
                            type="submit"
                            variant="primary"
                            disabled={showSpinner ? true : false}
                            style={{ marginTop: '10px', float: 'right', textAlign: 'center' }}
                        >
                            {showSpinner && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ margin: '1px 5px 0px' }}
                                />
                            )}
                            Send Email
                        </Button>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}
