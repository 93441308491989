import axios from 'axios'
import html2pdf from 'html2pdf.js'
import React, { useEffect, useState } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import { FaFileDownload } from 'react-icons/fa'
import { FiMail } from 'react-icons/fi'
import { toast } from 'react-toastify'
import { Button, Form, Input, Label, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import ReactECharts from 'echarts-for-react'
import { getCookie } from '../../../utils'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import ApartmentIcon from '@mui/icons-material/Apartment'

export default function QuickReportPreview(props) {
    const token = getCookie('csrftoken')
    axios.defaults.headers.common['X-CSRFToken'] = token
    const [showSpinner, setShowSpinner] = useState(false)
    const [emailModal, setEmailModal] = useState(false)
    const [reportView, setReportView] = useState(null)
    const [isLoading, setLoader] = useState(true)

    const { jsonData = null, quickReportModal, setQuickReportModal, jsonURLID = null } = props

    useEffect(() => {
        if (jsonURLID) {
            axios
                .get(`/news/report-request/?id=${jsonURLID}`)
                .then(res => {
                    setReportView(res.data)
                })
                .catch(err => toast('Failed to Fetch Report!, please try again Later.'))
                .finally(() => setLoader(false))
        }
    }, [jsonURLID !== null])

    useEffect(() => {
        if (jsonData) {
            setReportView(jsonData)
            setLoader(false)
        }
    }, [jsonData !== null])

    const pdfDownLoad = () => {
        const element = document.getElementById('quick_report_content')

        // Configuration for html2pdf
        const options = {
            margin: 10,
            filename: 'downloaded_pdf.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { useCORS: true, scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        }

        html2pdf()
            .set(options)
            .from(element)
            .save()
    }

    const sendingMail = e => {
        e.preventDefault()
        setShowSpinner(true)
        const element = document.getElementById('quick_report_content')

        const htmlContent = element.innerHTML
        const singleLineString = htmlContent.replace(/\s+/g, ' ').trim()

        let formData = new FormData()
        let blob = new Blob([singleLineString], { type: 'text/html' })
        formData.append('htmlcontent', blob)
        formData.append('email', e.target.email.value)

        axios
            .post('/endusers/custom-newsletters/', formData, {
                headers: { 'X-CSRFToken': token, 'Content-Type': 'multipart/form-data' },
            })
            .then(res => {
                if (res.status === 200) {
                    setEmailModal(false)
                    setShowSpinner(false)
                    toast('Email sent')
                }
            })
            .catch(err => {
                toast('Email sending failed')
                setShowSpinner(false)
            })
    }

    const BarChart = ({ data, type }) => {
        const extractData = data => {
            const years = []
            const revenues = []
            const currencyTypes = []
            let overallCurrency = ''

            for (const key in data) {
                if (key === 'Currency') {
                    overallCurrency = String(data[key])
                }
                if (
                    key !== 'Currency' &&
                    (String(data[key]).toLowerCase() !== 'not available' || String(data[key]).toLowerCase() !== 'n/a')
                ) {
                    years.push(key)

                    const matchResult = String(data[key]).match(/[\d,.]+/)
                    if (matchResult) {
                        const amountStr = matchResult[0]
                        const currencyStr = String(data[key])
                            .replace(amountStr, '')
                            .trim()

                        // Parse the amount and handle commas
                        const amount = parseFloat(amountStr.replace(/,/g, ''))

                        revenues.push(amount)
                        currencyTypes.push(currencyStr)
                    }
                }
            }

            return { years, revenues, currencyTypes, overallCurrency }
        }

        const { years, revenues, currencyTypes, overallCurrency } = extractData(data)

        const option = {
            title: {
                text: type === 'revenue' ? 'Revenue (2021 - 2023)' : 'Revenue by Geography (2023)',
                // subtext: 'Fiscal Years 2022-2023',
                left: 'center',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                },
            },
            legend: {
                data: [`Revenue in ${overallCurrency} ${currencyTypes.length > 0 && currencyTypes[0]}`],
                top: 'bottom', // Move legend to the bottom
                itemWidth: 10, // Width of the legend item (icon)
                itemHeight: 10, // Height of the legend item (icon)
                textStyle: {
                    fontSize: 12, // Font size of legend text
                },
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '10%',
                containLabel: true,
            },
            xAxis: [
                {
                    type: 'category',
                    data: years,
                    axisLabel: {
                        formatter: function(value) {
                            return value.length > 10 ? value.substring(0, 10) + '...' : value
                        },
                    },
                },
            ],
            yAxis: [
                {
                    // type: 'value',
                    // name: 'Amount (in billions)',
                    axisLabel: {
                        show: false, // Hide the axis labels
                    },
                    splitLine: {
                        // Add splitLine configuration for grid lines
                        show: true,
                    },
                },
            ],
            series: [
                {
                    name: `Revenue in ${overallCurrency} ${currencyTypes.length > 0 && currencyTypes[0]}`,
                    type: 'bar',
                    barWidth: 70,
                    data: revenues,
                    label: {
                        show: true,
                        position: 'top',
                        // formatter: '{c} Bn', // Add units to the label
                    },
                },
            ],
        }

        return (
            <>
                <label
                    className="report-f18"
                    style={{
                        color: '#186ADE',
                        fontWeight: 500,
                        fontFamily: 'Montserrat',
                        display:
                            (overallCurrency === 'Not Available' || overallCurrency === 'N/A') &&
                            currencyTypes.length === 0
                                ? 'none'
                                : 'block',
                    }}
                >
                    {type === 'revenue' ? 'REVENUE ANALYSIS' : 'REVENUE BY GEOGRAPHY'}
                </label>
                <div
                    style={{
                        border: '1px solid #ccc',
                        width: '80%',
                        margin: 'auto',
                        borderRadius: '5px',
                        padding: '10px',
                        marginTop: '20px',
                        display:
                            (overallCurrency === 'Not Available' || overallCurrency === 'N/A') &&
                            currencyTypes.length === 0
                                ? 'none'
                                : 'block',
                        pageBreakInside: 'avoid',
                    }}
                >
                    <ReactECharts
                        option={option}
                        style={{ width: '100%', height: '300px', margin: 'auto', pageBreakInside: 'avoid' }}
                        className="report-f16"
                    />
                </div>
            </>
        )
    }

    return (
        <>
            <Modal
                isOpen={quickReportModal}
                toggle={() => {
                    setQuickReportModal(!quickReportModal)
                }}
                size="lg"
            >
                <div className="modal_header_black">
                    <span style={{ color: 'white' }}>Quick Report</span>

                    <div>
                        {reportView && reportView.data && (
                            <>
                                <span onClick={pdfDownLoad} className="modal_close_btn" style={{ marginRight: '20px' }}>
                                    <FaFileDownload />
                                </span>
                                <span
                                    onClick={() => setEmailModal(!emailModal)}
                                    className="modal_close_btn"
                                    style={{ marginRight: '20px' }}
                                >
                                    <FiMail />
                                </span>
                            </>
                        )}
                        <span
                            onClick={() => {
                                setQuickReportModal(false)
                            }}
                            className="modal_close_btn"
                        >
                            <AiFillCloseCircle />
                        </span>
                    </div>
                </div>
                <ModalBody style={{ height: '100%' }}>
                    {isLoading && <Spinner animation="border" role="status" />}
                    {reportView !== null && (
                        <div
                            id="quick_report_content"
                            className="quick_report_content"
                            style={{ padding: '10px', textAlign: 'justify' }}
                        >
                            {/* <div className="kaitongo_logo">
                                <img
                                    crossOrigin="*"
                                    src={reportView.kaitongo_logo}
                                    style={{ width: '300px', maxHeight: '80px' }}
                                    alt="kaitongo_logo"
                                />
                            </div>
                            <div style={{ marginTop: '30px', display: 'flex', alignItems: 'center' }}>
                                {reportView.logo && (
                                    <div style={{ width: '100px' }}>
                                        <img
                                            crossOrigin="*"
                                            src={reportView.logo}
                                            style={{ width: '50px', maxHeight: '50px' }}
                                            alt="report_logo"
                                        />
                                    </div>
                                )}
                                <div>
                                    <div className="report-f16" style={{ textDecoration: 'underline' }}>
                                        Quick {reportView.type && reportView.type} Report
                                    </div>
                                    {reportView.name && (
                                        <div className="report-f18" style={{ fontWeight: 600 }}>
                                            {reportView.name}
                                        </div>
                                    )}
                                </div>
                            </div> */}
                            <div className="A4">
                                <div className="kaitongo_logo" style={{ display: 'flex', justifyContent: 'end' }}>
                                    <img
                                        src={reportView.kaitongo_logo}
                                        style={{ width: '300px', maxHeight: '80px' }}
                                        alt="kaitongo_logo"
                                    />
                                </div>
                                <div className="report-t150" style={{ marginLeft: '50px' }}>
                                    <div className="report-f50" style={{ fontWeight: 800 }}>
                                        Quick
                                    </div>
                                    <div className="report-f50" style={{ fontWeight: 800 }}>
                                        {reportView.type && !reportView.type.toLowerCase().includes('company')
                                            ? 'Sector'
                                            : 'Company'}{' '}
                                        Report
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: '100px',
                                        marginLeft: '50px',
                                    }}
                                >
                                    <div>
                                        {reportView.logo && (
                                            <img
                                                src={reportView.logo}
                                                style={{ width: '100px', maxHeight: '100px' }}
                                                alt="report_logo"
                                            />
                                        )}
                                    </div>
                                    <div className="report-f40"> {reportView.name}</div>{' '}
                                </div>
                                {/* {reportView.detailed_data && reportView.detailed_data['Sector name'] && (
                                    <>
                                        <div style={{ marginLeft: '50px' }}>Prepared for:</div>

                                        <div style={{ marginLeft: '50px' }}>
                                            {reportView.detailed_data['Sector name']}
                                        </div>
                                    </>
                                )} */}

                                <div className="curved-circle" />
                            </div>
                            <div className="A4">
                                <div
                                    style={{
                                        paddingTop: '50px',
                                        marginLeft: '50px',
                                        marginTop: '10px',
                                        pageBreakBefore: 'always',
                                    }}
                                >
                                    Table of Contents
                                </div>
                                <div style={{ marginTop: '250px', display: 'flex', alignItems: 'center' }}>
                                    <div
                                        className="report-f100"
                                        style={{
                                            transform: 'rotate(270deg)',
                                            marginLeft: '-100px',
                                            color: '#186ADE',
                                        }}
                                    >
                                        CONTENTS
                                    </div>
                                    <div className="report-f14" style={{ textTransform: 'uppercase' }}>
                                        {reportView.quick_data && reportView.quick_data['Executive Summary'] && (
                                            <div>Executive Summary</div>
                                        )}
                                        {reportView.overview && (
                                            <div>{reportView.type && reportView.type} Overview</div>
                                        )}
                                        {reportView.quick_data &&
                                            (reportView.quick_data['Financial Analysis'] ||
                                                reportView.quick_data['Financial Summary']) && (
                                                <div>Financial Overview</div>
                                            )}
                                        {reportView.quick_data && reportView.quick_data['Financial Analysis'] && (
                                            <div style={{ marginLeft: '10px' }}>Revenue Analysis</div>
                                        )}
                                        {reportView.data && reportView.data.length > 0 && <div>Key Developments</div>}
                                    </div>
                                </div>
                            </div>
                            {reportView.quick_data && reportView.quick_data['Executive Summary'] && (
                                <>
                                    <div style={{ marginLeft: '10px', marginTop: '20px', pageBreakBefore: 'always' }}>
                                        <label
                                            className="report-f22"
                                            style={{
                                                color: '#186ADE',
                                                fontWeight: 500,
                                                fontFamily: 'Montserrat',
                                            }}
                                        >
                                            {/* <AutoAwesomeOutlinedIcon style={{ fontSize: '30px' }} /> */}
                                            <span style={{ marginLeft: '10px', textTransform: 'uppercase' }}>
                                                Executive Summary
                                            </span>
                                        </label>
                                        <div
                                            style={{
                                                marginTop: '10px',
                                                margin: '5px',
                                            }}
                                            className="executive_summary report-f14"
                                        >
                                            {/* {reportView.quick_data['Executive Summary']} */}
                                            {Object.keys(reportView.quick_data['Executive Summary']).map(key => {
                                                const theme = reportView.quick_data['Executive Summary'][key]
                                                if (theme.Summary !== 'No relevant news.') {
                                                    return (
                                                        <div
                                                            key={key}
                                                            style={{
                                                                padding: '10px',
                                                                background: '#186ADE',
                                                                color: 'white',
                                                                marginTop: '10px',
                                                            }}
                                                        >
                                                            <label className="report-f14" style={{ color: 'white' }}>
                                                                {theme['Theme Name']}
                                                            </label>
                                                            <ul
                                                                style={{
                                                                    margin: '0px',
                                                                }}
                                                                className="report-f14"
                                                            >
                                                                <li> {theme.Summary}</li>
                                                            </ul>
                                                        </div>
                                                    )
                                                }
                                                return null
                                            })}
                                        </div>
                                    </div>
                                </>
                            )}

                            {reportView.overview && (
                                <div style={{ pageBreakInside: 'avoid' }}>
                                    <div className="overview" style={{ marginTop: '30px' }}>
                                        <label
                                            className="report-f22"
                                            style={{
                                                color: '#186ADE',
                                                fontWeight: 500,
                                                fontFamily: 'Montserrat',
                                                textTransform: 'uppercase',
                                            }}
                                        >
                                            {reportView.type && reportView.type} Overview
                                        </label>
                                        <div
                                            className="report-f14"
                                            style={{
                                                marginTop: '10px',
                                                marginLeftv: '20px',
                                            }}
                                        >
                                            {reportView.overview}
                                        </div>
                                    </div>

                                    {reportView.quick_data && reportView.quick_data['Founded Year'] && (
                                        <div
                                            style={{
                                                width: '90%',
                                                borderRadius: '2px',
                                                margin: '20px auto',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                padding: '10px',
                                                textAlign: 'center',
                                                border: '1px solid lightgray',
                                                background: '#D9E2F1',
                                                boxShadow: '5px 5px 5px 0px #186ADE',
                                                pageBreakInside: 'avoid',
                                            }}
                                        >
                                            {reportView.quick_data['Founded Year']['Founded Year'] && (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <PowerSettingsNewIcon
                                                        style={{ fontSize: '60px', color: '#186ADE' }}
                                                    />
                                                    <div className="report-f14" style={{ marginLeft: '5px' }}>
                                                        <label style={{}}> Year of Establishment</label>
                                                        <div style={{ fontWeight: 500 }}>
                                                            {reportView.quick_data['Founded Year']['Founded Year']}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {reportView.quick_data['Founded Year']['Headquarters Location'] && (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <ApartmentIcon style={{ fontSize: '60px', color: '#186ADE' }} />
                                                    <div className="report-f14" style={{ marginLeft: '5px' }}>
                                                        <label> Headquarters</label>
                                                        <div style={{ fontWeight: 500 }}>
                                                            {
                                                                reportView.quick_data['Founded Year'][
                                                                    'Headquarters Location'
                                                                ]
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}

                            {/* {reportView.financial_data && (
                                <div className="financial_overview" style={{ marginTop: '30px' }}>
                                    <label className="report-f16">Financial Performance Overview</label>
                                    <table
                                        style={{
                                            width: '70%',
                                            borderRadius: '20px',
                                            background: '#F25022',
                                            color: 'white',
                                            margin: '20px auto',
                                        }}
                                    >
                                        {reportView.financial_data &&
                                            reportView.financial_data.revenue_and_net_income_data &&
                                            reportView.financial_data.revenue_and_net_income_data.revenue && (
                                                <thead>
                                                    {reportView.financial_data.fiscal_years && (
                                                        <tr
                                                            className="report-financial-height"
                                                            style={{ textAlign: 'center' }}
                                                        >
                                                            <th
                                                                className="report-f14"
                                                                style={{
                                                                    borderBottom: '1px solid white',
                                                                }}
                                                            />
                                                            {reportView.financial_data.fiscal_years.map(year => {
                                                                return (
                                                                    <th
                                                                        className="report-f14"
                                                                        style={{
                                                                            borderBottom: '1px solid white',
                                                                        }}
                                                                    >
                                                                        {year}($M)
                                                                    </th>
                                                                )
                                                            })}

                                                            {reportView.financial_data &&
                                                                reportView.financial_data.revenue_growth && (
                                                                    <th
                                                                        className="report-f14"
                                                                        style={{
                                                                            borderBottom: '1px solid white',
                                                                        }}
                                                                    >
                                                                        Revenue Growth
                                                                    </th>
                                                                )}
                                                        </tr>
                                                    )}
                                                </thead>
                                            )}
                                        {reportView.financial_data &&
                                            reportView.financial_data.revenue_and_net_income_data &&
                                            reportView.financial_data.revenue_and_net_income_data.revenue && (
                                                <tbody>
                                                    <tr
                                                        className="report-financial-height"
                                                        style={{ textAlign: 'center' }}
                                                    >
                                                        <td className="report-f14" style={{ fontWeight: 800 }}>
                                                            Revenue
                                                        </td>
                                                        <td className="report-f14">
                                                            {reportView.financial_data.revenue_and_net_income_data.revenue[
                                                                '2023'
                                                            ].toLocaleString()}
                                                        </td>
                                                        <td className="report-f14">
                                                            {reportView.financial_data.revenue_and_net_income_data.revenue[
                                                                '2022'
                                                            ].toLocaleString()}
                                                        </td>
                                                        {reportView.financial_data &&
                                                            reportView.financial_data.revenue_growth && (
                                                                <td className="report-f14">
                                                                    {reportView.financial_data.revenue_growth + '%'}
                                                                </td>
                                                            )}
                                                    </tr>
                                                </tbody>
                                            )}
                                    </table>
                                    {reportView.financial_data && reportView.financial_data.gpt_summary && (
                                        <div className="report-f14" style={{ marginTop: '10px' }}>
                                            {reportView.financial_data.gpt_summary}
                                        </div>
                                    )}
                                </div>
                            )} */}
                            {reportView.quick_data &&
                                (reportView.quick_data['Financial Analysis'] ||
                                    reportView.quick_data['Financial Summary']) && (
                                    <div style={{ marginTop: '30px' }}>
                                        <label
                                            className="report-f22"
                                            style={{
                                                color: '#186ADE',
                                                fontWeight: 500,
                                                fontFamily: 'Montserrat',
                                                pageBreakInside: 'avoid',
                                            }}
                                        >
                                            FINANCIAL OVERVIEW
                                        </label>
                                        {reportView.quick_data['Financial Analysis'] &&
                                            reportView.quick_data['Financial Analysis']['Total Revenue'] &&
                                            typeof reportView.quick_data['Financial Analysis']['Total Revenue'] ===
                                                'object' && (
                                                <div
                                                    style={{
                                                        marginTop: '20px',
                                                        marginLeft: '10px',
                                                        pageBreakInside: 'avoid',
                                                    }}
                                                >
                                                    <BarChart
                                                        data={
                                                            reportView.quick_data['Financial Analysis']['Total Revenue']
                                                        }
                                                        type="revenue"
                                                    />
                                                </div>
                                            )}

                                        {reportView.quick_data['Financial Summary'] && (
                                            <pre
                                                className="report-f14"
                                                style={{
                                                    textWrap: 'pretty',
                                                    fontFamily: 'Montserrat',
                                                    marginTop: '10px',
                                                    marginLeft: '10px',
                                                }}
                                            >
                                                {reportView.quick_data['Financial Summary']}
                                            </pre>
                                        )}
                                    </div>
                                )}

                            <div style={{ marginTop: '25px' }}>
                                <label
                                    className="report-f22"
                                    style={{
                                        color: '#186ADE',
                                        fontWeight: 500,
                                        pageBreakInside: 'avoid',
                                        fontFamily: 'Montserrat',
                                    }}
                                >
                                    KEY DEVELOPMENTS
                                </label>
                            </div>
                            <div style={{ margin: '25px 20px 0px 20px' }}>
                                {/* {reportView.summary && (
                                    <div className="summary" style={{ marginTop: '20px' }}>
                                        <label className="report-f18">Summary</label>
                                        <div>
                                            <pre
                                                style={{
                                                    textWrap: 'pretty',
                                                    fontFamily: 'Muli',
                                                }}
                                                className="report-f14"
                                            >
                                                {reportView.summary}
                                            </pre>
                                        </div>
                                    </div>
                                )} */}
                                {reportView.data && reportView.data.length > 0 && (
                                    <>
                                        <div className="news_section" style={{ marginTop: '25px' }}>
                                            {reportView.data.map(value => (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        border: '1px solid lightgray',
                                                        background: 'white',
                                                        marginTop: '10px',
                                                        borderRadius: '5px',
                                                        pageBreakInside: 'avoid',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            width: value.image ? '60%' : '100%',
                                                            maxHeight: '250px',
                                                        }}
                                                    >
                                                        <div style={{ margin: '10px' }}>
                                                            {value.source_name && (
                                                                <div
                                                                    className="source report-f14"
                                                                    style={{ marginTop: '10px' }}
                                                                >
                                                                    {value.source_name}
                                                                </div>
                                                            )}
                                                            {value.title && (
                                                                <div className="title">
                                                                    <a
                                                                        href={value.source ? value.source : ''}
                                                                        style={{
                                                                            color: '#3048A6',
                                                                            overflow: 'hidden',
                                                                            display: '-webkit-box',
                                                                            WebkitLineClamp: 2,
                                                                            WebkitBoxOrient: 'vertical',
                                                                            marginTop: '10px',
                                                                        }}
                                                                        className="report-f14"
                                                                    >
                                                                        {value.title}
                                                                    </a>
                                                                </div>
                                                            )}
                                                            {value.snippet && (
                                                                <div
                                                                    className="snippet report-f14"
                                                                    style={{
                                                                        overflow: 'hidden',
                                                                        display: '-webkit-box',
                                                                        WebkitLineClamp: 4,
                                                                        WebkitBoxOrient: 'vertical',
                                                                        marginTop: '10px',
                                                                    }}
                                                                    title={value.snippet}
                                                                >
                                                                    {value.snippet}
                                                                </div>
                                                            )}
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    marginTop: '10px',
                                                                }}
                                                            >
                                                                {value.date && (
                                                                    <div
                                                                        className="date report-f12"
                                                                        style={{ color: 'lightgray' }}
                                                                    >
                                                                        {value.date}
                                                                    </div>
                                                                )}
                                                                {value.subcategories &&
                                                                    value.subcategories.map(each => (
                                                                        <>
                                                                            <p style={{ margin: '0px 10px 0px' }}>
                                                                                {' '}
                                                                                |{' '}
                                                                            </p>
                                                                            <div
                                                                                className="date report-f12"
                                                                                style={{
                                                                                    border: 'solid 2px orange',
                                                                                    padding: '5px',
                                                                                    borderRadius: '14px',
                                                                                }}
                                                                            >
                                                                                {each.name}
                                                                            </div>
                                                                        </>
                                                                    ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {value.image && (
                                                        <div style={{ width: '40%' }}>
                                                            <img
                                                                src={value.image}
                                                                style={{
                                                                    width: '100%',
                                                                    maxHeight: '250px',
                                                                    borderRadius: '10px',
                                                                }}
                                                                alt="news_image"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </div>
                            <div
                                className="A4"
                                style={{
                                    background: '#186ADE',
                                    color: 'white',
                                    textAlign: 'center',
                                    marginTop: '10px',
                                    pageBreakBefore: 'always',
                                }}
                            >
                                <div className="report-f25 report-t150">This Report was Created by Kaitongo</div>
                                <div className="report-f60" style={{ marginTop: '20px' }}>
                                    <div>Explore more in-depth</div>
                                    <div>reports, cutting-edge</div>
                                    <div>insights, and powerful</div>
                                    <div>tools to drive your success.</div>
                                </div>
                                <div style={{ marginTop: '20px' }}>
                                    <span className="report-f25"> Discover More Features on </span>{' '}
                                    <span className="report-f40" style={{ textDecoration: 'underline' }}>
                                        Kaitongo
                                    </span>
                                </div>
                            </div>
                            {/* <div
                                className="report-f14"
                                style={{ marginTop: '30px', fontWeight: 400, textAlign: 'center' }}
                            >
                                Copyright © 2024 Kaitongo
                            </div> */}
                        </div>
                    )}
                </ModalBody>
            </Modal>

            <Modal isOpen={emailModal} toggle={() => setEmailModal(!emailModal)}>
                <ModalHeader>
                    <strong>Email</strong>
                </ModalHeader>

                <ModalBody>
                    <Form onSubmit={e => sendingMail(e)}>
                        <Label>Email-ID</Label>
                        <Input type="email" name="email" />
                        <Button
                            type="submit"
                            variant="primary"
                            disabled={showSpinner ? true : false}
                            style={{ marginTop: '10px', float: 'right', textAlign: 'center' }}
                        >
                            {showSpinner && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ margin: '1px 5px 0px' }}
                                />
                            )}
                            Send Email
                        </Button>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}
