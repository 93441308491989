import React, { useEffect, useState } from 'react'
import { Autocomplete, TextField, createFilterOptions, Button } from '@mui/material'
import { Input, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import { toast } from 'react-toastify'

export default function SaveSearches(props) {
    const {
        openModal,
        setOpenModal,
        editPage,
        collectionName,
        setCollectionName,
        collectionDescription,
        setCollectionDescription,
        collectionShared,
        setCollectionShared,
        collectionUsers,
        setCollectionUsers,
        allCollectionUsers,
        allCollectionName,
        editqueryName,
        setEditQueryName,
        saveQueryName,
        setSaveQueryName,
        saveSemanticQuery,
        editSemanticQuery,
        collectionPrivacy,
        setCollectionPrivacy,
        collectionModal,
        setCollectionModal,
        collectionShareList,
        isQuickSearch,
        isQueryEditor,
        generateQuery,
        saveQuerySearch,
        isPowerSearch,
        users,
        setUsers,
        directRun,
        isMyRequest,
    } = props

    // const [collectionModal, setCollectionModal] = useState(false)

    return (
        <>
            <Modal
                isOpen={openModal}
                toggle={() => {
                    setOpenModal(!openModal)
                }}
                autoFocus={false}
            >
                <ModalHeader style={{ width: '100%' }}>
                    <div style={{ marginTop: '10px' }} className="d-flex justify-content-between">
                        <div style={{ marginLeft: '10px' }}> Save Search</div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <label>Name</label>
                    <br />
                    <TextField
                        value={directRun ? editqueryName : saveQueryName}
                        onChange={e => {
                            directRun ? setEditQueryName(e.target.value) : setSaveQueryName(e.target.value)
                        }}
                        placeholder="Name"
                        variant="outlined"
                        size="small"
                        sx={{ width: '100%' }}
                        className="mt-1"
                    />
                    {!collectionModal && !isMyRequest && (
                        <div>
                            <label className="mt-3">Choose a Newsletter</label>
                            <br />
                            <Autocomplete
                                // sx={{ marginTop: '20px' }}
                                className="mt-1"
                                options={allCollectionName}
                                autoHighlight
                                value={collectionName}
                                getOptionLabel={option => option.label}
                                renderOption={(props, option) => (
                                    <li
                                        {...props}
                                        style={{
                                            color: option.value === 'Add into New Newsletter' ? '#197bbd' : 'black',
                                        }}
                                    >
                                        {option.label}
                                    </li>
                                )}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        // sx={{ height: '40px' }}
                                        // style={{ height: 40 }}
                                        variant="outlined"
                                        placeholder="Choose a Newsletter"
                                        size="small"
                                    />
                                )}
                                onChange={(event, value) => {
                                    if (value !== null && value.value === 'Add into New Newsletter') {
                                        setCollectionModal(true)
                                        // setOpenModal(false)
                                        setCollectionName(null)
                                        // setCollectionName('')
                                    } else {
                                        setCollectionName(value)
                                        setCollectionModal(false)
                                    }
                                }}
                            />
                        </div>
                    )}
                    {collectionModal && (
                        <>
                            <label className="mt-3">Newsletter Name</label>
                            <br />
                            <TextField
                                value={collectionName}
                                onChange={e => {
                                    setCollectionName(e.target.value)
                                }}
                                placeholder="Newsletter Name"
                                variant="outlined"
                                sx={{ width: '100%' }}
                                size="small"
                                className="mt-1"
                            />
                            <label className="mt-3">Description</label>
                            <br />
                            <TextField
                                value={collectionDescription}
                                onChange={e => {
                                    setCollectionDescription(e.target.value)
                                }}
                                placeholder="Description"
                                variant="outlined"
                                sx={{ width: '100%' }}
                                size="small"
                                className="mt-1"
                            />
                            <label className="mt-3">Shareable</label>
                            <br />
                            <Autocomplete
                                className="mt-1"
                                options={collectionShareList}
                                autoHighlight
                                value={collectionPrivacy}
                                getOptionLabel={option => option.label}
                                // renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        style={{ borderRadius: '10px' }}
                                        variant="outlined"
                                        placeholder="Shareable"
                                        size="small"
                                    />
                                )}
                                defaultValue={collectionShareList[0]} // Set your default value here
                                onChange={(event, value) => {
                                    console.log(value)
                                    // setCollectionName(value)
                                    if (value !== null) {
                                        setCollectionPrivacy(value)
                                    }
                                }}
                            />

                            {collectionPrivacy && collectionPrivacy.value === false && (
                                <div>
                                    <label className="mt-3">Users</label>
                                    <br />
                                    <Autocomplete
                                        className="mt-1"
                                        multiple
                                        // sx={{ marginTop: '20px' }}
                                        options={allCollectionUsers}
                                        autoHighlight
                                        value={collectionUsers}
                                        getOptionLabel={option => option.email}
                                        renderOption={(props, option) => <li {...props}>{option.email}</li>}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                // sx={{ height: '40px' }}
                                                style={{ borderRadius: '10px' }}
                                                variant="outlined"
                                                placeholder="Users"
                                                size="small"
                                            />
                                        )}
                                        onChange={(event, value) => {
                                            console.log(value)
                                            setCollectionUsers(value)
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            marginLeft: 'auto',
                            paddingTop: '20px',
                            marginRight: '15px',
                            // marginLeft: '269px',
                        }}
                    >
                        <Button
                            variant="contained"
                            style={{
                                background: '#186ADE',
                                borderRadius: '4px',
                                width: 'max-content',
                                // marginLeft: '20px',
                                fontSize: '14px',
                                height: '40px',
                                color: 'white',
                                boxShadow: 'none',
                                textTransform: 'none',
                            }}
                            className="Cancel_btn"
                            onClick={() => {
                                setOpenModal(false)
                                setCollectionModal(false)
                                setCollectionName(null)
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            variant="contained"
                            style={{
                                background: '#186ADE',
                                borderRadius: '4px',
                                width: 'max-content',
                                // marginLeft: '20px',
                                fontSize: '14px',
                                height: '40px',
                                color: 'white',
                                boxShadow: 'none',
                                textTransform: 'none',
                                marginLeft: '20px',
                            }}
                            onClick={directRun ? editSemanticQuery : saveSemanticQuery}
                            className="save_btn"
                        >
                            Save
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}
