import React, { PureComponent } from 'react'
import { FormGroup, Input, Label } from 'reactstrap'
import classnames from 'classnames'
import { IoMdEye, IoMdEyeOff } from 'react-icons/io'
import { ErrorMessage } from 'formik'
import styled from 'styled-components'

class PasswordInput extends PureComponent {
    state = {
        passwordHidden: true,
    }

    togglePasswordShow = e => {
        e.preventDefault()
        this.setState({
            passwordHidden: !this.state.passwordHidden,
        })
    }

    render() {
        const { name, label, placeholder, handleBlur, errors, touched, values, handleChange } = this.props

        return (
            <FormGroup key={name}>
                {/* <Label htmlFor={name}>{label}</Label> */}
                <p className="signup__label">
                    {label}
                    <span style={{ color: 'red' }}>*</span>
                </p>
                <Input
                    id={name}
                    placeholder={placeholder}
                    type={this.state.passwordHidden ? 'password' : 'text'}
                    value={values[name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classnames('password-input signup__input', {
                        error: errors[name] && touched[name],
                    })}
                    autocomplete="off"
                />
                {/* <PasswordButton onClick={this.togglePasswordShow} className="btn btn-link" type="button">
                    {this.state.passwordHidden ? <IoMdEye /> : <IoMdEyeOff />}
                </PasswordButton> */}
                <div
                    onClick={this.togglePasswordShow}
                    // style={{
                    //     marginLeft: '90%',
                    //     marginTop: this.props.formProps.initialValues.type === 'login' ? '-30%' : '-18%',
                    // }}
                    className="btn btn-link d-none d-sm-block"
                    type="button"
                >
                    {!this.state.passwordHidden ? (
                        <IoMdEye className="pass-hidden" />
                    ) : (
                        <IoMdEyeOff className="pass-hidden" />
                    )}
                </div>
                <ErrorMessage
                    component={() => <div className="input-feedback mt-pass">{errors[name]}</div>}
                    name={name}
                />
            </FormGroup>
        )
    }
}

export default PasswordInput

const PasswordButton = styled.button`
    font-size: 20px;
    color: #777;
    cursor: pointer;
    position: relative;
    margin-left: 92%;
    margin-top: -19%;
    &.btn-link {
        color: #777;
    }
`
