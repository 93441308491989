import React, { useState } from 'react'
import { Grid } from '@mui/material'
import './MailModal.css'
import NewsBody from './NewsBody'
import Resizer from './LogoResizingSlider'
import TemplateFooter from './TemplateFooter'

export default function BlakesPreview(props) {
    const {
        selectedStories,
        primaryColor,
        secondaryColor,
        title,
        selectedLogo,
        selectedBg,
        setSize,
        justPreview,
        newsBodySize,
        includeSection,
        includeBusiness,
    } = props
    const [slidervalue, setSliderValue] = useState(150)
    const logoRef = React.useRef()
    const getSize = () => {
        setSize(logoRef.current.offsetWidth, logoRef.current.offsetHeight, 'primary')
    }
    const setStyleOn = event => {
        const { value } = event.target

        let element = document.querySelector('#imgcontainer')
        let image = document.querySelector('#logotag')
        element.style.width = value + 'px'
        element.style.maxHeight = '167px'
        image.style.maxHeight = '167px'
        image.style.maxWidth = '100%'
        setSliderValue(value)
        setSize(logoRef.current.offsetWidth, logoRef.current.offsetHeight, 'primary')
    }
    return (
        <Grid container direction="column">
            <Grid item>
                <table
                    background={selectedBg ? selectedBg : ''}
                    style={{
                        width: '670px',
                        height: '210px',
                        backgroundRepeat: 'no-repeat',
                        border: 'solid 2px #979797',
                    }}
                >
                    <tbody>
                        <tr>
                            <td>
                                <div
                                    id="imgcontainer"
                                    style={{
                                        paddingLeft: '10px',
                                        width: '150px',
                                        maxWidth: '287px',
                                        maxHeight: '167px',
                                    }}
                                >
                                    <img
                                        src={selectedLogo}
                                        onLoad={e => getSize()}
                                        id="logotag"
                                        alt="logo"
                                        ref={ref => (logoRef.current = ref)}
                                        style={{
                                            maxHeight: '100%',
                                            maxWidth: '100%',
                                        }}
                                    />
                                </div>
                            </td>
                            <td>
                                <p
                                    style={{
                                        color: selectedBg ? 'white' : 'black',
                                        textAlign: 'center',
                                        fontFamily: 'Gorgia',
                                        fontSize: '18pt',
                                    }}
                                >
                                    <strong> {title} </strong>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                    {justPreview !== true && (
                        <div
                            style={{
                                position: 'relative',
                                marginTop: '-25px',
                                marginLeft: '15px',
                                display: !selectedLogo ? 'none' : undefined,
                            }}
                        >
                            <Resizer slidervalue={slidervalue} setStyleOn={setStyleOn} />
                        </div>
                    )}
                </table>
            </Grid>
            <NewsBody
                isBlakes={true}
                selectedStories={selectedStories}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                newsBodySize={newsBodySize}
                includeSection={includeSection}
                includeBusiness={includeBusiness}
            />
            <TemplateFooter />
        </Grid>
    )
}
