import React from 'react'
import { Stepper, Step, StepLabel } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Grid, Button } from '@mui/material'
const stepss = ['Select News', 'Select Newsletter Template', 'Template Preview Editor', 'Mail Recipients']
// const newslettersteps = ['Create Newsletter', 'Add your searches', 'Schedule settings']

export function Steppers(props) {
    const { activeStep, steps, isNewsLetters } = props
    return (
        <Stepper activeStep={activeStep} style={{ marginBottom: '5px', paddingRight: '1rem' }}>
            {isNewsLetters
                ? steps.map((label, index) => {
                      const stepProps = {}
                      const labelProps = {}
                      return (
                          <Step key={label} {...stepProps} className="stepper_request">
                              <StepLabel {...labelProps} />
                          </Step>
                      )
                  })
                : stepss.map((label, index) => {
                      const stepProps = {}
                      const labelProps = {}
                      return (
                          <Step key={label} {...stepProps}>
                              <StepLabel {...labelProps} sx={{ '& .MuiStepLabel-label': { fontSize: '1.05rem' } }}>
                                  {label}
                              </StepLabel>
                          </Step>
                      )
                  })}
        </Stepper>
    )
}

export function StepperButton(props) {
    const { activeStep, setActiveStep, count, template, resetSteps, isSearchPage, setIncludeSection } = props

    return (
        <Grid container direction="row" justifyContent="space-between" style={{ paddingRight: '1rem' }}>
            <Grid item>
                <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    startIcon={<ArrowBackIosIcon />}
                    onClick={() => {
                        setActiveStep(prevActiveStep => prevActiveStep - 1)
                        activeStep === 2 && setIncludeSection(false)
                    }}
                    style={{ fontSize: '1.05rem' }}
                >
                    Previous
                </Button>
            </Grid>
            {isSearchPage ? (
                activeStep !== 1 && (
                    <Grid item>
                        <Button
                            disabled={(activeStep === 0 && count === 0) || (activeStep === 1 && !template)}
                            onClick={
                                activeStep === 3
                                    ? resetSteps
                                    : () => setActiveStep(prevActiveStep => prevActiveStep + 1)
                            }
                            endIcon={<ArrowForwardIosIcon />}
                            style={{ fontSize: '1.05rem' }}
                        >
                            {activeStep === 3 ? 'Reset' : 'Next'}
                        </Button>
                    </Grid>
                )
            ) : (
                <Grid item>
                    <Button
                        disabled={(activeStep === 0 && count === 0) || (activeStep === 1 && !template)}
                        onClick={
                            activeStep === 3 ? resetSteps : () => setActiveStep(prevActiveStep => prevActiveStep + 1)
                        }
                        endIcon={<ArrowForwardIosIcon />}
                        style={{ fontSize: '1.05rem' }}
                    >
                        {activeStep === 3 ? 'Reset' : 'Next'}
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}
