import React from 'react'
import Select from 'react-select'
import './NewsEditCard2020.css'

export default function TopicsDropDown(props) {
    const { isStorySelected, allTopics, selectedTopics, handleTopicChange, isFindAndShare } = props

    if (!props.allTopics) {
        return <div>Topics Error</div>
    }

    return (
        <div style={props.extraStyle}>
            {!props.isAddNews && (
                <div className="newsCard20__oppHeading">
                    <p>Topics</p>
                </div>
            )}
            <Select
                isMulti
                isDisabled={isStorySelected}
                className={isFindAndShare ? 'topic_dropdown' : ''}
                placeholder="Select Topics"
                options={allTopics.sort((a, b) => (a.label > b.label ? 1 : -1))}
                value={selectedTopics}
                onChange={handleTopicChange}
            />
        </div>
    )
}
