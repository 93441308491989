import React, { useEffect, useState } from 'react'
import { Autocomplete, TextField, createFilterOptions } from '@mui/material'
import { Input, Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import { toast } from 'react-toastify'

export default function SavedQueriesModal(props) {
    const {
        openModal,
        setOpenModal,
        editPage,
        collectionName,
        setCollectionName,
        collectionDescription,
        setCollectionDescription,
        collectionShared,
        setCollectionShared,
        collectionUsers,
        setCollectionUsers,
        allCollectionUsers,
        allCollectionName,
        editqueryName,
        setEditQueryName,
        saveQueryName,
        setSaveQueryName,
        saveSemanticQuery,
        editSemanticQuery,
        collectionPrivacy,
        setCollectionPrivacy,
        collectionModal,
        setCollectionModal,
        collectionShareList,
        isQuickSearch,
        isQueryEditor,
        generateQuery,
        saveQuerySearch,
        isPowerSearch,
        users,
        setUsers,
    } = props

    const filter = createFilterOptions()

    const [inputStr, setInputStr] = useState('')
    const [showPicker, setShowPicker] = useState(false)

    const onEmojiClick = (event, emojiObject) => {
        setInputStr(event.emoji)
        setShowPicker(false)
    }

    return (
        <>
            <Modal
                isOpen={openModal}
                toggle={() => {
                    setOpenModal(!openModal)
                }}
                autoFocus={false}
            >
                <ModalHeader style={{ width: '100%' }}>
                    <div style={{ marginTop: '10px' }} className="d-flex justify-content-between">
                        <div style={{ marginLeft: '10px' }}> Save Query Modal</div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <TextField
                        value={editPage ? editqueryName : saveQueryName}
                        onChange={e => {
                            editPage ? setEditQueryName(e.target.value) : setSaveQueryName(e.target.value)
                        }}
                        label="Name"
                        variant="outlined"
                        size="small"
                        sx={{ width: '450px', height: '60px' }}
                    />
                    <Autocomplete
                        sx={{ width: '450px', marginTop: '20px' }}
                        options={allCollectionName}
                        autoHighlight
                        value={collectionName}
                        getOptionLabel={option => option.label}
                        renderOption={(props, option) => (
                            <li
                                {...props}
                                style={{ color: option.value === 'Add into New Newsletter' ? '#197bbd' : 'black' }}
                            >
                                {option.label}
                            </li>
                        )}
                        renderInput={params => (
                            <TextField
                                {...params}
                                // sx={{ height: '40px' }}
                                // style={{ height: 40 }}
                                variant="outlined"
                                label="Choose a Collection"
                                size="small"
                            />
                        )}
                        onChange={(event, value) => {
                            if (value !== null && value.value === 'Add into New Newsletter') {
                                setCollectionModal(true)
                                setOpenModal(false)
                                setCollectionName(null)
                            } else {
                                setCollectionName(value)
                            }
                        }}
                    />
                    <div
                        style={{
                            display: 'flex',
                            marginLeft: 'auto',
                            paddingTop: '20px',
                            marginRight: '15px',
                            // marginLeft: '269px',
                        }}
                    >
                        <Button
                            className="Cancel_btn"
                            onClick={() => {
                                setOpenModal(false)
                                // setCollectionModal(false)
                            }}
                        >
                            Cancel
                        </Button>
                        {isQuickSearch && (
                            <Button onClick={!editPage ? saveSemanticQuery : editSemanticQuery} className="save_btn">
                                Save
                            </Button>
                        )}
                        {isPowerSearch && (
                            <Button onClick={!isQueryEditor ? generateQuery : saveQuerySearch} className="save_btn">
                                Save
                            </Button>
                        )}
                    </div>
                </ModalBody>
            </Modal>
            <Modal
                isOpen={collectionModal}
                toggle={() => {
                    setCollectionModal(!collectionModal)
                }}
                // size="lg"
            >
                <ModalHeader style={{ width: '100%' }}>
                    <div style={{ marginTop: '10px' }} className="d-flex justify-content-between">
                        <div style={{ marginLeft: '10px' }}>Collection Details</div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <TextField
                        value={collectionName}
                        onChange={e => {
                            setCollectionName(e.target.value)
                        }}
                        label="Title"
                        variant="outlined"
                        sx={{ width: '450px' }}
                        size="small"
                    />

                    <TextField
                        value={collectionDescription}
                        onChange={e => {
                            setCollectionDescription(e.target.value)
                        }}
                        label="Description"
                        variant="outlined"
                        sx={{ width: '450px', marginTop: '20px' }}
                        size="small"
                    />
                    <Autocomplete
                        sx={{ width: '450px', marginTop: '20px' }}
                        options={collectionShareList}
                        autoHighlight
                        value={collectionPrivacy}
                        getOptionLabel={option => option.label}
                        // renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        renderInput={params => (
                            <TextField
                                {...params}
                                style={{ borderRadius: '10px' }}
                                variant="outlined"
                                label="Shareable"
                                size="small"
                            />
                        )}
                        defaultValue={collectionShareList[0]} // Set your default value here
                        onChange={(event, value) => {
                            // setCollectionName(value)
                            if (value !== null) {
                                setCollectionPrivacy(value)
                            }
                        }}
                    />
                    {collectionPrivacy && collectionPrivacy.value === false && (
                        // <Autocomplete
                        //     sx={{ width: '450px', marginTop: '20px' }}
                        //     multiple
                        //     options={allCollectionUsers}
                        //     value={collectionUsers}
                        //     getOptionLabel={option => {
                        //         if (option.label) {
                        //             return option.label
                        //         }

                        //         return option.email
                        //     }}
                        //     onChange={(event, newValue) => {
                        //         if (newValue && newValue.length) {
                        //             const results = []

                        //             newValue.forEach(value => {
                        //                 if (typeof value === 'string') {
                        //                     function ValidateEmail(mail) {
                        //                         return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)
                        //                     }

                        //                     if (!ValidateEmail(value)) {
                        //                         toast.error(`You have entered an invalid email address! ${value}`)
                        //                         return
                        //                     }

                        //                     return results.push({
                        //                         email: value,
                        //                     })
                        //                 }

                        //                 return results.push(value)
                        //             })

                        //             setCollectionUsers(results)
                        //         }
                        //     }}
                        //     renderInput={params => (
                        //         <TextField {...params} variant="outlined" label="Collection Users" />
                        //     )}
                        //     // renderTags={() => null}
                        //     freeSolo
                        //     filterOptions={(options, params) => {
                        //         const filtered = filter(options, params)
                        //         const { inputValue } = params
                        //         const isExisting = options.some(option => inputValue === option.email)
                        //         if (inputValue !== '' && !isExisting) {
                        //             filtered.push({
                        //                 id: `Add email :   ${inputValue}`,
                        //                 email: inputValue,
                        //             })
                        //         }

                        //         return filtered
                        //     }}
                        //     renderOption={(props, option) => <li {...props}>{option.label || option.email}</li>}
                        // />
                        <Autocomplete
                            multiple
                            sx={{ width: '450px', marginTop: '20px' }}
                            options={allCollectionUsers}
                            autoHighlight
                            value={collectionUsers}
                            getOptionLabel={option => option.email}
                            renderOption={(props, option) => <li {...props}>{option.email}</li>}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    // sx={{ height: '40px' }}
                                    style={{ borderRadius: '10px' }}
                                    variant="outlined"
                                    label="Users"
                                    size="small"
                                />
                            )}
                            onChange={(event, value) => {
                                setCollectionUsers(value)
                            }}
                        />
                    )}
                    <div
                        style={{
                            display: 'flex',
                            margin: 'auto',
                            paddingTop: '20px',
                            marginLeft: '269px',
                        }}
                    >
                        <Button
                            className="Cancel_btn"
                            onClick={() => {
                                setCollectionModal(false)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button onClick={!editPage ? saveSemanticQuery : editSemanticQuery} className="save_btn">
                            Save
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}
