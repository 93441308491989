import React, { useEffect, useState } from 'react'
import { Cron } from 'react-js-cron'

export default function CronTab(props) {
    const {
        isEnabled,
        setIsEnabled,
        cronTabValue,
        setCronTabValue,
        lastScheduled,
        nextScheduled,
        isAssistant,
        isMyRequest,
        isMypreference,
    } = props
    const [allowedDropdowns, setAllowedDropdowns] = useState([
        'period',
        'months',
        'month-days',
        'week-days',
        'hours',
        'minutes',
    ])

    // useEffect(() => {
    //     const parentElement = document.querySelector('.react-js-cron-hours')
    //     const spanElement = parentElement.querySelector('span')

    //     if (spanElement && spanElement.textContent.trim() === 'at') {
    //         spanElement.innerHTML = 'at hour'
    //     }
    // }, [])

    return (
        <div style={{ marginTop: '10px' }}>
            {!isMypreference && (
                <>
                    {!isAssistant ? (
                        <div className="form-check form-switch" style={{ marginLeft: '10px' }}>
                            {!isMyRequest ? (
                                <label className="form-check-label text-black" htmlFor="flexSwitchCheckCheckedCron">
                                    {isEnabled ? 'Newsletter Scheduling Enabled' : 'Newsletter Scheduling Disabled'}
                                </label>
                            ) : (
                                <label
                                    style={{ fontSize: '16px' }}
                                    className="form-check-label text-black"
                                    htmlFor="flexSwitchCheckCheckedCron"
                                >
                                    {isEnabled ? 'Frequency' : 'Frequency'}
                                </label>
                            )}
                            <input
                                style={{ cursor: 'pointer' }}
                                title="enabled/disabled of scheduling"
                                className="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckCheckedCron"
                                value={isEnabled}
                                checked={isEnabled ? true : false}
                                onChange={e => {
                                    setIsEnabled(current => !current)
                                }}
                            />
                            {lastScheduled && (
                                <label style={{ marginLeft: '20px' }}>Last Scheduled Time - {lastScheduled}</label>
                            )}
                            {nextScheduled && (
                                <label style={{ marginLeft: '20px' }}>Next Scheduled Time - {nextScheduled}</label>
                            )}
                        </div>
                    ) : (
                        <div style={{ marginLeft: '10px', fontStyle: 'italic', fontSize: '17px' }}>
                            <label>Choose the schedule for your alerts</label>
                        </div>
                    )}
                </>
            )}
            <div
                className="mypreference d-flex justify-content-betweenF"
                style={{ marginTop: !isAssistant ? '10px' : '' }}
            >
                {/* {!isAssistant && (
                    <label style={{ fontSize: '16px', marginTop: '13px' }}>Recommendation NewsLetter - </label>
                )} */}
                <div
                    className="d-flex align-items-center"
                    style={{ marginTop: '10px', marginLeft: !isAssistant && !isMyRequest ? '20px' : '' }}
                >
                    <Cron
                        value={cronTabValue === '* * * * *' ? '0 0 * * 1' : cronTabValue}
                        setValue={(newValue, metadata) => {
                            if (metadata.selectedPeriod === 'month') {
                                setAllowedDropdowns(['period', 'months', 'month-days', 'hours', 'minutes'])
                                const updatedCronValue = newValue.replace(/(\S+)\s*$/, '*')
                                setCronTabValue(updatedCronValue)
                            } else {
                                setAllowedDropdowns(['period', 'months', 'month-days', 'week-days', 'hours', 'minutes'])
                                setCronTabValue(newValue)
                            }
                        }}
                        disabled={!isEnabled ? true : false}
                        mode="single"
                        isClearable={false}
                        allowedDropdowns={allowedDropdowns}
                        allowedPeriods={['month', 'week', 'day']}
                        clearButton={false}
                        periodicityOnDoubleClick={false}
                        // clockFormat="12-hour-clock"
                    />
                    <span style={{ marginBottom: '8px', marginLeft: '5px' }}>EST</span>
                </div>
            </div>
            <div />
        </div>
    )
}
