import React, { PureComponent } from 'react'
import { getTrans } from '../../utils'
import { Redirect, Link } from 'react-router-dom'
import AuthWrapper from '../../wrappers/AuthWrapper/AuthWrapper'

class PasswordResetConfirmationScreen extends PureComponent {
    state = {
        redirectToReferrer: false,
    }

    render() {
        if (this.state.redirectToReferrer) {
            return <Redirect to="/app/news-and-opportunities" />
        }
        return (
            <AuthWrapper title={getTrans('Reset password confirm')}>
                <p>{getTrans('Your password was reset successfully.')}</p>
                <div className="text-right">
                    <Link to="/">{getTrans('Go to Login')}</Link>
                </div>
            </AuthWrapper>
        )
    }
}

export default PasswordResetConfirmationScreen
