import React, { useState, useEffect, useRef } from 'react'
import DropArrow from '../../assets/icons/carret-down.svg'
import './IndustrySelectionDropdown.css'

export default function IndustrySelectionDropdown(props) {
    const [expanded, setexpanded] = useState(false)
    const { isStorySelected, searchComponent, isFindAndShare } = props
    const [filterText, setFilterText] = useState('')
    const focusRef = useRef()

    // Hook that alerts clicks outside of the passed ref
    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setexpanded(false)
                }
            }
            document.addEventListener('mousedown', handleClickOutside)
            return () => {
                document.removeEventListener('mousedown', handleClickOutside)
            }
        }, [ref])
    }
    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef)

    const whichIndustryName = selectedInd => {
        const filteredArray = props.allIndustries.filter(ind => ind.type === 'Industry' && ind.value === selectedInd)
        if (filteredArray.length > 0) {
            return filteredArray[0].label
        } else {
            return null
        }
    }
    const whichSectorName = selectedInd => {
        const filteredArray = props.allIndustries.filter(ind => ind.type === 'Sector' && ind.value === selectedInd)
        if (filteredArray.length > 0) {
            return filteredArray[0].label
        } else {
            return null
        }
    }

    const Sectorindustry = (
        <React.Fragment>
            <input
                type="text"
                placeholder="Select Sector/Industry"
                value={filterText}
                ref={focusRef}
                onChange={e => setFilterText(e.target.value)}
                style={{
                    width: '-webkit-fill-available',
                    border: 'none',
                    outline: 'none',
                }}
                autoFocus={true}
            />
        </React.Fragment>
    )

    const sortedOptions = props.allIndustries.sort((a, b) => a.label.localeCompare(b.label))

    return (
        <div className={isFindAndShare ? 'industry_container' : 'industrySelectDropdown__container'}>
            <div className="newsCard20__oppHeading">
                <p>{isFindAndShare ? 'Sector' : 'Sector/Industry'}</p>
            </div>

            <div
                className="multiSelectDropDown__headingBoxAlt"
                onClick={
                    isFindAndShare
                        ? () => null
                        : isStorySelected
                        ? null
                        : () => (expanded ? setexpanded(false) : setexpanded(true))
                }
            >
                <div className="newsCard20__categoriesContainerAlt">
                    {props.industries &&
                        props.industries.map(ind => {
                            if (whichIndustryName(ind) !== null) {
                                return (
                                    <div
                                        className={isFindAndShare ? 'sectors_tag_value' : 'newsCard20__indTag'}
                                        key={'industry' + ind}
                                        onClick={e => e.stopPropagation()}
                                    >
                                        <p>{whichIndustryName(ind)}</p>
                                        {!isFindAndShare && (
                                            <p
                                                className="newsCard20__indTag-close"
                                                onClick={
                                                    isFindAndShare
                                                        ? null
                                                        : isStorySelected
                                                        ? null
                                                        : () => props.handleIndustryChange(ind, 'Industry')
                                                }
                                            >
                                                x
                                            </p>
                                        )}
                                    </div>
                                )
                            } else {
                                return null
                            }
                        })}

                    {props.sectors &&
                        props.sectors.map(ind => {
                            if (whichSectorName(ind) !== null) {
                                return (
                                    <div
                                        className={isFindAndShare ? 'sectors_tag_value' : 'newsCard20__sectTag'}
                                        key={'sector' + ind}
                                        onClick={e => e.stopPropagation()}
                                    >
                                        <p>{whichSectorName(ind)}</p>
                                        {!isFindAndShare && (
                                            <p
                                                className="newsCard20__indTag-close"
                                                onClick={
                                                    isFindAndShare
                                                        ? null
                                                        : isStorySelected
                                                        ? null
                                                        : () => props.handleIndustryChange(ind, 'Sector')
                                                }
                                            >
                                                x
                                            </p>
                                        )}
                                    </div>
                                )
                            } else {
                                return null
                            }
                        })}
                </div>
                {!isFindAndShare && (
                    <div
                        className="multiSelectDropDown__caratAlt"
                        onClick={isStorySelected ? null : () => (expanded ? setexpanded(false) : setexpanded(true))}
                    >
                        <img
                            src={DropArrow}
                            style={expanded ? null : { transform: 'rotate(180deg)' }}
                            alt="dropdown arrow"
                        />
                    </div>
                )}
            </div>
            {expanded && (
                <div className="multiSelectDropDown__industriesExpandedBoxAlt" ref={wrapperRef}>
                    {Sectorindustry}
                    <div style={{ marginTop: '10px' }}>
                        {sortedOptions
                            .filter(ind => ind.label.toLowerCase().includes(filterText.toLowerCase()))
                            .map(ind => (
                                <p
                                    key={ind.label + ind.value}
                                    onClick={
                                        isStorySelected ? null : () => props.handleIndustryChange(ind.value, ind.type)
                                    }
                                    style={
                                        props.industries.find(arr => arr === ind.value && ind.type === 'Industry') ||
                                        props.sectors.find(arr => arr === ind.value && ind.type === 'Sector')
                                            ? { backgroundColor: '#f4f4f4' }
                                            : null
                                    }
                                >
                                    {ind.label}
                                </p>
                            ))}
                    </div>
                </div>
            )}
        </div>
    )
}
