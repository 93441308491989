import React, { useState, useEffect, useRef } from 'react'
import OppRichTextEditor from './OppRichTextEditor'
import DropArrow from '../../assets/icons/carret-down.svg'
import listIcon from '../../assets/icons/listIcon.svg'
import rightArrowActive from '../../assets/icons/right-proceed-arrow-active.svg'
import { Modal, ModalBody } from 'reactstrap'
import { getSubcategoriesString, opportunityTypeChecked } from '../../screens/NewsFeedScreen/urlFilterFunctions'
import './OpportunitiesWidget.css'
import axios from 'axios'

export default function OpportunitiesWidget(props) {
    const [oppModalOpen, setOppModalOpen] = useState(false)
    const [allOppsForSubCategories, setAllOppsForSubCategories] = useState([])
    const { isPublishedNews } = props

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOppModalOpen(false)
                }
            }
            document.addEventListener('mousedown', handleClickOutside)
            return () => {
                document.removeEventListener('mousedown', handleClickOutside)
            }
        }, [ref])
    }
    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef)

    const handleModalToggle = () => {
        let newModalOpen = !oppModalOpen
        setOppModalOpen(newModalOpen)
        if (newModalOpen) {
            const subCatURLParams = getSubcategoriesString(props.subCategory, props.industries)
            console.log(subCatURLParams)
            if (subCatURLParams)
                axios
                    .get(`/core/opportunities/subcategories/?${subCatURLParams}`)
                    .then(response => {
                        setAllOppsForSubCategories(response.data.data)
                    })
                    .catch(err => {
                        console.log(err)
                    })
        }
    }

    //Rich text boxes
    const oppTagsOuterMap = props.selectedOpportunities.map(opp => {
        return (
            <div key={opp.id} onClick={e => e.stopPropagation()}>
                <div className="oppModal__subTag">
                    <span>{isPublishedNews ? opp.opportunity_type__name : opp.name}</span>
                    <span className="oppModal__removeX" onClick={() => props.handleOppChange(opp)}>
                        X
                    </span>
                </div>

                <p style={{ textDecoration: 'underline' }}>Target Company Opportunity</p>
                <OppRichTextEditor
                    value={isPublishedNews ? opp.sentence : opp.default_sentence}
                    outerOnChange={props.handleOppTextChange}
                    oppId={opp.id}
                />
                <p style={{ textDecoration: 'underline' }}>Peer Company Opportunity Sentence</p>
                <OppRichTextEditor
                    value={isPublishedNews ? opp.peer_sentence : opp.default_peer_sentence}
                    outerOnChange={props.handleOppTextChangePeer}
                    oppId={opp.id}
                />
            </div>
        )
    })

    //MODAL chosen categories / subcategories (left side)
    const categoryMap = props.subCategory.map(cat => {
        return (
            <div key={cat.id}>
                <div className="oppModal__catTag">
                    <span>{cat.name}</span>
                </div>
                {/* {cat.subcategories &&
                    cat.subcategories.map(sub => {
                        return (
                            <div className="oppModal__subTag" key={sub.name}>
                                <span>{sub.name}</span>
                            </div>
                        )
                    })} */}
            </div>
        )
    })

    //Modal lower left
    const selectedOpportunitiesMap = props.selectedOpportunities.map(opp => {
        return (
            <div className="oppModal__selectedOpportunitiesContainer" key={opp.id}>
                <span className="oppModal__oppTag">
                    {isPublishedNews ? opp.opportunity_type__name : opp.name}
                    <span className="oppModal__removeX" onClick={() => props.handleOppChange(opp)}>
                        X
                    </span>
                </span>
            </div>
        )
    })

    //Modal right side
    const opportunitiesSubcatMap = allOppsForSubCategories.map(subCat => {
        return (
            <div className="oppModal__opportunityOptionCard" key={subCat.id}>
                <div className="oppModal__checkboxColumn">
                    <input
                        checked={opportunityTypeChecked(props.selectedOpportunities, subCat.id)}
                        type="checkbox"
                        onChange={event => props.handleOppChange(subCat, event)}
                    />
                </div>
                <div className="oppModal__textColumn">
                    <p style={{ textDecoration: 'underline' }}>Target Company Opportunity</p>
                    <p>{subCat.default_sentence}</p>
                    <p style={{ textDecoration: 'underline' }}>Peer Company Opportunity Sentence</p>
                    <p>{subCat.default_peer_sentence ? subCat.default_peer_sentence : 'None'}</p>
                </div>
            </div>
        )
    })

    return (
        <div className="multiSelectDropDown__ContainerAlt">
            <div className="multiSelectDropDown__headingBoxAlt">
                <div className="newsCard20__oppHeading">
                    <p>Suggested</p>
                    <p>Actions</p>
                </div>
                <div className="newsCard20__catBorder" style={{ border: 'solid 1px #d1cccc' }}>
                    <div className="oppModal__opportunitiesMap" onClick={props.isDisabled ? null : handleModalToggle}>
                        {props.selectedOpportunities.length === 0 ? (
                            <p style={{ marginLeft: '12px' }}>No opportunities selected</p>
                        ) : (
                            oppTagsOuterMap
                        )}
                    </div>
                    <div
                        className="multiSelectDropDown__caratAlt"
                        onClick={props.isDisabled ? null : handleModalToggle}
                        style={{ cursor: 'pointer' }}
                    >
                        <img src={process.env.REACT_APP_MEDIA_URL + DropArrow} alt="dropdown arrow" />
                    </div>
                </div>
            </div>

            <Modal isOpen={oppModalOpen} size="lg">
                <ModalBody style={{ padding: 0 }}>
                    <div className="opportunitiesModalContainer" ref={wrapperRef}>
                        <div className="oppModal__leftColumn">
                            <div className="oppModal__headingText">
                                <h1>Opportunity </h1>
                                <h1>Selection</h1>
                            </div>

                            <h3>Category and Sub-category:</h3>

                            <div className="oppModal__categoryTags">{categoryMap}</div>

                            <h3 className="oppModal__selectionsHeading">Selections</h3>

                            <div className="oppModal__selectedOpportunitiesContainer">{selectedOpportunitiesMap}</div>
                        </div>

                        <div className="oppModal__rightColumn">
                            <div className="oppModal_closeX" onClick={handleModalToggle}>
                                <h3> X </h3>
                            </div>

                            <div className="oppModal__rightHeaderGraphics">
                                <div className="oppModal__listIconBox">
                                    <img
                                        src={process.env.REACT_APP_MEDIA_URL + listIcon}
                                        alt="list icon"
                                        className="oppModal__listIcon"
                                    />
                                </div>
                                <div className="oppModal__pickOppBox">
                                    <p>Pick the ideal opportunity(s)</p>
                                </div>
                                <div className="oppModal__proceedBox" onClick={handleModalToggle}>
                                    <img src={process.env.REACT_APP_MEDIA_URL + rightArrowActive} alt="proceed arrow" />
                                </div>
                            </div>

                            <div className="oppModal__oppOptionsContainer">{opportunitiesSubcatMap}</div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}
