import React, { useEffect, useState } from 'react'
import BaseScreen from '../../BaseScreen/BaseScreen'
import { BsInfoCircle } from 'react-icons/bs'
import axios from 'axios'
import { MdContentCopy } from 'react-icons/md'
import './Filter.css'
import { toast } from 'react-toastify'

export default function BingSearch() {
    const [inputTerm, setInputTerm] = useState('')
    const [inputMarket, setInputMarket] = useState('en-US')
    const [inputCount, setInputCount] = useState(100)
    const [inputFresh, setInputFresh] = useState('Week')
    const [jsonResult, setJsonResult] = useState('')
    const [inputsort, setInputsort] = useState('Date')
    const [inputsince, setinputsince] = useState()
    const sub_key = process.env.BING_SUBSCRIPTION_KEY
    const sub_value = process.env.BING_SUBSCRIPTION_VALUE

    const sortbyDate = event => {
        const dateValue = event.target.value
        const dateObject = new Date(dateValue)
        const selectedinputsince = dateObject.getTime()
        setinputsince(selectedinputsince)
    }

    if (inputTerm.length >= 1500) {
        toast('Max search term length is 1500 characters')
    }

    const bingSearch = () => {
        if (inputTerm.length < 1500) {
            axios
                .get(
                    `https://api.bing.microsoft.com/v7.0/news/search?mkt=${inputMarket}&q=${inputTerm}&count=${inputCount}&freshness=${inputFresh}${
                        inputsince ? `&since=${inputsince}&sortBy=${inputsort}` : ''
                    }`,
                    {
                        headers: {
                            'Ocp-Apim-Subscription-Key': 'fd9f807a1a1141cdaaa8b31167c37774',
                        },
                    }
                )
                .then(res => {
                    console.log(res)
                    setJsonResult(res.data)
                })
                .catch(err => console.log(err))
        }
    }

    const exportJson = () => {
        const blob = new Blob([JSON.stringify(jsonResult)], { type: 'application/json' })
        const fileURL = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.download = 'Result.json'
        link.href = fileURL
        link.click()
    }

    const exportCSV = () => {
        const csvData = convertToCSV()

        const downloadLink = document.createElement('a')
        downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData)
        downloadLink.download = 'news_data.csv'

        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
    }

    function convertToCSV() {
        const newsData = jsonResult

        const headers = [
            'value__name',
            'value__url',
            'value__image__thumbnail__contentUrl',
            'value__image__thumbnail__width',
            'value__image__thumbnail__height',
            'value__description',
            'value__about__name',
            'value__mentions__name',
            'value__provider___type',
            'value__provider__name',
            'value__provider__image__thumbnail__contentUrl',
            'value__datePublished',
            'value__category',
        ]

        const rows = newsData.value.map(news => {
            return [
                news.name ? escapeValue(news.name) : '',
                news.url ? escapeValue(news.url) : '',
                news.image && news.image.thumbnail && news.image.thumbnail.contentUrl
                    ? escapeValue(news.image.thumbnail.contentUrl)
                    : '',
                news.image && news.image.thumbnail && news.image.thumbnail.width
                    ? escapeValue(news.image.thumbnail.width)
                    : '',
                news.image && news.image.thumbnail && news.image.thumbnail.height
                    ? escapeValue(news.image.thumbnail.height)
                    : '',
                news.description ? escapeValue(news.description) : '',
                news.about ? escapeValue(news.about.map(about => about.name).join('; ')) : '',
                news.mentions ? escapeValue(news.mentions.map(mention => mention.name).join('; ')) : '',
                news.provider ? escapeValue(news.provider.map(pro => pro._type).join('; ')) : '',
                news.provider ? escapeValue(news.provider.map(pro => pro.name).join('; ')) : '',
                news.provider
                    ? escapeValue(news.provider.map(pro => pro.image && pro.image.thumbnail.contentUrl).join('; '))
                    : '',
                news.datePublished ? escapeValue(news.datePublished) : '',
                news.category ? escapeValue(news.category) : '',
            ]
        })

        const csvContent = [headers.join(',')].concat(rows.map(row => row.join(','))).join('\n')

        return csvContent
    }
    function escapeValue(value) {
        if (typeof value === 'string') {
            const hashRemoved = value.replace(/#/g, '')
            return `"${hashRemoved.replace(/"/g, '""')}"`
        }
        return value
    }

    const copyJson = () => {
        navigator.clipboard.writeText(JSON.stringify(jsonResult))

        var tooltip = document.getElementById('jsonTooltip')
        tooltip.innerHTML = 'Copy to clipboard'
        setTimeout(() => {
            tooltip.innerHTML = ''
        }, 1000)
    }
    return (
        <BaseScreen>
            <div className="bingsearch_section" style={{ background: 'white', width: '99%' }}>
                <h2 style={{ padding: '10px 0px 0px 10px', fontWeight: '600' }}>Bing Search</h2>
                <div className="bingsearch_input_container" style={{ padding: '20px' }}>
                    <div className="d-flex justify-content-between" style={{ padding: '0px 60px 10px 30px' }}>
                        <label style={{ marginTop: '0%' }}>
                            Search terms<span style={{ color: 'red', marginLeft: '5px' }}>*</span>
                            <span className="term_info_icon" style={{ marginLeft: '5px', color: 'gray' }}>
                                <BsInfoCircle title="Search terms(q): The user's search query string. The query string must not be empty" />
                            </span>
                        </label>
                    </div>
                    <textarea
                        maxLength={1500}
                        type="text"
                        placeholder="Enter a few search term"
                        value={inputTerm}
                        onChange={e => {
                            setInputTerm(e.target.value)
                        }}
                        style={{
                            width: '91%',
                            height: '150px',
                            border: '1px solid gray',
                            outline: 'none',
                            borderRadius: '4px',
                            resize: 'none',
                            marginLeft: '30px',
                        }}
                    />
                    <div className="d-flex justify-content-between" style={{ padding: '10px 60px 10px 30px' }}>
                        <label style={{ marginTop: '15px' }}>
                            Market
                            <span className="mkt_info_icon" style={{ marginLeft: '5px', color: 'gray' }}>
                                <BsInfoCircle
                                    title="Market(mkt) : The market where the results come from. Typically, mkt is the country where
                            the user is making the request form."
                                />
                            </span>
                        </label>
                        <select
                            type="text"
                            placeholder="Enter a market"
                            value={inputMarket}
                            onChange={e => {
                                setInputMarket(e.target.value)
                                console.log(e.target.value)
                            }}
                            style={{
                                width: '150px',
                                height: '30px',
                                border: '1px solid gray',
                                outline: 'none',
                                borderRadius: '4px',
                                marginTop: '9px',
                                // marginRight: "100px"
                            }}
                        >
                            <option value="" hidden>
                                Select Market
                            </option>
                            <option value="en-US">en-US</option>
                            <option value="en-CA">en-CA</option>
                        </select>
                        <label style={{ marginTop: '15px' }}>
                            Result Counts
                            <span className="count_info_icon" style={{ marginLeft: '5px', color: 'gray' }}>
                                <BsInfoCircle title="Count(count) : The number of results count." />
                            </span>
                        </label>
                        <input
                            type="number"
                            placeholder="Enter a result counts"
                            value={inputCount}
                            onChange={e => {
                                setInputCount(e.target.value)
                            }}
                            style={{
                                width: '150px',
                                height: '30px',
                                border: '1px solid gray',
                                outline: 'none',
                                borderRadius: '4px',
                                marginTop: '9px',
                                // marginRight: "150px"
                            }}
                        />
                        <label style={{ marginTop: '15px' }}>
                            Freshness
                            <span className="freshness_info_icon" style={{ marginLeft: '5px', color: 'gray' }}>
                                <BsInfoCircle title="Freshness(freshness) : The news articles that Bing discovered within the last week,day,month" />
                            </span>
                        </label>
                        <select
                            type="text"
                            placeholder="Enter a result counts"
                            value={inputFresh}
                            onChange={e => {
                                setInputFresh(e.target.value)
                            }}
                            style={{
                                width: '150px',
                                height: '30px',
                                border: '1px solid gray',
                                outline: 'none',
                                borderRadius: '4px',
                                marginTop: '9px',
                            }}
                        >
                            <option value="" hidden>
                                Select freshness
                            </option>
                            <option value="Day">Day</option>
                            <option value="Week">Week</option>
                            <option value="Month">Month</option>
                        </select>
                    </div>
                    <div className="d-flex justify-content-between" style={{ padding: '10px 60px 10px 30px' }}>
                        <label style={{ marginTop: '15px' }}>
                            Since
                            <span className="mkt_info_icon" style={{ marginLeft: '5px', color: 'gray' }}>
                                <BsInfoCircle title="" />
                            </span>
                        </label>
                        <input
                            type="date"
                            value={inputsince ? new Date(inputsince).toISOString().split('T')[0] : ''}
                            onChange={sortbyDate}
                            placeholder="Enter a value"
                            style={{
                                width: '153px',
                                height: '31px',
                                border: '1px solid gray',
                                outline: 'none',
                                borderRadius: '4px',
                                marginTop: '9px',
                                marginRight: '71.8%',
                            }}
                        />
                    </div>
                    <div
                        className="d-flex justify-content-between"
                        style={{ padding: '0px 60px 0px 30px', marginTop: '15px' }}
                    >
                        {/* search */}
                        <button
                            className="btn btn-primary"
                            onClick={bingSearch}
                            style={{
                                color: inputTerm === '' ? 'gray' : '',
                                background: inputTerm === '' ? 'lightgray' : '',
                                border: inputTerm === '' ? '1px solid gray' : '',
                            }}
                            disabled={inputTerm === '' ? true : false}
                        >
                            Search
                        </button>
                        <div>
                            {/* clear */}
                            <button
                                className="btn btn-primary"
                                style={{
                                    marginRight: '30px',
                                }}
                                onClick={() => {
                                    setInputTerm('')
                                    setJsonResult('')
                                    setInputCount(100)
                                    setInputFresh('week')
                                    setInputMarket('en-US')
                                }}
                            >
                                clear
                            </button>
                            {/* export */}
                            <button
                                className="btn btn-primary"
                                onClick={exportCSV}
                                style={{
                                    color: jsonResult === '' ? 'gray' : '',
                                    background: jsonResult === '' ? 'lightgray' : '',
                                    border: jsonResult === '' ? '1px solid gray' : '',
                                }}
                                disabled={jsonResult === '' ? true : false}
                            >
                                Export
                            </button>
                        </div>
                    </div>
                    <div
                        className="result_container d-flex"
                        style={{ marginTop: '10px', padding: '0px 60px 0px 30px' }}
                    >
                        <textarea
                            style={{ width: '100%', height: '200px', outline: 'none' }}
                            value={jsonResult === '' ? jsonResult : JSON.stringify(jsonResult, undefined, 4)}
                            readOnly
                        />

                        <span
                            id="jsonTooltip"
                            style={{
                                position: 'absolute',
                                width: '135px',
                                borderRadius: '5px',
                                marginTop: '0.5%',
                                marginLeft: '58%',
                                background: '#197bbd',
                                color: 'white',
                                textAlign: 'center',
                            }}
                        />

                        <MdContentCopy
                            onClick={copyJson}
                            style={{ marginLeft: '-3%', marginTop: '1%', cursor: 'pointer' }}
                        />
                    </div>
                </div>
            </div>
        </BaseScreen>
    )
}
