import React, { useState } from 'react'
import LinkedInIcon from '../../../assets/icons/share-linkedin.png'
import DownCarrotLarge from '../../../assets/icons/down-carrot-large.svg'
import './Overview.css'
import ReactECharts from 'echarts-for-react'

export default function Overview(props) {
    const { Industry, Revenue, CompanyType, Management, BoardMembers, windowWidth } = props
    const [companyInfoExpanded, setCompanyInfoExpanded] = useState(windowWidth > 900 ? true : false)
    const [manageExpanded, setManageExpanded] = useState(windowWidth > 900 ? true : false)
    const [boardExpanded, setBoardExpanded] = useState(windowWidth > 900 ? true : false)
    const option = {
        title: {
            text: '# of Media Hits',
            textStyle: {
                fontSize: 15,
            },
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
            },
        },
        // legend: {
        //   // Try 'horizontal'
        //   orient: "vertical",
        //   right: "-5",
        //   top: "3%",
        //   textStyle: {
        //     width: 60,
        //     overflow: "break",
        //   },
        // },
        // grid: {
        //   left: "3%",
        //   right: "22%",
        //   bottom: "3%",
        //   containLabel: true,
        // },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['Jan', 'Feb', 'Mar', 'Apr'],
            axisLabel: {
                rotate: 20,
                // formatter: function(value) {
                //     return moment.utc(value).format('MM-DD HH:mm')
                // },
            },
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                data: [158, 185, 375, 173],
                type: 'line',
                areaStyle: {},
            },
        ],
    }
    const companies = {
        title: {
            text: 'Sentiment by Month',
            textStyle: {
                fontSize: 15,
            },
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
            },
        },
        // legend: {
        //   // Try 'horizontal'
        //   orient: "vertical",
        //   right: "-5",
        //   top: "3%",
        //   textStyle: {
        //     width: 60,
        //     overflow: "break",
        //   },
        // },
        // grid: {
        //   left: "3%",
        //   right: "22%",
        //   bottom: "3%",
        //   containLabel: true,
        // },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    show: false,
                },
                data: ['Jan', 'Feb', 'Mar', 'Apr'],
            },
        ],
        yAxis: [{ rotate: 90 }],
        series: [
            {
                name: 'Positive',
                type: 'bar',
                label: {
                    show: true,
                    position: 'inside',
                },
                emphasis: {
                    focus: 'series',
                },
                data: [15, 15, 8, 3],
            },

            {
                name: 'Negative',
                type: 'bar',
                stack: 'Total',
                label: {
                    show: true,
                    position: 'left',
                },
                emphasis: {
                    focus: 'series',
                },
                data: [-3, -0, -2, -1],
            },
        ],
    }
    const events = {
        title: {
            text: 'Trend by Events',
            textStyle: {
                fontSize: 15,
            },
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
            },
        },
        // legend: {
        //     // Try 'horizontal'
        //     orient: 'vertical',
        //     right: '-5',
        //     top: '10%',
        //     textStyle: {
        //         width: 60,
        //         overflow: 'break',
        //     },
        //     data: ['M&A', 'ESG Initiatives/ESG Investing', 'Executive Movement/Appointment', 'R&D Innovation Annoucement', 'Partnership/Alliance'],
        // },
        // grid: {
        //     left: '3%',
        //     right: '22%',
        //     bottom: '3%',
        //     containLabel: true,
        // },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['Jan', 'Feb', 'Mar', 'Apr'],
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                name: 'M&A',
                type: 'line',

                data: [2, 1, 1, 0],
            },
            {
                name: 'ESG Initiatives/ESG Investing',
                type: 'line',

                data: [0, 0, 2, 0],
            },
            {
                name: 'Executive Movement/Appointment',
                type: 'line',

                data: [1, 1, 0, 0],
            },
            {
                name: 'R&D Innovation Annoucement',
                type: 'line',

                data: [0, 0, 0, 0],
            },
            {
                name: 'Partnership/Alliance',
                type: 'line',

                data: [7, 6, 1, 3],
            },
        ],
    }
    const topices = {
        title: {
            text: 'Trend by Topics',
            textStyle: {
                fontSize: 15,
            },
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
            },
        },
        // legend: {
        //     // Try 'horizontal'
        //     orient: 'vertical',
        //     right: '-5',
        //     top: '10%',
        //     textStyle: {
        //         width: 60,
        //         overflow: 'break',
        //     },
        //     data: ['M&A', 'ESG Initiatives/ESG Investing', 'Executive Movement/Appointment', 'R&D Innovation Annoucement', 'Partnership/Alliance'],
        // },
        // grid: {
        //     left: '3%',
        //     right: '22%',
        //     bottom: '3%',
        //     containLabel: true,
        // },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['Jan', 'Feb', 'Mar', 'Apr'],
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                name: 'Investment Banking',
                type: 'line',

                data: [0, 1, 0, 0],
            },
            {
                name: 'Digital Payments',
                type: 'line',

                data: [2, 2, 3, 2],
            },
        ],
    }

    const CompanyInfoLine = props => {
        return (
            <div className="companyOverview__keyValueBox">
                <p className="companyOverview__key">{props.first}</p>
                <p className="companyOverview__value">{props.second}</p>
            </div>
        )
    }

    return (
        <div className="companyOverview__container">
            {/* <div className="d-flex justify-content-between">
                <div
                    style={{
                        width: '48%',
                        height: '90%',
                        padding: '25px',
                        border: '2px solid whitesmoke',
                        marginTop: '30px',

                        backgroundColor: 'white',
                    }}
                >
                    <ReactECharts option={option} />
                </div>
                <div
                    style={{
                        width: '48%',
                        height: '90%',
                        padding: '25px',
                        border: '2px solid whitesmoke',
                        marginTop: '30px',
                        backgroundColor: 'white',
                    }}
                >
                    <ReactECharts option={companies} />
                </div>
            </div>
            <div className="d-flex justify-content-between">
                <div
                    style={{
                        width: '48%',
                        height: '90%',
                        padding: '25px',
                        border: '2px solid whitesmoke',
                        marginTop: '30px',

                        backgroundColor: 'white',
                    }}
                >
                    <ReactECharts option={events} />
                </div>
                <div
                    style={{
                        width: '48%',
                        height: '90%',
                        padding: '25px',
                        border: '2px solid whitesmoke',
                        marginTop: '30px',
                        backgroundColor: 'white',
                        marginRight: '10px',
                    }}
                >
                    <ReactECharts option={topices} />
                </div>
            </div> */}
            <div className="companyOverview__3Box" style={{ marginTop: '30px' }}>
                <div className="companyOverview__content" style={windowWidth > 900 ? { height: '360px' } : null}>
                    <div
                        className="companyOverview__headingBox"
                        onClick={windowWidth > 900 ? null : () => setCompanyInfoExpanded(!companyInfoExpanded)}
                    >
                        <h3>Company Info</h3>
                        <div
                            className={
                                companyInfoExpanded ? 'companyOverview__expand-rotate' : 'companyOverview__expand'
                            }
                        >
                            <img src={DownCarrotLarge} alt="click to expand" />
                        </div>
                    </div>

                    {companyInfoExpanded && (
                        <div>
                            {Industry && <CompanyInfoLine first="Industry" second={Industry} />}
                            {(Revenue || Revenue !== 0) && <CompanyInfoLine first="Revenue" second={Revenue} />}
                            {CompanyType && <CompanyInfoLine first="Company Type" second={CompanyType} />}
                        </div>
                    )}
                </div>

                {Management.length > 0 && (
                    <div className="companyOverview__content" style={windowWidth > 900 ? { height: '360px' } : null}>
                        <div
                            className="companyOverview__headingBox"
                            onClick={windowWidth > 900 ? null : () => setManageExpanded(!manageExpanded)}
                        >
                            <h3>Management</h3>
                            <div
                                className={
                                    manageExpanded ? 'companyOverview__expand-rotate' : 'companyOverview__expand'
                                }
                            >
                                <img src={DownCarrotLarge} alt="click to expand" />
                            </div>
                        </div>

                        {manageExpanded && (
                            <div>
                                {Management.map(person => {
                                    return (
                                        <div className="companyOverview__personBox" key={person.id}>
                                            <div className="companyOverview__personBox_nameTitle">
                                                <p className="companyOverview__personName">{person.name}</p>
                                                <p className="companyOverview__personTitle">
                                                    {person.position.length > 58
                                                        ? person.position.substring(0, 58) + '...'
                                                        : person.position}
                                                </p>
                                            </div>
                                            <div className="companyOverview__personBox_social">
                                                {person.linkedin && (
                                                    <a href={person.linkedin} target="_blank" rel="noopener noreferrer">
                                                        <img src={LinkedInIcon} alt="LinkedIn" />
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                )}

                {BoardMembers.length > 0 && (
                    <div className="companyOverview__content" style={windowWidth > 900 ? { height: '360px' } : null}>
                        <div
                            className="companyOverview__headingBox"
                            onClick={windowWidth > 900 ? null : () => setBoardExpanded(!boardExpanded)}
                        >
                            <h3>Board Members</h3>
                            <div
                                className={boardExpanded ? 'companyOverview__expand-rotate' : 'companyOverview__expand'}
                            >
                                <img src={DownCarrotLarge} alt="click to expand" />
                            </div>
                        </div>

                        {boardExpanded && (
                            <div>
                                {BoardMembers.map(person => {
                                    return (
                                        <div className="companyOverview__personBox" key={person.id}>
                                            <div className="companyOverview__personBox_nameTitle">
                                                <p className="companyOverview__personName">{person.name}</p>
                                                <p className="companyOverview__personTitle">
                                                    {person.position.length > 58
                                                        ? person.position.substring(0, 58) + '...'
                                                        : person.position}
                                                </p>
                                            </div>
                                            <div className="companyOverview__personBox_social">
                                                {person.linkedin && (
                                                    <a href={person.linkedin} target="_blank" rel="noopener noreferrer">
                                                        <img src={LinkedInIcon} alt="LinkedIn" />
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
