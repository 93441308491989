import axios from 'axios'
import React, { useEffect } from 'react'
const qs = require('query-string')

export default function Unsubscribe(props) {
    const { query } = qs.parseUrl(props.location.search)
    const unsubscribe_token = query.unsubscribe_token

    useEffect(() => {
        unsubscribe(unsubscribe_token)
    }, [])

    const unsubscribe = token => {
        axios
            .get(`/accounts/email-unsubscribe/?unsubscribe_token=${token}`)
            .then(response => {
                console.log(response.data)
            })
            .catch(err => console.log(err))
    }

    return <div style={unSubStyles}>You've been unsubscribed from our newsletter</div>
}

const unSubStyles = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '50px',
}
