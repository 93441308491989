import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Grid, Button, TextField, Paper, Autocomplete, Chip, createFilterOptions } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import ClearIcon from '@mui/icons-material/Clear'
import SyncIcon from '@mui/icons-material/Sync'
import { LoadingButton } from '@mui/lab'
import { toast } from 'react-toastify'
import { getCookie } from '../../../utils'
const token = getCookie('csrftoken')

const filter = createFilterOptions()

export default function SendNewsLetter(props) {
    const {
        selectedTemplate,
        selectedStories,
        categorizedNews,
        allIndustries,
        allCompanies,
        allTopics,
        allFirms,
        subjectLine,
        introText,
        primaryColor,
        secondaryColor,
        title,
        selectedLogo,
        selectedBg,
        logoSize,
        secLogoSize,
        hyperlink,
        content,
        secondaryLogo,
        usertype,
        allFirmUsers,
        includeSection,
        includeBusiness,
        hyperlinkTitle,
        hyperlinkSource,
        setActiveStep,
        sentUsers,
        setSentUsers,
    } = props

    const [isLoading, setIsLoading] = useState(false)
    const [firmFilters, setFirmFilters] = useState([])
    const [sectorFilters, setSectorFilters] = useState([])
    const [industryFilters, setIndustryFilters] = useState([])
    const [companyFilters, setCompanyFilters] = useState([])
    const [regionFilters, setRegionFilters] = useState([])
    const [allSubCategories, setAllSubCategories] = useState([])
    const [subCategoriesFilter, setSubCategoriesFilter] = useState([])
    const [topicsFilters, setTopicsFilter] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])
    const [groupUsers, setGroupUsers] = useState([])

    function getGroupFilters() {
        const filteredUsers = allFirmUsers
        const data = {
            recipients: selectedUsers
                .map(val => val.email)
                .filter(a => filteredUsers.map(val => val.email).includes(a)),
            firms: firmFilters.map(firm => firm.value),
            sectors: sectorFilters.map(sector => sector.value),
            industries: industryFilters.map(industry => industry.value),
            companies: companyFilters.map(company => company.value),
            subcategories: subCategoriesFilter.map(category => category.value),
            topics: topicsFilters.map(topic => topic.value),
            other_recipients: selectedUsers
                .map(val => val.email)
                .filter(a => !filteredUsers.map(val => val.email).includes(a)),
        }
        return data
    }

    useEffect(() => {
        getAllSubCategories()
    }, [])

    function ManualTemplatePayload() {
        const gFilters = getGroupFilters()
        const payLoad = {
            template: selectedTemplate.id,
            news: processNews(),
            subject: subjectLine,
            introtext: introText,
            primary_color: primaryColor,
            secondary_color: secondaryColor,
            title: title,
            logo: selectedLogo,
            secondary_logo: secondaryLogo,
            background: selectedBg,
            logosize: logoSize,
            seclogosize: secLogoSize,
            hyperlink: hyperlink,
            has_section: includeSection,
            include_subcategory: includeBusiness,
            hyperlink_title: hyperlinkTitle,
            hyperlink_source: hyperlinkSource,
        }
        return { ...payLoad, filters: gFilters }
    }

    function CustomTemplatePayload() {
        let payLoad = new FormData()
        let blob = new Blob([content], { type: 'text/html' })
        const news = processNews()
        const gFilters = getGroupFilters()
        payLoad.append('htmlcontent', blob)
        payLoad.append('template', selectedTemplate.id)
        payLoad.append('news', JSON.stringify(news))
        payLoad.append('filters', JSON.stringify(gFilters))

        return payLoad
    }

    const preparePaylod = async e => {
        setIsLoading(true)
        if (selectedTemplate.custom_template) {
            const data = CustomTemplatePayload()
            sendNewsLetter(data)
        } else {
            const data = ManualTemplatePayload()
            sendNewsLetter(data)
        }
    }

    const getAllSubCategories = async () => {
        await axios.get('/news/all-subcategories/').then(response => {
            setAllSubCategories(response.data)
        })
    }

    const getRecipients = async () => {
        setSentUsers([])
        let firmIds = firmFilters.map(firm => firm.value)
        let sectorIds = sectorFilters.map(sector => sector.value)
        let industryIds = industryFilters.map(industry => industry.value)
        let companyIds = companyFilters.map(company => company.value)
        let subCategoriesIds = subCategoriesFilter.map(category => category.value)
        let topicsIds = topicsFilters.map(topic => topic.value)

        const headers = { 'X-CSRFToken': token }

        setIsLoading(true)
        axios
            .get(
                `/endusers/get-users/?firms=${firmIds}&industries=${industryIds}&sectors=${sectorIds}&companies=${companyIds}&subcategories=${subCategoriesIds}&topics=${topicsIds}`,
                { headers: headers }
            )
            .then(res => {
                setIsLoading(false)
                setGroupUsers(res.data.data)
            })
            .catch(err => {
                setIsLoading(false)
            })
    }

    const processNews = () => {
        const transformedData = {}

        if (!selectedTemplate.has_sections) {
            const sectionTitle = 'newsletter'
            transformedData[sectionTitle] = {}
            selectedStories.forEach(newsItem => {
                const indexName = newsItem.index_name
                const id = newsItem.id

                if (!transformedData[sectionTitle][indexName]) {
                    transformedData[sectionTitle][indexName] = []
                }

                transformedData[sectionTitle][indexName].push(id)
            })
        } else {
            categorizedNews.forEach(section => {
                const sectionTitle = section.title
                transformedData[sectionTitle] = {}

                section.news.forEach(newsItem => {
                    const indexName = newsItem.index_name
                    const id = newsItem.id

                    if (!transformedData[sectionTitle][indexName]) {
                        transformedData[sectionTitle][indexName] = []
                    }

                    transformedData[sectionTitle][indexName].push(id)
                })
            })
        }
        return transformedData
    }

    const sendNewsLetter = async data => {
        let url = !selectedTemplate.custom_template ? '/news/send-newsletter/' : '/endusers/custom-newsletters/'
        let token = getCookie('csrftoken')
        let headers = selectedTemplate.custom_template
            ? { 'X-CSRFToken': token, 'Content-Type': 'multipart/form-data' }
            : { 'X-CSRFToken': token }

        axios
            .post(url, data, { headers: headers })
            .then(res => {
                setIsLoading(false)
                setSelectedUsers([])

                if (res.status === 200) {
                    setSentUsers(res.data)
                    if (res.data.length > 0) {
                        usertype === 'client_analyst' || usertype === 'analyst'
                            ? toast('Newsletter Sent')
                            : setActiveStep(4)
                    } else {
                        toast.warn('Newsletter has not sent')
                    }
                } else {
                    toast('Failed to send email')
                    console.log('Failed to send email')
                }
            })
            .catch(err => {
                setIsLoading(false)
                console.log(`Could not send email due to error:\n${err}`)
            })
    }

    return (
        <>
            <Grid container spacing={2}>
                {(usertype === 'client_analyst' || usertype === 'analyst') && (
                    <Grid item xs={6}>
                        <Paper style={{ marginTop: '1rem', padding: '2rem' }}>
                            <Grid container direction="column" spacing={3}>
                                <Grid item>
                                    <h3>Group Filters</h3>
                                </Grid>
                                {(usertype === 'client_analyst' || usertype === 'analyst') && (
                                    <>
                                        <Grid item>
                                            <Grid container direction="column" spacing={3}>
                                                <Grid item>
                                                    <h4>Law Firm</h4>
                                                    <Autocomplete
                                                        multiple
                                                        options={allFirms}
                                                        value={firmFilters}
                                                        onChange={(e, firm) => setFirmFilters(firm)}
                                                        renderTags={() => null}
                                                        renderInput={params => (
                                                            <TextField {...params} variant="outlined" label="Firm" />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="row" spacing={1}>
                                                {firmFilters.map(firm => (
                                                    <Grid item>
                                                        <Chip
                                                            key={firm.value}
                                                            label={firm.label}
                                                            onDelete={() =>
                                                                setFirmFilters(
                                                                    firmFilters.filter(
                                                                        currFirm => currFirm.value !== firm.value
                                                                    )
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                <Grid item>
                                    <h4>Sector</h4>
                                    <Autocomplete
                                        multiple
                                        options={allIndustries.filter(arr => arr.type === 'Sector')}
                                        value={sectorFilters}
                                        onChange={(e, sector) => setSectorFilters(sector)}
                                        renderTags={() => null}
                                        renderInput={params => (
                                            <TextField {...params} variant="outlined" label="Sector" />
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" spacing={1}>
                                        {sectorFilters.map(sector => (
                                            <Grid item>
                                                <Chip
                                                    key={sector.value}
                                                    label={sector.label}
                                                    onDelete={() =>
                                                        setSectorFilters(
                                                            sectorFilters.filter(
                                                                currSector => currSector.value !== sector.value
                                                            )
                                                        )
                                                    }
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <h4>Industry</h4>
                                    <Autocomplete
                                        multiple
                                        options={allIndustries.filter(arr => arr.type === 'Industry')}
                                        value={industryFilters}
                                        onChange={(e, industry) => setIndustryFilters(industry)}
                                        renderTags={() => null}
                                        renderInput={params => (
                                            <TextField {...params} variant="outlined" label="Industry" />
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" spacing={1}>
                                        {industryFilters.map(industry => (
                                            <Grid item>
                                                <Chip
                                                    key={industry.value}
                                                    label={industry.label}
                                                    onDelete={() =>
                                                        setIndustryFilters(
                                                            industryFilters.filter(
                                                                currIndustry => currIndustry.value !== industry.value
                                                            )
                                                        )
                                                    }
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <h4>Company</h4>
                                    <Autocomplete
                                        multiple
                                        options={allCompanies}
                                        value={companyFilters}
                                        onChange={(e, company) => setCompanyFilters(company)}
                                        renderTags={() => null}
                                        renderInput={params => (
                                            <TextField {...params} variant="outlined" label="Company" />
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" spacing={1}>
                                        {companyFilters.map(company => (
                                            <Grid item>
                                                <Chip
                                                    key={company.value}
                                                    label={company.label}
                                                    onDelete={() =>
                                                        setCompanyFilters(
                                                            companyFilters.filter(
                                                                currCompany => currCompany.value !== company.value
                                                            )
                                                        )
                                                    }
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <h4>SubCategories</h4>
                                    <Autocomplete
                                        multiple
                                        options={allSubCategories}
                                        value={subCategoriesFilter}
                                        onChange={(e, category) => setSubCategoriesFilter(category)}
                                        renderTags={() => null}
                                        renderInput={params => (
                                            <TextField {...params} variant="outlined" label="SubCategories" />
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" spacing={1}>
                                        {subCategoriesFilter.map(category => (
                                            <Grid item>
                                                <Chip
                                                    key={category.value}
                                                    label={category.label}
                                                    onDelete={() =>
                                                        setSubCategoriesFilter(
                                                            subCategoriesFilter.filter(
                                                                currCategory => currCategory.value !== category.value
                                                            )
                                                        )
                                                    }
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                                {(usertype === 'client_analyst' || usertype === 'analyst') && (
                                    <>
                                        <Grid item>
                                            <h4>Topics</h4>
                                            <Autocomplete
                                                multiple
                                                options={allTopics}
                                                value={topicsFilters}
                                                onChange={(e, topics) => setTopicsFilter(topics)}
                                                renderTags={() => null}
                                                renderInput={params => (
                                                    <TextField {...params} variant="outlined" label="Topics" />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="row" spacing={1}>
                                                {topicsFilters.map(topic => (
                                                    <Grid item>
                                                        <Chip
                                                            key={topic.value}
                                                            label={topic.label}
                                                            onDelete={() =>
                                                                setTopicsFilter(
                                                                    topicsFilters.filter(
                                                                        currTopic => currTopic.value !== topic.value
                                                                    )
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Paper>
                    </Grid>
                )}
                <Grid
                    item
                    xs={usertype === 'Regular User' || usertype === 'Power User' ? '' : 6}
                    style={{
                        width: usertype === 'client_analyst' || usertype === 'analyst' ? '' : '99%',
                        margin: usertype === 'client_analyst' || usertype === 'analyst' ? '' : 'auto',
                    }}
                >
                    <Paper style={{ marginTop: '1rem', padding: '2rem' }}>
                        <Grid container direction="column" spacing={4}>
                            <Grid item>
                                <Grid container direction="column" spacing={3}>
                                    <Grid item>
                                        <h3>End Users</h3>
                                    </Grid>
                                    <Grid item>
                                        <Autocomplete
                                            multiple
                                            options={allFirmUsers}
                                            value={selectedUsers}
                                            getOptionLabel={option => {
                                                if (option.label) {
                                                    return option.label
                                                }

                                                return option.email
                                            }}
                                            onChange={(event, newValue) => {
                                                if (newValue && newValue.length) {
                                                    const results = []

                                                    newValue.forEach(value => {
                                                        if (typeof value === 'string') {
                                                            function ValidateEmail(mail) {
                                                                return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                                                                    mail
                                                                )
                                                            }

                                                            if (!ValidateEmail(value)) {
                                                                toast.error(
                                                                    `You have entered an invalid email address! ${value}`
                                                                )
                                                                return
                                                            }

                                                            return results.push({
                                                                email: value,
                                                            })
                                                        }

                                                        return results.push(value)
                                                    })

                                                    setSelectedUsers(results)
                                                }
                                            }}
                                            renderTags={() => null}
                                            renderInput={params => (
                                                <TextField {...params} variant="outlined" label="End Users" />
                                            )}
                                            freeSolo
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params)
                                                const { inputValue } = params

                                                const isExisting = options.some(option => inputValue === option.email)
                                                if (inputValue !== '' && !isExisting) {
                                                    filtered.push({
                                                        label: `Add email :   ${inputValue}`,
                                                        email: inputValue,
                                                    })
                                                }

                                                return filtered
                                            }}
                                            renderOption={(props, option) => (
                                                <li {...props}>
                                                    <span> {option.label || option.email}</span>{' '}
                                                    {usertype !== 'Regular User' &&
                                                        option.user_category === 'LiteUser' && (
                                                            <span
                                                                className="Lite"
                                                                style={{
                                                                    marginLeft: '5px',
                                                                    paddingLeft: '5px',
                                                                    fontSize: 'small',
                                                                    background: 'skyblue',
                                                                    padding: '2px 5px',
                                                                    borderRadius: '5px',
                                                                }}
                                                            >
                                                                Lite*
                                                            </span>
                                                        )}
                                                </li>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row" spacing={1}>
                                            {selectedUsers.map(user => (
                                                <Grid item>
                                                    <Chip
                                                        key={user.id}
                                                        label={user.email}
                                                        onDelete={() =>
                                                            setSelectedUsers(
                                                                selectedUsers.filter(
                                                                    currUser => currUser.id !== user.id
                                                                )
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                    {(usertype === 'client_analyst' || usertype === 'analyst') && (
                                        <Grid item>
                                            <Paper style={{ marginTop: '1rem', padding: '1rem' }}>
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item>
                                                        <h3>Group Filter Users</h3>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" spacing={1}>
                                                            {groupUsers.map(user => (
                                                                <Grid item>
                                                                    <Chip
                                                                        icon="information"
                                                                        key={user.id}
                                                                        label={user.email}
                                                                    />
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" spacing={1}>
                                    {(usertype === 'client_analyst' || usertype === 'analyst') && (
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                endIcon={<SyncIcon />}
                                                onClick={e => getRecipients()}
                                            >
                                                Get Recipients
                                            </Button>
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <Button
                                            disabled={selectedUsers.length === 0 && groupUsers.length === 0}
                                            variant="contained"
                                            endIcon={<ClearIcon />}
                                            onClick={e => {
                                                setSelectedUsers([])
                                                setGroupUsers([])
                                            }}
                                        >
                                            Clear Recipients
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <LoadingButton
                                            disabled={selectedUsers.length === 0 && groupUsers.length === 0}
                                            loading={isLoading}
                                            variant="contained"
                                            endIcon={<SendIcon />}
                                            onClick={e => preparePaylod(e)}
                                        >
                                            Send
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {sentUsers.length !== 0 && (
                                <Grid item>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <h5>Newsletter Sent to:</h5>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="row" spacing={1}>
                                                {sentUsers.map(user => (
                                                    <Grid item>
                                                        <Chip
                                                            color="success"
                                                            icon="success"
                                                            key={user.id}
                                                            label={user.email}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}
