import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import Header from '../../components/Header/Header'

class PrivacyScreen extends PureComponent {
    render() {
        return (
            <Wrapper>
                <Header />
                <div className="static-container">
                    <div className="main-title">
                        <h1>Privacy Policy</h1>
                    </div>
                    <div
                        className="static-content"
                        style={{ background: 'white', fontFamily: 'sans-serif', textAlign: 'justify' }}
                    >
                        <p style={{ textAlign: 'end' }}>
                            <b>Effective Date:</b> March 27, 2023
                        </p>
                        <h1 style={{ paddingTop: '10px' }}>Privacy Policy</h1>
                        <p>
                            Kaitongo Inc. (“Kaitongo”, “we,” or “us”) respects your privacy. Our digital platform
                            aggregates, refines and classifies news to provide client facing professionals personalized
                            insights about their clients and prospects, empowering them to reach out with credible
                            content and demonstrate their knowledge and expertise. This Privacy Policy (“Policy”)
                            describes how we collect, use, and disclose information about individuals and employees of
                            businesses that use the platform, and other platform visitors in connection with our
                            Services (“Users”) and the online services provided through us (collectively, the
                            “Services”). This Policy does not apply to information that you submit directly to third
                            parties, including the organizations and individuals with which you connect on the Services.
                            This Privacy Policy should be read in accordance with the Terms of Use.
                        </p>
                        <h1>General</h1>
                        <b>In this Policy, we describe:</b>
                        <p>
                            <ul>
                                <li>
                                    the information that we collect and process about you (that you provide directly to
                                    us or is provided to us by third parties such as our recruitment partners and
                                    counsellors);
                                </li>
                                <li>the purposes for which we collect and process information about you; and</li>
                                <li>
                                    the basis on which we collect and process information about you, when you use our
                                    Services located at{' '}
                                    <a href="https://insights.kaitongo.com/"> https://insights.kaitongo.com/</a> (our
                                    “Website”) and the services available through this Website, provided to our users
                                    (collectively, our “Services”).
                                </li>
                            </ul>
                        </p>
                        <p>
                            In this Policy, the term “personal data” or “personal information” means personal data or
                            data that relates to an identified or identifiable person.
                        </p>
                        <p>
                            Please read this Policy carefully in order to understand what Kaitongo and any affiliates
                            and/or subsidiaries do with your personal data. Your use of our Website and Services and any
                            dispute over privacy is subject to this Policy and any of our applicable Terms of Use for
                            use of our Services, including their applicable limitations on damages and the resolution of
                            disputes. Our Terms and Conditions for our Services are incorporated by reference into this
                            Policy.
                        </p>
                        <h1>What We Collect and How</h1>
                        <b>Data That We Collect</b>
                        <p>
                            We collect Personal Information and Other Information about Users in connection with the
                            Services. For the purposes of this Policy, “Personal Information” is any information
                            provided to us or generated within our Services or Website that personally identifies or
                            could be used to identify an individual, such as name or email address, gender,
                            identification number or other information which could be reasonably linked to such
                            identifying information.
                        </p>
                        <p>“Other Information” is any information we collect that is not Personal Information.</p>
                        <p>
                            We may combine Personal Information with Other Information; in which case we will treat the
                            combined information as Personal Information. We also may combine information that we have
                            about you with additional information that we, or other third parties, collect in other
                            contexts—such as our communications with you via email or phone, your responses to project
                            screening questions, your customer service records, or any feedback provided by a User. In
                            those circumstances, we will treat the combined information as Personal Information.{' '}
                        </p>
                        <p>For greater clarity, we may collect the following Personal Information from you: </p>
                        <table>
                            <tr>
                                <td>
                                    <b>Identifiers </b>
                                </td>
                                <td>
                                    <p>Name, email address, billing and mailing address, IP address</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Financial Information</b>
                                </td>
                                <td>
                                    Bank and credit account, and details of any transactions carried out using our
                                    Services.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Professional or Employment Information</b>
                                </td>
                                <td>Professional credentials and Role, educational and work history, letter.</td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Internet or Other Similar Network Activity</b>
                                </td>
                                <td>
                                    Other information collected through Cookies and other tracking technologies as
                                    listed and described below.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Other Categories </b>
                                </td>
                                <td>
                                    Types of News and insights that you are interested in for pursuing clients and
                                    prospects
                                </td>
                            </tr>
                        </table>
                        <h1>How Do We Collect Data?</h1>
                        <p>
                            We collect personal data directly from you, from third parties, and through the use of
                            cookies and metadata collected on our Website and Services.{' '}
                        </p>
                        <p>
                            We, and our third-party service providers, collect information automatically through our
                            Services. This may include information such as your IP address and domain name, your
                            Internet service provider, the date and time of your visit, your use of the Services during
                            your current session and over time (including the pages you view and the files you
                            download), the URLs from the websites you visit before and after navigating to the Services,
                            your computer’s operating system and browser type, your software and hardware attributes
                            (such as device IDs, device OS, and device type), search keyword and search engine, and your
                            general geographic location (e.g., your city, state, province, or metropolitan region). Such
                            information may be considered Personal Information under certain applicable law. We collect
                            such information using the following technologies:
                        </p>
                        <p>
                            <ul>
                                <li>
                                    <b>Server Logs.</b> When you use the Services, we automatically receive and record
                                    certain information from your computer (or another device) and your browser. To
                                    obtain such information, we may use server logs or applications that recognize your
                                    computer and gather information about its online activity.
                                </li>

                                <li>
                                    {' '}
                                    <b>Cookies.</b> We also use cookies. Cookies are small files that are stored on your
                                    computer (or other device) by your web browser. A cookie allows the Services to
                                    recognize whether you have visited the Website before and may store user preferences
                                    and information. If you are concerned about having cookies on your computer, you can
                                    set your browser to refuse all cookies or to indicate when a cookie is being set,
                                    allowing you to decide whether to accept it. You can also delete cookies from your
                                    computer.{' '}
                                </li>
                                <li>
                                    <b>Collaboration with Third-Party Data Collectors.</b> We may partner with certain
                                    third parties to collect, analyze, and use some of the information described above.
                                    For example, we may allow third parties to set cookies or use other tracking
                                    mechanisms (such as tags or scripts) on the Services or in email communications from
                                    us, or we may allow third parties to use an application software development kit
                                    (SDK) or a server-to-server connection to collect{' '}
                                </li>
                            </ul>
                        </p>
                        <h1>When We Collect Your Information and How We Use It</h1>
                        <p>
                            Kaitongo collects your personal data whenever you do any of the following and uses it as
                            described:
                        </p>
                        <p>
                            <b>Creating an Account:</b> To access certain features on the Website, you are required to
                            create an account and provide your email address and password or sign in with Facebook or
                            Google. Your login information is used to create your account, and otherwise provide and
                            administer the service you requested.
                        </p>
                        <p>
                            <b>Administer Services:</b> Provide the Services to you, administer your account, and
                            fulfill the terms of any agreement you have with us. Note that any information you submit
                            may be used by us to evaluate you for qualification to match and to provide the Services.
                            Kaitongo’s MyRecommendations algorithm uses your data to select News and Insights to be
                            presented on your personalized MyRecommendations page. We use Role and Professional
                            credentials data as prompts to ChatGPT to generate personalized content for your use.
                        </p>
                        <p>
                            <b>Interacting with Live Chat and Online Support Services:</b> When we provide online
                            support services powered by third parties on our Website, your information is collected with
                            the aim of operating the online support services and analyzing the use of those services. It
                            may include your first and last name, phone number, email address, IP address, and other
                            data communicated while using the support services. In addition, the support services may
                            collect browsing and usage data in the pages where they are installed, even if you do not
                            actively use them. Live chat conversations (if available) may also be logged for quality
                            assurance and training purposes.
                        </p>
                        <p>
                            These support services enable us, and the service providers we work with, to communicate
                            instantly with you and to provide additional information or answer any questions.
                        </p>
                        <p>
                            By using the support services on our Website, you explicitly consent to the collection and
                            use of your information. You are solely responsible for the information you choose to submit
                            while using the support services.
                        </p>
                        <p>
                            <b>Communicating with Us:</b> When you contact us with a comment, question or complaint, you
                            may be asked for information that identifies you (such as your name, address and a phone
                            number) along with additional information we need to help us promptly answer your question
                            or respond to your comment or complaint. We may also retain this information to assist you
                            in the future and to improve our customer service, products, service offerings, events and
                            promotions.
                        </p>
                        <p>
                            <b>Providing Information to Third Parties With Whom We Work:</b> We may obtain and/or
                            provide your personal data from and/or to third parties with whom we use to facilitate the
                            provision of our Services.
                        </p>
                        <p>
                            We are not responsible for the Privacy Policy of the third parties with whom you may
                            interact while using our Services.{' '}
                        </p>
                        <p>
                            <b>Payment Information: </b>When required, we will collect your credit card or other payment
                            information. We use third-party payment service providers to process payments on our behalf,
                            and as a result we do not ourselves store your credit card number or other payment
                            information.
                        </p>
                        <p>
                            <b>Signing Up for Marketing Email and Other Communications:</b> We may offer you the ability
                            to sign up to receive marketing and promotional communications through the Website, on third
                            party websites (including through social media sites), or educational events and information
                            sessions. In these circumstances, we collect certain personal data (such as first and last
                            name, email address or mobile phone number) which we use to send you email and other
                            communications containing updates, tips, and other content that may be of interest to you.
                            You can opt out of receiving promotional email from us by following the “opt-out” or
                            “unsubscribe” instructions provided in your email or text message, or by contacting us as
                            set out below. Please note that you may continue to receive messages from us regarding your
                            application or other transactional and account-related electronic messages from us.
                        </p>
                        <h1>When Do We Share Your Information</h1>
                        <p>
                            We share your personal data with third parties, including service providers, regulated
                            institutions (e.g., financial institutions), affiliated entities, and business partners as
                            set out below.
                        </p>
                        <p>In the section below, we also list the reasons why we share information.</p>
                        <p>
                            <b>How and Why We Share Your Information</b>
                        </p>
                        <p>
                            <ul>
                                <li>
                                    <b>Third-party service providers.</b> We use third-party service providers to help
                                    us provide, manage, and improve the Services. These service providers may collect
                                    and/or use your information to assist us in achieving the purposes discussed in this
                                    Policy. We do not exercise control over any other sites that may be linked to or
                                    accessed through the Platform or our website. We have no responsibility for the
                                    privacy practices of third-parties, including any technology or software providers,
                                    which are used within the Services. You should review the policies of any
                                    third-party sites before providing personal information to these sites.
                                </li>
                                <li>
                                    <b>Affiliates.</b> We may share information with our corporate affiliates for the
                                    purposes described in this Policy.
                                </li>
                                <li>
                                    <b>Analytics.</b> We may use your data internally for research and development to
                                    improve our existing products and services and/or create new features to serve you
                                    better and expand our user base. We may also use non-personal data (“Metadata”) for
                                    this same purpose. We may share your personal data with third parties involved in
                                    research and development to improve our existing products and services and/or create
                                    new features to serve you better.{' '}
                                </li>
                                <li>
                                    {' '}
                                    <b>Third-party plugins and integrations</b> The Services may integrate certain
                                    third-party plugins, widgets, or integrations (such as social media plugins, or
                                    integrations with partners. Even if you do not click on these plugins, widgets, or
                                    integrations, they may collect information about you, such as your IP address and
                                    the pages that you view. They also may set and/or access a cookie or other tracking
                                    mechanism. Each plugin, widget, and integration is governed by the privacy policy of
                                    the company providing it, and not this Policy.
                                </li>
                                <li>
                                    <b>Legal purposes.</b> The information we process, and store may be disclosed by us
                                    to legal authorities if we are required to do so by law. We may also disclose, at
                                    our discretion, some information we collect and store where we have reason to
                                    believe that disclosing this information is necessary to identify, contact or bring
                                    legal action against someone who may be causing interference with the rights,
                                    property, or rules of the Users, or someone who may cause or be at risk of imminent
                                    bodily harm, or someone who may be violating the Terms of Use of our Services. In
                                    such cases, the User in question will be informed.
                                </li>{' '}
                                <li>
                                    <b>Financing, business transfers, and similar transactions.</b> As we develop our
                                    business, we might sell or buy businesses or assets. In the event of a sale, merger,
                                    reorganization, dissolution, or similar event relating to all or a portion of our
                                    business or assets, your information may be part of the transferred assets. In
                                    accordance with applicable law, to a buyer or other successor in the event of a
                                    merger, divestiture, restructuring, reorganization, dissolution, or other sale or
                                    transfer of some or all of Kaitongo’s assets, whether as a going concern or as part
                                    of bankruptcy, liquidation, or similar proceeding, in which personal information
                                    held by Kaitongo about our customers and users is among the assets transferred.
                                </li>{' '}
                                <li>
                                    <b>De-identified or aggregated information.</b> We may also share de-identified and
                                    aggregated information about Services Users, such as by publishing a report on
                                    trends in the usage of the Services or sharing aggregated information about
                                    historical survey matching results.
                                </li>
                            </ul>
                        </p>
                        <p>
                            You will be able to unsubscribe from any marketing emails sent to you by Kaitongo using a
                            link provided in such emails. We do not sell your personal data.
                        </p>
                        <h1>Where We Store Your Data</h1>
                        <p>
                            All information you provide to us is stored in the servers of our cloud service providers.
                            We take reasonable steps to ensure our service providers adhere to the security standards we
                            apply to your information. Where we have given you (or where you have chosen) a password
                            which enables you to access certain parts of our site, you are responsible for keeping this
                            password confidential. We ask you not to share a password with anyone.
                        </p>
                        <p>
                            Our service providers’ servers are predominantly located in the USA and Canada. Many of our
                            service providers are also located outside of Canada. While we use appropriate safeguards to
                            keep your information secure, the laws in other places may differ from those in Canada and
                            authorities in those jurisdictions may access your information in accordance with their
                            local laws.
                        </p>
                        <h1>Safeguards</h1>
                        <p>
                            Kaitongo takes the safeguarding of your information very seriously. We have implemented
                            commercially reasonable administrative, technical and physical safeguards in an effort to
                            protect against unauthorized access, use, modification and disclosure of personal data in
                            our custody and control.
                        </p>
                        <p>
                            Unfortunately, the transmission of information via the internet is not completely secure.
                            Although we will do our best to protect your personal data, we cannot guarantee the security
                            of your data transmitted to our Services; and thus any transmission is at your own risk.
                            Once we have received your information, we will use strict procedures and security features
                            to try to prevent unauthorized access.
                        </p>
                        <h1>Your Rights</h1>
                        <p>
                            You have certain rights relating to your personal data, subject to local data protection
                            laws. Depending on the applicable laws, these rights may include the right to:
                        </p>
                        <p>
                            <ul>
                                <li>Be informed</li>
                                <li>Access your personal data</li>
                                <li>Correct or rectify inaccuracies</li>
                                <li>Erase your personal data ('be forgotten')</li>
                                <li>Restrict our processing of your personal data</li>
                                <li>Obtain and reuse your personal data</li>
                                <li>Object to the processing of your personal data</li>
                                <li>
                                    Not to be subject to a decision based solely on automated processing, including
                                    profiling
                                </li>
                                <li>Withdraw your consent</li>
                                <li>Right to report or complain</li>
                            </ul>
                        </p>
                        <p>
                            You may access, update, delete, and/or correct inaccuracies in your personal data in our
                            custody or control at any time, subject to limited exceptions prescribed by law. Simply log
                            into your account, go to your account profile, and make the necessary changes.
                        </p>
                        <p>
                            You can also request access, corrections, updates, or deletion of your personal data in our
                            records by contacting us as set out below. We may request certain personal data for the
                            purposes of verifying the identity of the individual seeking access to their personal data
                            records.
                        </p>
                        <p>
                            Should you wish to lodge a concern or complaint to Kaitongo, please contact us as set out
                            below. We will respond to your concerns in a reasonable time, typically within 10 business
                            days.
                        </p>
                        <p>
                            We are not responsible for the privacy practices of our third-party service providers and
                            recommend that you review their privacy policies carefully. We may communicate with our
                            third-party service providers, to the extent permitted by law, when you make a request based
                            on your personal data rights that involves the data collected by our third-party service
                            providers. We will keep you informed on the status of your request in such cases.
                        </p>
                        <h1>Third-Party Links, Websites, Apps, and Communication Technologies</h1>
                        <p>
                            Our Website may contain links to other websites that Kaitongo does not own or operate,
                            including the websites of third-party partners. Also, links to our Website may be featured
                            on third party websites on which we advertise. Except as provided herein, we will not
                            provide any of your personal information to these third parties without your consent. We
                            provide links to third party websites as a convenience to the user. These links are not
                            intended as an endorsement of or referral to the linked websites. The linked websites have
                            separate and independent privacy statements, notices and terms of use, which we recommend
                            you read carefully. We do not have any control over such websites, and therefore we have no
                            responsibility or liability for the manner in which the organizations that operate such
                            linked websites may collect, use or disclose, secure and otherwise treat your personal
                            information.
                        </p>
                        <p>
                            We may offer you the opportunity to engage with our content on or through third-party social
                            networking websites, plug-ins, and applications. When you engage with our content on or
                            through third-party social networking websites, plug-ins and applications, you may allow us
                            to have access to certain information associated with your social media account (e.g., name,
                            username, email address, profile picture) to deliver the content or as part of the operation
                            of the website, plug-in or application. When you provide information from your social media
                            account, we may use this information to personalize your experience on the Website and on
                            the third-party social networking websites, plug-ins and applications, and to provide you
                            with other products or services you may request.
                        </p>
                        <h1>Third-Party Analytics</h1>
                        <p>
                            Kaitongo uses the web analytics services such as Google Analytics, Microsoft Clarity and
                            HotJar to collect standard internet log information. Tools such as these may use cookies to
                            help us analyze how visitors use the Website. For example, with Google Analytics, the
                            information generated by the cookie about your use of the Website (including your IP
                            address) will be transmitted to and stored by a Google server. Google uses this information
                            for the purpose of evaluating your use of the Website, compiling reports on Website activity
                            for Website operators, and providing Website operators with other services relating to
                            Website activity and internet usage. You can prevent the storage of data relating to your
                            use of the Website and created via the cookie (including your IP address) by Google, as well
                            as the processing of this data by Google, by downloading and installing the browser plug-in
                            available at the following{' '}
                        </p>
                        <p>
                            link:{' '}
                            <a href="https://tools.google.com/dlpage/gaoptout?hl=en." target="no_blank">
                                https://tools.google.com/dlpage/gaoptout?hl=en.
                            </a>
                        </p>
                        <p>
                            You can also obtain additional information on Google Analytics’ collection and processing of
                            data and data privacy and security at the following links:
                            <a href="https://policies.google.com/technologies/partner-sites">
                                https://policies.google.com/technologies/partner-sites
                            </a>{' '}
                            and{' '}
                            <a href="https://support.google.com/analytics/topic/2919631">
                                https://support.google.com/analytics/topic/2919631
                            </a>
                            .
                        </p>
                        <h1>Third-Party Ad Networks</h1>
                        <p>
                            We use third parties, such as network advertisers and other marketing agents, to display
                            advertisements on our Website, to assist us in displaying advertisements on third party
                            websites, and to evaluate the success of our advertising campaigns. Network advertisers are
                            third parties that display advertisements based on your visits to our Website and unrelated
                            third-party websites. This enables us and these third parties to target advertisements by
                            displaying ads for products and services in which you might be interested. Third-party ad
                            network providers, advertisers, sponsors and/or traffic measurement services may use
                            cookies, JavaScript, web beacons (including clear GIFs), Flash LSOs and other technologies
                            to measure the effectiveness of their ads and to personalize advertising content to you.
                            These third-party cookies and other technologies are governed by each third party’s specific
                            privacy policy, not this one. We may provide these third-party advertisers with information
                            about your use of our Website and our Services, as well as aggregate or non-personally
                            identifiable information about visitors to our Website and users of our Service.
                        </p>
                        <h1>Data Retention</h1>
                        <p>
                            Kaitongo will only retain your personal data while it is needed to fulfill the intended
                            purpose for which it was collected or as otherwise permitted or required by law or contract
                            (which may extend beyond the termination of our relationship with you).
                        </p>
                        <p>
                            Typically, personal data is maintained during the time you are engaged in the institutional
                            program you applied to, or for up to a year following our last contact with you. We may
                            retain certain data as necessary for:
                        </p>
                        <p>
                            <ul>
                                <li>Legitimate business purposes, such as auditing, account recovery;</li>
                                <li>If required by applicable federal, state, provincial, or other laws;</li>
                                <li>To prevent fraud or future abuse;</li>
                                <li>By contract with a partner and/or institution.</li>
                            </ul>
                        </p>
                        <p>All retained personal data is treated in accordance with this Policy.</p>
                        <p>
                            If you request that your personal data be removed from our databases and/or systems, it may
                            not be possible to completely delete all your personal data due to technological and legal
                            constraints. However, we will take such steps as are reasonable to securely destroy the
                            information or to ensure that the information is de identified and/or anonymized as
                            necessary.
                        </p>
                        <h1>Children’s Privacy</h1>
                        <p>
                            We do not knowingly collect information from children under the age of 16 and do not target
                            our Services to children under 16. If you are under 16, please do not use the Services. We
                            encourage parents and legal guardians to monitor their children’s Internet usage and to help
                            enforce this prohibition. If you have reason to believe that a child under the age of 16 has
                            provided information to us, please contact us, and we will endeavor to delete that
                            information from our databases.
                        </p>
                        <h1>Governing Law</h1>
                        <p>
                            We have taken commercially reasonable steps to ensure your data is protected in accordance
                            with the Personal Information and Electronic Documents Act (“PIPEDA”), including leveraging
                            the cyber security services, firewalls, as well as other security measures embedded within
                            our third party service providers. From time to time, your Personal Information may be
                            accessed by Kaitongo-authorized services or staff in another country where Kaitongo or
                            authorized third parties operate. We implement measures to protect your information and to
                            ensure compliance with applicable laws.
                        </p>
                        <p>
                            All issues and questions concerning the application, construction, validity, interpretation
                            and enforcement of this Privacy Policy shall be governed by and construed in accordance with
                            the laws of the Province of Ontario, and the federal laws of Canada applicable therein.
                        </p>
                        <p>
                            To inquire about or exercise your rights in accordance with applicable law, please contact
                            us at support@kaitongo.com.
                        </p>
                        <h1>Changes to Our Privacy Policy</h1>
                        <p>
                            This Policy may change from time to time. Any changes in the future will be posted on our
                            website and, where appropriate, we will notify you by email.
                        </p>
                        <p>
                            We strongly encourage you to check back frequently to see any updates or changes to our
                            Policy. We will not materially reduce your rights under this Policy without taking steps to
                            bring such changes to your attention.
                        </p>
                        <h1>Contact Us, Questions, Updating Your Info, Opting Out</h1>
                        <p>
                            If you use our Services and would like to update your personal data, please log into your
                            account on our Website in order to update certain information or contact us through the
                            contact information below. From time to time, we may email you or send you with special
                            offers; you may opt-out of those offers by using a link provided in the email, or by
                            contacting us as noted below.
                        </p>
                        <p>Please contact us if:</p>
                        <p>
                            <ul>
                                <li>You have any questions or comments about this Policy</li>
                                <li>
                                    You wish to access, update, and/or correct inaccuracies in your personal data and
                                    are unable to do it yourself
                                </li>
                                <li>
                                    You otherwise have a question or complaint about the manner in which we or our
                                    service providers treat your personal data.
                                </li>
                            </ul>
                        </p>
                        <p>
                            You may contact us by email at{' '}
                            <a href="mailto:support@kaitongo.com">support@kaitongo.com</a>.
                        </p>
                        <h1 />
                    </div>
                </div>
            </Wrapper>
        )
    }
}

export default PrivacyScreen

const Wrapper = styled.div`
    a {
        text-decoration: underline;
        font-weight: bold;
    }

    h1 {
        color: black;
    }

    h3 {
        margin-bottom: 2rem;
    }

    section {
        margin-bottom: 2rem;
    }

    hr {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    th,
    td {
        border: 1px solid #ccc;
        padding: 8px;
        text-align: left;
    }

    @media (max-width: 575px) {
        .list-style-upper-alpha {
        }
    }
`
