import React, { useEffect, useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { Input } from 'reactstrap'
import SearchDropDown from './SearchDropDown'
import axios from 'axios'
import { BiSearch } from 'react-icons/bi'
import { Button } from '@mui/material'
import { AiFillSchedule } from 'react-icons/ai'
import SendIcon from '@mui/icons-material/Send'
import { IoMdSettings } from 'react-icons/io'
import RangeInput from './RangeInput'

export default function NewSearch(props) {
    const {
        basicSearch,
        basicSearchInput,
        setBasicSearchInput,
        clearFilters,
        setSaveQueryName,
        selectedStories,
        setActiveStep,
        isAdvanceSearch,
    } = props
    const {
        wrapperRef,
        allTopics,
        allSubCategories,
        allIndustries,
        basicSearchDrop,
        setBasicSearchDrop,
        setSchedulingSection,
        defaultData,
        setDefaultData,
        setNewsFilters,
        filterRef,
        OpenFilter,
    } = props

    const {
        relavancesection,
        setRelavanceSection,
        selectedRelavance,
        setSelectedRelavance,
        selectedAlpha,
        setSelectedAlpha,
        userName,
        limit,
        setLimit,
        limitValue,
        setLimitValue,
        selectedOption,
        generateQuery,
    } = props

    const [cancelToken, setCancelToken] = useState(null)
    const [cancelSource, setCancelSource] = useState(null)

    useEffect(() => {
        if (cancelToken) {
            cancelToken.cancel('Operation canceled due to new request.')
        }
        const newCancelSource = axios.CancelToken.source()
        setCancelToken(newCancelSource)
        setCancelSource(newCancelSource.token)

        if (basicSearchInput && basicSearchInput.length > 2) {
            axios
                .get(`/news/auto-complete/?type=company&string=${encodeURIComponent(basicSearchInput)}`)
                .then(response => {
                    const filteredData = response.data.map(item => ({
                        value: item.id,
                        label: item.name,
                        url: item.url,
                        type: 'Company',
                        alias: item.alias,
                    }))
                    const uniqueData = new Set([...filteredData])
                    const arrayFromSet = [...uniqueData]
                    setDefaultData(arrayFromSet)
                })
                .catch(err => console.log(err))
        }

        return () => {
            if (cancelToken) {
                cancelToken.cancel('Component unmounted.')
            }
        }
    }, [basicSearchInput])

    const handleChange = e => {
        setLimit(e.target.value)
    }

    return (
        <div className="basic_search_inputs" style={{ width: '100%', margin: 'auto' }}>
            {/* <div style={{ fontWeight: 'bold' }}>Search</div> */}
            <div style={{ marginTop: '8px' }} className="row">
                <div className="col-8" style={{ flex: 4 }}>
                    <div className="d-flex align-items-center">
                        <Input
                            ref={filterRef}
                            type="text"
                            value={basicSearchInput}
                            placeholder="Search by topic, industry, location or keywords"
                            id="quickinput"
                            style={{
                                height: '40px',
                                border: '1.7px solid #dadada',
                                outline: 'none',
                                borderRadius: '4px',
                                background: 'white',
                            }}
                            onChange={e => {
                                setBasicSearchInput(e.target.value)
                                setBasicSearchDrop(e.target.value.length >= 3 ? true : false)
                                OpenFilter()
                            }}
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()

                                    OpenFilter()
                                    // if (basicSearchInput.length > 0) {
                                    basicSearch()
                                    // }
                                }
                            }}
                            onFocus={() => {
                                // OpenFilter()
                                setNewsFilters(true)
                            }}
                            autoComplete="off"
                        />

                        {userName === 'vignesh.perumal@data-aces.com' && (
                            <div
                                id="relavance_drop"
                                style={{ marginLeft: '-30px', padding: '0px 5px', marginTop: '-3px' }}
                            >
                                <div className="">
                                    <div
                                        onClick={() => {
                                            setRelavanceSection(!relavancesection)
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <IoMdSettings
                                            style={{
                                                fontSize: '18px',
                                                color: '#3B7DBA',
                                                width: '20px',
                                                height: '20px',
                                            }}
                                        />
                                    </div>
                                </div>
                                {relavancesection && (
                                    <div
                                        ref={wrapperRef}
                                        className="date_section"
                                        style={{
                                            // width: '250px',
                                            marginTop: '18px',
                                            background: 'white',
                                            position: 'absolute',
                                            padding: '20px',
                                            height: 'auto',
                                            cursor: 'pointer',
                                            marginLeft: '-135px',
                                            borderRadius: '4px',
                                            boxShadow: '0px 0px 2px 2px lightgray',
                                            zIndex: 1,
                                        }}
                                    >
                                        <div className="range pb-2">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <label style={{ fontWeight: 'bold' }}>Limit </label>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                value="yes"
                                                                checked={limit === 'yes'}
                                                                onChange={handleChange}
                                                            />
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div className="ms-3">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                value="no"
                                                                checked={limit === 'no'}
                                                                onChange={handleChange}
                                                            />
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {limit === 'yes' && (
                                                <div style={{ fontSize: '13px', marginTop: '5px' }}>
                                                    <input
                                                        class="form-range"
                                                        style={{ width: '170px' }}
                                                        value={limitValue}
                                                        onChange={e => {
                                                            setLimitValue(e.target.value)
                                                        }}
                                                        type="number"
                                                        // disabled={limit === 'no'}
                                                    />
                                                    <span
                                                        onClick={() => {
                                                            basicSearchInput !== '' && generateQuery(selectedOption)
                                                        }}
                                                    >
                                                        <BiSearch
                                                            style={{
                                                                fontSize: '18px',
                                                                color: '#3B7DBA',
                                                                marginLeft: '10px',
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                        {limit === 'no' && (
                                            <div className="range">
                                                <label
                                                    className="d-flex justify-content-between align-items-center"
                                                    style={{ fontWeight: 'bold' }}
                                                >
                                                    <span>
                                                        Relevance Cut-off{' '}
                                                        <span style={{ fontSize: '13px' }}>({selectedRelavance})</span>
                                                    </span>
                                                </label>
                                                <RangeInput
                                                    selectedValue={selectedRelavance}
                                                    setSelectedValue={setSelectedRelavance}
                                                    min={10}
                                                    max={100}
                                                    step={10}
                                                />
                                            </div>
                                        )}
                                        <div className="range">
                                            <label style={{ fontWeight: 'bold' }}>
                                                Semantic Preference{' '}
                                                <span style={{ fontSize: '13px' }}>({selectedAlpha})</span>
                                            </label>
                                            <RangeInput
                                                selectedValue={selectedAlpha}
                                                setSelectedValue={setSelectedAlpha}
                                                min={0}
                                                max={100}
                                                step={5}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        {!isAdvanceSearch && (
                            <button
                                ref={filterRef}
                                onClick={() => {
                                    OpenFilter()
                                    // if (basicSearchInput.length > 0) {
                                    basicSearch()
                                    // }
                                }}
                                style={{
                                    background: '#186ADE',
                                    color: 'white',
                                    border: 'none',
                                    marginLeft: '20px',
                                    borderRadius: '4px',
                                    width: '40px',
                                    height: '40px',
                                    opacity: basicSearchInput.length === 0 ? 0.6 : 1,
                                }}
                                className="d-flex align-items-center justify-content-center"
                            >
                                <BiSearch style={{ fontSize: '18px', color: 'white' }} />
                            </button>
                        )}
                    </div>
                </div>

                {/* {!isAdvanceSearch && (
                    <div className="col text-end">
                        <Button
                            variant="contained"
                            endIcon={<SendIcon style={{ fontSize: '18px' }} />}
                            style={{
                                background: '#186ADE',
                                borderRadius: '4px',
                                width: 'max-content',
                                marginRight: '20px',
                                fontSize: '14px',
                                height: '40px',
                                color: 'white',
                                opacity: selectedStories.length > 0 ? 1 : 0.6,
                                textTransform: 'none',
                            }}
                            disabled={selectedStories.length > 0 ? false : true}
                            onClick={() => {
                                // setSaveQueryName('Schedule: ' + basicSearchInput)
                                // setSchedulingSection(true)
                                setActiveStep(1)
                            }}
                        >
                            Send Newsletter
                        </Button>
                    </div>
                )} */}
            </div>
            {basicSearchInput && basicSearchInput.length > 2 && basicSearchDrop && (
                <SearchDropDown
                    setNewsFilters={setNewsFilters}
                    setBasicSearchDrop={setBasicSearchDrop}
                    setBasicSearchInput={setBasicSearchInput}
                    basicSearch={basicSearch}
                    searchInput={basicSearchInput}
                    allCompanies={defaultData}
                    allIndustries={allIndustries}
                    allCategories={allSubCategories}
                    allTopics={allTopics}
                    wrapperRef={wrapperRef}
                    isWeaviate={true}
                    OpenFilter={OpenFilter}
                />
            )}
        </div>
    )
}
