import React from 'react'
import { getFullFormattedDate } from '../../../utils/dateHelpers'

export default function CompanyNewsModal(props) {
    const { val, original } = props
    return (
        <div
            className={'card-body '}
            style={{
                overflow: 'hidden',
                // maxHeight: '220px',
                textOverflow: 'ellipsis',
                whiteSpace: 'normal',
                padding: '',
                marginTop: '',
                fontSize: '14px',
                padding: '10px 10px 0px 15px',
                background: !original ? 'white' : 'rgba(102, 178, 236, 0.1)',
                borderLeft: '3px solid rgb(25, 118, 210)',
                marginLeft: '5px',
                paddingBottom: '10px',
            }}
        >
            <div className="d-flex justify-content-between" style={{ marginBottom: '-8px' }}>
                <i style={{ color: 'gray' }}>{getFullFormattedDate(val.date)}</i>
                <a
                    href={val.news_url ? val.news_url : val.source ? val.source : ''}
                    style={{ textDecoration: 'none', color: 'gray' }}
                    target="_blank"
                >
                    <span title="source" className="d-flex align-items-center">
                        {(val.source_name || val.source) && val.source_subscription && (
                            <img
                                src="https://kaitongo-static.s3.amazonaws.com/insights/static/paywall.svg"
                                style={{ width: '10px', height: '14px', marginRight: '5px' }}
                            />
                        )}

                        {val.source_name && val.source_name !== ''
                            ? val.source_name
                            : val.source
                                  .match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/gim)[0]
                                  .replace(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?/gim, '')}
                    </span>
                </a>
            </div>
            <a
                href={val.news_url ? val.news_url : val.source ? val.source : ''}
                target="_blank"
                // title={val.title}
                style={{ textDecoration: 'none' }}
            >
                <h4
                    className="card-title"
                    style={{
                        cursor: 'pointer',
                        fontSize: '15px',
                        marginTop: '',
                    }}
                >
                    {val.title.replace(/\\/g, ' ')}
                </h4>
            </a>
            <div
                className="cut-offtext"
                style={{
                    color: 'gray',
                    marginTop: '5px',
                }}
            >
                {val.snippet}
            </div>
            <div
                className=""
                style={{
                    marginTop: '5px',
                    width: '100%',
                    borderTop: '0px',
                    padding: '0px 0px 0px 15px',
                }}
            >
                <div
                    className="CompanyNewsCard__container d-flex align-items-center"
                    style={{
                        width: '100% ',
                        boxShadow: 'none',
                    }}
                >
                    {val.subcategories && val.subcategories.length > 0 && (
                        <span style={{ marginLeft: 'auto' }} title="Business Events">
                            {val.subcategories.map(v => (
                                <span
                                    className="sectors_tag_value"
                                    style={{
                                        margin: '2px 0px',
                                        fontSize: '12px',
                                        background: '#66B2EC1A',
                                        color: 'rgb(25, 118, 210)',
                                        fontWeight: 600,
                                    }}
                                >
                                    {v.name}
                                </span>
                            ))}
                        </span>
                    )}
                </div>
            </div>
        </div>
    )
}
