import React, { useState, useEffect } from 'react'
import './Chat.css'
import { Switch } from '@mui/material'
import { IoMdSend } from 'react-icons/io'
import { FaSearchengin } from 'react-icons/fa'
import { Button } from 'reactstrap'
import { BsChatFill } from 'react-icons/bs'
import axios from 'axios'

const SemanticChat = props => {
    const [messages, setMessages] = useState([{ text: 'Hi, How can I help? ', sender: 'sender' }])
    const [newMessage, setNewMessage] = useState('')
    const [responseMessage, setResponseMessage] = useState([])
    const [isTyping, setIsTyping] = useState(false)
    const [typing, setTyping] = useState(false)
    const { chat, setChat, context } = props
    const [opencontext, setOpenContext] = useState(false)

    const handleSendMessage = () => {
        if (newMessage.trim() !== '') {
            const userMessage = { text: newMessage, sender: 'user' }
            setMessages(prevMessages => [...prevMessages, userMessage])
            setNewMessage('')

            setTyping(true)
            setResponseMessage([])

            const search = {
                context_id: context.context_id,
                chat_question: newMessage,
                is_new_search: false,
                open_context: opencontext,
            }
            axios
                .post('/semantic/search/', search)
                .then(datas => {
                    console.log('sfbsd', datas)
                    console.log('text', datas.data.text)
                    setTyping(false)
                    const responseText = datas.data.text
                    simulateTypingEffect(responseText)
                })
                .catch(error => {
                    console.error('Error:', error)
                })
        }
    }

    const simulateTypingEffect = text => {
        let currentIndex = 0
        const typingInterval = setInterval(() => {
            setIsTyping(true)
            if (currentIndex < text.length) {
                setResponseMessage(prevMessages => [...prevMessages, text[currentIndex]])
                currentIndex++
            } else {
                setIsTyping(false)
                clearInterval(typingInterval)

                const senderMessage = {
                    text: text,
                    sender: 'sender',
                }
                setMessages(prevMessages => [...prevMessages, senderMessage])
            }
        }, 30)
    }

    useEffect(() => {
        if (isTyping) {
            const messageList = document.querySelector('.message-list')
            if (messageList) {
                messageList.scrollTop = messageList.scrollHeight
            }
        }
    }, [isTyping])

    return (
        <div className="chat-container">
            <div style={{ marginLeft: 'auto', marginRight: '1%' }}>
                <label style={{ fontSize: '16px', fontWeight: '600' }}>Open context:</label>
                <Switch
                    defaultChecked={opencontext}
                    onChange={checked => {
                        setOpenContext(!opencontext)
                    }}
                />
            </div>
            <div className="message-list">
                {messages.map((message, index) => (
                    <div key={index} className={message.sender === 'user' ? 'message user' : 'sender'}>
                        <p
                            style={{
                                marginLeft: '20px',
                                color: 'black',
                                marginTop: 0,
                                marginBottom: 0,
                            }}
                            id={message.sender === 'user' ? `user_id_${index}` : `sender_id_${index}`}
                        >
                            {message.text}
                        </p>
                    </div>
                ))}
                {isTyping && (
                    <div className="sender">
                        <p
                            style={{
                                marginLeft: '20px',
                                color: 'black',
                                marginTop: 0,
                                marginBottom: 0,
                            }}
                        >
                            {responseMessage.join('')}
                        </p>
                    </div>
                )}
                {typing && (
                    <div className="sender">
                        <p
                            style={{
                                marginLeft: '20px',
                                color: 'black',
                                marginTop: 0,
                                marginBottom: 0,
                            }}
                        >
                            ....
                        </p>
                    </div>
                )}
            </div>
            <div className="d-flex">
                <div class="search">
                    <input
                        type="text"
                        className="chatboxinput"
                        placeholder="Type a message..."
                        value={newMessage}
                        onChange={e => setNewMessage(e.target.value)}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                handleSendMessage()
                            }
                        }}
                    />
                    <IoMdSend className="sendico" onClick={handleSendMessage} />
                </div>
                <Button
                    title="Sematic search"
                    onClick={() => setChat(false)}
                    style={{
                        borderRadius: '50px',
                        backgroundColor: '#0a294f',
                        border: 'none',
                        marginLeft: '3%',
                    }}
                >
                    <FaSearchengin style={{ fontSize: '29px', color: 'white' }} />
                </Button>
            </div>
        </div>
    )
}

export default SemanticChat
