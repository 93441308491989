import React, { useEffect, useRef, useState } from 'react'
import FilterListIcon from '@mui/icons-material/FilterList'
import { Button, Chip } from '@mui/material'

export default function JustShowFilters(props) {
    const { combinedFilters, filterLength, setNewsFilters } = props

    const [isExpanded, setIsExpanded] = useState(false)
    const [isOverflowing, setIsOverflowing] = useState(false)
    const textRef = useRef(null)
    const toggleExpanded = () => setIsExpanded(prev => !prev)

    useEffect(() => {
        const element = textRef.current

        if (element) {
            // Check if content overflows
            const isOverflow = element.scrollHeight > element.clientHeight
            setIsOverflowing(isOverflow)
        }
    }, [filterLength])

    return (
        <div className="mt-3">
            <div
                onClick={() => {
                    setNewsFilters(true)
                }}
                style={{
                    cursor: 'pointer',
                }}
                className={`d-flex ${!isExpanded ? 'align-items-center' : ''} justify-content-between`}
            >
                <div className={`d-flex ${!isExpanded ? 'align-items-center' : ''}`}>
                    <div
                        className={`font-h d-flex ${!isExpanded ? 'align-items-center' : ''}`}
                        style={{ marginTop: isExpanded ? '10px' : '0px' }}
                    >
                        <span>Filters</span> <span>{filterLength !== 0 && '(' + filterLength + ')'}</span>
                        <span className="d-none d-sm-block">
                            <FilterListIcon className="font-h d-none d-sm-block" />
                        </span>
                    </div>

                    <div
                        ref={textRef}
                        style={{
                            overflow: 'hidden',
                            display: isExpanded ? 'block' : '-webkit-box',
                            WebkitLineClamp: isExpanded ? 'unset' : 1,
                            WebkitBoxOrient: 'vertical',
                            margin: 0,
                        }}
                    >
                        {combinedFilters.map(val => (
                            <Chip
                                key={val}
                                label={val}
                                style={{
                                    background: 'none',
                                    border: '1px solid #3f3f3f',
                                    color: '#3f3f3f',
                                    marginLeft: '10px',
                                    marginTop: isExpanded ? '10px' : '0px',
                                }}
                            />
                        ))}
                    </div>
                </div>
                {isOverflowing && (
                    <button
                        onClick={toggleExpanded}
                        style={{
                            fontSize: '14px',
                            whiteSpace: 'nowrap',
                            marginTop: isExpanded ? '10px' : '0px',
                        }}
                        className={`d-flex ${!isExpanded ? 'align-items-center' : ''}`}
                    >
                        {isExpanded ? 'See Less' : 'See More'}
                    </button>
                )}
            </div>
        </div>
    )
}
