import React, { useEffect, useState } from 'react'
import BaseScreen from '../../BaseScreen/BaseScreen'
import DataTable from 'react-data-table-component'
import axios from 'axios'
import { AiOutlineDelete } from 'react-icons/ai'
import { MdModeEdit } from 'react-icons/md'
import { Modal, ModalBody } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import RenderTemplate from './RenderTemplate'
import Grape from './Grapejs'
import { toast } from 'react-toastify'
import NewsLetterTemplateForm from './NewsLetterTemplateForm'
import { FaArrowLeft } from 'react-icons/fa'

export default function NewsLetterTemplate() {
    const [allTemplates, setAllTemplates] = useState([])
    const [search, setSearch] = useState('')
    const [openTemplateModal, setOpenTemplateModal] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState({})
    const [htmlContent, setHtmlContent] = useState()
    const [templateForm, setTemplateForm] = useState(false)
    const [newTemplate, setNewTemplate] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [goBackModal, setGoBackModal] = useState(false)
    const [confirmGo, setConfirmGo] = useState(false)

    useEffect(() => {
        getAllTemplate()
        const headerTitle = document.getElementById('header_title')
        headerTitle.textContent = 'Templates'
    }, [])

    const getAllTemplate = () => {
        axios.get('/endusers/templates/').then(response => {
            setAllTemplates(response.data)
        })
    }

    useEffect(() => {
        if (selectedTemplate.custom_template)
            axios.get(`/news/custom-templates/${selectedTemplate.id}`).then(res => {
                setHtmlContent(res.data)
            })
    }, [selectedTemplate])

    const deleteTemplate = templateId => {
        axios
            .delete('/endusers/templates/', { data: { id: templateId } })
            .then(res => {
                setAllTemplates(allTemplates.filter(val => val.id !== templateId))
                toast('Template Deleted')
            })
            .catch(err => console.log(err))
    }

    const columns = [
        {
            name: <p style={{ fontSize: '16px', fontWeight: '700', color: '#1c2b3e' }}>S.No</p>,
            selector: (row, index) => index + 1,
            width: '7%',
        },
        {
            name: <p style={{ fontSize: '16px', fontWeight: '700', color: '#1c2b3e' }}>Preview</p>,
            selector: row => (
                <div
                    className="d-flex"
                    style={{
                        cursor: 'pointer',
                        width: '60px',
                        height: '60px',
                        borderRadius: '50%',
                        objectFit: 'cover',
                        padding: '1px',
                    }}
                    onClick={() => {
                        setOpenTemplateModal(true)
                        setSelectedTemplate(row)
                    }}
                >
                    <img src={row.thumbnail} style={{ width: '100%', height: '100%' }} alt="thumbail" />
                </div>
            ),
            width: '12%',
        },
        {
            name: <p style={{ fontSize: '16px', fontWeight: '700', color: '#1c2b3e' }}>Name</p>,
            selector: row => row.name,
            sortable: true,
            width: '30%',
        },
        {
            name: <p style={{ fontSize: '16px', fontWeight: '700', color: '#1c2b3e' }}>Firm</p>,
            selector: row => row.firm_name,
            sortable: true,
            width: '20%',
        },

        {
            name: <p style={{ fontSize: '16px', fontWeight: '700', color: '#1c2b3e' }}>Action</p>,
            selector: row => (
                <div className="d-flex" style={{ cursor: 'pointer' }}>
                    <MdModeEdit
                        className="edit_action_btn"
                        title="edit"
                        onClick={() => {
                            setSelectedTemplate(row)
                            if (row.custom_template === true) {
                                setNewTemplate(true)
                            } else {
                                setTemplateForm(true)
                            }
                        }}
                    />
                    <AiOutlineDelete
                        className="delete_action_btn"
                        onClick={() => {
                            // deleteTemplate(row.id)
                            setDeleteModal(true)
                            setSelectedTemplate(row)
                        }}
                        style={{ marginLeft: '10px' }}
                        title="delete"
                    />
                </div>
            ),
        },
    ]

    const news = [{ news: [], title: 'Test' }]
    const [newss, setNewss] = useState([])

    const setSize = () => {
        return 140, 150
    }

    return (
        <div>
            <BaseScreen>
                {newTemplate && (
                    <div style={{ marginTop: '150px' }}>
                        <div className="indSelect__container">
                            <div className="indSelect__content" style={{ width: '100%' }}>
                                <span
                                    onClick={() => {
                                        if (!confirmGo) {
                                            setGoBackModal(true)
                                            setConfirmGo(false)
                                        } else {
                                            setNewTemplate(false)
                                            setSelectedTemplate({})
                                            getAllTemplate()
                                            setGoBackModal(false)
                                            setConfirmGo(false)
                                        }
                                    }}
                                    className={!newTemplate ? 'indSelect__link-Active' : 'indSelect__link-Inactive'}
                                    style={{ marginRight: '35px' }}
                                >
                                    <FaArrowLeft style={{ fontSize: '16px', color: 'rgb(105, 100, 100)' }} />
                                </span>
                                <span className={newTemplate ? 'indSelect__link-Active' : 'indSelect__link-Inactive'}>
                                    Edit Template
                                </span>
                            </div>
                        </div>
                        <Grape selectedTemplate={selectedTemplate} setConfirmGo={setConfirmGo} />
                    </div>
                )}
                <div style={{ width: '99%', background: 'white', display: newTemplate ? 'none' : '' }}>
                    {templateForm ? (
                        <div style={{ marginTop: '54px' }}>
                            <div className="indSelect__container">
                                <div className="indSelect__content" style={{ width: '100%' }}>
                                    <span
                                        onClick={() => {
                                            if (!confirmGo) {
                                                setGoBackModal(!goBackModal)
                                                setConfirmGo(false)
                                            } else {
                                                getAllTemplate()
                                                setTemplateForm(false)
                                                setGoBackModal(false)
                                                setConfirmGo(false)
                                            }
                                        }}
                                        className={
                                            !templateForm ? 'indSelect__link-Active' : 'indSelect__link-Inactive'
                                        }
                                        style={{ marginRight: '35px' }}
                                    >
                                        <FaArrowLeft style={{ fontSize: '16px', color: 'rgb(105, 100, 100)' }} />
                                    </span>
                                    <span
                                        className={templateForm ? 'indSelect__link-Active' : 'indSelect__link-Inactive'}
                                    >
                                        Edit Template
                                    </span>
                                </div>
                            </div>
                            <div style={{ padding: '20px' }}>
                                <NewsLetterTemplateForm
                                    selectedTemplate={selectedTemplate}
                                    setConfirmGo={setConfirmGo}
                                />
                            </div>
                        </div>
                    ) : (
                        <DataTable
                            style={{ paddingLeft: '20px' }}
                            columns={columns}
                            data={
                                search === ''
                                    ? allTemplates
                                    : allTemplates.filter(each =>
                                          each.name.toLowerCase().includes(search.toLowerCase())
                                      )
                            }
                            pagination
                            fixedHeader
                            noHeader={true}
                            subHeader
                            subHeaderComponent={
                                <>
                                    <NavLink to="/app/create-template/" className="adminEndUser__newUserButton">
                                        <span>Create New Template+</span>
                                    </NavLink>
                                    <input
                                        type="search"
                                        placeholder="Search here"
                                        className="w-25 form-control"
                                        value={search}
                                        onChange={e => {
                                            setSearch(e.target.value)
                                        }}
                                    />
                                </>
                            }
                        />
                    )}
                </div>

                <Modal
                    className="newsCard20__deleteConfirmModalContainer"
                    isOpen={deleteModal}
                    toggle={() => {
                        setDeleteModal(!deleteModal)
                    }}
                    size=""
                    style={{ width: '700px' }}
                >
                    <ModalBody>
                        <div style={{ textAlign: 'center' }}>
                            <p
                                style={{ textAlign: 'right', cursor: 'pointer', marginBottom: 0 }}
                                onClick={() => setDeleteModal(false)}
                            >
                                X
                            </p>
                            <p style={{ fontSize: '20px' }}>
                                Are you sure you want to delete
                                <br /> ({selectedTemplate.name}) this template
                            </p>
                            <div className="d-flex justify-content-around">
                                <div className="btn btn-danger" onClick={() => setDeleteModal(false)}>
                                    No
                                </div>
                                <div
                                    // style={{ paddingRight: '20px' }}
                                    className="btn btn-primary"
                                    style={{ background: '#197bb3', color: 'white' }}
                                    onClick={() => {
                                        deleteTemplate(selectedTemplate.id)
                                        setDeleteModal(false)
                                    }}
                                >
                                    Yes
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={openTemplateModal}
                    toggle={() => {
                        setOpenTemplateModal(!openTemplateModal)
                    }}
                    size="lg"
                    style={{ width: '720px' }}
                >
                    <ModalBody>
                        {selectedTemplate.custom_template === true ? (
                            <>
                                <div style={{ marginBottom: '25px' }} />
                                <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                            </>
                        ) : (
                            <RenderTemplate
                                setSize={setSize}
                                justPreview={true}
                                selectedStories={selectedTemplate.has_sections ? news : newss}
                                title={selectedTemplate.title}
                                selectedLogo={selectedTemplate.logo}
                                selectedTemplate={selectedTemplate}
                                introText={selectedTemplate.introtext}
                                primaryColor={selectedTemplate.primary_color}
                                secondaryLogo={selectedTemplate.secondary_logo}
                                secondaryColor={selectedTemplate.secondary_color}
                                selectedBg={selectedTemplate.has_background ? selectedTemplate.background_image : null}
                                newsBodySize={false}
                            />
                        )}
                    </ModalBody>
                </Modal>

                <Modal
                    className="newsCard20__deleteConfirmModalContainer"
                    isOpen={goBackModal}
                    toggle={() => {
                        setGoBackModal(!goBackModal)
                    }}
                    size=""
                    style={{ width: '700px' }}
                >
                    <ModalBody>
                        <div style={{ textAlign: 'center' }}>
                            <p
                                style={{ textAlign: 'right', cursor: 'pointer', marginBottom: 0 }}
                                onClick={() => setGoBackModal(false)}
                            >
                                X
                            </p>
                            <p style={{ fontSize: '20px' }}>
                                Are you sure you want to Go back
                                <br /> without any changes
                            </p>
                            <div className="d-flex justify-content-around">
                                <div className="btn btn-danger" onClick={() => setGoBackModal(false)}>
                                    No
                                </div>
                                <div
                                    className="btn btn-primary"
                                    style={{ background: '#197bb3', color: 'white' }}
                                    onClick={() => {
                                        if (newTemplate) {
                                            setNewTemplate(false)
                                            setSelectedTemplate({})
                                            getAllTemplate()
                                            setGoBackModal(false)
                                        }
                                        if (templateForm) {
                                            getAllTemplate()
                                            setTemplateForm(false)
                                            setGoBackModal(false)
                                        }
                                    }}
                                >
                                    Yes
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </BaseScreen>
        </div>
    )
}
