import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import { getTrans } from '../../utils'
import AuthWrapper from '../../wrappers/AuthWrapper/AuthWrapper'
import { validateForm } from '../../utils/validateForm'
import { Form, Formik } from 'formik'
import RenderField from '../../form-elements/RenderField'
import { Button } from 'reactstrap'
import axios from 'axios'

const fields = [
    {
        label: 'Password',
        value: '',
        name: 'password',
        component: 'password',
        placeholder: 'Password',
        type: 'password',
        required: true,
    },
    {
        label: getTrans('Confirm password'),
        value: '',
        name: 'password2',
        component: 'password',
        placeholder: getTrans('Type your password again'),
        type: 'password',
        required: true,
    },
]

class SetPasswordScreen extends PureComponent {
    state = {
        nonFieldErrors: [],
        successMessage: '',
    }

    onSubmit = (values, { setSubmitting }) => {
        const { history, match } = this.props

        axios
            .patch(`/accounts/set-password/${match.params.userId}/`, {
                password: values.password,
                token: match.params.token,
            })
            .then(response => {
                console.log('Create password response', response)
                history.push('/reset-password-confirmation')
            })
            .catch(() => {
                this.setState({
                    nonFieldErrors: [getTrans('We could not reset your password.')],
                })
            })
            .finally(() => {
                setSubmitting(false)
            })
    }

    render() {
        return (
            <AuthWrapper title={getTrans('Create your password')}>
                <Formik
                    initialValues={{
                        password: '',
                        password2: '',
                    }}
                    validate={values => validateForm(values, fields)}
                    onSubmit={this.onSubmit}
                    render={({ status, isSubmitting, ...formProps }) => (
                        <Form>
                            {fields.map(field => {
                                return RenderField(field, formProps)
                            })}
                            {this.state.nonFieldErrors.map(error => (
                                <div className="input-feedback" key={error}>
                                    {error}
                                </div>
                            ))}
                            <div className="text-right">
                                <Button color="primary" type="submit" disabled={isSubmitting}>
                                    {getTrans('Create your password')}
                                </Button>
                            </div>
                        </Form>
                    )}
                />
            </AuthWrapper>
        )
    }
}

export default withRouter(SetPasswordScreen)
