import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button, Modal, ModalBody } from 'reactstrap'
var lo_ = require('lodash')

export default function AssignContacts(props) {
    const [modalOpen, setModalOpen] = useState(false)
    const [allContacts, setAllContacts] = useState([])
    const [nameFilter, setNameFilter] = useState('')

    const { selectedContacts, setSelectedContacts } = props

    useEffect(() => {
        if (modalOpen) {
            getAllContacts()
        }
    }, [modalOpen])

    const getAllContacts = () => {
        axios.get('/news/contacts/').then(response => {
            setAllContacts(response.data)
        })
    }

    const addContactToSelected = contact => {
        setSelectedContacts([...selectedContacts, contact])
    }
    const removeContactFromSelected = contact => {
        let newContacts = [...selectedContacts]
        newContacts = newContacts.filter(item => item.id !== contact.id)
        setSelectedContacts(newContacts)
    }

    return (
        <div>
            <div
                className="adminEndUser__assignContactsButtonContainer"
                style={{ marginTop: '5px', textAlign: 'right' }}
            >
                <Button
                    className="adminEndUser__submitButton"
                    style={{
                        width: '60%',
                        height: '40px',
                        backgroundColor: 'rgb(59, 125, 186)',
                        color: 'white',
                        fontSize: '14px',
                    }}
                    onClick={() => setModalOpen(true)}
                >
                    Assign Contacts
                </Button>
            </div>

            <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} size="lg">
                <ModalBody>
                    <div className="assignContacts__2columns">
                        <div className="assignContacts__leftColumn">
                            <h3>Contacts Master List</h3>

                            <input
                                type="search"
                                placeholder="Search names"
                                className="myRelationships__searchBox"
                                onChange={ev => setNameFilter(ev.target.value)}
                            />

                            <div className="assignContacts__mapContainer">
                                {allContacts
                                    .filter(contact => {
                                        return !lo_.some(selectedContacts, contact)
                                    })
                                    .filter(contact => {
                                        return (
                                            contact.first_name.toUpperCase().includes(nameFilter.toUpperCase()) ||
                                            contact.last_name.toUpperCase().includes(nameFilter.toUpperCase())
                                        )
                                    })
                                    .map(item => {
                                        return (
                                            <p onClick={() => addContactToSelected(item)} key={'master' + item.id}>
                                                {item.first_name + ' ' + item.last_name}
                                            </p>
                                        )
                                    })}
                            </div>
                        </div>
                        <div className="assignContacts__rightColumn">
                            <h3>Assigned Contacts</h3>
                            <div className="assignContacts__mapContainer">
                                {selectedContacts.map(item => {
                                    return (
                                        <p onClick={() => removeContactFromSelected(item)} key={'selected' + item.id}>
                                            {item.first_name + ' ' + item.last_name}{' '}
                                            <span className="assignContacts__closeX">X</span>
                                        </p>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="assignContacts__updateButtonContainer">
                        <Button className="adminEndUser__submitButton" onClick={() => setModalOpen(false)}>
                            Update Contacts
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}
