import React, { useState, useEffect } from 'react'
import axios from 'axios'

export default function TokenPage() {
    const [data, setData] = useState([])
    useEffect(() => {
        axios.get(`/ChimeraToken.txt`).then(response => {
            setData(response.data)
            console.log(response.data)
        })
    })
    return <div>{data}</div>
}
