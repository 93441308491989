import React, { useEffect, useState } from 'react'
import SearchDropDown from './SearchDropDown'
import { BiSearch } from 'react-icons/bi'
import { Input } from 'reactstrap'
import { IoMdSettings } from 'react-icons/io'
import axios from 'axios'

export default function QuickSearch(props) {
    const {
        basicSearch,
        basicSearchInput,
        setBasicSearchInput,
        basicSearchDrop,
        setBasicSearchDrop,
        allIndustries,
        allCompanies,
        allSubCategories,
        allTopics,
        wrapperRef,
        clearFilters,
        popularsearch,
        selectedLevel,
        usertype,
        relavancesection,
        setRelavanceSection,
        handleLevelChange,
        currentPath,
    } = props

    const [data, setData] = useState([])
    const [cancelToken, setCancelToken] = useState(null)
    const [cancelSource, setCancelSource] = useState(null)

    useEffect(() => {
        if (cancelToken) {
            cancelToken.cancel('Operation canceled due to new request.')
        }
        const newCancelSource = axios.CancelToken.source()
        setCancelToken(newCancelSource)
        setCancelSource(newCancelSource.token)
        if (basicSearchInput.length > 3) {
            axios
                .get(`/news/auto-complete/?type=company&string=${encodeURIComponent(basicSearchInput)}`)
                .then(response => {
                    const filteredData = response.data.map(item => ({
                        value: item.id,
                        label: item.name,
                        url: item.url,
                        type: 'Company',
                    }))
                    const uniqueData = new Set([...filteredData])
                    const arrayFromSet = [...uniqueData]
                    console.log('log', filteredData)
                    setData(arrayFromSet)
                })
                .catch(err => console.log(err))
        }
        return () => {
            if (cancelToken) {
                cancelToken.cancel('Component unmounted.')
            }
        }
    }, [basicSearchInput])
    return (
        <div className="basic_search_inputs" style={{ width: '99.5%', margin: 'auto' }}>
            <div style={{ display: 'flex', margin: 'auto', marginTop: '15px' }}>
                <Input
                    type="text"
                    value={basicSearchInput}
                    placeholder="Search for a company or Industry"
                    style={{
                        height: '40px',
                        margin: 'auto',
                        border: '1.7px solid #dadada',
                        outline: 'none',
                    }}
                    id="quickinput"
                    onChange={e => {
                        setBasicSearchInput(e.target.value)
                        setBasicSearchDrop(e.target.value.length >= 3 ? true : false)
                    }}
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            e.preventDefault()
                            basicSearch(basicSearchInput, 'free')
                        }
                    }}
                    autoComplete="off"
                />

                {currentPath === '/app/raw-search/' && (
                    <div id="relavance_drop" style={{ marginLeft: '-30px', padding: '0px 5px', marginTop: '5px' }}>
                        <div className="">
                            <div
                                onClick={() => {
                                    setRelavanceSection(!relavancesection)
                                }}
                            >
                                <IoMdSettings
                                    style={{
                                        fontSize: '18px',
                                        color: '#3B7DBA',
                                        width: '20px',
                                        height: '20px',
                                    }}
                                />
                            </div>
                        </div>
                        {relavancesection && (
                            <div
                                ref={wrapperRef}
                                className="date_section"
                                style={{
                                    marginTop: '20px',
                                    background: 'white',
                                    position: 'absolute',
                                    padding: '20px',
                                    height: 'auto',
                                    cursor: 'pointer',
                                    marginLeft: '-100px',
                                    borderRadius: '4px',
                                    boxShadow: '0px 0px 2px 2px lightgray',
                                    zIndex: 1,
                                }}
                            >
                                <div>
                                    <label
                                        className="d-flex justify-content-between align-items-center"
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        <span>Curation Level</span>
                                    </label>
                                    <div className="d-flex align-items-center">
                                        <span className="d-flex align-items-center">
                                            <input
                                                type="radio"
                                                id="level0"
                                                name="level"
                                                value="level0"
                                                checked={selectedLevel === 'level0'}
                                                onChange={handleLevelChange}
                                            />
                                            <label htmlFor="level0">Level 0</label>
                                        </span>
                                        <span className="d-flex align-items-center" style={{ marginLeft: '10px' }}>
                                            <input
                                                type="radio"
                                                id="level1"
                                                name="level"
                                                value="level1"
                                                checked={selectedLevel === 'level1'}
                                                onChange={handleLevelChange}
                                            />
                                            <label htmlFor="level1">Level 1</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                <button
                    onClick={() => basicSearch(basicSearchInput, 'free')}
                    style={{
                        background: '#197bbd',
                        color: 'white',
                        border: 'none',
                        padding: '0px 12px',
                    }}
                >
                    <BiSearch style={{ fontSize: '18px' }} />
                </button>
                <button
                    title="clear all filters"
                    onClick={clearFilters}
                    style={{
                        background: 'white',
                        color: 'gray',
                        border: 'none',
                        fontSize: '13px',
                        color: 'gray',
                    }}
                >
                    RESET
                </button>
            </div>
            {basicSearchInput.length >= 3 && basicSearchDrop && (
                <SearchDropDown
                    setBasicSearchDrop={setBasicSearchDrop}
                    setBasicSearchInput={setBasicSearchInput}
                    basicSearch={basicSearch}
                    searchInput={basicSearchInput}
                    allCompanies={data}
                    allIndustries={allIndustries}
                    allCategories={allSubCategories}
                    allTopics={allTopics}
                    wrapperRef={wrapperRef}
                    popularBtns={popularsearch.length}
                />
            )}
        </div>
    )
}
