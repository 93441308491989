import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'reactstrap'
import axios from 'axios'
import './Filter.css'
import './Chat.css'

import { Switch } from '@mui/material'
import { IoMdSend } from 'react-icons/io'
import { FaSearchengin } from 'react-icons/fa'

import { toast } from 'react-toastify'
import { BiSearch, BiEraser } from 'react-icons/bi'
import { BsChatFill, BsFillChatDotsFill, BsFullscreen } from 'react-icons/bs'
import { BiMinusCircle } from 'react-icons/bi'
import { FiMinimize2 } from 'react-icons/fi'
import { AiFillCloseCircle, AiOutlineMinus, AiOutlineClose, AiOutlineExpandAlt } from 'react-icons/ai'
import NoResults from '../../../components/NoResults/NoResults'
import ResultHeader from './ResultsHeader'
import SearchQueryView from '../../../components/NewsEditedCard/SearchQueryView'
import InfiniteScroll from 'react-infinite-scroller'
import { Circles } from 'react-loader-spinner'
import { Grid } from '@mui/material'
import SemanticChat from './SemanticChat'
import uuid from 'react-uuid'
import { RiSpamLine } from 'react-icons/ri'

export default function SemanticSearch(props) {
    // const embedding = new IEmbeddingFunction()
    const {
        // isAllSelected,
        // setAllSelected,
        expandedView,
        setExpandedView,
        selectedNewsOnly,
        setSelectedNewsOnly,
        selectedStories,
        allCategories,
        setSelectedStories,
        allLinkedInGroups,
        // removeFromSelection,
        // addToSelection,
        handleLoadMore,
        usertype,
        isLoading,
        setIsLoading,
        allSubCategories,
        allIndustries,
        allCompanies,
        allTopics,
        allRegions,
    } = props
    const [isAllSelected, setAllSelected] = useState(false)
    const [context, setContext] = useState(null)
    const [newText, setNewText] = useState('')
    const [results, setResults] = useState([])
    const [chatModal, setChatModal] = useState(false)
    const [Message, setMessage] = useState([])
    const [chaticon, setChatIcon] = useState(false)
    // const [chat, setChat  ] = useState(true)

    const [messages, setMessages] = useState([{ text: 'Hi, How can I help? ', sender: 'sender' }])
    const [newMessage, setNewMessage] = useState('')
    const [responseMessage, setResponseMessage] = useState([])
    const [isTyping, setIsTyping] = useState(false)
    const [typing, setTyping] = useState(false)
    const [expand, setExpand] = useState(false)
    const [opencontext, setOpenContext] = useState(true)
    const [minContext, setMinContext] = useState(false)
    const [chatid, setChatid] = useState('')
    const [cache, setChache] = useState([])
    const [cachenews, setCachenews] = useState(false)
    const [maxnews, setMaxnews] = useState(false)
    const [opencontextnews, setOpencontextnews] = useState(true)

    useEffect(() => {
        isAllSelected
            ? setSelectedStories(current => [
                  ...current,
                  ...results.data.data.filter(each => !selectedStories.includes(each)),
              ])
            : setSelectedStories([])
    }, [isAllSelected])

    const addToSelection = story => {
        console.log(selectedNewsOnly, selectedStories, story)
        // if (selectedStories.length >= 3) {
        //     toast.warn('Maximum 3 can be selected')
        //     setMaxnews(true)
        //     return
        // }
        setSelectedStories([...selectedStories, story])
        setChatid(uuid())
    }

    useEffect(() => {
        const isSame = JSON.stringify(cache) !== JSON.stringify(selectedStories.map(val => val.news_id))

        setCachenews(!isSame)
    }, [cache, selectedStories])

    console.log(cachenews)

    const removeFromSelection = story => {
        let selectedCopy = selectedStories.filter(arr => arr.id !== story.news_id)
        setSelectedStories(selectedCopy)
        setChatid(uuid())
        if (selectedCopy.length === 0) {
            setNewMessage('')
            setResponseMessage([])
            setMessages([{ text: 'Hi, How can I help? ', sender: 'sender' }])
            setChatModal(false)
        }
    }
    const id = uuid()

    const search = {
        context_id: id,
        search_query: newText,
        is_new_search: true,
    }

    const handleopencontext = () => {
        if (opencontext === false) {
            setOpenContext(true)
            setOpencontextnews(true)
            setSelectedStories([])
        } else {
            setOpenContext(false)
            setNewMessage('')
            setResponseMessage([])
            setMessages([{ text: 'Hi, How can I help? ', sender: 'sender' }])
            setChatModal(false)
            setOpencontextnews(false)
            setMinContext(false)
            if (selectedStories.length > 0) {
                setChatModal(true)
            } else {
                toast.warn('Please select some news')
            }
        }
    }

    const handlechat = () => {
        if (opencontext) {
            setChatModal(true)
        } else if (!opencontext) {
            if (selectedStories.length > 0) {
                setChatModal(true)
            } else {
                toast.warn('Please select some news')
            }
        }
    }

    const handleSearch = () => {
        setResults({ data: [] })
        setIsLoading(true)
        setContext(search)
        setChatIcon(false)
        setChatModal(false)
        axios
            .post('/semantic/search/', search)
            .then(datas => {
                const statusCode = datas.status
                if (statusCode === 204 || statusCode === 400) {
                    setIsLoading(false)
                    setNewMessage('')
                    setResponseMessage([])
                    toast('Please rephrase your query')
                }
                // else if (statusCode === 400) {
                //     setIsLoading(false)
                //     toast('')
                // }
                else {
                    setIsLoading(false)
                    setNewMessage('')
                    setSelectedStories([])
                    setNewMessage('')
                    setResponseMessage([])
                    setMessages([{ text: 'Hi, How can I help? ', sender: 'sender' }])
                    setMinContext(false)
                    const news = datas.data.Level1_news
                    setChatIcon(true)
                    setResults({
                        data: {
                            data: news,
                        },
                    })
                }
            })
            .catch(error => {
                console.error('Error:', error)
                setIsLoading(false)
            })
    }

    const calculateResultCount = () => {
        if (results && results.data && results.data.data) {
            const filteredData = results.data.data.filter(
                val => (val.title && val.title.length > 0) || (val.title && val.title !== '') || !val.title
            )

            return filteredData.length
        }
        return 0
    }
    const handleSendMessage = () => {
        setChache(selectedStories.map(value => value.news_id))
        if (newMessage.trim() !== '') {
            const userMessage = { text: newMessage, sender: 'user' }
            setMessages(prevMessages => [...prevMessages, userMessage])
            setNewMessage('')

            setTyping(true)
            setResponseMessage([])

            const search = {
                context_id: context.context_id,
                chat_question: newMessage,
                is_new_search: false,
                news_ids: selectedStories.map(value => value.news_id),
                cache_news: cachenews,
            }
            axios
                .post('/semantic/search/', search)
                .then(datas => {
                    console.log('sfbsd', datas)
                    console.log('text', datas.data.text)

                    setTyping(false)
                    const responseText = datas.data.text
                    simulateTypingEffect(responseText)
                })
                .catch(error => {
                    console.error('Error:', error)
                })
        }
    }

    const simulateTypingEffect = text => {
        let currentIndex = 0
        const typingInterval = setInterval(() => {
            setIsTyping(true)
            if (currentIndex < text.length) {
                setResponseMessage(prevMessages => [...prevMessages, text[currentIndex]])
                currentIndex++
            } else {
                setIsTyping(false)
                clearInterval(typingInterval)

                const senderMessage = {
                    text: text,
                    sender: 'sender',
                }
                setMessages(prevMessages => [...prevMessages, senderMessage])
            }
        }, 30)
    }

    useEffect(() => {
        if (isTyping) {
            const messageList = document.querySelector('.message-list')
            if (messageList) {
                messageList.scrollTop = messageList.scrollHeight
            }
        }
    }, [isTyping])

    return (
        <>
            <div
                className="query_editor"
                style={{
                    background: 'white',
                    padding: '20px 10px 7% 10px',
                    marginTop: '-2px',
                    width: '99%',
                }}
            >
                {/* <div className="d-flex">
                    {' '}
                    <div
                        style={{
                            width: '80%',
                            margin: 'auto',
                            border: '1px solid #dad8d8',
                            borderRadius: '3px',
                            background: '#f7f7f7',
                            // marginTop: '10px',
                            marginLeft: '85px',
                        }}
                    >
                        <textarea
                            className="query_editor_textarea"
                            type="text"
                            value={newText}
                            id="textarea_edit_query"
                            style={{
                                height: '45px',
                                width: '96%',
                                outline: 'none',
                                border: 'none',
                                resize: 'none',
                                background: '#f7f7f7',
                            }}
                            onChange={e => {
                                setNewText(e.target.value)
                            }}
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()
                                    handleSearch()
                                }
                            }}
                            // placeholder='Search'
                        />

                        <span
                            className="remove_btn_query_editor"
                            style={{
                                position: 'absolute',
                                cursor: 'pointer',
                            }}
                        >
                            <BiEraser
                                style={{ fontSize: '20px' }}
                                onClick={() => {
                                    setNewText('')
                                }}
                            />
                        </span>
                    </div>
                    {chaticon && (
                        <Button
                            title="chat"
                            onClick={() => {
                                handlechat()
                            }}
                            style={{
                                borderRadius: '50px',
                                backgroundColor: '#0a294f',
                                marginRight: ' 3%',
                                border: 'none',
                            }}
                        >
                            <BsChatFill style={{ fontSize: '24px', color: 'white' }} />
                        </Button>
                    )}
                </div> */}
                {chaticon && (
                    <div style={{ marginLeft: '83%' }}>
                        <label style={{ fontSize: '14px', fontWeight: 'bold' }}>Open context:</label>
                        <Switch
                            defaultChecked={opencontext}
                            onChange={checked => {
                                handleopencontext()
                            }}
                        />
                    </div>
                )}

                <div style={{ display: 'flex', margin: '15px auto auto 6%' }}>
                    <textarea
                        className="query_editor_textarea"
                        type="text"
                        value={newText}
                        id="textarea_edit_query"
                        style={{
                            height: '10%',
                            width: '85%',
                            outline: 'none',
                            border: '1.7px solid rgb(218, 218, 218)',
                            resize: 'none',
                            background: '#f7f7f7',
                        }}
                        onChange={e => {
                            setNewText(e.target.value)
                        }}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                e.preventDefault()
                                handleSearch()
                            }
                        }}
                        // placeholder='Search'
                    />
                    <span
                        className="remove_btn_query_editor"
                        style={{
                            position: 'absolute',
                            cursor: 'pointer',
                            right: '14%',
                        }}
                    >
                        <BiEraser
                            style={{ fontSize: '20px' }}
                            onClick={() => {
                                setNewText('')
                            }}
                        />
                    </span>
                    <button
                        onClick={() => handleSearch()}
                        style={{
                            background: '#197bbd',
                            color: 'white',
                            border: 'none',
                            padding: '3px 12px 3px 12px',
                        }}
                    >
                        <BiSearch style={{ fontSize: '18px' }} />
                    </button>
                    {chaticon && (
                        <Button
                            title="chat"
                            onClick={() => {
                                handlechat()
                            }}
                            style={{
                                borderRadius: '50px',
                                backgroundColor: '#0a294f',
                                marginLeft: ' 3%',
                                border: 'none',
                            }}
                        >
                            <BsChatFill style={{ fontSize: '24px', color: 'white' }} />
                        </Button>
                    )}
                </div>

                {/* <div
                    className="date_slider d-flex jsutify-content_between"
                    style={{ width: '90%', margin: '2% auto 0 8%' }}
                >
                    <span
                        // className="btn_color btn_text"
                        style={{
                            marginLeft: 'auto',
                        }}
                        onClick={() => {
                            setNewText('')
                        }}
                    >
                        RESET
                    </span>
                </div> */}
            </div>
            <div>
                {results &&
                results.data &&
                results.data.data &&
                (results.data.data.length > 0 ||
                    results.data.data.filter(
                        val => (val.title && val.title.length > 0) || (val.title && val.title !== '') || !val.title
                    ).length > 0) ? (
                    <div style={{ width: '92%' }}>
                        <ResultHeader
                            style={{ fontSize: '20px' }}
                            isBasicSearchPage={true}
                            isSemantic={true}
                            allSelected={isAllSelected}
                            setAllSelected={setAllSelected}
                            resultLength={calculateResultCount()}
                            resultCount={calculateResultCount()}
                            expanded={expandedView}
                            setExpanded={setExpandedView}
                            selectedNewsOnly={selectedNewsOnly}
                            setSelectedNewsOnly={setSelectedNewsOnly}
                        />
                        <Grid item>
                            <InfiniteScroll
                                pageStart={0}
                                loadMore={handleLoadMore}
                                hasMore={results.data.next < results.data.total}
                                loader={<Circles key={0} type="Circles" color="#adadad" height={80} width={80} />}
                            >
                                {results.data.data.map(story => {
                                    return (
                                        <SearchQueryView
                                            isFindAndShare={true}
                                            key={story.id}
                                            EachrawNews={story}
                                            isSemantic={true}
                                            isSemanticnews={maxnews}
                                            selectedStories={selectedStories}
                                            isStorySelected={
                                                selectedStories.findIndex(arr => arr.news_id === story.news_id) === -1
                                                    ? false
                                                    : true
                                            }
                                            addToSelection={addToSelection}
                                            allCategories={allCategories}
                                            opencontext={opencontextnews}
                                            allSubCategories={allSubCategories}
                                            allIndustries={allIndustries}
                                            allCompanies={allCompanies}
                                            allRegions={allRegions}
                                            allLinkedInGroups={allLinkedInGroups}
                                            removeFromSelection={removeFromSelection}
                                            allTopics={allTopics}
                                            collapsed={!expandedView}
                                            usertype={usertype}
                                        />
                                    )
                                })}
                            </InfiniteScroll>
                        </Grid>
                    </div>
                ) : isLoading ? (
                    <div style={{ marginLeft: '40%', marginTop: '10px' }}>
                        <Circles key={0} type="Circles" color="#adadad" height={80} width={80} />
                    </div>
                ) : (
                    <div style={{ marginLeft: '36%', marginTop: '3%' }}>
                        <NoResults />
                    </div>
                )}
                {chatModal && (
                    <div
                        style={{
                            width: expand ? '79%' : '32%',
                            background: 'white',
                            top: minContext ? '90%' : '25%',
                            zIndex: '5',
                            right: '10px',
                            position: 'fixed',
                            boxShadow: '0 0 10px',
                            borderRadius: ' 9px',
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                height: '50px',
                                background: 'black',
                                color: 'white',
                                borderRadius: '9px 9px 0px 0px',
                            }}
                        >
                            <p
                                style={{
                                    textAlign: 'center',
                                    fontSize: '27px',
                                    padding: '5px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    // justifyContent: 'center',
                                    fontWeight: '700',
                                }}
                            >
                                <span style={{ fontSize: '20px', fontWeight: '600', marginTop: '5PX' }}>Chat</span>
                                <span
                                    onClick={() => {
                                        setExpand(!expand)
                                    }}
                                    className="modal_close_btn mins"
                                    style={{ marginLeft: 'auto', marginRight: '5px', fontSize: '23px' }}
                                >
                                    {expand ? <FiMinimize2 /> : <AiOutlineExpandAlt />}
                                </span>
                                <span
                                    onClick={() => {
                                        setMinContext(!minContext)
                                    }}
                                    className="modal_close_btn mins"
                                    style={{ marginRight: '5px' }}
                                >
                                    {minContext ? <BsFullscreen /> : <AiOutlineMinus />}
                                </span>
                                <span
                                    onClick={() => {
                                        setChatModal(false)
                                        setNewMessage('')
                                        setResponseMessage([])
                                        setMessages([{ text: 'Hi, How can I help? ', sender: 'sender' }])
                                    }}
                                    className="modal_close_btn close "
                                    style={{ marginRight: '5px' }}
                                >
                                    <AiOutlineClose />
                                </span>
                            </p>
                        </div>
                        <div className="chat-container" style={{ display: minContext ? 'none' : '' }}>
                            <div className="message-list">
                                {messages.map((message, index) => (
                                    <div key={index} className={message.sender === 'user' ? 'message user' : 'sender'}>
                                        <p
                                            style={{
                                                marginLeft: '20px',
                                                color: 'black',
                                                marginTop: 0,
                                                marginBottom: 0,
                                                fontSize: '14px',
                                            }}
                                            id={message.sender === 'user' ? `user_id_${index}` : `sender_id_${index}`}
                                        >
                                            {message.text}
                                        </p>
                                    </div>
                                ))}
                                {isTyping && (
                                    <div className="sender">
                                        <p
                                            style={{
                                                marginLeft: '20px',
                                                color: 'black',
                                                marginTop: 0,
                                                marginBottom: 0,
                                                fontSize: '14px',
                                            }}
                                        >
                                            {responseMessage.join('')}
                                        </p>
                                    </div>
                                )}
                                {typing && (
                                    <div className="sender">
                                        <p
                                            style={{
                                                marginLeft: '20px',
                                                color: 'black',
                                                marginTop: 0,
                                                marginBottom: 0,
                                                fontSize: '14px',
                                            }}
                                        >
                                            ....
                                        </p>
                                    </div>
                                )}
                            </div>

                            <div class="search">
                                <input
                                    type="text"
                                    className="chatboxinput"
                                    placeholder="Type a message..."
                                    value={newMessage}
                                    onChange={e => setNewMessage(e.target.value)}
                                    onKeyPress={e => {
                                        if (e.key === 'Enter') {
                                            handleSendMessage()
                                        }
                                    }}
                                />
                                <IoMdSend className="sendico" onClick={handleSendMessage} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
