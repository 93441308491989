import React from 'react'

export default function ImportMenu(props) {
    return (
        <div>
            <p className="importContacts__topText"> Import or enter your contact data to Kaitongo APP</p>

            <div className="importContacts__choices">
                {/* <div className="importContacts__choiceBox">
                    <h4>Import Contacts from Spreadsheet</h4>
                    <p>Upload any CSV, Ms Excel file with contact names and details </p>
                    <div className="importContacts__selectButton">Coming Soon</div>
                </div>
                <div className="importContacts__choiceBox">
                    <h4>Import Contacts from Email</h4>
                    <p>Import your contacts from Office 365/Outlook to Kaitongo </p>
                    <div className="importContacts__selectButton">Coming Soon</div>
                </div> */}
                <div className="importContacts__choiceBox">
                    <h4>Import Contacts Manually</h4>
                    <p>Enter your contacts name and details one at a time</p>
                    <div className="importContacts__selectButton" onClick={() => props.setManualModelOpen(true)}>
                        Select
                    </div>
                </div>
            </div>
        </div>
    )
}
