export const newUserValidate = (values, emailError, emailExists) => {
    if (
        values.first_name === '' ||
        // values.last_name === '' ||
        values.email === '' ||
        emailError === true ||
        emailExists === true
        // values.firm === '' ||
        // values.country === 0 ||
        // values.subscriptionType === ''
    ) {
        return false
    } else {
        return true
    }
}

export const newFirmValidate = (values, mobileImg, desktopImg) => {
    if (
        values.firmName === '' ||
        values.contactName === '' ||
        values.email === '' ||
        values.website === '' ||
        values.description === ''
    ) {
        return false
    } else {
        return true
    }
}
