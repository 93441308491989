import React from 'react'
import FilterListIcon from '@mui/icons-material/FilterList'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { getShortDate } from '../../../utils/dateHelpers'

export default function NewsHeaderSection(props) {
    const {
        filterLength,
        newsData,
        setNewsFilters,
        currentDate,
        sortBy,
        setSortBy,
        isAllSelected,
        setAllSelected,
        isQuickSearch,
        isAdvanceSearch,
        isMyRequest,
        isCreationMyRequest,
        isMyRequestPage,
        isAssistant,
        handleAllSelected,
        relevantNews,
        searchName,
        customDate,
        approxLength,
        isMyRequestPageRunAll,
    } = props

    function formatDateRange(from, to) {
        const options = { month: 'short', day: 'numeric' } // Format for day and month

        const fromDate = new Date(from) // Parse the 'from' date string
        const toDate = new Date(to) // Parse the 'to' date string

        // Get the formatted month and day part of the 'from' and 'to' dates
        const fromFormatted = fromDate.toLocaleDateString('en-US', options)
        const toFormatted = toDate.toLocaleDateString('en-US', options)

        // Get the years of the 'from' and 'to' dates
        const fromYear = fromDate.getFullYear()
        const toYear = toDate.getFullYear()

        // If the years are different, display both 'from' and 'to' years
        if (fromYear !== toYear) {
            return `${fromFormatted}, ${fromYear} - ${toFormatted}, ${toYear}`
        }

        // If the months are different, display both 'from' and 'to' months
        if (fromDate.getMonth() !== toDate.getMonth()) {
            return `${fromFormatted} - ${toFormatted}, ${toYear}`
        }

        // If same month and year, return shortened format (e.g., "Oct 1-12, 2024")
        const toDay = toDate.getDate() // Get only the day part of the 'to' date
        return `${fromFormatted.split(' ')[0]} ${fromDate.getDate()}-${toDay}, ${toYear}`
    }

    return (
        <>
            {relevantNews && (
                <div style={{ fontSize: '17px', textAlign: 'center', fontWeight: 'bold' }}>
                    We found no direct news for {searchName}. Here is some relevant news from its Sector
                </div>
            )}
            <div
                className={`d-flex align-items-center justify-content-between ${isAdvanceSearch ? 'm-auto' : ''}`}
                style={{ width: isAdvanceSearch ? '97%' : '', marginTop: relevantNews ? '15px' : '' }}
            >
                {(isQuickSearch || isMyRequestPage || isAssistant) && filterLength === 0 ? (
                    <div
                        onClick={() => {
                            setNewsFilters(true)
                        }}
                        style={{ cursor: 'pointer' }}
                        title="Click to add filters"
                        className="d-flex align-items-center"
                    >
                        <span className="font-h"> Filters {filterLength !== 0 && '(' + filterLength + ')'}</span>
                        <span className="d-none d-sm-block">
                            <FilterListIcon className="font-h d-none d-sm-block" />
                        </span>
                    </div>
                ) : (
                    <div />
                )}

                <div className="font-h d-none d-sm-block">
                    Showing 1 - {newsData.data.length} of {approxLength && <> &asymp;</>}
                    {newsData.total}
                    {approxLength && <> (Count may change post-validation) </>} results
                    {!isAssistant && !isMyRequestPageRunAll && currentDate !== 0 && (
                        <React.Fragment>
                            {customDate !== 'custom' ? (
                                <>
                                    <span> for last </span>
                                    <span>
                                        {isQuickSearch || isMyRequestPage
                                            ? currentDate === 2
                                                ? 1 + ' day'
                                                : currentDate === 3
                                                ? 2 + ' days'
                                                : currentDate + ' days'
                                            : currentDate + ' days'}
                                    </span>
                                </>
                            ) : (
                                <span>
                                    {currentDate.startDate && currentDate.endDate && (
                                        <> from {formatDateRange(currentDate.startDate, currentDate.endDate)}</>
                                    )}
                                </span>
                            )}
                        </React.Fragment>
                    )}
                    {!isAssistant && !isMyRequestPageRunAll && currentDate === 0 && <> for last 1 day</>}
                </div>
                <div>
                    {(isQuickSearch || isMyRequestPage || isAssistant || isMyRequestPageRunAll) && (
                        <select
                            style={{ outline: 'none', borderRadius: '4px', background: 0 }}
                            value={sortBy}
                            onChange={e => {
                                setSortBy(e.target.value)
                            }}
                            disabled={
                                (isQuickSearch || isMyRequestPage || isAssistant) && newsData.data[0].score
                                    ? false
                                    : true
                            }
                        >
                            <option value={null} style={{ display: 'none' }}>
                                Sort by
                            </option>
                            <option value="date" style={{ borderRadius: '0px' }}>
                                Date
                            </option>
                            <option value="relevance">Relevance</option>
                        </select>
                    )}
                </div>
            </div>
            <div
                style={{
                    width: isAdvanceSearch ? '98%' : '',
                    margin: isAdvanceSearch ? 'auto' : '',
                    marginTop: isAdvanceSearch ? '15px' : '',
                }}
            >
                <div
                    style={{
                        background: '#00193C',
                        color: 'white',
                        padding: '10px',
                        borderRadius: '4px 4px 0px 0px',
                        marginTop: '10px',
                    }}
                    className="d-flex align-items-center justify-content-between"
                >
                    <div style={{ width: newsData.data[0].score ? '52%' : '57%' }}>
                        {!isMyRequestPage && !isMyRequest && !isCreationMyRequest && !isMyRequestPageRunAll && (
                            <span
                                style={{ marginLeft: '1%', cursor: 'pointer' }}
                                onClick={() => {
                                    !isAssistant && handleAllSelected()

                                    setAllSelected(!isAllSelected)
                                }}
                            >
                                {!isAllSelected ? (
                                    <CheckBoxOutlineBlankIcon style={{ fontSize: 'larger' }} />
                                ) : (
                                    <CheckBoxIcon style={{ fontSize: 'larger' }} />
                                )}
                            </span>
                        )}
                        <span
                            style={{
                                marginLeft:
                                    isQuickSearch && newsData.data[0].score
                                        ? '3%'
                                        : isQuickSearch && !newsData.data[0].score
                                        ? '6%'
                                        : isMyRequestPage && newsData.data[0].score
                                        ? '6%'
                                        : isMyRequestPage && !newsData.data[0].score
                                        ? '11%'
                                        : isMyRequestPageRunAll
                                        ? '9%'
                                        : '6%',
                            }}
                            className={isAssistant ? 'font-h ml-30' : ''}
                        >
                            Title
                        </span>
                    </div>
                    {(isQuickSearch || isMyRequestPage || isAssistant) &&
                        !isMyRequestPageRunAll &&
                        newsData.data[0].score && (
                            <div className="d-none d-sm-block font-h" style={{ width: '10%' }}>
                                Relevance
                            </div>
                        )}
                    <div
                        className="d-none d-sm-block font-h"
                        style={{
                            width: '17%',
                            marginLeft: newsData.data[0].score ? '2%' : '',
                        }}
                    >
                        Source
                    </div>
                    <div className="d-none d-sm-block font-h" style={{ width: '12%', marginRight: '1%' }}>
                        Date
                    </div>
                    <div />
                </div>
            </div>
        </>
    )
}
