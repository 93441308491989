import React from 'react'
import Select from 'react-select'
import './AutoCompleteFilter.css'

export default function AutoCompleteFilter(props) {
    const { name, options, onChange, value, placeholder, classOverride } = props

    return (
        <Select
            isMulti
            name={name}
            options={options}
            className={classOverride ? classOverride : 'companiesAutoCompleteBox'}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
        />
    )
}
