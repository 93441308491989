import React from 'react'
import { getFullFormattedDate } from '../../../utils/dateHelpers'
import { Grid, Card } from '@mui/material'
import './MailModal.css'
import twitterX from '../../../assets/icons/twitterx-black.svg'
import TemplateFooter from './TemplateFooter'
import NewsBody from './NewsBody'

export default function WinstonPreview(props) {
    const {
        introText,
        selectedStories,
        primaryColor,
        secondaryColor,
        selectedLogo,
        setSize,
        newsBodySize,
        includeSection,
        includeBusiness,
        hyperlinkSource,
        hyperlinkTitle,
    } = props
    const logoRef = React.useRef()
    const getSize = () => {
        setSize(logoRef.current.offsetWidth, logoRef.current.offsetHeight, 'primary')
    }
    return (
        <div>
            <div className="mailModal__emailContainer">
                <table
                    cellSpacing="0"
                    cellPadding="0"
                    className="mailModal__headerGraphicAndClickBelow"
                    // style={{ width: '670px' }}
                >
                    <tbody>
                        <tr style={{ maxHeight: '150px' }}>
                            <td
                                colSpan="3"
                                className="mailModal__headerGraphic"
                                align="center"
                                style={{ width: '33%', maxHeight: '150px' }}
                            >
                                <img
                                    ref={ref => (logoRef.current = ref)}
                                    onLoad={e => getSize()}
                                    onChange={e => getSize()}
                                    src={selectedLogo}
                                    style={{ position: 'inherit', maxHeight: '150px', maxWidth: '250px' }}
                                />
                            </td>
                            <td style={{ width: '33%', textAlign: 'center' }}>
                                <hr
                                    align="center"
                                    style={{
                                        height: '2px',
                                        backgroundColor: '#110e5d',
                                        width: '80%',
                                        margin: '0 33px',
                                    }}
                                />
                            </td>
                            <td style={{ width: '33%', textAlign: 'center' }}>
                                <i>{getFullFormattedDate(Date.now())}</i>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table cellSpacing="0" cellPadding="0" className="mailModal__headerGraphicAndClickBelow">
                    <tr align="center" className="mailModalClickBelowTR" style={{ backgroundColor: primaryColor }}>
                        <td>
                            <a href="https://app.kaitongo.com/" style={{ textDecoration: 'none' }}>
                                Click Here to see more news on our app
                            </a>
                        </td>
                    </tr>
                </table>
                <table style={{ width: '100%', border: `solid 1px ${primaryColor}` }}>
                    <tbody>
                        <tr>
                            <td className="mailModal__openingParagraphData">
                                <p>Hi ,</p>
                                <p style={{ whiteSpace: 'pre-line' }}>{`${introText}`}</p>
                                {hyperlinkSource !== '' && hyperlinkTitle !== '' && (
                                    <div>
                                        <a href={hyperlinkSource} target="_blank" style={{ fontSize: '16px' }}>
                                            {hyperlinkTitle}
                                        </a>
                                    </div>
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <NewsBody
                    isblakes={true}
                    selectedStories={selectedStories}
                    primaryColor={primaryColor}
                    secondaryColor={secondaryColor}
                    newsBodySize={newsBodySize}
                    includeSection={includeSection}
                    includeBusiness={includeBusiness}
                />
            </div>
            <TemplateFooter />
        </div>
    )
}
