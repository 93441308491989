import React, { Component, useEffect, useState } from 'react'
import { Form, Input, Label, Button, Spinner } from 'reactstrap'
import axios from 'axios'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { CircularProgress } from '@mui/material'

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        minWidth: '90%',
        background: '#fafafa',
        borderRadius: '5px',
        width: '90%',
        borderBottom: '1px solid lightgray',
        borderRadius: 0,
    }),
    menu: (provided, state) => ({
        ...provided,
        width: '90%',
    }),
}

export default function NewsLetterTemplateForm(props) {
    const { selectedTemplate, setConfirmGo } = props
    const [selectedFirm, setSelectedFirm] = useState(null)

    const [allFirms, setAllFirms] = useState([])
    const [isLoader, setIsLoader] = useState(false)
    const [buttonText, setButtonText] = useState('Edit Template')
    useEffect(() => {
        axios
            .get('/analyst/firms/')
            .then(response => {
                setAllFirms(
                    response.data.firms.map(val => {
                        return {
                            value: val.value,
                            label: val.label,
                        }
                    })
                )
            })
            .catch(err => console.log(err))
    }, [])

    useEffect(() => {
        if (allFirms.length > 0 && selectedTemplate.firm) {
            const selectedFirmData = allFirms.find(val => val.value === selectedTemplate.firm)
            setSelectedFirm(selectedFirmData)
        }
    }, [allFirms, selectedTemplate.firm])

    const logoButtonRef = React.useRef()
    const secondarylogoButtonRef = React.useRef()
    const bgButtonRef = React.useRef()
    const thumbRef = React.useRef()

    const [defaultData, setDefaultData] = useState({
        templateName: selectedTemplate.name,
        templateDesc: selectedTemplate.description,
        templateTitle: selectedTemplate.title,
        templateIntrotext: selectedTemplate.introtext,
        templateSubject: selectedTemplate.subject,
        Logo: selectedTemplate.Logo,
        backgroundImage: selectedTemplate.background_image,
        secondaryLogo: selectedTemplate.secondary_logo,
        thumbnail: selectedTemplate.thumbnail,
        primaryColor: selectedTemplate.primary_color,
        secondaryColor: selectedTemplate.secondary_color,
        bgName: selectedTemplate.bgname,
        logoName: selectedTemplate.logoname,
        seclogoName: selectedTemplate.seclogoname,
        thumbName: selectedTemplate.thumbnail_name,
    })

    const saveTemplate = e => {
        e.preventDefault()
        setIsLoader(true)
        let formData = new FormData()
        formData.append('id', selectedTemplate.id)
        formData.append('name', e.target.templateName.value)
        defaultData.thumbName !== selectedTemplate.thumbnail_name &&
            formData.append('thumbnail', e.target.thumbnail.files[0])
        defaultData.thumbName !== selectedTemplate.thumbnail_name &&
            formData.append('thumbnail_name', e.target.thumbnail.files[0].name)
        formData.append('description', e.target.templateDesc.value)
        formData.append('primary_color', e.target.primaryColor.value)
        formData.append('secondary_color', e.target.secondaryColor.value)
        formData.append('subject', e.target.templateSubject.value)
        selectedTemplate.has_introtext && formData.append('introtext', e.target.templateIntrotext.value)
        selectedTemplate.has_title && formData.append('title', e.target.templateTitle.value)
        selectedTemplate.has_multilogo &&
            defaultData.seclogoName !== selectedTemplate.seclogoname &&
            formData.append('secondary_logo', e.target.secondaryLogo.files[0])
        selectedTemplate.has_multilogo &&
            defaultData.seclogoName !== selectedTemplate.seclogoname &&
            formData.append('seclogoname', e.target.secondaryLogo.files[0].name)
        defaultData.logoName !== selectedTemplate.logoname && formData.append('logo', e.target.Logo.files[0])
        defaultData.logoName !== selectedTemplate.logoname && formData.append('logoname', e.target.Logo.files[0].name)
        selectedTemplate.has_background &&
            selectedTemplate.bgname !== defaultData.bgName &&
            formData.append('background_image', e.target.backgroundImage.files[0])
        selectedTemplate.has_background &&
            selectedTemplate.bgname !== defaultData.bgName &&
            formData.append('bgname', e.target.backgroundImage.files[0].name)
        formData.append('firm', selectedFirm.value)
        formData.append('default_sections', selectedTemplate.default_sections)
        formData.append('custom_template', selectedTemplate.custom_template)
        formData.append('has_background', selectedTemplate.has_background)
        formData.append('has_banner', selectedTemplate.has_banner)
        formData.append('has_hyperlink', selectedTemplate.has_hyperlink)
        formData.append('has_introtext', selectedTemplate.has_introtext)
        formData.append('has_multilogo', selectedTemplate.has_multilogo)
        formData.append('has_sections', selectedTemplate.has_sections)
        formData.append('has_title', selectedTemplate.has_title)
        formData.append('html_name', selectedTemplate.html_name)
        formData.append('is_active', selectedTemplate.is_active)

        console.log(formData)
        axios
            .post('/endusers/templates/', formData)
            .then(res => {
                console.log(res)
                setTimeout(() => {
                    toast('Template Updated')
                    setIsLoader(false)
                    setButtonText('Saved')
                    setConfirmGo(true)
                }, 3000)
            })
            .catch(err => {
                console.log(err)
                setIsLoader(false)
                toast.warn('Failed')
            })
    }

    const handleInputChange = e => {
        const { name, value } = e.target
        console.log(e.target)
        console.log(e)
        setDefaultData({ ...defaultData, [name]: value })
    }
    const logoNameChange = e => {
        console.log(e.target.name)
        const { name, value } = e.target
        if (e.target.files[0]) {
            name === 'Logo' && setDefaultData({ ...defaultData, logoName: value })
            name === 'backgroundImage' && setDefaultData({ ...defaultData, bgName: value })
            name === 'secondaryLogo' && setDefaultData({ ...defaultData, seclogoname: value })
            name === 'thumbnail' && setDefaultData({ ...defaultData, thumbName: value })
        }
    }
    return (
        <div>
            <h2 style={{ paddingBottom: '15px', fontSize: '26px', fontWeight: 600 }}>Template Form</h2>
            <Form onSubmit={e => saveTemplate(e)}>
                <div className="d-flex justify-content-between">
                    <div style={{ width: '33%' }}>
                        <Label>Template Name</Label>
                        <Input
                            type="text"
                            value={defaultData.templateName}
                            onChange={handleInputChange}
                            name="templateName"
                            required
                            style={{ width: '90%' }}
                        />
                    </div>
                    <div style={{ width: '33%' }}>
                        <Label>Template Subject</Label>
                        <Input
                            type="text"
                            name="templateSubject"
                            value={defaultData.templateSubject}
                            onChange={handleInputChange}
                            required
                            style={{ width: '90%' }}
                        />
                    </div>
                    {selectedTemplate.has_title ? (
                        <div style={{ width: '33%' }}>
                            <Label>Template Title</Label>
                            <Input
                                type="text"
                                name="templateTitle"
                                value={defaultData.templateTitle}
                                onChange={handleInputChange}
                                required
                                style={{ width: '90%' }}
                            />
                        </div>
                    ) : (
                        <div style={{ width: '33%' }} />
                    )}
                </div>
                <div className="d-flex justify-content-between mt-3">
                    <div style={{ width: '33%' }}>
                        <Label>Template Description</Label>
                        <Input
                            type="textarea"
                            name="templateDesc"
                            value={defaultData.templateDesc}
                            onChange={handleInputChange}
                            required
                            style={{ width: '90%' }}
                        />
                    </div>
                    {selectedTemplate.has_introtext && (
                        <div style={{ width: '33%' }}>
                            <Label>Template Introtext</Label>
                            <Input
                                type="textarea"
                                name="templateIntrotext"
                                value={defaultData.templateIntrotext}
                                onChange={handleInputChange}
                                required
                                style={{ width: '90%' }}
                            />
                        </div>
                    )}
                    <div style={{ width: '33%' }} />
                </div>

                <div className="d-flex justify-content-between mt-3">
                    <div style={{ width: '33%' }}>
                        <Label>Firm</Label>
                        <Select
                            options={allFirms}
                            value={selectedFirm}
                            onChange={e => {
                                setSelectedFirm(e)
                            }}
                            styles={customStyles}
                        />
                    </div>
                    <div style={{ width: '33%' }}>
                        <Label>Primary Color</Label>
                        <Input
                            type="color"
                            name="primaryColor"
                            value={defaultData.primaryColor}
                            onChange={handleInputChange}
                            title="primary color"
                            style={{ width: '90%' }}
                        />
                    </div>
                    <div style={{ width: '33%' }}>
                        <Label>Secondary Color</Label>
                        <Input
                            type="color"
                            name="secondaryColor"
                            value={defaultData.secondaryColor}
                            onChange={handleInputChange}
                            title="secondary color"
                            style={{ width: '90%' }}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-between mt-3">
                    <div style={{ width: '33%' }}>
                        <Label>Thumbnail</Label>
                        <br />
                        {/* <Input type="file" accept="image/*" name="thumbnail" required /> */}
                        <input
                            type="file"
                            accept="image/*"
                            name="thumbnail"
                            onChange={e => logoNameChange(e)}
                            ref={ref => (thumbRef.current = ref)}
                            style={{ display: 'none' }}
                            // required
                        />
                        <div className="d-flex">
                            <Button
                                style={{
                                    border: '1px solid lightgray',
                                    color: 'black',
                                    background: '#eeeeee',
                                    width: '30%',
                                    fontSize: '12px',
                                }}
                                onClick={e => thumbRef.current.click()}
                            >
                                Choose File
                            </Button>
                            {/* <span style={{borderBottom:'1px solid gray',marginLeft:'10px',}}>{defaultData.thumbName}</span> */}
                            <Input
                                value={defaultData.thumbName}
                                type="text"
                                style={{ width: '60%', background: '#fafafa' }}
                                readOnly
                            />
                        </div>
                    </div>
                    <div style={{ width: '33%' }}>
                        <Label>Logo</Label>
                        <br />
                        {/* <Input type="file" accept="image/*" name="Logo" required /> */}
                        <input
                            type="file"
                            accept="image/*"
                            onChange={e => logoNameChange(e)}
                            name="Logo"
                            style={{ display: 'none' }}
                            ref={ref => (logoButtonRef.current = ref)}
                        />

                        <div className="d-flex">
                            <Button
                                style={{
                                    border: '1px solid lightgray',
                                    color: 'black',
                                    background: '#eeeeee',
                                    width: '30%',
                                    fontSize: '12px',
                                }}
                                onClick={e => logoButtonRef.current.click()}
                            >
                                Choose File
                            </Button>
                            <Input
                                value={defaultData.logoName}
                                type="text"
                                style={{ width: '60%', background: '#fafafa' }}
                                readOnly
                            />
                        </div>
                    </div>
                    <div style={{ width: '33%' }} />
                </div>

                <div className="d-flex justify-content-between mt-3">
                    {selectedTemplate.has_background && (
                        <div style={{ width: '33%' }}>
                            <Label>Background Image</Label>
                            <br />
                            {/* <Input type="file" accept="image/*" name="backgroundImage" required /> */}
                            <input
                                type="file"
                                onChange={e => logoNameChange(e)}
                                accept="image/*"
                                name="backgroundImage"
                                ref={ref => (bgButtonRef.current = ref)}
                                style={{ display: 'none' }}
                                // required
                            />
                            <div className="d-flex">
                                <Button
                                    style={{
                                        border: '1px solid lightgray',
                                        color: 'black',
                                        background: '#eeeeee',
                                        width: '30%',
                                        fontSize: '12px',
                                    }}
                                    onClick={e => bgButtonRef.current.click()}
                                >
                                    Choose File
                                </Button>
                                <Input
                                    value={defaultData.bgName}
                                    type="text"
                                    style={{ width: '60%', background: '#fafafa' }}
                                    readOnly
                                />
                            </div>
                        </div>
                    )}

                    {selectedTemplate.has_multilogo && (
                        <div style={{ width: '33%' }}>
                            <Label>Secondary Logo</Label>
                            <br />
                            {/* <Input type="file" accept="image/*" name="secondaryLogo" required /> */}
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                ref={ref => (secondarylogoButtonRef.current = ref)}
                                accept="image/*"
                                onChange={e => logoNameChange(e)}
                                name="secondaryLogo"
                                // required
                            />
                            <div className="d-flex">
                                <Button
                                    style={{
                                        border: '1px solid lightgray',
                                        color: 'black',
                                        background: '#eeeeee',
                                        width: '30%',
                                        fontSize: '12px',
                                    }}
                                    onClick={e => secondarylogoButtonRef.current.click()}
                                >
                                    Choose File
                                </Button>
                                <Input
                                    value={defaultData.seclogoName}
                                    type="text"
                                    style={{ width: '60%', background: '#fafafa' }}
                                    readOnly
                                />
                            </div>
                        </div>
                    )}
                    <div style={{ width: '33%' }} />
                </div>

                <Button
                    type="submit"
                    className="d-flex mt-3"
                    style={{
                        textAlign: 'center',
                        background: '#197bbd',
                        color: 'white',
                        margin: 'auto',
                        justifyContent: 'center',
                        disabled: isLoader ? true : false,
                    }}
                >
                    {isLoader && (
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{ margin: '1px 5px 0px' }}
                        />
                    )}
                    <span>{buttonText}</span>
                </Button>
            </Form>
        </div>
    )
}
