import React from 'react'
import { Modal } from 'reactstrap'
import { AiFillCloseCircle } from 'react-icons/ai'
import CloseIcon from '@mui/icons-material/Close'

export default function ConversationModal(props) {
    const { openModal, setOpenModal, News, heading } = props

    return (
        <div>
            <Modal
                isOpen={openModal}
                toggle={() => {
                    setOpenModal(false)
                }}
                size="lg"
            >
                <div className="modal_header_black" style={{ backgroundColor: 'white', padding: '20px 20px 5px 20px' }}>
                    <span style={{ color: 'black', fontSize: '17px', fontWeight: 600 }}>{heading}</span>

                    <div>
                        <span
                            onClick={() => {
                                setOpenModal(false)
                            }}
                            style={{ color: 'black', cursor: 'pointer' }}
                        >
                            <CloseIcon />
                        </span>
                    </div>
                </div>

                <div style={{ paddingBottom: '20px' }}>
                    {News.map((item, index) => (
                        <div>
                            <div
                                style={{
                                    width: '95%',
                                    margin: 'auto',
                                    fontSize: '14px',
                                    padding: '15px 0px 0px 0px',
                                }}
                            >
                                <div
                                    style={{
                                        borderLeft: '3px solid rgb(25, 118, 210)',
                                        padding: '5px 0px 5px 10px',
                                        background: index % 2 !== 0 ? 'white' : 'rgb(0 144 255 / 10%)',
                                    }}
                                >
                                    <div
                                        style={{ fontWeight: 'bold', cursor: 'pointer', fontSize: '15px' }}
                                        className="d-flex align-items-center"
                                    >
                                        <span
                                            style={{
                                                overflow: 'hidden',
                                                display: '-webkit-box',
                                                WebkitLineClamp: 1,
                                                WebkitBoxOrient: 'vertical',
                                            }}
                                        >
                                            {item.Name}
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            fontSize: '14px',
                                            color: 'gray',
                                            marginTop: '10px',
                                        }}
                                    >
                                        {item.Summary}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </Modal>
        </div>
    )
}
