import React from 'react'

export default function ImportThankYou(props) {
    return (
        <div className="importContacts__ThanksContainer">
            <h1 className="importContacts__ThanksHeading">
                {props.userNotReady ? 'Account Setup In Progress' : 'Contact Setup in Progress'}
            </h1>
            <p className="importContacts__ThanksMessage">
                {props.userNotReady && (
                    <p className="importContacts__ThanksMessage">
                        Thank you for submitting your contacts. You will receive an email at
                        <strong> {props.user.email}</strong> when your account is ready to be accessed.
                    </p>
                )}

                {!props.userNotReady && (
                    <p className="importContacts__ThanksMessage">
                        Thank you for submitting your contact. You will receive an email at
                        <strong> {props.user.email}</strong> when it's ready to be accessed.
                    </p>
                )}
            </p>
            {!props.hideReturnButton && (
                <div className="addNews__backAndNextButtonsRow">
                    <button
                        className={'addNews__nextButton'}
                        onClick={() => {
                            props.getAllUserContacts()
                            props.setManualModelOpen(false)
                        }}
                        style={{ width: '200px' }}
                    >
                        Return
                    </button>
                </div>
            )}
        </div>
    )
}
