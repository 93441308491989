import axios from 'axios'
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { Button, Input } from 'reactstrap'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { getCookie } from '../../../utils'
import { BiPlus, BiMinus, BiSearch } from 'react-icons/bi'
import './Filter.css'
import { BiCalendar } from 'react-icons/bi'
import NewsEditDropdownFilterDatePicker from '../../../components/NewsEditDropdownFilter/NewsEditDropdownFilterDatePicker'
import { Chip } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
const token = getCookie('csrftoken')
axios.defaults.headers.common['X-CSRFToken'] = token

export default function QuickAdvanceFilters(props) {
    const { regionHierarchy } = props
    const [regionSection, setRegionSection] = useState(false)
    const [companySection, setCompanySection] = useState(false)
    const [practiceSection, setPracticeSection] = useState(false)
    const [topicSection, setTopicSection] = useState(false)
    const [sourceSection, setSourceSection] = useState(false)
    const [regionInput, setRegionInput] = useState('')
    const [companyInput, setCompanyInput] = useState('')
    const [practiceInput, setPracticeInput] = useState('')
    const [topicInput, setTopicInput] = useState('')
    const [sourceInput, setSourceInput] = useState('')
    const { selectedOption, setSelectedOption } = props
    const [subCategoryOpen, setSubCategoryOpen] = useState([])
    const [sectorOpen, setSectorOpen] = useState([])
    const [topicOpen, setTopicOpen] = useState([])
    const [checkedCompanies, setCheckedCompanies] = useState(false)
    const [checkedSources, setCheckedSources] = useState(false)
    const [checkedPractices, setCheckedPractices] = useState(false)
    const [checkedTopics, setCheckedTopics] = useState(false)
    const [checkedRegions, setCheckedRegions] = useState(false)
    const [overallReg, setOverallReg] = useState([])
    const [overallTopic, setOverallTopics] = useState([])

    const {
        selectedSectors,
        setSelectedSectors,
        selectedCompanies,
        setSelectedCompanies,
        selectedCategories,
        setSelectedCategories,
        selectedTopics,
        setSelectedTopics,
        selectedRegions,
        setSelectedRegions,
        selectedSources,
        setSelectedSources,
        allHierarchy,
        practiceSubCategory,
        generateQuery,
        defaultData,
        setDefaultData,
        dateSection,
        setDateSection,
        clearFilters,
        isMyRequest,
        selectedSubCategories,
        setSelectedSubCategories,
        basicSearchInput,
        customDate,
        setcustomDate,
        customOnchange,
    } = props

    const [selectedIndustries, setSelectedIndustries] = useState([])
    const [showBusinessEvents, setShowBusinessEvents] = useState([])
    const [btnAnimation, setBtnAnimation] = useState(false)

    useEffect(() => {
        setDefaultData(props.allCompanies)
        flattenCategories()
        flattenRegions()
        flattenTopics()
    }, [props.allCompanies])

    const flattenCategories = () => {
        const flattened = []

        const flattenHelper = category => {
            flattened.push({ id: category.id, name: category.name })
            if (category.subcategories && category.subcategories.length > 0) {
                category.subcategories.forEach(subcategory => {
                    flattenHelper(subcategory)
                })
            }
        }

        practiceSubCategory.forEach(category => {
            flattenHelper(category)
        })
        setShowBusinessEvents(flattened)

        return flattened
    }

    const flattenRegions = () => {
        const result = regionHierarchy.flatMap(reg => [
            { value: reg.id, name: reg.name, type: reg.type },
            ...reg.countries.flatMap(cty => [
                { value: cty.id, name: cty.name, type: cty.type },
                ...cty.provinces.map(pro => ({
                    value: pro.id,
                    name: pro.name,
                    type: pro.type,
                })),
            ]),
        ])

        setOverallReg(result)
    }

    const flattenTopics = () => {
        const result = allHierarchy.flatMap(ind => [
            { value: ind.id, name: ind.name, type: 'industries' },
            ...ind.sectors.flatMap(sec => [
                { value: sec.id, name: sec.name, type: 'sectors' },
                ...sec.topics.map(top => ({
                    value: top.id,
                    name: top.name,
                    type: 'topics',
                })),
            ]),
        ])

        const uniqueArray = [...new Map(result.map(item => [item.value, item])).values()]

        setOverallTopics(uniqueArray)
    }

    ///sector-company
    const [showType, setShowType] = useState({
        topics: [],
        industries: [],
        sectors: [],
    })
    const [showCompanies, setShowCompanies] = useState([])

    // const wrapperRef = useRef(null)
    // closeExpandable(wrapperRef)

    // function closeExpandable(ref) {
    //     function handleClickOutside(event) {
    //         if (ref.current && !ref.current.contains(event.target)) {
    //             // setRegionSection(false)
    //             // setCompanySection(false)
    //             // setPracticeSection(false)
    //             // setTopicSection(false)
    //             // setSourceSection(false)
    //             // setDateSection(false)
    //         }
    //     }
    //     document.addEventListener('mousedown', handleClickOutside)
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside)
    //     }
    // }

    ///industry->sector->topic
    const allIndustriesName = allHierarchy.map(val => val.name)

    const allSectorsName = allHierarchy
        .map(val => val.sectors.map(sec => sec.name))
        .reduce((acc, curr) => acc.concat(curr), [])

    const allTopicsName = allHierarchy
        .map(val => val.sectors.map(sec => sec.topics.map(top => top.name)))
        .reduce((acc, curr) => acc.concat(curr), [])
        .reduce((acc, curr) => acc.concat(curr), [])

    const addingOfIndustriesSectorsNames = allIndustriesName.concat(allSectorsName)

    const stateMap = {
        Industry: [selectedIndustries, setSelectedIndustries],
        sector: [selectedSectors, setSelectedSectors],
        company: [selectedCompanies, setSelectedCompanies],
        country: [selectedRegions, setSelectedRegions],
        topic: [selectedTopics, setSelectedTopics],
        source: [selectedSources, setSelectedSources],
        category: [selectedCategories, setSelectedCategories],
        showCompanies: [showCompanies, setShowCompanies],
        subCategory: [selectedSubCategories, setSelectedSubCategories],
    }

    const bulkChange = (type, data, name) => {
        const [selectedItems, setSelectedItems] = stateMap[type]

        let result = selectedItems

        const isSelectedItem = selectedItems.find(item => item === name)
        data.forEach(list => {
            if (isSelectedItem) {
                result = result.filter(item => item !== list.name)
            } else {
                result.push(list.name)
            }
        })

        setSelectedItems([...result])
    }

    const bulkChangesub = (type, data, name) => {
        const [selectedItems, setSelectedItems] = stateMap[type]

        let result = selectedItems

        const isSelectedItem = selectedItems.find(item => item === name)
        data.forEach(list => {
            if (isSelectedItem) {
                result = result.filter(item => item !== list.id)
            } else {
                result.push(list.id)
            }
        })

        setSelectedItems([...result])
    }

    const changeCheck = (type, id, typeOf = null, value = null) => {
        const [selectedItems, setSelectedItems] = stateMap[type]
        const isItemSelected = selectedItems.includes(id)
        if (isItemSelected) {
            const filteredItems = selectedItems.filter(item => item !== id)
            setSelectedItems(filteredItems)
        } else {
            setSelectedItems([...selectedItems, id])
        }

        if (typeOf) {
            if (showType[typeOf].includes(value)) {
                const filtered = showType[typeOf].filter(item => item != value)
                setShowType({ ...showType, [typeOf]: filtered })
            } else {
                setShowType({ ...showType, [typeOf]: [...showType[typeOf], value] })
            }
        }
    }

    const toggleid = (Id, type) => {
        type(prevExpandedValues => {
            if (prevExpandedValues.includes(Id)) {
                return prevExpandedValues.filter(id => id !== Id)
            } else {
                return [...prevExpandedValues, Id]
            }
        })
    }

    const handleSelectAllCompany = () => {
        if (checkedCompanies) {
            setSelectedCompanies([])
        } else {
            const allCompanyLabels = defaultData.filter(company => company.label).map(company => company.label)
            setSelectedCompanies(allCompanyLabels)
        }
        setCheckedCompanies(!checkedCompanies)
    }

    const handleSelectAllSources = () => {
        if (checkedSources) {
            setSelectedSources([])
        } else {
            const allSourceLabels = props.allSources
                .filter(source => source.label.toUpperCase().includes(sourceInput.toUpperCase()))
                .map(source => source.value)
            setSelectedSources(allSourceLabels)
        }
        setCheckedSources(!checkedSources)
    }

    const handleSelectAllSubCategories = () => {
        if (checkedPractices) {
            setSelectedSubCategories([])
        } else {
            const allSubCats = props.allSubCategories
                .filter(source => source.label.toUpperCase().includes(practiceInput.toUpperCase()))
                .map(source => source.label)
            setSelectedSubCategories(allSubCats)
        }
        setCheckedPractices(!checkedPractices)
    }

    const handleSelectAllPractice = () => {
        if (checkedPractices) {
            setSelectedCategories([])
        } else {
            const allPracticeLabels = practiceSubCategory
                .filter(category => {
                    const categoryAndSubcategories = [
                        category.name,
                        ...category.subcategories.map(subcategory => subcategory.name),
                    ]
                    return categoryAndSubcategories.some(name =>
                        name.toLowerCase().includes(practiceInput.toLowerCase())
                    )
                })
                .reduce((result, category) => {
                    result.push(category.id)

                    const matchingSubcategories = category.subcategories.filter(subcategory =>
                        subcategory.name.toLowerCase().includes(practiceInput.toLowerCase())
                    )

                    matchingSubcategories.forEach(subcategory => {
                        result.push(subcategory.id)
                    })

                    return result
                }, [])

            setSelectedCategories(allPracticeLabels)
        }
        setCheckedPractices(!checkedPractices)
    }

    const handleSelectAllTopics = () => {
        if (checkedTopics) {
            setSelectedSectors([])
        } else {
            const filteredItems = allHierarchy.filter(ind => {
                const matchingSectors = ind.sectors.filter(sec =>
                    sec.topics.some(topic => topic.name.toLowerCase().includes(topicInput.toLowerCase()))
                )

                return (
                    ind.name.toLowerCase().includes(topicInput.toLowerCase()) ||
                    matchingSectors.length > 0 ||
                    ind.sectors.some(sec => sec.name.toLowerCase().includes(topicInput.toLowerCase()))
                )
            })

            const filteredTopicLabels = filteredItems.reduce((result, val) => {
                result.push(val.name)

                val.sectors.forEach(sector => {
                    result.push(sector.name)

                    sector.topics.forEach(topic => {
                        if (topic.name.toLowerCase().includes(topicInput.toLowerCase())) {
                            result.push(topic.name)
                        }
                    })
                })

                return result
            }, [])
            setSelectedSectors(filteredTopicLabels)
            // const allTopicLabels = overallTopic
            //     .filter(top => top.name.toUpperCase().includes(topicInput.toUpperCase()))
            //     .map(top => top.name)
            // setSelectedSectors(allTopicLabels)
        }
        setCheckedTopics(!checkedTopics)
    }

    const handleSelectAllRegions = () => {
        if (checkedRegions) {
            setSelectedRegions([])
        } else {
            const filteredItems = regionHierarchy.filter(reg => {
                const matchingcountries = reg.countries.filter(cty =>
                    cty.provinces.some(pro => pro.name.toLowerCase().includes(regionInput.toLowerCase()))
                )

                return (
                    reg.name.toLowerCase().includes(regionInput.toLowerCase()) ||
                    matchingcountries.length > 0 ||
                    reg.countries.some(cty => cty.name.toLowerCase().includes(regionInput.toLowerCase()))
                )
            })

            const filteredProvincesLabels = filteredItems.reduce((result, val) => {
                result.push(val.name)

                val.countries.forEach(country => {
                    result.push(country.name)

                    country.provinces.forEach(pro => {
                        if (pro.name.toLowerCase().includes(regionInput.toLowerCase())) {
                            result.push(pro.name)
                        }
                    })
                })

                return result
            }, [])

            setSelectedRegions(filteredProvincesLabels)

            // const allRegLabels = overallReg
            //     .filter(reg => reg.name.toUpperCase().includes(regionInput.toUpperCase()))
            //     .map(reg => reg.name)
            // setSelectedRegions(allRegLabels)
        }
        setCheckedRegions(!checkedRegions)
    }

    const companySearch = e => {
        setCompanyInput(e.target.value)
        console.log(selectedCompanies)

        if (e.target.value.length > 2) {
            axios
                .get(`/news/auto-complete/?type=company&string=${encodeURIComponent(e.target.value)}`)
                .then(response => {
                    const filteredData = response.data.map(item => ({
                        value: item.id,
                        label: item.name,
                    }))
                    const uniqueData = new Set([...filteredData])
                    const arrayFromSet = [...uniqueData]
                    setDefaultData(arrayFromSet)
                })
                .catch(err => console.log(err))
        } else {
            setDefaultData(props.allCompanies)
        }
    }
    const selectedOptionsChip = (value, setValue) => {
        return (
            <div style={{ margin: '2px' }}>
                {value.map(val => (
                    <Chip
                        size="small"
                        key={val}
                        label={val}
                        onDelete={() => setValue(value.filter(curr => curr !== val))}
                    />
                ))}
            </div>
        )
    }

    return (
        <>
            <div
                className="new_quick_advance"
                style={{
                    height: 'calc(100vh - 200px)',
                    overflowY: 'auto',
                    margin: 'auto',
                }}
            >
                <div className="overall_drop" style={{ width: '80%', margin: 'auto' }}>
                    <div className={isMyRequest ? 'row' : ''}>
                        <div
                            id="date_drop"
                            style={{ marginTop: isMyRequest ? '20px' : '' }}
                            className={isMyRequest ? 'col-4' : ''}
                        >
                            <div className="">
                                <div
                                    className="d-flex align-items-center justify-content-between mt-3"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setDateSection(!dateSection)
                                    }}
                                >
                                    <label> Date</label>
                                    <span>{!dateSection ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</span>
                                </div>
                            </div>
                            <div style={{ width: '99%', position: 'relative', margin: 'auto' }}>
                                {dateSection && (
                                    <div
                                        // ref={wrapperRef}
                                        className="date_section"
                                        style={{
                                            width: '100%',
                                            // marginTop: '5px',
                                            // background: 'white',
                                            // position: 'absolute',
                                            padding: '10px 0px',
                                            height: 'auto',
                                            cursor: 'pointer',
                                            borderRadius: '4px',
                                            // boxShadow: '0px 0px 2px 2px lightgray',
                                            zIndex: 1,
                                        }}
                                    >
                                        <div className="radio">
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="1"
                                                    checked={selectedOption === '1'}
                                                    onChange={e => {
                                                        setSelectedOption(e.target.value)
                                                        if (basicSearchInput.length > 0) generateQuery(1)
                                                    }}
                                                />
                                                <span style={{ marginLeft: '10px' }}>Today</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="2"
                                                    checked={selectedOption === '2'}
                                                    onChange={e => {
                                                        setSelectedOption(e.target.value)
                                                        if (basicSearchInput.length > 0) generateQuery(2)
                                                    }}
                                                />
                                                <span style={{ marginLeft: '10px' }}>Last 2 days</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="6"
                                                    checked={selectedOption === '6'}
                                                    onChange={e => {
                                                        setSelectedOption(e.target.value)
                                                        if (basicSearchInput.length > 0) generateQuery(6)
                                                    }}
                                                />
                                                <span style={{ marginLeft: '10px' }}>Last 7 days</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="29"
                                                    defaultChecked
                                                    checked={selectedOption === '29'}
                                                    onChange={e => {
                                                        setSelectedOption(e.target.value)
                                                        if (basicSearchInput.length > 0) generateQuery(29)
                                                    }}
                                                />
                                                <span style={{ marginLeft: '10px' }}>Last 30 days</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="59"
                                                    checked={selectedOption === '59'}
                                                    onChange={e => {
                                                        setSelectedOption(e.target.value)
                                                        if (basicSearchInput.length > 0) generateQuery(59)
                                                    }}
                                                />
                                                <span style={{ marginLeft: '10px' }}>Last 60 days</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="89"
                                                    checked={selectedOption === '89'}
                                                    onChange={e => {
                                                        setSelectedOption(e.target.value)
                                                        if (basicSearchInput.length > 0) generateQuery(89)
                                                    }}
                                                />
                                                <span style={{ marginLeft: '10px' }}>Last 90 days</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="179"
                                                    checked={selectedOption === '179'}
                                                    onChange={e => {
                                                        setSelectedOption(e.target.value)
                                                        if (basicSearchInput.length > 0) generateQuery(179)
                                                    }}
                                                />
                                                <span style={{ marginLeft: '10px' }}>Last 180 days</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="364"
                                                    checked={selectedOption === '364'}
                                                    onChange={e => {
                                                        setSelectedOption(e.target.value)
                                                        if (basicSearchInput.length > 0) generateQuery(364)
                                                    }}
                                                />
                                                <span style={{ marginLeft: '10px' }}>Last 1 year</span>
                                            </label>
                                        </div>
                                        {!props.isMyCompanyPage && (
                                            <div style={{ marginTop: '5px' }}>
                                                <label className="d-flex align-items-center">
                                                    <input
                                                        type="radio"
                                                        value="custom"
                                                        checked={selectedOption === 'custom'}
                                                        // onChange={this.onValueChange}
                                                        onChange={e => {
                                                            setSelectedOption(e.target.value)
                                                        }}
                                                    />
                                                    <span
                                                        style={{ marginLeft: '10px' }}
                                                        className="d-flex align-items-center"
                                                    >
                                                        <span>Custom</span>{' '}
                                                        <span style={{ marginLeft: '5px' }}> Date</span>
                                                        <BiCalendar style={{ fontSize: '18px', marginLeft: '5px' }} />
                                                        {selectedOption === 'custom' &&
                                                            customDate.startDate !== null &&
                                                            customDate.endDate !== null && (
                                                                <span>
                                                                    <Button
                                                                        className="btn btn-primary"
                                                                        onClick={() => {
                                                                            if (basicSearchInput.length > 0)
                                                                                generateQuery('custom')
                                                                        }}
                                                                        style={{
                                                                            textDecoration: 'none',
                                                                            textTransform: 'none',
                                                                            color: 'white',
                                                                            background: 'rgb(24, 106, 222)',
                                                                            fontSize: '12px',
                                                                            cursor: 'pointer',
                                                                            marginLeft: '10px',
                                                                        }}
                                                                    >
                                                                        Ok
                                                                    </Button>
                                                                </span>
                                                            )}
                                                    </span>
                                                </label>
                                                {selectedOption === 'custom' && (
                                                    <NewsEditDropdownFilterDatePicker
                                                        isFindAndShare={true}
                                                        setDateRange={setcustomDate}
                                                        selectedDateRange={customDate}
                                                        customOnchange={customOnchange}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div id="source_drop" style={{ marginTop: '20px' }} className={isMyRequest ? 'col-4' : ''}>
                            <div className="">
                                <div
                                    className="d-flex align-items-center justify-content-between mt-3"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setSourceSection(!sourceSection)
                                    }}
                                >
                                    <label>
                                        {' '}
                                        Source Name {selectedSources.length !== 0 && '(' + selectedSources.length + ')'}
                                    </label>
                                    <span>{!sourceSection ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</span>
                                </div>
                            </div>
                            <div style={{ width: '99%', position: 'relative', margin: 'auto' }}>
                                {sourceSection && (
                                    <div
                                        // ref={wrapperRef}
                                        className="source_section"
                                        style={{
                                            width: '100%',
                                            // marginTop: '5px',
                                            // background: 'white',
                                            // position: 'absolute',
                                            padding: '10px 0px',
                                            height: sourceInput.length > 2 ? 'max-content' : '260px',
                                            zIndex: 1,
                                            borderRadius: '4px',
                                            // boxShadow: '0px 0px 2px 2px lightgray',
                                        }}
                                    >
                                        <Input
                                            style={{ height: '27px' }}
                                            type="search"
                                            value={sourceInput}
                                            onChange={e => {
                                                setSourceInput(e.target.value)
                                            }}
                                        />
                                        <div
                                            style={{
                                                height: sourceInput.length > 2 ? 'max-content' : '210px',
                                                overflow: 'auto',
                                            }}
                                        >
                                            <div className="d-flex" style={{ marginTop: '5px' }}>
                                                <input
                                                    type="checkbox"
                                                    id={'selectAll' + 'source'}
                                                    checked={checkedSources}
                                                    onChange={() => {
                                                        handleSelectAllSources()
                                                    }}
                                                />
                                                <label
                                                    htmlFor={'selectAll' + 'source'}
                                                    style={{
                                                        marginLeft: '10px',
                                                        cursor: 'pointer',
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    Select All
                                                </label>
                                            </div>
                                            {props.allSources
                                                .filter(val =>
                                                    val.label.toUpperCase().includes(sourceInput.toUpperCase())
                                                )
                                                .sort((a, b) => {
                                                    // First place the checked sources, then the rest in alphabetical order
                                                    // const isASelected = selectedSources.includes(a.value)
                                                    // const isBSelected = selectedSources.includes(b.value)
                                                    // if (isASelected && !isBSelected) return -1
                                                    // if (!isASelected && isBSelected) return 1
                                                    return a.label.localeCompare(b.label)
                                                })
                                                .map(val => {
                                                    return (
                                                        <div
                                                            className="d-flex"
                                                            style={{ marginTop: '5px' }}
                                                            title={val.value}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                value={val.value}
                                                                id={'source' + val.value}
                                                                checked={selectedSources.includes(val.value)}
                                                                onChange={() => changeCheck('source', val.value)}
                                                            />
                                                            <label
                                                                htmlFor={'source' + val.value}
                                                                style={{
                                                                    marginLeft: '10px',
                                                                    cursor: 'pointer',
                                                                    display: '-webkit-box',
                                                                    WebkitLineClamp: sourceInput.length === 0 ? 1 : 2,
                                                                    fontSize: '13px',
                                                                    WebkitBoxOrient: 'vertical',
                                                                    overflow: 'hidden',
                                                                }}
                                                            >
                                                                {val.label}
                                                            </label>
                                                        </div>
                                                    )
                                                })}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div id="region_drop" style={{ marginTop: '20px' }} className={isMyRequest ? 'col-4' : ''}>
                            <div className=" region">
                                <div
                                    className="d-flex align-items-center justify-content-between mt-3"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setRegionSection(!regionSection)
                                    }}
                                >
                                    <label>
                                        {' '}
                                        Region/Country{' '}
                                        {selectedRegions.length !== 0 && '(' + selectedRegions.length + ')'}
                                    </label>
                                    <span>{!regionSection ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</span>
                                </div>
                            </div>
                            <div style={{ width: '99%', position: 'relative', margin: 'auto' }}>
                                {regionSection && (
                                    <div
                                        // ref={wrapperRef}
                                        className="region_section"
                                        style={{
                                            width: '100%',
                                            // marginTop: '5px',
                                            // background: 'white',
                                            // position: 'absolute',
                                            padding: '10px 0px',
                                            height: regionInput.length > 2 ? 'max-content' : '260px',
                                            zIndex: 1,
                                            borderRadius: '4px',
                                            // boxShadow: '0px 0px 2px 2px lightgray',
                                        }}
                                    >
                                        <Input
                                            style={{ height: '27px' }}
                                            type="search"
                                            value={regionInput}
                                            onChange={e => {
                                                setRegionInput(e.target.value)
                                            }}
                                        />
                                        <div
                                            style={{
                                                height: regionInput.length > 2 ? 'max-content' : '210px',
                                                overflow: 'auto',
                                            }}
                                        >
                                            <div className="d-flex" style={{ marginTop: '5px' }}>
                                                <input
                                                    type="checkbox"
                                                    id={'selectAll' + 'region'}
                                                    checked={checkedRegions}
                                                    onChange={() => {
                                                        handleSelectAllRegions()
                                                    }}
                                                />
                                                <label
                                                    htmlFor={'selectAll' + 'region'}
                                                    style={{
                                                        marginLeft: '10px',
                                                        cursor: 'pointer',
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    Select All
                                                </label>
                                            </div>
                                            {/* {regionHierarchy
                                                .filter(region => {
                                                    const regionNameMatches = region.name
                                                        .toLowerCase()
                                                        .includes(regionInput.toLowerCase())

                                                    const countriesMatch = region.countries.some(country =>
                                                        country.name.toLowerCase().includes(regionInput.toLowerCase())
                                                    )

                                                    return regionNameMatches || countriesMatch
                                                })
                                                .map(reg => {
                                                    return (
                                                        <>
                                                            <div
                                                                className="d-flex"
                                                                style={{ marginTop: '5px' }}
                                                                key={reg.name}
                                                            >
                                                                <>
                                                                    <input
                                                                        type="checkbox"
                                                                        value={reg.name}
                                                                        id={'country' + reg.name}
                                                                        checked={selectedRegions.includes(reg.name)}
                                                                        onChange={() => {
                                                                            bulkChange('country', [reg], reg.name)
                                                                        }}
                                                                    />

                                                                    <label
                                                                        htmlFor={'country' + reg.name}
                                                                        style={{
                                                                            marginLeft: '10px',
                                                                            cursor: 'pointer',
                                                                            display: '-webkit-box',
                                                                            WebkitLineClamp: 1,
                                                                            fontSize: '13px',
                                                                            WebkitBoxOrient: 'vertical',
                                                                            overflow: 'hidden',
                                                                        }}
                                                                    >
                                                                        {reg.name}
                                                                    </label>
                                                                </>
                                                            </div>
                                                            {reg.countries &&
                                                                reg.countries.map(cty => (
                                                                    <>
                                                                        <div
                                                                            className="d-flex"
                                                                            style={{
                                                                                marginTop: '5px',
                                                                                marginLeft: '15px',
                                                                            }}
                                                                            key={cty.name}
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                value={cty.name}
                                                                                id={'country' + cty.name}
                                                                                checked={selectedRegions.includes(
                                                                                    cty.name
                                                                                )}
                                                                                onChange={() => {
                                                                                    bulkChange(
                                                                                        'country',
                                                                                        [cty],
                                                                                        cty.name
                                                                                    )
                                                                                }}
                                                                            />

                                                                            <label
                                                                                htmlFor={'country' + cty.name}
                                                                                style={{
                                                                                    marginLeft: '10px',
                                                                                    cursor: 'pointer',
                                                                                    display: '-webkit-box',
                                                                                    WebkitLineClamp: 1,
                                                                                    fontSize: '13px',
                                                                                    WebkitBoxOrient: 'vertical',
                                                                                    overflow: 'hidden',
                                                                                }}
                                                                            >
                                                                                {cty.name}
                                                                            </label>
                                                                        </div>
                                                                        {cty.provinces &&
                                                                            cty.provinces.map(pro => (
                                                                                <>
                                                                                    <div
                                                                                        className="d-flex"
                                                                                        style={{
                                                                                            marginTop: '5px',
                                                                                            marginLeft: '30px',
                                                                                        }}
                                                                                        key={pro.name}
                                                                                    >
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            value={pro.name}
                                                                                            id={'provinces' + pro.name}
                                                                                            checked={selectedRegions.includes(
                                                                                                pro.name
                                                                                            )}
                                                                                            onChange={() => {
                                                                                                bulkChange(
                                                                                                    'country',
                                                                                                    [pro],
                                                                                                    pro.name
                                                                                                )
                                                                                            }}
                                                                                        />

                                                                                        <label
                                                                                            htmlFor={
                                                                                                'provinces' + pro.name
                                                                                            }
                                                                                            style={{
                                                                                                marginLeft: '10px',
                                                                                                cursor: 'pointer',
                                                                                                display: '-webkit-box',
                                                                                                WebkitLineClamp: 1,
                                                                                                fontSize: '13px',
                                                                                                WebkitBoxOrient:
                                                                                                    'vertical',
                                                                                                overflow: 'hidden',
                                                                                            }}
                                                                                        >
                                                                                            {pro.name}
                                                                                        </label>
                                                                                    </div>
                                                                                </>
                                                                            ))}
                                                                    </>
                                                                ))}
                                                        </>
                                                    )
                                                })} */}

                                            {regionHierarchy
                                                .reduce((result, reg) => {
                                                    const filteredCountries = reg.countries.filter(cou =>
                                                        cou.provinces.some(pro =>
                                                            pro.name.toLowerCase().startsWith(regionInput.toLowerCase())
                                                        )
                                                    )

                                                    if (
                                                        filteredCountries.length > 0 ||
                                                        reg.name.toLowerCase().startsWith(regionInput.toLowerCase()) ||
                                                        reg.countries.find(cou =>
                                                            cou.name.toLowerCase().startsWith(regionInput.toLowerCase())
                                                        )
                                                    ) {
                                                        if (regionInput.length > 2) {
                                                            sectorOpen.push(reg.id)
                                                            filteredCountries.forEach(cou => {
                                                                topicOpen.push(cou.id)
                                                            })
                                                        }
                                                        if (regionInput.length === 1) {
                                                            topicOpen.splice(0, topicOpen.length)
                                                            sectorOpen.splice(0, sectorOpen.length)
                                                        }
                                                        result.push({
                                                            ...reg,
                                                            countries: reg.countries
                                                                .filter(
                                                                    country =>
                                                                        country.name
                                                                            .toLowerCase()
                                                                            .startsWith(regionInput.toLowerCase()) ||
                                                                        country.provinces.some(topic =>
                                                                            topic.name
                                                                                .toLowerCase()
                                                                                .startsWith(regionInput.toLowerCase())
                                                                        )
                                                                )
                                                                .map(filteredCountry => ({
                                                                    ...filteredCountry,
                                                                    provinces: filteredCountry.provinces.filter(topic =>
                                                                        topic.name
                                                                            .toLowerCase()
                                                                            .startsWith(regionInput.toLowerCase())
                                                                    ),
                                                                })),
                                                        })
                                                    }
                                                    return result
                                                }, [])
                                                .map(val => {
                                                    return (
                                                        <>
                                                            <div
                                                                className="d-flex align-items-center"
                                                                style={{ marginTop: '5px' }}
                                                                key={val.name}
                                                                title={val.name}
                                                            >
                                                                <>
                                                                    <input
                                                                        type="checkbox"
                                                                        value={val.name}
                                                                        id={'country' + val.name}
                                                                        checked={selectedRegions.includes(val.name)}
                                                                        onChange={() => {
                                                                            bulkChange(
                                                                                'country',
                                                                                [val]
                                                                                    .concat(...val.countries)
                                                                                    .concat(
                                                                                        ...val.countries.map(cou =>
                                                                                            cou.provinces.map(
                                                                                                pro => pro
                                                                                            )
                                                                                        )
                                                                                    ),
                                                                                val.name
                                                                            )
                                                                        }}
                                                                    />

                                                                    <label
                                                                        htmlFor={'country' + val.name}
                                                                        style={{
                                                                            marginLeft: '10px',
                                                                            cursor: 'pointer',
                                                                            display: '-webkit-box',
                                                                            WebkitLineClamp: 1,
                                                                            fontSize: '13px',
                                                                            WebkitBoxOrient: 'vertical',
                                                                            overflow: 'hidden',
                                                                        }}
                                                                    >
                                                                        {val.name}
                                                                    </label>
                                                                    <span
                                                                        key={val.id}
                                                                        style={{
                                                                            marginLeft: 'auto',
                                                                            cursor: 'pointer',
                                                                            marginRight: '10px',
                                                                            display:
                                                                                val.countries.length === 0
                                                                                    ? 'none'
                                                                                    : 'block',
                                                                        }}
                                                                    >
                                                                        {val.countries && val.countries.length > 0 && (
                                                                            <span
                                                                                onClick={() => {
                                                                                    toggleid(val.id, setSectorOpen)
                                                                                }}
                                                                            >
                                                                                {sectorOpen.includes(val.id) ? (
                                                                                    <BiMinus className="minus" />
                                                                                ) : (
                                                                                    <BiPlus className="plus" />
                                                                                )}
                                                                            </span>
                                                                        )}
                                                                    </span>
                                                                </>
                                                            </div>
                                                            {sectorOpen.includes(val.id) &&
                                                                val.countries &&
                                                                val.countries.map(cou => (
                                                                    <>
                                                                        <div
                                                                            className="d-flex align-items-center"
                                                                            style={{
                                                                                marginTop: '5px',
                                                                                marginLeft: '15px',
                                                                            }}
                                                                            key={cou.name}
                                                                            title={cou.name}
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                value={cou.name}
                                                                                id={'country' + cou.name}
                                                                                checked={selectedRegions.includes(
                                                                                    cou.name
                                                                                )}
                                                                                onChange={() => {
                                                                                    bulkChange(
                                                                                        'country',
                                                                                        [cou, ...cou.provinces],
                                                                                        cou.name
                                                                                    )
                                                                                }}
                                                                            />

                                                                            <label
                                                                                htmlFor={'country' + cou.name}
                                                                                style={{
                                                                                    marginLeft: '10px',
                                                                                    cursor: 'pointer',
                                                                                    display: '-webkit-box',
                                                                                    WebkitLineClamp: 1,
                                                                                    fontSize: '13px',
                                                                                    WebkitBoxOrient: 'vertical',
                                                                                    overflow: 'hidden',
                                                                                }}
                                                                            >
                                                                                {cou.name}
                                                                            </label>
                                                                            <span
                                                                                key={cou.id}
                                                                                style={{
                                                                                    marginLeft: 'auto',
                                                                                    cursor: 'pointer',
                                                                                    marginRight: '10px',
                                                                                    display:
                                                                                        cou.provinces.length === 0
                                                                                            ? 'none'
                                                                                            : 'block',
                                                                                }}
                                                                            >
                                                                                {cou.provinces &&
                                                                                    cou.provinces.length > 0 && (
                                                                                        <span
                                                                                            onClick={() => {
                                                                                                toggleid(
                                                                                                    cou.id,
                                                                                                    setTopicOpen
                                                                                                )
                                                                                            }}
                                                                                        >
                                                                                            {topicOpen.includes(
                                                                                                cou.id
                                                                                            ) ? (
                                                                                                <BiMinus className="minus" />
                                                                                            ) : (
                                                                                                <BiPlus className="plus" />
                                                                                            )}
                                                                                        </span>
                                                                                    )}
                                                                            </span>
                                                                        </div>
                                                                        {topicOpen.includes(cou.id) &&
                                                                            cou.provinces &&
                                                                            cou.provinces.map(pro => (
                                                                                <div
                                                                                    className="d-flex align-items-center"
                                                                                    style={{
                                                                                        marginTop: '5px',
                                                                                        marginLeft: '30px',
                                                                                    }}
                                                                                    key={pro.name}
                                                                                    title={pro.name}
                                                                                >
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        value={pro.name}
                                                                                        id={'country' + pro.name}
                                                                                        checked={selectedRegions.includes(
                                                                                            pro.name
                                                                                        )}
                                                                                        onChange={() => {
                                                                                            changeCheck(
                                                                                                'country',
                                                                                                pro.name
                                                                                            )
                                                                                        }}
                                                                                    />

                                                                                    <label
                                                                                        htmlFor={'country' + pro.name}
                                                                                        style={{
                                                                                            marginLeft: '10px',
                                                                                            cursor: 'pointer',
                                                                                            display: '-webkit-box',
                                                                                            WebkitLineClamp: 1,
                                                                                            fontSize: '13px',
                                                                                            WebkitBoxOrient: 'vertical',
                                                                                            overflow: 'hidden',
                                                                                        }}
                                                                                    >
                                                                                        {pro.name}
                                                                                    </label>
                                                                                </div>
                                                                            ))}
                                                                    </>
                                                                ))}
                                                        </>
                                                    )
                                                })}

                                            {/* {overallReg
                                                .filter(region =>
                                                    region.name.toLowerCase().startsWith(regionInput.toLowerCase())
                                                )
                                                // .sort((a, b) => {
                                                //     const aSelected = selectedRegions.includes(a.name)
                                                //     const bSelected = selectedRegions.includes(b.name)
                                                //     if (aSelected && !bSelected) return -1
                                                //     if (!aSelected && bSelected) return 1
                                                //     return 0
                                                // })
                                                .map(val => {
                                                    return (
                                                        <div
                                                            className="d-flex"
                                                            style={{
                                                                marginTop: '5px',
                                                                marginLeft:
                                                                    // selectedRegions.includes(val.name) ||
                                                                    val.type === 'Region'
                                                                        ? '0px'
                                                                        : val.type === 'Country'
                                                                        ? '15px'
                                                                        : '30px',
                                                            }}
                                                            title={val.name}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                value={val.value}
                                                                id={'country' + val.value}
                                                                checked={selectedRegions.includes(val.name)}
                                                                onChange={() => changeCheck('country', val.name)}
                                                            />
                                                            <label
                                                                htmlFor={'country' + val.value}
                                                                style={{
                                                                    marginLeft: '10px',
                                                                    cursor: 'pointer',
                                                                    display: '-webkit-box',
                                                                    WebkitLineClamp: 1,
                                                                    fontSize: '13px',
                                                                    WebkitBoxOrient: 'vertical',
                                                                    overflow: 'hidden',
                                                                }}
                                                            >
                                                                {val.name}
                                                            </label>
                                                        </div>
                                                    )
                                                })} */}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {!props.isMyCompanyPage && (
                            <div id="sector_drop" style={{ marginTop: '20px' }} className={isMyRequest ? 'col-4' : ''}>
                                <div className="">
                                    <div
                                        className="d-flex align-items-center justify-content-between mt-3"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setTopicSection(!topicSection)
                                        }}
                                    >
                                        <label>
                                            {' '}
                                            Topic {selectedSectors.length !== 0 && '(' + selectedSectors.length + ')'}
                                        </label>
                                        <span>
                                            {!topicSection ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                                        </span>
                                    </div>
                                </div>
                                <div style={{ width: '99%', position: 'relative', margin: 'auto' }}>
                                    {topicSection && (
                                        <div
                                            // ref={wrapperRef}
                                            className="region_section"
                                            style={{
                                                width: '100%',
                                                // marginTop: '5px',
                                                // background: 'white',
                                                // position: 'absolute',
                                                padding: '10px 0px',
                                                height: topicInput.length > 2 ? 'max-content' : '260px',
                                                zIndex: 1,
                                                borderRadius: '4px',
                                                // boxShadow: '0px 0px 2px 2px lightgray',
                                            }}
                                        >
                                            <Input
                                                style={{ height: '27px' }}
                                                type="search"
                                                value={topicInput}
                                                onChange={e => {
                                                    setTopicInput(e.target.value)
                                                }}
                                            />
                                            <div
                                                style={{
                                                    height: topicInput.length > 2 ? 'max-content' : '210px',
                                                    overflow: 'auto',
                                                }}
                                            >
                                                <div className="d-flex align-items-center" style={{ marginTop: '5px' }}>
                                                    <input
                                                        type="checkbox"
                                                        id={'selectAll' + 'topic'}
                                                        checked={checkedTopics}
                                                        onChange={() => {
                                                            handleSelectAllTopics()
                                                        }}
                                                    />
                                                    <label
                                                        htmlFor={'selectAll' + 'topic'}
                                                        style={{
                                                            marginLeft: '10px',
                                                            cursor: 'pointer',
                                                            fontSize: '13px',
                                                        }}
                                                    >
                                                        Select All
                                                    </label>
                                                </div>
                                                {allHierarchy
                                                    .reduce((result, ind) => {
                                                        const filteredSectors = ind.sectors.filter(sec =>
                                                            sec.topics.some(top =>
                                                                top.name
                                                                    .toLowerCase()
                                                                    .startsWith(topicInput.toLowerCase())
                                                            )
                                                        )

                                                        if (
                                                            filteredSectors.length > 0 ||
                                                            ind.name
                                                                .toLowerCase()
                                                                .startsWith(topicInput.toLowerCase()) ||
                                                            ind.sectors.find(sec =>
                                                                sec.name
                                                                    .toLowerCase()
                                                                    .startsWith(topicInput.toLowerCase())
                                                            )
                                                        ) {
                                                            if (topicInput.length > 2) {
                                                                sectorOpen.push(ind.id)
                                                                filteredSectors.forEach(sec => {
                                                                    topicOpen.push(sec.id)
                                                                })
                                                            }
                                                            if (topicInput.length === 1) {
                                                                topicOpen.splice(0, topicOpen.length)
                                                                sectorOpen.splice(0, sectorOpen.length)
                                                            }
                                                            result.push({
                                                                ...ind,
                                                                sectors: ind.sectors
                                                                    .filter(
                                                                        sector =>
                                                                            sector.name
                                                                                .toLowerCase()
                                                                                .startsWith(topicInput.toLowerCase()) ||
                                                                            sector.topics.some(topic =>
                                                                                topic.name
                                                                                    .toLowerCase()
                                                                                    .startsWith(
                                                                                        topicInput.toLowerCase()
                                                                                    )
                                                                            )
                                                                    )
                                                                    .map(filteredSector => ({
                                                                        ...filteredSector,
                                                                        topics: filteredSector.topics.filter(topic =>
                                                                            topic.name
                                                                                .toLowerCase()
                                                                                .startsWith(topicInput.toLowerCase())
                                                                        ),
                                                                    })),
                                                            })
                                                        }
                                                        return result
                                                    }, [])
                                                    .map(val => {
                                                        return (
                                                            <>
                                                                <div
                                                                    className="d-flex align-items-center"
                                                                    style={{ marginTop: '5px' }}
                                                                    key={val.name}
                                                                    title={val.name}
                                                                >
                                                                    <>
                                                                        <input
                                                                            type="checkbox"
                                                                            value={val.name}
                                                                            id={'sector' + val.name}
                                                                            checked={selectedSectors.includes(val.name)}
                                                                            onChange={() => {
                                                                                bulkChange(
                                                                                    'sector',
                                                                                    [val]
                                                                                        .concat(...val.sectors)
                                                                                        .concat(
                                                                                            ...val.sectors.map(sec =>
                                                                                                sec.topics.map(
                                                                                                    top => top
                                                                                                )
                                                                                            )
                                                                                        ),
                                                                                    val.name
                                                                                )
                                                                            }}
                                                                        />

                                                                        <label
                                                                            htmlFor={'sector' + val.name}
                                                                            style={{
                                                                                marginLeft: '10px',
                                                                                cursor: 'pointer',
                                                                                display: '-webkit-box',
                                                                                WebkitLineClamp: 1,
                                                                                fontSize: '13px',
                                                                                WebkitBoxOrient: 'vertical',
                                                                                overflow: 'hidden',
                                                                            }}
                                                                        >
                                                                            {val.name}
                                                                        </label>
                                                                        <span
                                                                            key={val.id}
                                                                            style={{
                                                                                marginLeft: 'auto',
                                                                                cursor: 'pointer',
                                                                                marginRight: '10px',
                                                                                display:
                                                                                    val.sectors.length === 0
                                                                                        ? 'none'
                                                                                        : 'block',
                                                                            }}
                                                                        >
                                                                            {val.sectors && val.sectors.length > 0 && (
                                                                                <span
                                                                                    onClick={() => {
                                                                                        toggleid(val.id, setSectorOpen)
                                                                                    }}
                                                                                >
                                                                                    {sectorOpen.includes(val.id) ? (
                                                                                        <BiMinus className="minus" />
                                                                                    ) : (
                                                                                        <BiPlus className="plus" />
                                                                                    )}
                                                                                </span>
                                                                            )}
                                                                        </span>
                                                                    </>
                                                                </div>
                                                                {sectorOpen.includes(val.id) &&
                                                                    val.sectors &&
                                                                    val.sectors.map(sec => (
                                                                        <>
                                                                            <div
                                                                                className="d-flex align-items-center"
                                                                                style={{
                                                                                    marginTop: '5px',
                                                                                    marginLeft: '15px',
                                                                                }}
                                                                                key={sec.name}
                                                                                title={sec.name}
                                                                            >
                                                                                <input
                                                                                    type="checkbox"
                                                                                    value={sec.name}
                                                                                    id={'sector' + sec.name}
                                                                                    checked={selectedSectors.includes(
                                                                                        sec.name
                                                                                    )}
                                                                                    onChange={() => {
                                                                                        bulkChange(
                                                                                            'sector',
                                                                                            [sec, ...sec.topics],
                                                                                            sec.name
                                                                                        )
                                                                                    }}
                                                                                />

                                                                                <label
                                                                                    htmlFor={'sector' + sec.name}
                                                                                    style={{
                                                                                        marginLeft: '10px',
                                                                                        cursor: 'pointer',
                                                                                        display: '-webkit-box',
                                                                                        WebkitLineClamp: 1,
                                                                                        fontSize: '13px',
                                                                                        WebkitBoxOrient: 'vertical',
                                                                                        overflow: 'hidden',
                                                                                    }}
                                                                                >
                                                                                    {sec.name}
                                                                                </label>
                                                                                <span
                                                                                    key={sec.id}
                                                                                    style={{
                                                                                        marginLeft: 'auto',
                                                                                        cursor: 'pointer',
                                                                                        marginRight: '10px',
                                                                                        display:
                                                                                            sec.topics.length === 0
                                                                                                ? 'none'
                                                                                                : 'block',
                                                                                    }}
                                                                                >
                                                                                    {sec.topics &&
                                                                                        sec.topics.length > 0 && (
                                                                                            <span
                                                                                                onClick={() => {
                                                                                                    toggleid(
                                                                                                        sec.id,
                                                                                                        setTopicOpen
                                                                                                    )
                                                                                                }}
                                                                                            >
                                                                                                {topicOpen.includes(
                                                                                                    sec.id
                                                                                                ) ? (
                                                                                                    <BiMinus className="minus" />
                                                                                                ) : (
                                                                                                    <BiPlus className="plus" />
                                                                                                )}
                                                                                            </span>
                                                                                        )}
                                                                                </span>
                                                                            </div>
                                                                            {topicOpen.includes(sec.id) &&
                                                                                sec.topics &&
                                                                                sec.topics.map(top => (
                                                                                    <div
                                                                                        className="d-flex align-items-center"
                                                                                        style={{
                                                                                            marginTop: '5px',
                                                                                            marginLeft: '30px',
                                                                                        }}
                                                                                        key={top.name}
                                                                                        title={top.name}
                                                                                    >
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            value={top.name}
                                                                                            id={'sector' + top.name}
                                                                                            checked={selectedSectors.includes(
                                                                                                top.name
                                                                                            )}
                                                                                            onChange={() => {
                                                                                                changeCheck(
                                                                                                    'sector',
                                                                                                    top.name
                                                                                                )
                                                                                            }}
                                                                                        />

                                                                                        <label
                                                                                            htmlFor={
                                                                                                'sector' + top.name
                                                                                            }
                                                                                            style={{
                                                                                                marginLeft: '10px',
                                                                                                cursor: 'pointer',
                                                                                                display: '-webkit-box',
                                                                                                WebkitLineClamp: 1,
                                                                                                fontSize: '13px',
                                                                                                WebkitBoxOrient:
                                                                                                    'vertical',
                                                                                                overflow: 'hidden',
                                                                                            }}
                                                                                        >
                                                                                            {top.name}
                                                                                        </label>
                                                                                    </div>
                                                                                ))}
                                                                        </>
                                                                    ))}
                                                            </>
                                                        )
                                                    })}
                                                {/* {overallTopic
                                                .filter(topic =>
                                                    topic.name.toLowerCase().startsWith(topicInput.toLowerCase())
                                                )
                                                // .sort((a, b) => {
                                                //     const aSelected = selectedSectors.includes(a.name)
                                                //     const bSelected = selectedSectors.includes(b.name)
                                                //     if (aSelected && !bSelected) return -1
                                                //     if (!aSelected && bSelected) return 1
                                                //     return 0
                                                // })
                                                .map(val => {
                                                    return (
                                                        <div
                                                            className="d-flex"
                                                            style={{
                                                                marginTop: '5px',
                                                                marginLeft:
                                                                    selectedSectors.includes(val.name) ||
                                                                    val.type === 'industries'
                                                                        ? '0px'
                                                                        : val.type === 'sectors'
                                                                        ? '15px'
                                                                        : '30px',
                                                            }}
                                                            title={val.name}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                value={val.value}
                                                                id={val.type + val.value}
                                                                checked={selectedSectors.includes(val.name)}
                                                                onChange={() => changeCheck('sector', val.name)}
                                                            />
                                                            <label
                                                                htmlFor={val.type + val.value}
                                                                style={{
                                                                    marginLeft: '10px',
                                                                    cursor: 'pointer',
                                                                    display: '-webkit-box',
                                                                    WebkitLineClamp: 1,
                                                                    fontSize: '13px',
                                                                    WebkitBoxOrient: 'vertical',
                                                                    overflow: 'hidden',
                                                                }}
                                                            >
                                                                {val.name}
                                                            </label>
                                                        </div>
                                                    )
                                                })} */}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        {!props.isMyCompanyPage && (
                            <div id="company_drop" style={{ marginTop: '20px' }} className={isMyRequest ? 'col-4' : ''}>
                                <div
                                    className="d-flex align-items-center justify-content-between mt-3"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setCompanySection(!companySection)
                                    }}
                                >
                                    <label>
                                        {' '}
                                        Company {selectedCompanies.length !== 0 && '(' + selectedCompanies.length + ')'}
                                    </label>
                                    <span>{!companySection ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</span>
                                </div>

                                <div style={{ width: '99%', position: 'relative', margin: 'auto' }}>
                                    {companySection && (
                                        <div
                                            // ref={wrapperRef}
                                            className="region_section"
                                            style={{
                                                width: '100%',
                                                // marginTop: '5px',
                                                // background: 'white',
                                                // position: 'absolute',
                                                padding: '10px 0px',
                                                height: companyInput.length > 2 ? 'max-content' : '260px',
                                                zIndex: 1,
                                                borderRadius: '4px',
                                                // boxShadow: '0px 0px 2px 2px lightgray',
                                            }}
                                        >
                                            <Input
                                                style={{ height: '27px' }}
                                                type="search"
                                                value={companyInput}
                                                onChange={e => {
                                                    companySearch(e)
                                                }}
                                            />
                                            <div
                                                style={{
                                                    height: companyInput.length > 2 ? 'max-content' : '210px',
                                                    overflow: 'auto',
                                                }}
                                            >
                                                <div className="d-flex" style={{ marginTop: '5px' }}>
                                                    <input
                                                        type="checkbox"
                                                        id={'selectAll' + 'company'}
                                                        checked={checkedCompanies}
                                                        onChange={() => {
                                                            handleSelectAllCompany()
                                                        }}
                                                    />
                                                    <label
                                                        htmlFor={'selectAll' + 'company'}
                                                        style={{
                                                            marginLeft: '10px',
                                                            cursor: 'pointer',
                                                            fontSize: '13px',
                                                        }}
                                                    >
                                                        Select All
                                                    </label>
                                                </div>
                                                {defaultData
                                                    .sort((a, b) => {
                                                        // const isASelected = selectedCompanies.includes(a.label)
                                                        // const isBSelected = selectedCompanies.includes(b.label)
                                                        // if (isASelected && !isBSelected) return -1
                                                        // if (!isASelected && isBSelected) return 1
                                                        return a.label.localeCompare(b.label)
                                                    })
                                                    .map(val => {
                                                        return (
                                                            <div
                                                                className="d-flex"
                                                                style={{ marginTop: '5px' }}
                                                                key={val.label}
                                                                title={val.label}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    value={val.label}
                                                                    id={'company' + val.value}
                                                                    checked={selectedCompanies.includes(val.label)}
                                                                    onChange={() => changeCheck('company', val.label)}
                                                                />
                                                                <label
                                                                    htmlFor={'company' + val.value}
                                                                    style={{
                                                                        marginLeft: '10px',
                                                                        cursor: 'pointer',
                                                                        display: '-webkit-box',
                                                                        WebkitLineClamp: 1,
                                                                        fontSize: '13px',
                                                                        WebkitBoxOrient: 'vertical',
                                                                        overflow: 'hidden',
                                                                    }}
                                                                >
                                                                    {val.label}
                                                                </label>
                                                            </div>
                                                        )
                                                    })}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {/* <div id="practice_drop" style={{ marginTop: '20px' }} className={isMyRequest ? 'col-4' : ''}>
                            <div
                                className="d-flex align-items-center justify-content-between mt-3"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setPracticeSection(!practiceSection)
                                }}
                            >
                                <label>
                                    {' '}
                                    Business Events
                                    {selectedCategories.length !== 0
                                        ? '(' +
                                          Number(
                                              selectedCategories.length -
                                                  props.allCategories.filter(cat =>
                                                      selectedCategories.includes(cat.name)
                                                  ).length
                                          ) +
                                          ')'
                                        : ''}
                                </label>
                                <span>{!practiceSection ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</span>
                            </div>
                            <div style={{ width: '99%', position: 'relative', margin: 'auto' }}>
                                {practiceSection && (
                                    <div
                                        // ref={wrapperRef}
                                        className="region_section"
                                        style={{
                                            width: '100%',
                                            // marginTop: '5px',
                                            background: 'white',
                                            position: 'absolute',
                                            padding: '10px 0px',
                                            height: '210px',
                                            zIndex: 1,
                                            borderRadius: '4px',
                                            // boxShadow: '0px 0px 2px 2px lightgray',
                                        }}
                                    >
                                        <Input
                                            style={{ height: '27px' }}
                                            type="search"
                                            value={practiceInput}
                                            onChange={e => {
                                                setPracticeInput(e.target.value)
                                            }}
                                        />
                                        <div style={{ height: '210px', overflow: 'auto' }}>
                                            <div className="d-flex" style={{ marginTop: '5px' }}>
                                                <input
                                                    type="checkbox"
                                                    id={'selectAll' + 'practice'}
                                                    checked={checkedPractices}
                                                    onChange={() => {
                                                        handleSelectAllPractice()
                                                    }}
                                                />
                                                <label
                                                    htmlFor={'selectAll' + 'practice'}
                                                    style={{
                                                        marginLeft: '10px',
                                                        cursor: 'pointer',
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    Select All
                                                </label>
                                            </div>
                                            {practiceSubCategory
                                                .filter(function(eachCat) {
                                                    if (
                                                        eachCat.subcategories.filter(subCat =>
                                                            subCat.name
                                                                .toLowerCase()
                                                                .includes(practiceInput.toLowerCase())
                                                        ).length !== 0
                                                    ) {
                                                        if (practiceInput.length > 2) {
                                                            subCategoryOpen.push(eachCat.id)
                                                        }
                                                        if (practiceInput.length === 1) {
                                                            subCategoryOpen.splice(0, subCategoryOpen.length)
                                                        }
                                                        return true
                                                    }
                                                    if (
                                                        eachCat.name
                                                            .toLowerCase()
                                                            .includes(practiceInput.toLocaleLowerCase())
                                                    ) {
                                                        return true
                                                    }
                                                })
                                                .map(val => {
                                                    return (
                                                        <>
                                                            <div
                                                                className="d-flex"
                                                                style={{ marginTop: '5px' }}
                                                                key={val.label}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    id={'sub' + val.name}
                                                                    checked={selectedCategories.includes(val.id)}
                                                                    onChange={() => {
                                                                        bulkChangesub(
                                                                            'category',
                                                                            [val, ...val.subcategories],
                                                                            val.id
                                                                        )
                                                                    }}
                                                                />
                                                                <label
                                                                    htmlFor={'sub' + val.name}
                                                                    style={{
                                                                        marginLeft: '10px',
                                                                        cursor: 'pointer',
                                                                        display: '-webkit-box',
                                                                        WebkitLineClamp: 1,
                                                                        fontSize: '13px',
                                                                        WebkitBoxOrient: 'vertical',
                                                                        overflow: 'hidden',
                                                                    }}
                                                                >
                                                                    {val.name}
                                                                </label>
                                                                <span
                                                                    key={val.id}
                                                                    style={{
                                                                        marginLeft: '10px',
                                                                        cursor: 'pointer',
                                                                        display: '-webkit-box',
                                                                        WebkitLineClamp: 1,
                                                                        fontSize: '13px',
                                                                        WebkitBoxOrient: 'vertical',
                                                                        overflow: 'hidden',
                                                                        display:
                                                                            val.subcategories.length === 0
                                                                                ? 'none'
                                                                                : '-webkit-box',
                                                                    }}
                                                                >
                                                                    {val.subcategories && val.subcategories.length > 0 && (
                                                                        <span
                                                                            onClick={() => {
                                                                                toggleid(val.id, setSubCategoryOpen)
                                                                            }}
                                                                        >
                                                                            {subCategoryOpen.includes(val.id) ? (
                                                                                <BiMinus className="minus" />
                                                                            ) : (
                                                                                <BiPlus className="plus" />
                                                                            )}
                                                                        </span>
                                                                    )}
                                                                </span>
                                                            </div>
                                                            {subCategoryOpen.includes(val.id) &&
                                                                val.subcategories &&
                                                                val.subcategories
                                                                    .filter(each =>
                                                                        each.name
                                                                            .toLowerCase()
                                                                            .includes(practiceInput.toLocaleLowerCase())
                                                                    )
                                                                    .map(sub => (
                                                                        <div
                                                                            style={{
                                                                                marginLeft: '25px',
                                                                                marginTop: '5px',
                                                                            }}
                                                                            className="d-flex"
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                id={'sub' + sub.name}
                                                                                checked={selectedCategories.includes(
                                                                                    sub.id
                                                                                )}
                                                                                onChange={() =>
                                                                                    changeCheck('category', sub.id)
                                                                                }
                                                                            />
                                                                            <label
                                                                                htmlFor={'sub' + sub.name}
                                                                                style={{
                                                                                    marginLeft: '10px',
                                                                                    cursor: 'pointer',
                                                                                    display: '-webkit-box',
                                                                                    WebkitLineClamp: 1,
                                                                                    fontSize: '13px',
                                                                                    WebkitBoxOrient: 'vertical',
                                                                                    overflow: 'hidden',
                                                                                }}
                                                                            >
                                                                                {sub.name}
                                                                            </label>
                                                                        </div>
                                                                    ))}
                                                        </>
                                                    )
                                                })}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div> */}
                        <div id="practice_drop" style={{ marginTop: '20px' }} className={isMyRequest ? 'col-4' : ''}>
                            <div className="">
                                <div
                                    className="d-flex align-items-center justify-content-between mt-3"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setPracticeSection(!practiceSection)
                                    }}
                                >
                                    <label>
                                        Business Events{' '}
                                        {selectedSubCategories.length !== 0 && '(' + selectedSubCategories.length + ')'}
                                    </label>
                                    <span>
                                        {!practiceSection ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                                    </span>
                                </div>
                            </div>
                            <div style={{ width: '99%', position: 'relative', margin: 'auto' }}>
                                {practiceSection && (
                                    <div
                                        // ref={wrapperRef}
                                        className="business_section"
                                        style={{
                                            width: '100%',
                                            // marginTop: '5px',
                                            // background: 'white',
                                            // position: 'absolute',
                                            padding: '10px 0px',
                                            height: practiceInput.length > 2 ? 'max-content' : '260px',
                                            zIndex: 1,
                                            borderRadius: '4px',
                                            // boxShadow: '0px 0px 2px 2px lightgray',
                                        }}
                                    >
                                        <Input
                                            style={{ height: '27px' }}
                                            type="search"
                                            value={practiceInput}
                                            onChange={e => {
                                                setPracticeInput(e.target.value)
                                            }}
                                        />
                                        <div
                                            style={{
                                                height: practiceInput.length > 2 ? 'max-content' : '210px',
                                                overflow: 'auto',
                                            }}
                                        >
                                            <div className="d-flex" style={{ marginTop: '5px' }}>
                                                <input
                                                    type="checkbox"
                                                    id={'selectAll' + 'subcategory'}
                                                    checked={checkedPractices}
                                                    onChange={() => {
                                                        handleSelectAllSubCategories()
                                                    }}
                                                />
                                                <label
                                                    htmlFor={'selectAll' + 'subcategory'}
                                                    style={{
                                                        marginLeft: '10px',
                                                        cursor: 'pointer',
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    Select All
                                                </label>
                                            </div>
                                            {props.allSubCategories
                                                .filter(val =>
                                                    val.label.toUpperCase().includes(practiceInput.toUpperCase())
                                                )
                                                .sort((a, b) => {
                                                    // const isASelected = selectedSubCategories.includes(a.label)
                                                    // const isBSelected = selectedSubCategories.includes(b.label)
                                                    // if (isASelected && !isBSelected) return -1
                                                    // if (!isASelected && isBSelected) return 1
                                                    return a.label.localeCompare(b.label)
                                                })
                                                .map(val => {
                                                    return (
                                                        <div
                                                            className="d-flex"
                                                            style={{ marginTop: '5px' }}
                                                            title={val.label}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                value={val.label}
                                                                id={'subCategory' + val.value}
                                                                checked={selectedSubCategories.includes(val.label)}
                                                                onChange={() => changeCheck('subCategory', val.label)}
                                                            />
                                                            <label
                                                                htmlFor={'subCategory' + val.value}
                                                                style={{
                                                                    marginLeft: '10px',
                                                                    cursor: 'pointer',
                                                                    display: '-webkit-box',
                                                                    WebkitLineClamp: 1,
                                                                    fontSize: '13px',
                                                                    WebkitBoxOrient: 'vertical',
                                                                    overflow: 'hidden',
                                                                }}
                                                            >
                                                                {val.label}
                                                            </label>
                                                        </div>
                                                    )
                                                })}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div
                        className={props.isMyCompanyPage ? 'd-flex align-items-center justify-content-between' : ''}
                        style={{
                            marginTop: '20px',
                            position: 'fixed',
                            bottom: '0px',
                            // left: '75px',
                            margin: 'auto',
                            width: '200px',
                            background: '#051340',
                            height: '70px',
                        }}
                    >
                        <Button
                            style={{
                                textDecoration: 'underline',
                                color: 'gray',
                                background: 'white',
                                width: props.isMyCompanyPage ? '' : '150px',
                                fontSize: '14px',
                                cursor: 'pointer',
                                border: 'none',
                                textTransform: 'none',
                                boxShadow: 'none',
                                // marginTop: '10px',
                                position: 'relative',
                                top: '5px',
                                left: props.isMyCompanyPage ? '0px' : '17px',
                            }}
                            onClick={() => {
                                clearFilters()
                                setCheckedCompanies(false)
                                setCheckedPractices(false)
                                setCheckedSources(false)
                                setCheckedTopics(false)
                                setCheckedRegions(false)
                                setSourceInput('')
                                setRegionInput('')
                                setTopicInput('')
                                setCompanyInput('')
                                setPracticeInput('')
                                setRegionSection(false)
                                setCompanySection(false)
                                setPracticeSection(false)
                                setTopicSection(false)
                                setSourceSection(false)
                                setDateSection(false)
                            }}
                        >
                            Clear
                        </Button>
                        {props.isMyCompanyPage && (
                            <Button
                                style={{
                                    textDecoration: 'none',
                                    textTransform: 'none',
                                    color: btnAnimation ? 'black' : 'white',
                                    background: btnAnimation ? 'white' : 'rgb(24, 106, 222)',
                                    // width: '90%',
                                    fontSize: '14px',
                                    cursor: 'pointer',
                                    marginLeft: '10px',
                                    marginTop: '10px',
                                }}
                                onClick={() => generateQuery(selectedOption)}
                                title="Click to apply filters"
                            >
                                Apply
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
