import React, { useEffect, useState } from 'react'
import Select, { components } from 'react-select'
import { BiPlus, BiMinus } from 'react-icons/bi'

// Transform hierarchical data into a flat list with parent-child relationships
const transformData = data => {
    const options = []
    data.forEach(region => {
        const regionOption = {
            value: region.id,
            label: region.name,
            type: 'Region',
            level: 0,
            parent: null,
        }
        options.push(regionOption)

        region.countries.forEach(country => {
            const countryOption = {
                value: country.id,
                label: country.name,
                type: 'Country',
                level: 1,
                parent: regionOption,
            }
            options.push(countryOption)

            country.provinces.forEach(province => {
                const provinceOption = {
                    value: province.id,
                    label: province.name,
                    type: 'Province',
                    level: 2,
                    parent: countryOption,
                }
                options.push(provinceOption)
            })
        })
    })
    return options
}

export default function NewRegionHierarchy(props) {
    const { isMypreference, regionHierarchy, selectedOptions, setSelectedOptions, isAnalystAdminAdd } = props
    const [expanded, setExpanded] = useState({}) // Track expanded state for regions and countries
    const [inputValue, setInputValue] = useState('')
    const options = transformData(regionHierarchy)

    // Default data for pre-selecting options
    const defaultData = ['Canada', 'Germany', 'North America'] // Default selection (for example: ['Canada'])

    // Map the default data (['Canada']) to corresponding option objects
    // const getDefaultSelections = () => {
    //     console.log(selectedOptions.region)
    //     const sele = selectedOptions.region.map(v => v.label)

    //     console.log(options)
    //     console.log(options.filter(item => sele.includes(item.label)))

    //     const selected = []
    //     selectedOptions.region.forEach(defaultName => {
    //         console.log(defaultName, defaultName.label)
    //         console.log(options)

    //         const option = options.filter(opt => (opt.label === defaultName.label ? defaultName.label : defaultName))
    //         console.log('selopt', option)

    //         if (option) {
    //             selected.push(option)
    //         }
    //         console.log('final', selected, option)
    //     })

    //     return selected
    // }
    const getDefaultSelections = () => {
        console.log('Selected regions:', selectedOptions.region || selectedOptions)

        // Extract selected labels
        const selectedLabels = !isMypreference ? selectedOptions.region.map(v => v.label) : selectedOptions
        console.log('Selected labels:', selectedLabels)

        // Filter options based on selected labels
        console.log('options', options)

        const selected = options.filter(opt => selectedLabels.includes(opt.label))
        console.log('Filtered options:', selected)

        return selected
    }

    useEffect(() => {
        console.log('regionhier', regionHierarchy)
        console.log('selopt', selectedOptions)
        console.log('selfun', isMypreference, getDefaultSelections())

        // Set the default selected options when the component mounts
        isMypreference
            ? setSelectedOptions(getDefaultSelections())
            : setSelectedOptions(prev => ({
                  ...prev,
                  region: getDefaultSelections(),
              }))
    }, [regionHierarchy])

    // Toggle expansion for regions and countries
    const toggleExpand = option => {
        setExpanded(prev => {
            const newState = { ...prev }
            if (option.type === 'Region') {
                newState[option.value] = !newState[option.value]
            } else if (option.type === 'Country') {
                newState[option.value] = !newState[option.value]
            }
            return newState
        })
    }

    // Handle option selection
    const handleSelect = selectedOption => {
        const updatedSelection = isMypreference ? [...selectedOptions] : [...selectedOptions.region]
        console.log('update', updatedSelection)

        const isSelected = updatedSelection.some(option => option.value === selectedOption.value)

        if (isSelected) {
            const removeWithChildren = option => {
                const index = updatedSelection.findIndex(opt => opt.value === option.value)
                if (index > -1) {
                    updatedSelection.splice(index, 1)
                }
                const children = options.filter(opt => opt.parent && opt.parent.value === option.value)
                children.forEach(removeWithChildren)
            }
            removeWithChildren(selectedOption)
        } else {
            const addWithChildren = option => {
                updatedSelection.push(option)
                const children = options.filter(opt => opt.parent && opt.parent.value === option.value)
                children.forEach(addWithChildren)
            }
            addWithChildren(selectedOption)
        }

        // setSelectedOptions(updatedSelection)
        !isMypreference
            ? setSelectedOptions(prev => ({
                  ...prev,
                  region: updatedSelection,
              }))
            : setSelectedOptions(updatedSelection)
    }

    // Check if the option is already selected
    // const isChecked = option =>
    //     Array.isArray(selectedOptions.region || selectedOptions) && selectedOptions.region
    //         ? selectedOptions.region.some(selected => selected.value === option.value)
    //         : selectedOptions.some(selected => selected.value === option.value)

    const isChecked = option => {
        const selectionArray = isMypreference
            ? Array.isArray(selectedOptions) && selectedOptions
            : Array.isArray(selectedOptions.region) && selectedOptions.region

        // Check if the option is present in the selection array
        return selectionArray && selectionArray.some(selected => selected.value === option.value)
    }

    // const isChecked = option =>
    //     Array.isArray(selectedOptions) && selectedOptions
    //         ? selectedOptions.some(selected => selected.value === option.value)
    //         : isArrayOfObjects(selectedOptions) === false
    //         ? getDefaultSelections()
    //         : selectedOptions.some(selected => selected.value === option.value)

    // console.log('ischecked', isChecked)

    // Custom Option component with expand/collapse functionality
    const CustomOption = props => {
        const { data, innerRef, innerProps } = props
        const paddingLeft = `${data.level * 20}px` // Indentation for hierarchy
        const hasChildren =
            data.type === 'Country' && options.some(opt => opt.parent && opt.parent.value === data.value)

        const isExpandable = data.type === 'Region' || hasChildren // Expand Regions and Countries with provinces
        const isExpanded = expanded[data.value] || false

        return (
            <div
                ref={innerRef}
                {...innerProps}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '5px 10px',
                    paddingLeft,
                    cursor: 'pointer',
                }}
                onClick={() => handleSelect(data)} // Trigger selection
            >
                <span>
                    <input
                        type="checkbox"
                        checked={isChecked(data)}
                        onChange={() => handleSelect(data)}
                        style={{ marginLeft: '5px' }}
                    />
                    <span style={{ marginLeft: '10px' }}>
                        {data.label} ({data.type})
                    </span>
                </span>
                {isExpandable && (
                    <span
                        onClick={e => {
                            e.stopPropagation() // Prevent triggering the select event
                            toggleExpand(data) // Expand/collapse
                        }}
                        style={{
                            cursor: 'pointer',
                            userSelect: 'none',
                        }}
                    >
                        {isExpanded ? <BiMinus /> : <BiPlus />}
                    </span>
                )}
            </div>
        )
    }

    // Filter options based on search input and expansion state
    const filteredOptions = options.filter(option => {
        // Case when there's no search input:
        if (!inputValue) {
            // Show regions, countries, and provinces based on their expanded state
            if (option.level === 0) return true // Show regions
            if (option.level === 1 && expanded[option.parent.value]) return true // Show countries if region is expanded
            if (option.level === 2 && expanded[option.parent.value]) return true // Show provinces if country is expanded
            return false
        }

        // Case when there is a search input:
        const matchesSearch = option.label.toLowerCase().includes(inputValue.toLowerCase())
        return matchesSearch
    })

    const isArrayOfObjects = array => {
        if (!Array.isArray(array)) return false // Ensure it's an array
        if (array.length === 0) return false
        return array.every(item => typeof item === 'object' && item !== null)
    }

    return (
        <div>
            {console.log('selectboc', isMypreference, selectedOptions)}
            <Select
                options={filteredOptions}
                value={
                    isMypreference
                        ? // isArrayOfObjects(selectedOptions) === false
                          //     ? getDefaultSelections(selectedOptions)
                          //     :
                          selectedOptions
                        : selectedOptions.region
                }
                onChange={newValue => {
                    !isMypreference
                        ? setSelectedOptions(prev => ({
                              ...prev,
                              region: newValue || [],
                          }))
                        : setSelectedOptions(newValue)
                }}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option: CustomOption }}
                placeholder="Select Region, Country, or Province"
                filterOption={null}
                onInputChange={value => setInputValue(value)}
                className={isMypreference ? 'preference_add' : 'inputs_new'}
            />
        </div>
    )
}
