import React, { useRef, useState } from 'react'
import '../RawNews.css'
import { Switch } from '@mui/material'
import store from '../../../Store'
import { FaListAlt } from 'react-icons/fa'

export default function ResultHeader(props) {
    const {
        allSelected,
        setAllSelected,
        resultLength,
        resultCount,
        expanded,
        setExpanded,
        selectedNewsOnly,
        setSelectedNewsOnly,
        isPublishedNews,
        isSemantic,
        isBasicSearchPage,
        israwNews,
        premeetingPlan,
        setPremeetingPlan,
        selectedStoriesLength,
        basicScore,
        isScore,
        setIsScore,
    } = props

    const usertype = store.getState().auth.user.user_type
    const [openSettings, setOPenSettings] = useState(false)

    const settingsRef = useRef(null)
    closeExpandable(settingsRef)
    function closeExpandable(ref) {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setOPenSettings(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
                style={{
                    display: 'inherit',
                    alignItems: 'inherit',
                    marginLeft: isBasicSearchPage ? '25px' : '',
                    width: usertype === 'Regular User' || usertype === 'Power User' ? '20%' : '16%',
                }}
            >
                <div className="newsCardCollapsed__selectColumn" style={{ marginLeft: '5px', width: '5%' }}>
                    {!isSemantic && (
                        <div
                            className={allSelected ? 'newsCard__selectSquare-active' : 'newsCard__selectSquare'}
                            style={{ width: '15px', height: '15px', margin: '0' }}
                            onClick={() => setAllSelected(!allSelected)}
                        />
                    )}
                </div>
                {!isSemantic && (
                    <span
                        className="rawNews3__expandedView"
                        style={{
                            width: '70%',
                            textAlign: 'center',
                        }}
                    >
                        Select All
                    </span>
                )}
            </div>

            <p className="rawNews3__newsCount" style={{ marginRight: isBasicSearchPage ? '' : '' }}>
                Showing{' '}
                <strong>
                    {resultLength} of {resultCount} News items{' '}
                </strong>{' '}
                for your search criteria
            </p>

            <div style={{ marginLeft: 'auto' }}>
                <FaListAlt
                    onClick={() => {
                        setOPenSettings(!openSettings)
                    }}
                    style={{
                        color: 'rgb(25, 123, 189)',
                        width: '25px',
                        height: '25px',
                        cursor: 'pointer',
                    }}
                />
                {openSettings && (
                    <div
                        ref={settingsRef}
                        style={{
                            width: '220px',
                            marginTop: '10px',
                            background: 'white',
                            position: 'absolute',
                            padding: '10px',
                            height: 'auto',
                            cursor: 'pointer',
                            marginLeft: '-184px',
                            borderRadius: '4px',
                            boxShadow: '0px 0px 2px 2px lightgray',
                            zIndex: 1,
                        }}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span className="rawNews3__expandedView">Selected Only</span>
                            <Switch
                                label="Expand"
                                defaultChecked={selectedNewsOnly}
                                onChange={checked => {
                                    setSelectedNewsOnly(!selectedNewsOnly)
                                }}
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span className="rawNews3__expandedView">Expanded View</span>
                            <Switch label="Expand" checked={expanded} onChange={checked => setExpanded(!expanded)} />
                        </div>
                        {basicScore !== undefined && (
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <span className="rawNews3__expandedView">Relevance Score</span>
                                <Switch label="Expand" checked={isScore} onChange={checked => setIsScore(!isScore)} />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
