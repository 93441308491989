export const dashboardUrlStrings = filter => {
    if (filter.type === 'industry') {
        return `&industries=${filter.indObj.value}&sectors&companies&topics`
    } else if (filter.type === 'sector') {
        return `&industries&sectors=${filter.indObj.value}&companies&topics`
    } else if (filter.type === 'company') {
        return `&industries&sectors&companies=${filter.indObj.value}&topics`
    } else if (filter.type === 'topics') {
        return `&industries&sectors&companies&topics=${filter.indObj.value}`
    } else {
        return `&industries&sectors&companies&topics`
    }
}

export const getNumberOfDays = (startDate, endDate) => {
    const difference = endDate - startDate
    return difference / 86400000
}

export const LastCronValue = (cronExpression, operator) => {
    // Split the cron expression into its components
    const parts = cronExpression.split(' ')

    // Ensure there are exactly 5 parts (standard cron format)
    if (parts.length !== 5) {
        throw new Error('Invalid cron expression format')
    }

    // Identify the last part
    const lastPart = parts[4]

    // Check if the last part is an integer value
    if (/^\d+$/.test(lastPart)) {
        // Parse the last part as an integer
        let value = parseInt(lastPart, 10)

        // Define the maximum value for wrapping
        const maxValue = 6 // For days of the week, this is 0-6

        // Increment or decrement the value and handle wrapping around
        if (operator === '+') {
            value = (value + 1) % (maxValue + 1)
        } else if (operator === '-') {
            value = (value - 1 + (maxValue + 1)) % (maxValue + 1) // Adding maxValue+1 to handle negative results
        } else {
            throw new Error('Invalid operator. Use "+" or "-"')
        }

        // Replace the last part with the incremented or decremented value
        parts[4] = value.toString()
    } else {
        // If not an integer, return the cron expression unchanged
        console.log('Last part is not an integer. No changes made.')
        return cronExpression
    }

    // Join the parts back into a cron expression
    return parts.join(' ')
}
