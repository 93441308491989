import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import Step1Form from './components/Step1Form'
import Step2Form from './components/Step2Form'
import KaitongoLogo from '../../assets/images/logo.svg'
import BlueDots from '../../assets/images/blue-dots.svg'
import OrangeDots from '../../assets/images/orange-dots.svg'
import { loginFunction } from '../../utils/login'
import { logoutUserNoRedirect } from '../../actions/AuthActions'
import './UsersignupMain.css'
import Logo from '../../components/Logo/Logo'

export default function UserSignupMain(props) {
    const [page, setPage] = useState(1)
    const [allCategories, setAllCategories] = useState([])
    const [userForm, setUserForm] = useState({
        first_name: '',
        last_name: '',
        email: '',
        country: '',
        practice_area: 5,
        password: '',
        password_confirm: '',
    })
    const [selectedCategoryName, setSelectedCategoryName] = useState('')
    const [allSubCats, setAllSubCats] = useState([])
    const [selectedSubCats, setSelectedSubCats] = useState([])

    useEffect(() => {
        logoutUserNoRedirect()
        const axiosInstanceCats = axios.create()
        axiosInstanceCats.defaults.headers.common = {}
        axiosInstanceCats.get('/endusers/all-practice-areas').then(response => {
            setAllCategories(response.data.results)
        })
    }, [])

    const getSubcategoriesFromPracticeArea = () => {
        const selectedPracticeTemp = allCategories.find(item => item.id === userForm.practice_area)
        setSelectedCategoryName(selectedPracticeTemp.name)
        setSelectedSubCats([])
        axios.get(`/news/subcategories-from-practice-area/?practiceAreas=${userForm.practice_area}`).then(res => {
            setAllSubCats(res.data.results)
            setSelectedSubCats(res.data.results.map(item => item.value))
        })
    }

    const createNewUser = () => {
        let userObj = {
            first_name: userForm.first_name,
            last_name: userForm.last_name,
            email: userForm.email,
            password: userForm.password,
            country: userForm.country,
            subcategories: selectedSubCats,
            practice_area: userForm.practice_area,
        }

        const axiosInstance = axios.create()
        axiosInstance.defaults.headers.common = {}
        axiosInstance.defaults.headers.common.accept = 'application/json'
        axiosInstance
            .post('/accounts/analyst/new-user/', userObj)
            .then(() => {
                console.log('User created')
                const values = {
                    email: userForm.email,
                    password: userForm.password,
                    remember_me: false,
                }
                loginFunction(values, props.history, props.location)
            })
            .catch(err => {
                console.log(err.response)
                if (
                    err.response.data.email &&
                    err.response.data.email.includes('user with this Email already exists.')
                ) {
                    toast('Email already exits')
                } else {
                    toast('Unknown error creating user')
                }
            })
    }

    return (
        <div
            className="signup__mainContainer"
            style={{ minHeight: '100vh', background: '#05183d', paddingTop: '3rem' }}
        >
            {/* <img src={process.env.REACT_APP_MEDIA_URL + BlueDots} alt="blue dots" className="signup__blueDots" />
            <img src={process.env.REACT_APP_MEDIA_URL + OrangeDots} alt="blue dots" className="signup__orangeDots" /> */}

            <div className="signup__header">
                <Logo white />
                {page === 1 && (
                    <div>
                        <p className="signup__startToday">Start today for free</p>
                        <p className="signup__getAccess">
                            Get access to our powerful hybrid intelligence system and see how Kaitongo can help you.
                        </p>
                    </div>
                )}
                {page === 2 && (
                    <div>
                        <p className="signup__startToday">Welcome to Kaitongo, let us personalize your experience!</p>
                    </div>
                )}
            </div>

            <div className="signup__body">
                {page === 1 && (
                    <Step1Form
                        setPage={setPage}
                        userForm={userForm}
                        setUserForm={setUserForm}
                        allCategories={allCategories}
                        getSubcategoriesFromPracticeArea={getSubcategoriesFromPracticeArea}
                    />
                )}

                {page === 2 && (
                    <Step2Form
                        setPage={setPage}
                        createNewUser={createNewUser}
                        selectedCategoryName={selectedCategoryName}
                        allSubCats={allSubCats}
                        selectedSubCats={selectedSubCats}
                        setSelectedSubCats={setSelectedSubCats}
                    />
                )}
            </div>
        </div>
    )
}
