import React, { PureComponent } from 'react'
import logo from '../../assets/images/logo.svg'
import logoWhite from '../../assets/images/logo.png'
import { Link } from 'react-router-dom'
import './logo.css'

class Logo extends PureComponent {
    render() {
        const { white, user } = this.props

        if (
            user &&
            user.subscription_type &&
            (user.subscription_type === 'Firm' || user.user_type === 'Power User') &&
            user.firm_logo &&
            user.mobile_firm_logo
        ) {
            return (
                <Link to="/">
                    <img
                        src={window.innerWidth > 900 ? user.firm_logo : user.mobile_firm_logo}
                        alt="logo"
                        className={window.innerWidth > 900 ? 'firm_logo_desktop' : 'firm_logo_mobile'}
                    />
                </Link>
            )
        }

        return (
            <Link to="/">
                <img src={white ? logoWhite : logo} className="signup" alt="logo" />
            </Link>
        )
    }
}

export default Logo
