import React, { useEffect, useState } from 'react'
import grapesjs from 'grapesjs'
import './Grape.css'
import 'grapesjs/dist/css/grapes.min.css'
import grapesjsPresetNewsletter from 'grapesjs-preset-newsletter'
import { Modal, ModalBody, ModalHeader, Form, Input, Label, Button, Spinner, ModalFooter } from 'reactstrap'
import { toast } from 'react-toastify'
import axios from 'axios'
import { getCookie } from '../../../utils'
import { subDays, addDays, format } from 'date-fns'
import { useLocation } from 'react-router'
import { renderToStaticMarkup } from 'react-dom/server'
import NewsBody from './NewsBody'
import NewsSection from './NewsSections'
import Select from 'react-select'
import store from '../../../Store'

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        minWidth: '90%',
        background: '#fafafa',
        borderRadius: '5px',
        width: '90%',
        borderBottom: '1px solid lightgray',
        borderRadius: 0,
    }),
}

export default function Grape(props) {
    const [editor, setEditor] = useState(null)
    const [saveModal, setSaveModal] = useState(false)
    const [emailModal, setEmailModal] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)
    const token = getCookie('csrftoken')
    const [news, setNews] = useState()
    const user_type = store.getState().auth.user.user_type

    const { selectedStories = null, categorizedNews = null, setHtmlContent = null, setCategorizedNews = null } = props
    let { selectedTemplate = null, setConfirmGo = null, pathname = null } = props
    let location = useLocation()
    if (location.state) {
        selectedTemplate = location.state.selectedTemplate
    }
    const [defaultData, setDefaultData] = useState({
        templateName: selectedTemplate ? selectedTemplate.name : '',
        thumbnail: selectedTemplate ? selectedTemplate.thumbnail : '',
        templateDesc: selectedTemplate ? selectedTemplate.description : '',
        primaryColor: selectedTemplate ? selectedTemplate.primary_color : '',
        secondaryColor: selectedTemplate ? selectedTemplate.secondary_color : '',
        thumbName: selectedTemplate ? selectedTemplate.thumbnail_name : 'No File Chosen',
    })
    //firm
    const [selectedFirm, setSelectedFirm] = useState(null)

    const [allFirms, setAllFirms] = useState([])
    useEffect(() => {
        if (pathname !== '/app/search/') {
            axios
                .get('/analyst/firms/')
                .then(response => {
                    setAllFirms(
                        response.data.firms.map(val => {
                            return { value: val.value, label: val.label }
                        })
                    )
                })
                .catch(err => console.log(err))
        }
    }, [])

    useEffect(() => {
        if (allFirms && allFirms.length > 0 && selectedTemplate && selectedTemplate.firm) {
            const selectedFirmData = allFirms.find(val => val.value === selectedTemplate.firm)
            setSelectedFirm(selectedFirmData)
        }
    }, [allFirms])

    useEffect(() => {
        const editor = grapesjs.init({
            container: '#editor',
            allowScripts: 1,
            height: '100vh',
            plugins: [grapesjsPresetNewsletter],
            storageManager: false,
            fromElement: true,
        })

        editor.Panels.removePanel('devices-c')
        editor.Panels.removeButton('options', 'gjs-open-import-template')
        editor.Panels.removeButton('devices-c')
        // editor.Panels.removeButton('options', 'export-template')

        user_type !== 'Regular User' &&
            editor.Panels.addButton('options', [
                {
                    id: 'saveTemplate',
                    active: false,
                    className: selectedTemplate ? 'fas fa-edit' : 'fa fa-floppy-o',
                    attributes: { title: selectedTemplate ? 'Edit Template' : 'Save Template' },
                    command: function(editor) {
                        setSaveModal(true)
                    },
                },
            ])

        editor.Panels.addButton('options', [
            {
                id: 'testMail',
                active: false,
                className: 'fa fa-envelope',
                attributes: { title: 'Send Test EMail' },
                command: function(editor) {
                    setEmailModal(true)
                },
            },
        ])

        editor.on('load', function() {
            var body = editor.Canvas.getBody()
            body.style.width = '700px'
            if (selectedTemplate) {
                if (selectedTemplate.custom_template) {
                    axios.get(`/news/custom-templates/${selectedTemplate.id}`).then(res => {
                        if (res.status === 200) {
                            // editor.setComponents([])
                            // editor.setComponents({
                            //     content: `${res.data}
                            //     <style>body{width:700px}</style>`,
                            // })
                            const newContent = res.data
                            editor.setComponents(newContent)
                            if (setHtmlContent) setHtmlContent(editor.runCommand('gjs-get-inlined-html'))
                        }
                    })
                }
            }
        })

        editor.on('component:update storage:end', function() {
            if (setHtmlContent) setHtmlContent(editor.runCommand('gjs-get-inlined-html'))
        })
        editor.on('block:drag:stop', function() {
            if (setHtmlContent) setHtmlContent(editor.runCommand('gjs-get-inlined-html'))
        })

        setEditor(editor)
    }, [selectedTemplate])

    useEffect(() => {
        if (editor) {
            editor.Blocks.remove('news-sections')
            editor.Blocks.add('news-sections', {
                label: 'News Items',
                attributes: { class: 'gjs-block-section' },
                content:
                    categorizedNews.length !== 0
                        ? renderToStaticMarkup(
                              <NewsBody
                                  selectedStories={categorizedNews}
                                  primaryColor={defaultData.primaryColor}
                                  secondaryColor={defaultData.secondaryColor}
                              />
                          )
                        : '<h4> Select News and Sections to render News items</h4>',
            })
        }
    }, [categorizedNews])

    function testMail(e) {
        e.preventDefault()
        setShowSpinner(true)
        let formData = new FormData()
        let blob = new Blob([editor.runCommand('gjs-get-inlined-html')], { type: 'text/html' })
        formData.append('htmlcontent', blob)
        formData.append('email', e.target.email.value)

        axios
            .post('/endusers/custom-newsletters/', formData, {
                headers: { 'X-CSRFToken': token, 'Content-Type': 'multipart/form-data' },
            })
            .then(res => {
                if (res.status === 200) {
                    setEmailModal(false)
                    setShowSpinner(false)
                    toast('Mail Sended Successfully')
                }
            })
            .catch(err => {
                toast('Mail sending failed')
                setShowSpinner(false)
            })
    }

    function saveTemplate(e) {
        setShowSpinner(true)
        e.preventDefault()
        let formData = new FormData()
        let blob = new Blob([editor.runCommand('gjs-get-inlined-html')], { type: 'text/html' })
        selectedTemplate && formData.append('id', selectedTemplate.id)
        formData.append('name', e.target.templateName.value)
        formData.append('htmlcontent', blob)
        selectedTemplate
            ? defaultData.thumbName !== selectedTemplate.thumbnail_name &&
              formData.append('thumbnail', e.target.thumbnail.files[0])
            : formData.append('thumbnail', e.target.thumbnail.files[0])
        selectedTemplate
            ? defaultData.thumbName !== selectedTemplate.thumbnail_name &&
              formData.append('thumbnail_name', e.target.thumbnail.files[0].name)
            : formData.append('thumbnail_name', e.target.thumbnail.files[0].name)
        formData.append('description', e.target.templateDesc.value)
        formData.append('primary_color', e.target.primaryColor.value)
        formData.append('secondary_color', e.target.secondaryColor.value)
        user_type !== 'Power User' && formData.append('firm', selectedFirm.value)
        axios
            .post('/news/custom-templates/', formData, {
                headers: { 'X-CSRFToken': token, 'Content-Type': 'multipart/form-data' },
            })
            .then(res => {
                // if (res.status === 200) {
                setShowSpinner(false)
                res.data === 'Updated' ? toast('Template Updated') : toast('Template Saved')
                setSaveModal(false)
                selectedTemplate && setConfirmGo(true)
                // }
            })
            .catch(err => toast('Saving Template Failed') && setShowSpinner(false))
    }

    const thumbRef = React.useRef()

    const handleInputChange = e => {
        const { name, value } = e.target
        setDefaultData({ ...defaultData, [name]: value })
    }

    return (
        <div style={{ marginTop: props.isSearchComponent ? '-65px' : '-100px' }}>
            <div
                style={{
                    position: 'absolute',
                    zIndex: '4',
                    marginLeft: '66%',
                    marginTop: '4px',
                }}
            >
                {selectedTemplate && (
                    <div>
                        <NewsSection
                            selectedNews={selectedStories ? selectedStories : news}
                            setNewsCategory={setCategorizedNews}
                            categorizedNews={categorizedNews}
                        />
                    </div>
                )}
            </div>

            <div id="editor" />
            <Modal isOpen={saveModal} toggle={() => setSaveModal(!saveModal)}>
                <ModalHeader>
                    <p>
                        <strong>Set Template Name and Description</strong>
                    </p>
                </ModalHeader>
                <ModalBody>
                    <div className="container" style={{ textAlign: 'center' }}>
                        <Form
                            onSubmit={e => {
                                saveTemplate(e)
                            }}
                        >
                            <Label>Template Name</Label>
                            <Input
                                type="text"
                                value={defaultData.templateName}
                                onChange={handleInputChange}
                                name="templateName"
                                required
                            />
                            <Label>Template Description</Label>
                            <Input
                                type="textarea"
                                value={defaultData.templateDesc}
                                onChange={handleInputChange}
                                name="templateDesc"
                                required
                            />
                            <Label>Thumbnail</Label>
                            <input
                                type="file"
                                accept="image/*"
                                ref={ref => (thumbRef.current = ref)}
                                onChange={e => {
                                    const { name, value } = e.target
                                    console.log(e)
                                    if (e.target.files[0]) {
                                        name === 'thumbnail' && setDefaultData({ ...defaultData, thumbName: value })
                                    }
                                }}
                                name="thumbnail"
                                style={{ display: 'none' }}
                                required={false}
                            />
                            <div className="d-flex">
                                <Button
                                    style={{
                                        border: '1px solid lightgray',
                                        color: 'black',
                                        background: '#eeeeee',
                                        width: '30%',
                                        fontSize: '12px',
                                    }}
                                    onClick={e => thumbRef.current.click()}
                                >
                                    Choose File
                                </Button>
                                <Input
                                    value={defaultData.thumbName}
                                    type="text"
                                    style={{ background: 'white', width: '70%' }}
                                    readOnly
                                />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '40%' }}>
                                    <Label>Primary Color</Label>
                                    <Input
                                        type="color"
                                        value={defaultData.primaryColor}
                                        onChange={handleInputChange}
                                        name="primaryColor"
                                        title="primary color"
                                    />
                                </div>
                                <div style={{ width: '40%' }}>
                                    <Label>Secondary Color</Label>
                                    <Input
                                        type="color"
                                        value={defaultData.secondaryColor}
                                        onChange={handleInputChange}
                                        name="secondaryColor"
                                        title="secondary color"
                                    />
                                </div>
                            </div>

                            {user_type !== 'Power User' && (
                                <>
                                    <Label>Firm</Label>
                                    <Select
                                        placeholder="Select Firm"
                                        options={allFirms}
                                        value={selectedFirm}
                                        onChange={e => {
                                            setSelectedFirm(e)
                                        }}
                                        styles={customStyles}
                                    />
                                </>
                            )}

                            <Button
                                type="submit"
                                color="primary"
                                disabled={showSpinner ? true : false}
                                style={{
                                    marginTop: '10px',
                                    float: 'left',
                                    textAlign: 'center',
                                    marginLeft: selectedTemplate ? '34%' : '',
                                }}
                            >
                                {showSpinner && (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        style={{ margin: '1px 5px 0px' }}
                                    />
                                )}
                                {selectedTemplate ? 'Edit Template' : 'Save Template'}
                            </Button>
                            {!selectedTemplate && (
                                <Button
                                    type="cancel"
                                    color="danger"
                                    onClick={() => setSaveModal(false)}
                                    disabled={showSpinner ? true : false}
                                    style={{ marginTop: '10px', float: 'right', textAlign: 'center' }}
                                >
                                    Cancel
                                </Button>
                            )}
                        </Form>
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={emailModal} toggle={() => setEmailModal(!emailModal)}>
                <ModalHeader>
                    <p>
                        <strong>Test Mail</strong>
                    </p>
                </ModalHeader>

                <ModalBody>
                    <Form onSubmit={e => testMail(e)}>
                        <Label>Email-ID</Label>
                        <Input type="email" name="email" />
                        <Button
                            type="submit"
                            variant="primary"
                            disabled={showSpinner ? true : false}
                            style={{ marginTop: '10px', float: 'right', textAlign: 'center' }}
                        >
                            {showSpinner && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ margin: '1px 5px 0px' }}
                                />
                            )}
                            Send Email
                        </Button>
                    </Form>
                </ModalBody>
            </Modal>
        </div>
    )
}
