import axios from 'axios'
import html2pdf from 'html2pdf.js'
import React, { useEffect, useState } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import { FaFileDownload } from 'react-icons/fa'
import { FiMail } from 'react-icons/fi'
import { toast } from 'react-toastify'
import { Button, Form, Input, Label, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import { getCookie } from '../../../utils'

export default function PreMeetingBriefModal(props) {
    const [showSpinner, setShowSpinner] = useState(false)
    const [emailModal, setEmailModal] = useState(false)

    const {
        premeetingData,
        premeetingPreview,
        setPremeetingPreview,
        preMeetingLoader,
        companyInformation,
        isFindAndShare,
    } = props

    const token = getCookie('csrftoken')
    const pdfDownLoad = () => {
        const element = document.getElementById('premeeting_content')

        // Configuration for html2pdf
        const options = {
            margin: 10,
            filename: 'downloaded_pdf.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        }

        html2pdf()
            .set(options)
            .from(element)
            .save()
    }

    const sendingMail = e => {
        e.preventDefault()
        setShowSpinner(true)
        const element = document.getElementById('premeeting_content')

        const htmlContent = element.innerHTML
        const singleLineString = htmlContent.replace(/\s+/g, ' ').trim()

        let formData = new FormData()
        let blob = new Blob([singleLineString], { type: 'text/html' })
        formData.append('htmlcontent', blob)
        formData.append('email', e.target.email.value)

        axios
            .post('/endusers/custom-newsletters/', formData, {
                headers: { 'X-CSRFToken': token, 'Content-Type': 'multipart/form-data' },
            })
            .then(res => {
                if (res.status === 200) {
                    setEmailModal(false)
                    setShowSpinner(false)
                    toast('Email sent')
                }
            })
            .catch(err => {
                toast('Email sending failed')
                setShowSpinner(false)
            })
    }

    return (
        <>
            <Modal isOpen={premeetingPreview} toggle={() => setPremeetingPreview(false)} size="xl">
                <div className="modal_header_black">
                    <span style={{ color: 'white' }}>Premeeting Summary</span>

                    <div>
                        {premeetingData && premeetingData.news && (
                            <>
                                <span onClick={pdfDownLoad} className="modal_close_btn" style={{ marginRight: '20px' }}>
                                    <FaFileDownload />
                                </span>
                                <span
                                    onClick={() => setEmailModal(!emailModal)}
                                    className="modal_close_btn"
                                    style={{ marginRight: '20px' }}
                                >
                                    <FiMail />
                                </span>
                            </>
                        )}
                        <span
                            onClick={() => {
                                setPremeetingPreview(false)
                            }}
                            className="modal_close_btn"
                        >
                            <AiFillCloseCircle />
                        </span>
                    </div>
                </div>
                {preMeetingLoader ? (
                    <ModalBody>
                        <input
                            style={{
                                width: '97%',
                                height: '30px',
                                outline: 'none',
                                border: 'none',
                            }}
                            id="pregenerating"
                            value={''}
                        />
                    </ModalBody>
                ) : (
                    <ModalBody>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h3 style={{ fontWeight: 'bold' }} />
                        </div>

                        <div id="premeeting_content" className="premeeting_content" style={{ color: 'black' }}>
                            {premeetingData && premeetingData.summary ? (
                                <div className="summary_part">
                                    <h2>Summary</h2>
                                    <p>{premeetingData.summary}</p>
                                </div>
                            ) : (
                                <div className="summary_part">
                                    <h2>No News Available</h2>
                                </div>
                            )}
                            {!isFindAndShare && (
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    {premeetingData && premeetingData.news && premeetingData.news.companyNews && (
                                        <div className="company_news" style={{ marginRight: '2%' }}>
                                            <div style={{ color: 'black' }}>
                                                {premeetingData.news.companyNews.length > 0 && <h2>Company News</h2>}
                                                {premeetingData.news.companyNews.length > 0 &&
                                                    premeetingData.news.companyNews.map(val => (
                                                        <div>
                                                            <a href={val.sourceUrl} style={{ color: 'black' }}>
                                                                <b>{val.title}</b>
                                                            </a>
                                                            <p style={{ textAlign: 'justify', color: 'black' }}>
                                                                {val.snippet}
                                                            </p>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    )}
                                    <div
                                        className="topic_and_peer_news"
                                        style={{
                                            marginLeft:
                                                premeetingData &&
                                                premeetingData.news &&
                                                premeetingData.news.companyNews &&
                                                premeetingData.news.companyNews.length > 0
                                                    ? '2%'
                                                    : '',
                                        }}
                                    >
                                        {premeetingData && premeetingData.news && premeetingData.news.topicNews && (
                                            <div className="topic_news">
                                                <div>
                                                    {premeetingData.news.topicNews.length > 0 && <h2>Topic News</h2>}
                                                    {premeetingData.news.topicNews.length > 0 &&
                                                        premeetingData.news.topicNews.map(val => (
                                                            <div>
                                                                <a href={val.sourceUrl} style={{ color: 'black' }}>
                                                                    <b>{val.title}</b>
                                                                </a>
                                                                <p style={{ textAlign: 'justify', color: 'black' }}>
                                                                    {val.snippet}
                                                                </p>
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>
                                        )}
                                        {!isFindAndShare &&
                                            premeetingData &&
                                            premeetingData.news &&
                                            premeetingData.news.peerNews && (
                                                <div className="peer_news">
                                                    <div>
                                                        {premeetingData.news.peerNews.length > 0 && <h2>Peer News</h2>}
                                                        {premeetingData.news.peerNews.length > 0 &&
                                                            premeetingData.news.peerNews.map(val => (
                                                                <div>
                                                                    <a href={val.sourceUrl} style={{ color: 'black' }}>
                                                                        <b>{val.title}</b>
                                                                    </a>
                                                                    <p style={{ textAlign: 'justify', color: 'black' }}>
                                                                        {val.snippet}
                                                                    </p>
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>
                                            )}
                                        {premeetingData &&
                                            premeetingData.news &&
                                            premeetingData.news.featuredCompanyNews && (
                                                <div className="featured_news">
                                                    <div>
                                                        {premeetingData.news.featuredCompanyNews.length > 0 && (
                                                            <h2>Featured Company News</h2>
                                                        )}
                                                        {premeetingData.news.featuredCompanyNews.length > 0 &&
                                                            premeetingData.news.featuredCompanyNews.map(val => (
                                                                <div>
                                                                    <a href={val.sourceUrl} style={{ color: 'black' }}>
                                                                        <b>{val.title}</b>
                                                                    </a>
                                                                    <p style={{ textAlign: 'justify', color: 'black' }}>
                                                                        {val.snippet}
                                                                    </p>
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </ModalBody>
                )}
            </Modal>

            <Modal isOpen={emailModal} toggle={() => setEmailModal(!emailModal)}>
                <ModalHeader>
                    <strong>Email</strong>
                </ModalHeader>

                <ModalBody>
                    <Form onSubmit={e => sendingMail(e)}>
                        <Label>Email-ID</Label>
                        <Input type="email" name="email" />
                        <Button
                            type="submit"
                            variant="primary"
                            disabled={showSpinner ? true : false}
                            style={{ marginTop: '10px', float: 'right', textAlign: 'center' }}
                        >
                            {showSpinner && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ margin: '1px 5px 0px' }}
                                />
                            )}
                            Send Email
                        </Button>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}
