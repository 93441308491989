import { createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import rootReducer from './reducers'
import reduxThunk from 'redux-thunk'
import {
    compose,
    // ...
} from 'redux'

let middleware = [reduxThunk]
if (process.env.NODE_ENV === 'development') {
    middleware = [...middleware, createLogger()]
}

const composeEnhancers = compose(
    applyMiddleware(...middleware)
    // window.__REDUX_DEVTOOLS_EXTENSION_ && window.__REDUX_DEVTOOLS_EXTENSION_()
)

const store = createStore(rootReducer, {}, composeEnhancers)

export default store
