import React from 'react'
import { NavLink } from 'react-router-dom'

export default function FirmCreatedModal(props) {
    const firmId = props.createdFirmId
    const countryId = props.selectedCountry
        .filter(value => value.type === 'Country')
        .map(value => {
            return parseInt(value.value.split('_')[1])
        })

    return (
        <div className="firmCreatedModal__container">
            <h1>The firm has been created! Would you like to add a user to this firm?</h1>
            <NavLink
                to={`/app/admin/add-enduser/?firm=${firmId}&country=${countryId}&subType=Firm`}
                className="firmCreatedModal__yesButton"
            >
                Yes
            </NavLink>
            <NavLink to="/app/admin/firm/" className="firmCreatedModal__noButton">
                No
            </NavLink>
        </div>
    )
}
