import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import BaseScreen from '../BaseScreen/BaseScreen'
import Page2Form from './Page2Form/Page2Form'
import './AddNews.css'
import { getTrans } from '../../utils'

class AddNews extends Component {
    state = {
        formData: {
            sourceLink: '',
            source: 'Webhose',
            companies: [],
            title: '',
            abstract: '',
            date: new Date(),
            industry: [],
            sector: [],
            categories: [],
            opportunity: '',
            country: [],
            regions: [],
            provinces: [],
            selectedOpportunities: [],
            linkedInGroups: [],
            featured: false,
            subscription: false,
            topics: [],
            source_name: '',
            subcategories: [],
        },
        allCategories: [],
        allIndustries: [],
        allSectors: [],
        allCompanies: [],
        allRegions: [],
        allLinkedInGroups: [],
        allTopics: [],
        allSubCategoriesOnly: [],
        regionHierarchy: [],
        selectedRegCoPro: [],
    }

    componentDidMount() {
        this.getAllCategories()
        this.getIndustriesVerticalsSectors()
        this.getAllCompanies()
        this.getRegions()
        this.getLinkedInGroups()
        this.getAllTopics()
        this.getAllSubCategories()
        this.getRegionHierarchy()
    }

    getAllCategories = async () => {
        await axios.get('/news/categories/').then(response => {
            this.setState({
                allCategories: response.data.results,
            })
        })
    }

    getAllSubCategories = async () => {
        await axios
            .get('/news/all-subcategories/')
            .then(res => {
                this.setState({
                    allSubCategoriesOnly: res.data,
                })
            })
            .catch(err => console.log(err))
    }

    getIndustriesVerticalsSectors = async () => {
        await axios.get(`/news/all-industries-sectors/`).then(response => {
            const updatedIndustries = []
            response.data.forEach(item => {
                if (item.nick_name) {
                    const tempObj = { ...item }
                    tempObj.label = item.nick_name
                    updatedIndustries.push(tempObj)
                } else {
                    updatedIndustries.push(item)
                }
            })
            this.setState({
                allIndustries: updatedIndustries.filter(item => item.type === 'Industry'),
                allSectors: updatedIndustries.filter(item => item.type === 'Sector'),
            })
        })
    }

    getAllCompanies = async () => {
        await axios.get(`/accounts/news/companies/`).then(response => {
            this.setState({
                allCompanies: response.data.companies,
            })
        })
    }

    getRegions = () => {
        axios.get('/news/all-regions/').then(response => {
            this.setState({
                allRegions: response.data,
            })
        })
    }

    getLinkedInGroups = () => {
        axios.get(`/core/linkedin-groups/`).then(response => {
            const mappedData = response.data.map(item => {
                return {
                    label: item.group_name,
                    value: item.id,
                    industry: item.industry,
                    url: item.url,
                }
            })
            this.setState({ allLinkedInGroups: mappedData })
        })
    }

    getAllTopics = () => {
        axios.get('/news/topics/').then(response => {
            const remappedTopics = response.data.map(item => {
                return { value: item.id, label: item.name }
            })
            this.setState({ allTopics: remappedTopics })
        })
    }

    getRegionHierarchy = () => {
        axios.get('/news/region-countries-provinces/').then(response => {
            this.setState({
                regionHierarchy: response.data.map(item => ({
                    ...item,
                    id: item.type + '_' + item.id,
                    countries: item.countries
                        ? item.countries.map(cty => ({
                              ...cty,
                              id: cty.type + '_' + cty.id,
                              provinces: cty.provinces
                                  ? cty.provinces.map(pro => ({
                                        ...pro,
                                        id: pro.type + '_' + pro.id,
                                    }))
                                  : [],
                          }))
                        : [],
                })),
            })
        })
    }

    handleFormUpdate = (field, data) => {
        let formDataCopy = this.state.formData
        formDataCopy[field] = data
        this.setState({
            formData: formDataCopy,
        })
    }

    handleIndustryChange = industry => {
        let industryTemp = industry
        if (industry === null) {
            industryTemp = []
        }
        this.setState({
            formData: {
                ...this.state.formData,
                industry: industryTemp,
            },
        })
    }
    handleSectorChange = sector => {
        this.setState({
            formData: {
                ...this.state.formData,
                sector: sector,
            },
        })
    }
    handleCompanyChange = company => {
        this.setState({
            formData: {
                ...this.state.formData,
                companies: company,
            },
        })
    }
    handleRegionChanges = cty => {
        this.setState({
            formData: {
                ...this.state.formData,
                country: cty,
            },
        })
    }
    handleLinkedInChange = groups => {
        if (groups === null) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    linkedInGroups: [],
                },
            })
        } else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    linkedInGroups: groups,
                },
            })
        }
    }
    handleTopicChange = topic => {
        if (topic === null) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    topics: [],
                },
            })
        } else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    topics: topic,
                },
            })
        }
    }

    handleSubCategoryChange = (sub, subId) => {
        let subCopy = [...this.state.formData.subcategories]
        let formCopy = { ...this.state.formData }
        if (subCopy.find(arr => arr.name === sub)) {
            subCopy = subCopy.filter(arr => arr.name !== sub)
            formCopy.subcategories = subCopy
        } else {
            let newCat = {
                id: subId,
                name: sub,
            }
            subCopy.push(newCat)
            formCopy.subcategories = subCopy
        }
        this.setState({ formData: formCopy })
    }

    handleCategoryChange = (catOrSub, cat, catId, catIndex, sub, subId) => {
        let catCopy = [...this.state.formData.categories]
        let formCopy = { ...this.state.formData }

        if (catOrSub === 'cat') {
            if (catCopy.find(arr => arr.name === cat)) {
                catCopy = catCopy.filter(arr => arr.name !== cat)
                formCopy.categories = catCopy
            } else {
                catCopy.push({
                    name: cat,
                    id: catId,
                    subcategories: [],
                })
                formCopy.categories = catCopy
            }
        } else if (catOrSub === 'sub') {
            if (this.state.formData.categories.findIndex(arr => arr.name === cat) === -1) {
                catCopy.push({
                    id: catId,
                    name: cat,
                    subcategories: [],
                })
            }

            let newCatIndex = catCopy.findIndex(arr => arr.id === catId)
            if (catCopy[newCatIndex].subcategories.find(arr => arr.id === subId)) {
                let newSubcategories = catCopy[newCatIndex].subcategories.filter(arr => arr.id !== subId)
                catCopy[newCatIndex].subcategories = newSubcategories
                formCopy.categories = catCopy
            } else {
                catCopy[newCatIndex].subcategories.push({ name: sub, id: subId })
                formCopy.categories = catCopy
            }
        }
        this.setState({ formData: formCopy })
    }

    handleOppChange = oppObj => {
        let newSelectedOpportunities = [...this.state.formData.selectedOpportunities]
        if (newSelectedOpportunities.findIndex(arr => arr.id === oppObj.id) === -1) {
            newSelectedOpportunities.push(oppObj)
        } else {
            newSelectedOpportunities = newSelectedOpportunities.filter(opp => opp.id !== oppObj.id)
        }
        console.log(newSelectedOpportunities)
        this.setState({ formData: { ...this.state.formData, selectedOpportunities: newSelectedOpportunities } })
    }

    handleOppTextChange = (oppId, value) => {
        let oppToEdit = this.state.formData.selectedOpportunities.find(arr => arr.id === oppId)
        let oppIndex = this.state.formData.selectedOpportunities.findIndex(arr => arr.id === oppId)
        oppToEdit.default_sentence = value
        let selectedCopy = [...this.state.formData.selectedOpportunities]
        selectedCopy[oppIndex] = oppToEdit
        this.setState({ formData: { ...this.state.formData, selectedOpportunities: selectedCopy } })
    }

    handleCreateNewsStory = () => {
        let subCatArr = []
        const { formData } = this.state
        formData.subcategories.forEach(cat => {
            subCatArr.push(cat.id)
        })
        let indArray = []
        formData.industry.forEach(ind => indArray.push(ind.value))
        let secArray = []
        formData.sector.forEach(ind => secArray.push(ind.value))
        let companyArray = []
        formData.companies.forEach(comp => companyArray.push(comp.value))
        let regionArray = []
        formData.country.forEach(reg => regionArray.push(reg.value))
        const rawNewsObj = {
            title: formData.title,
            snippet: formData.abstract,
            content: formData.abstract,
            source: formData.sourceLink,
            content_type: formData.source,
            date: formData.date,
            discarded: false,
            discarded_date: null,
            companies: companyArray,
            tags: [],
            subcategory: subCatArr,
            discarded_reasons: [],
            industries: indArray,
            sectors: secArray,
            country: this.state.selectedRegCoPro
                .filter(value => value.type === 'Country')
                .map(value => {
                    return parseInt(value.value.split('_')[1])
                }),
            regions: this.state.selectedRegCoPro
                .filter(value => value.type === 'Region')
                .map(value => {
                    return parseInt(value.value.split('_')[1])
                }),
            provinces: this.state.selectedRegCoPro
                .filter(value => value.type === 'Province')
                .map(value => {
                    return parseInt(value.value.split('_')[1])
                }),
            opportunity: formData.selectedOpportunities,
            linkedin_groups: formData.linkedInGroups.map(item => item.value),
            featured: formData.featured,
            subscription: formData.subscription,
            under_review: false,
            topics: this.state.formData.topics.map(item => item.value),
            source_name: this.state.formData.source_name,
        }
        axios
            .post(`/core/add-publish-raw-news/`, rawNewsObj)
            .then(response => {
                toast(getTrans('News created'))
                const formData = {
                    sourceLink: '',
                    source: 'Webhose',
                    companies: [],
                    title: '',
                    abstract: '',
                    date: new Date(),
                    industry: [],
                    sector: [],
                    categories: [],
                    subcategories: [],
                    selectedOpportunities: [],
                    country: [],
                    regions: [],
                    provinces: [],
                    linkedInGroups: [],
                    featured: false,
                    subscription: false,
                    under_review: false,
                    topics: [],
                    source_name: '',
                }
                this.setState({ formData: formData })
            })
            .catch(error => {
                console.log('create news error', error.response)
                toast(getTrans('Error creating News. Please try Again.'))
            })
    }

    handleRegionChange = country => {
        // 1 = United States, 2 = Canada, 3 = All
        if (this.state.formData.country === null) {
            this.setState({ formData: { ...this.state.formData, country: country } })
        } else if (this.state.formData.country === 1 && country === 1) {
            this.setState({ formData: { ...this.state.formData, country: null } })
        } else if (this.state.formData.country === 2 && country === 2) {
            this.setState({ formData: { ...this.state.formData, country: null } })
        } else if (this.state.formData.country === 1 && country === 2) {
            this.setState({ formData: { ...this.state.formData, country: 3 } })
        } else if (this.state.formData.country === 2 && country === 1) {
            this.setState({ formData: { ...this.state.formData, country: 3 } })
        } else if (this.state.formData.country === 3 && country === 2) {
            this.setState({ formData: { ...this.state.formData, country: 1 } })
        } else if (this.state.formData.country === 3 && country === 1) {
            this.setState({ formData: { ...this.state.formData, country: 2 } })
        }
    }

    setSection = props => {
        this.setState({ selectedRegCoPro: props })
    }

    render() {
        return (
            <BaseScreen>
                <div className="addNews_headerContainer">
                    <p>To Create your news item fill the information bellow</p>
                </div>

                <div className="addNews__Body">
                    <div>
                        <Page2Form
                            allCategories={this.state.allCategories}
                            allIndustries={this.state.allIndustries}
                            allSectors={this.state.allSectors}
                            allCompanies={this.state.allCompanies}
                            formData={this.state.formData}
                            allRegions={this.state.allRegions}
                            allLinkedInGroups={this.state.allLinkedInGroups}
                            allTopics={this.state.allTopics}
                            allSubCategoriesOnly={this.state.allSubCategoriesOnly}
                            regionHierarchy={this.state.regionHierarchy}
                            selectedRegCoPro={this.state.selectedRegCoPro}
                            setSection={this.setSection}
                            handleFormUpdate={this.handleFormUpdate}
                            handleIndustryChange={this.handleIndustryChange}
                            handleSectorChange={this.handleSectorChange}
                            handleCompanyChange={this.handleCompanyChange}
                            handleLinkedInChange={this.handleLinkedInChange}
                            handleCategoryChange={this.handleCategoryChange}
                            handleRegionChange={this.handleRegionChange}
                            handleRegionChanges={this.handleRegionChanges}
                            handleTopicChange={this.handleTopicChange}
                            userInfo={this.props.userInfo}
                            handleOppChange={this.handleOppChange}
                            handleOppTextChange={this.handleOppTextChange}
                            handleSubCategoryChange={this.handleSubCategoryChange}
                        />
                        <div className="addNews__backAndNextButtonsRow">
                            <button
                                className={
                                    this.state.formData.sourceLink === '' ||
                                    this.state.formData.title === '' ||
                                    this.state.formData.abstract === '' ||
                                    this.state.formData.subcategories.length === 0
                                        ? 'addNews__nextButton-inactive'
                                        : 'addNews__nextButton'
                                }
                                onClick={
                                    this.state.formData.sourceLink === '' ||
                                    this.state.formData.title === '' ||
                                    this.state.formData.abstract === '' ||
                                    this.state.formData.subcategories.length === 0
                                        ? null
                                        : this.handleCreateNewsStory
                                }
                            >
                                Create News
                            </button>
                        </div>
                    </div>
                </div>
            </BaseScreen>
        )
    }
}

const mapStateToProps = state => ({
    userInfo: state.auth.user,
})

export default connect(mapStateToProps)(AddNews)
