import React, { PureComponent } from 'react'
import axios from 'axios'
import store from './Store'
import { LOGIN_USER } from './actions/types'
import { getCookie } from './utils'
import { Redirect } from 'react-router-dom'

class AppWrapper extends PureComponent {
    state = {
        loginError: false,
        loading: true,
    }

    componentDidMount() {
        const token = getCookie('csrftoken')

        if (token) {
            axios
                .get('/accounts/auth/user/')
                .then(userResponse => {
                    const user = {
                        ...userResponse.data,
                    }
                    store.dispatch({
                        type: LOGIN_USER,
                        payload: user,
                    })
                })
                .then(() => {
                    this.setState({ loading: false })
                })
                .catch(err => {
                    if (err.response.statusText === 'Unauthorized') {
                        this.setState({ loginError: true, loading: false })
                    }
                })
        }
    }

    render() {
        if (this.state.loading) return null
        if (this.state.loginError) {
            return <Redirect to={{ pathname: '/', state: { from: window.location.pathname } }} />
        } else {
            return <div>{this.props.children}</div>
        }
    }
}

export default AppWrapper
