export const getTrans = (string = '') => {
    return string
}

export function getCookie(name) {
    const value = '; ' + document.cookie
    const parts = value.split('; ' + name + '=')
    if (parts.length === 2)
        return parts
            .pop()
            .split(';')
            .shift()
}

export function formatMoney(amount, decimalCount = 2, decimal = '.', thousands = ',') {
    try {
        decimalCount = Math.abs(decimalCount)
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount

        const negativeSign = amount < 0 ? '-' : ''

        let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString()
        let j = i.length > 3 ? i.length % 3 : 0

        return (
            negativeSign +
            (j ? i.substr(0, j) + thousands : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
            (decimalCount
                ? decimal +
                  Math.abs(amount - i)
                      .toFixed(decimalCount)
                      .slice(2)
                : '')
        )
    } catch (e) {
        console.log(e)
    }
}

export function chunk(arr = [], len = 3) {
    let chunks = []
    let i = 0
    let n = arr.length

    while (i < n) {
        chunks.push(arr.slice(i, (i += len)))
    }

    return chunks
}

export function delete_cookie(name) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}
