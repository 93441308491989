import React, { Component } from 'react'
import { getFullFormattedDate } from '../../utils/dateHelpers'
import linkIcon from '../../assets/icons/linkIcon.svg'
import './NewsEditCard2020.css'
import axios from 'axios'
import { toast } from 'react-toastify'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import DuplicateNewsItems from './DuplicatedNewsItems'
import duplicateImg from '../../assets/icons/connectingvector.svg'

const customStyles = {
    multiValue: (provided, state) => {
        const typeBackgroundColors = {
            // Sector: '#197bbd',
            // Industry: '#fbab00',
            Sector: 'lightgray',
            Industry: 'lightgray',
        }

        const type = state.data.type
        return {
            ...provided,
            background: (type && typeBackgroundColors[type]) || 'lightgray',
            color: 'black',
            // borderRadius: '15px',
            // margin: '5px',
            // padding: '2px',
        }
    },
}

export default class NewSearchQueryResult extends Component {
    state = {
        title: this.props.EachrawNews.title,
        snippet: this.props.EachrawNews.snippet,
        practice: this.props.EachrawNews.practices,
        industries: this.props.EachrawNews.industries
            ? this.props.EachrawNews.industries.map(val => {
                  return { value: val.id, label: val.label, type: 'Industry' }
              })
            : [],
        sectors: this.props.EachrawNews.sectors
            ? this.props.EachrawNews.sectors.map(val => {
                  return { value: val.id, label: val.label, type: 'Sector' }
              })
            : [],
        region:
            this.props.usertype !== 'Regular User' || this.props.usertype !== 'Power User'
                ? this.props.EachrawNews.countries && this.props.EachrawNews.countries.length > 0
                    ? this.props.allRegions.filter(reg =>
                          this.props.EachrawNews.countries.some(val => reg.label === val.name)
                      )
                    : []
                : this.props.EachrawNews.countries,
        companies:
            //  this.props.allCompanies
            //     ? this.props.allCompanies.filter(reg =>
            //           this.props.EachrawNews.companies.some(val => reg.label === val.name)
            //       )
            //     : [],
            this.props.EachrawNews.companies
                ? this.props.EachrawNews.companies.map(val => {
                      return { value: val.id, label: val.name }
                  })
                : [],
        linkedInGroups:
            this.props.allLinkedInGroups && this.props.EachrawNews.linkedin_groups
                ? this.props.allLinkedInGroups.filter(group =>
                      this.props.EachrawNews.linkedin_groups.includes(group.value)
                  )
                : [],
        modalOpen: false,
        selectedOpportunities: this.props.isPublishedNews ? this.props.EachrawNews.opportunities : [],
        featured: this.props.EachrawNews.featured ? this.props.EachrawNews.featured : false,
        selectedLawFirms: this.props.EachrawNews.law_firms ? this.props.EachrawNews.law_firms : [],
        selectedTopics: this.props.EachrawNews.topics
            ? this.props.EachrawNews.topics.map(val => {
                  return { value: val.id, label: val.name }
              })
            : [],

        collapsed: this.props.collapsed,
        subcategory:
            this.props.usertype !== 'Regular User' || this.props.usertype !== 'Power User'
                ? this.props.EachrawNews.subcategories
                    ? this.props.allSubCategories.filter(sub =>
                          this.props.EachrawNews.subcategories.some(val => sub.label === val.name)
                      )
                    : []
                : this.props.EachrawNews.subcategories,
        defaultIndSec: [
            ...(this.props.EachrawNews.industries
                ? this.props.EachrawNews.industries.map(val => {
                      return { value: val.id, label: val.name, type: 'Industry' }
                  })
                : []),
            ...(this.props.EachrawNews.sectors
                ? this.props.EachrawNews.sectors.map(val => {
                      return { value: val.id, label: val.name, type: 'Sector' }
                  })
                : []),
        ],
        selectAll: this.props.selectAll,
        usertype: this.props.usertype,
        currentPath: window.location.pathname,
        defaultCompanyData: this.props.allCompanies,
        otherSourcesOpen: false,
        id: this.props.EachrawNews.id,
        index_name: this.props.EachrawNews.index_name,
        summaryLoader: false,
        alreadyGenerated: false,
        regionCountryProvince: [
            ...(this.props.EachrawNews.countries ? this.props.EachrawNews.countries : []),
            ...(this.props.EachrawNews.regions ? this.props.EachrawNews.regions : []),
            ...(this.props.EachrawNews.provinces ? this.props.EachrawNews.provinces : []),
        ],
        duplicateCollapsed: this.props.duplicateCollapsed,
    }

    componentDidUpdate = prevProps => {
        if (prevProps.allTopics !== this.props.allTopics) {
            this.setState({
                selectedTopics: this.props.allTopics.filter(top => this.props.EachrawNews.topics.includes(top.value)),
            })
        }
        if (prevProps.allRegions !== this.props.allRegions) {
            this.setState({
                region: this.props.allRegions.filter(reg =>
                    this.props.EachrawNews.countries.some(val => reg.label === val.name)
                ),
            })
        }
        if (prevProps.selectAll !== this.props.selectAll) {
            this.setState({ isStorySelected: this.props.selectAll })
            this.prepareAlteredStoryHandler('select_all')
        }
        if (prevProps.allCompanies !== this.props.allCompanies) {
            this.setState({
                companies: this.props.EachrawNews.companies
                    ? this.props.EachrawNews.companies.map(val => {
                          return { value: val.id, label: val.name }
                      })
                    : [],
            })
        }
        if (prevProps.collapsed !== this.props.collapsed) {
            this.setState({ collapsed: this.props.collapsed })
        }
        if (prevProps.currentPath !== this.props.currentPath) {
            this.setState({ currentPath: this.props.currentPath })
        }
        if (prevProps.defaultCompanyData) {
            this.setState({ defaultCompanyData: this.props.allCompanies })
        }
        if (prevProps.duplicateCollapsed !== this.props.duplicateCollapsed) {
            this.setState({ duplicateCollapsed: this.props.duplicateCollapsed })
        }
    }

    prepareAlteredStoryHandler = value => {
        let alteredStory = this.props.EachrawNews
        alteredStory.title = this.state.title
        alteredStory.snippet = this.state.snippet
        alteredStory.industries = this.state.defaultIndSec
            .filter(val => val.type === 'Industry')
            .map(val => {
                return { id: val.value, name: val.label }
            })
        alteredStory.sectors = this.state.defaultIndSec
            .filter(val => val.type === 'Sector')
            .map(val => {
                return { id: val.value, name: val.label }
            })
        if (this.state.currentPath !== '/app/search/') {
            alteredStory.countries = this.state.region.map(val => {
                return { id: val.value, name: val.label }
            })
        }
        alteredStory.companies = this.state.companies.map(val => {
            return { id: val.value, name: val.label }
        })
        if (this.state.currentPath !== '/app/search/') {
            alteredStory.subcategories = this.state.subcategory.map(val => {
                return { id: val.value, name: val.label }
            })
        }
        alteredStory.topics = this.state.selectedTopics.map(val => {
            return { id: val.value, name: val.label }
        })
        if (value !== 'select_all') this.props.addToSelection(alteredStory)
        const updateSnippet = newSnippet => {
            alteredStory.snippet = newSnippet
        }

        this.generateSummary(alteredStory.index_name, alteredStory.id, alteredStory.snippet, updateSnippet)
    }

    handleCompanyChange = company => {
        if (company === null) {
            this.setState({
                companies: [],
            })
        } else {
            this.setState({
                companies: company,
            })
        }
    }
    handleTopicChange = topic => {
        if (topic === null) {
            this.setState({
                selectedTopics: [],
            })
        } else {
            this.setState({
                selectedTopics: topic,
            })
        }
    }

    handleRegionChanges = regi => {
        if (regi === null) {
            this.setState({
                region: [],
            })
        } else {
            this.setState({
                region: regi,
            })
        }
    }

    handleSecInd = indSec => {
        if (indSec === null) {
            this.setState({
                defaultIndSec: [],
            })
        } else {
            this.setState({
                defaultIndSec: indSec,
            })
        }
    }

    handleSub = sub => {
        if (sub === null) {
            this.setState({
                subcategory: [],
            })
        } else {
            this.setState({
                subcategory: sub,
            })
        }
    }

    handleSaveStoryForRaw = () => {
        const ind = this.state.defaultIndSec.filter(val => val.type === 'Industry')
        const sec = this.state.defaultIndSec.filter(val => val.type === 'Sector')
        let newsObj = {
            id: this.props.EachrawNews.id,
            [this.state.currentPath === '/app/raw-search/' ? 'title' : 'published_title']:
                this.state.title !== this.props.EachrawNews.title && this.state.title,
            [this.state.currentPath === '/app/raw-search/' ? 'snippet' : 'published_snippet']:
                this.state.snippet !== this.props.EachrawNews.snippet && this.state.snippet,
            [this.state.currentPath === '/app/raw-search/'
                ? 'subcategories'
                : 'published_subcategories']: this.state.subcategory.map(val => {
                return { id: val.value, name: val.label }
            }),
            [this.state.currentPath === '/app/raw-search/'
                ? 'industries'
                : 'published_industries']: this.state.defaultIndSec
                .filter(val => val.type === 'Industry')
                .map(val => {
                    return { id: val.value, name: val.label }
                }),
            [this.state.currentPath === '/app/raw-search/' ? 'sectors' : 'published_sectors']: this.state.defaultIndSec
                .filter(val => val.type === 'Sector')
                .map(val => {
                    return { id: val.value, name: val.label }
                }),
            [this.state.currentPath === '/app/raw-search/'
                ? 'companies'
                : 'published_companies']: this.state.companies.map(val => {
                return { id: val.value, name: val.label }
            }),
            [this.state.currentPath === '/app/raw-search/'
                ? 'countries'
                : 'published_countries']: this.state.region.map(val => {
                return { id: val.value, name: val.label }
            }),
            [this.state.currentPath === '/app/raw-search/'
                ? 'topics'
                : 'published_topics']: this.state.selectedTopics.map(val => {
                return { id: val.value, name: val.label }
            }),
            index_name: this.props.EachrawNews.index_name,
        }
        console.log('save', newsObj)

        const filteredObject = {}

        for (const key in newsObj) {
            if (newsObj[key] !== false) {
                filteredObject[key] = newsObj[key]
            }
        }
        if (this.state.currentPath === '/app/raw-search/') {
            filteredObject['published'] = false
        } else {
            filteredObject['published'] = true
            filteredObject['is_publish'] = false
        }

        console.log('final', filteredObject)
        axios
            .post(`/news/edit-publish-delete-news/`, {
                news: [
                    {
                        doc: filteredObject,
                    },
                ],
            })
            .then(response => {
                toast('Story has been updated')
            })
            .catch(err => {
                toast('Error saving story')
            })
    }

    copyToClipboard = source => {
        const valueToCopy = source

        const tempInput = document.createElement('input')
        tempInput.value = valueToCopy
        document.body.appendChild(tempInput)
        tempInput.select()
        document.execCommand('copy')
        document.body.removeChild(tempInput)
    }

    generateSummary = (index, id, snippet, callback) => {
        if (index && index.includes('level0') && !this.state.alreadyGenerated) {
            this.setState({ summaryLoader: true })
            this.props.generateSummary && this.props.generateSummary(true)

            const payload = {
                id: id,
                snippet: snippet,
            }

            axios
                .post('/core/summarise-article/', payload)
                .then(res => {
                    this.setState({ snippet: res.data })
                    this.setState({ alreadyGenerated: true })
                    if (callback) {
                        callback(res.data) // Call the callback with the new snippet
                    }
                })
                .catch(err => console.log(err))
                .finally(() => {
                    this.setState({ summaryLoader: false })
                    this.props.generateSummary && this.props.generateSummary(false)
                })
        }
    }

    render() {
        const {
            id,
            source,
            source_name,
            date,
            content,
            highlight_title,
            highlight_text,
            other_sources,
            source_subscription,
        } = this.props.EachrawNews
        const { isStorySelected, isPublishedNews, isDeal, EachrawNews, isRawNews, selectedStories } = this.props
        if (this.state.collapsed && this.state.title !== undefined && this.state.title !== '') {
            return (
                <div
                    className={
                        this.props.selectedOnly ? 'd-flex  justify-content-between' : 'newsCardCollapsed__Container'
                    }
                    style={{
                        width: '100%',
                        borderRadius: '3px',
                        marginBottom: '1px',
                        background: this.props.selectedOnly ? '#F2F5F7' : 'white',
                        padding: this.props.selectedOnly ? '10px 0px' : '8px 16px',
                    }}
                >
                    {!this.props.isAssisstant && (
                        <div
                            // className="mb-sm-0"
                            style={{ width: '1%' }}
                            className="d-flex align-items-center"
                        >
                            <button
                                className={` ${
                                    isStorySelected ? 'newsCard__select-active' : 'newsCard__select'
                                } mobile-newsCard_selecte `}
                                style={{
                                    border: this.props.opencontext ? 'solid 2px gray' : 'solid 1.5px #3E5463',
                                    background: this.props.opencontext ? 'gray' : '',
                                }}
                                disabled={this.props.opencontext}
                                onClick={
                                    isStorySelected
                                        ? () => this.props.removeFromSelection(this.props.EachrawNews)
                                        : this.prepareAlteredStoryHandler
                                }
                            />
                        </div>
                    )}

                    <div
                        className="newsCardCollapsed__title w-sm-80"
                        style={{
                            width: this.props.selectedOnly ? '80%' : '50%',
                            textAlign: 'left',
                            marginLeft: this.props.selectedOnly ? '10px' : '',
                        }}
                        onClick={() => {
                            this.setState({ collapsed: false })
                        }}
                    >
                        <p
                            onClick={() => {
                                this.generateSummary(this.state.index_name, this.state.id, this.state.snippet)
                                // this.setState({ collapsed: false })
                            }}
                            className="w-sm-100 font"
                            style={{
                                fontWeight: '600',
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitLineClamp: this.props.selectedOnly ? 2 : 1,
                                WebkitBoxOrient: 'vertical',
                                width: this.props.selectedOnly ? '100%' : '100%',
                                color: '#0D4EA6',
                                fontSize: this.props.selectedOnly ? '10px' : '16px',
                                textAlign: this.props.selectedOnly ? 'justify' : '',
                            }}
                        >
                            <a
                                href={source ? source : ''}
                                style={{ color: '#0D4EA6', textDecoration: 'none', width: 'max-content' }}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {this.state.title}
                            </a>
                        </p>
                    </div>
                    {!this.props.selectedOnly && this.props.EachrawNews.score && !this.props.isMyRequestPageRunAll && (
                        <div
                            className="newsCardCollapsed_relavance font d-none d-sm-block"
                            style={{
                                textAlign: 'left',
                                cursor: 'pointer',
                            }}
                            title="Relevance score"
                        >
                            <p style={{ marginBottom: '0rem', textAlign: 'left' }}>
                                {this.props.EachrawNews.score ? this.props.EachrawNews.score + '%' : ''}
                            </p>
                        </div>
                    )}
                    {!this.props.selectedOnly && (
                        <div
                            className="newsCardCollapsed_source font d-none d-sm-block"
                            style={{
                                textAlign: 'left',
                            }}
                        >
                            <div className="d-flex align-items-center" style={{ overflow: 'hidden' }}>
                                <a
                                    href={source}
                                    style={{ color: 'black' }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="d-flex align-items-center"
                                >
                                    {source_subscription && (
                                        <img
                                            src="https://kaitongo-static.s3.amazonaws.com/insights/static/paywall.svg"
                                            style={{ width: '12px', height: '16px', marginRight: '5px' }}
                                        />
                                    )}
                                    <span
                                        style={{
                                            color: 'black',
                                            textAlign: 'left',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        {source_name
                                            ? source_name
                                            : source
                                                  .match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/gim)[0]
                                                  .replace(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?/gim, '')}
                                    </span>
                                </a>
                                <>
                                    {this.props.EachrawNews.duplicate_news &&
                                        this.props.EachrawNews.duplicate_news.length > 0 && (
                                            <span style={{ fontSize: '9px', marginLeft: '5px' }} className="d-flex">
                                                <span>+</span>
                                                <span>{this.props.EachrawNews.duplicate_news.length}</span>{' '}
                                                <span style={{ marginLeft: '3px' }}>other</span>
                                            </span>
                                        )}
                                </>
                            </div>
                        </div>
                    )}

                    {!this.props.selectedOnly && (
                        <div
                            className="newsCardCollapsed__date font d-none d-sm-block"
                            style={{
                                textAlign: 'left',
                            }}
                        >
                            <p className="newsCard20__companyDate">
                                {this.props.isSemantic ? date : getFullFormattedDate(date)}
                            </p>
                        </div>
                    )}

                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            this.generateSummary(this.state.index_name, this.state.id, this.state.snippet)
                            this.setState({ collapsed: false })
                        }}
                    >
                        <KeyboardArrowDownIcon />
                    </div>
                </div>
            )
        }

        //full view
        return (
            this.state.title !== '' &&
            this.state.title !== undefined && (
                <div style={{ margin: '1px 0px 4px 0px' }}>
                    <div style={{ width: '100%', background: this.props.selectedOnly ? '#F2F5F7' : 'white' }}>
                        <div
                            className={`d-flex ${
                                this.props.selectedOnly ? 'align-items-center' : ''
                            } justify-content-between`}
                            style={{
                                padding: this.props.selectedOnly ? '0px' : '0px 17px',
                                background: this.props.selectedOnly ? '#F2F5F7' : 'white',
                            }}
                        >
                            <div
                                className={`d-flex ${
                                    this.props.selectedOnly ? 'align-items-center' : ''
                                } justify-content-between`}
                                style={{ marginTop: this.props.selectedOnly ? '10px' : '0px', width: '100%' }}
                            >
                                {!this.props.isAssisstant && (
                                    <div
                                        className={` ${
                                            isStorySelected ? 'newsCard__select-active' : 'newsCard__select'
                                        }`}
                                        style={{
                                            border: this.props.opencontext ? 'solid 2px gray' : 'solid 1.5px #3E5463',
                                            background: this.props.opencontext ? 'gray' : '',
                                            marginTop: this.props.selectedOnly ? '0px' : '20px',
                                        }}
                                        disabled={this.props.opencontext}
                                        onClick={
                                            isStorySelected
                                                ? () => this.props.removeFromSelection(this.props.EachrawNews)
                                                : this.prepareAlteredStoryHandler
                                        }
                                    />
                                )}

                                {this.state.title && (
                                    <a
                                        href={source ? source : ''}
                                        style={{
                                            color: 'black',
                                            textDecoration: 'none',
                                            marginLeft: this.props.selectedOnly ? '20px' : '2.8%',
                                            marginTop: this.props.selectedOnly ? '0px' : '16px',
                                            width: '95%',
                                        }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <div
                                            style={{
                                                fontWeight: 600,
                                                color: '#0D4EA6',
                                                fontSize: this.props.selectedOnly ? '10px' : '15px',
                                            }}
                                            title={this.state.title}
                                            className={this.props.selectedOnly ? '' : 'font'}
                                        >
                                            {this.state.title}
                                        </div>
                                    </a>
                                )}
                            </div>

                            <div style={{ marginTop: this.props.selectedOnly ? '6px' : '15px', textAlign: 'end' }}>
                                <div style={{ cursor: 'pointer' }} onClick={() => this.setState({ collapsed: true })}>
                                    <KeyboardArrowUpIcon />
                                </div>
                            </div>
                        </div>

                        <div style={{ padding: this.props.selectedOnly ? '0px 25px' : '3px 4%' }}>
                            {this.state.snippet && this.props.selectedOnly && (
                                <>
                                    {this.state.summaryLoader ? (
                                        <div
                                            style={{
                                                padding: '15px',
                                                borderRadius: '2px',
                                                cursor: 'pointer',
                                                margin: '5px',
                                                display: 'flex',
                                                height: '100px',
                                                justifyContent: 'center',
                                                border: '1px solid black',
                                                paddingTop: '35px',
                                                fontSize: '10px',
                                            }}
                                            className="font"
                                        >
                                            <span className="round_loader" />{' '}
                                            <span style={{ marginLeft: '10px', fontSize: '10px' }} className="font">
                                                Displaying News Summary...
                                            </span>
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                marginTop: '10px',
                                                // display: '-webkit-box',
                                                // WebkitLineClamp: 2,
                                                // WebkitBoxOrient: 'vertical',
                                                // overflow: 'hidden',
                                                fontSize: '10px',
                                                textAlign: 'justify',
                                            }}
                                            title={this.state.snippet}
                                        >
                                            {this.state.snippet}
                                        </div>
                                    )}
                                </>
                            )}

                            {this.state.snippet && !this.props.selectedOnly && (
                                <>
                                    {this.state.summaryLoader ? (
                                        <div
                                            style={{
                                                padding: '15px',
                                                borderRadius: '2px',
                                                cursor: 'pointer',
                                                margin: '5px',
                                                display: 'flex',
                                                height: '100px',
                                                justifyContent: 'center',
                                                border: '1px solid black',
                                                paddingTop: '35px',
                                            }}
                                            className="font"
                                        >
                                            <span className="round_loader" />{' '}
                                            <span style={{ marginLeft: '10px' }} className="font">
                                                Generate summary...
                                            </span>
                                        </div>
                                    ) : (
                                        <div
                                            style={
                                                {
                                                    // display: '-webkit-box',
                                                    // WebkitLineClamp: 3,
                                                    // WebkitBoxOrient: 'vertical',
                                                    // overflow: 'hidden',
                                                }
                                            }
                                            className="font"
                                            title={this.state.snippet}
                                        >
                                            {this.state.snippet}
                                        </div>
                                    )}
                                </>
                            )}

                            {!this.props.selectedOnly && (source_name || source) && (
                                <div className="d-flex align-items-center" style={{ padding: '5px 0px' }}>
                                    <div className="d-flex align-items-center">
                                        <a
                                            href={source ? source : ''}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                textDecoration: 'none',
                                                color: 'rgb(13, 78, 166)',
                                            }}
                                            className="font d-flex align-items-center"
                                        >
                                            {source_name
                                                ? source_name
                                                : source
                                                      .match(
                                                          /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/gim
                                                      )[0]
                                                      .replace(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?/gim, '')}
                                        </a>
                                        {source_subscription && (
                                            <span className="font" style={{ color: 'darkorange', marginLeft: '3px' }}>
                                                <i>(May Require Subscription)</i>
                                            </span>
                                        )}
                                        {/* {this.props.EachrawNews.other_sources &&
                                            this.props.EachrawNews.other_sources.length > 0 && (
                                                <span
                                                    style={{
                                                        borderLeft: '1.5px solid lightgray',
                                                        height: '100px',
                                                        marginRight: '10px',
                                                        marginLeft: '10px',
                                                        fontSize: '10px',
                                                    }}
                                                />
                                            )} */}
                                    </div>
                                    {/* {this.props.EachrawNews.other_sources &&
                                        this.props.EachrawNews.other_sources.length > 0 &&
                                        this.props.EachrawNews.other_sources.map((val, index) => (
                                            <div>
                                                <a
                                                    href={val.source ? val.source : ''}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{
                                                        textDecoration: 'underline',
                                                        color: 'black',
                                                    }}
                                                    className="font d-flex align-items-center"
                                                >
                                                    <img
                                                        src="https://kaitongo-static.s3.amazonaws.com/insights/static/paywall.svg"
                                                        style={{ width: '12px', height: '16px', marginRight: '5px' }}
                                                    />
                                                    {val.source_name
                                                        ? val.source_name
                                                        : val.source
                                                              .match(
                                                                  /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/gim
                                                              )[0]
                                                              .replace(
                                                                  /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?/gim,
                                                                  ''
                                                              )}
                                                </a>
                                                {index !== this.props.EachrawNews.other_sources.length - 1 && (
                                                    <span
                                                        style={{
                                                            borderLeft: '1.5px solid lightgray',
                                                            height: '100px',
                                                            marginRight: '10px',
                                                            marginLeft: '10px',
                                                            fontSize: '10px',
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        ))} */}
                                </div>
                            )}
                            <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ marginTop: '5px' }}
                            >
                                {!this.props.selectedOnly && (
                                    <div
                                        className="newsCard20__companyDate font"
                                        style={{
                                            fontSize: this.props.selectedOnly ? '10px' : '14px',
                                            marginBottom: this.props.selectedOnly ? '0px' : '0px',
                                        }}
                                    >
                                        {this.props.isSemantic ? date : getFullFormattedDate(date)}
                                    </div>
                                )}
                                {this.props.selectedOnly && (source_name || source) && (
                                    <div style={{ fontSize: '10px' }}>
                                        <a
                                            href={source ? source : ''}
                                            style={{ color: 'black' }}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="d-flex align-items-center"
                                        >
                                            {source_subscription && (
                                                <img
                                                    src="https://kaitongo-static.s3.amazonaws.com/insights/static/paywall.svg"
                                                    style={{ width: '12px', height: '16px', marginRight: '5px' }}
                                                />
                                            )}
                                            {source_name
                                                ? source_name
                                                : source
                                                      .match(
                                                          /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/gim
                                                      )[0]
                                                      .replace(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?/gim, '')}
                                        </a>
                                    </div>
                                )}
                                {this.props.EachrawNews.score && (
                                    <div
                                        style={{
                                            marginTop: this.props.selectedOnly ? '0px' : '2px',
                                            marginRight: '10px',
                                            fontSize: this.props.selectedOnly ? '10px' : '14px',
                                            color: '#1C2B36',
                                        }}
                                        className="font"
                                        title="Relevance score"
                                    >
                                        <div>
                                            {!this.props.selectedOnly && <span>Relevance Score : </span>}
                                            <span title={this.props.selectedOnly ? 'Relevance Score' : ''}>
                                                {this.props.EachrawNews.score + '%'}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {!this.props.selectedOnly &&
                                this.props.EachrawNews.duplicate_news &&
                                this.props.EachrawNews.duplicate_news.length > 0 && (
                                    <>
                                        <div
                                            onClick={() =>
                                                this.setState({ duplicateCollapsed: !this.state.duplicateCollapsed })
                                            }
                                            style={{
                                                color: '#0D4EA6',
                                                cursor: 'pointer',
                                                // marginLeft: '-5px',
                                                marginTop: '5px',
                                            }}
                                            className="d-flex align-items-center"
                                        >
                                            {this.state.duplicateCollapsed ? (
                                                <KeyboardArrowRightIcon
                                                    style={{ color: '#0D4EA6', fontSize: '15px' }}
                                                />
                                            ) : (
                                                <KeyboardArrowDownIcon style={{ color: '#0D4EA6', fontSize: '15px' }} />
                                            )}
                                            <img width={'50'} height={'40'} src={duplicateImg} alt="similar_image" />
                                            <span style={{ fontSize: '14px', fontWeight: 600 }}>
                                                {this.props.EachrawNews.duplicate_news.length}
                                                {this.props.EachrawNews.duplicate_news.length === 1
                                                    ? ' Duplicate or Similar article'
                                                    : ' Duplicate or Similar articles'}
                                            </span>
                                        </div>

                                        {!this.state.duplicateCollapsed && (
                                            <>
                                                <div style={{ width: '97%', margin: 'auto' }}>
                                                    {this.props.EachrawNews.duplicate_news.map((v, i) => (
                                                        <DuplicateNewsItems
                                                            key={v.id}
                                                            EachrawNews={v}
                                                            isDuplicateSelected={
                                                                selectedStories.findIndex(arr => arr.id === v.id) === -1
                                                                    ? false
                                                                    : true
                                                            }
                                                            addToSelection={this.props.addToSelection}
                                                            removeFromSelection={this.props.removeFromSelection}
                                                            prepareAlteredStoryHandler={this.prepareAlteredStoryHandler}
                                                            lastIndex={
                                                                i === this.props.EachrawNews.duplicate_news.length - 1
                                                            }
                                                        />
                                                    ))}
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                        </div>

                        {!this.props.isAssisstant && !this.props.selectedOnly && !this.props.isMyRequestPageRunAll && (
                            <div style={{ padding: '0px 4% 10px 4%' }}>
                                <div className="newsCard20__industryAndRegion d-flex align-items-center justify-content-between">
                                    {this.state.defaultIndSec.length > 0 && (
                                        <div style={{ width: '49%' }}>
                                            <div className="newsCard20__oppHeading">
                                                <label>Sectors</label>
                                            </div>
                                            <div className="multiSelectDropDown__headingBoxAlt">
                                                <div className="newsCard20__categoriesContainerAlt">
                                                    {this.state.defaultIndSec.map(val => (
                                                        <div className="sectors_tag_value">
                                                            <p>{val.label}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {this.props.EachrawNews.subcategories &&
                                        this.props.EachrawNews.subcategories.length > 0 && (
                                            <div style={{ width: '49%' }}>
                                                <div className="newsCard20__oppHeading">
                                                    <label>Business Events</label>
                                                </div>
                                                <div className="multiSelectDropDown__headingBoxAlt">
                                                    <div className="newsCard20__categoriesContainerAlt">
                                                        {this.props.EachrawNews.subcategories &&
                                                            this.props.EachrawNews.subcategories.map(val => (
                                                                <div className="subcategoryTag">
                                                                    <p>{val.name}</p>
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                </div>

                                <div className="d-flex align-items-center justify-content-between">
                                    {this.state.companies.length > 0 && (
                                        <div className="" style={{ width: '49%', marginTop: '5px' }}>
                                            <div className="newsCard20__oppHeading">
                                                <label>Company</label>
                                            </div>
                                            <div className="multiSelectDropDown__headingBoxAlt">
                                                <div className="newsCard20__categoriesContainerAlt">
                                                    {this.state.companies.map(val => (
                                                        <div className="sectors_tag_value">
                                                            <p>{val.label}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* {this.props.EachrawNews.countries && this.props.EachrawNews.countries.length > 0 && (
                                        <div style={{ width: '49%' }}>
                                            <div className="newsCard20__oppHeading">
                                                <label>Countries</label>
                                            </div>
                                            <div className="multiSelectDropDown__headingBoxAlt">
                                                <div className="newsCard20__categoriesContainerAlt">
                                                    {this.props.EachrawNews.countries.map(val => (
                                                        <div className="sectors_tag_value">
                                                            <p>{val.name}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )} */}
                                    {this.state.regionCountryProvince && this.state.regionCountryProvince.length > 0 && (
                                        <div style={{ width: '49%' }}>
                                            <div className="newsCard20__oppHeading">
                                                <label>Countries</label>
                                            </div>
                                            <div className="multiSelectDropDown__headingBoxAlt">
                                                <div className="newsCard20__categoriesContainerAlt">
                                                    {this.state.regionCountryProvince.map(val => (
                                                        <div className="sectors_tag_value">
                                                            <p>{val.name}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="newsCard20__companyAndFeatured">
                                    {this.state.selectedTopics && this.state.selectedTopics.length > 0 && (
                                        <div style={{ width: '49%' }}>
                                            <div className="newsCard20__oppHeading">
                                                <label style={{ marginTop: '10px' }}>Topic</label>
                                            </div>
                                            <div className="multiSelectDropDown__headingBoxAlt">
                                                <div className="newsCard20__categoriesContainerAlt">
                                                    {this.state.selectedTopics.map(val => (
                                                        <div className="sectors_tag_value">
                                                            <p>{val.label}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )
        )
    }
}
