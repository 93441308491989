import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { MdNavigateNext } from 'react-icons/md'
import { NavLink } from 'react-router-dom'
import { Grid, Chip } from '@mui/material'

export default function ScheduleSucessMsg(props) {
    const { subjectLine, isSchedule = null, setActiveStep, clearSchedule, sentUsers, isMyRequest } = props
    return (
        <div style={{ margin: '20px 0px 0px 20px' }}>
            <div className="d-flex align-items-center">
                <span style={{ fontWeight: 700, fontSize: '23px', color: '#000000' }}>
                    Your Newsletter has been {isSchedule ? 'scheduled' : 'sent'}!
                </span>
                <span style={{ marginLeft: '5px' }}>
                    <CheckCircleIcon style={{ color: '#0DD58D' }} />
                </span>
            </div>
            <p>
                <span>
                    Your {subjectLine} newsletter {isSchedule ? 'is scheduled.' : 'has been sent to'}
                </span>
            </p>

            {sentUsers && sentUsers.length > 0 && (
                <Grid container direction="row" spacing={1}>
                    {sentUsers.map(user => (
                        <Grid item>
                            <Chip key={user.id} label={user.email} />
                        </Grid>
                    ))}
                </Grid>
            )}

            <div style={{ marginTop: '30px' }}>
                <p
                    style={{ textDecoration: 'underline', color: 'black', cursor: 'pointer' }}
                    onClick={() => {
                        if (isSchedule) {
                            clearSchedule()
                        } else setActiveStep(0)
                    }}
                >
                    Create more
                    <MdNavigateNext style={{ fontSize: 'larger' }} />
                </p>
                {!isMyRequest && (
                    <NavLink to="/app/request/">
                        <p style={{ textDecoration: 'underline', color: 'black' }}>
                            View my Requests <MdNavigateNext style={{ fontSize: 'larger' }} />
                        </p>
                    </NavLink>
                )}
                <NavLink to="/app/assistant/">
                    <p style={{ textDecoration: 'underline', color: 'black' }}>
                        What else can Kaitongo do for me? <MdNavigateNext style={{ fontSize: 'larger' }} />
                    </p>
                </NavLink>
            </div>
        </div>
    )
}
