import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import axios from 'axios'
import Select from 'react-select'
import DataTable from 'react-data-table-component'
import BaseScreen from '../BaseScreen/BaseScreen'
import AdminHeader from './components/AdminHeader'
import { endUserDataTableColumns as columns } from './constants/AnalystAdminConstants'
import './AnalystAdmin.css'
import { Circles } from 'react-loader-spinner'
import store from '../../Store'

export default function AnalystAdminEnduser() {
    const [allEndUsers, setAllEndUsers] = useState([])
    const [filterText, setFilterText] = useState('')
    const [subTypeFilter, setSubTypeFilter] = useState()
    const [firmFilter, setFirmFilter] = useState()
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
    const userType = store.getState().auth.user.user_type

    useEffect(() => {
        const headerTitle = document.getElementById('header_title')
        headerTitle.textContent = 'Manage Users'
    }, [])

    const filteredItems = allEndUsers
        .filter(item => item.email && item.email.toLowerCase().includes(filterText.toLowerCase()))
        .filter(item => {
            if (firmFilter && firmFilter.label) {
                return item.firm_name === (firmFilter.label === 'Firm (unassigned)' ? null : firmFilter.label)
            } else {
                return item
            }
        })
        .filter(item => {
            if (subTypeFilter && subTypeFilter.label) {
                return item.user_type === subTypeFilter.label
            } else {
                return item
            }
        })

    useEffect(() => {
        axios.get('/endusers/all-endusers/').then(response => {
            setAllEndUsers(response.data)
        })
    }, [])

    const handleClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle)
            setFilterText('')
        }
        setFirmFilter()
        setSubTypeFilter()
    }

    const unassignedFirm = { value: 'Firm (unassigned)', label: 'Firm (unassigned)' }
    let firmOptions = allEndUsers.map(item => ({ label: item.firm_name, value: item.firm_id }))
    firmOptions.push(unassignedFirm)

    const firmMap = {}
    const deDupeFirmArray = []
    firmOptions.forEach(el => {
        if (!firmMap[JSON.stringify(el)]) {
            firmMap[JSON.stringify(el)] = true
            deDupeFirmArray.push(el)
        }
    })

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: '#00193C', // Change to your desired header background color
                color: '#FFF', // Change to your desired header text color
                fontSize: '16px', // Change to your desired header font size
                height: '20px',
            },
        },
    }

    return (
        <BaseScreen>
            {allEndUsers.length > 0 ? (
                <>
                    {userType !== 'Power User' && <AdminHeader currentPage={'enduser'} />}
                    <div
                        className="adminEndUser__filterSection"
                        style={{
                            margin: userType !== 'Power User' ? '60px auto auto auto' : '0px auto auto auto',
                            width: '95%',
                        }}
                    >
                        <div className="adminEndUser__filters">
                            <div className="adminEndUser__emailFilterContainer">
                                <input
                                    id="search"
                                    type="text"
                                    placeholder="Filter By Email"
                                    className="analystAdmin__filterTextField"
                                    aria-label="Search Input"
                                    value={filterText}
                                    onChange={e => setFilterText(e.target.value)}
                                    key={'Email Filter'}
                                />
                                <div type="button" onClick={handleClear} className="analystAdmin__ClearButton">
                                    X
                                </div>
                            </div>
                            {/* {userType !== 'Power User' && ( */}
                            <Select
                                name="User Type"
                                options={[
                                    { label: 'Lite User', value: 'Lite User' },
                                    { label: 'Power User', value: 'Power User' },
                                    { label: 'Regular User', value: 'Regular User' },
                                ]}
                                placeholder="User Type"
                                onChange={value => setSubTypeFilter(value)}
                                value={subTypeFilter}
                                className="companiesAutoCompleteBox225NoMarginTop"
                            />
                            {/* )} */}
                            {userType !== 'Power User' && (
                                <Select
                                    name="Firm"
                                    options={deDupeFirmArray}
                                    placeholder="Firm"
                                    onChange={value => setFirmFilter(value)}
                                    value={firmFilter}
                                    className="companiesAutoCompleteBox225NoMarginTop"
                                />
                            )}
                        </div>

                        <NavLink to="/app/admin/add-enduser/" className="adminEndUser__newUserButton">
                            <span>Add End User +</span>
                        </NavLink>
                    </div>

                    <div className="adminEndUser__body">
                        <DataTable
                            title="End Users"
                            columns={columns.filter(item => {
                                return !(
                                    userType === 'Power User' &&
                                    (item.name === 'Firm' || item.name === 'Subscription')
                                )
                            })}
                            data={filteredItems}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle}
                            persistTableHead
                            customStyles={customStyles}
                        />
                    </div>
                </>
            ) : (
                <div style={{ marginLeft: '10px' }}>
                    <Circles key={0} type="Circles" color="#adadad" height={80} width={80} />
                </div>
            )}
        </BaseScreen>
    )
}
