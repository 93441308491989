import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Circles } from 'react-loader-spinner'
import { connect } from 'react-redux'
import BaseScreen from '../BaseScreen/BaseScreen'
//import DirectUserDashboardHeader from './components/DirectUserDashboardHeader'
import MyRelationships from './components/MyRelationships'
import Profile from './components/Profile'
import './DirectUserDashboard.css'
var lo_ = require('lodash')

const DirectUserDashboard = props => {
    const [userContacts, setUserContacts] = useState([])
    const [letters, setLetters] = useState([])
    const [onPage, setOnPage] = useState(-1)
    const [selectedPerson, setSelectedPerson] = useState({})
    const [nameFilter, setNameFilter] = useState('')
    const [contactLoading, setContactLoading] = useState(false)

    useEffect(() => {
        getAllUserContacts()
    }, [])

    const getAllUserContacts = () => {
        axios.get('/endusers/user-contacts/').then(response => {
            const contacts = response.data.people

            const lettersArray = []
            contacts.forEach(person => {
                const firstLetter = person.first_name.substring(0, 1).toUpperCase()
                if (!lettersArray.includes(firstLetter)) {
                    lettersArray.push(firstLetter)
                }
            })
            setLetters(lettersArray.sort())
            setUserContacts(lo_.orderBy(contacts, ['first_name'], ['asc']))

            if (contacts.length > 0) {
                getSingleContact(contacts[0].id)
            } else {
                setOnPage(0)
            }
        })
    }

    const getSingleContact = id => {
        setContactLoading(true)
        axios.get(`/endusers/get-contact/${id}/`).then(response => {
            setOnPage(id)
            setSelectedPerson(response.data.contact)
            setContactLoading(false)
        })
    }

    if (onPage === -1) {
        return (
            <BaseScreen>
                <div className="dirDash__container">
                    <Circles key={0} type="Grid" color="#adadad" height={80} width={80} />
                </div>
            </BaseScreen>
        )
    }

    return (
        <BaseScreen
            letters={letters}
            userContacts={
                nameFilter === ''
                    ? userContacts
                    : userContacts.filter(
                          person =>
                              person.first_name.toLowerCase().includes(nameFilter.toLowerCase()) ||
                              person.last_name.toLowerCase().includes(nameFilter.toLowerCase())
                      )
            }
            selectedPerson={selectedPerson}
            onPage={onPage}
            setOnPage={setOnPage}
            getSingleContact={getSingleContact}
            setNameFilter={setNameFilter}
        >
            <div className="dirDash__container">
                {/* <DirectUserDashboardHeader /> */}

                <div className="dirDash__body">
                    <div className="myRelationships__outer">
                        <MyRelationships
                            letters={letters}
                            userContacts={
                                nameFilter === ''
                                    ? userContacts
                                    : userContacts.filter(
                                          person =>
                                              person.first_name.toLowerCase().includes(nameFilter.toLowerCase()) ||
                                              person.last_name.toLowerCase().includes(nameFilter.toLowerCase())
                                      )
                            }
                            selectedPerson={selectedPerson}
                            onPage={onPage}
                            setOnPage={setOnPage}
                            getSingleContact={getSingleContact}
                            setNameFilter={setNameFilter}
                        />
                    </div>
                    <div className="profile__outer">
                        <Profile
                            onPage={onPage}
                            setOnPage={setOnPage}
                            selectedPerson={selectedPerson}
                            user={props.user}
                            getAllUserContacts={getAllUserContacts}
                            contactLoading={contactLoading}
                        />
                    </div>
                </div>
            </div>
        </BaseScreen>
    )
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default connect(mapStateToProps)(DirectUserDashboard)
