import React, { PureComponent } from 'react'
import { getCookie, getTrans } from '../../utils'
import AuthWrapper from '../../wrappers/AuthWrapper/AuthWrapper'
import LoginForm from '../../forms/LoginForm'
import axios from 'axios'
import { USER_TYPE } from '../../api'
import store from '../../Store'
import { LOGIN_USER } from '../../actions/types'
import { redirectUser } from '../../utils/handleRedirects'
import { withRouter } from 'react-router-dom'

//Check this tutorial for redirection when login / logout : https://tylermcginnis.com/react-router-protected-routes-authentication/

class LoginScreen extends PureComponent {
    componentDidMount() {
        const token = getCookie('csrftoken')

        if (token) {
            axios.get(USER_TYPE).then(userTypeResponse => {
                axios.get('/accounts/auth/user/').then(userResponse => {
                    const user = {
                        ...userResponse.data,
                        ...userTypeResponse.data,
                    }
                    store.dispatch({
                        type: LOGIN_USER,
                        payload: user,
                    })

                    console.log(this.props)
                    redirectUser(user, this.props.history, this.props.location.search)
                })
            })
        }
    }

    render() {
        return (
            <AuthWrapper title={getTrans('Login')}>
                <LoginForm />
            </AuthWrapper>
        )
    }
}

export default withRouter(LoginScreen)
