import React from 'react'
import moment from 'moment'
import { Circles } from 'react-loader-spinner'
import ImportContacts from './ImportContacts'
import ImportThankYou from './importComponents/ImportThankYou'
import linkedIn from '../../../assets/icons/share-linkedin-black.png'
import twitter from '../../../assets/icons/share-twitter-black.png'
import email from '../../../assets/icons/share-email-dark.png'

export default function Profile(props) {
    const { onPage, contactLoading, selectedPerson, user } = props

    if (onPage === 0) {
        return (
            <div>
                <h3 className="dirDash__columnHeader dirDash__heading">Profile</h3>
                <div className="profile__body">
                    <ImportContacts user={user} getAllUserContacts={props.getAllUserContacts} />
                </div>
            </div>
        )
    }

    if (user.content_ready === false) {
        return (
            <div>
                <h3 className="dirDash__columnHeader dirDash__heading">Profile</h3>
                <div className="profile__body">
                    <div style={{ padding: '0 80px' }}>
                        <ImportThankYou user={user} hideReturnButton={true} userNotReady={true} />
                    </div>
                </div>
            </div>
        )
    }

    if (contactLoading) {
        return (
            <div>
                <h3 className="dirDash__columnHeader dirDash__heading">Profile</h3>
                <div className="profile__body">
                    <div style={{ padding: '0 80px' }}>
                        <Circles key={0} type="Grid" color="#adadad" height={80} width={80} />
                    </div>
                </div>
            </div>
        )
    }

    if (selectedPerson.contact_ready === false) {
        return (
            <div>
                <h3 className="dirDash__columnHeader dirDash__heading">Profile</h3>
                <div className="profile__body">
                    <div style={{ padding: '0 80px' }}>
                        <ImportThankYou user={user} hideReturnButton={true} />
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div style={{ position: 'relative' }}>
                <h3 className="dirDash__columnHeader dirDash__heading">Profile</h3>
                <div className="profile__body">
                    <div>
                        <div className="profile__upperHalf">
                            <div className="profile__nameHeading">
                                {selectedPerson.first_name + ' ' + selectedPerson.last_name}
                            </div>
                            <div className="profile__info">
                                <div className="profile__info-left">
                                    <p>
                                        <strong>Company: </strong>
                                        {selectedPerson.company}
                                    </p>
                                    <p>
                                        <strong>Email: </strong>
                                        {selectedPerson.email}
                                    </p>
                                    {selectedPerson.linkedin !== '' && (
                                        <p>
                                            <strong>LinkedIn: </strong>
                                            <a href={selectedPerson.linkedin} target="_blank" rel="noopener noreferrer">
                                                {selectedPerson.linkedin}
                                            </a>
                                        </p>
                                    )}
                                </div>
                                <div className="profile__info-right">
                                    <p>
                                        <strong>Designation: </strong>
                                        {selectedPerson.position}
                                    </p>
                                </div>
                            </div>
                            <hr style={{ marginTop: 0 }} />
                            {selectedPerson.topics && selectedPerson.topics.length > 0 && (
                                <div style={{ paddingLeft: '16px' }}>
                                    <p>
                                        <strong style={{ marginRight: '8px' }}>Topics:</strong>
                                        {selectedPerson.topics.map((topic, index) => {
                                            return (
                                                <span key={'topic' + topic.id}>
                                                    {topic.name}
                                                    {selectedPerson.topics.length - 1 === index ? null : ', '}
                                                </span>
                                            )
                                        })}
                                    </p>
                                </div>
                            )}
                            <div className="profile__nameHeading">Content of interest</div>
                        </div>

                        {selectedPerson.news && selectedPerson.news.length > 0 && (
                            <div className="profile__newsContainer">
                                {selectedPerson.news.map(news => {
                                    return (
                                        <div className="profile__singleStory" key={news.id}>
                                            <div className="profile__storyColumn">
                                                <p
                                                    dangerouslySetInnerHTML={{ __html: news.title_link }}
                                                    style={{ marginBottom: '5px' }}
                                                />
                                                <p style={{ fontSize: '0.6rem', marginBottom: '8px' }}>
                                                    {moment(news.date).format('MMMM DD / YYYY')}
                                                </p>
                                                <p style={{ fontSize: '0.8rem' }}>{news.snippet}</p>
                                            </div>

                                            <div className="profile__socialColumn">
                                                <a
                                                    href={`http://www.linkedin.com/shareArticle?mini=true&url=${
                                                        news.news_url
                                                    }&title=%22${news.title}%22`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <img src={linkedIn} alt="LinkedIn" />
                                                </a>
                                                <a
                                                    href={`https://twitter.com/intent/tweet?url=${
                                                        news.news_url
                                                    }%22&text=%22${news.title}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <img src={twitter} alt="twitter" />
                                                </a>
                                                <a
                                                    href={`mailto:?to=&body=${news.title}%0D${
                                                        news.news_url
                                                    }%0D&subject=An%20article%20share%20from%20Kaitongo%20Inc.`}
                                                >
                                                    <img src={email} alt="email" />
                                                </a>
                                            </div>
                                            <hr />
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </div>
                <div className="profile__bottomPadding" />
            </div>
        )
    }
}
