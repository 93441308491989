import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { components } from 'react-select'
import './Filter.css'

export default function RegionHiearchyMultiSelect(props) {
    const [allHierRegions, setAllHierRegions] = useState([])

    const { selectedQueries, setSelectedQueries, regionHierarchy, setRegionInput } = props
    const [selectedRegion, setSelectedRegion] = useState([])
    const SIGN = props.isSignUp

    useEffect(() => {
        getAllRegionsHierarchy()
    }, [props.regionHierarchy, selectedRegion.length > 0])

    const [inputs, setInputs] = useState('')

    const getAllRegionsHierarchy = () => {
        const allReg = []
        regionHierarchy.forEach(reg => {
            const isRegionExpanded = reg.isExpanded || false

            allReg.push({
                value: reg.id,
                label: reg.name,
                // (
                //     <div>
                //         <div onClick={() => toggleRegion(reg)} className="expand-icon">
                //             {reg.name}
                //             <span
                //                 style={{
                //                     display: reg.countries.length === 0 ? 'none' : '',
                //                     marginLeft: '10px',
                //                     fontWeight: 600,
                //                 }}
                //             >
                //                 {/* {isRegionExpanded > 0 ? '-' : '+'} */}
                //             </span>
                //         </div>{' '}
                //     </div>
                // )
                type: reg.type,
                // under: reg.id,
                // state: reg.type + '_' + reg.id,
                // isExpanded: isRegionExpanded,
            })

            // if (isRegionExpanded) {
            reg.countries.forEach(cty => {
                const isCountryExpanded = cty.isExpanded || false

                allReg.push({
                    value: cty.id,
                    label: cty.name,
                    //  (
                    // <div>
                    //     <div onClick={() => toggleCountry(cty)} className="expand-icon">
                    //         {cty.name}
                    //         <span
                    //             style={{
                    //                 display: cty.provinces && cty.provinces.length === 0 ? 'none' : '',
                    //                 marginLeft: '15px',
                    //                 fontWeight: 600,
                    //             }}
                    //         >
                    //             {/* {isCountryExpanded > 0 ? '-' : '+'} */}
                    //         </span>
                    //     </div>{' '}
                    // </div>
                    // )
                    type: cty.type,
                    // under: reg.id,
                    // state: cty.type + '_' + reg.id,
                    // isExpanded: isCountryExpanded,
                })

                // if (isCountryExpanded) {
                cty.provinces &&
                    cty.provinces.forEach(pro => {
                        // allReg.push({
                        //     value: pro.id,
                        //     label: pro.name,
                        //     type: 'PROVINCE',
                        // })
                        allReg.push({
                            value: pro.id,
                            label: pro.name,
                            // (
                            //     <div>
                            //         <div className="expand-icon">
                            //             {pro.name}
                            //             <span
                            //                 style={{
                            //                     // display: cty.provinces.length === 0 ? 'none' : '',
                            //                     marginLeft: '15px',
                            //                     fontWeight: 600,
                            //                 }}
                            //             >
                            //                 {/* {isCountryExpanded > 0 ? '-' : '+'} */}
                            //             </span>
                            //         </div>{' '}
                            //     </div>
                            // )
                            type: pro.type,
                            // under: reg.id,
                            // state: pro.type + '_' + cty.id,
                            // isExpanded: isCountryExpanded,
                        })
                    })
                // }
            })
            // }
        })
        setAllHierRegions(allReg)

        if (props.isMyPreference || props.isAnalystNewsSection || props.isAnalystAdminAdd || props.isAddFirm) {
            const selReg = []

            const selRegions = props.selectedPreference.map(val => {
                return {
                    value: val.type + '_' + val.id,
                    label: val.name,
                    // (
                    //     <div>
                    //         <div className="expand-icon">
                    //             {val.name}
                    //             <span
                    //                 style={{
                    //                     // display: reg.countries.length === 0 ? 'none' : '',
                    //                     marginLeft: '10px',
                    //                     textAlign: 'end',
                    //                     marginRight: 'auto',
                    //                     fontWeight: 600,
                    //                 }}
                    //             >
                    //                 {/* {isRegionExpanded > 0 ? '-' : '+'} */}
                    //             </span>
                    //         </div>{' '}
                    //     </div>
                    // )
                    type: val.type,
                }
            })

            selReg.push(selRegions)
            setSelectedRegion(selRegions)

            // const selRegions = props.selectedPreference.map(item => ({
            //     ...item,
            //     id: item.id,
            //     type: 'Region',
            //     countries: item.countries
            //         ? item.countries.map(cty => ({
            //               ...cty,
            //               id: cty.id,
            //               type: 'Country',
            //               provinces: cty.provinces
            //                   ? cty.provinces.map(pro => ({
            //                         ...pro,
            //                         id: pro.id,
            //                         type: 'Province',
            //                     }))
            //                   : [],
            //           }))
            //         : [],
            // }))
            // selRegions.forEach(reg => {
            //     const isRegionExpanded = reg.isExpanded || false

            //     selReg.push({
            //         value: reg.type + '_' + reg.id,
            //         label: (
            //             <div>
            //                 <div onClick={() => toggleRegion(reg)} className="expand-icon">
            //                     {reg.name}
            //                     <span
            //                         style={{
            //                             display: reg.countries.length === 0 ? 'none' : '',
            //                             marginLeft: '10px',
            //                             textAlign: 'end',
            //                             marginRight: 'auto',
            //                             fontWeight: 600,
            //                         }}
            //                     >
            //                         {/* {isRegionExpanded > 0 ? '-' : '+'} */}
            //                     </span>
            //                 </div>{' '}
            //             </div>
            //         ),
            //         type: reg.type,
            //         under: reg.id,
            //         state: reg.type + '_' + reg.id,
            //         // isExpanded: true,
            //     })

            //     reg.countries.forEach(cty => {
            //         const isCountryExpanded = cty.isExpanded || false

            //         selReg.push({
            //             value: cty.type + '_' + cty.id,
            //             label: (
            //                 <div>
            //                     <div onClick={() => toggleCountry(cty)} className="expand-icon">
            //                         {cty.name}
            //                         <span
            //                             style={{
            //                                 display: cty.provinces && cty.provinces.length === 0 ? 'none' : '',
            //                                 marginLeft: '15px',
            //                                 fontWeight: 600,
            //                             }}
            //                         >
            //                             {/* {isCountryExpanded > 0 ? '-' : '+'} */}
            //                         </span>
            //                     </div>{' '}
            //                 </div>
            //             ),
            //             type: cty.type,
            //             under: reg.id,
            //             state: cty.type + '_' + reg.id,
            //             // isExpanded: true,
            //         })

            //         cty.provinces &&
            //             cty.provinces.forEach(pro => {
            //                 // allReg.push({
            //                 //     value: pro.id,
            //                 //     label: pro.name,
            //                 //     type: 'PROVINCE',
            //                 // })
            //                 selReg.push({
            //                     value: pro.type + '_' + pro.id,
            //                     label: (
            //                         <div>
            //                             <div className="expand-icon">
            //                                 {pro.name}
            //                                 <span
            //                                     style={{
            //                                         // display: cty.provinces.length === 0 ? 'none' : '',
            //                                         marginLeft: '15px',
            //                                         fontWeight: 600,
            //                                     }}
            //                                 >
            //                                     {/* {isCountryExpanded > 0 ? '-' : '+'} */}
            //                                 </span>
            //                             </div>{' '}
            //                         </div>
            //                     ),
            //                     type: pro.type,
            //                     under: reg.id,
            //                     state: pro.type + '_' + cty.id,
            //                     // isExpanded: true,
            //                 })
            //             })
            //     })
            // })
            // console.log('selreg', selReg)
            if (props.isMyPreference || props.isAnalystAdminAdd || props.isAddFirm) {
                setSelectedQueries(selRegions)
            }
            props.isAnalystNewsSection && props.setSection(selRegions)
            // setSelectedQueries(selReg)
        }
    }

    const handleOptionRegion = selected => {
        const selectedLabels = selected.map(option => option.value)
        const selectedRegionOptions = allHierRegions
            .filter(option => selectedLabels.includes(option.value))
            .map(option => option)
        // const selectedHierarchicalOptions = []
        // selectedRegionOptions.forEach(selectedOption => {
        //     if (selectedOption.type === 'Region') {
        //         selectedHierarchicalOptions.push(selectedOption)

        //         const children = allHierRegions.filter(child => child.under === selectedOption.value)
        //         selectedHierarchicalOptions.push(...children)
        //     } else if (selectedOption.type === 'Country') {
        //         selectedHierarchicalOptions.push(selectedOption)

        //         const children = allHierRegions.filter(child => child.under === selectedOption.under)
        //         console.log('couchi', children)
        //         const filteredChildren = children.filter(
        //             chi => chi.state === 'Province' || chi.state.includes(selectedOption.value)
        //         )
        //         console.log('filte', filteredChildren)

        //         selectedHierarchicalOptions.push(...filteredChildren)
        //     } else if (selectedOption.type === 'Province') {
        //         selectedHierarchicalOptions.push(selectedOption)

        //         const parentCountry = allHierRegions.filter(parent => parent.under === selectedOption.under)
        //         const filteredParent = parentCountry.filter(par => par.type === 'Country')
        //         selectedHierarchicalOptions.push(...filteredParent)
        //         console.log('cou', filteredParent)
        //     }
        // })

        // const uniqueSelectedOptions = Array.from(new Set(selectedHierarchicalOptions))

        // console.log('Selected Options:', selectedHierarchicalOptions)
        if (props.isAnalyst) {
            setSelectedQueries({ ...selectedQueries, countries: selectedRegionOptions })
        }
        if (props.isFindAndShare) {
            setSelectedQueries({
                ...selectedQueries,
                country: selected === null ? [] : selectedRegionOptions,
            })
        }
        if (props.isSignUp) {
            setSelectedQueries({ ...selectedQueries, country: selectedRegionOptions })

            if (selectedRegionOptions) {
                const reginput = selectedRegionOptions
                    // .filter(value => value.label.props.children[0].props.children[0].includes('Other'))
                    .filter(value => value.label.includes('Other'))
                    .map(value => value)

                if (reginput.length > 0) {
                    setRegionInput(true)
                } else {
                    setRegionInput(false)
                }
            }
        }
        if (props.isMyPreference || props.isAnalystAdminAdd || props.isAddFirm) {
            setSelectedQueries(selectedRegionOptions)
        }
        if (props.isAnalystNewsSection || props.isAddNews) {
            props.setSection(selectedRegionOptions)
        }
    }

    const handleInputChange = inputValue => {
        // const filteredOptions = allHierRegions
        //     .filter(option =>
        //         option.label.props.children[0].props.children[0].toLowerCase().startsWith(inputValue.toLowerCase())
        //     )
        //     .map(option => option.label.props.children[0].props.children[0])
        // return filteredOptions
        setInputs(inputValue)
    }

    const toggleRegion = region => {
        region.isExpanded = !region.isExpanded
        getAllRegionsHierarchy()
    }

    const toggleCountry = countries => {
        countries.isExpanded = !countries.isExpanded
        getAllRegionsHierarchy()
    }

    const Option = props => {
        const { data } = props
        const optionStyle = {
            paddingLeft: data.type === 'Region' ? '0' : data.type === 'Country' ? '20px' : '40px',
        }

        return (
            <div style={optionStyle}>
                <components.Option {...props}>
                    {data.type === 'Region' || data.type === 'Country' || data.type === 'Province' ? (
                        <div style={{ display: 'flex' }}>
                            {/* <input
                                style={{
                                    marginLeft: '20px',
                                }}
                                type="checkbox"
                                checked={props.isSelected}
                                onChange={() => null}
                            />{' '} */}
                            <label>{props.label}</label>
                        </div>
                    ) : null}
                </components.Option>
            </div>
        )
    }

    const MultiValue = props => (
        <components.MultiValue {...props}>
            {/* <span>{props.data.label.props.children[0].props.children[0]}</span> */}
            <span>{props.data.label}</span>
        </components.MultiValue>
    )

    const animatedComponents = makeAnimated()

    return (
        <Select
            isMulti
            options={allHierRegions.filter(option => option.type !== 'Province')}
            onInputChange={handleInputChange}
            styles={{
                placeholder: provided => ({
                    ...provided,
                    fontSize: props.isSignUp && '13px',
                }),
                control: (provided, state) => ({
                    ...provided,
                    minHeight: props.isAddFirm ? '50px' : props.isMyPreference || props.isAnalystAdminAdd ? '45px' : '',
                }),
            }}
            value={
                props.isMyPreference || props.isAnalystNewsSection || props.isAnalystAdminAdd || props.isAddFirm
                    ? selectedQueries.filter(option => option.type !== 'Province')
                    : props.isAnalyst
                    ? selectedQueries.countries
                    : selectedQueries.country
            }
            onChange={handleOptionRegion}
            placeholder={
                props.isSignUp
                    ? 'Select Country'
                    : props.isAddNews || props.isAnalystNewsSection
                    ? 'Select Region/Country'
                    : props.isMyPreference
                    ? 'Select Region/Country'
                    : 'Region/Country'
            }
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
                Option,
                MultiValue,
                animatedComponents,
            }}
            className={
                props.isAnalyst || props.isSignUp || props.isAnalystNewsSection
                    ? 'analyst_input'
                    : props.isAnalystAdminAdd
                    ? 'analyst_add'
                    : props.isMyPreference
                    ? 'preference_add'
                    : props.isAddFirm || props.isAddNews
                    ? 'firm_add'
                    : 'inputs'
            }
            style={{ marginLeft: props.isAnalyst || props.isAddFirm || props.isAddNews ? '0px' : '50px' }}
        />
    )
}
