import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Modal, ModalBody } from 'reactstrap'
import { subDays, addDays, format, endOfDay } from 'date-fns'
import {
    getIndustryString,
    getSectorString,
    getCompanyString,
    getRegionString,
    getSourceString,
    getPullTypeString,
    getTopicsString,
    getReviewString,
    getSubcategoriesIdsString,
    getSearchString,
    getProvinceString,
    getRegString,
} from './urlFilterFunctions'
import InfiniteScroll from 'react-infinite-scroller'
import { Circles } from 'react-loader-spinner'
import NoResults from '../../components/NoResults/NoResults'
import BaseScreen from '../BaseScreen/BaseScreen'
import NewsEditCardAnalyst from '../../components/NewsEditedCard/NewsEditCardAnalyst'
import NewsFeedFilterHeader from './components/NewsFeedFilterHeader'
import SendIcon from '../../assets/icons/icon-ionic-ios-send.svg'
import deleteBin from '../../assets/icons/delete.svg'
import EyeIcon from '../../assets/icons/eye.svg'
import './RawNews.css'
import { Switch, Grid, CircularProgress } from '@mui/material'
import ResultHeader from './components/ResultsHeader'
import DeleteNews from './components/DeleteNews'
var lo_ = require('lodash')

export default function RawNews() {
    const [isLoading, setIsLoading] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [selectedStories, setSelectedStories] = useState([])
    const [allCategories, setAllCategories] = useState([])
    const [allIndustries, setAllIndustries] = useState([])
    const [allCompanies, setAllCompanies] = useState([])
    const [allLinkedInGroups, setAllLinkedInGroups] = useState([])
    const [allRegions, setAllRegions] = useState([])
    const [rawNews, setRawNews] = useState({})
    const [allTopics, setAllTopics] = useState([])
    const [expandedView, setExpandedView] = useState(false)
    const [selectAll, setSelectAll] = useState(false)
    const [allSubCategoriesOnly, setAllSubCategoriesOnly] = useState([])
    const [regionHierarchy, setRegionHierarchy] = useState([])
    const [selectedNewsOnly, setSelectedNewsOnly] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        getAllRawNews().then(() => setIsLoading(false))
        getAllCategories()
        getIndustriesVerticalsSectors()
        getAllCompanies()
        getAllLinkedInGroups()
        getAllRegions()
        getAllTopics()
        getAllSubCategories()
        getRegionHierarchy()
    }, [])

    useEffect(() => {
        selectAll
            ? setSelectedStories(current => [
                  ...current,
                  ...rawNews.results.data.filter(each => !selectedStories.includes(each)),
              ])
            : setSelectedStories([])
    }, [selectAll])

    const defaultResult = response => {
        if (response.data.results !== undefined) setRawNews(response.data)
        else {
            const datas = {
                count: 0,
                next: null,
                previous: null,
                results: {
                    data: [],
                },
            }
            setRawNews(datas)
        }
    }

    const getAllRawNews = async () => {
        setIsLoading(true)
        await axios
            .get(
                `
                /news/raw-news-new/?newsStartDate=${format(subDays(new Date(), 365), 't')}&newsEndDate=${format(
                    addDays(new Date(), 1),
                    't'
                )}&newsPulledStartDate=${format(subDays(new Date(), 7), 't')}&newsPulledEndDate=${format(
                    addDays(new Date(), 1),
                    't'
                )}&industries&sectors&subcategories&companies&countries&sources&deleted=notDeleted&underReview&pullType&topics&searchText`
            )
            .then(response => {
                defaultResult(response)
                setIsLoading(false)
            })
    }
    const getAllCategories = async () => {
        await axios.get('/news/categories/').then(response => {
            setAllCategories(response.data.results)
        })
    }
    const getAllRegions = async () => {
        await axios.get('/news/all-regions/').then(response => {
            setAllRegions(response.data)
        })
    }
    const getIndustriesVerticalsSectors = async () => {
        await axios.get(`/news/all-industries-sectors/`).then(response => {
            const updatedIndustries = []
            response.data.forEach(item => {
                if (item.nick_name) {
                    const tempObj = { ...item }
                    tempObj.label = item.nick_name
                    updatedIndustries.push(tempObj)
                } else {
                    updatedIndustries.push(item)
                }
            })
            setAllIndustries(updatedIndustries)
        })
    }
    const getAllCompanies = async () => {
        await axios.get(`/news/all-companies/`).then(response => {
            setAllCompanies(response.data)
        })
    }
    const getAllLinkedInGroups = async () => {
        await axios.get(`/core/linkedin-groups/`).then(response => {
            const mappedData = response.data.map(item => {
                return {
                    label: item.group_name,
                    value: item.id,
                    industry: item.industry,
                    url: item.url,
                }
            })
            setAllLinkedInGroups(mappedData)
        })
    }

    const getAllTopics = async () => {
        await axios.get('/news/topics/').then(response => {
            let remappedTopics = response.data.map(item => {
                return { value: item.id, label: item.name }
            })
            remappedTopics = lo_.orderBy(remappedTopics, ['label'], ['asc'])
            setAllTopics(remappedTopics)
        })
    }

    const getAllSubCategories = async () => {
        await axios
            .get('/news/all-subcategories/')
            .then(res => {
                setAllSubCategoriesOnly(res.data)
            })
            .catch(err => console.log(err))
    }

    const getRegionHierarchy = async () => {
        await axios.get('/news/region-countries-provinces/').then(response => {
            setRegionHierarchy(
                response.data.map(item => ({
                    ...item,
                    id: item.type + '_' + item.id,
                    countries: item.countries
                        ? item.countries.map(cty => ({
                              ...cty,
                              id: cty.type + '_' + cty.id,
                              provinces: cty.provinces
                                  ? cty.provinces.map(pro => ({
                                        ...pro,
                                        id: pro.type + '_' + pro.id,
                                    }))
                                  : [],
                          }))
                        : [],
                }))
            )
        })
    }

    const getFilteredRawNews = selectedFilters => {
        setIsLoading(true)
        if (selectedFilters.companies === null) selectedFilters.companies = []
        if (selectedFilters.industries === null) selectedFilters.industries = []
        const countryIds = selectedFilters.countries
            .filter(item => item.type === 'Country')
            .map(item => ({
                ...item,
                value: item.value.split('_')[1],
            }))
        const regionIds = selectedFilters.countries
            .filter(item => item.type === 'Region')
            .map(item => ({
                ...item,
                value: item.value.split('_')[1],
            }))
        console.log('regio', regionIds.forEach(val => val))
        const provinceIds = selectedFilters.countries
            .filter(item => item.type === 'Province')
            .map(item => ({
                ...item,
                value: item.value.split('_')[1],
            }))
        axios
            .get(
                `/news/raw-news-new/?newsStartDate=${format(selectedFilters.date.startDate, 't')}&newsEndDate=${format(
                    endOfDay(selectedFilters.date.endDate),
                    't'
                )}&newsPulledStartDate=${format(selectedFilters.pullDate.startDate, 't')}&newsPulledEndDate=${format(
                    endOfDay(selectedFilters.pullDate.endDate),
                    't'
                )}&${getIndustryString(
                    selectedFilters.industries.filter(arr => arr.type === 'Industry')
                )}&${getSectorString(
                    selectedFilters.industries.filter(arr => arr.type === 'Sector')
                )}&subcategories${getSubcategoriesIdsString(selectedFilters.subcategories)}&${getCompanyString(
                    selectedFilters.companies
                )}&${getRegionString(countryIds)}&${getRegString(regionIds)}&${getProvinceString(
                    provinceIds
                )}&${getSourceString(selectedFilters.sources)}&deleted=${
                    selectedFilters.deleted.value
                }&underReview${getReviewString(selectedFilters.underReview.value)}&${getPullTypeString(
                    selectedFilters.pullType.value
                )}&${getTopicsString(selectedFilters.topics)}&${getSearchString(selectedFilters.textSearch)}&newsType=${
                    selectedFilters.newsType.value
                }&reason${selectedFilters.deletedReason !== '' ? '=' + selectedFilters.deletedReason.value : ''}`
            )
            .then(response => {
                setIsLoading(false)
                defaultResult(response)
            })
    }

    const handleLoadMore = () => {
        // let url = rawNews.next.replace('http://localhost:8000', '')
        axios.get(rawNews.next).then(response => {
            function filterUniqueById(arr) {
                const uniqueIds = new Set()
                return arr.filter(obj => {
                    if (!uniqueIds.has(obj.id)) {
                        uniqueIds.add(obj.id)
                        return true
                    }
                    return false
                })
            }

            const rawNewsData = filterUniqueById(rawNews.results.data)

            let newsBefore = [...rawNewsData, ...response.data.results.data]

            const uniqueData = filterUniqueById(newsBefore)

            setRawNews({
                count: response.data.count,
                next: response.data.next,
                previous: response.data.previous,
                results: { data: uniqueData },
            })
        })
    }

    const addToSelection = story => {
        setSelectedStories([...selectedStories, story])
    }
    const removeFromSelection = story => {
        let selectedCopy = selectedStories.filter(arr => arr.id !== story.id)
        setSelectedStories(selectedCopy)
    }

    const handleBatchPublishStories = str => {
        // let nullRegion = false
        // selectedStories.forEach(item => {
        //     if (item.region === null) {
        //         nullRegion = true
        //     }
        // })

        let underReview
        if (str === 'review') {
            underReview = 'underReview'
        } else {
            underReview = 'published'
        }

        if (selectedStories.length === 0) {
            toast('No stories selected')
        }
        // else if (nullRegion === true) {
        //     toast('A story is missing a region')
        // }
        else {
            let publishObject = {
                news: [],
                title: [],
                snippet: [],
                content: [],
                subcategory: [],
                country: [],
                regions: [],
                provinces: [],
                companies: [],
                industries: [],
                sectors: [],
                opportunities: [],
                linkedin_group: [],
                featured: [],
                status: [],
                topics: [],
            }
            const uniqueSelectedStories = new Set(selectedStories)
            uniqueSelectedStories.forEach(story => {
                let subCatArray = []
                story.subcategories.forEach(sub => {
                    subCatArray.push(sub.id)
                })
                publishObject.news.push(story.id)
                publishObject.title.push(story.title)
                publishObject.snippet.push(story.snippet)
                publishObject.content.push(story.content)
                publishObject.subcategory.push(subCatArray)
                publishObject.country.push(story.countries)
                publishObject.regions.push(story.region)
                publishObject.provinces.push(story.province)
                publishObject.companies.push(story.companies)
                publishObject.industries.push(story.industries)
                publishObject.sectors.push(story.sectors)
                publishObject.opportunities.push(
                    story.selectedOpportunities === undefined || null ? [] : story.selectedOpportunities
                )
                publishObject.linkedin_group.push(
                    story.linkedInGroups === undefined || null ? [] : story.linkedInGroups.map(item => item.value)
                )
                publishObject.featured.push(story.featured === undefined || null ? false : story.featured)
                publishObject.status.push(underReview)
                publishObject.topics.push(story.topics.map(item => item.value))
            })

            axios
                .post(`/core/publish-raw-news/`, publishObject)
                .then(response => {
                    if (str === 'review') {
                        toast('News marked for review')
                        let rawNewsCopy = [...rawNews.results.data]
                        let rawNewsFiltered = []
                        rawNewsCopy.forEach(story => {
                            if (publishObject.news.includes(story.id)) {
                                story.under_review = true
                            }
                            rawNewsFiltered.push(story)
                        })
                        setRawNews({ ...rawNews, results: { data: rawNewsFiltered } })
                        setSelectedStories([])
                    } else {
                        toast('News Published')
                        let rawNewsCopy = [...rawNews.results.data]
                        let rawNewsFiltered = rawNewsCopy.filter(story => !response.data.pk.includes(story.id))
                        setRawNews({ ...rawNews, results: { data: rawNewsFiltered } })
                        setSelectedStories([])
                    }
                })
                .catch(error => {
                    console.log('rawnews to news error', error.response)
                    toast('Error publishing story')
                })
        }
    }

    const handleBatchDeleteStories = () => {
        let idsToRemove = []
        selectedStories.forEach(item => {
            idsToRemove.push(item.id)
        })
        const uniqueIdsToRemove = [...new Set(idsToRemove)]

        axios
            .delete('/news/raw-news/', { data: { ids: uniqueIdsToRemove } })
            .then(response => {
                toast('News Deleted')
                let rawNewsCopy = [...rawNews.results.data]
                let rawNewsFiltered = rawNewsCopy.filter(story => !idsToRemove.includes(story.id))
                setRawNews({ ...rawNews, results: { data: rawNewsFiltered } })
                setSelectedStories([])
                setDeleteModalOpen(false)
            })
            .catch(error => {
                console.log('rawnews to news error', error.response)
                toast('Error deleting story')
            })
    }
    const NewsFilter = (
        <NewsFeedFilterHeader
            allIndustries={allIndustries}
            allCompanies={allCompanies}
            allRegions={allRegions}
            allTopics={allTopics}
            getAllRawNews={getAllRawNews}
            getFilteredRawNews={getFilteredRawNews}
            setSelectedStories={setSelectedStories}
            allCategories={allCategories}
            onRawPage={true}
            allSubCategoriesOnly={allSubCategoriesOnly}
            regionHierarchy={regionHierarchy}
        />
    )

    if (!rawNews.results) {
        return (
            <BaseScreen>
                {NewsFilter}
                {isLoading ? (
                    <Grid style={{ textAlign: 'center', marginTop: '30px' }}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <Grid container direction="column" style={{ paddingRight: '1rem' }}>
                        <Grid item alignSelf={'center'} style={{ paddingTop: '10vh' }}>
                            <NoResults />
                        </Grid>
                    </Grid>
                )}
            </BaseScreen>
        )
    }

    return (
        <BaseScreen>
            <div className="rawNews3_filterHeader">{NewsFilter}</div>

            {rawNews.results.data.length === 0 && !isLoading && (
                <Grid container direction="column" style={{ paddingRight: '1rem' }}>
                    <Grid item alignSelf={'center'} style={{ paddingTop: '10vh' }}>
                        <NoResults />
                    </Grid>
                </Grid>
            )}

            {isLoading && (
                <Grid container direction="column" style={{ paddingRight: '1rem' }}>
                    <Grid item alignSelf={'center'} style={{ paddingTop: '10vh' }}>
                        <CircularProgress />
                    </Grid>
                </Grid>
            )}

            {rawNews.results.data.length > 0 && (
                <React.Fragment>
                    <div>
                        <DeleteNews
                            deleteModalOpen={deleteModalOpen}
                            setDeleteModalOpen={setDeleteModalOpen}
                            selectedStoriesLength={selectedStories.length}
                            handleBatchDeleteStories={handleBatchDeleteStories}
                        />
                    </div>
                    <div className="newsEdit__CardsSection">
                        <Grid item>
                            <Grid container direction="row" alignItems="center" justifyContent="space-between">
                                <Grid item style={{ paddingLeft: '16px', width: '90%' }}>
                                    <React.Fragment>
                                        <ResultHeader
                                            israwNews={true}
                                            allSelected={selectAll}
                                            setAllSelected={setSelectAll}
                                            resultLength={
                                                selectedNewsOnly ? selectedStories.length : rawNews.results.data.length
                                            }
                                            resultCount={selectedNewsOnly ? selectedStories.length : rawNews.count}
                                            expanded={expandedView}
                                            selectedNewsOnly={selectedNewsOnly}
                                            setSelectedNewsOnly={setSelectedNewsOnly}
                                            setExpanded={setExpandedView}
                                        />
                                    </React.Fragment>
                                </Grid>
                            </Grid>

                            <div className="sendAndDeleteContainer">
                                <button
                                    className="sendButton"
                                    onClick={() => handleBatchPublishStories('publish')}
                                    disabled={selectedStories.length === 0}
                                >
                                    <img
                                        src={process.env.REACT_APP_MEDIA_URL + SendIcon}
                                        alt="send"
                                        className={selectedStories.length === 0 ? 'buttonIconFaded' : ''}
                                    />
                                </button>

                                <button
                                    className="deleteButton"
                                    onClick={
                                        selectedStories.length === 0
                                            ? () => toast('No Stories Selected')
                                            : () => setDeleteModalOpen(true)
                                    }
                                    disabled={selectedStories.length === 0}
                                >
                                    <img
                                        src={process.env.REACT_APP_MEDIA_URL + deleteBin}
                                        alt="delete"
                                        className={selectedStories.length === 0 ? 'buttonIconFaded' : ''}
                                    />
                                </button>

                                <button
                                    className="sendButton"
                                    style={{ marginTop: '38px' }}
                                    onClick={() => handleBatchPublishStories('review')}
                                    disabled={selectedStories.length === 0}
                                >
                                    <img
                                        src={process.env.REACT_APP_MEDIA_URL + EyeIcon}
                                        alt="review"
                                        style={{ width: '80%' }}
                                        className={selectedStories.length === 0 ? 'buttonIconFaded' : ''}
                                    />
                                </button>
                            </div>

                            <Grid item style={{ maxWidth: '99%' }}>
                                <div
                                    className="newsCardCollapsed__Container"
                                    style={{ width: '92%', display: expandedView ? 'none' : '' }}
                                >
                                    <div className="newsCardCollapsed__selectColumn">
                                        <div style={{ width: '15px' }} />
                                    </div>
                                    <div className="newsCardCollapsed__date" style={{ width: '150px' }}>
                                        <p
                                            className="newsCard20__companyDate"
                                            style={{
                                                marginLeft: '-40px',
                                                fontSize: '15px',
                                                fontWeight: '700',
                                                color: 'black',
                                            }}
                                        >
                                            Article Date
                                        </p>
                                    </div>
                                    <div className="newsCardCollapsed__date" style={{ width: '150px' }}>
                                        <p
                                            className="newsCard20__companyDate"
                                            style={{
                                                marginLeft: '-110px',
                                                fontSize: '15px',
                                                fontWeight: '700',
                                                color: 'black',
                                            }}
                                        >
                                            Pull Date
                                        </p>
                                    </div>
                                    <div className="newsCardCollapsed__title">
                                        <p
                                            style={{
                                                marginLeft: '-50px',
                                                fontSize: '15px',
                                                fontWeight: '700',
                                                color: 'black',
                                            }}
                                        >
                                            Title
                                        </p>
                                    </div>
                                    <div className="newsCardCollapsed__date">
                                        <p
                                            style={{
                                                marginLeft: '-160px',
                                                fontSize: '15px',
                                                fontWeight: '700',
                                                color: 'black',
                                            }}
                                        >
                                            Actions
                                        </p>
                                    </div>
                                </div>
                                {selectedNewsOnly ? (
                                    selectedStories.map(story => {
                                        return (
                                            <NewsEditCardAnalyst
                                                key={story.id}
                                                EachrawNews={story}
                                                isRawNews={true}
                                                selectedStories={selectedStories}
                                                isStorySelected={
                                                    selectedStories.findIndex(arr => arr.id === story.id) === -1
                                                        ? false
                                                        : true
                                                }
                                                addToSelection={addToSelection}
                                                allCategories={allCategories}
                                                allIndustries={allIndustries}
                                                allCompanies={allCompanies}
                                                allRegions={allRegions}
                                                allSubCategoriesOnly={allSubCategoriesOnly}
                                                allLinkedInGroups={allLinkedInGroups}
                                                removeFromSelection={removeFromSelection}
                                                allTopics={allTopics}
                                                collapsed={!expandedView}
                                                handleBatchPublishStories={handleBatchPublishStories}
                                                handleBatchDeleteStories={handleBatchDeleteStories}
                                                selectAll={selectAll}
                                            />
                                        )
                                    })
                                ) : (
                                    <>
                                        <InfiniteScroll
                                            pageStart={0}
                                            loadMore={handleLoadMore}
                                            hasMore={rawNews.next !== null}
                                            loader={
                                                <Circles key={0} type="Grid" color="#adadad" height={80} width={80} />
                                            }
                                        >
                                            {rawNews.results.data.map(story => {
                                                return (
                                                    <NewsEditCardAnalyst
                                                        key={story.id}
                                                        EachrawNews={story}
                                                        isRawNews={true}
                                                        selectedStories={selectedStories}
                                                        isStorySelected={
                                                            selectedStories.findIndex(arr => arr.id === story.id) === -1
                                                                ? false
                                                                : true
                                                        }
                                                        addToSelection={addToSelection}
                                                        allCategories={allCategories}
                                                        allIndustries={allIndustries}
                                                        allCompanies={allCompanies}
                                                        allRegions={allRegions}
                                                        allSubCategoriesOnly={allSubCategoriesOnly}
                                                        allLinkedInGroups={allLinkedInGroups}
                                                        removeFromSelection={removeFromSelection}
                                                        allTopics={allTopics}
                                                        collapsed={!expandedView}
                                                        handleBatchPublishStories={handleBatchPublishStories}
                                                        handleBatchDeleteStories={handleBatchDeleteStories}
                                                        regionHierarchy={regionHierarchy}
                                                        selectAll={selectAll}
                                                    />
                                                )
                                            })}
                                        </InfiniteScroll>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </div>
                </React.Fragment>
            )}
        </BaseScreen>
    )
}
