import React, { PureComponent } from 'react'
import { FormGroup, Input, Label } from 'reactstrap'
import classnames from 'classnames'
import { ErrorMessage } from 'formik'

class MyInput extends PureComponent {
    render() {
        const { name, label, placeholder, type, values, handleChange, handleBlur, errors, touched } = this.props

        return (
            <FormGroup key={name}>
                {/* <Label htmlFor={name}>{label}</Label> */}
                <p className="signup__label">
                    {label}
                    <span style={{ color: 'red' }}>*</span>
                </p>
                <Input
                    id={name}
                    placeholder={placeholder}
                    type={type}
                    value={values[name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classnames('signup__input', {
                        error: errors[name] && touched[name],
                    })}
                    autocomplete="off"
                />
                <ErrorMessage component={() => <div className="input-feedback">{errors[name]}</div>} name={name} />
            </FormGroup>
        )
    }
}

export default MyInput
