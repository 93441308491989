import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Form, Formik, Field } from 'formik'
import { Button } from 'reactstrap'
import Select from 'react-select'

import BaseScreen from '../BaseScreen/BaseScreen'
import AdminHeader from './components/AdminHeader'
import TopicsDropDown from '../../components/NewsEditedCard/TopcisDropDownAlt'

import { addContactFields as fields } from './constants/AnalystAdminConstants'

//const qs = require('query-string')

function AnalystAdminAddContact(props) {
    const [onEditPage, setOnEditPage] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [contactInfo, setContactInfo] = useState({})
    const [allTopics, setAllTopics] = useState([])
    const [allEndUsers, setAllEndUsers] = useState([])
    const [selectedEndUser, setSelectedEndUser] = useState({})
    const [selectedTopics, setSelectedTopics] = useState([])

    const userId = props.match.params.id
    //const { query } = qs.parseUrl(props.location.search)

    useEffect(() => {
        getInitialFetchData()
    }, [])

    const getInitialFetchData = async () => {
        await axios.get('/news/topics/').then(response => {
            const remappedTopics = response.data.map(item => {
                return { value: item.id, label: item.name }
            })
            setAllTopics(remappedTopics)
        })

        if (userId) {
            setOnEditPage(true)
            await axios.get(`/endusers/get-contact/${userId}/`).then(contactResponse => {
                setContactInfo(contactResponse.data.contact)
                const mappedTopics = contactResponse.data.contact.topics.map(item => {
                    return { value: item.id, label: item.name }
                })
                setSelectedTopics(mappedTopics)

                axios.get('/endusers/all-endusers/').then(userResponse => {
                    setAllEndUsers(
                        userResponse.data.map(item => {
                            return {
                                value: item.id,
                                label: item.first_name + ' ' + item.last_name + ' - ' + item.email,
                            }
                        })
                    )
                    const selectedEndUser = userResponse.data.find(
                        item => contactResponse.data.contact.contact_of === item.id
                    )
                    console.log(selectedEndUser)
                    setSelectedEndUser({
                        value: selectedEndUser.id,
                        label:
                            selectedEndUser.first_name +
                            ' ' +
                            selectedEndUser.last_name +
                            ' - ' +
                            selectedEndUser.email,
                    })
                })
            })
        } else {
            await axios.get('/endusers/all-endusers/').then(response => {
                setAllEndUsers(
                    response.data.map(item => {
                        return { value: item.id, label: item.first_name + ' ' + item.last_name + ' - ' + item.email }
                    })
                )
            })
        }
    }

    const validateEmail = mail => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
            return setEmailError(false)
        } else {
            return setEmailError(true)
        }
    }

    const handleTopicChange = topic => {
        if (topic === null) {
            setSelectedTopics([])
        } else {
            setSelectedTopics(topic)
        }
    }

    const onSubmit = values => {
        const submitObject = {
            firstName: values.first_name,
            lastName: values.last_name,
            email: values.email,
            company: values.company,
            linkedin: values.linkedIn,
            title: values.position,
            isActive: values.is_active,
            contactReady: values.contact_ready,
            topics: selectedTopics.map(item => item.value),
            enduser: selectedEndUser.value,
        }
        if (onEditPage) {
            submitObject.id = userId
        }
        axios
            .post('/endusers/contact/new/', submitObject)
            .then(response => {
                console.log(response)
                toast(onEditPage ? 'Contact Updated' : 'Contact Created')
                props.history.push('/app/admin/contacts/')
            })
            .catch(err => {
                console.log(err)
                toast.warn('Error creating contact-' + err.message)
            })
    }

    return (
        <BaseScreen>
            <AdminHeader currentPage={'addContact'} />

            <div className="adminEndUser__formBody" style={{ marginTop: '6%' }}>
                <Formik
                    enableReinitialize
                    initialValues={
                        onEditPage
                            ? {
                                  first_name: contactInfo.first_name,
                                  last_name: contactInfo.last_name,
                                  email: contactInfo.email,
                                  company: contactInfo.company,
                                  linkedIn: contactInfo.linkedin,
                                  position: contactInfo.position,
                                  is_active: contactInfo.is_active,
                                  contact_ready: contactInfo.contact_ready,
                              }
                            : {
                                  first_name: '',
                                  last_name: '',
                                  email: '',
                                  company: '',
                                  linkedIn: '',
                                  position: '',
                                  is_active: true,
                                  contact_ready: true,
                              }
                    }
                    onSubmit={onSubmit}
                >
                    {props => {
                        return (
                            <Form>
                                <div className="adminEndUser__formRow">
                                    <Field
                                        type={fields.firstName.type}
                                        name={fields.firstName.name}
                                        id={fields.firstName.name}
                                        placeholder={fields.firstName.placeholder}
                                        style={{ width: fields.firstName.width, marginRight: '20px' }}
                                        className="adminAddFirm__textInput"
                                    />
                                    <Field
                                        type={fields.lastName.type}
                                        name={fields.lastName.name}
                                        id={fields.lastName.name}
                                        placeholder={fields.lastName.placeholder}
                                        style={{ width: fields.lastName.width, marginRight: '20px' }}
                                        className="adminAddFirm__textInput"
                                    />
                                    <Field
                                        name={fields.contactReady.name}
                                        as={fields.contactReady.type}
                                        id={fields.contactReady.name}
                                        placeholder={fields.contactReady.placeholder}
                                        style={{ width: fields.contactReady.width, marginRight: '20px' }}
                                        className="adminAddFirm__textInput"
                                    >
                                        <option value="true">Is Ready</option>
                                        <option value="false">Not Ready</option>
                                    </Field>
                                </div>

                                <div className="adminEndUser__formRow">
                                    <Field
                                        type={fields.company.type}
                                        name={fields.company.name}
                                        id={fields.company.name}
                                        placeholder={fields.company.placeholder}
                                        style={{ width: fields.company.width, marginRight: '20px' }}
                                        className="adminAddFirm__textInput"
                                    />

                                    <Field
                                        type={fields.linkedIn.type}
                                        name={fields.linkedIn.name}
                                        id={fields.linkedIn.name}
                                        placeholder={fields.linkedIn.placeholder}
                                        style={{ width: fields.linkedIn.width, marginRight: '20px' }}
                                        className="adminAddFirm__textInput"
                                    />

                                    <Field
                                        type={fields.position.type}
                                        name={fields.position.name}
                                        id={fields.position.name}
                                        placeholder={fields.position.placeholder}
                                        style={{ width: fields.position.width, marginRight: '20px' }}
                                        className="adminAddFirm__textInput"
                                    />
                                </div>

                                <div className="adminEndUser__formRow" style={{ width: '66.6%' }}>
                                    <Field
                                        type={fields.email.type}
                                        name={fields.email.name}
                                        id={fields.email.name}
                                        onBlur={ev => validateEmail(ev.target.value)}
                                        placeholder={fields.email.placeholder}
                                        style={{ width: fields.email.width, marginRight: '20px' }}
                                        className="adminAddFirm__textInput"
                                    />
                                    <Field
                                        name={fields.is_active.name}
                                        as={fields.is_active.type}
                                        id={fields.is_active.name}
                                        placeholder={fields.is_active.placeholder}
                                        style={{ width: fields.is_active.width }}
                                        className="adminAddFirm__textInput"
                                    >
                                        <option value="false">Not Active</option>
                                        <option value="true">Is Active</option>
                                    </Field>
                                </div>
                                {emailError && <div className="adminEndUser__error">Email not valid</div>}

                                <div className="row">
                                    <div className="col">
                                        <h3 className="addNew2__label" style={{ fontSize: '18px' }}>
                                            Topics
                                        </h3>
                                        <TopicsDropDown
                                            selectedTopics={selectedTopics}
                                            allTopics={allTopics}
                                            handleTopicChange={handleTopicChange}
                                            extraStyle={{ width: '97.5%' }}
                                        />
                                    </div>
                                </div>
                                <div className="row" style={{ width: '100%' }}>
                                    <div className="col">
                                        <h3 className="addNew2__label" style={{ fontSize: '18px' }}>
                                            End User
                                        </h3>
                                        <Select
                                            className="addNews2__selectInput"
                                            placeholder="Select User"
                                            options={allEndUsers.sort((a, b) => (a.label > b.label ? 1 : -1))}
                                            value={selectedEndUser}
                                            onChange={setSelectedEndUser}
                                        />
                                    </div>
                                </div>

                                <div className="adminEndUser__buttonContainer">
                                    <Button
                                        type="submit"
                                        className="adminEndUser__submitButton"
                                        style={{
                                            width: '20%',
                                            height: '40px',
                                            backgroundColor: 'rgb(59, 125, 186)',
                                            color: 'white',
                                            fontSize: '16px',
                                        }}
                                    >
                                        {onEditPage ? 'Update Contact' : 'Add Contact'}
                                    </Button>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </BaseScreen>
    )
}

export default AnalystAdminAddContact
