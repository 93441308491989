import React, { PureComponent } from 'react'

function withModal(WrappedComponent) {
    return class component extends PureComponent {
        state = {
            isModalOpen: false,
        }

        toggle = () => {
            this.setState(prevState => ({
                isModalOpen: !prevState.isModalOpen,
            }))
        }

        render() {
            return (
                <WrappedComponent
                    isModalOpen={this.state.isModalOpen}
                    toggleModal={this.toggle}
                    {...this.props}
                />
            )
        }
    }
}

export default withModal
