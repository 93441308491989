import React, { PureComponent } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { FiLogOut, FiLogIn } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { getTrans } from '../../utils'
import { logoutUser } from '../../actions/AuthActions'
import './MenuModal.css'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import withModal from '../../hoc/withModal'
import { FiMenu } from 'react-icons/fi'
import KLogo from '../../assets/images/K-logo.png'
import MyRelationships from '../../screens/DirectUserDashboard/components/MyRelationships'

class MenuModalDirect extends PureComponent {
    render() {
        const { isModalOpen, toggleModal, isLoggedIn, logoutUser } = this.props
        return (
            <div>
                <span className="menu-modal-icon" onClick={toggleModal}>
                    <FiMenu className="nav-icon" fontSize="27px" />
                </span>
                <Modal isOpen={isModalOpen} toggle={toggleModal} className="menu-modal">
                    <ModalHeader toggle={toggleModal}>
                        <div className="d-lg-none d-xl-none" style={{ marginTop: '6px' }}>
                            {isLoggedIn ? (
                                <Button className="nav-item btn-block" color="link" onClick={logoutUser}>
                                    <span className="sidebar-icon">
                                        <FiLogOut className="nav-icon" fontSize="16px" />
                                    </span>
                                    <span>{getTrans('Log Out')}</span>
                                </Button>
                            ) : (
                                <Link to="/" className="nav-item">
                                    <strong>{getTrans('Log in')}</strong>
                                    <span className="sidebar-icon">
                                        <FiLogIn className="nav-icon ml-2" fontSize="17px" />
                                    </span>
                                </Link>
                            )}
                        </div>
                        <img src={process.env.REACT_APP_MEDIA_URL + KLogo} alt="" className="img-fluid" />
                    </ModalHeader>

                    <ModalBody style={{ padding: '8px' }}>
                        <MyRelationships {...this.props} toggleModal={toggleModal} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggleModal}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapDispatchToProps = {
    logoutUser,
}
const mapStateToProps = state => {
    return {
        user: state.auth.user,
        isLoggedIn: state.auth.isLoggedIn,
    }
}

export default compose(
    withModal,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(MenuModalDirect)
