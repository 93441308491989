import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Form, Formik, Field } from 'formik'
import { Circles } from 'react-loader-spinner'
import { Button, Modal, ModalBody } from 'reactstrap'
import { newFirmValidate } from './AnalystAdminValidation'
import BaseScreen from '../BaseScreen/BaseScreen'
import AdminHeader from './components/AdminHeader'
import FirmCreatedModal from './components/FirmCreatedModal'
import ImageUpload from '../../components/ImageUpload/ImageUpload'
import MobilePlaceHolder from '../../assets/images/firm-placeholder-mobile.JPG'
import DesktopPlaceHolder from '../../assets/images/firm-placeholder-desktop.JPG'
import { addFirmFields as fields } from './constants/AnalystAdminConstants'
import { Tab, Tabs } from '@mui/material'
import { FaArrowLeft, FaLongArrowAltLeft, FaMinus, FaPlus } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import RegionHiearchyMultiSelect from '../NewsFeedScreen/components/RegionHiearchyMultiSelect'
import store from '../../Store'
import CronTab from './CronTab'
import PreferenceContainers from './PreferenceContainers'
import PreferenceSectorContainer from './PreferenceSectorContainer'

export default function AnalystAdminAddFirm(props) {
    const [allIndustries, setAllIndustries] = useState([])
    const [selectedIndustries, setSelectedIndustries] = useState([])
    const [selectedCompanies, setSelectedCompanies] = useState([])
    const [imageMobile, setImageMobile] = useState()
    const [imageMobileCropped, setImageMobileCropped] = useState()
    const [imageDesktop, setImageDesktop] = useState()
    const [imageDesktopCropped, setImageDesktopCropped] = useState()
    const [pageError, setPageError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [firmCreatedModal, setFirmCreatedModal] = useState(false)
    const [createdFirmId, setCreatedFirmId] = useState(2)
    const [selectedCountry, setSelectedCountry] = useState()
    const [firmInfo, setFirmInfo] = useState({})
    const [onEditPage, setOnEditPage] = useState(false)
    const [allSubCats, setAllSubCats] = useState([])
    const [selectedSubCats, setSelectedSubCats] = useState([])
    const [allRegions, setAllRegions] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [regionHierarchy, setRegionHierarchy] = useState([])
    const [selectedAllCountry, setSelectedAllCountry] = useState([])
    const [defaultCountry, setDefaultCountry] = useState([])
    const [selectedImage, setSelectedImage] = useState('')
    const [selectedImage1, setSelectedImage1] = useState('')
    const userType = store.getState().auth.user.user_type
    const [isEnabled, setIsEnabled] = useState(false)
    const [cronTabValue, setCronTabValue] = useState('0 0 * * 1')
    const [editCron, setEditCron] = useState(null)
    const [lastScheduled, setLastScheduled] = useState(null)
    const [nextScheduled, setNextScheduled] = useState(null)
    const [emailSubject, setEmailSubject] = useState('Recommendation Newsletter')

    const [activeTab, setActiveTab] = useState(0)
    const [input, setInput] = useState('')
    const [selectedinput, setSelectedInput] = useState('')
    const [allCompanies, setAllCompanies] = useState([])
    const [allHierarchy, setAllHierarchy] = useState([])
    const [selectedSectors, setSelectedSectors] = useState([])
    const [sectorOpen, setSectorOpen] = useState([])
    const [topicOpen, setTopicOpen] = useState([])
    const [sectorInput, setsectorInput] = useState('')
    const [checkedTopics, setCheckedTopics] = useState(false)
    const stateMap = {
        Industry: [selectedIndustries, setSelectedIndustries],
        sector: [selectedSectors, setSelectedSectors],
        company: [selectedCompanies, setSelectedCompanies],
        business: [selectedSubCats, setSelectedSubCats],
    }

    const allIndustriesName = allHierarchy.map(val => val)

    const allSectorsName = allHierarchy.map(val => val.sectors).reduce((acc, curr) => acc.concat(curr), [])

    const allTopicsName = allHierarchy
        .map(val => val.sectors.map(sec => sec.topics.map(top => top)))
        .reduce((acc, curr) => acc.concat(curr), [])
        .reduce((acc, curr) => acc.concat(curr), [])

    const handleSelectAllTopics = () => {
        if (checkedTopics) {
            setSelectedSectors([])
        } else {
            const filteredItems = allHierarchy.filter(ind => {
                const matchingSectors = ind.sectors.filter(sec =>
                    sec.topics.some(topic => topic.name.toLowerCase().includes(sectorInput.toLowerCase()))
                )

                return (
                    ind.name.toLowerCase().includes(sectorInput.toLowerCase()) ||
                    matchingSectors.length > 0 ||
                    ind.sectors.some(sec => sec.name.toLowerCase().includes(sectorInput.toLowerCase()))
                )
            })

            const filteredTopicLabels = filteredItems.reduce((result, val) => {
                result.push(val.name)

                val.sectors.forEach(sector => {
                    result.push(sector.name)

                    sector.topics.forEach(topic => {
                        if (topic.name.toLowerCase().includes(sectorInput.toLowerCase())) {
                            result.push(topic.name)
                        }
                    })
                })

                return result
            }, [])
            setSelectedSectors(filteredTopicLabels)
        }
        setCheckedTopics(!checkedTopics)
    }

    const handleRemoveTopic = (type, id) => {
        const [selectedItems, setSelectedItems] = stateMap[type]
        setSelectedItems(selectedItems.filter(val => val !== id))
    }

    const handleRemove = (type, id) => {
        const [selectedItems, setSelectedItems] = stateMap[type]
        setSelectedItems(selectedItems.filter(val => val.value !== id))
    }

    const handleToggle = (type, id) => {
        const [selectedItems, setSelectedItems] = stateMap[type]
        // const isItemSelected = selectedItems.includes(id)
        // if (isItemSelected) {
        //     const filteredItems = selectedItems.filter(item => item !== id)
        //     setSelectedItems(filteredItems)
        // } else {
        //     setSelectedItems([...selectedItems, id])
        // }
        setSelectedItems([...id])
    }

    const toggleid = (Id, type) => {
        type(prevExpandedValues => {
            if (prevExpandedValues.includes(Id)) {
                return prevExpandedValues.filter(id => id !== Id)
            } else {
                return [...prevExpandedValues, Id]
            }
        })
    }

    const bulkChange = (type, data, name) => {
        const [selectedItems, setSelectedItems] = stateMap[type]

        let result = selectedItems

        const isSelectedItem = selectedItems.find(item => item === name)
        data.forEach(list => {
            if (isSelectedItem) {
                result = result.filter(item => item !== list.name)
            } else {
                result.push(list.name)
            }
        })

        setSelectedItems([...result])
    }

    const changeCheck = (type, id, typeOf = null, value = null) => {
        const [selectedItems, setSelectedItems] = stateMap[type]
        const isItemSelected = selectedItems.includes(id)
        if (isItemSelected) {
            const filteredItems = selectedItems.filter(item => item !== id)
            setSelectedItems(filteredItems)
        } else {
            setSelectedItems([...selectedItems, id])
        }
    }

    const firmId = userType === 'Power User' ? store.getState().auth.user.firm_id : props.match.params.id

    useEffect(() => {
        axios.get(`/news/all-industries-sectors/`).then(response => {
            const updatedIndustries = []
            response.data.forEach(item => {
                if (item.nick_name) {
                    const tempObj = { ...item }
                    tempObj.label = item.nick_name
                    updatedIndustries.push(tempObj)
                } else {
                    updatedIndustries.push(item)
                }
            })
            setAllIndustries(updatedIndustries)
        })
        getAllSubCategories()
        getRegionHierarchy()
        getCron()

        // axios.get(`/core/all-documents/`).then(response => {
        //     setAllDocuments(response.data.map(doc => ({ label: doc.title, value: doc.id })))
        // })

        axios.get(`/news/all-regions/`).then(response => {
            setAllRegions(response.data.map(reg => ({ label: reg.label, value: reg.value })))
        })

        axios
            .get('/news/all-companies/')
            .then(response => {
                let remappedTopics = response.data.map(item => {
                    return { value: item.value, label: item.label }
                })
                setAllCompanies(remappedTopics)
            })
            .catch(err => console.log(err))

        axios
            .get('/news/industries-sectors-topics/')
            .then(res => {
                setAllHierarchy(res.data)
            })
            .catch(err => console.log(err))

        if (firmId) {
            setOnEditPage(true)
            axios.get(`/accounts/firm/?pk=${firmId}`).then(response => {
                setFirmInfo(response.data)
                let sectorsMapped = [...response.data.sectors]
                sectorsMapped = sectorsMapped.map(item => ({ ...item, type: 'Sector' }))
                let industriesMapped = [...response.data.industries]
                industriesMapped = industriesMapped.map(item => ({ ...item, type: 'Industry' }))
                setSelectedIndustries([...sectorsMapped, ...industriesMapped])
                setSelectedCompanies(response.data.companies)
                // setSelectedDocuments(response.data.documents.map(val => val.value))
                setImageMobile(response.data.mobile_logo)
                setImageDesktop(response.data.desktop_logo)
                setSelectedSubCats(response.data.subcategories.map(val => val.value))
                setSelectedSectors([
                    ...response.data.sectors.map(v => v.label),
                    ...response.data.industries.map(v => v.label),
                    ...response.data.topics.map(v => v.label),
                ])
                // setSelectedCountry(response.data.country)
                console.log(response.data.country)
                const addCountry = response.data.country.map(val => {
                    return {
                        id: val.value,
                        name: val.label,
                        type: 'Country',
                    }
                })
                const addRegion = response.data.regions.map(val => {
                    return {
                        id: val.value,
                        name: val.label,
                        type: 'Region',
                    }
                })
                const addProvince = response.data.provinces.map(val => {
                    return {
                        id: val.value,
                        name: val.label,
                        type: 'Province',
                    }
                })
                const addingAll = [...addRegion, ...addCountry, ...addProvince]

                setDefaultCountry(addingAll)
                setIsLoading(false)
            })
            const headerTitle = document.getElementById('header_title')
            headerTitle.textContent = 'Edit Firm'
        } else {
            const headerTitle = document.getElementById('header_title')
            headerTitle.textContent = 'Add Firm'
        }
    }, [defaultCountry.length > 0])

    const getAllSubCategories = () => {
        axios
            .get('/news/all-subcategories/')
            .then(res => {
                setAllSubCats(res.data)
            })
            .catch(err => console.log(err))
    }

    const getCompaniesSearch = value => {
        if (value.length > 2) {
            return axios.get(`/news/auto-complete/?type=company&string=${encodeURIComponent(value)}`).then(response => {
                return response.data.map(item => ({ label: item.name, value: item.id }))
            })
        }
    }

    const getRegionHierarchy = async () => {
        await axios.get('/news/region-countries-provinces/').then(response => {
            const regions = response.data.map(item => ({
                ...item,
                id: item.type + '_' + item.id,
                countries: item.countries
                    ? item.countries.map(cty => ({
                          ...cty,
                          id: cty.type + '_' + cty.id,
                          provinces: cty.provinces
                              ? cty.provinces.map(pro => ({
                                    ...pro,
                                    id: pro.type + '_' + pro.id,
                                }))
                              : [],
                      }))
                    : [],
            }))
            const data = regions

            const customSort = (a, b) => {
                if (a.name === 'North America') {
                    return -1
                } else if (b.name === 'North America') {
                    return 1
                }
                return a.name.localeCompare(b.name)
            }

            const sortedData = data.sort((a, b) => customSort(a, b))

            sortedData.forEach(region => {
                region.countries.sort((a, b) => a.name.localeCompare(b.name))
            })

            setRegionHierarchy(sortedData)
        })
    }

    const getCron = () => {
        axios
            .get(`/core/schedule-newsletter/`)
            .then(res => {
                const crondata = res.data
                if (crondata.length > 0) {
                    setEditCron(crondata[crondata.length - 1].ap_scheduler)
                    setCronTabValue(crondata[crondata.length - 1].cron_expression)
                    if (crondata[crondata.length - 1] !== '') {
                        setIsEnabled(true)
                        setLastScheduled(crondata[crondata.length - 1].last_sent)
                        setNextScheduled(crondata[crondata.length - 1].next_schedule)
                    }
                    if (crondata[crondata.length - 1].subject) {
                        setEmailSubject(crondata[crondata.length - 1].subject)
                    }
                } else {
                    setCronTabValue('0 0 * * 1')
                    setLastScheduled(null)
                    setNextScheduled(null)
                    setEditCron(null)
                    setIsEnabled(false)
                }
            })
            .catch(err => console.log(err))
    }

    const validateEmail = mail => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
            return setEmailError(false)
        } else {
            return setEmailError(true)
        }
    }

    const removeTag = (id, type) => {
        if (type === 'industry') {
            let itemTemp = [...selectedIndustries]
            itemTemp.filter(item => item.value !== id)
            setSelectedIndustries(itemTemp.filter(item => item.value !== id))
        }
        if (type === 'company') {
            let itemTemp = [...selectedCompanies]
            itemTemp.filter(item => item.value !== id)
            setSelectedCompanies(itemTemp.filter(item => item.value !== id))
        }
        // if (type === 'document') {
        //     let itemTemp = [...selectedDocuments]
        //     itemTemp.filter(item => item.value !== id)
        //     setSelectedDocuments(itemTemp.filter(item => item.value !== id))
        // }
    }

    const onSubmit = values => {
        let submitObject = { ...values }
        submitObject.imageMobile = imageMobileCropped ? imageMobileCropped : imageMobile
        submitObject.imageDesktop = imageDesktopCropped ? imageDesktopCropped : imageDesktop
        submitObject.companies = selectedCompanies.map(ele => ele.value)
        submitObject.subcategories = selectedSubCats
        submitObject.sectors = allSectorsName.filter(item => selectedSectors.includes(item.name)).map(item => item.id)
        submitObject.industries = allIndustriesName
            .filter(item => selectedSectors.includes(item.name))
            .map(item => item.id)
        submitObject.topics = allTopicsName.filter(item => selectedSectors.includes(item.name)).map(item => item.id)
        submitObject.country = selectedAllCountry
            .filter(value => value.type === 'Country')
            .map(value => {
                return parseInt(value.value.split('_')[1])
            })
        submitObject.regions = selectedAllCountry
            .filter(value => value.type === 'Region')
            .map(value => {
                return parseInt(value.value.split('_')[1])
            })
        submitObject.provinces = selectedAllCountry
            .filter(value => value.type === 'Province')
            .map(value => {
                return parseInt(value.value.split('_')[1])
            })
        if (firmId) {
            submitObject.id = firmId
        }
        if (newFirmValidate(submitObject, imageMobile, imageDesktop)) {
            setPageError(false)
            axios
                .post('/accounts/create-firm/', submitObject)
                .then(response => {
                    setCreatedFirmId(response.data.pk)
                    if (onEditPage) {
                        toast('Firm has been successfully updated.')
                    } else {
                        setFirmCreatedModal(true)
                    }
                    let scheduling = {
                        newsletter: 'recommendations',
                        recipients: [values.email],
                        user: null,
                        subject: emailSubject,
                    }
                    if (editCron !== null) {
                        scheduling.ap_scheduler = editCron
                        scheduling.cron_expression = cronTabValue
                        scheduling.enabled = isEnabled
                    } else {
                        scheduling.cron_expression = cronTabValue
                        scheduling.enabled = isEnabled
                    }
                    //scheduling
                    // if (userType === 'Power User') {
                    //     axios.post(`/news/schedule-newsletter/`, scheduling).then(res => {
                    //         getCron()
                    //     })
                    // }
                })
                .catch(err => {
                    console.log(err)
                    toast.warn('Error creating firm-' + err.message)
                })
        } else {
            toast.warn('Some required fields are missing.')
        }
    }

    if (onEditPage && isLoading) {
        return (
            <BaseScreen>
                <Circles key={0} type="Grid" color="#adadad" height={80} width={80} />
            </BaseScreen>
        )
    }

    return (
        <BaseScreen>
            {/* {userType === 'Power User' && <AdminHeader currentPage={'firm'} />} */}
            <div
                className="adminEndUser__formBody"
                style={{
                    // 'margin-top': userType === 'Power User' ? '0%' : '0%',
                    marginTop: '-23px',
                    width: '100%',
                    paddingTop: '15px',
                    paddingBottom: '5px',
                }}
            >
                <div className="d-flex">
                    {userType !== 'Power User' && (
                        <NavLink style={{ 'text-decoration': 'none', marginTop: '3px' }} to="/app/admin/firm/">
                            <h3
                                style={{
                                    color: '#696464',
                                    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                                    fontWeight: 500,
                                    textTransform: 'uppercase',
                                    fontSize: '12px',
                                    lineHeight: '1.25',
                                    letterSpacing: '0.02857em',
                                }}
                            >
                                <FaArrowLeft style={{ fontSize: '16px' }} />
                            </h3>
                        </NavLink>
                    )}
                    <div style={{ marginLeft: '20px', color: '#197bbd', cursor: 'pointer' }}>
                        <Tabs
                            style={{ marginTop: '-15px' }}
                            value={activeTab}
                            onChange={(event, newValue) => {
                                setActiveTab(newValue)
                                setInput('')
                                setSelectedInput('')
                            }}
                        >
                            <Tab label="Profile" />

                            <Tab style={{ display: userType === 'Power User' ? 'none' : '' }} label="Companies" />
                            <Tab style={{ display: userType === 'Power User' ? 'none' : '' }} label="Sectors" />
                            {/* <Tab
                                label="Newsletter Scheduling"
                                style={{ display: userType === 'Power User' ? '' : 'none' }}
                            /> */}
                        </Tabs>
                    </div>
                </div>
            </div>
            <div className="p-3">
                <Formik
                    enableReinitialize
                    initialValues={
                        onEditPage
                            ? {
                                  firmName: firmInfo.firm_name,
                                  contactName: firmInfo.contact_name,
                                  email: firmInfo.contact_email,
                                  website: firmInfo.firm_website,
                                  country: firmInfo.country,
                                  description: firmInfo.description,
                              }
                            : {
                                  firmName: '',
                                  contactName: '',
                                  email: '',
                                  website: '',
                                  country: '',
                                  description: '',
                              }
                    }
                    onSubmit={onSubmit}
                >
                    {({ setFieldValue }) => {
                        return (
                            <Form>
                                <div style={{ width: '100%', display: 'flex' }}>
                                    <Button
                                        type="submit"
                                        className="adminEndUser__submitButton"
                                        style={{
                                            width: '14%',
                                            height: '45px',
                                            marginLeft: '84%',
                                            marginTop: '-65px',
                                            backgroundColor: 'rgb(24, 106, 222)',
                                            color: 'white',
                                            fontSize: '14px',
                                            zIndex: 1,
                                            border: 0,
                                            borderRadius: '4px',
                                        }}
                                    >
                                        {onEditPage ? 'Update Firm' : 'Next Step'}
                                    </Button>
                                </div>

                                {activeTab === 0 && (
                                    <div style={{ padding: '15px' }}>
                                        <div
                                            className="adminEndUser__formRow"
                                            style={{
                                                justifyContent: 'space-between',
                                                paddingTop: '10px',
                                            }}
                                        >
                                            <div style={{ width: '45%' }}>
                                                <label className="fw-bold" style={{ fontSize: '16px' }}>
                                                    Firm Name
                                                </label>
                                                <br />
                                                <Field
                                                    type={fields.firmName.type}
                                                    name={fields.firmName.name}
                                                    id={fields.firmName.name}
                                                    placeholder={fields.firmName.placeholder}
                                                    // style={{ width: fields.firmName.width, marginRight: '20px' }}
                                                    style={{ height: '50px', marginTop: '10px' }}
                                                    className="adminAddFirm__textInput"
                                                />
                                            </div>
                                            <div style={{ width: '45%' }}>
                                                <label className="fw-bold" style={{ fontSize: '16px' }}>
                                                    Contact Name
                                                </label>
                                                <br />
                                                <Field
                                                    type={fields.contactName.type}
                                                    name={fields.contactName.name}
                                                    id={fields.contactName.name}
                                                    placeholder={fields.contactName.placeholder}
                                                    // style={{ width: fields.contactName.width, marginRight: '20px' }}
                                                    style={{ height: '50px', marginTop: '10px' }}
                                                    className="adminAddFirm__textInput"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="adminEndUser__formRow"
                                            style={{ justifyContent: 'space-between' }}
                                        >
                                            <div style={{ width: '45%', marginRight: '20px' }}>
                                                <label className="fw-bold" style={{ fontSize: '16px' }}>
                                                    Website
                                                </label>
                                                <br />
                                                <Field
                                                    type={fields.website.type}
                                                    name={fields.website.name}
                                                    id={fields.website.name}
                                                    placeholder={fields.website.placeholder}
                                                    // style={{ width: fields.website.width }}
                                                    style={{ height: '50px', marginTop: '10px' }}
                                                    className="adminAddFirm__textInput"
                                                />
                                            </div>
                                            <div style={{ width: '45%' }}>
                                                <label className="fw-bold" style={{ fontSize: '16px' }}>
                                                    Email
                                                </label>
                                                <br />
                                                <Field
                                                    type={fields.email.type}
                                                    name={fields.email.name}
                                                    id={fields.email.name}
                                                    onBlur={ev => validateEmail(ev.target.value)}
                                                    placeholder={fields.email.placeholder}
                                                    // style={{ width: fields.email.width, marginRight: '20px' }}
                                                    style={{ height: '50px', marginTop: '10px' }}
                                                    className="adminAddFirm__textInput"
                                                />
                                            </div>
                                        </div>

                                        <div
                                            className="adminEndUser__formRow"
                                            style={{ justifyContent: 'space-between' }}
                                        >
                                            {/* <div style={{ width: '45%', marginTop: '-13px' }}>
                                                <label>Region</label>
                                                <br />
                                                <div style={{ marginTop: '10px' }}>
                                                    <RegionHiearchyMultiSelect
                                                        style={{ width: fields.country.width }}
                                                        regionHierarchy={regionHierarchy}
                                                        selectedQueries={selectedAllCountry}
                                                        setSelectedQueries={setSelectedAllCountry}
                                                        isAddFirm={true}
                                                        selectedPreference={defaultCountry}
                                                    />
                                                </div>
                                            </div> */}
                                            <div style={{ width: '45%' }}>
                                                <label className="fw-bold" style={{ fontSize: '16px' }}>
                                                    Description
                                                </label>
                                                <br />
                                                <Field
                                                    type={fields.description.type}
                                                    name={fields.description.name}
                                                    id={fields.description.name}
                                                    placeholder={fields.description.placeholder}
                                                    // style={{
                                                    //     // width: fields.description.width,
                                                    //     // marginRight: '20px',
                                                    //     marginLeft: '20px',
                                                    // }}
                                                    style={{ height: '50px', marginTop: '10px' }}
                                                    className="adminAddFirm__textInput"
                                                />
                                            </div>
                                        </div>
                                        {emailError && <div className="adminEndUser__error">Email not valid</div>}
                                        <div
                                            className="adminEndUser__imageUploadOuter"
                                            style={{ backgroundColor: 'white' }}
                                        >
                                            <h2>Add Firm Logo</h2>
                                            <h3>Choose a file, then draw a box to crop the image</h3>
                                            <div className="adminEndUser__formRow">
                                                <div className="adminEndUser__mobileLogoContainer">
                                                    <h4>Mobile Logo (approx Dimensions 160x160px)</h4>
                                                    <ImageUpload
                                                        width={'135'}
                                                        height={'135'}
                                                        maxWidth={'160'}
                                                        maxHeight={'160'}
                                                        selectedImage={selectedImage}
                                                        setSelectedImage={setSelectedImage}
                                                        outerState={img => setImageMobile(img)}
                                                        setCroppedImage={setImageMobileCropped}
                                                    />
                                                    {selectedImage === '' && imageMobile !== null && (
                                                        <div>
                                                            <img
                                                                src={imageMobile}
                                                                style={{ width: '160px', maxHeight: '160px' }}
                                                                alt="mobile placeholder"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="adminEndUser__desktopLogoContainer">
                                                    <h4>Desktop Logo (approx Dimensions 220x40px)</h4>
                                                    <ImageUpload
                                                        width={'337'}
                                                        height={'127'}
                                                        maxWidth={'220'}
                                                        maxHeight={'160'}
                                                        selectedImage={selectedImage1}
                                                        setSelectedImage={setSelectedImage1}
                                                        outerState={img => setImageDesktop(img)}
                                                        setCroppedImage={setImageDesktopCropped}
                                                    />

                                                    {selectedImage1 === '' && imageDesktop !== null && (
                                                        <div>
                                                            <img
                                                                src={imageDesktop}
                                                                style={{
                                                                    width: '220px',
                                                                    maxWidth: '220px',
                                                                    maxHeight: '160px',
                                                                }}
                                                                alt="mobile placeholder"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {userType !== 'Power User' && (
                                    <>
                                        {activeTab === 1 && (
                                            <div style={{ padding: '15px' }}>
                                                <PreferenceContainers
                                                    header={'All Companies'}
                                                    subHeader={'Selected Companies'}
                                                    placeHolder={'Type to search companies'}
                                                    data={allCompanies}
                                                    selectedData={selectedCompanies}
                                                    setSelectedData={setSelectedCompanies}
                                                    input={input}
                                                    setInput={setInput}
                                                    selectedinput={selectedinput}
                                                    setSelectedInput={setSelectedInput}
                                                    state={'company'}
                                                    handleToggle={handleToggle}
                                                    handleRemove={handleRemove}
                                                />
                                            </div>
                                        )}
                                        {activeTab === 2 && (
                                            <div style={{ padding: '15px' }}>
                                                <PreferenceSectorContainer
                                                    header={'All Topics'}
                                                    subHeader={'Selected Topics'}
                                                    placeHolder={'Search Topics'}
                                                    selectedData={selectedSectors}
                                                    selectedSectors={selectedSectors}
                                                    setSelectedData={setSelectedSectors}
                                                    input={input}
                                                    selectedinput={selectedinput}
                                                    setSelectedInput={setSelectedInput}
                                                    allHierarchy={allHierarchy}
                                                    checkedTopics={checkedTopics}
                                                    setInput={setInput}
                                                    sectorInput={sectorInput}
                                                    setsectorInput={setsectorInput}
                                                    state={'sector'}
                                                    sectorOpen={sectorOpen}
                                                    setSectorOpen={setSectorOpen}
                                                    topicOpen={topicOpen}
                                                    setTopicOpen={setTopicOpen}
                                                    toggleid={toggleid}
                                                    bulkChange={bulkChange}
                                                    handleRemove={handleRemoveTopic}
                                                    changeCheck={changeCheck}
                                                    handleSelectAllTopics={handleSelectAllTopics}
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                                {userType === 'Power User' && (
                                    <>
                                        {activeTab === 3 && (
                                            <div style={{ padding: '15px' }}>
                                                <div style={{ display: 'flex', borderTop: '1px solid lightgray' }}>
                                                    <div>
                                                        <CronTab
                                                            isEnabled={isEnabled}
                                                            setIsEnabled={setIsEnabled}
                                                            cronTabValue={cronTabValue}
                                                            setCronTabValue={setCronTabValue}
                                                            lastScheduled={lastScheduled}
                                                            nextScheduled={nextScheduled}
                                                        />
                                                    </div>
                                                    <div style={{ marginLeft: '30px', marginTop: '10px' }}>
                                                        <label className="fw-bold" style={{ fontSize: '16px' }}>
                                                            Email subject
                                                        </label>
                                                        <br />
                                                        <textarea
                                                            value={emailSubject}
                                                            onChange={e => setEmailSubject(e.target.value)}
                                                            disabled={!isEnabled ? true : false}
                                                            style={{
                                                                outline: 'none',
                                                                border: '1px solid lightgray',
                                                                width: '300px',
                                                                borderRadius: '4px',
                                                                resize: 'none',
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}

                                <Modal isOpen={firmCreatedModal}>
                                    <ModalBody>
                                        <FirmCreatedModal
                                            selectedCountry={selectedAllCountry}
                                            createdFirmId={createdFirmId}
                                        />
                                    </ModalBody>
                                </Modal>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </BaseScreen>
    )
}
