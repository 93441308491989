import React, { useEffect, useState } from 'react'
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'
import { Chip, Grid, Autocomplete, TextField, createFilterOptions, Button } from '@mui/material'
import { toast } from 'react-toastify'
import axios from 'axios'
import CronTab from '../../AnalystAdmin/CronTab'
import SavedQueriesModal from './SavedQueriesModal'

const filter = createFilterOptions()

const sections = [
    { label: 'Business Events', value: 'subcategories' },
    { label: 'Company', value: 'companies' },
    { label: 'Industry', value: 'industries' },
    { label: 'Sector', value: 'sectors' },
    { label: 'Topic', value: 'topics' },
]

export default function SchedulingSection(props) {
    const {
        userEmail,
        usertype,
        cronTabValue,
        setCronTabValue,
        isEnabled,
        setIsEnabled,
        selectedTemplate,
        setSelectedTemplate,
        saveSchedule,
        selectedUsers,
        setSelectedUsers,
        selectedSection,
        setSelectedSection,
        includeBusiness,
        setIncludeBusiness,
        includeSection,
        setIncludeSection,
        clearSchedule,
        saveQueryName,
        setSaveQueryName,
        subjectLine,
        setSubjectLine,
        isMyRequest,
    } = props

    const {
        semanticSaveModal,
        setSemanticSaveModal,
        editPage,
        collectionName,
        setCollectionName,
        collectionDescription,
        setCollectionDescription,
        collectionShared,
        setCollectionShared,
        collectionUsers,
        setCollectionUsers,
        allCollectionUsers,
        allCollectionName,
        saveSemanticQuery,
        editSemanticQuery,
        collectionPrivacy,
        setCollectionPrivacy,
        collectionModal,
        setCollectionModal,
        collectionShareList,
        templates,
    } = props

    useEffect(() => {
        getUsers()
    }, [])

    const [users, setUsers] = useState([])
    const getUsers = () => {
        axios.get('/endusers/firm-users/').then(res => {
            const filter = [userEmail]
            filter.forEach(email => {
                const existingUser = res.data.find(user => user.email === email)

                if (!existingUser) {
                    const newUser = {
                        id: email,
                        email: email,
                        full_name: email,
                    }

                    res.data.push(newUser)
                }
            })
            res.data.sort((a, b) => (a.email === userEmail ? -1 : b.email === userEmail ? 1 : 0))

            setUsers(res.data)
        })
    }

    return (
        <div>
            <div className="scheduling_Section" style={{ margin: 'auto', marginTop: isMyRequest ? '0px' : '-24px' }}>
                {!isMyRequest && (
                    <>
                        <div class="d-flex align-items-center justify-content-between mt-3">
                            <div>
                                <label style={{ fontSize: '20px' }}>Schedule Newsletter</label>
                                {/* <div>Control when and how your newsletter reaches your audience. </div> */}
                                <div>
                                    Customize delivery frequency, timing, recipients, and more for effective
                                    communication.
                                </div>
                            </div>
                        </div>
                        <hr />
                    </>
                )}
                <div className="Scheduling_container">
                    <form
                        onSubmit={e => {
                            e.preventDefault()
                            setSemanticSaveModal(true)
                        }}
                    >
                        <div className="d-flex" style={{ padding: '10px' }}>
                            <div>
                                {!isMyRequest && (
                                    <div style={{ marginTop: '5px' }}>
                                        <TextField
                                            // disabled={isEnabled ? false : true}
                                            sx={{ width: '320px' }}
                                            variant="outlined"
                                            label="Title"
                                            size="small"
                                            required
                                            name="query"
                                            value={saveQueryName}
                                            onChange={e => {
                                                setSaveQueryName(e.target.value)
                                            }}
                                        />
                                    </div>
                                )}
                                <div style={{ marginTop: '15px' }}>
                                    <TextField
                                        // disabled={isEnabled ? false : true}
                                        sx={{ width: '320px' }}
                                        size="small"
                                        multiline
                                        rows={1}
                                        variant="outlined"
                                        label="Email subject"
                                        required
                                        name="subject"
                                        value={subjectLine}
                                        onChange={e => {
                                            setSubjectLine(e.target.value)
                                        }}
                                    />
                                </div>
                                <div style={{ marginTop: '15px' }}>
                                    <Autocomplete
                                        // disabled={isEnabled ? false : true}
                                        sx={{ width: '320px' }}
                                        options={templates}
                                        autoHighlight
                                        value={selectedTemplate}
                                        getOptionLabel={option => option.label}
                                        renderOption={(props, option) => (
                                            <li {...props}>
                                                <img
                                                    src={option.imageUrl}
                                                    alt={option.label}
                                                    style={{ width: '24px', marginRight: '8px' }}
                                                />
                                                {option.label}
                                            </li>
                                        )}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                sx={{ height: '40px' }}
                                                style={{ height: 40 }}
                                                label="Choose a template"
                                                size="small"
                                                required
                                                name="template"
                                            />
                                        )}
                                        onChange={(event, value) => {
                                            setSelectedTemplate(value)
                                            if (value === null || value.has_sections === false) {
                                                setIncludeSection(false)
                                            }
                                        }}
                                    />
                                </div>

                                {/* Includes Sections and Business Events */}
                                <div style={{ marginTop: '15px' }}>
                                    <div className="form-check form-switch">
                                        <label className="form-check-label text-black">
                                            {'Include Business Events in the Newsletter'}
                                        </label>
                                        <input
                                            style={{ cursor: 'pointer' }}
                                            title="include/exclude of business events in the newsletter"
                                            className="form-check-input"
                                            type="checkbox"
                                            value={includeBusiness}
                                            checked={includeBusiness ? true : false}
                                            onChange={e => {
                                                setIncludeBusiness(current => !current)
                                            }}
                                            // disabled={isEnabled ? false : true}
                                        />
                                    </div>
                                    {selectedTemplate && selectedTemplate.has_sections && (
                                        <>
                                            <div className="form-check form-switch">
                                                <label className="form-check-label text-black">
                                                    {'Include Section in the Newsletter'}
                                                </label>
                                                <input
                                                    style={{ cursor: 'pointer' }}
                                                    title="include/exlude of Section in the newsletter"
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={includeSection}
                                                    checked={includeSection ? true : false}
                                                    onChange={e => {
                                                        setIncludeSection(current => !current)
                                                    }}
                                                    // disabled={isEnabled ? false : true}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>

                                {selectedTemplate !== null && selectedTemplate.has_sections && includeSection && (
                                    <div style={{ marginTop: '10px' }}>
                                        <Autocomplete
                                            // disabled={isEnabled ? false : true}
                                            sx={{ width: '320px' }}
                                            options={sections}
                                            autoHighlight
                                            aria-required={selectedTemplate.has_sections ? true : false}
                                            value={selectedSection}
                                            getOptionLabel={option => option.label}
                                            renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    sx={{ height: '40px' }}
                                                    style={{ height: 40 }}
                                                    label="Choose a section type"
                                                    name="section"
                                                    size="small"
                                                />
                                            )}
                                            onChange={(event, value) => {
                                                setSelectedSection(value)
                                                if (value === null) {
                                                    setIncludeSection(false)
                                                }
                                            }}
                                        />
                                    </div>
                                )}

                                {/* users */}
                                <div
                                    style={{
                                        marginTop: includeSection ? '25px' : '15px',
                                    }}
                                >
                                    <Autocomplete
                                        // disabled={isEnabled ? false : true}
                                        sx={{ width: '320px' }}
                                        multiple
                                        options={users}
                                        value={selectedUsers}
                                        getOptionLabel={option => {
                                            if (option.label) {
                                                return option.label
                                            }

                                            return option.email
                                        }}
                                        onChange={(event, newValue) => {
                                            if (newValue && newValue.length) {
                                                const results = []

                                                newValue.forEach(value => {
                                                    if (typeof value === 'string') {
                                                        function ValidateEmail(mail) {
                                                            return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                                                                mail
                                                            )
                                                        }

                                                        if (!ValidateEmail(value)) {
                                                            toast.error(
                                                                `You have entered an invalid email address! ${value}`
                                                            )
                                                            return
                                                        }

                                                        return results.push({
                                                            email: value,
                                                        })
                                                    }

                                                    return results.push(value)
                                                })

                                                setSelectedUsers(results)
                                            }
                                        }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Recipients"
                                                size="small"
                                                required={selectedUsers.length === 0 ? true : false}
                                                name="users"
                                            />
                                        )}
                                        renderTags={() => null}
                                        freeSolo
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params)
                                            const { inputValue } = params
                                            const isExisting = options.some(option => inputValue === option.email)
                                            if (inputValue !== '' && !isExisting) {
                                                filtered.push({
                                                    id: `Add email :   ${inputValue}`,
                                                    email: inputValue,
                                                })
                                            }

                                            return filtered
                                        }}
                                        renderOption={(props, option) => (
                                            <li
                                                {...props}
                                                style={{
                                                    color: option.email === userEmail ? '#197bbd' : '',
                                                    title: option.email === userEmail ? 'User' : 'exiting user',
                                                }}
                                            >
                                                <span>{option.label || option.email}</span>
                                                {usertype !== 'Regular User' && option.user_category === 'LiteUser' && (
                                                    <span
                                                        className="Lite"
                                                        style={{
                                                            marginLeft: '5px',
                                                            paddingLeft: '5px',
                                                            fontSize: 'small',
                                                            background: 'skyblue',
                                                            padding: '2px 5px',
                                                            borderRadius: '5px',
                                                        }}
                                                    >
                                                        Lite*
                                                    </span>
                                                )}
                                            </li>
                                        )}
                                        // required
                                    />
                                </div>
                            </div>

                            {selectedUsers.length > 0 && (
                                <div
                                    style={{
                                        marginLeft: '20px',
                                        padding: '10px',
                                        width: '100%',
                                        overflow: 'auto',
                                        border: '1px solid lightgray',
                                        borderRadius: '4px',
                                        marginTop: '5px',
                                    }}
                                >
                                    <label style={{ fontSize: '16px' }}>Selected Users</label>
                                    <Grid item>
                                        <Grid
                                            container
                                            direction="row"
                                            spacing={1}
                                            style={{ marginTop: '5px', borderTop: '1px solid lightgray' }}
                                        >
                                            {selectedUsers.map(user => (
                                                <Grid item>
                                                    <Chip
                                                        key={user.id}
                                                        label={user.email}
                                                        style={{
                                                            background: typeof user.id === 'number' ? '' : '#cdc8c7',
                                                        }}
                                                        title={
                                                            typeof user.id === 'number'
                                                                ? 'Existing user'
                                                                : 'Custom user'
                                                        }
                                                        // disabled={isEnabled ? false : true}
                                                        onDelete={() =>
                                                            setSelectedUsers(
                                                                selectedUsers.filter(
                                                                    currUser => currUser.id !== user.id
                                                                )
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </div>
                            )}
                        </div>

                        <div>
                            <CronTab
                                isEnabled={isEnabled}
                                setIsEnabled={setIsEnabled}
                                cronTabValue={cronTabValue}
                                setCronTabValue={setCronTabValue}
                                // isAssistant={!isMyRequest ? true : false}
                            />
                        </div>

                        {!isMyRequest && (
                            <>
                                <Button
                                    variant="contained"
                                    startIcon={<MdNavigateBefore />}
                                    style={{
                                        background: '#cfcfcf00',
                                        color: 'gray',
                                        boxShadow: 'none',
                                        borderRadius: '2px',
                                        position: 'fixed',
                                        top: '96px',
                                        right: '12%',
                                    }}
                                    onClick={() => {
                                        clearSchedule()
                                    }}
                                >
                                    Back
                                </Button>

                                <Button
                                    variant="contained"
                                    endIcon={<MdNavigateNext />}
                                    type="submit"
                                    style={{
                                        background: '#3048a6',
                                        borderRadius: '2px',
                                        marginRight: '10px',
                                        position: 'fixed',
                                        top: '96px',
                                        right: '20px',
                                    }}
                                >
                                    Finish
                                </Button>
                            </>
                        )}
                    </form>
                </div>
                {!isMyRequest && (
                    <SavedQueriesModal
                        collectionDescription={collectionDescription}
                        setCollectionDescription={setCollectionDescription}
                        collectionShared={collectionShared}
                        setCollectionShared={setCollectionShared}
                        allCollectionUsers={allCollectionUsers}
                        collectionUsers={collectionUsers}
                        setCollectionUsers={setCollectionUsers}
                        collectionName={collectionName}
                        setCollectionName={setCollectionName}
                        allCollectionName={allCollectionName}
                        editPage={editPage}
                        openModal={semanticSaveModal}
                        setOpenModal={setSemanticSaveModal}
                        saveSemanticQuery={saveSemanticQuery}
                        editSemanticQuery={editSemanticQuery}
                        saveQueryName={saveQueryName}
                        setSaveQueryName={setSaveQueryName}
                        collectionModal={collectionModal}
                        setCollectionModal={setCollectionModal}
                        collectionPrivacy={collectionPrivacy}
                        setCollectionPrivacy={setCollectionPrivacy}
                        collectionShareList={collectionShareList}
                        isQuickSearch={true}
                    />
                )}
            </div>
        </div>
    )
}
