import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import { Input, Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import { AiFillCloseCircle, AiOutlineSave, AiOutlinePlus } from 'react-icons/ai'
import './Filter.css'
import { toast } from 'react-toastify'
import { BiSearch, BiEraser } from 'react-icons/bi'
import { getYMD } from '../../../utils/dateHelpers'
import { components } from 'react-select'
import makeAnimated from 'react-select/animated'
import NoResults from '../../../components/NoResults/NoResults'
import ResultHeader from './ResultsHeader'
import SearchQueryView from '../../../components/NewsEditedCard/SearchQueryView'
import InfiniteScroll from 'react-infinite-scroller'
import { Circles } from 'react-loader-spinner'
import { Grid } from '@mui/material'
import axios from 'axios'

export default function QuerySearch(props) {
    const { editPage, setEditPage } = props
    const {
        setAdvanceResponse,
        isQueryEditorJson,
        setIsQueryEditorJson,
        filtersToAdd,
        querySearch,
        updateQuerySearch,
        saveModal,
        setSaveModal,
        advanceResponse,
        isAllSelected,
        setAllSelected,
        expandedView,
        setExpandedView,
        selectedNewsOnly,
        setSelectedNewsOnly,
        selectedStories,
        setSelectedStories,
        allCategories,
        allLinkedInGroups,
        removeFromSelection,
        addToSelection,
        handleLoadMore,
        usertype,
        isLoading,
        isQueryEditor,
        setSelectedLevel,
        selectedLevel,
        preeditPage,
        setPreeditPage,
        predefinedSearch,
        premeetingPlan,
        setPremeetingPlan,
        currentPath,
        setAllCompanies,
    } = props

    const { allRegions, allSources, allSubCategories, allCompanies, allTopics, allIndustries, regionHierarchy } = props
    const [sliderValue, setSliderValue] = useState('7')
    const [sliderShow, setSliderShow] = useState(false)
    const [addFilter, setAddFilter] = useState(false)
    const [showFilterInput, setShowFilterInput] = useState('')
    const [filterInputValue, setFilterInputValue] = useState('')
    const [filterInputTextValue, setFilterInputTextValue] = useState('')
    const [optionSelected, setOptionSelected] = useState([])
    const [regionResult, setRegionResult] = useState([])
    const [companyResult, setCompanyResult] = useState([])
    const [topicResult, setTopicResult] = useState([])
    const [sectorResult, setSectorResult] = useState([])
    const [sourceResult, setSourceResult] = useState([])
    const [categoryResult, setCategoryResult] = useState([])
    const [provinceResult, setProvinceResult] = useState([])
    const [countryResult, setCountryResult] = useState([])
    const [allHierRegions, setAllHierRegions] = useState([])
    const [allCountry, setAllCountry] = useState([])
    const [allProvince, setAllProvince] = useState([])
    ////
    const [curationResult, setCurationResult] = useState([])
    const [dateResult, setDateResult] = useState([])
    const [curationValue, setCurationValue] = useState('level1')
    const [defaultData, setDefaultData] = useState([])
    useEffect(() => {
        setDefaultData(allCompanies)

        const allReg = []
        const allCty = []
        const allPro = []
        regionHierarchy.forEach(reg => {
            allReg.push({
                value: reg.id,
                label: reg.name,
                type: reg.type,
            })

            reg.countries.forEach(cty => {
                allCty.push({
                    value: cty.id,
                    label: cty.name,
                    type: cty.type,
                })

                cty.provinces &&
                    cty.provinces.forEach(pro => {
                        allPro.push({
                            value: pro.id,
                            label: pro.name,
                            type: pro.type,
                        })
                    })
            })
        })
        setAllHierRegions(allReg)
        setAllCountry(allCty)
        setAllProvince(allPro)
    }, [])

    const wrapperRef = useRef(null)
    closeExpandable(wrapperRef)
    ////

    const curationOption = [
        { value: 'level1', label: 'level1' },
        { value: 'level2', label: 'level2' },
    ]

    const dateOption = [
        { value: '0', label: 'Today' },
        { value: '7', label: 'Last 7 days' },
        { value: '30', label: 'Last 30 days' },
        { value: '60', label: 'Last 60 days' },
        { value: '90', label: 'Last 90 days' },
        { value: '365', label: 'Last 1 year' },
    ]
    // useEffect(() => {
    //     let out = 'region:('
    //     regionResult.forEach(value => {
    //         out = value + 'OR'
    //     })
    //     out += ')'

    // }, [regionResult, companyResult, topicResult, sourceResult, categoryResult, sectorResult])
    function closeExpandable(ref) {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setSliderShow(false)
                setAddFilter(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }

    const Option = props => {
        return (
            <div>
                <components.Option {...props}>
                    <input type="checkbox" checked={props.isSelected} onChange={() => null} />{' '}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        )
    }
    const MultiValue = props => (
        <components.MultiValue {...props}>
            <span>{props.data.label}</span>
        </components.MultiValue>
    )

    const animatedComponents = makeAnimated()

    const filterSelectOptions = () => {
        const strOfQuery = isQueryEditorJson
        if (
            (showFilterInput === 'Country' ||
                showFilterInput === 'Province' ||
                showFilterInput === 'Region' ||
                showFilterInput === 'Company' ||
                showFilterInput === 'Category' ||
                showFilterInput === 'Source' ||
                showFilterInput === 'Topic' ||
                showFilterInput === 'Sectors' ||
                showFilterInput === 'Curation Level' ||
                showFilterInput === 'Article date') &&
            (strOfQuery.includes('countries:') ||
                strOfQuery.includes('provinces:') ||
                strOfQuery.includes('regions:') ||
                strOfQuery.includes('company:') ||
                strOfQuery.includes('category:') ||
                strOfQuery.includes('topics:') ||
                strOfQuery.includes('source:') ||
                strOfQuery.includes('sectors:') ||
                strOfQuery.includes('Curation Level:') ||
                strOfQuery.includes('Article date:'))
        ) {
            const query = strOfQuery

            const startIndex =
                showFilterInput === 'Country'
                    ? query.indexOf('countries:')
                    : showFilterInput === 'Province'
                    ? query.indexOf('provinces:')
                    : showFilterInput === 'Region'
                    ? query.indexOf('regions:')
                    : showFilterInput === 'Company'
                    ? query.indexOf('company:')
                    : showFilterInput === 'Category'
                    ? query.indexOf('category:')
                    : showFilterInput === 'Sectors'
                    ? query.indexOf('sectors:')
                    : showFilterInput === 'Topic'
                    ? query.indexOf('topics:')
                    : showFilterInput === 'Source'
                    ? query.indexOf('source:')
                    : showFilterInput === 'Curation Level'
                    ? query.indexOf('Curation Level:')
                    : query.indexOf('Article date:')

            if (startIndex !== -1) {
                const endIndex = query.indexOf(')', startIndex)
                if (endIndex !== -1) {
                    const output = query.substring(startIndex, endIndex + 1)
                    const str = `'${output}'`
                    const match = str.match(/"(.*?)"/g)
                    const Result = match.map(match => match.replace(/"/g, ''))
                    const Res = Result.map(val => val.toLowerCase())
                    //set
                    showFilterInput === 'Country'
                        ? setCountryResult(Res)
                        : showFilterInput === 'Province'
                        ? setProvinceResult(Res)
                        : showFilterInput === 'Region'
                        ? setRegionResult(Res)
                        : showFilterInput === 'Company'
                        ? setCompanyResult(Res)
                        : showFilterInput === 'Category'
                        ? setCategoryResult(Res)
                        : showFilterInput === 'Sectors'
                        ? setSectorResult(Res)
                        : showFilterInput === 'Topic'
                        ? setTopicResult(Res)
                        : setSourceResult(Res)
                }
            }
        }
    }
    ////////

    const addSingleValue = type => {
        if (type === 'date') {
            var start = new Date()
            start.setDate(start.getDate() - sliderValue)
            if (isQueryEditorJson.includes('articleDate:')) {
                // const query = isQueryEditorJson
                // const newFromDate = getYMD(start)
                // const newToDate = getYMD(new Date())

                // const fromIndex = query.indexOf('[', query.indexOf('articleDate:')) + 1
                // const toIndex = query.indexOf(']', fromIndex)

                // const fromDate = query.substring(fromIndex, toIndex).split(' TO ')[0]
                // const toDate = query.substring(fromIndex, toIndex).split(' TO ')[1]
                // const updatedRange = `${newFromDate} TO ${newToDate}`

                // const updatedQuery = query.replace(`${fromDate} TO ${toDate}`, updatedRange)

                const inputString = isQueryEditorJson
                const replacement =
                    sliderValue === '0'
                        ? 'now/d TO now+1d'
                        : sliderValue === '365'
                        ? 'now/d-1y/d TO now/d'
                        : 'now/d-' + sliderValue + 'd' + ' TO ' + 'now/d'

                const regex = /articleDate:\[(.*?)\]/
                const result = inputString.replace(regex, `articleDate:[${replacement}]`)
                setIsQueryEditorJson(result)
            } else {
                const relativeTime =
                    sliderValue === '0'
                        ? 'now/d TO now+1d'
                        : sliderValue === '365'
                        ? 'now/d-1y/d TO now/d'
                        : 'now/d-' + sliderValue + 'd' + ' TO ' + 'now/d'

                setIsQueryEditorJson(
                    isQueryEditorJson !== ''
                        ? isQueryEditorJson + ' AND ' + `articleDate:[${relativeTime}]`
                        : isQueryEditorJson + `articleDate:[${relativeTime}]`
                )
            }
        }
        if (type === 'curation') {
            if (isQueryEditorJson.includes('curationLevel:')) {
                const query = isQueryEditorJson
                const replacedString = query.replace(/curationLevel:\(.*?\)/, `curationLevel:("${curationValue}")`)

                setIsQueryEditorJson(replacedString)
            } else {
                setIsQueryEditorJson(
                    isQueryEditorJson !== ''
                        ? isQueryEditorJson + ' AND ' + `curationLevel:("${curationValue}")`
                        : isQueryEditorJson + `curationLevel:("${curationValue}")`
                )
            }
        }
        setAddFilter(false)
    }

    const addDateFilter = () => {
        setSliderShow(false)
        var start = new Date()
        start.setDate(start.getDate() - sliderValue)
        if (isQueryEditorJson.includes('articleDate:')) {
            const query = isQueryEditorJson
            const newFromDate = getYMD(start)
            const newToDate = getYMD(new Date())

            const fromIndex = query.indexOf('[', query.indexOf('articleDate:')) + 1
            const toIndex = query.indexOf(']', fromIndex)

            const fromDate = query.substring(fromIndex, toIndex).split(' TO ')[0]
            const toDate = query.substring(fromIndex, toIndex).split(' TO ')[1]
            const updatedRange = `${newFromDate} TO ${newToDate}`

            const updatedQuery = query.replace(`${fromDate} TO ${toDate}`, updatedRange)

            setIsQueryEditorJson(updatedQuery)
        } else {
            setIsQueryEditorJson(
                isQueryEditorJson !== ''
                    ? isQueryEditorJson + ' AND ' + `articleDate:[${getYMD(start) + ' TO ' + getYMD(new Date())}]`
                    : isQueryEditorJson + `articleDate:[${getYMD(start) + ' TO ' + getYMD(new Date())}]`
            )
        }
    }

    const textAreaFiltersAdding = val => {
        if (filterInputTextValue !== '' || filterInputValue !== '') {
            val === 'Newstitle' || val === 'NewsContent'
                ? setIsQueryEditorJson(
                      isQueryEditorJson.includes(val === 'Newstitle' ? 'newsTitle' : 'newsContent')
                          ? isQueryEditorJson.replace(
                                `${val === 'Newstitle' ? 'newsTitle' : 'newsContent'}:(`,
                                `${val === 'Newstitle' ? 'newsTitle' : 'newsContent'}:("${filterInputTextValue}" or `
                            )
                          : isQueryEditorJson === ''
                          ? isQueryEditorJson +
                            (val === 'Newstitle' ? 'newsTitle' : 'newsContent') +
                            ':' +
                            '("' +
                            filterInputTextValue +
                            '")'
                          : isQueryEditorJson +
                            ' ' +
                            'AND' +
                            ' ' +
                            (val === 'Newstitle' ? 'newsTitle' : 'newsContent') +
                            ':' +
                            '("' +
                            filterInputTextValue +
                            '")'
                  )
                : setIsQueryEditorJson(
                      isQueryEditorJson.includes(
                          val === 'Country'
                              ? 'countries'
                              : val === 'Province'
                              ? 'provinces'
                              : val === 'Region'
                              ? 'regions'
                              : val === 'Company'
                              ? 'company'
                              : val === 'Topic'
                              ? 'topics'
                              : val === 'Category'
                              ? 'category'
                              : val === 'Sectors'
                              ? 'sectors'
                              : 'source'
                      )
                          ? isQueryEditorJson.replace(
                                `${
                                    val === 'Country'
                                        ? 'countries'
                                        : val === 'Province'
                                        ? 'provinces'
                                        : val === 'Region'
                                        ? 'regions'
                                        : val === 'Company'
                                        ? 'company'
                                        : val === 'Topic'
                                        ? 'topics'
                                        : val === 'Category'
                                        ? 'category'
                                        : val === 'Sectors'
                                        ? 'sectors'
                                        : 'source'
                                }:(`,
                                `${
                                    val === 'Country'
                                        ? 'countries'
                                        : val === 'Province'
                                        ? 'provinces'
                                        : val === 'Region'
                                        ? 'regions'
                                        : val === 'Company'
                                        ? 'company'
                                        : val === 'Topic'
                                        ? 'topics'
                                        : val === 'Category'
                                        ? 'category'
                                        : val === 'Sectors'
                                        ? 'sectors'
                                        : 'source'
                                }:(${filterInputValue} or `
                            )
                          : isQueryEditorJson === ''
                          ? isQueryEditorJson +
                            (val === 'Country'
                                ? 'countries'
                                : val === 'Province'
                                ? 'provinces'
                                : val === 'Region'
                                ? 'regions'
                                : val === 'Company'
                                ? 'company'
                                : val === 'Topic'
                                ? 'topics'
                                : val === 'Category'
                                ? 'category'
                                : val === 'Sectors'
                                ? 'sectors'
                                : 'source') +
                            ':' +
                            '(' +
                            filterInputValue +
                            ')'
                          : isQueryEditorJson +
                            ' ' +
                            'AND' +
                            ' ' +
                            (val === 'Country'
                                ? 'countries'
                                : val === 'Province'
                                ? 'provinces'
                                : val === 'Region'
                                ? 'regions'
                                : val === 'Company'
                                ? 'company'
                                : val === 'Topic'
                                ? 'topics'
                                : val === 'Category'
                                ? 'category'
                                : val === 'Sectors'
                                ? 'sectors'
                                : 'source') +
                            ':' +
                            '(' +
                            filterInputValue +
                            ')'
                  )
            setAddFilter(false)
            setFilterInputValue('')
            setFilterInputTextValue('')
            setShowFilterInput('')
        } else {
            toast('Select the required field.')
        }
    }

    const saveEditBtn = () => {
        if (editPage === true) {
            // updateQuerySearch()
            setSaveModal(!saveModal)
        } else if (preeditPage === true) {
            // predefinedSearch()
            setSaveModal(!saveModal)
        } else {
            setSaveModal(!saveModal)
        }
    }

    const options =
        currentPath === '/app/search/' || currentPath === '/app/publish-search/'
            ? [
                  { value: 'level0', label: 'Level 0' },
                  { value: 'level1', label: 'Level 1' },
                  { value: 'level2', label: 'Level 2' },
              ]
            : [
                  { value: 'level0', label: 'Level 0' },
                  { value: 'level1', label: 'Level 1' },
              ]

    return (
        <>
            <div
                className="query_editor"
                style={{
                    background: 'white',
                    padding: '20px 10px 10px 10px',
                    marginTop: '-2px',
                    width: '99%',
                }}
            >
                <div
                    className="sections"
                    style={{
                        display:
                            (usertype !== 'Regular User' && currentPath === '/app/search/') ||
                            currentPath === '/app/raw-search/'
                                ? 'block'
                                : 'none',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            marginBottom: '10px',
                        }}
                        id="level-section"
                    >
                        <label className="filter_label" style={{ marginLeft: '2%' }}>
                            Curation Level
                        </label>
                        <span className="filter_span" style={{ fontSize: '12px' }}>
                            <Input
                                onChange={e => {
                                    setSelectedLevel(e.target.value)
                                    setAdvanceResponse({ data: [], next: null })
                                    setSelectedStories([])
                                }}
                                value={selectedLevel}
                                type="select"
                                style={{
                                    height: '37px',
                                    width: '100px',
                                    fontSize: '10px',
                                    marginTop: '-6px',
                                    fontWeight: '600',
                                    borderRadius: '6px',
                                    color: 'hsl(0,0%,50%)',
                                    marginLeft: '-80px',
                                }}
                            >
                                {options.map(val => (
                                    <option value={val.value}>{val.label}</option>
                                ))}
                            </Input>
                        </span>
                    </div>
                </div>
                <div
                    style={{
                        width: '90%',
                        margin: 'auto',
                        border: '1px solid #dad8d8',
                        borderRadius: '3px',
                        background: '#f7f7f7',
                    }}
                >
                    <textarea
                        className="query_editor_textarea"
                        type="text"
                        value={isQueryEditorJson}
                        id="textarea_edit_query"
                        style={{
                            height: '170px',
                            width: '96%',
                            outline: 'none',
                            border: 'none',
                            resize: 'none',
                            background: '#f7f7f7',
                        }}
                        onChange={e => {
                            setIsQueryEditorJson(e.target.value)
                        }}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                e.preventDefault()
                                querySearch()
                            }
                        }}
                    />

                    <span
                        className="remove_btn_query_editor"
                        style={{
                            position: 'absolute',
                            cursor: 'pointer',
                        }}
                    >
                        <BiEraser
                            style={{ fontSize: '20px' }}
                            onClick={() => {
                                setIsQueryEditorJson('')
                                setAdvanceResponse({ data: [], next: null })
                            }}
                        />
                    </span>
                </div>
                {/* add filters */}
                <Modal
                    isOpen={addFilter}
                    toggle={() => {
                        setAddFilter(false)
                        setFilterInputValue('')
                        setFilterInputTextValue('')
                        setShowFilterInput('')
                    }}
                    // size="lg"
                    style={{ width: '850px', marginLeft: '50%', marginTop: '7%' }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '10px',
                            background: 'black',
                            color: 'white',
                        }}
                    >
                        <span style={{ color: 'white', marginLeft: '2%' }}>Add Filter</span>
                        <span
                            onClick={() => {
                                setAddFilter(!addFilter)
                            }}
                            className="modal_close_btn"
                            style={{ marginRight: '2%' }}
                        >
                            <AiFillCloseCircle />
                        </span>
                    </div>

                    <ModalBody>
                        <div className="row">
                            {filtersToAdd.map(val => (
                                <>
                                    <div
                                        className="d-flex"
                                        style={{
                                            margin: val === 'NewsContent' ? '10px' : '3px auto',
                                            width: '230px',
                                            cursor: 'pointer',
                                            height: 'auto',
                                            border: '1px solid #dad8d8',
                                            borderRadius: '4px',
                                            background: val === showFilterInput ? '#197bbd' : 'white',
                                            color: val === showFilterInput ? 'white' : '',
                                        }}
                                        onClick={() => {
                                            setShowFilterInput(val)
                                            setFilterInputTextValue('')
                                            setFilterInputValue('')
                                            setOptionSelected([])
                                        }}
                                    >
                                        <div
                                            style={{
                                                cursor: 'pointer',
                                                margin: '3% 2%',

                                                background: val === showFilterInput ? '#197bbd' : 'white',
                                                color: val === showFilterInput ? 'white' : '',
                                            }}
                                        >
                                            <label style={{ color: val === showFilterInput ? 'white' : '' }}>
                                                {val === 'Newstitle'
                                                    ? 'News Title'
                                                    : val === 'NewsContent'
                                                    ? 'News Content'
                                                    : val === 'Category'
                                                    ? 'Business Events'
                                                    : val}
                                            </label>
                                            <div style={{ fontSize: '12px' }}>
                                                Click to add {val === 'Category' ? 'Business Events' : val}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))}
                            <div
                                style={{
                                    display: showFilterInput !== '' ? '' : 'none',
                                    margin: 'auto',
                                    marginTop: '2%',
                                    background: 'white',
                                    width: '97%',
                                    borderRadius: '4px',
                                    border: '1px solid #dad8d8',
                                }}
                            >
                                <div
                                    className="d-flex justify-content-between"
                                    style={{ padding: '20px 10px 20px 10px' }}
                                >
                                    <div className="" onClick={filterSelectOptions}>
                                        {showFilterInput === 'Newstitle' || showFilterInput === 'NewsContent' ? (
                                            <Input
                                                placeholder={
                                                    showFilterInput === 'Newstitle'
                                                        ? 'Enter news title'
                                                        : 'Enter news content'
                                                }
                                                style={{ width: '320px', height: '37px' }}
                                                value={filterInputTextValue}
                                                onChange={e => {
                                                    setFilterInputTextValue(e.target.value)
                                                }}
                                            />
                                        ) : (
                                            <Select
                                                menuPlacement="top"
                                                className="filter_select_input"
                                                isMulti={
                                                    showFilterInput === 'Curation Level' ||
                                                    showFilterInput === 'Article date'
                                                        ? false
                                                        : true
                                                }
                                                placeholder={
                                                    showFilterInput === 'Country'
                                                        ? 'Select country'
                                                        : showFilterInput === 'Province'
                                                        ? 'Select province'
                                                        : showFilterInput === 'Region'
                                                        ? 'Select region'
                                                        : showFilterInput === 'Company'
                                                        ? 'Select company'
                                                        : showFilterInput === 'Topic'
                                                        ? 'Select topic'
                                                        : showFilterInput === 'Category'
                                                        ? 'Select business events'
                                                        : showFilterInput === 'Sectors'
                                                        ? 'Select sector'
                                                        : showFilterInput === 'Source'
                                                        ? 'Select Source'
                                                        : showFilterInput === 'Article date'
                                                        ? 'Select Article date'
                                                        : 'Select Curation Level'
                                                }
                                                options={
                                                    showFilterInput === 'Country'
                                                        ? allCountry
                                                              .filter(
                                                                  item =>
                                                                      !countryResult.includes(item.label.toLowerCase())
                                                              )
                                                              .map(item => item)
                                                        : showFilterInput === 'Region'
                                                        ? allHierRegions
                                                              .filter(
                                                                  item =>
                                                                      !regionResult.includes(item.label.toLowerCase())
                                                              )
                                                              .map(item => item)
                                                        : showFilterInput === 'Province'
                                                        ? allProvince
                                                              .filter(
                                                                  item =>
                                                                      !provinceResult.includes(item.label.toLowerCase())
                                                              )
                                                              .map(item => item)
                                                        : showFilterInput === 'Company'
                                                        ? allCompanies
                                                              .filter(
                                                                  item =>
                                                                      !companyResult.includes(item.label.toLowerCase())
                                                              )
                                                              .map(item => item)
                                                        : showFilterInput === 'Topic'
                                                        ? allTopics
                                                              .filter(
                                                                  item =>
                                                                      !topicResult.includes(item.label.toLowerCase())
                                                              )
                                                              .map(item => item)
                                                        : showFilterInput === 'Category'
                                                        ? allSubCategories
                                                              .filter(
                                                                  item =>
                                                                      !categoryResult.includes(item.label.toLowerCase())
                                                              )
                                                              .map(item => item)
                                                        : showFilterInput === 'Sectors'
                                                        ? allIndustries
                                                              .filter(
                                                                  item =>
                                                                      !sectorResult.includes(item.label.toLowerCase())
                                                              )
                                                              .map(item => item)
                                                        : showFilterInput === 'Source'
                                                        ? allSources
                                                              .filter(
                                                                  item =>
                                                                      !sourceResult.includes(item.value.toLowerCase())
                                                              )
                                                              .map(item => item)
                                                        : showFilterInput === 'Curation Level'
                                                        ? curationOption
                                                        : dateOption
                                                }
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                components={{
                                                    Option,
                                                    MultiValue,
                                                    animatedComponents,
                                                }}
                                                onInputChange={e => {
                                                    if (e.length > 2 && showFilterInput === 'Company') {
                                                        axios
                                                            .get(
                                                                `/news/auto-complete/?type=company&string=${encodeURIComponent(
                                                                    e
                                                                )}`
                                                            )
                                                            .then(res => {
                                                                const filteredData = res.data.map(item => ({
                                                                    label: item.name,
                                                                    value: item.id,
                                                                }))
                                                                setAllCompanies(filteredData)
                                                            })
                                                            .catch(err => console.log(err))
                                                    } else {
                                                        setAllCompanies(allCompanies)
                                                    }
                                                }}
                                                onChange={selected => {
                                                    setOptionSelected(selected)

                                                    if (showFilterInput === 'Article date') {
                                                        setSliderValue(selected.value)
                                                    } else if (showFilterInput === 'Curation Level') {
                                                        setCurationValue(selected.value)
                                                    } else {
                                                        if (selected && selected.length !== 0) {
                                                            setFilterInputValue(
                                                                selected
                                                                    .map(
                                                                        val =>
                                                                            `"${
                                                                                showFilterInput === 'Source'
                                                                                    ? val.value
                                                                                    : val.label
                                                                            }"`
                                                                    )
                                                                    .join(' or ')
                                                            )
                                                        }
                                                    }
                                                }}
                                                allowSelectAll={true}
                                                value={optionSelected}
                                                // value={
                                                //     showFilterInput === 'Region'
                                                //         ? [
                                                //               ...new Set(
                                                //                   allRegions
                                                //                       .filter(reg => regionResult.includes(reg.label))
                                                //                       .concat(optionSelected)
                                                //               ),
                                                //           ]
                                                //         : showFilterInput === 'Company'
                                                //         ? optionSelected.concat(
                                                //               allCompanies
                                                //                   .filter(item => companyResult.includes(item.label))
                                                //                   .map(item => item)
                                                //           )
                                                //         : showFilterInput === 'Topic'
                                                //         ? optionSelected.concat(
                                                //               allTopics
                                                //                   .filter(item => topicResult.includes(item.label))
                                                //                   .map(item => item)
                                                //           )
                                                //         : showFilterInput === 'Category'
                                                //         ? optionSelected.concat(
                                                //               allSubCategories
                                                //                   .filter(item => categoryResult.includes(item.label))
                                                //                   .map(item => item)
                                                //           )
                                                //         : showFilterInput === 'Sectors'
                                                //         ? optionSelected.concat(
                                                //               allIndustries
                                                //                   .filter(item => sectorResult.includes(item.label))
                                                //                   .map(item => item)
                                                //           )
                                                //         : optionSelected.concat(
                                                //               allSources
                                                //                   .filter(item => sourceResult.includes(item.label))
                                                //                   .map(item => item)
                                                //           )
                                                // }
                                            />
                                        )}
                                    </div>
                                    <Button
                                        style={{ height: '35px' }}
                                        className="btn btn-primary"
                                        onClick={
                                            showFilterInput === 'Article date'
                                                ? () => {
                                                      addSingleValue('date')
                                                  }
                                                : showFilterInput === 'Curation Level'
                                                ? () => {
                                                      addSingleValue('curation')
                                                  }
                                                : () => textAreaFiltersAdding(showFilterInput)
                                        }
                                    >
                                        OK
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                <div
                    className="date_slider d-flex jsutify-content_between"
                    style={{ width: '90%', margin: 'auto', marginTop: '2%' }}
                >
                    {/* <Button
                        onClick={() => {
                            setSliderShow(!sliderShow)
                        }}
                        className="btn_color btn_text"
                    >
                        Published Since
                    </Button>

                    {sliderShow && (
                        <div
                            style={{
                                width: '300px',
                                height: '150px',
                                borderRadius: '3px',
                                position: 'absolute',
                                border: '1px solid lightgray',
                                background: 'white',
                                marginTop: '4%',
                                // boxShadow: '5px 0px 5px 5px',
                            }}
                            ref={wrapperRef}
                        >
                            <div className="d-flex justify-content-between" style={{ padding: '10px' }}>
                                <label style={{ marginTop: '3%' }}>Published Since</label>
                                <input
                                    type="text"
                                    style={{
                                        outline: 'none',
                                        width: '100px',
                                        height: '35px',
                                        marginRight: '5px',
                                        border: '1px solid lightgray',
                                        borderRadius: '3px',
                                        fontSize: '12px',
                                    }}
                                    value={sliderValue + ' Days Ago'}
                                    readOnly
                                />
                            </div>

                            <input
                                style={{ width: '250px', marginLeft: '7%', marginTop: '2%' }}
                                type="range"
                                class="form-range"
                                min="1"
                                max="30"
                                id="customRange"
                                value={sliderValue}
                                onChange={e => {
                                    setSliderValue(e.target.value)
                                    console.log(e.target.value)
                                }}
                            />
                            <button
                                className="btn btn-primary"
                                style={{ marginLeft: '40%', borderRadius: '30px' }}
                                onClick={addDateFilter}
                            >
                                Set
                            </button>
                        </div>
                    )} */}
                    {/* </div> */}

                    <Button
                        onClick={() => {
                            setAddFilter(!addFilter)
                        }}
                        className="btn_color btn_text"
                        style={{ marginLeft: '10px' }}
                    >
                        Add Filter <AiOutlinePlus />
                    </Button>
                    {/* </div> */}
                    <Button
                        className="btn_color btn_text"
                        style={{
                            marginLeft: 'auto',
                        }}
                        onClick={querySearch}
                    >
                        Search
                        <span style={{ fontSize: '18px', marginLeft: '5px' }}>
                            <BiSearch />
                        </span>
                    </Button>
                    <Button className="btn_color btn_text" style={{ marginLeft: '20px' }} onClick={saveEditBtn}>
                        {editPage ? 'Update' : preeditPage ? 'Save As' : 'Save'}
                        <span style={{ fontSize: '18px', marginLeft: '5px' }}>
                            <AiOutlineSave />
                        </span>
                    </Button>
                </div>
            </div>
            <div>
                {advanceResponse &&
                advanceResponse.data &&
                advanceResponse.data.data &&
                (advanceResponse.data.data.length > 0 ||
                    advanceResponse.data.data.filter(
                        val => (val.title && val.title.length > 0) || (val.title && val.title !== '') || !val.title
                    ).length > 0) ? (
                    <div style={{ width: '92%' }}>
                        <ResultHeader
                            style={{ fontSize: '20px' }}
                            isBasicSearchPage={true}
                            allSelected={isAllSelected}
                            setAllSelected={setAllSelected}
                            resultLength={
                                advanceResponse.data.next -
                                advanceResponse.data.data.filter(
                                    val =>
                                        (val.title && val.title.length < 0) ||
                                        (val.title && val.title === '') ||
                                        !val.title
                                ).length
                            }
                            resultCount={
                                advanceResponse.data.total -
                                advanceResponse.data.data.filter(
                                    val =>
                                        (val.title && val.title.length < 0) ||
                                        (val.title && val.title === '') ||
                                        !val.title
                                ).length
                            }
                            expanded={expandedView}
                            setExpanded={setExpandedView}
                            selectedNewsOnly={selectedNewsOnly}
                            setSelectedNewsOnly={setSelectedNewsOnly}
                            premeetingPlan={premeetingPlan}
                            setPremeetingPlan={setPremeetingPlan}
                            selectedStoriesLength={selectedStories.length}
                        />
                        <Grid item>
                            {selectedNewsOnly ? (
                                selectedStories.map(story => {
                                    return (
                                        <SearchQueryView
                                            isFindAndShare={true}
                                            key={story.id}
                                            EachrawNews={story}
                                            isBasicSearch={true}
                                            selectedStories={selectedStories}
                                            isStorySelected={
                                                selectedStories.findIndex(arr => arr.id === story.id) === -1
                                                    ? false
                                                    : true
                                            }
                                            addToSelection={addToSelection}
                                            allCategories={allCategories}
                                            allSubCategories={allSubCategories}
                                            allIndustries={allIndustries}
                                            allCompanies={allCompanies}
                                            allRegions={allRegions}
                                            allLinkedInGroups={allLinkedInGroups}
                                            removeFromSelection={removeFromSelection}
                                            allTopics={allTopics}
                                            collapsed={!expandedView}
                                            usertype={usertype}
                                            selectAll={isAllSelected}
                                            currentPath={currentPath}
                                            selectedNewsOnly={selectedNewsOnly}
                                        />
                                    )
                                })
                            ) : (
                                <>
                                    <InfiniteScroll
                                        pageStart={0}
                                        loadMore={handleLoadMore}
                                        hasMore={advanceResponse.data.next < advanceResponse.data.total}
                                        loader={
                                            <Circles key={0} type="Circles" color="#adadad" height={80} width={80} />
                                        }
                                    >
                                        {advanceResponse.data.data.map(story => {
                                            return (
                                                <SearchQueryView
                                                    isFindAndShare={true}
                                                    key={story.id}
                                                    EachrawNews={story}
                                                    isBasicSearch={true}
                                                    selectedStories={selectedStories}
                                                    isStorySelected={
                                                        selectedStories.findIndex(arr => arr.id === story.id) === -1
                                                            ? false
                                                            : true
                                                    }
                                                    addToSelection={addToSelection}
                                                    allCategories={allCategories}
                                                    allSubCategories={allSubCategories}
                                                    allIndustries={allIndustries}
                                                    allCompanies={allCompanies}
                                                    setAllCompanies={setAllCompanies}
                                                    allRegions={allRegions}
                                                    allLinkedInGroups={allLinkedInGroups}
                                                    removeFromSelection={removeFromSelection}
                                                    allTopics={allTopics}
                                                    collapsed={!expandedView}
                                                    usertype={usertype}
                                                    selectAll={isAllSelected}
                                                    currentPath={currentPath}
                                                    selectedNewsOnly={selectedNewsOnly}
                                                />
                                            )
                                        })}
                                    </InfiniteScroll>
                                </>
                            )}
                        </Grid>
                    </div>
                ) : isLoading ? (
                    <div style={{ marginLeft: '40%', marginTop: '10px' }}>
                        <Circles key={0} type="Circles" color="#adadad" height={80} width={80} />
                    </div>
                ) : (
                    <div style={{ marginLeft: '36%', marginTop: '3%' }}>
                        <NoResults />
                    </div>
                )}
            </div>
        </>
    )
}
