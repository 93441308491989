import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { subDays, addDays, format, endOfDay } from 'date-fns'
import { Circles } from 'react-loader-spinner'
import InfiniteScroll from 'react-infinite-scroller'
import BaseScreen from '../BaseScreen/BaseScreen'
import NoResults from '../../components/NoResults/NoResults'
import NewsFeedFilterHeader from './components/NewsFeedFilterHeader'
import NewsEditCardAnalyst from '../../components/NewsEditedCard/NewsEditCardAnalyst'
import { getCookie } from '../../utils/index'
import '../../components/NewsEditedCard/NewsEditCard2020.css'
import ResultHeader from './components/ResultsHeader'
import { Steppers, StepperButton } from './components/Steppers'
import SelectTemplate from './components/SelectTemplate'
import TemplatePreview from './components/TemplatePreview'
import SendNewsLetter from './components/SendNewsLetter'
import { Grid, CircularProgress } from '@mui/material'
import store from '../../Store'
import SendIcon from '../../assets/icons/icon-ionic-ios-send.svg'
import deleteBin from '../../assets/icons/delete.svg'
import EyeIcon from '../../assets/icons/eye.svg'
import { toast } from 'react-toastify'

import {
    getIndustryString,
    getSectorString,
    getCompanyString,
    getRegionString,
    getPullTypeString,
    getTopicsString,
    getSubcategoriesIdsString,
    getRegString,
    getProvinceString,
} from './urlFilterFunctions'
import DeleteNews from './components/DeleteNews'

var lo_ = require('lodash')
const token = getCookie('csrftoken')

export default function PublishedNews() {
    const [isLoading, setIsLoading] = useState(false)
    const [expandedView, setExpandedView] = useState(false)
    const [isAllSelected, setAllSelected] = useState(false)
    const [selectedNewsOnly, setSelectedNewsOnly] = useState(false)

    const [allCategories, setAllCategories] = useState([])
    const [allTopics, setAllTopics] = useState([])
    const [allIndustries, setAllIndustries] = useState([])
    const [allCompanies, setAllCompanies] = useState([])
    const [allLinkedInGroups, setAllLinkedInGroups] = useState([])
    const [allRegions, setAllRegions] = useState([])
    const [allSubCategoriesOnly, setAllSubCategoriesOnly] = useState([])

    const [publishedNews, setPublishedNews] = useState({})

    const [selectedStories, setSelectedStories] = useState([])
    const [selectedRegion, setSelectedRegion] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState()

    const [introText, setIntroText] = useState()
    const [subjectLine, setSubjectLine] = useState()

    const [allActiveEndUsers, setAllActiveEndUsers] = useState([])

    const [primaryColor, setPrimaryColor] = useState('')
    const [secondaryColor, setSecondaryColor] = useState('')
    const [title, setTitle] = useState('')

    const [allFirms, setAllFirms] = useState([])

    const [activeStep, setActiveStep] = useState(0)
    // Left Side Logo
    const [selectedLogo, setSelectedLogo] = useState('')
    const [logoManualImage, setLogoManualImage] = useState(false)
    const [logoFile, setLogoFile] = useState(null)
    const [logoFileName, setFileName] = useState('')
    const [logoSize, setLogoSize] = useState({})
    // Right Side Logo
    const [secondaryLogo, setSecondaryLogo] = useState('')
    const [logo2ManualImage, setLogo2ManualImage] = useState(false)
    const [logo2File, setLogo2File] = useState(null)
    const [seclogoFileName, setsecFileName] = useState('')
    const [secLogoSize, setSecLogoSize] = useState({})
    // Background Image
    const [selectedBg, setSelectedBg] = useState('')
    const [bgManualImage, setBgManualImage] = useState(false)
    const [bgFile, setBgFile] = useState(null)
    const [bgFileName, setBgFileName] = useState('')
    const [categorizedNews, setCategorizedNews] = useState([])
    const [hyperlink, setHyperlink] = useState('')
    const [content, setContent] = useState('')
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [regionHierarchy, setRegionHierarchy] = useState([])
    const usertype = store.getState().auth.user.user_type

    useEffect(() => {
        // setIsLoading(true)
        getAllPublishedNews()
        getAllCategories()
        getAllTopics()
        getAllCompanies()
        getAllRegions()
        getAllLinkedInGroups()
        getAllSubCategories()
        getRegionHierarchy()
        getIndustriesVerticalsSectors().then(() => setIsLoading(false))
    }, [])

    useEffect(() => {
        if (selectedTemplate) {
            setIntroText(selectedTemplate.introtext)
            setSubjectLine(selectedTemplate.subject)
            setPrimaryColor(selectedTemplate.primary_color)
            setSecondaryColor(selectedTemplate.secondary_color)
            setTitle(selectedTemplate.title)
            setSelectedLogo(selectedTemplate.logo)
            setLogoManualImage(false)
            setFileName(selectedTemplate.logoname)
            setSecondaryLogo(selectedTemplate.secondary_logo)
            setLogo2ManualImage(false)
            setsecFileName(selectedTemplate.seclogoname)
            setBgManualImage(false)
            setBgFileName(selectedTemplate.bgname)
            setSelectedBg(selectedTemplate.background_image)
        }
    }, [selectedTemplate])

    function resetPrimaryLogo() {
        setFileName('')
        setSelectedLogo('')
        setLogoFile(null)
        setLogoManualImage(false)
    }

    function resetSecondaryLogo() {
        setsecFileName('')
        setSecondaryLogo('')
        setLogo2File(null)
        setLogo2ManualImage(false)
    }

    const resetSteps = e => {
        setSelectedStories([])
        setSelectedTemplate()
        setSubjectLine()
        setIntroText()
        setActiveStep(0)
    }

    const handleTemplateSelected = (event, template) => {
        setSelectedTemplate(template)
    }

    const uploadFile = async (fileToUpload, type) => {
        let formData = new FormData()
        formData.append('logo', fileToUpload)
        formData.append('name', fileToUpload.name)
        formData.append('type', type)
        axios
            .post('/news/upload/', formData, {
                headers: { 'X-CSRFToken': token, 'Content-Type': 'multipart/form-data' },
            })
            .then(res => {
                if (res.status === 200) {
                    if (type === 'logo') {
                        setSelectedLogo(res.data.logo)
                        setLogoManualImage(false)
                        setLogoFile(null)
                    }
                    if (type === 'logo2') {
                        setSecondaryLogo(res.data.logo)
                        setLogo2ManualImage(false)
                        setLogo2File(null)
                    }
                    if (type === 'bg') {
                        setSelectedBg(res.data.logo)
                        setBgManualImage(false)
                        setBgFile(null)
                    }
                }
            })
    }

    useEffect(() => {
        async function uploadImages() {
            if (logoManualImage) {
                uploadFile(logoFile, 'logo')
            }
            if (bgManualImage) {
                uploadFile(bgFile, 'bg')
            }
            if (logo2ManualImage) {
                uploadFile(logo2File, 'logo2')
            }
        }
        if (activeStep === 3) {
            uploadImages()
        }
        if (activeStep + 1 === 3) {
            getAllFirms()
            getAllEndUsers()
        }
    }, [activeStep])

    useEffect(() => {
        isAllSelected
            ? setSelectedStories(current => [
                  ...current,
                  ...publishedNews.results.data.filter(each => !selectedStories.includes(each)),
              ])
            : setSelectedStories([])
    }, [isAllSelected])

    const getAllFirms = async () => {
        if (allFirms.length === 0) {
            const response = await axios.get('/analyst/firms/')
            setAllFirms(response.data.firms)
        }
    }

    const getAllEndUsers = async () => {
        if (allActiveEndUsers.length === 0) {
            const response = await axios.get('/endusers/all-endusers/')
            const activeAnalyst = await axios.get('/analyst/all-analysts/')
            const allEndUsers = [...response.data, ...activeAnalyst.data]
            setAllActiveEndUsers(allEndUsers)
        }
    }

    const getAllSubCategories = async () => {
        await axios
            .get('/news/all-subcategories/')
            .then(res => {
                setAllSubCategoriesOnly(res.data)
            })
            .catch(err => console.log(err))
    }

    const getRegionHierarchy = async () => {
        await axios.get('/news/region-countries-provinces/').then(response => {
            setRegionHierarchy(
                response.data.map(item => ({
                    ...item,
                    id: item.type + '_' + item.id,
                    countries: item.countries
                        ? item.countries.map(cty => ({
                              ...cty,
                              id: cty.type + '_' + cty.id,
                              provinces: cty.provinces
                                  ? cty.provinces.map(pro => ({
                                        ...pro,
                                        id: pro.type + '_' + pro.id,
                                    }))
                                  : [],
                          }))
                        : [],
                }))
            )
        })
    }

    const getAllPublishedNews = async () => {
        await axios
            .get(
                `
                /news/get-published-news/?newsStartDate=${format(subDays(new Date(), 365), 't')}&newsEndDate=${format(
                    addDays(new Date(), 1),
                    't'
                )}&newsPulledStartDate&newsPulledEndDate&newsPublishedStartDate=${format(subDays(new Date(), 7), 't')}
                &newsPublishedEndDate=${format(addDays(new Date(), 1), 't')}
                &industries&sectors&companies&subcategories&countries&sent=notSent&linkedin_groups&pullType&featured&underReview&topics&analysts`,
                {
                    headers: {
                        'Cache-Control': 'no-cache',
                    },
                }
            )
            .then(response => {
                setPublishedNews(response.data)
            })
    }

    const getFilteredRawNews = selectedFilters => {
        if (selectedFilters.companies === null) selectedFilters.companies = []
        if (selectedFilters.industries === null) selectedFilters.industries = []
        let featuredString
        if (selectedFilters.featured.value === 'true') {
            featuredString = 'featured=featured'
        } else if (selectedFilters.featured.value === 'false') {
            featuredString = 'featured=notFeatured'
        } else {
            featuredString = 'featured'
        }
        let reviewString = 'underReview'

        setIsLoading(true)

        const countryIds = selectedFilters.countries
            .filter(item => item.type === 'Country')
            .map(item => ({
                ...item,
                value: item.value.split('_')[1],
            }))
        const regionIds = selectedFilters.countries
            .filter(item => item.type === 'Region')
            .map(item => ({
                ...item,
                value: item.value.split('_')[1],
            }))
        const provinceIds = selectedFilters.countries
            .filter(item => item.type === 'Province')
            .map(item => ({
                ...item,
                value: item.value.split('_')[1],
            }))

        axios
            .get(
                `/news/get-published-news/?newsStartDate=${format(
                    selectedFilters.date.startDate,
                    't'
                )}&newsEndDate=${format(endOfDay(selectedFilters.date.endDate), 't')}&newsPulledStartDate=${
                    selectedFilters.pullDate.startDate ? format(selectedFilters.pullDate.startDate, 't') : ''
                }&newsPulledEndDate=${
                    selectedFilters.pullDate.endDate ? format(endOfDay(selectedFilters.pullDate.endDate), 't') : ''
                }&newsPublishedStartDate=${format(selectedFilters.publishedDate.startDate, 't')}
                &newsPublishedEndDate=${format(endOfDay(selectedFilters.publishedDate.endDate), 't')}
                &${getIndustryString(
                    selectedFilters.industries.filter(arr => arr.type === 'Industry')
                )}&${getSectorString(
                    selectedFilters.industries.filter(arr => arr.type === 'Sector')
                )}&${getCompanyString(selectedFilters.companies)}&subcategories${getSubcategoriesIdsString(
                    selectedFilters.subcategories
                )}&${getRegionString(countryIds)}&${getRegString(regionIds)}&${getProvinceString(provinceIds)}&sent=${
                    selectedFilters.sent.value
                }&linkedin_groups&${getPullTypeString(
                    selectedFilters.pullType.value
                )}&${featuredString}&${reviewString}&${getTopicsString(selectedFilters.topics)}&analysts&publisher${
                    selectedFilters.publisher !== '' ? '=' + selectedFilters.publisher.value : ''
                }&newsType=${selectedFilters.newsType.value}`,
                {
                    headers: {
                        'Cache-Control': 'no-cache',
                    },
                }
            )
            .then(response => {
                console.log('setting published news')
                setPublishedNews(response.data)
                setIsLoading(false)
            })
    }

    const getAllCategories = async () => {
        await axios.get('/news/categories/').then(response => {
            setAllCategories(response.data.results)
        })
    }

    const getAllTopics = async () => {
        await axios.get('/news/topics/').then(response => {
            let remappedTopics = response.data.map(item => {
                return { value: item.id, label: item.name }
            })
            remappedTopics = lo_.orderBy(remappedTopics, ['label'], ['asc'])

            setAllTopics(remappedTopics)
        })
    }

    const getAllCompanies = async () => {
        await axios.get(`/news/all-companies/`).then(response => {
            setAllCompanies(response.data)
        })
    }

    const getAllRegions = async () => {
        await axios.get(`/news/all-regions/`).then(response => {
            setAllRegions(response.data)
        })
    }

    const getAllLinkedInGroups = async () => {
        await axios.get(`/core/linkedin-groups/`).then(response => {
            const mappedData = response.data.map(item => {
                return {
                    label: item.group_name,
                    value: item.id,
                    industry: item.industry,
                    url: item.url,
                }
            })
            setAllLinkedInGroups(mappedData)
        })
    }

    const getIndustriesVerticalsSectors = async () => {
        await axios.get(`/news/all-industries-sectors/`).then(response => {
            const updatedIndustries = []
            response.data.forEach(item => {
                if (item.nick_name) {
                    const tempObj = { ...item }
                    tempObj.label = item.nick_name
                    updatedIndustries.push(tempObj)
                } else {
                    updatedIndustries.push(item)
                }
            })
            setAllIndustries(updatedIndustries)
        })
    }

    const handleLoadMore = () => {
        axios.get(publishedNews.next).then(response => {
            let newsBefore = [...publishedNews.results.data, ...response.data.results.data]
            setPublishedNews({
                count: response.data.count,
                next: response.data.next,
                previous: response.data.previous,
                results: { data: newsBefore },
            })
        })
    }

    const addToSelection = story => {
        setSelectedStories([...selectedStories, story])
    }

    const removeFromSelection = story => {
        let selectedCopy = selectedStories.filter(arr => arr.id !== story.id)
        setSelectedStories(selectedCopy)
    }

    const NewsFilter = (
        <NewsFeedFilterHeader
            getAllRawNews={getAllPublishedNews}
            getFilteredRawNews={getFilteredRawNews}
            allCategories={allCategories}
            allTopics={allTopics}
            allCompanies={allCompanies}
            allRegions={allRegions}
            allIndustries={allIndustries}
            publishedFilters={true}
            setSelectedStories={setSelectedStories}
            setSelectedRegion={setSelectedRegion}
            allSubCategoriesOnly={allSubCategoriesOnly}
            regionHierarchy={regionHierarchy}
        />
    )

    if (!publishedNews.results) {
        return (
            <BaseScreen>
                {NewsFilter}
                <Grid style={{ textAlign: 'center', marginTop: '30px' }}>
                    <CircularProgress />
                </Grid>
            </BaseScreen>
        )
    }

    const setLogo = e => {
        if (e.target.files[0]) {
            setSelectedLogo(URL.createObjectURL(e.target.files[0]))
            setLogoFile(e.target.files[0])
            setLogoManualImage(true)
            setFileName(e.target.files[0].name)
        }
    }

    const setsecondaryLogo = e => {
        if (e.target.files[0]) {
            setSecondaryLogo(URL.createObjectURL(e.target.files[0]))
            setLogo2File(e.target.files[0])
            setLogo2ManualImage(true)
            setsecFileName(e.target.files[0].name)
        }
    }

    const setBackgroundImage = e => {
        if (e.target.files[0]) {
            setSelectedBg(URL.createObjectURL(e.target.files[0]))
            setBgFile(e.target.files[0])
            setBgManualImage(true)
            setBgFileName(e.target.files[0].name)
        }
    }

    const setSize = (width, height, logo) => {
        if (logo === 'primary') {
            setLogoSize({ width: width, height: height })
        }
        if (logo === 'secondary') {
            setSecLogoSize({ width: width, height: height })
        }
    }

    const setNewsCategory = data => {
        setCategorizedNews(data)
    }

    const handleBatchDeleteStories = () => {
        const idsToRemove = [...new Set(selectedStories.map(item => item.id))]
        axios
            .delete('/news/delete-news/', { data: { ids: idsToRemove } })
            .then(response => {
                toast('News Deleted')
                let publishCopy = [...publishedNews.results.data]
                let publishFiltered = publishCopy.filter(story => !idsToRemove.includes(story.id))
                setPublishedNews({ ...publishedNews, results: { data: publishFiltered } })
                setDeleteModalOpen(false)
                setSelectedStories([])
            })
            .catch(error => {
                console.log('Published to news error', error.response)
                toast('Error deleting story')
            })
    }

    function filterUniqueById(arr) {
        const uniqueIds = new Set()
        return arr.filter(obj => {
            if (!uniqueIds.has(obj.id)) {
                uniqueIds.add(obj.id)
                return true
            }
            return false
        })
    }

    const uniquestories = filterUniqueById(selectedStories)
    return (
        <BaseScreen allIndustries={allIndustries} allCompanies={allCompanies} allRegions={allRegions}>
            {activeStep !== 0 && (
                <>
                    <Steppers activeStep={activeStep} />
                    <StepperButton
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        count={selectedStories.length}
                        template={selectedTemplate}
                        resetSteps={resetSteps}
                    />
                </>
            )}
            {activeStep === 0 && (
                <Grid container direction="column" style={{ paddingRight: '1rem' }}>
                    <Grid item>{NewsFilter}</Grid>
                    {publishedNews.results.data.length > 0 && !isLoading && (
                        <Grid item style={{ width: '99%' }}>
                            <DeleteNews
                                deleteModalOpen={deleteModalOpen}
                                setDeleteModalOpen={setDeleteModalOpen}
                                selectedStoriesLength={selectedStories.length}
                                handleBatchDeleteStories={handleBatchDeleteStories}
                            />
                            <Grid container direction="column">
                                <Grid item>
                                    <Grid container direction="row" alignItems="center" justifyContent="space-between">
                                        <Grid item style={{ paddingLeft: '16px', width: '-webkit-fill-available' }}>
                                            <React.Fragment>
                                                <ResultHeader
                                                    isPublishedNews={true}
                                                    allSelected={isAllSelected}
                                                    setAllSelected={setAllSelected}
                                                    resultLength={
                                                        selectedNewsOnly
                                                            ? selectedStories.length
                                                            : publishedNews.results.data.length
                                                    }
                                                    resultCount={
                                                        selectedNewsOnly ? selectedStories.length : publishedNews.count
                                                    }
                                                    expanded={expandedView}
                                                    setExpanded={setExpandedView}
                                                    selectedNewsOnly={selectedNewsOnly}
                                                    setSelectedNewsOnly={setSelectedNewsOnly}
                                                />
                                            </React.Fragment>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <div className="sendAndDeleteContainer" style={{ top: '500px' }}>
                                    <button
                                        className="sendButton"
                                        onClick={() => setActiveStep(1)}
                                        disabled={selectedStories.length === 0}
                                        style={{ marginBottom: '15px' }}
                                    >
                                        <img
                                            src={process.env.REACT_APP_MEDIA_URL + SendIcon}
                                            alt="send"
                                            className={selectedStories.length === 0 ? 'buttonIconFaded' : ''}
                                        />
                                    </button>

                                    <button
                                        className="deleteButton"
                                        onClick={
                                            selectedStories.length === 0
                                                ? () => toast('No Stories Selected')
                                                : () => setDeleteModalOpen(!deleteModalOpen)
                                        }
                                        disabled={selectedStories.length === 0}
                                    >
                                        <img
                                            src={process.env.REACT_APP_MEDIA_URL + deleteBin}
                                            alt="delete"
                                            className={selectedStories.length === 0 ? 'buttonIconFaded' : ''}
                                        />
                                    </button>
                                </div>
                                <Grid item style={{ maxWidth: '99%' }}>
                                    <div
                                        className="newsCardCollapsed__Container"
                                        style={{ width: '92%', display: expandedView ? 'none' : '' }}
                                    >
                                        <div className="newsCardCollapsed__selectColumn">
                                            <div style={{ width: '15px' }} />
                                        </div>
                                        <div className="newsCardCollapsed__date" style={{ width: '150px' }}>
                                            <p
                                                className="newsCard20__companyDate"
                                                style={{ fontSize: '15px', fontWeight: '700', color: 'black' }}
                                            >
                                                Article Date
                                            </p>
                                        </div>
                                        <div className="newsCardCollapsed__date" style={{ width: '150px' }}>
                                            <p
                                                className="newsCard20__companyDate"
                                                style={{
                                                    marginLeft: '-30%',
                                                    fontSize: '15px',
                                                    fontWeight: '700',
                                                    color: 'black',
                                                }}
                                            >
                                                Published Date
                                            </p>
                                        </div>
                                        <div className="newsCardCollapsed__title">
                                            <p
                                                style={{
                                                    marginLeft: '-18%',
                                                    fontSize: '15px',
                                                    fontWeight: '700',
                                                    color: 'black',
                                                }}
                                            >
                                                Title
                                            </p>
                                        </div>
                                    </div>
                                    {selectedNewsOnly ? (
                                        selectedStories.map(story => {
                                            return (
                                                <NewsEditCardAnalyst
                                                    key={story.id}
                                                    EachrawNews={story}
                                                    isPublishedNews={true}
                                                    selectedStories={selectedStories}
                                                    isStorySelected={
                                                        selectedStories.findIndex(arr => arr.id === story.id) === -1
                                                            ? false
                                                            : true
                                                    }
                                                    addToSelection={addToSelection}
                                                    allCategories={allCategories}
                                                    allIndustries={allIndustries}
                                                    allCompanies={allCompanies}
                                                    allRegions={allRegions}
                                                    allLinkedInGroups={allLinkedInGroups}
                                                    removeFromSelection={removeFromSelection}
                                                    allTopics={allTopics}
                                                    collapsed={!expandedView}
                                                    usertype={usertype}
                                                    allSubCategoriesOnly={allSubCategoriesOnly}
                                                    regionHierarchy={regionHierarchy}
                                                />
                                            )
                                        })
                                    ) : (
                                        <>
                                            <InfiniteScroll
                                                pageStart={0}
                                                loadMore={handleLoadMore}
                                                hasMore={publishedNews.next !== null}
                                                loader={
                                                    <Circles
                                                        key={0}
                                                        type="Grid"
                                                        color="#adadad"
                                                        height={80}
                                                        width={80}
                                                    />
                                                }
                                            >
                                                {publishedNews.results.data.map(story => {
                                                    return (
                                                        <NewsEditCardAnalyst
                                                            key={story.id}
                                                            EachrawNews={story}
                                                            isPublishedNews={true}
                                                            selectedStories={selectedStories}
                                                            isStorySelected={
                                                                selectedStories.findIndex(
                                                                    arr => arr.id === story.id
                                                                ) === -1
                                                                    ? false
                                                                    : true
                                                            }
                                                            addToSelection={addToSelection}
                                                            allCategories={allCategories}
                                                            allIndustries={allIndustries}
                                                            allCompanies={allCompanies}
                                                            allRegions={allRegions}
                                                            allLinkedInGroups={allLinkedInGroups}
                                                            removeFromSelection={removeFromSelection}
                                                            allTopics={allTopics}
                                                            collapsed={!expandedView}
                                                            usertype={usertype}
                                                            allSubCategoriesOnly={allSubCategoriesOnly}
                                                            regionHierarchy={regionHierarchy}
                                                            isAllSelected={isAllSelected}
                                                        />
                                                    )
                                                })}
                                            </InfiniteScroll>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {publishedNews.results.data.length === 0 && !isLoading && (
                        <Grid item alignSelf={'center'} style={{ paddingTop: '10vh' }}>
                            <NoResults />
                        </Grid>
                    )}
                    {isLoading && (
                        <Grid item alignSelf={'center'} style={{ paddingTop: '10vh' }}>
                            <CircularProgress />
                        </Grid>
                    )}
                </Grid>
            )}
            {activeStep === 1 && (
                <SelectTemplate selectedTemplate={selectedTemplate} handleTemplateSelected={handleTemplateSelected} />
            )}

            {activeStep === 2 && selectedTemplate && (
                <TemplatePreview
                    selectedStories={uniquestories}
                    selectedTemplate={selectedTemplate}
                    setContent={setContent}
                    subjectLine={subjectLine}
                    setSubjectLine={setSubjectLine}
                    title={title}
                    setTitle={setTitle}
                    introText={introText}
                    setIntroText={setIntroText}
                    hyperlink={setHyperlink}
                    setHyperlink={setHyperlink}
                    primaryColor={primaryColor}
                    setPrimaryColor={setPrimaryColor}
                    secondaryColor={secondaryColor}
                    setSecondaryColor={setSecondaryColor}
                    setLogo={setLogo}
                    resetPrimaryLogo={resetPrimaryLogo}
                    logoFileName={logoFileName}
                    secondaryLogo={secondaryLogo}
                    setsecondaryLogo={setsecondaryLogo}
                    resetSecondaryLogo={resetSecondaryLogo}
                    setSize={setSize}
                    seclogoFileName={seclogoFileName}
                    setBackgroundImage={setBackgroundImage}
                    bgFileName={bgFileName}
                    selectedLogo={selectedLogo}
                    selectedBg={selectedBg}
                    categorizedNews={categorizedNews}
                    setNewsCategory={setNewsCategory}
                />
            )}

            {activeStep === 3 && (
                <SendNewsLetter
                    selectedTemplate={selectedTemplate}
                    selectedStories={selectedStories}
                    allActiveEndUsers={allActiveEndUsers}
                    allCategories={allCategories}
                    allIndustries={allIndustries}
                    allCompanies={allCompanies}
                    allRegions={allRegions}
                    allTopics={allTopics}
                    allFirms={allFirms}
                    categorizedNews={categorizedNews}
                    subjectLine={subjectLine}
                    introText={introText}
                    primaryColor={primaryColor}
                    secondaryColor={secondaryColor}
                    title={title}
                    selectedLogo={selectedLogo}
                    secondaryLogo={secondaryLogo}
                    selectedBg={selectedBg}
                    logoSize={logoSize}
                    secLogoSize={secLogoSize}
                    hyperlink={hyperlink}
                    content={content}
                    usertype={usertype}
                />
            )}
        </BaseScreen>
    )
}
