import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Circles } from 'react-loader-spinner'
import { connect } from 'react-redux'

import BaseScreen from '../BaseScreen/BaseScreen'
import Profile from '../DirectUserDashboard/components/Profile'

const RelationshipScreen = props => {
    const ContactId = props.match.params.id
    const [contactLoading, setContactLoading] = useState(true)
    const [selectedPerson, setSelectedPerson] = useState({})

    useEffect(() => {
        getSingleContact()
    }, [ContactId])

    const getSingleContact = id => {
        setContactLoading(true)
        axios.get(`/endusers/get-contact/${ContactId}/`).then(response => {
            setSelectedPerson(response.data.contact)
            setContactLoading(false)
        })
    }

    if (contactLoading || !selectedPerson.id) {
        return (
            <BaseScreen>
                <div className="dirDash__container">
                    <Circles key={0} type="Grid" color="#adadad" height={80} width={80} />
                </div>
            </BaseScreen>
        )
    }

    return (
        <BaseScreen>
            <Profile
                onPage={ContactId}
                //setOnPage={setOnPage}
                selectedPerson={selectedPerson}
                user={props.user}
                //getAllUserContacts={getAllUserContacts}
                contactLoading={contactLoading}
            />
        </BaseScreen>
    )
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default connect(mapStateToProps)(RelationshipScreen)
