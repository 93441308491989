import React from 'react'
import { getSmallDate } from '../../../utils/dateHelpers'
import './MailModal.css'
import { Grid } from '@mui/material'
import TemplateFooter from './TemplateFooter'

export default function BLGManualPreview(props) {
    const {
        introText,
        selectedStories,
        primaryColor,
        secondaryColor,
        selectedBg,
        primaryLogo,
        secondaryLogo,
        setSize,
        title,
        hyperlinkSource,
        hyperlinkTitle,
    } = props

    const logoRef = React.useRef()
    const secLogoRef = React.useRef()
    const getSize = logo => {
        if (logo === 'primary') {
            setSize(logoRef.current.offsetWidth, logoRef.current.offsetHeight, logo)
        } else {
            setSize(secLogoRef.current.offsetWidth, secLogoRef.current.offsetHeight, logo)
        }
    }

    return (
        <Grid container direction="column" style={{ fontFamily: 'Arial' }}>
            <Grid item style={{ padding: '10px' }}>
                <table style={{ width: '650px', borderCollapse: 'separate', marginTop: '20px' }}>
                    <tbody>
                        <tr>
                            <td style={{ width: '200px', textAlign: 'center', height: '100px' }} key="primarylogo">
                                {primaryLogo && (
                                    <img
                                        src={primaryLogo}
                                        onLoad={e => getSize('primary')}
                                        onChange={e => getSize('primary')}
                                        alt="left-logo"
                                        ref={ref => (logoRef.current = ref)}
                                        style={{
                                            maxHeight: '100%',
                                            maxWidth: '100%',
                                        }}
                                    />
                                )}
                            </td>
                            {secondaryLogo && (
                                <>
                                    <td style={{ width: '0px' }}>
                                        <span> &nbsp;&nbsp; | &nbsp;&nbsp; </span>
                                    </td>
                                    <td
                                        style={{ width: '200px', textAlign: 'center', height: '100px' }}
                                        key="secondarylogo"
                                    >
                                        <img
                                            src={secondaryLogo}
                                            ref={ref => (secLogoRef.current = ref)}
                                            onLoad={e => getSize('secondary')}
                                            onChange={e => getSize('secondary')}
                                            alt="partner-logo"
                                            style={{
                                                maxHeight: '100%',
                                                maxWidth: '100%',
                                            }}
                                        />
                                    </td>
                                </>
                            )}
                            <td key="date">
                                <p
                                    style={{
                                        color: 'black',
                                        textAlign: 'right',
                                        fontSize: '20px',
                                        paddingRight: '20px',
                                    }}
                                >
                                    <strong> {getSmallDate(Date.now())} </strong>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table style={{ width: '650px', marginTop: '30px' }}>
                    <tr>
                        <td key="title">
                            <p style={{ textAlign: 'center', fontSize: '20px' }}>{title}</p>
                        </td>
                    </tr>
                    <tr>
                        <td key="image" style={{ height: '150px', marginTop: '20px' }}>
                            <img
                                src={selectedBg}
                                alt="image"
                                style={{ width: '100%', height: 'inherit', objectFit: '100% 100%' }}
                            />
                        </td>
                    </tr>
                </table>

                <table style={{ width: '650px', marginTop: '20px' }}>
                    <tr>
                        <td key="user">
                            <p style={{ fontSize: '20px' }}>Dear User</p>
                            <p>{introText}</p>
                            {hyperlinkSource !== '' && hyperlinkTitle !== '' && (
                                <div>
                                    <a href={hyperlinkSource} target="_blank" style={{ fontSize: '16px' }}>
                                        {hyperlinkTitle}
                                    </a>
                                </div>
                            )}
                            <hr />
                        </td>
                    </tr>
                </table>

                {selectedStories
                    .sort((a, b) => (a.date.split('T')[0] > b.date.split('T')[0] ? -1 : 1))
                    .map(story => {
                        return (
                            <table style={{ width: '650px' }}>
                                <tr>
                                    <td key="source">
                                        <a href={story.source} target="_blank">
                                            <p style={{ fontSize: '20px', textAlign: 'justify', fontSize: '15pt' }}>
                                                {story.title}{' '}
                                            </p>
                                        </a>
                                        <p key="snippet" style={{ textAlign: 'justify', fontSize: '10pt' }}>
                                            {' '}
                                            {story.snippet.split(' ', 50).join(' ')}...
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table style={{ fontSize: '12px' }}>
                                            <tr>
                                                <td>
                                                    <div style={{ display: 'flex' }}>
                                                        {story.source_name && (
                                                            <span key="source_name">
                                                                {story.source_name} &nbsp;&nbsp; | &nbsp;&nbsp;
                                                            </span>
                                                        )}
                                                        <span key="date">{getSmallDate(story.date)}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <hr />
                            </table>
                        )
                    })}

                <TemplateFooter />
            </Grid>
        </Grid>
    )
}
