import axios from 'axios'
import { toast } from 'react-toastify'

export const uploadDocument = formData => {
    let reader = new FileReader()
    reader.readAsDataURL(formData.file)
    reader.onload = e => {
        const uploadObject = {
            title: formData.title,
            industries: formData.industries.map(item => item.value),
            sectors: formData.sectors.map(item => item.value),
            company: formData.company.value ? formData.company.value : {},
            firms: formData.firms.map(item => item.value),
            country: formData.country,
            document: e.target.result,
            extension: '.' + formData.file.name.split('.').pop(),
        }
        axios
            .post('/core/add-document/', uploadObject)
            .then(response => {
                console.log(response.data)
                toast('Upload Success')
            })
            .catch(err => {
                console.log(err)
                toast('Error uploading document')
            })
    }
}

export const updateDocumentData = formData => {
    const uploadObject = {
        id: formData.id,
        title: formData.title,
        industries: formData.industries ? formData.industries.map(item => item.value) : [],
        sectors: formData.sectors ? formData.sectors.map(item => item.value) : [],
        company: formData.company && formData.company.value ? formData.company.value : {},
        firms: formData.firms ? formData.firms.map(item => item.value) : [],
        country: formData.country,
    }
    axios
        .post('/core/add-document/', uploadObject)
        .then(response => {
            console.log(response.data)
            toast('Document Data updated')
        })
        .catch(err => {
            console.log(err)
            toast('Error updating document')
        })
}
