import React from 'react'
import BaseScreen from '../BaseScreen/BaseScreen'
import './AnalystDashboard.css'
import NewNewsPullButton from './components/NewNewsPullButton'

function AnalystDashboard() {
    return (
        <BaseScreen>
            <div className="analystDashboard__headerSection">
                <div>
                    <h1 className="analystDashboard__welcome">News Pull</h1>
                </div>
                <NewNewsPullButton />
            </div>
        </BaseScreen>
    )
}

export default AnalystDashboard
