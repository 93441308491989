import axios from 'axios'
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { Button, Input } from 'reactstrap'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { getCookie } from '../../../utils'
import { BiPlus, BiMinus, BiSearch } from 'react-icons/bi'
import './Filter.css'
import { BiCalendar } from 'react-icons/bi'
import NewsEditDropdownFilterDatePicker from '../../../components/NewsEditDropdownFilter/NewsEditDropdownFilterDatePicker'
import { Chip } from '@mui/material'
const token = getCookie('csrftoken')
axios.defaults.headers.common['X-CSRFToken'] = token

export default function NewAdvanceFilters(props) {
    const { regionHierarchy } = props
    const [regionSection, setRegionSection] = useState(false)
    const [companySection, setCompanySection] = useState(false)
    const [practiceSection, setPracticeSection] = useState(false)
    const [topicSection, setTopicSection] = useState(false)
    const [sourceSection, setSourceSection] = useState(false)
    const [regionInput, setRegionInput] = useState('')
    const [companyInput, setCompanyInput] = useState('')
    const [practiceInput, setPracticeInput] = useState('')
    const [topicInput, setTopicInput] = useState('')
    const [sourceInput, setSourceInput] = useState('')
    const { selectedOption, setSelectedOption } = props
    const [subCategoryOpen, setSubCategoryOpen] = useState([])
    const [sectorOpen, setSectorOpen] = useState([])
    const [topicOpen, setTopicOpen] = useState([])
    const [checkedCompanies, setCheckedCompanies] = useState(false)
    const [checkedSources, setCheckedSources] = useState(false)
    const [checkedPractices, setCheckedPractices] = useState(false)
    const [checkedTopics, setCheckedTopics] = useState(false)
    const [checkedRegions, setCheckedRegions] = useState(false)

    const {
        selectedSectors,
        setSelectedSectors,
        selectedCompanies,
        setSelectedCompanies,
        selectedCategories,
        setSelectedCategories,
        selectedTopics,
        setSelectedTopics,
        selectedRegions,
        setSelectedRegions,
        selectedSources,
        setSelectedSources,
        allHierarchy,
        practiceSubCategory,
        generateQuery,
        defaultData,
        setDefaultData,
        dateSection,
        setDateSection,
        clearFilters,
        isMyRequest,
    } = props

    const [selectedIndustries, setSelectedIndustries] = useState([])
    const [showBusinessEvents, setShowBusinessEvents] = useState([])
    useEffect(() => {
        setDefaultData(props.allCompanies)
        flattenCategories()
    }, [props.allCompanies])

    const flattenCategories = () => {
        const flattened = []

        const flattenHelper = category => {
            flattened.push({ id: category.id, name: category.name })
            if (category.subcategories && category.subcategories.length > 0) {
                category.subcategories.forEach(subcategory => {
                    flattenHelper(subcategory)
                })
            }
        }

        practiceSubCategory.forEach(category => {
            flattenHelper(category)
        })
        setShowBusinessEvents(flattened)

        return flattened
    }

    ///sector-company
    const [showType, setShowType] = useState({
        topics: [],
        industries: [],
        sectors: [],
    })
    const [showCompanies, setShowCompanies] = useState([])

    const wrapperRef = useRef(null)
    closeExpandable(wrapperRef)

    function closeExpandable(ref) {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setRegionSection(false)
                setCompanySection(false)
                setPracticeSection(false)
                setTopicSection(false)
                setSourceSection(false)
                setDateSection(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }

    ///industry->sector->topic
    const allIndustriesName = allHierarchy.map(val => val.name)

    const allSectorsName = allHierarchy
        .map(val => val.sectors.map(sec => sec.name))
        .reduce((acc, curr) => acc.concat(curr), [])

    const allTopicsName = allHierarchy
        .map(val => val.sectors.map(sec => sec.topics.map(top => top.name)))
        .reduce((acc, curr) => acc.concat(curr), [])
        .reduce((acc, curr) => acc.concat(curr), [])

    const addingOfIndustriesSectorsNames = allIndustriesName.concat(allSectorsName)

    const stateMap = {
        Industry: [selectedIndustries, setSelectedIndustries],
        sector: [selectedSectors, setSelectedSectors],
        company: [selectedCompanies, setSelectedCompanies],
        country: [selectedRegions, setSelectedRegions],
        topic: [selectedTopics, setSelectedTopics],
        source: [selectedSources, setSelectedSources],
        category: [selectedCategories, setSelectedCategories],
        showCompanies: [showCompanies, setShowCompanies],
    }

    const bulkChange = (type, data, name) => {
        const [selectedItems, setSelectedItems] = stateMap[type]

        let result = selectedItems

        const isSelectedItem = selectedItems.find(item => item === name)
        data.forEach(list => {
            if (isSelectedItem) {
                result = result.filter(item => item !== list.name)
            } else {
                result.push(list.name)
            }
        })

        setSelectedItems([...result])
    }

    const bulkChangesub = (type, data, name) => {
        const [selectedItems, setSelectedItems] = stateMap[type]

        let result = selectedItems

        const isSelectedItem = selectedItems.find(item => item === name)
        data.forEach(list => {
            if (isSelectedItem) {
                result = result.filter(item => item !== list.id)
            } else {
                result.push(list.id)
            }
        })

        setSelectedItems([...result])
    }

    const changeCheck = (type, id, typeOf = null, value = null) => {
        const [selectedItems, setSelectedItems] = stateMap[type]
        const isItemSelected = selectedItems.includes(id)
        if (isItemSelected) {
            const filteredItems = selectedItems.filter(item => item !== id)
            setSelectedItems(filteredItems)
        } else {
            setSelectedItems([...selectedItems, id])
        }

        if (typeOf) {
            if (showType[typeOf].includes(value)) {
                const filtered = showType[typeOf].filter(item => item != value)
                setShowType({ ...showType, [typeOf]: filtered })
            } else {
                setShowType({ ...showType, [typeOf]: [...showType[typeOf], value] })
            }
        }
    }

    const toggleid = (Id, type) => {
        type(prevExpandedValues => {
            if (prevExpandedValues.includes(Id)) {
                return prevExpandedValues.filter(id => id !== Id)
            } else {
                return [...prevExpandedValues, Id]
            }
        })
    }

    const handleSelectAllCompany = () => {
        if (checkedCompanies) {
            setSelectedCompanies([])
        } else {
            const allCompanyLabels = defaultData.filter(company => company.label).map(company => company.label)
            setSelectedCompanies(allCompanyLabels)
        }
        setCheckedCompanies(!checkedCompanies)
    }

    const handleSelectAllSources = () => {
        if (checkedSources) {
            setSelectedSources([])
        } else {
            const allSourceLabels = props.allSources
                .filter(source => source.label.toUpperCase().includes(sourceInput.toUpperCase()))
                .map(source => source.value)
            setSelectedSources(allSourceLabels)
        }
        setCheckedSources(!checkedSources)
    }

    const handleSelectAllPractice = () => {
        if (checkedPractices) {
            setSelectedCategories([])
        } else {
            const allPracticeLabels = practiceSubCategory
                .filter(category => {
                    const categoryAndSubcategories = [
                        category.name,
                        ...category.subcategories.map(subcategory => subcategory.name),
                    ]
                    return categoryAndSubcategories.some(name =>
                        name.toLowerCase().includes(practiceInput.toLowerCase())
                    )
                })
                .reduce((result, category) => {
                    result.push(category.id)

                    const matchingSubcategories = category.subcategories.filter(subcategory =>
                        subcategory.name.toLowerCase().includes(practiceInput.toLowerCase())
                    )

                    matchingSubcategories.forEach(subcategory => {
                        result.push(subcategory.id)
                    })

                    return result
                }, [])

            setSelectedCategories(allPracticeLabels)
        }
        setCheckedPractices(!checkedPractices)
    }

    const handleSelectAllTopics = () => {
        if (checkedTopics) {
            setSelectedSectors([])
        } else {
            const filteredItems = allHierarchy.filter(ind => {
                const matchingSectors = ind.sectors.filter(sec =>
                    sec.topics.some(topic => topic.name.toLowerCase().includes(topicInput.toLowerCase()))
                )

                return (
                    ind.name.toLowerCase().includes(topicInput.toLowerCase()) ||
                    matchingSectors.length > 0 ||
                    ind.sectors.some(sec => sec.name.toLowerCase().includes(topicInput.toLowerCase()))
                )
            })

            const filteredTopicLabels = filteredItems.reduce((result, val) => {
                result.push(val.name)

                val.sectors.forEach(sector => {
                    result.push(sector.name)

                    sector.topics.forEach(topic => {
                        if (topic.name.toLowerCase().includes(topicInput.toLowerCase())) {
                            result.push(topic.name)
                        }
                    })
                })

                return result
            }, [])
            setSelectedSectors(filteredTopicLabels)
        }
        setCheckedTopics(!checkedTopics)
    }

    const handleSelectAllRegions = () => {
        if (checkedRegions) {
            setSelectedRegions([])
        } else {
            const filteredItems = regionHierarchy.filter(reg => {
                const matchingcountries = reg.countries.filter(cty =>
                    cty.provinces.some(pro => pro.name.toLowerCase().includes(regionInput.toLowerCase()))
                )

                return (
                    reg.name.toLowerCase().includes(regionInput.toLowerCase()) ||
                    matchingcountries.length > 0 ||
                    reg.countries.some(cty => cty.name.toLowerCase().includes(regionInput.toLowerCase()))
                )
            })

            const filteredProvincesLabels = filteredItems.reduce((result, val) => {
                result.push(val.name)

                val.countries.forEach(country => {
                    result.push(country.name)

                    country.provinces.forEach(pro => {
                        if (pro.name.toLowerCase().includes(regionInput.toLowerCase())) {
                            result.push(pro.name)
                        }
                    })
                })

                return result
            }, [])
            setSelectedRegions(filteredProvincesLabels)
        }
        setCheckedRegions(!checkedRegions)
    }

    const companySearch = e => {
        setCompanyInput(e.target.value)
        if (e.target.value.length > 2) {
            axios
                .get(`/news/auto-complete/?type=company&string=${encodeURIComponent(e.target.value)}`)
                .then(response => {
                    const filteredData = response.data.map(item => ({
                        value: item.id,
                        label: item.name,
                    }))
                    const uniqueData = new Set([...filteredData])
                    const arrayFromSet = [...uniqueData]
                    setDefaultData(arrayFromSet)
                })
                .catch(err => console.log(err))
        } else {
            setDefaultData(props.allCompanies)
        }
    }
    const selectedOptionsChip = (value, setValue) => {
        return (
            <div style={{ margin: '2px' }}>
                {value.map(val => (
                    <Chip
                        size="small"
                        key={val}
                        label={val}
                        onDelete={() => setValue(value.filter(curr => curr !== val))}
                    />
                ))}
            </div>
        )
    }

    return (
        <>
            <div className="New_Advanced_filters">
                <div className="overall_drop">
                    <div className={isMyRequest ? 'row' : ''}>
                        <div
                            id="date_drop"
                            style={{ marginTop: isMyRequest ? '20px' : '' }}
                            className={isMyRequest ? 'col-4' : ''}
                        >
                            <div className="">
                                <Button
                                    id="date_btn"
                                    style={{
                                        background: 'white',
                                        color: 'gray',
                                        borderRadius: '5px',
                                        fontSize: '10px',
                                        width: '100%',
                                        border: '1px solid gray',
                                    }}
                                    onClick={() => {
                                        setDateSection(!dateSection)
                                    }}
                                    className="d-flex justify-content-between align-content-center"
                                >
                                    Article Date
                                    <ExpandMoreIcon style={{ fontSize: '14px' }} />{' '}
                                </Button>
                            </div>
                            <div style={{ width: '99%', position: 'relative', margin: 'auto' }}>
                                {dateSection && (
                                    <div
                                        ref={wrapperRef}
                                        className="date_section"
                                        style={{
                                            width: '100%',
                                            marginTop: '5px',
                                            background: 'white',
                                            position: 'absolute',
                                            padding: '10px',
                                            height: 'auto',
                                            cursor: 'pointer',
                                            borderRadius: '4px',
                                            boxShadow: '0px 0px 2px 2px lightgray',
                                            zIndex: 1,
                                        }}
                                    >
                                        <div className="radio">
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="1"
                                                    checked={selectedOption === '1'}
                                                    onChange={e => {
                                                        setSelectedOption(e.target.value)
                                                        generateQuery(1)
                                                    }}
                                                />
                                                <span style={{ marginLeft: '10px' }}>Today</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="6"
                                                    checked={selectedOption === '6'}
                                                    onChange={e => {
                                                        setSelectedOption(e.target.value)
                                                        generateQuery(6)
                                                    }}
                                                />
                                                <span style={{ marginLeft: '10px' }}>Last 7 days</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="29"
                                                    defaultChecked
                                                    checked={selectedOption === '29'}
                                                    onChange={e => {
                                                        setSelectedOption(e.target.value)
                                                        generateQuery(29)
                                                    }}
                                                />
                                                <span style={{ marginLeft: '10px' }}>Last 30 days</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="59"
                                                    checked={selectedOption === '59'}
                                                    onChange={e => {
                                                        setSelectedOption(e.target.value)
                                                        generateQuery(59)
                                                    }}
                                                />
                                                <span style={{ marginLeft: '10px' }}>Last 60 days</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="89"
                                                    checked={selectedOption === '89'}
                                                    onChange={e => {
                                                        setSelectedOption(e.target.value)
                                                        generateQuery(89)
                                                    }}
                                                />
                                                <span style={{ marginLeft: '10px' }}>Last 90 days</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="364"
                                                    checked={selectedOption === '364'}
                                                    onChange={e => {
                                                        setSelectedOption(e.target.value)
                                                        generateQuery(364)
                                                    }}
                                                />
                                                <span style={{ marginLeft: '10px' }}>Last 1 year</span>
                                            </label>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {!isMyRequest && (
                                <div style={{ margin: '2px' }}>
                                    <Chip
                                        size="small"
                                        label={
                                            selectedOption === '1' || selectedOption === '0'
                                                ? 'Today'
                                                : selectedOption === '6'
                                                ? 'Last 7 days'
                                                : selectedOption === '29'
                                                ? 'Last 30 days'
                                                : selectedOption === '59'
                                                ? 'Last 60 days'
                                                : selectedOption === '89'
                                                ? 'Last 90 days'
                                                : 'Last 1 year'
                                        }
                                    />
                                </div>
                            )}
                        </div>

                        <div id="region_drop" style={{ marginTop: '20px' }} className={isMyRequest ? 'col-4' : ''}>
                            <div className="d-flex region">
                                <Button
                                    style={{
                                        background: 'white',
                                        color: 'gray',
                                        borderRadius: '5px',
                                        fontSize: '10px',
                                        width: '100%',
                                        border: '1px solid gray',
                                    }}
                                    onClick={() => {
                                        setRegionSection(!regionSection)
                                    }}
                                    className="d-flex justify-content-between align-content-center"
                                >
                                    Location/Region
                                    {selectedRegions.length !== 0 ? '(' + selectedRegions.length + ')' : ''}
                                    <ExpandMoreIcon style={{ fontSize: '14px' }} />{' '}
                                </Button>
                            </div>
                            <div style={{ width: '99%', position: 'relative', margin: 'auto' }}>
                                {regionSection && (
                                    <div
                                        ref={wrapperRef}
                                        className="region_section"
                                        style={{
                                            width: '100%',
                                            marginTop: '5px',
                                            background: 'white',
                                            position: 'absolute',
                                            padding: '20px',
                                            height: '260px',
                                            zIndex: 1,
                                            borderRadius: '4px',
                                            boxShadow: '0px 0px 2px 2px lightgray',
                                        }}
                                    >
                                        <Input
                                            type="search"
                                            value={regionInput}
                                            onChange={e => {
                                                setRegionInput(e.target.value)
                                            }}
                                        />
                                        <div style={{ height: '210px', overflow: 'auto' }}>
                                            <div className="d-flex" style={{ marginTop: '5px' }}>
                                                <input
                                                    type="checkbox"
                                                    id={'selectAll' + 'region'}
                                                    checked={checkedRegions}
                                                    onChange={() => {
                                                        handleSelectAllRegions()
                                                    }}
                                                />
                                                <label
                                                    htmlFor={'selectAll' + 'region'}
                                                    style={{
                                                        marginLeft: '10px',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    Select All
                                                </label>
                                            </div>
                                            {regionHierarchy
                                                .filter(region => {
                                                    const regionNameMatches = region.name
                                                        .toLowerCase()
                                                        .includes(regionInput.toLowerCase())

                                                    const countriesMatch = region.countries.some(country =>
                                                        country.name.toLowerCase().includes(regionInput.toLowerCase())
                                                    )

                                                    return regionNameMatches || countriesMatch
                                                })
                                                .map(reg => {
                                                    return (
                                                        <>
                                                            <div
                                                                className="d-flex"
                                                                style={{ marginTop: '5px' }}
                                                                key={reg.name}
                                                            >
                                                                <>
                                                                    <input
                                                                        type="checkbox"
                                                                        value={reg.name}
                                                                        id={'country' + reg.name}
                                                                        checked={selectedRegions.includes(reg.name)}
                                                                        onChange={() => {
                                                                            bulkChange('country', [reg], reg.name)
                                                                        }}
                                                                    />

                                                                    <label
                                                                        htmlFor={'country' + reg.name}
                                                                        style={{
                                                                            marginLeft: '10px',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    >
                                                                        {reg.name}
                                                                    </label>
                                                                </>
                                                            </div>
                                                            {reg.countries &&
                                                                reg.countries.map(cty => (
                                                                    <>
                                                                        <div
                                                                            className="d-flex"
                                                                            style={{
                                                                                marginTop: '5px',
                                                                                marginLeft: '15px',
                                                                            }}
                                                                            key={cty.name}
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                value={cty.name}
                                                                                id={'country' + cty.name}
                                                                                checked={selectedRegions.includes(
                                                                                    cty.name
                                                                                )}
                                                                                onChange={() => {
                                                                                    bulkChange(
                                                                                        'country',
                                                                                        [cty],
                                                                                        cty.name
                                                                                    )
                                                                                }}
                                                                            />

                                                                            <label
                                                                                htmlFor={'country' + cty.name}
                                                                                style={{
                                                                                    marginLeft: '10px',
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                            >
                                                                                {cty.name}
                                                                            </label>
                                                                        </div>
                                                                    </>
                                                                ))}
                                                        </>
                                                    )
                                                })}
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* {selectedRegions.length > 0 && selectedOptionsChip(selectedRegions, setSelectedRegions)} */}
                        </div>

                        <div id="sector_drop" style={{ marginTop: '20px' }} className={isMyRequest ? 'col-4' : ''}>
                            <div className="d-flex">
                                <Button
                                    style={{
                                        background: 'white',
                                        color: 'gray',
                                        borderRadius: '5px',
                                        fontSize: '10px',
                                        width: '100%',
                                        border: '1px solid gray',
                                    }}
                                    onClick={() => {
                                        setTopicSection(!topicSection)
                                    }}
                                    className="d-flex justify-content-between align-content-center"
                                >
                                    Topic
                                    {selectedSectors.length !== 0 ? '(' + selectedSectors.length + ')' : ''}{' '}
                                    <ExpandMoreIcon style={{ fontSize: '14px' }} />{' '}
                                </Button>
                            </div>
                            <div style={{ width: '99%', position: 'relative', margin: 'auto' }}>
                                {topicSection && (
                                    <div
                                        ref={wrapperRef}
                                        className="region_section"
                                        style={{
                                            width: '100%',
                                            marginTop: '5px',
                                            background: 'white',
                                            position: 'absolute',
                                            padding: '20px',
                                            height: '260px',
                                            zIndex: 1,
                                            borderRadius: '4px',
                                            boxShadow: '0px 0px 2px 2px lightgray',
                                        }}
                                    >
                                        <Input
                                            type="search"
                                            value={topicInput}
                                            onChange={e => {
                                                setTopicInput(e.target.value)
                                            }}
                                        />
                                        <div style={{ height: '210px', overflow: 'auto' }}>
                                            <div className="d-flex" style={{ marginTop: '5px' }}>
                                                <input
                                                    type="checkbox"
                                                    id={'selectAll' + 'topic'}
                                                    checked={checkedTopics}
                                                    onChange={() => {
                                                        handleSelectAllTopics()
                                                    }}
                                                />
                                                <label
                                                    htmlFor={'selectAll' + 'topic'}
                                                    style={{
                                                        marginLeft: '10px',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    Select All
                                                </label>
                                            </div>
                                            {allHierarchy
                                                .reduce((result, ind) => {
                                                    const filteredSectors = ind.sectors.filter(sec =>
                                                        sec.topics.some(top =>
                                                            top.name.toLowerCase().startsWith(topicInput.toLowerCase())
                                                        )
                                                    )

                                                    if (
                                                        filteredSectors.length > 0 ||
                                                        ind.name.toLowerCase().startsWith(topicInput.toLowerCase()) ||
                                                        ind.sectors.find(sec =>
                                                            sec.name.toLowerCase().startsWith(topicInput.toLowerCase())
                                                        )
                                                    ) {
                                                        if (topicInput.length > 2) {
                                                            sectorOpen.push(ind.id)
                                                            filteredSectors.forEach(sec => {
                                                                topicOpen.push(sec.id)
                                                            })
                                                        }
                                                        if (topicInput.length === 1) {
                                                            topicOpen.splice(0, topicOpen.length)
                                                            sectorOpen.splice(0, sectorOpen.length)
                                                        }
                                                        result.push({
                                                            ...ind,
                                                            sectors: ind.sectors
                                                                .filter(
                                                                    sector =>
                                                                        sector.name
                                                                            .toLowerCase()
                                                                            .startsWith(topicInput.toLowerCase()) ||
                                                                        sector.topics.some(topic =>
                                                                            topic.name
                                                                                .toLowerCase()
                                                                                .startsWith(topicInput.toLowerCase())
                                                                        )
                                                                )
                                                                .map(filteredSector => ({
                                                                    ...filteredSector,
                                                                    topics: filteredSector.topics.filter(topic =>
                                                                        topic.name
                                                                            .toLowerCase()
                                                                            .startsWith(topicInput.toLowerCase())
                                                                    ),
                                                                })),
                                                        })
                                                    }
                                                    return result
                                                }, [])
                                                .map(val => {
                                                    return (
                                                        <>
                                                            <div
                                                                className="d-flex"
                                                                style={{ marginTop: '5px' }}
                                                                key={val.name}
                                                            >
                                                                <>
                                                                    <input
                                                                        type="checkbox"
                                                                        value={val.name}
                                                                        id={'sector' + val.name}
                                                                        checked={selectedSectors.includes(val.name)}
                                                                        onChange={() => {
                                                                            bulkChange(
                                                                                'sector',
                                                                                [val]
                                                                                    .concat(...val.sectors)
                                                                                    .concat(
                                                                                        ...val.sectors.map(sec =>
                                                                                            sec.topics.map(top => top)
                                                                                        )
                                                                                    ),
                                                                                val.name
                                                                            )
                                                                        }}
                                                                    />

                                                                    <label
                                                                        htmlFor={'sector' + val.name}
                                                                        style={{
                                                                            marginLeft: '10px',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    >
                                                                        {val.name}
                                                                    </label>
                                                                    <span
                                                                        key={val.id}
                                                                        style={{
                                                                            marginLeft: 'auto',
                                                                            cursor: 'pointer',
                                                                            marginRight: '10px',
                                                                            display:
                                                                                val.sectors.length === 0
                                                                                    ? 'none'
                                                                                    : 'block',
                                                                        }}
                                                                    >
                                                                        {val.sectors && val.sectors.length > 0 && (
                                                                            <span
                                                                                onClick={() => {
                                                                                    toggleid(val.id, setSectorOpen)
                                                                                }}
                                                                            >
                                                                                {sectorOpen.includes(val.id) ? (
                                                                                    <BiMinus className="minus" />
                                                                                ) : (
                                                                                    <BiPlus className="plus" />
                                                                                )}
                                                                            </span>
                                                                        )}
                                                                    </span>
                                                                </>
                                                            </div>
                                                            {sectorOpen.includes(val.id) &&
                                                                val.sectors &&
                                                                val.sectors.map(sec => (
                                                                    <>
                                                                        <div
                                                                            className="d-flex"
                                                                            style={{
                                                                                marginTop: '5px',
                                                                                marginLeft: '15px',
                                                                            }}
                                                                            key={sec.name}
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                value={sec.name}
                                                                                id={'sector' + sec.name}
                                                                                checked={selectedSectors.includes(
                                                                                    sec.name
                                                                                )}
                                                                                onChange={() => {
                                                                                    bulkChange(
                                                                                        'sector',
                                                                                        [sec, ...sec.topics],
                                                                                        sec.name
                                                                                    )
                                                                                }}
                                                                            />

                                                                            <label
                                                                                htmlFor={'sector' + sec.name}
                                                                                style={{
                                                                                    marginLeft: '10px',
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                            >
                                                                                {sec.name}
                                                                            </label>
                                                                            <span
                                                                                key={sec.id}
                                                                                style={{
                                                                                    marginLeft: 'auto',
                                                                                    cursor: 'pointer',
                                                                                    marginRight: '10px',
                                                                                    display:
                                                                                        sec.topics.length === 0
                                                                                            ? 'none'
                                                                                            : 'block',
                                                                                }}
                                                                            >
                                                                                {sec.topics && sec.topics.length > 0 && (
                                                                                    <span
                                                                                        onClick={() => {
                                                                                            toggleid(
                                                                                                sec.id,
                                                                                                setTopicOpen
                                                                                            )
                                                                                        }}
                                                                                    >
                                                                                        {topicOpen.includes(sec.id) ? (
                                                                                            <BiMinus className="minus" />
                                                                                        ) : (
                                                                                            <BiPlus className="plus" />
                                                                                        )}
                                                                                    </span>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                        {topicOpen.includes(sec.id) &&
                                                                            sec.topics &&
                                                                            sec.topics.map(top => (
                                                                                <div
                                                                                    className="d-flex"
                                                                                    style={{
                                                                                        marginTop: '5px',
                                                                                        marginLeft: '30px',
                                                                                    }}
                                                                                    key={top.name}
                                                                                >
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        value={top.name}
                                                                                        id={'sector' + top.name}
                                                                                        checked={selectedSectors.includes(
                                                                                            top.name
                                                                                        )}
                                                                                        onChange={() => {
                                                                                            changeCheck(
                                                                                                'sector',
                                                                                                top.name
                                                                                            )
                                                                                        }}
                                                                                    />

                                                                                    <label
                                                                                        htmlFor={'sector' + top.name}
                                                                                        style={{
                                                                                            marginLeft: '10px',
                                                                                            cursor: 'pointer',
                                                                                        }}
                                                                                    >
                                                                                        {top.name}
                                                                                    </label>
                                                                                </div>
                                                                            ))}
                                                                    </>
                                                                ))}
                                                        </>
                                                    )
                                                })}
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* {selectedSectors.length > 0 && selectedOptionsChip(selectedSectors, setSelectedSectors)} */}
                        </div>
                    </div>

                    <div className={isMyRequest ? 'row' : ''}>
                        <div id="company_drop" style={{ marginTop: '20px' }} className={isMyRequest ? 'col-4' : ''}>
                            <div className="d-flex">
                                <Button
                                    style={{
                                        background: 'white',
                                        color: 'gray',
                                        borderRadius: '5px',
                                        fontSize: '10px',
                                        width: '100%',
                                        border: '1px solid gray',
                                    }}
                                    onClick={() => {
                                        setCompanySection(!companySection)
                                    }}
                                    className="d-flex justify-content-between align-content-center"
                                >
                                    Company
                                    {selectedCompanies.length !== 0 ? '(' + selectedCompanies.length + ')' : ''}{' '}
                                    <ExpandMoreIcon style={{ fontSize: '14px' }} />{' '}
                                </Button>
                            </div>
                            <div style={{ width: '99%', position: 'relative', margin: 'auto' }}>
                                {companySection && (
                                    <div
                                        ref={wrapperRef}
                                        className="region_section"
                                        style={{
                                            width: '100%',
                                            marginTop: '5px',
                                            background: 'white',
                                            position: 'absolute',
                                            padding: '20px',
                                            height: '260px',
                                            zIndex: 1,
                                            borderRadius: '4px',
                                            boxShadow: '0px 0px 2px 2px lightgray',
                                        }}
                                    >
                                        <Input
                                            type="search"
                                            value={companyInput}
                                            onChange={e => {
                                                companySearch(e)
                                            }}
                                        />
                                        <div style={{ height: '210px', overflow: 'auto' }}>
                                            <div className="d-flex" style={{ marginTop: '5px' }}>
                                                <input
                                                    type="checkbox"
                                                    id={'selectAll' + 'company'}
                                                    checked={checkedCompanies}
                                                    onChange={() => {
                                                        handleSelectAllCompany()
                                                    }}
                                                />
                                                <label
                                                    htmlFor={'selectAll' + 'company'}
                                                    style={{
                                                        marginLeft: '10px',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    Select All
                                                </label>
                                            </div>
                                            {defaultData.map(val => {
                                                return (
                                                    <div
                                                        className="d-flex"
                                                        style={{ marginTop: '5px' }}
                                                        key={val.label}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            value={val.label}
                                                            id={'company' + val.value}
                                                            checked={selectedCompanies.includes(val.label)}
                                                            onChange={() => changeCheck('company', val.label)}
                                                        />
                                                        <label
                                                            htmlFor={'company' + val.value}
                                                            style={{
                                                                marginLeft: '10px',
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            {val.label}
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* {selectedCompanies.length > 0 &&
                                selectedOptionsChip(selectedCompanies, setSelectedCompanies)} */}
                        </div>
                        <div id="practice_drop" style={{ marginTop: '20px' }} className={isMyRequest ? 'col-4' : ''}>
                            <div className="d-flex">
                                <Button
                                    style={{
                                        background: 'white',
                                        color: 'gray',
                                        borderRadius: '5px',
                                        fontSize: '10px',
                                        width: '100%',
                                        border: '1px solid gray',
                                    }}
                                    onClick={() => {
                                        setPracticeSection(!practiceSection)
                                    }}
                                    className="d-flex justify-content-between align-content-center"
                                >
                                    Business Events
                                    {selectedCategories.length !== 0
                                        ? '(' +
                                          Number(
                                              selectedCategories.length -
                                                  props.allCategories.filter(cat =>
                                                      selectedCategories.includes(cat.name)
                                                  ).length
                                          ) +
                                          ')'
                                        : ''}{' '}
                                    <ExpandMoreIcon style={{ fontSize: '14px' }} />{' '}
                                </Button>
                            </div>
                            <div style={{ width: '99%', position: 'relative', margin: 'auto' }}>
                                {practiceSection && (
                                    <div
                                        ref={wrapperRef}
                                        className="region_section"
                                        style={{
                                            width: '100%',
                                            marginTop: '5px',
                                            background: 'white',
                                            position: 'absolute',
                                            padding: '20px',
                                            height: '260px',
                                            zIndex: 1,
                                            borderRadius: '4px',
                                            boxShadow: '0px 0px 2px 2px lightgray',
                                        }}
                                    >
                                        <Input
                                            type="search"
                                            value={practiceInput}
                                            onChange={e => {
                                                setPracticeInput(e.target.value)
                                            }}
                                        />
                                        <div style={{ height: '210px', overflow: 'auto' }}>
                                            <div className="d-flex" style={{ marginTop: '5px' }}>
                                                <input
                                                    type="checkbox"
                                                    id={'selectAll' + 'practice'}
                                                    checked={checkedPractices}
                                                    onChange={() => {
                                                        handleSelectAllPractice()
                                                    }}
                                                />
                                                <label
                                                    htmlFor={'selectAll' + 'practice'}
                                                    style={{
                                                        marginLeft: '10px',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    Select All
                                                </label>
                                            </div>
                                            {practiceSubCategory
                                                .filter(function(eachCat) {
                                                    if (
                                                        eachCat.subcategories.filter(subCat =>
                                                            subCat.name
                                                                .toLowerCase()
                                                                .includes(practiceInput.toLowerCase())
                                                        ).length !== 0
                                                    ) {
                                                        if (practiceInput.length > 2) {
                                                            subCategoryOpen.push(eachCat.id)
                                                        }
                                                        if (practiceInput.length === 1) {
                                                            subCategoryOpen.splice(0, subCategoryOpen.length)
                                                        }
                                                        return true
                                                    }
                                                    if (
                                                        eachCat.name
                                                            .toLowerCase()
                                                            .includes(practiceInput.toLocaleLowerCase())
                                                    ) {
                                                        return true
                                                    }
                                                })
                                                .map(val => {
                                                    return (
                                                        <>
                                                            <div
                                                                className="d-flex"
                                                                style={{ marginTop: '5px' }}
                                                                key={val.label}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    id={'sub' + val.name}
                                                                    checked={selectedCategories.includes(val.id)}
                                                                    onChange={() => {
                                                                        bulkChangesub(
                                                                            'category',
                                                                            [val, ...val.subcategories],
                                                                            val.id
                                                                        )
                                                                    }}
                                                                />
                                                                <label
                                                                    htmlFor={'sub' + val.name}
                                                                    style={{
                                                                        marginLeft: '10px',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                >
                                                                    {val.name}
                                                                </label>
                                                                <span
                                                                    key={val.id}
                                                                    style={{
                                                                        marginLeft: 'auto',
                                                                        cursor: 'pointer',
                                                                        marginRight: '10px',
                                                                        display:
                                                                            val.subcategories.length === 0
                                                                                ? 'none'
                                                                                : 'block',
                                                                    }}
                                                                >
                                                                    {val.subcategories && val.subcategories.length > 0 && (
                                                                        <span
                                                                            onClick={() => {
                                                                                toggleid(val.id, setSubCategoryOpen)
                                                                            }}
                                                                        >
                                                                            {subCategoryOpen.includes(val.id) ? (
                                                                                <BiMinus className="minus" />
                                                                            ) : (
                                                                                <BiPlus className="plus" />
                                                                            )}
                                                                        </span>
                                                                    )}
                                                                </span>
                                                            </div>
                                                            {subCategoryOpen.includes(val.id) &&
                                                                val.subcategories &&
                                                                val.subcategories
                                                                    .filter(each =>
                                                                        each.name
                                                                            .toLowerCase()
                                                                            .includes(practiceInput.toLocaleLowerCase())
                                                                    )
                                                                    .map(sub => (
                                                                        <div
                                                                            style={{
                                                                                marginLeft: '25px',
                                                                                marginTop: '5px',
                                                                            }}
                                                                            className="d-flex"
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                id={'sub' + sub.name}
                                                                                checked={selectedCategories.includes(
                                                                                    sub.id
                                                                                )}
                                                                                onChange={() =>
                                                                                    changeCheck('category', sub.id)
                                                                                }
                                                                            />
                                                                            <label
                                                                                htmlFor={'sub' + sub.name}
                                                                                style={{
                                                                                    marginLeft: '10px',
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                            >
                                                                                {sub.name}
                                                                            </label>
                                                                        </div>
                                                                    ))}
                                                        </>
                                                    )
                                                })}
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* {selectedCategories.length > 0 && (
                                <div style={{ margin: '2px' }}>
                                    {showBusinessEvents
                                        .filter(v => selectedCategories.includes(v.id))
                                        .map(v => (
                                            <Chip
                                                size="small"
                                                key={v.id}
                                                label={v.name}
                                                onDelete={() => {
                                                    setSelectedCategories(prevCategories =>
                                                        prevCategories.filter(id => id !== v.id)
                                                    )
                                                }}
                                            />
                                        ))}
                                </div>
                            )} */}
                        </div>

                        <div id="source_drop" style={{ marginTop: '20px' }} className={isMyRequest ? 'col-4' : ''}>
                            <div className="d-flex">
                                <Button
                                    style={{
                                        background: 'white',
                                        color: 'gray',
                                        borderRadius: '5px',
                                        fontSize: '10px',
                                        width: '100%',
                                        border: '1px solid gray',
                                    }}
                                    onClick={() => {
                                        setSourceSection(!sourceSection)
                                    }}
                                    className="d-flex justify-content-between align-content-center"
                                >
                                    Source{selectedSources.length !== 0 ? '(' + selectedSources.length + ')' : ''}{' '}
                                    <ExpandMoreIcon style={{ fontSize: '14px' }} />{' '}
                                </Button>
                            </div>
                            <div style={{ width: '99%', position: 'relative', margin: 'auto' }}>
                                {sourceSection && (
                                    <div
                                        ref={wrapperRef}
                                        className="source_section"
                                        style={{
                                            width: '100%',
                                            marginTop: '5px',
                                            background: 'white',
                                            position: 'absolute',
                                            padding: '15px 15px 10px 15px',
                                            height: '260px',
                                            zIndex: 1,
                                            borderRadius: '4px',
                                            boxShadow: '0px 0px 2px 2px lightgray',
                                        }}
                                    >
                                        <Input
                                            type="search"
                                            value={sourceInput}
                                            onChange={e => {
                                                setSourceInput(e.target.value)
                                            }}
                                        />
                                        <div style={{ height: '210px', overflow: 'auto' }}>
                                            <div className="d-flex" style={{ marginTop: '5px' }}>
                                                <input
                                                    type="checkbox"
                                                    id={'selectAll' + 'source'}
                                                    checked={checkedSources}
                                                    onChange={() => {
                                                        handleSelectAllSources()
                                                    }}
                                                />
                                                <label
                                                    htmlFor={'selectAll' + 'source'}
                                                    style={{
                                                        marginLeft: '10px',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    Select All
                                                </label>
                                            </div>
                                            {props.allSources
                                                .filter(val =>
                                                    val.label.toUpperCase().includes(sourceInput.toUpperCase())
                                                )
                                                .map(val => {
                                                    return (
                                                        <div className="d-flex" style={{ marginTop: '5px' }}>
                                                            <input
                                                                type="checkbox"
                                                                value={val.value}
                                                                id={'source' + val.value}
                                                                checked={selectedSources.includes(val.value)}
                                                                onChange={() => changeCheck('source', val.value)}
                                                            />
                                                            <label
                                                                htmlFor={'source' + val.value}
                                                                style={{ marginLeft: '10px', cursor: 'pointer' }}
                                                            >
                                                                {val.label}
                                                            </label>
                                                        </div>
                                                    )
                                                })}
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* {selectedSources.length > 0 && selectedOptionsChip(selectedSources, setSelectedSources)} */}
                        </div>
                    </div>

                    {!isMyRequest && (
                        <div
                            className="d-flex align-items-center justify-content-between"
                            style={{ marginTop: '20px' }}
                        >
                            <span
                                style={{ textDecoration: 'underline', color: 'gray', cursor: 'pointer' }}
                                onClick={() => {
                                    clearFilters()
                                    setCheckedCompanies(false)
                                    setCheckedPractices(false)
                                    setCheckedSources(false)
                                    setCheckedTopics(false)
                                    setCheckedRegions(false)
                                }}
                            >
                                Reset
                            </span>
                            <span
                                style={{ textDecoration: 'underline', color: '#3b7dba', cursor: 'pointer' }}
                                onClick={() => generateQuery(selectedOption)}
                            >
                                Apply
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
