import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import './Page2Form.css'
import CategoriesDropDownAlt from '../../../components/NewsEditedCard/CategoriesDropDownAlt'
import RegionSelectWidget from '../../../components/NewsEditedCard/RegionSelectWidget'
import DatePickerSingle from '../../../components/DatePickerSingle/DatePickerSingle'
import OpportunitiesWidget from '../../../components/NewsEditedCard/OpportunitiesWidget'
import TopicsDropDown from '../../../components/NewsEditedCard/TopcisDropDownAlt'
import Check from '../../../assets/icons/check.svg'
import axios from 'axios'
import store from '../../../Store'
import RegionHiearchyMultiSelect from '../../NewsFeedScreen/components/RegionHiearchyMultiSelect'

export default function Page2Form(props) {
    const { formData, handleFormUpdate } = props
    const usertype = store.getState().auth.user.user_type

    const newsSources = [
        {
            value: 'Mondaq',
            label: 'Mondaq',
        },
        {
            value: 'Competition',
            label: 'Competition',
        },
        {
            value: 'Webhose',
            label: 'Webhose',
        },
        {
            value: 'Yahoo Finance',
            label: 'Yahoo Finance',
        },
        {
            value: 'RSS',
            label: 'RSS',
        },
    ]

    return (
        <div>
            <div style={{ height: '35px' }} />

            <div className="addNew2__row">
                <div className="width60">
                    <h3 className="addNew2__label">News URL</h3>
                    <div className="addNew2__inputContainer">
                        <input
                            type="url"
                            className="addNews__input "
                            placeholder="Insert the news source here"
                            value={formData.sourceLink}
                            onChange={ev => handleFormUpdate('sourceLink', ev.target.value)}
                        />
                    </div>
                </div>

                <div style={{ marginRight: '32px', width: '300px' }}>
                    <h3 className="addNew2__label">Need Subscription</h3>
                    <div
                        className={formData.subscription ? 'regionSelect__box-active' : 'regionSelect__box'}
                        style={{ marginRight: '12px', padding: '7.5px' }}
                        onClick={() => handleFormUpdate('subscription', !formData.subscription)}
                    >
                        <div className="regionSelect__check">
                            {formData.subscription && (
                                <img src={process.env.REACT_APP_MEDIA_URL + Check} alt="region check mark" />
                            )}
                        </div>
                        <p>Subscription</p>
                    </div>
                </div>

                {usertype !== 'client_analyst' && usertype !== 'analyst' && (
                    <>
                        <div style={{ marginRight: '32px', width: '240px' }}>
                            <h3 className="addNew2__label">Featured Story</h3>
                            <div
                                className={formData.featured ? 'regionSelect__box-active' : 'regionSelect__box'}
                                style={{ marginRight: '12px', padding: '7.5px' }}
                                onClick={() => handleFormUpdate('featured', !formData.featured)}
                            >
                                <div className="regionSelect__check">
                                    {formData.featured && (
                                        <img src={process.env.REACT_APP_MEDIA_URL + Check} alt="region check mark" />
                                    )}
                                </div>
                                <p>Featured</p>
                            </div>
                        </div>

                        <div className="width23">
                            <h3 className="addNew2__label">Source</h3>
                            <div className="addNew2__inputContainer height40" style={{ border: 'none' }}>
                                <Select
                                    className="width100"
                                    options={newsSources}
                                    placeholder="Select source"
                                    value={formData.source.label}
                                    defaultValue={{ label: 'Webhose', value: 'Webhose' }}
                                    onChange={ev => handleFormUpdate('source', ev.value)}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>

            <div className="addNew2__row">
                <div className="width75">
                    <h3 className="addNew2__label">Company</h3>
                    <Select
                        isMulti
                        className="width100 "
                        placeholder="Select companies"
                        value={formData.companies}
                        options={props.allCompanies.sort((a, b) => (a.label > b.label ? 1 : -1))}
                        onChange={props.handleCompanyChange}
                    />
                </div>

                <div className="width23">
                    <h3 className="addNew2__label">News Date</h3>
                    <DatePickerSingle handleFormUpdate={handleFormUpdate} />
                </div>
            </div>

            <h3 className="addNew2__label">Title</h3>
            <div className="addNew2__row">
                <div className="addNew2__inputContainer width100 height42">
                    <input
                        type="text"
                        className="addNews__input width100"
                        placeholder="Copy & Paste the news header or create your own"
                        value={formData.title}
                        onChange={ev => handleFormUpdate('title', ev.target.value)}
                    />
                </div>
            </div>

            <h3 className="addNew2__label">Abstract</h3>
            <div className="addNew2__row">
                <div className="addNew2__inputContainer width100 height101">
                    <textarea
                        className="addNews2__textArea width100"
                        placeholder="Summarize the content or copy the introduction. It’s nice to keep it smaller than 5 lines"
                        value={formData.abstract}
                        onChange={ev => handleFormUpdate('abstract', ev.target.value)}
                    />
                </div>
            </div>

            <div className="addNew2__row">
                <div className="width32" style={{ marginRight: '16px' }}>
                    <h3 className="addNew2__label">Industry</h3>
                    <Select
                        isMulti
                        className="width100 "
                        placeholder="Select industries"
                        value={formData.industry}
                        options={props.allIndustries.sort((a, b) => (a.label > b.label ? 1 : -1))}
                        onChange={props.handleIndustryChange}
                    />
                </div>

                <div className="width32" style={{ marginRight: '16px' }}>
                    <h3 className="addNew2__label">Sector</h3>
                    <Select
                        isMulti
                        className=""
                        placeholder="Select sectors"
                        value={formData.sector}
                        options={props.allSectors.sort((a, b) => (a.label > b.label ? 1 : -1))}
                        onChange={props.handleSectorChange}
                    />
                </div>
                <div className="col-4">
                    {/* <RegionSelectWidget
                        region={formData.region}
                        handleRegionChange={props.handleRegionChange}
                        isStorySelected={false}
                        headingStyle={{ fontSize: '20px', marginTop: '18px' }}
                        boxStyle={{ padding: '10px' }}
                    /> */}
                    <h3 className="addNew2__label">Region/Country</h3>
                    {/* <Select
                        isMulti
                        className="width100 "
                        placeholder="Select Countries"
                        options={props.allRegions}
                        value={formData.country}
                        onChange={props.handleRegionChanges}
                    /> */}
                    <RegionHiearchyMultiSelect
                        regionHierarchy={props.regionHierarchy}
                        selectedQueries={props.selectedRegCoPro}
                        setSelectedQueries={props.setSection}
                        setSection={props.setSection}
                        isAddNews={true}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-8">
                    <h3 className="addNew2__label">Sub-category</h3>
                    <CategoriesDropDownAlt
                        subCategory={formData.subcategories}
                        isAddNews={true}
                        handleCategoryChange={props.handleCategoryChange}
                        allCategories={props.allCategories}
                        allSubCategoriesOnly={props.allSubCategoriesOnly}
                        handleSubCategoryChange={props.handleSubCategoryChange}
                    />
                </div>
            </div>

            <div className="row">
                {usertype !== 'client_analyst' && usertype !== 'analyst' && (
                    <div className="col-8">
                        <h3 className="addNew2__label">Topics</h3>
                        <TopicsDropDown
                            isAddNews={true}
                            selectedTopics={formData.topics}
                            allTopics={props.allTopics.sort((a, b) => (a.label > b.label ? 1 : -1))}
                            handleTopicChange={props.handleTopicChange}
                            extraStyle={{ width: '100%' }}
                        />
                    </div>
                )}
                {formData.source === 'Webhose' && (
                    <div className="col-4">
                        <h3 className="addNew2__label">Source Name</h3>
                        <div className="addNew2__inputContainer">
                            <input
                                type="url"
                                className="addNews__input"
                                placeholder="Insert the Source Name"
                                value={formData.source_name}
                                onChange={ev => handleFormUpdate('source_name', ev.target.value)}
                            />
                        </div>
                    </div>
                )}
            </div>

            {usertype !== 'client_analyst' && usertype !== 'analyst' && (
                <div className="row">
                    <div className="col">
                        <h3 className="addNew2__label">Opportunity</h3>
                        <div className="addNew2__row">
                            <OpportunitiesWidget
                                selectedOpportunities={formData.selectedOpportunities}
                                handleOppChange={props.handleOppChange}
                                handleOppTextChange={props.handleOppTextChange}
                                category={formData.categories}
                                industries={formData.industry.map(ind => ind.value)}
                                subCategory={formData.subcategories}
                            />
                        </div>
                    </div>
                </div>
            )}

            <h3 className="addNew2__label">LinkedIn Groups</h3>
            <div className="multiSelectDropDown__ContainerAlt" style={{ marginTop: '10px' }}>
                <div className="multiSelectDropDown__headingBoxAlt">
                    <div className="newsCard20__oppHeading">
                        <p>LinkedIn</p>
                        <p>Groups</p>
                    </div>
                    <div className="newsCard20__catBorder" style={{ border: 'none' }}>
                        <Select
                            isMulti
                            className="width100 "
                            placeholder="Select LinkedIn Groups"
                            options={props.allLinkedInGroups.sort((a, b) => (a.label > b.label ? 1 : -1))}
                            value={formData.linkedInGroups}
                            onChange={props.handleLinkedInChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
