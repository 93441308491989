import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
    Plate,
    createReactPlugin,
    createHistoryPlugin,
    createParagraphPlugin,
    createHeadingPlugin,
    createItalicPlugin,
    createUnderlinePlugin,
    createPlateComponents,
    createPlateOptions,
    createBoldPlugin,
} from '@udecode/plate'
// https://plate.udecode.io/
import { Formik, Form, Field } from 'formik'
import { Button } from 'reactstrap'
import AsyncSelect from 'react-select/async'
import { Circles } from 'react-loader-spinner'
import { toast } from 'react-toastify'
import BaseScreen from '../BaseScreen/BaseScreen'
import AdminHeader from './components/AdminHeader'
import SectorChecklist from './components/SectorChecklist'
import { addSectorFields as fields } from './constants/AnalystAdminConstants'
import { NavLink } from 'react-router-dom'
import { FaArrowLeft } from 'react-icons/fa'

export default function AnalystAdminAddSector(props) {
    const [selectedCompanies, setSelectedCompanies] = useState([])
    const [selectedAssociations, setSelectedAssociations] = useState([])
    const [onEditPage, setOnEditPage] = useState(false)
    const [industryInfo, setIndustryInfo] = useState({})
    const [checkList, setCheckList] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [overview, setOverview] = useState([
        {
            children: [
                {
                    text: 'Enter overview text here',
                },
            ],
        },
    ])

    const { type, id } = props.match.params

    useEffect(() => {
        if (type && id) {
            setOnEditPage(true)
            getIndustryInfo()
            getChecklist()
            const headerTitle = document.getElementById('header_title')
            headerTitle.textContent = 'Edit Industy/Sector'
        } else {
            const headerTitle = document.getElementById('header_title')
            headerTitle.textContent = 'Add Industy/Sector'
        }
        setIsLoading(false)
    }, [])

    const getIndustryInfo = async () => {
        if (type === 'Sector') {
            await axios.get(`/accounts/sector-information/${id}/`).then(response => {
                setIndustryInfo(response.data)
                setSelectedCompanies(response.data.featured_companies)
                setSelectedAssociations(response.data.associations)
                if (response.data.overview.charAt(0) === '[') {
                    //Rich text overview
                    setOverview(JSON.parse(response.data.overview))
                } else {
                    //string overview, converted to rich text
                    setOverview([
                        {
                            children: [
                                {
                                    text: response.data.overview,
                                },
                            ],
                        },
                    ])
                }
            })
        } else if (type === 'Industry') {
            await axios.get(`/accounts/industry-information/${id}/`).then(response => {
                setIndustryInfo(response.data)
                setSelectedCompanies(response.data.featured_companies)
                setSelectedAssociations(response.data.associations)
                if (response.data.overview.charAt(0) === '[') {
                    setOverview(JSON.parse(response.data.overview))
                } else {
                    setOverview([
                        {
                            children: [
                                {
                                    text: response.data.overview,
                                },
                            ],
                        },
                    ])
                }
            })
        }
    }
    const getChecklist = async () => {
        if (type === 'Sector') {
            await axios.get(`/news/checklist/?sector=${id}&industry`).then(response => {
                setCheckList(response.data)
            })
        } else if (type === 'Industry') {
            await axios.get(`/news/checklist/?sector&industry=${id}`).then(response => {
                setCheckList(response.data)
            })
        }
    }

    const getCompaniesSearch = value => {
        if (value.length > 2) {
            return axios.get(`/news/auto-complete/?type=company&string=${encodeURIComponent(value)}`).then(response => {
                return response.data.map(item => ({ label: item.name, value: item.id }))
            })
        }
    }
    const getAssociationsSearch = value => {
        if (value.length > 2) {
            return axios
                .get(`/news/auto-complete/?type=association&string=${encodeURIComponent(value)}`)
                .then(response => {
                    return response.data.map(item => ({ label: item.name, value: item.id }))
                })
        }
    }

    const removeTag = (id, type) => {
        if (type === 'company') {
            let itemTemp = [...selectedCompanies]
            itemTemp.filter(item => item.value !== id)
            setSelectedCompanies(itemTemp.filter(item => item.value !== id))
        }
        if (type === 'associations') {
            let itemTemp = [...selectedAssociations]
            itemTemp.filter(item => item.value !== id)
            setSelectedAssociations(itemTemp.filter(item => item.value !== id))
        }
    }

    const onSubmitNew = values => {
        const sectorSubmitObject = { ...values }
        if (values.isActive === 'true') {
            sectorSubmitObject.isActive = true
        } else if (values.isActive === 'false') {
            sectorSubmitObject.isActive = false
        } else {
            sectorSubmitObject.isActive = values.isActive
        }
        sectorSubmitObject.featuredCompanies = selectedCompanies.map(item => item.value)
        sectorSubmitObject.associations = selectedAssociations.map(item => item.value)
        sectorSubmitObject.overview = JSON.stringify(overview)
        console.log(sectorSubmitObject)
        if (values.industry === 'Sector') {
            axios
                .post(`/accounts/sector-information/`, sectorSubmitObject)
                .then(response => {
                    toast('Sector Created')
                    props.history.push('/app/admin/sector/')
                })
                .catch(err => {
                    console.log(err)
                    toast.warn('Error creating sector-' + err.message)
                })
        } else if (values.industry === 'Industry') {
            axios
                .post(`/accounts/industry-information/`, sectorSubmitObject)
                .then(response => {
                    toast('Industry Created')
                    props.history.push('/app/admin/sector/')
                })
                .catch(err => {
                    console.log(err)
                    toast.warn('Error creating sector-' + err.message)
                })
        }
    }

    const onSubmitEdit = values => {
        const sectorSubmitObject = { ...values }
        sectorSubmitObject.id = id
        if (values.isActive === 'true') {
            sectorSubmitObject.isActive = true
        } else if (values.isActive === 'false') {
            sectorSubmitObject.isActive = false
        } else {
            sectorSubmitObject.isActive = values.isActive
        }
        sectorSubmitObject.featuredCompanies = selectedCompanies.map(item => item.value)
        sectorSubmitObject.associations = selectedAssociations.map(item => item.value)
        sectorSubmitObject.overview = JSON.stringify(overview)
        if (type === 'Sector') {
            axios
                .post(`/accounts/sector-information/`, sectorSubmitObject)
                .then(response => {
                    toast('Sector Updated')
                    props.history.push('/app/admin/sector/')
                })
                .catch(err => {
                    console.log(err)
                    toast.warn('Error updating sector-' + err.message)
                })
        } else if (values.industry === 'Industry') {
            axios
                .post(`/accounts/industry-information/`, sectorSubmitObject)
                .then(response => {
                    toast('Industry Updated')
                    props.history.push('/app/admin/sector/')
                })
                .catch(err => {
                    console.log(err)
                    toast.warn('Error updating sector-' + err.message)
                })
        }
    }

    const editableProps = {
        style: {
            padding: '15px',
            width: '100%',
            height: '300px',
            backgroundColor: 'white',
            border: 'thin solid hsl(0, 0%, 80%)',
            borderRadius: '4px',
            color: 'gray',
        },
    }
    const pluginsBasic = [
        // editor
        createReactPlugin(), // withReact
        createHistoryPlugin(), // withHistory
        // elements
        createParagraphPlugin(), // paragraph element
        createHeadingPlugin(), // heading elements
        // marks
        createBoldPlugin(), // bold mark
        createItalicPlugin(), // italic mark
        createUnderlinePlugin(), // underline mark
    ]
    const components = createPlateComponents()
    const options = createPlateOptions()

    if (isLoading) {
        return (
            <BaseScreen>
                <Circles key={0} type="Grid" color="#adadad" height={80} width={80} />
            </BaseScreen>
        )
    }

    return (
        <BaseScreen>
            {/* <AdminHeader currentPage={'addSector'} /> */}

            <div className="adminEndUser__formBody" style={{ 'margin-top': '0%' }}>
                <Formik
                    enableReinitialize
                    initialValues={
                        onEditPage
                            ? {
                                  name: industryInfo.label,
                                  industry: props.match.params.type,
                                  privacy: industryInfo.privacy,
                                  nickname: industryInfo.nick_name,
                                  isActive: industryInfo.is_active,
                                  taxonomy: industryInfo.taxonomy,
                                  num_of_ipos: industryInfo.num_of_ipos,
                                  num_of_ma: industryInfo.num_of_ma,
                                  private_financings: industryInfo.private_financings,
                              }
                            : {
                                  name: '',
                                  industry: 'Sector',
                                  privacy: 'Private',
                                  nickname: '',
                                  isActive: true,
                                  taxonomy: 'standard',
                                  num_of_ipos: '',
                                  num_of_ma: '',
                                  private_financings: '',
                              }
                    }
                    onSubmit={onEditPage ? onSubmitEdit : onSubmitNew}
                >
                    {({ setFieldValue }) => {
                        // useEffect(() => {
                        if (onEditPage) {
                            setFieldValue('name', industryInfo.label)
                            setFieldValue('industry', type)
                            setFieldValue('privacy', industryInfo.privacy)
                            setFieldValue('num_of_ipos', industryInfo.num_of_ipos)
                            setFieldValue('num_of_ma', industryInfo.num_of_ma)
                        }
                        // }, [])

                        return (
                            <Form>
                                <div>
                                    <div class="d-sm-flex align-items-center justify-content-between mb-2">
                                        <div className="d-flex align-items-center">
                                            <NavLink
                                                style={{ 'text-decoration': 'none', alignItems: 'center' }}
                                                to="/app/admin/sector/"
                                            >
                                                <h3
                                                    style={{
                                                        color: '#696464',
                                                        fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                                                        fontWeight: 500,
                                                        textTransform: 'uppercase',
                                                        fontSize: '12px',
                                                        lineHeight: '1.25',
                                                        letterSpacing: '0.02857em',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <FaArrowLeft style={{ fontSize: '16px' }} />
                                                </h3>
                                            </NavLink>
                                            <h3 style={{ marginLeft: '20px', fontWeight: 600, alignItems: 'center' }}>
                                                New Sector
                                            </h3>
                                        </div>
                                        <Button
                                            type="submit"
                                            className="adminEndUser__submitButton"
                                            style={{
                                                width: '17%',
                                                height: '40px',
                                                'background-color': 'rgb(59, 125, 186)',
                                                color: 'white',
                                                'font-size': '16px',
                                                margin: 'unset',
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                    <div
                                        className="adminEndUser__sectorBodyLeft"
                                        style={{ width: '100%', borderTop: '1px solid lightgray' }}
                                    >
                                        <div className="adminEndUser__formRow" style={{ marginTop: '15px' }}>
                                            <div style={{ width: fields.name.width, marginRight: '20px' }}>
                                                <h3>Name</h3>
                                                <Field
                                                    type={fields.name.type}
                                                    name={fields.name.name}
                                                    id={fields.name.name}
                                                    placeholder={fields.name.placeholder}
                                                    style={{ width: '100%' }}
                                                    className="adminAddFirm__textInput"
                                                />
                                            </div>

                                            <div style={{ width: fields.industry.width, marginRight: '20px' }}>
                                                <h3>Industry Type</h3>
                                                <Field
                                                    name={fields.industry.name}
                                                    as={fields.industry.type}
                                                    id={fields.industry.name}
                                                    placeholder={fields.industry.placeholder}
                                                    style={{ width: '100%' }}
                                                    className="adminAddFirm__textInput"
                                                    disabled={onEditPage}
                                                >
                                                    <option value="Industry">Industry</option>
                                                    <option value="Sector">Sector</option>
                                                </Field>
                                            </div>

                                            <div style={{ width: fields.privacy.width }}>
                                                <h3>Privacy</h3>
                                                <Field
                                                    name={fields.privacy.name}
                                                    as={fields.privacy.type}
                                                    id={fields.privacy.name}
                                                    placeholder={fields.privacy.placeholder}
                                                    style={{ width: '100%' }}
                                                    className="adminAddFirm__textInput"
                                                >
                                                    <option value="Public">Public</option>
                                                    <option value="Private">Private</option>
                                                </Field>
                                            </div>
                                        </div>

                                        <div className="adminEndUser__formRow">
                                            <div style={{ width: fields.name.width, marginRight: '20px' }}>
                                                <h3>Nickname</h3>
                                                <Field
                                                    type={fields.nickname.type}
                                                    name={fields.nickname.name}
                                                    id={fields.nickname.name}
                                                    placeholder={fields.nickname.placeholder}
                                                    style={{ width: '100%' }}
                                                    className="adminAddFirm__textInput"
                                                />
                                            </div>
                                            <div style={{ width: fields.privacy.width, marginRight: '20px' }}>
                                                <h3>Is Active</h3>
                                                <Field
                                                    name={fields.isActive.name}
                                                    as={fields.isActive.type}
                                                    id={fields.isActive.name}
                                                    placeholder={fields.isActive.placeholder}
                                                    style={{ width: '100%' }}
                                                    className="adminAddFirm__textInput"
                                                >
                                                    <option value={true}>Active</option>
                                                    <option value={false}>Inactive</option>
                                                </Field>
                                            </div>
                                            <div style={{ width: fields.privacy.width }}>
                                                <h3>Taxonomy</h3>
                                                <Field
                                                    name={fields.taxonomy.name}
                                                    as={fields.taxonomy.type}
                                                    id={fields.taxonomy.name}
                                                    placeholder={fields.taxonomy.placeholder}
                                                    style={{ width: '100%' }}
                                                    className="adminAddFirm__textInput"
                                                >
                                                    <option value="Standard">Standard</option>
                                                    <option value="Custom">Custom</option>
                                                </Field>
                                            </div>
                                        </div>

                                        <div className="adminEndUser__formRow">
                                            <div style={{ width: fields.privateFinancing.width, marginRight: '20px' }}>
                                                <h3>Private Financing</h3>
                                                <Field
                                                    type={fields.privateFinancing.type}
                                                    name={fields.privateFinancing.name}
                                                    id={fields.privateFinancing.name}
                                                    placeholder={fields.privateFinancing.placeholder}
                                                    className="adminAddFirm__textInput"
                                                    style={{ width: '100%' }}
                                                />
                                            </div>

                                            <div style={{ width: fields.ipos.width, marginRight: '20px' }}>
                                                <h3>Number of IPO's</h3>
                                                <Field
                                                    type={fields.ipos.type}
                                                    name={fields.ipos.name}
                                                    id={fields.ipos.name}
                                                    placeholder={fields.ipos.placeholder}
                                                    className="adminAddFirm__textInput"
                                                    style={{ width: '100%' }}
                                                />
                                            </div>

                                            <div style={{ width: fields.mas.width }}>
                                                <h3>Number of MA's</h3>
                                                <Field
                                                    type={fields.mas.type}
                                                    name={fields.mas.name}
                                                    id={fields.mas.name}
                                                    placeholder={fields.mas.placeholder}
                                                    className="adminAddFirm__textInput"
                                                    style={{ width: '100%' }}
                                                />
                                            </div>
                                        </div>

                                        <h3 style={{ 'font-weight': '600' }}>Overview</h3>
                                        <h4 style={{ 'font-weight': '400' }}>
                                            ctrl+U / command+U = Underline , ctrl+B / command+B = Bold , ctrl+I /
                                            command+I = Italics , Enter / Return = line break
                                        </h4>
                                        <div className="adminEndUser__formRow">
                                            <Plate
                                                id="overview"
                                                editableProps={editableProps}
                                                value={overview}
                                                onChange={newValue => {
                                                    setOverview(newValue)
                                                }}
                                                plugins={pluginsBasic}
                                                components={components}
                                                options={options}
                                            />
                                        </div>

                                        <div className="adminEndUser__tagSelectBox">
                                            <h3 style={{ 'font-weight': '600' }}>Select Associations</h3>
                                            <div className="adminEndUser__formRow">
                                                <AsyncSelect
                                                    isMulti
                                                    name="Associations"
                                                    loadOptions={getAssociationsSearch}
                                                    onChange={value => {
                                                        if (value === null) {
                                                            setSelectedAssociations([])
                                                        } else {
                                                            setSelectedAssociations(value)
                                                        }
                                                    }}
                                                    value={selectedAssociations}
                                                    cacheOptions
                                                    placeholder="Associations"
                                                    className="adminEndUser__sectorSelectors"
                                                />
                                            </div>
                                            <h3 style={{ 'font-weight': '400' }}>Selected Associations</h3>
                                            <div className="adminEndUser__tagMap">
                                                {selectedAssociations.map(item => (
                                                    <div
                                                        className="adminEndUser__singleTag adminEndUser__darkTag"
                                                        key={'companyTag-' + item.value}
                                                    >
                                                        <p>{item.label}</p>
                                                        <p
                                                            className="adminEndUser__tagClose"
                                                            onClick={() => removeTag(item.value, 'associations')}
                                                        >
                                                            x
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        <div className="adminEndUser__tagSelectBox">
                                            <h3 style={{ 'font-weight': '600' }}>
                                                Select Featured Companies (type at least 3 characters to search)
                                            </h3>
                                            <div className="adminEndUser__formRow">
                                                <AsyncSelect
                                                    isMulti
                                                    name="Companies"
                                                    loadOptions={getCompaniesSearch}
                                                    onChange={value => {
                                                        setSelectedCompanies(value)
                                                    }}
                                                    value={selectedCompanies}
                                                    cacheOptions
                                                    placeholder="Companies"
                                                    className="adminEndUser__sectorSelectors"
                                                />
                                            </div>
                                            <h3 style={{ 'font-weight': '400' }}>Selected Companies</h3>
                                            <div className="adminEndUser__tagMap">
                                                {selectedCompanies.map(item => (
                                                    <div
                                                        className="adminEndUser__singleTag adminEndUser__darkTag"
                                                        key={'companyTag-' + item.value}
                                                    >
                                                        <p>{item.label}</p>
                                                        <p
                                                            className="adminEndUser__tagClose"
                                                            onClick={() => removeTag(item.value, 'company')}
                                                        >
                                                            x
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        {onEditPage && (
                                            <div>
                                                <SectorChecklist checkList={checkList} getChecklist={getChecklist} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </BaseScreen>
    )
}
