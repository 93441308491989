import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { NavLink } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { documentsDataTableColumns as columns } from './constants/AnalystAdminConstants'
import BaseScreen from '../BaseScreen/BaseScreen'
import AdminHeader from './components/AdminHeader'
import { Circles } from 'react-loader-spinner'

export default function AnalystAdminDocuments() {
    const [allDocuments, setAllDocuments] = useState([])
    const [filterText, setFilterText] = useState('')
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)

    useEffect(() => {
        axios.get(`/core/all-documents/`).then(response => {
            setAllDocuments(response.data)
        })
    }, [])

    const filteredItems = allDocuments.filter(
        item => item.title && item.title.toLowerCase().includes(filterText.toLowerCase())
    )

    const handleClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle)
            setFilterText('')
        }
    }

    return (
        <BaseScreen>
            {allDocuments.length > 0 ? (
                <>
                    <AdminHeader currentPage={'documents'} />

                    <div className="adminEndUser__filterSection">
                        <div className="adminEndUser__filters">
                            <div className="adminEndUser__emailFilterContainer">
                                <input
                                    id="search"
                                    type="text"
                                    placeholder="Filter By Title"
                                    className="analystAdmin__filterTextField"
                                    aria-label="Search Input"
                                    value={filterText}
                                    onChange={e => setFilterText(e.target.value)}
                                    key={'doc title Filter'}
                                />
                                <div type="button" onClick={handleClear} className="analystAdmin__ClearButton">
                                    X
                                </div>
                            </div>
                        </div>

                        <NavLink to="/app/admin/add-documents/" className="adminEndUser__newUserButton">
                            <span>Upload Document +</span>
                        </NavLink>
                    </div>

                    <div className="adminEndUser__body">
                        <DataTable
                            title="Documents"
                            columns={columns}
                            data={filteredItems}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle}
                            persistTableHead
                        />
                    </div>
                </>
            ) : (
                <div>
                    <Circles key={0} type="Circles" color="#adadad" height={80} width={80} />
                </div>
            )}
        </BaseScreen>
    )
}
