import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { getCookie, getTrans } from '../../utils'
import Logo from '../../components/Logo/Logo'
import axios from 'axios'
import { AiFillWarning } from 'react-icons/ai'
import './AuthWrapper.css'

class AuthWrapper extends PureComponent {
    componentDidMount() {
        const token = getCookie('csrftoken')

        if (token) {
            axios.defaults.headers.common['X-CSRFToken'] = token
        }
    }

    render() {
        const { title } = this.props

        return (
            <>
                {/* <p className="warning-message-login">
                    <AiFillWarning style={{ fontSize: '20px', marginBottom: '7px' }} /> A Major push happening now to
                    production. Please expect a downtime of 24 hours
                </p> */}
                {title === 'Login' ? (
                    <>{this.props.children}</>
                ) : (
                    <Wrapper>
                        <AuthBody>
                            <div className="text-center mb-3">
                                <Logo white />
                            </div>
                            <Inner>
                                <Title>{title}</Title>
                                {this.props.children}
                            </Inner>
                            <Footer>
                                <span className="loginForm__noAccountSignUp">
                                    <h3 className="loginForm__noAccount">Don’t have an account?</h3>
                                    <Link
                                        to="/"
                                        className="loginForm__signUp"
                                        style={{
                                            fontSize: '24px',
                                            fontWeight: 600,
                                            color: '#3b7dba',
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        Sign up
                                    </Link>
                                </span>
                                <span className="loginForm__needHelpContactUs">
                                    <h3 className="loginForm__noAccount-firm" style={{ marginTop: '10px' }}>
                                        Need Help? Contact us at&nbsp;
                                    </h3>
                                    <h3 className="loginForm__noAccount-firm">
                                        <a
                                            href="mailto:support@kaitongo.com"
                                            className="loginForm__signUp-firm"
                                            style={{ color: '#3b7dba', fontSize: '18px' }}
                                        >
                                            support@kaitongo.com
                                        </a>
                                    </h3>
                                </span>

                                <div className="loginForm__legalRow">
                                    <Link to="/terms" className="mr-4 legal">
                                        {getTrans('Terms')}
                                    </Link>{' '}
                                    <span
                                        style={{
                                            fontWeight: 300,
                                            marginTop: '6px',
                                            position: 'absolute',
                                            height: '13px',
                                            marginLeft: '5px',
                                            marginRight: '5PX',
                                            border: '1px solid rgba(255,255,255,0.4)',
                                        }}
                                    />{' '}
                                    <Link to="/privacy" className="mr-4 legal" style={{ marginLeft: '15px' }}>
                                        {getTrans('Privacy')}
                                    </Link>{' '}
                                    <span
                                        style={{
                                            fontWeight: 300,
                                            marginTop: '6px',
                                            marginLeft: '5px',
                                            position: 'absolute',
                                            height: '13px',
                                            border: '1px solid rgba(255,255,255,0.4)',
                                        }}
                                    />{' '}
                                    <Link to="/saas" className="mr-4 legal" style={{ marginLeft: '15px' }}>
                                        {getTrans('SaaS')}
                                    </Link>
                                </div>
                                <div className="loginForm__legalRow">
                                    <span className="mr-4">{getTrans('© 2023 Kaitongo Inc.')}</span>
                                </div>
                            </Footer>
                        </AuthBody>
                    </Wrapper>
                )}
            </>
        )
    }
}

export default AuthWrapper

const Wrapper = styled.div`
    min-height: 100vh;
    background: #05183d;
    padding-top: 3rem;
`

const AuthBody = styled.div`
    max-width: 388px;
    margin: auto;
`

const Inner = styled.div`
    background: #fff;
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 0;
`

const Title = styled.h2`
    margin-bottom: 1.5rem;
    text-align: left;
`

const Footer = styled.div`
    text-align: left;
    a,
    span {
        font-size: 12px;
        font-weight: 600;
        text-align: left;
        color: rgba(255, 255, 255, 0.4);
    }
`
