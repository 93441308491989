import React, { PureComponent } from 'react'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRange } from 'react-date-range'
import { subDays, addDays } from 'date-fns'
import { getShortDate } from '../../utils/dateHelpers'

class NewsEditDropdownFilterDatePicker extends PureComponent {
    state = {
        picker: false,
        setdateRangeFilter: [],
        defaultDateRange: [
            {
                startDate: this.props.selectedDateRange.startDate,
                endDate: this.props.selectedDateRange.endDate,
                key: 'selection',
            },
        ],
    }
    wrapperRef = React.createRef()
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside)
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
    }
    handleClickOutside = event => {
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ picker: false })
        }
    }

    handlePicker = () => {
        var changedpicker = this.state.picker === false ? true : false
        this.setState({ picker: changedpicker })
    }

    handleSetdateRangeFilter = ranges => {
        this.props.customOnchange && this.props.customOnchange(ranges)
        this.setState({ defaultDateRange: [ranges.selection] })
        this.props.setDateRange(ranges.selection)
    }

    render() {
        const dateRangePickerStyles = {
            position: this.props.isFindAndShare ? 'fixed' : 'absolute',
            zIndex: 80,
            marginLeft: this.props.isFindAndShare ? '220px' : '212px',
            marginTop: this.props.isFindAndShare ? '-270px' : '-225px',
            boxShadow: '2px 2px 8px 0px rgba(0,0,0,0.30)',
        }

        const dropdownStyle = {
            width: '200px',
            height: '38px',
            paddingLeft: '20px',
            borderRadius: '3px',
            marginRight: '10px',
            marginTop: '8px',
            border: '1px solid rgba(169, 169, 169, 0.5)',
            backgroundColor: 'white',
            cursor: 'pointer',
        }

        const findAndSharedropdownStyle = {
            width: '195px',
            height: '30px',
            padding: '0px 10px',
            borderRadius: '3px',
            marginTop: '10px',
            border: '1px solid rgba(169, 169, 169, 0.5)',
            backgroundColor: 'white',
            cursor: 'pointer',
            textAlign: 'center',
        }
        return (
            <div>
                {!this.props.isFindAndShare && (
                    <div style={dropdownStyle} onClick={this.handlePicker}>
                        {this.props.selectedDateRange.startDate && this.props.selectedDateRange.endDate ? (
                            <p style={{ marginTop: '6px', color: 'black' }}>
                                {getShortDate(this.props.selectedDateRange.startDate) +
                                    ' - ' +
                                    getShortDate(this.props.selectedDateRange.endDate)}
                            </p>
                        ) : (
                            <p style={{ marginTop: '6px', marginLeft: '-7px', fontSize: '14px', color: 'black' }}>
                                MMM DD/YY - MMM DD/YY
                            </p>
                        )}
                    </div>
                )}

                {this.props.isFindAndShare && (
                    <div style={findAndSharedropdownStyle} onClick={this.handlePicker}>
                        {this.props.selectedDateRange.startDate && this.props.selectedDateRange.endDate ? (
                            <span style={{ marginTop: '6px', color: 'black', fontSize: '13px' }}>
                                {getShortDate(this.props.selectedDateRange.startDate) +
                                    ' - ' +
                                    getShortDate(this.props.selectedDateRange.endDate)}
                            </span>
                        ) : (
                            <span style={{ marginTop: '6px', fontSize: '13px', color: 'black' }}>
                                MMM DD/YY - MMM DD/YY
                            </span>
                        )}
                    </div>
                )}

                {this.state.picker && (
                    <div style={dateRangePickerStyles} ref={this.wrapperRef}>
                        <DateRange
                            onChange={this.handleSetdateRangeFilter}
                            moveRangeOnFirstSelection={false}
                            ranges={this.state.defaultDateRange}
                            minDate={new Date('2023-01-01')}
                            maxDate={addDays(new Date(), 1)}
                        />
                    </div>
                )}
            </div>
        )
    }
}

export default NewsEditDropdownFilterDatePicker
