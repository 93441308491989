import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import './LoginForm.css'
import { validateForm } from '../utils/validateForm'
import { Button } from 'reactstrap'
import { Form, Formik } from 'formik'
import RenderField from '../form-elements/RenderField'
import { loginFunction } from '../utils/login'
import { getTrans } from '../utils'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import FreemiumSignup from '../screens/UserSignup/FreemiumSignup'
import { FiMail } from 'react-icons/fi'
import Logo from '../components/Logo/Logo'
import LoginFooter from '../wrappers/LoginFooter'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const fields = [
    {
        label: 'Email',
        value: '',
        name: 'email',
        component: 'charField',
        placeholder: 'Enter your email',
        type: 'email',
        required: true,
    },
    {
        label: 'Password',
        value: '',
        name: 'password',
        component: 'password',
        placeholder: 'Password',
        type: 'password',
        required: true,
    },
]
const rememberCheckbox = {
    label: 'Remember me',
    value: false,
    name: 'remember_me',
    component: 'checkbox',
    type: 'checkbox',
    required: false,
}

class LoginForm extends PureComponent {
    // state = {}

    constructor(props) {
        super(props)
        this.state = {
            loginErrorMsg: '',
            type: 'signIn',
            verify: false,
            otp: '',
            mail: '',
            timer: 60, // Timer in seconds
            timerRunning: false,
            otpmsg: '',
            pass: '',
        }
        this.timerInterval = null // Variable to store the timer interval
    }

    componentDidMount() {
        const path = window.location.pathname
        if (path === '/signup') {
            this.setState({ type: 'signUp' })
        } else {
            this.setState({ type: 'signIn' })
        }
    }

    handleOnClick = text => {
        if (text !== this.state.type) {
            this.setState({ type: text })
            if (text === 'signIn') {
                window.location.pathname = '/'
            } else {
                window.location.pathname = '/signup'
            }
        }
    }

    handleOnMailSubmit = (email, password) => {
        this.setState({ mail: email })
        this.setState({ pass: password })

        this.setState({ verify: true })
    }

    onSubmit = (values, { setSubmitting }) => {
        const { history, location } = this.props

        setSubmitting(false)

        loginFunction(values, history, location).then(message => {
            if (message === 'Could not login user') {
                this.setState({
                    loginErrorMsg: getTrans('Could not login with provided email and password.'),
                })
            } else if (message === 'Could not login user. Contact Support team') {
                const errorMsg = (
                    <p style={{ fontSize: '16px', textAlign: 'center' }}>
                        <span>{getTrans('Please contact the support team')}</span>
                        <a
                            href={`mailto:support@kaitongo.com?subject=Login%20Issue%20-%20Username:%20${values.email}&body=Hello%20Support%20Team,%0D%0DI%20am%20currently%20I%20am%20currently%20facing%20difficulties%20accessing%20my%20dashboard.%20My%20username%20is%20${values.email}%20Unfortunately,%20I%20am%20unable%20to%20log%20in%20using%20my%20credentials.%0D%0D%0DCould%20you%20please%20assist%20me%20in%20resolving%20this%20issue?%20I%20appreciate%20your%20prompt%20attention%20to%20this%20matter.`}
                            className="email_not_verified"
                        >
                            {getTrans('click here')}
                        </a>
                    </p>
                )

                this.setState({
                    loginErrorMsg: errorMsg,
                })
            }
            if (message === 'Email not Verified') {
                const errorMsg = (
                    <p id="mail_not_verified" style={{ fontSize: '12px', textAlign: 'center' }}>
                        <span>Email not Verified</span>
                        <span
                            onClick={e => {
                                handleActivateEmail(e, values)
                            }}
                            className="email_not_verified"
                        >
                            {getTrans('click here')}
                        </span>
                        {/* <span>to get verification mail</span> */}
                        <span>to get OTP</span>
                    </p>
                )

                this.setState({
                    loginErrorMsg: errorMsg,
                })
            }
        })

        const handleActivateEmail = (e, values) => {
            document.getElementById('mail_not_verified').textContent = 'Sending ..... '
            this.setState({ mail: values.email })
            this.setState({ pass: values.password })
            axios
                // .get(`/accounts/verification-mail/?email=${values.email}`)
                // .then(res => {
                //     document.getElementById('mail_not_verified').textContent = 'Verification Mail sent '
                //     document.getElementById('mail_not_verified').style.color = 'green'
                //     setTimeout(() => {
                //         window.location.reload('/')
                //     }, 3000)
                // })
                .get(`/accounts/resend-code/?email=${values.email}`)
                .then(res => {
                    this.setState({ verify: true })
                })
                .catch(err => {
                    document.getElementById('mail_not_verified').textContent = 'Failed to sent Mail'
                    console.log(err)
                })
        }
    }

    handleChange = (index, event) => {
        let { value } = event.target
        const { otp } = this.state
        const updatedOTP = otp.split('')

        // Remove any non-digit characters
        value = value.replace(/\D/g, '')

        // Concatenate all OTP digits and limit to 6 characters
        const newOTP =
            updatedOTP.slice(0, index).join('') +
            value +
            updatedOTP
                .slice(index + 1)
                .join('')
                .substring(0, 5 - index)

        // Update the OTP state with the new value
        this.setState({
            otp: newOTP,
        })

        // If the current input field is filled and there's a next field, move focus to it
        if (value && index < 5) {
            document.querySelector(`.otp__field__${index + 2}`).focus()
        }

        if (index > 0 && value === '') {
            document.querySelector(`.otp__field__${index}`).focus()
            this.setState({ otpmsg: '' })
        }

        // if (index === 5) {
        //     axios
        //         .get(`/accounts/activate-signup/?otp=${this.state.otp}&email=${this.state.mail}`)
        //         .then(res => {
        //             console.log(res)
        //             this.setState({ otpmsg: res.data })
        //         })
        //         .catch(err => console.log(err))

        //     // this.setState({ otpmsg: '' })
        // }
    }

    handleResendClick = () => {
        axios
            .get(`/accounts/resend-code/?email=${this.state.mail}`)
            .then(res => console.log(res))
            .catch(err => console.log(err))
        this.setState({ otp: '' })
        this.setState({ otpmsg: '' })

        this.setState({ timer: 60, timerRunning: true }, () => {
            this.timerInterval = setInterval(this.tick, 1000)
        })
    }

    tick = () => {
        const { timer } = this.state
        if (timer > 0) {
            this.setState({ timer: timer - 1 })
        } else {
            clearInterval(this.timerInterval)
            this.setState({ timerRunning: false })
        }
    }
    render() {
        let userEmail = ''
        if (this.props.location.search.includes('email')) {
            userEmail = this.props.location.search.split('=')[1]
        }
        const containerClass = 'container ' + (this.state.type === 'signUp' ? 'right-panel-active' : '')
        const { otp, timer, timerRunning } = this.state

        return (
            <div
                className="Appss"
                style={{
                    background: this.state.verify ? 'rgb(5, 24, 61)' : 'whitesmoke',
                    // backgroundImage: !this.state.verify
                    //     ? 'url("https://img.freepik.com/premium-photo/night-starry-sky-with-stars-constellations-nebulae-galaxies-night-abstract-dark-blue-background_118086-4574.jpg?w=900")'
                    //     : null,
                }}
            >
                {this.state.verify ? (
                    <div style={{ marginTop: '-2%' }}>
                        <div style={{ textAlign: 'center', color: 'white' }}>
                            {/* <FiMail style={{ width: '80px', height: '80px' }} /> */}
                            {/* <h1 style={{ color: 'white', marginTop: '10px' }}>Thanks for Signing Up! </h1>
                            <p style={{ marginTop: '20px' }}>
                                We have sent an email for verification. Please check and validate to start using
                                Kaitongo.
                            </p> */}
                            <form className="otp-form" name="otp-form" onSubmit={e => e.preventDefault()}>
                                <div className="title">
                                    <h3>OTP VERIFICATION</h3>
                                    <p className="info" style={{ color: 'black' }}>
                                        An OTP has been sent to {this.state.mail}
                                    </p>
                                    <p className="msg" style={{ color: 'black' }}>
                                        Please enter OTP to verify
                                    </p>
                                </div>
                                <div className="otp-input-fields">
                                    {[...Array(6)].map((_, index) => (
                                        <input
                                            key={index}
                                            type="number"
                                            className={`otp__digit otp__field__${index + 1}`}
                                            value={otp[index] || ''}
                                            onChange={e => this.handleChange(index, e)}
                                            onFocus={e => e.target.select()}
                                        />
                                    ))}
                                </div>
                                <div className="result">
                                    {otp.length > 5 && this.state.otpmsg !== '' && (
                                        <p id="_otp" className={'_notok'}>
                                            {this.state.otpmsg}
                                        </p>
                                    )}
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <button
                                        disabled={timerRunning}
                                        style={{ opacity: timerRunning ? 0.7 : 1 }}
                                        className="resend-btn"
                                        onClick={this.handleResendClick}
                                    >
                                        {timerRunning ? `Resend in ${timer}s` : 'Resend'}
                                    </button>
                                    <button
                                        onClick={() => {
                                            axios
                                                .get(
                                                    `/accounts/activate-signup/?otp=${this.state.otp}&email=${this.state.mail}`
                                                )
                                                .then(res => {
                                                    if (res.data === 'OTP Verified Successfully') {
                                                        const { history, location } = this.props
                                                        const login_values = {
                                                            email: this.state.mail,
                                                            password: this.state.pass,
                                                            remember_me: false,
                                                            type: 'login',
                                                        }

                                                        loginFunction(login_values, history, location)
                                                    } else {
                                                        this.setState({ otpmsg: res.data })
                                                    }
                                                })
                                                .catch(err => console.log(err))
                                        }}
                                        style={{ padding: '12px 12px', borderRadius: '50%' }}
                                    >
                                        <ArrowForwardIcon />
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                ) : (
                    <div className={containerClass} id="container">
                        <div className="form-container sign-up-container">
                            <FreemiumSignup handleOnMailSubmit={this.handleOnMailSubmit} mail={this.state.mail} />
                        </div>
                        <div className="form-container sign-in-container">
                            <div>
                                <h2 className="m-25" style={{ textAlign: 'center' }}>
                                    Login
                                </h2>
                                <Formik
                                    initialValues={{
                                        email: userEmail,
                                        password: '',
                                        remember_me: false,
                                        type: 'login',
                                    }}
                                    validate={values => validateForm(values, fields)}
                                    onSubmit={this.onSubmit}
                                    render={({ status, isSubmitting, ...formProps }) => (
                                        <Form>
                                            {fields.map(field => {
                                                return RenderField(field, formProps)
                                            })}
                                            <div
                                                className="loginForm__rememberAndForgot"
                                                style={{
                                                    margin: '0 0.2rem 1rem  0',
                                                    display: 'block',
                                                    textAlign: 'end',
                                                    marginTop: '-20px',
                                                }}
                                            >
                                                {/* {RenderField(rememberCheckbox, formProps)} */}
                                                <u>
                                                    <Link to="/forgot-password" className="loginForm__forgot">
                                                        {getTrans('Forgot password?')}
                                                    </Link>
                                                </u>
                                            </div>
                                            <div className="input-feedback">{this.state.loginErrorMsg}</div>
                                            <div className="loginButtons">
                                                <button
                                                    color="primary"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                    style={{
                                                        width: 'max-content',
                                                        margin: 'auto',
                                                        backgroundColor: '#05183d',
                                                    }}
                                                >
                                                    {getTrans('Login')}
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="overlay-container">
                            <div className="overlay">
                                <div className="overlay-panel overlay-left">
                                    <div className="text-center mb-3" style={{ marginTop: '-75%' }}>
                                        <Logo white />
                                    </div>
                                    <div style={{ position: 'relative' }} className="t-10">
                                        <h2 style={{ color: 'white' }} className="f-24">
                                            Welcome Back!
                                        </h2>
                                        <p style={{ marginBottom: '0px' }}>
                                            To keep connected with us, please login with your personal info
                                        </p>
                                        <button
                                            className="ghost"
                                            id="signIn"
                                            style={{ marginTop: '10px' }}
                                            onClick={() => this.handleOnClick('signIn')}
                                        >
                                            Sign In
                                        </button>
                                    </div>
                                    <LoginFooter />
                                </div>
                                <div className=" overlay-panel overlay-right">
                                    <div className="text-center mb-3" style={{ marginTop: '-75%' }}>
                                        <Logo white />
                                    </div>
                                    <div style={{ position: 'relative' }} className="t-10">
                                        <h2 style={{ color: 'white' }} className="f-24">
                                            Create a free account now
                                        </h2>
                                        <p style={{ marginBottom: '0px' }}>
                                            Enter few details and start your journey with us
                                        </p>
                                        <button
                                            className="ghost"
                                            style={{ marginTop: '10px' }}
                                            id="signUp"
                                            onClick={() => this.handleOnClick('signUp')}
                                        >
                                            Sign Up
                                        </button>
                                    </div>
                                    <LoginFooter />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default withRouter(LoginForm)
