import React, { useEffect, useState } from 'react'
import {
    Plate,
    createReactPlugin,
    createHistoryPlugin,
    createParagraphPlugin,
    createHeadingPlugin,
    createItalicPlugin,
    createUnderlinePlugin,
    createPlateComponents,
    createPlateOptions,
    createBoldPlugin,
} from '@udecode/plate'
import ReactECharts from 'echarts-for-react'
import './IndustryAtAGlance.css'
import { CgNotes } from 'react-icons/cg'

export default function IndustryAtAGlance(props) {
    const { newsGraph, subCategoriesGraph, name, type } = props
    const [newsData, setNewsData] = useState([])
    const [subCategoryData, setSubCategoryData] = useState([])

    useEffect(() => {
        const transformedData = newsGraph.map(val => {
            return { month: val.month, data: val.topicCount }
        })
        setNewsData(transformedData)

        const output = {}
        newsGraph.forEach(item => {
            const { month, subcategoriesCount } = item

            subcategoriesCount.forEach(subcategory => {
                const { key, doc_count } = subcategory

                if (!output[key]) {
                    output[key] = []
                }

                output[key].push({
                    month: month,
                    newsCount: doc_count,
                })
            })
        })

        // Get all unique months across all entries
        const allMonths = Array.from(
            new Set(
                newsGraph.reduce((months, item) => {
                    const subcategoryMonths = item.subcategoriesCount.map(subcategory => item.month)
                    return months.concat(subcategoryMonths)
                }, [])
            )
        )

        for (const category in output) {
            output[category] = allMonths.map(month => {
                const existingEntry = output[category].find(entry => entry.month === month)
                return existingEntry || { month, newsCount: 0 }
            })

            output[category].sort((a, b) => a.month - b.month)
        }

        const subTransformedData = Object.entries(output).map(([name, values]) => {
            return {
                name: name,
                type: 'line',
                month: values.map(item => item.month),
                data: values.map(item => item.newsCount),
            }
        })

        setSubCategoryData(subTransformedData)
    }, [newsGraph])

    let overviewText = [
        {
            children: [
                {
                    text: 'Overview',
                },
            ],
        },
    ]
    if (props.Overview && props.Overview.overview) {
        if (props.Overview.overview.charAt(0) === '[') {
            overviewText = JSON.parse(props.Overview.overview)
        } else {
            overviewText = [
                {
                    children: [
                        {
                            text: props.Overview.overview,
                        },
                    ],
                },
            ]
        }
    }

    const editableProps = {
        style: {
            width: '100%',
        },
        readOnly: true,
    }
    const pluginsBasic = [
        // editor
        createReactPlugin(), // withReact
        createHistoryPlugin(), // withHistory
        // elements
        createParagraphPlugin(), // paragraph element
        createHeadingPlugin(), // heading elements
        // marks
        createBoldPlugin(), // bold mark
        createItalicPlugin(), // italic mark
        createUnderlinePlugin(), // underline mark
    ]
    const components = createPlateComponents()
    const options = createPlateOptions()

    const option = {
        title: {
            text: 'Number of Media Hits',
            textStyle: {
                fontSize: 15,
            },
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
            },
        },

        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: newsData && newsData.map(v => v.month),
            axisLabel: {
                rotate: 20,
            },
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                data: newsData && newsData.map(v => v.data),
                type: 'line',
                lineStyle: {
                    color: '#0E86D4',
                },
                areaStyle: {
                    color: '#64ccee',
                },
                smooth: false,
            },
        ],
    }

    const events = {
        title: {
            text: 'Trend by Events',
            textStyle: {
                fontSize: 15,
            },
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
            },
        },

        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: subCategoryData && subCategoryData[0] && subCategoryData[0].month,
        },
        yAxis: {
            type: 'value',
        },
        series: subCategoryData,
    }

    return (
        <div className="atAGlance__headingAndContainer">
            <h3 className="atAGlances__Heading">
                {type === 'industries' ? 'Industry' : type === 'sectors' ? 'Sector' : 'Topic'} At A Glance
            </h3>

            <div className="atAGlances__container">
                <div className="companyOverview__logoAndName" style={{ paddingBottom: '15px' }}>
                    <p className="companyOverview__companyName"> {props.Overview && props.Overview.name}</p>
                    <button
                        title="Pre-meeting brief"
                        className="deleteButton"
                        style={{
                            marginBottom: '0px',
                            background: 'rgb(14, 74, 147)',
                            marginLeft: '30px',
                        }}
                        onClick={props.getPreMeetingData}
                    >
                        <CgNotes style={{ fontSize: '24', color: 'white' }} />
                    </button>
                </div>
                <Plate
                    id="overview"
                    editableProps={editableProps}
                    value={overviewText}
                    plugins={pluginsBasic}
                    components={components}
                    options={options}
                />
            </div>

            {newsData && newsData.length > 0 && (
                <div className="d-flex justify-content-between">
                    <div
                        style={{
                            width: '48%',
                            height: '90%',
                            padding: '25px',
                            marginTop: '30px',

                            backgroundColor: 'white',
                        }}
                    >
                        <ReactECharts option={option} />
                    </div>

                    {subCategoryData && subCategoryData.length > 0 && (
                        <div
                            style={{
                                width: '48%',
                                height: '90%',
                                padding: '25px',
                                marginTop: '30px',

                                backgroundColor: 'white',
                            }}
                        >
                            <ReactECharts option={events} />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
