import React from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { components } from 'react-select'

export default function IndustryHierarchyHeaders(props) {
    const { combinedArray, selectedSector, setSelectedSector } = props
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            textAlign: 'left',
        }),
        control: provided => ({
            ...provided,
            border: 'none',
            outline: 'none',
            width: 'max-content',
            boxShadow: 'none',
        }),
        menuPortal: base => ({
            ...base,
            zIndex: 9999,
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: state.data.value === selectedSector.id ? '#3b7dba' : '', // Set your desired text color
        }),
        menu: base => ({
            ...base,
            position: 'absolute',
            width: 'max-content',
        }),
    }

    const Option = props => {
        const { data } = props
        const optionStyle = {
            paddingLeft: data.type === 'industries' ? '0' : data.type === 'sectors' ? '20px' : '40px',
        }

        return (
            <div>
                <components.Option {...props}>
                    <span style={optionStyle}>{props.label}</span>
                </components.Option>
            </div>
        )
    }

    const MultiValue = props => (
        <components.MultiValue {...props}>
            <>{props.data.label}</>
        </components.MultiValue>
    )

    const animatedComponents = makeAnimated()

    const renderOptions = options => {
        return options.map(option => ({
            value: option.id,
            label: option.name,
            type: option.type,
        }))
    }
    return (
        <>
            <Select
                options={renderOptions(combinedArray)}
                onChange={v => {
                    setSelectedSector({ id: v.value, type: v.type })
                }}
                defaultValue={renderOptions(combinedArray)[0]}
                styles={{
                    ...customStyles,
                    menuPortal: base => ({ ...base, ...customStyles.menuPortal(base) }),
                    menu: base => ({ ...base, ...customStyles.menu(base) }),
                }}
                menuPortalTarget={document.body}
                components={{
                    Option,
                    MultiValue,
                    animatedComponents,
                }}
            />
        </>
    )
}
