import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import FreemiumForm from './components/FreemiumForm'
import { loginFunction } from '../../utils/login'
import { logoutUserNoRedirect } from '../../actions/AuthActions'
import './UsersignupMain.css'
import Logo from '../../components/Logo/Logo'
import { FiMail } from 'react-icons/fi'
import { AiFillWarning } from 'react-icons/ai'
import { getCookie } from '../../utils'
export default function FreemiumSignup(props) {
    const token = getCookie('csrftoken')
    axios.defaults.headers.common['X-CSRFToken'] = token
    const [userForm, setUserForm] = useState({
        first_name: '',
        last_name: '',
        email: '',
        country: '',
        password: '',
        password_confirm: '',
        practice_area: 5,
        topic: [],
        news_type: [],
        role: '',
        others: {
            country: '',
            userroles: '',
            subcategory: '',
            topic: '',
            practicearea: '',
        },
    })
    // const [verify, setVerify] = useState(false)
    const { verify, setVerify } = props
    const [isLoader, setIsLoader] = useState(false)

    useEffect(() => {
        logoutUserNoRedirect()
    }, [])

    const createNewUser = () => {
        let userObj = {
            first_name: userForm.first_name,
            last_name: userForm.last_name,
            email: userForm.email,
            password: userForm.password,
            // regions: userForm.country
            //     .filter(val => val.type === 'Region')
            //     .map(val => {
            //         return parseInt(val.value.split('_')[1])
            //     }),
            // country: userForm.country
            //     .filter(val => val.type === 'Country')
            //     .map(val => {
            //         return parseInt(val.value.split('_')[1])
            //     }),
            // provinces: userForm.country
            //     .filter(val => val.type === 'Province')
            //     .map(val => {
            //         return parseInt(val.value.split('_')[1])
            //     }),
            subcategories: userForm.news_type
                .filter(val => val.type === 'category')
                .flatMap(val => val.subcategories.map(item => item.id)),
            // topics: userForm.news_type.filter(val => val.type === 'topic').map(val => val.value),
            // categories: userForm.news_type.filter(val => val.type === 'category').map(val => val.value),
            sectors: userForm.news_type.filter(val => val.type === 'Sector').map(val => val.value),
            industries: userForm.news_type.filter(val => val.type === 'Industry').map(val => val.value),
            role: userForm.role.value === 'Other' ? '' : userForm.role.value,
            practice_area: userForm.practice_area.value === 'Other' ? '' : userForm.practice_area.value,
            others: [userForm.others],
            is_active: false,
            subscription_type: 'Firm',
            // firm: 83,
            plan_type: 'Freemium',
            receive_verification_emails: true,
        }

        const axiosInstance = axios.create()

        axiosInstance.defaults.headers.common.accept = 'application/json'
        axiosInstance
            .post('/accounts/new-user/', userObj, {
                headers: { 'X-CSRFToken': token },
            })
            .then(() => {
                console.log('User created')
                setIsLoader(false)
                const initialValues = {
                    first_name: '',
                    last_name: '',
                    email: '',
                    country: '',
                    password: '',
                    password_confirm: '',
                    practice_area: 5,
                    // topic: [],
                    news_type: [],
                    role: '',
                    others: {
                        country: '',
                        userroles: '',
                        subcategory: '',
                        topic: '',
                        practicearea: '',
                    },
                }

                setUserForm(initialValues)
                // setVerify(true)
                props.handleOnMailSubmit(userObj.email, userObj.password)
            })
            .catch(err => {
                console.log(err.response)
                setIsLoader(false)
                if (
                    err.response.data.email &&
                    err.response.data.email.includes('user with this Email already exists.')
                ) {
                    toast('Email already exits')
                } else {
                    toast('Unknown error creating user')
                }
            })
    }

    return (
        <div
            className="signup__mainContainer"
            // style={{ minHeight: '100vh', background: '#05183d', paddingTop: '3rem' }}
        >
            <div className="signup__body">
                <FreemiumForm
                    isLoader={isLoader}
                    setIsLoader={setIsLoader}
                    userForm={userForm}
                    setUserForm={setUserForm}
                    createNewUser={createNewUser}
                />
            </div>
        </div>
    )
}
