import React from 'react'
import { Grid } from '@mui/material'
import './MailModal.css'
import NewsBody from './NewsBody'
import TemplateFooter from './TemplateFooter'

export default function EvershedPreview(props) {
    const {
        selectedStories,
        primaryColor,
        secondaryColor,
        title,
        selectedLogo,
        newsBodySize,
        includeSection,
        includeBusiness,
    } = props

    return (
        <Grid container direction="column" style={{ fontFamily: 'Arial' }}>
            <Grid item>
                <table>
                    <tr>
                        <td style={{ width: '670px', height: '210px' }}>
                            <img
                                src={selectedLogo}
                                alt="image"
                                style={{ width: '100%', height: '100%', objectFit: '100% 100%' }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td style={{ background: primaryColor }}>
                            <h2 style={{ textAlign: 'end', color: 'white', paddingRight: '10px', marginTop: '2px' }}>
                                {title}
                            </h2>
                        </td>
                    </tr>
                    <tr>
                        <td />
                    </tr>
                </table>
            </Grid>
            <NewsBody
                newsBodySize={newsBodySize}
                selectedStories={selectedStories}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                includeSection={includeSection}
                includeBusiness={includeBusiness}
            />
            <TemplateFooter />
        </Grid>
    )
}
