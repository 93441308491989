import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalBody, Input, ModalFooter, ModalHeader } from 'reactstrap'
import { Select, Chip, MenuItem, Grid } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import AddSharpIcon from '@mui/icons-material/AddSharp'
import ClearSharpIcon from '@mui/icons-material/ClearSharp'

export default function NewsSection(props) {
    const { selectedNews, setNewsCategory, categorizedNews } = props
    const [openModal, setOpenModal] = useState(false)
    const [temporarySectionData, setTemporaryData] = useState([])
    const [consolidatedData, setConsolidatedData] = useState([])

    useEffect(() => {
        setConsolidatedData(categorizedNews)
    })

    useEffect(() => {
        if (openModal) {
            consolidatedData.length
                ? setTemporaryData([...consolidatedData])
                : temporarySectionData.length
                ? setOpenModal(true)
                : addNewSection()
        }
    }, [openModal])

    const addNewSection = () => {
        const newSection = {
            title: '',
            news: [],
        }
        setTemporaryData([...temporarySectionData, newSection])
    }

    const removeSection = index => {
        const allData = [...temporarySectionData]
        allData.splice(index, 1)
        setTemporaryData(allData)
    }

    const changeNews = (index, event) => {
        const newsData = [...temporarySectionData]
        newsData[index]['news'] = event.target.value
        setTemporaryData(newsData)
    }

    const changeTitle = (index, event) => {
        const newsData = [...temporarySectionData]
        newsData[index]['title'] = event.target.value
        setTemporaryData(newsData)
    }

    const handleDelete = (index, item) => {
        const newsData = [...temporarySectionData]
        newsData[index]['news'] = newsData[index]['news'].filter(function(element) {
            return item !== element
        })
        setTemporaryData(newsData)
    }

    const revertChanges = () => {
        setTemporaryData([...consolidatedData])
        setOpenModal(false)
    }

    const confirmChanges = async () => {
        setNewsCategory(temporarySectionData)
        setConsolidatedData(temporarySectionData)
        setOpenModal(false)
    }

    return (
        <div>
            <Button
                style={{
                    border: 'solid 1px',
                    display: window.location.pathname === '/app/newsletter-template/' ? 'none' : '',
                }}
                onClick={() => setOpenModal(true)}
            >
                Choose Sections
            </Button>

            <Modal
                isOpen={openModal}
                toggle={() => setOpenModal(!openModal)}
                backdrop="static"
                size="xl"
                style={{ width: '100%' }}
            >
                <ModalHeader>
                    <h3>
                        <strong>Choose Section Names and News Items</strong>
                    </h3>
                </ModalHeader>
                <ModalBody>
                    <div className="container">
                        <div className="col-md-12">
                            <table style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th className="col-md-4">Section Title</th>
                                        <th className="col-md-7">Choosen News Items</th>
                                        <th className="col-md-1" style={{ textAlign: 'center' }}>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {temporarySectionData.map((data, index) => {
                                        let { title, news } = data
                                        return (
                                            <tr key={index}>
                                                <td style={{ padding: '15px', width: '30%' }}>
                                                    <Input
                                                        type="textarea"
                                                        bsSize="lg"
                                                        value={title}
                                                        key={index}
                                                        style={{
                                                            border: 'thin solid #e3e4e8',
                                                            width: '100%',
                                                            height: 'auto',
                                                        }}
                                                        onChange={event => changeTitle(index, event)}
                                                    />
                                                </td>
                                                <td style={{ padding: '15px', width: '60%' }}>
                                                    <Select
                                                        multiple
                                                        key={index}
                                                        variant="standard"
                                                        onChange={event => changeNews(index, event)}
                                                        value={news}
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        style={{ minWidth: '100%' }}
                                                        renderValue={selected => (
                                                            <Grid
                                                                container
                                                                direction="column"
                                                                justifyContent="space-between"
                                                                style={{ paddingRight: '1rem' }}
                                                            >
                                                                {selected.map(item => (
                                                                    <Grid item key={item.id} style={{ width: '600px' }}>
                                                                        <Chip
                                                                            clickable
                                                                            key={item.id}
                                                                            label={item.title}
                                                                            deleteIcon={<CancelIcon />}
                                                                            onDelete={e => handleDelete(index, item)}
                                                                            onMouseDown={event =>
                                                                                event.stopPropagation()
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                ))}
                                                            </Grid>
                                                        )}
                                                    >
                                                        {selectedNews
                                                            .filter(
                                                                element =>
                                                                    !temporarySectionData
                                                                        .flatMap(e => e.news)
                                                                        .includes(element)
                                                            )
                                                            .map(item => (
                                                                <MenuItem key={item.id} value={item}>
                                                                    {item.title}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </td>
                                                <td style={{ paddingLeft: '20px', textAlign: 'center', width: '10%' }}>
                                                    <Button
                                                        style={{ color: 'red' }}
                                                        outline
                                                        color="danger"
                                                        onClick={() => removeSection(index)}
                                                    >
                                                        {' '}
                                                        <ClearSharpIcon />{' '}
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td />
                                        <td />
                                        <td style={{ paddingLeft: '20px', textAlign: 'center' }}>
                                            <Button
                                                style={{ color: 'black' }}
                                                outline
                                                color="success"
                                                onClick={addNewSection}
                                            >
                                                <AddSharpIcon />{' '}
                                            </Button>{' '}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => revertChanges()}>Cancel</Button>{' '}
                    <Button onClick={() => confirmChanges()}>Confirm</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
