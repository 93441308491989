import React from 'react'

export default function TemplateFooter() {
    return (
        <table cellSpacing="0" cellPadding="0">
            <tbody>
                <tr>
                    <td className="mailModal__footerUpperContainer">
                        <p>
                            Have questions or feedback? Email us at mailto:support@kaitongo.com You’re receiving this
                            email because you’re subscribed to the Kaitongo digest. You can update your preferences or
                            unsubscribe from this list
                        </p>
                    </td>
                </tr>
                <tr>
                    <td className="mailModal__footerLogos">
                        <img
                            src="https://kaitongoprod.nyc3.digitaloceanspaces.com/kaitongoprod/static/linkedin2.png"
                            alt="LinkedIn"
                            style={{ marginRight: '32px' }}
                        />
                        <img
                            src="https://kaitongoprod.nyc3.digitaloceanspaces.com/kaitongoprod/static/kaitongo-k.png"
                            alt="Kaitongo Logo"
                        />
                    </td>
                </tr>
                <tr>
                    <td className="mailModal__footerLowerContainer">
                        <p>
                            Kaitongo: Actionable Intelligence for Law Firms See more sector insights on app.kaitongo.com
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}
