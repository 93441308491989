import React, { PureComponent } from 'react'
import styled from 'styled-components/macro'
import Header from '../../components/Header/Header'

class SaasScreen extends PureComponent {
    render() {
        return (
            <Wrapper>
                <Header />
                <div className="static-container">
                    <div className="main-title">
                        <h1>Product License Agreement And End User License Agreement</h1>
                    </div>

                    <div
                        className="static-content"
                        style={{ background: 'white', fontFamily: 'sans-serif', textAlign: 'justify' }}
                    >
                        <p style={{ textAlign: 'end' }}>
                            <b>Last Updated:</b> October 15, 2023
                        </p>
                        <h1 style={{ paddingTop: '10px' }}>Software as a Service Agreement </h1>
                        <p>
                            Effective upon your access and use of the Kaitongo Platform (as defined below), you or the
                            organization you represent (“Client”) agree with Kaitongo Inc. (“Kaitongo”) that you have
                            read, understood and accepted all the terms and conditions set forth below and that Client
                            agrees to be legally bound by them as of the first date of Client’s access and use of the
                            Kaitongo Platform (the “Effective Date”).
                        </p>
                        <p>
                            This Agreement, together with the applicable Terms of Service, and Order Form(s) sets forth
                            the terms under which Kaitongo will make its Platform and Services available to Client. This
                            Agreement includes the terms set forth in any applicable statements of work or Order Forms
                            that reference this Agreement as well as the Kaitongo Terms of Service.
                        </p>
                        <h1>Section 1. DEFINITIONS</h1>
                        <p>All capitalized terms used herein shall have the meanings set forth in this Section 1:</p>
                        <p>
                            “Change Request” means a requested change to the Kaitongo Platform, out of scope of the
                            services identified in this Agreement, made by Client to Kaitongo.
                        </p>
                        <p>
                            “Client Data” means collectively, all data, information and other materials (such as user
                            profiles, configurations, preferences, registration data, and logs) furnished by or on
                            behalf of Client including by Client Users.
                        </p>
                        <p>
                            “Client Provided Data” means all data collected and/or provided by Client (including through
                            Client’s website or CRM).
                        </p>
                        <p>
                            “Client Customers” means customers which purchase or consume Client’s products and services.
                        </p>
                        <p>
                            “Client Users” means employees, contractors, consultants, suppliers, agents, auditors, legal
                            counsel, and other representatives of Client who obtain and use provided log-in credentials
                            to access the Kaitongo Platform.
                        </p>
                        <p>
                            “Fees” means costs payable by Client for the Services in accordance with the fee schedule
                            set out in Order Form.
                        </p>
                        <p>
                            “Intellectual Property Rights” mean any and all intellectual property rights including
                            copyrights, patent rights, trademark rights and any other intellectual property right
                            anywhere in the world, whether registered or unregistered, and including all applications
                            for, and renewals or extensions of, such rights, and all similar or equivalent rights or
                            forms of protection;
                        </p>
                        <p>
                            “Kaitongo Platform” means the proprietary Software as a Service Platform offered by Kaitongo
                            through which Client receives market intelligence about Client Customers and generates
                            outreach content such as emails or LinkedIn/X posts in order to communicate credibly and
                            knowledgeably with Client Customers.
                        </p>
                        <p>
                            “Services” means all use of the Kaitongo Platform as described herein or as described in any
                            Order Form.
                        </p>
                        <p>
                            “Order Form” means the form describing the Services Client is purchasing from Kaitongo and
                            all associated fees due and payable pursuant to this Agreement.
                        </p>
                        <h1>Section 2. SCOPE</h1>
                        <b>2.1 Scope of Services.</b>
                        <p>
                            Subject to Client’s compliance with the terms and conditions of this Agreement, Kaitongo
                            will grant Client a non-exclusive, non-transferable license to the Services and the Kaitongo
                            Platform during the Term, accessible by Client at{' '}
                            <a href="https://insights.kaitongo.com">https://insights.kaitongo.com</a> or through the
                            installation, configuration, and use of the Kaitongo integration API or one of the supported
                            integrations. Kaitongo may, from time to time and in its discretion, update and modify the
                            Kaitongo Platform during the Term provided that said updates and modifications do not result
                            in materially diminished functionality to Client.
                        </p>
                        <b>2.2 Restrictions on Use.</b>
                        <p>Client agrees that it shall not permit or enable others to:</p>
                        <p>
                            (a) alter, modify, adapt, create derivative works based on, translate, deface, or reverse
                            engineer, decompile or disassemble the Kaitongo Platform or any of the Services;
                        </p>
                        <p>
                            (b) use any aspect of the Services to create, market or distribute any product or service
                            that is competitive with the Services or illegal;
                        </p>
                        <p>
                            {' '}
                            (c) transfer, sell, lease, license, sublicense, distribute, disclose or make available or
                            permit use of or access to the Kaitongo Platform or any of the Services to any other person;
                        </p>
                        <p>
                            {' '}
                            (d) use any aspect of the Service in a manner that violates the Kaitongo Terms of Service
                            located here: https://insights.kaitongo.com/terms;
                        </p>
                        <b>2.3 Data Ownership</b>
                        <p>
                            Client shall retain all ownership and Intellectual Property Rights in and to Client Data. As
                            between Client and Kaitongo, Client shall retain all ownership of Client Provided Data.
                            During the Term, Client grants to Kaitongo a nonexclusive, worldwide, royalty-free,
                            irrevocable, sublicensable, and fully paid-up right to access, collect, use, process, store,
                            disclose and transmit Client Data and Client Customer Data to: (i) provide the Services and
                            Kaitongo Platform; (ii) improve and enhance the Services, the Kaitongo Platform and its
                            other offerings; and (iii) produce data, information or other materials that cannot be
                            identified as relating to a particular individual or company (such data, information and
                            materials, the “Anonymized Client Data”). Kaitongo may use, process, store, disclose and
                            transmit the Anonymized Client Data for any purpose and without restriction, accounting or
                            obligation to Client of any kind provided that said Anonymized Client Data or Client
                            Customer Data cannot be associated in any way with Client or Client’s customers.
                        </p>
                        <b>2.4 Terms of Service for Users.</b>
                        <p>
                            All Client Users must create user accounts to access the Kaitongo Platform and accept
                            Kaitongo’s terms of service and privacy policy to receive log-in credentials. Client is
                            responsible for its Client User’s compliance with the then-current version of the Terms of
                            Service located at{' '}
                            <a href="https://insights.kaitongo.com/terms">https://insights.kaitongo.com/terms</a> and
                            all Client Users must accept the Terms of Service as a condition of accessing the Kaitongo
                            Platform.
                        </p>
                        <h1>Section 3. TERM; TERMINATION</h1>
                        <b>3.1 Term.</b>
                        <p>
                            This Agreement will commence on the Effective Date and continue to be in effect for the 10
                            Dundas St. E, Ste. 600, Toronto 416.544.1443 www.kaitongo.com initial period set out in an
                            Order Form (the “Initial Term”). The Initial Term will automatically renew for successive
                            one year terms (each a “Renewal Term” and together with the Initial Term, the “Term”) unless
                            either Party provides the other Party with written notice of its intention not to renew at
                            least 60 days prior to the end of the then current Term.
                        </p>
                        <b>3.2 Termination for Cause.</b>
                        <p>
                            In addition to any other available rights and remedies, either Party may immediately
                            terminate this Agreement in the event of material breach by the other Party of its
                            obligations hereunder, including any of the representations, warranties and covenants
                            hereunder, if such breach is not cured within thirty (30) days of written notification by
                            the non-breaching Party of such breach. In addition, either Party may terminate this
                            Agreement immediately upon written notice to the other if such other Party is subject to
                            proceedings in bankruptcy or insolvency, voluntarily or involuntarily, if a receiver is
                            appointed with or without the other Party’s consent or if the other Party becomes insolvent
                            and cannot pay its debts when they are due.
                        </p>
                        <b>3.3 Effect of Termination.</b>
                        <p>
                            Upon any expiration or termination of this Agreement, Client will have a fifteen (15) day
                            period to access its account and download or export Client Data. After the fifteen (15) day
                            period, Kaitongo will convert Client’s account to inactive status and Client Data will no
                            longer be available. After termination, Kaitongo will maintain all Client Data for a period
                            of not less than thirty (30) days after which time Client Data may be deleted unless
                            prohibited by applicable law. Upon termination of this Agreement, (i) the rights granted by
                            one Party to the other will immediately cease and Client shall have no further right to
                            access the Kaitongo Platform; (ii) Kaitongo shall issue Client an invoice for all accrued
                            Fees which shall be immediately due upon Client’s receipt of the final invoice; (iii) Client
                            will delete or return any software provided by Kaitongo in connection with the Services; and
                            (iv) each Party will return or destroy all Confidential Information of the other Party
                            (except as provided for in this Agreement).
                        </p>
                        <h1>Section 4. CHANGES TO THE SERVICES</h1>
                        <b>4.1 Change Requests.</b>
                        <p>
                            Any requested changes to an Order Form will not be effective until both parties have signed
                            an amendment changing the Services being provided.
                        </p>
                        <h1>Section 5. FEES AND TAXES</h1>
                        <b>5.1 Fees.</b>
                        <p>
                            Client will pay Kaitongo all Fees described in the applicable Order Form. Failure by Client
                            to pay the undisputed Fees pursuant to Section 5.2 shall constitute a material breach of
                            this Agreement and may result in the termination of this Agreement or suspension of access
                            to the Service. Unless otherwise noted in the Order Form, all Fees shall be paid in advance
                            in U.S. Dollars and are non-cancelable and non-refundable. The annual subscription fees for
                            any Renewal Term will be subject to a 10% increase over the fees in the immediately
                            preceding period for the same Scope of Services.
                        </p>
                        <b>5.2 Invoices.</b>
                        <p>
                            Kaitongo will invoice Client subscription Fees on an annual basis on or about each
                            anniversary of the effective date of this Agreement, and each invoice will categorize
                            amounts due accordingly. Unless otherwise expressly stipulated in an invoice, on an Order
                            Form or Schedule “A”, Client will pay: (i) all invoiced subscription Fees within 7 calendar
                            days of the invoice date. Any discrepancies regarding the invoiced amounts must be reported
                            to Kaitongo within 5 calendar days of invoice or they will be deemed correct.
                        </p>
                        <b>5.3 Taxes.</b>
                        <p>
                            {' '}
                            Each Party shall be liable for its own taxes based upon net income, capital or gross
                            receipts. All Fees are exclusive of all applicable taxes such as national, state or local
                            sales, use, value added or other taxes, customs duties, or similar tariffs and fees.
                        </p>
                        <h1>Section 6. REPRESENTATIONS, WARRANTIES AND COVENANTS</h1>
                        <b>6.1 Mutual.</b>
                        <p>
                            Each Party represents and warrants that (i) the execution and delivery of this Agreement
                            will not breach any contractual duty it has to any third party; and (ii) it has and will
                            have full and sufficient right, title or authority to enter into and perform its obligations
                            under this Agreement; and (iii) they will perform their obligations under this Agreement in
                            full compliance with applicable law.
                        </p>
                        <b>6.2 DISCLAIMER.</b>
                        <p>
                            {' '}
                            EXCEPT AS EXPRESSLY STATED IN THIS AGREEMENT, KAITONGO MAKES NO 10 Dundas St. E, Ste. 600,
                            Toronto 416.544.1443 www.kaitongo.com WARRANTIES, REPRESENTATIONS OR CONDITIONS OF ANY
                            NATURE, EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE IN RESPECT OF THE SERVICES, INCLUDING BUT
                            NOT LIMITED TO ANY WARRANTIES, REPRESENTATIONS OR CONDITIONS RESPECTING MERCHANTABILITY OR
                            FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT OR ARISING BY STATUTE, OTHERWISE IN LAW
                            OR FROM A COURSE OF DEALING OR USAGE OF TRADE. EXCEPT AS EXPRESSLY STATED IN THIS AGREEMENT,
                            KAITONGO DOES NOT WARRANT THAT THE PROVISION OF THE SERVICES TO CLIENT WILL BE UNINTERRUPTED
                            OR ERROR-FREE.
                        </p>
                        <h1>Section 7. CONFIDENTIALITY</h1>
                        <b>7.1 Definitions.</b>
                        <p>
                            For the purposes of this Agreement, a Party receiving Confidential Information (as defined
                            below) will be the “Recipient”, the Party disclosing such information will be the
                            “Discloser” and “Confidential Information” of Discloser means any and all information of
                            Discloser or any of its licensors that has or will come into the possession or knowledge of
                            the Recipient in connection with or as a result of entering into this Agreement, including
                            information concerning the Discloser’s customers, suppliers, technology or business, and
                            where Discloser is Client includes Client Data but excludes Anonymized Client Data; provided
                            that Discloser’s Confidential Information does not include: (i) information already known or
                            independently developed by Recipient without access to Discloser’s Confidential Information;
                            (ii) information that is publicly available through no wrongful act of Recipient; or (iii)
                            information received by Recipient from a third party who was free to disclose it without
                            confidentiality obligations.
                        </p>
                        <b>7.2 Protection of Confidential Information.</b>
                        <p>
                            Receiving Party shall: (a) hold all Confidential Information of Disclosing Party in
                            confidence; (b) not use or copy such Confidential Information except for purposes of and as
                            permitted by this Agreement; (c) not disclose such Confidential Information or the
                            provisions of this Agreement except to its directors, officers, employees or agents who have
                            a need to know such information for purposes of and as permitted by this Agreement. Each
                            Party agrees to use the same means it uses to protect its own confidential information of a
                            like nature, but in any event, not less than reasonable means, taking into consideration the
                            sensitivity and nature of the Confidential Information in question, to prevent the
                            disclosure of Disclosing Party’s Confidential Information to third parties. Receiving Party
                            shall be relieved from its obligations under this Section 7.2: (i) to the extent the
                            Disclosing Party gives its prior written 10 Dundas St. E, Ste. 600, Toronto 416.544.1443
                            www.kaitongo.com consent; or (ii) to the extent necessary to comply with applicable laws or
                            regulations, or judicial or governmental order, provided that such Receiving Party uses
                            commercially reasonable efforts to provide prior written notice of such disclosure (to the
                            extent such disclosure is not required to be made immediately) to Disclosing Party to afford
                            Disclosing Party the opportunity to seek a protective order. Receiving Party will promptly
                            notify Disclosing Party in writing of any unpermitted disclosure or unpermitted use of any
                            Confidential Information of Disclosing Party of which Receiving Party becomes aware.
                        </p>
                        <b>7.3 Remedies.</b>
                        <p>
                            {' '}
                            Receiving Party agrees that Disclosing Party may be irreparably injured by a breach of this
                            Section 7 and that Disclosing Party may be entitled to seek equitable relief, including a
                            restraining order, injunctive relief, specific performance and any other relief that may be
                            available from any appropriate court to prevent breaches of this Agreement and to
                            specifically enforce the terms and provisions hereof. Such remedies shall not be deemed to
                            be the exclusive remedies for a breach of this Agreement but shall be in addition to all
                            other remedies available at law or in equity
                        </p>
                        <h1>Section 8. KAITONGO’S INTELLECTUAL PROPERTY RIGHTS</h1>
                        <b>8.1 Ownership.</b>
                        <p>
                            Kaitongo or its licensors retain all ownership and intellectual property rights in and to:
                            (i) the Services and the Kaitongo Platform; (ii) anything developed or delivered by or on
                            behalf of Kaitongo under this Agreement; and (iii) any modifications to the foregoing (i)
                            and (ii). Other than as specifically licensed herein, Client shall obtain no rights to the
                            Kaitongo Platform or the Services. If Client provides Kaitongo with any feedback during the
                            Term, Kaitongo shall have a perpetual right to use and incorporate same at its discretion
                            without any obligation or accounting to the Client. All rights not expressly granted by
                            Kaitongo to Client under this Agreement are reserved
                        </p>
                        <h1>Section 9. INDEMNITY AND LIMITATION OF LIABILITY</h1>
                        <b>9.1 By Client.</b>
                        <p>
                            Client agrees to defend, fully indemnify and hold harmless Kaitongo from and against any and
                            all claims, demands, suits, actions, causes of action and/or liability, of any kind
                            whatsoever (each a “Claim”), for damages, losses, costs and/or expenses (including legal 10
                            Dundas St. E, Ste. 600, Toronto 416.544.1443 www.kaitongo.com fees and disbursements)
                            (“Losses”) arising out of or relating to: (i) Client Data, including but not limited to,
                            Client’s failure to comply with applicable laws with respect to the collection of Client
                            Data, including obtaining all necessary consents and permissions for the transfer of Client
                            Data to Kaitongo; (ii) Client’s breach of Sections 2.2 (Restrictions on Use); or (iii)
                            unauthorized use of Services or the Kaitongo Platform by Client or any Client User.
                        </p>
                        <b>9.2 By Kaitongo.</b>
                        <p>
                            (a) Kaitongo shall indemnify Client for any damages or expenses (but not for any loss of
                            profits) arising directly as a result of the Services or the Kaitongo Platform resulting in
                            an infringement of a third party’s Intellectual Property Rights (an “IP Claim”). Kaitongo’s
                            indemnity obligation will apply to any Losses that: (i) a court finally awards as a result
                            of any IP Claim; or (ii) are agreed to by Kaitongo in any settlement arising from any IP
                            Claim, in each case subject to this Section 10; provided, that Kaitongo shall have full
                            control over the defense of the IP Claim and (x) any settlement or resolution entered into
                            by Kaitongo shall not require an admission of liability or any payment by Client, (y) Client
                            has not made any admission against Kaitongo’s interests and has not agreed to any settlement
                            of any claim or demand without Kaitongo’s consent, and (z) Client shall cooperate with
                            Kaitongo in the defense of the claim, at Kaitongo’s expense.
                        </p>
                        <p>
                            (b) Notwithstanding anything herein to the contrary, Kaitongo will have no obligation or
                            liability to Client under this Section 9.2 if: (i) the IP Claim is based upon, arises out of
                            or is related to (a) the combination of any of the Services with any other software,
                            hardware or products not provided by Kaitongo; (b) the use of the Services for other than
                            their intended purpose; or (c) modifications, improvements and derivative works of Kaitongo
                            created by or on behalf of Client; (ii) Client is in material breach of this Agreement or
                            has failed to pay amounts due hereunder as set forth in Section 5; or (iii) Client fails to
                            notify Kaitongo of the IP Claim in a prompt manner and the delay results in prejudice to
                            Kaitongo.
                        </p>
                        <p>
                            (c) If any part of the Services become the subject of an IP Claim, Kaitongo may, at
                            Kaitongo’s option and expense, (i) procure for Client the right to continue using the
                            Services; (ii) replace or modify the Services with a non-infringing version of substantially
                            equivalent function and performance; or (iii) terminate this Agreement and refund to Client
                            on a pro rata basis any prepaid but unused subscription fees. The obligations of Kaitongo
                            set forth in this Section 9.2 shall constitute the sole and exclusive remedy of Client with
                            respect to any IP Claim.
                        </p>
                        <b>9.3 Cooperation.</b>
                        <p>
                            In connection with any Claim or action described in this Section 9, the Party seeking
                            indemnification will (i) give the indemnifying Party prompt written notice of such Claim or
                            action; (ii) cooperate with the indemnifying Party (at the indemnifying Party’s expense) in
                            connection with the defense and settlement of such Claim or action, and (iii) permit the
                            indemnifying Party to control the defense and settlement of such Claim or action; provided
                            that the indemnifying Party will not under any circumstances (a) settle such Claim or action
                            without the indemnified Party’s prior written consent (which will not be unreasonably
                            withheld or delayed), or (b) make an admission of liability on behalf of the indemnified
                            Party without the indemnified Party’s prior written consent and further provided that the
                            indemnified Party shall be entitled to participate (at its expense) in the defense and
                            settlement of such Claim or action.
                        </p>
                        <b>9.4 Limitation of Liability.</b>
                        <p>
                            (a) Neither Party will be liable for any indirect, special incidental, consequential,
                            punitive or exemplary damages, or lost profits including, without limitation, loss of
                            revenue, regardless of the form of action, whether in contract or in tort including
                            negligence, even if the Party knew or should have known of the possibility of such damages
                            and even if direct damages do not satisfy a remedy.
                        </p>
                        <p>
                            (b) Except as set forth in Section 9.4(c), neither Party’s liability for damages under this
                            Agreement (whether in contract or tort, including negligence, or otherwise) will in any
                            event exceed the aggregate amount paid by Client pursuant to this Agreement in the six (6)
                            month period preceding the event giving rise to the damages.
                        </p>
                        <p>
                            (c) The limitations provided for in Section 9.4(b) will not apply to Claims arising from:
                            (i) any failure of Client to pay any Fees or Rewards; or (ii) any breach of Section 2.2
                            (Restrictions on Use), or Section 7 (Confidentiality).
                        </p>
                        <h1>Section 10. DISPUTE RESOLUTION</h1>
                        <b>10.1 Arbitration.</b>
                        <p>
                            (a) Except for actions relating to Client’s failure to pay any invoiced Fee or Reward (which
                            shall be settled through the appropriate court system), the Parties will resolve any dispute
                            arising out of or relating to this Agreement as set forth in this Section 10.1.
                        </p>
                        <p>
                            (b) If a dispute occurs between the Parties so that one party is considering legal action
                            against the other Party, then notice shall be provided by the party to the other Party of
                            such dispute and the parties will endeavor in good faith to resolve same. In the event the
                            parties are unable to resolve the dispute within five (5) days of the receipt of such
                            notice, then such dispute shall be escalated to the immediate supervisors of such
                            representatives, who shall have an additional ten (10) days to resolve such dispute.
                        </p>
                        <p>
                            (c) If the dispute is not resolved pursuant to Section 10.1(b), the dispute will be referred
                            to and determined by arbitration in accordance with (i) the National Arbitration Rules of
                            the ADR Institute of Canada, Inc. (the “NADR Rules”) if the governing law of this Agreement
                            is Ontario and (ii) the Commercial Dispute Rules of JAMS/Endispute (“JAMS”) or American
                            Arbitration Association (“AAA”) under the Commercial Arbitration Rules and Supplementary
                            Procedures for Consumer Related Disputes (“CARASP”), at Kaitongo’s discretion, if the
                            governing law of this Agreement is in the United States. Either Party may commence the
                            arbitration. The site of the arbitration will be the same as the venue for the governing
                            law.
                        </p>
                        <p>
                            (d) The arbitration will be conducted in English before a single arbitrator. If the Parties
                            do not agree on the arbitrator within 15 days of the referral to arbitration, then the
                            arbitral tribunal will be appointed by the ADR Institute of Canada or pursuant to the
                            procedures set out in the CARASP. The arbitral tribunal will be conducted on an expedited
                            basis and will render its final award and the reasons for the award within 45 days of the
                            conclusion of the hearing, unless such time is reduced or extended by the tribunal after
                            giving the parties an opportunity to be heard. Any award or judgment on an award and any
                            award for interim relief may be entered in any court having jurisdiction and will be final
                            and binding on the parties and will not be subject to appeal. No Party is prohibited from
                            seeking interim, interlocutory, or expedited remedies (including the use of arbitration
                            rules providing for emergency measures of protection), in any forum having jurisdiction,
                            including remedies to preserve or protect trademarks, Confidential Information, copyrights,
                            or trade secrets or for extraordinary relief such as an injunction or eviction. In addition
                            to any other restriction on the tribunal in this Agreement, in no event will the arbitral
                            tribunal award, or have any jurisdiction to award, punitive or exemplary damages against any
                            Party. No arbitration award will have an effect of preclusion or collateral estoppel in any
                            other adjudication or arbitration.
                        </p>
                        <h1>Section 11. GENERAL PROVISIONS</h1>
                        <b>11.1 Third Party Software and Service.</b>
                        <p>
                            The Kaitongo Platform may provide links and access to third party software and services. The
                            use of such Third Party Software and Services are governed by the terms of the third party
                            provider and are binding on Client. Kaitongo does not provide any warranties, and has no
                            liability or obligation to Client, with respect to third party software and services. Copies
                            of the applicable license agreement between third party providers and Client are available
                            on the Kaitongo website or by contacting Kaitongo.
                        </p>
                        <b>11.2 Assignment.</b>
                        <p>
                            Client will not assign this Agreement or transfer any of its rights hereunder to any third
                            party without informing Kaitongo in writing and obtaining Kaitongo’s prior consent in
                            writing to such assignment. If Kaitongo in its reasonable discretion does not consent to the
                            assignment and/or transfer of rights in this Agreement, then Kaitongo may either, in its
                            sole discretion: (i) terminate this Agreement and refund Client Subscription Fee on
                            pro-rated basis; or (ii) mutually agree, with Client, on a revised Client Subscription Fee
                            for the remainder of the Term. This Agreement will inure to the benefit of and be binding
                            upon the Parties, their permitted successors and permitted assignees.
                        </p>
                        <b>11.3 Relationship of Parties.</b>
                        <p>
                            In all matters relating to this Agreement, Kaitongo and Client are independent contractors
                            and nothing will be construed to create any association, partnership, joint venture, or
                            relationship of agency or employment between Kaitongo and the Client.
                        </p>
                        <b>11.4 Publicity.</b>
                        <p>
                            Client authorizes Kaitongo to (i) include Client in any user or subscriber list; and (ii)
                            use Client’s name and/or trademarks for marketing and publicity on its website, in marketing
                            materials and/or in press releases
                        </p>
                        <b>11.5 Excusable Delays.</b>
                        <p>
                            Should Kaitongo incur any delay in the provision of the Services resulting from any errors,
                            defects or other problems contained in the information, materials and/or instructions 10
                            Dundas St. E, Ste. 600, Toronto 416.544.1443 www.kaitongo.com provided to it by Client,
                            Kaitongo shall be excused from performance during the period of such delay and Client shall
                            remain liable for any Fees incurred during such delay.
                        </p>
                        <b>11.6 Force Majeure.</b>
                        <p>
                            Except as relating to Client’s payment obligations, neither Party shall be liable to the
                            other for a failure or delay in the performance of any obligation under this Agreement if
                            such failure or delay is caused by an event beyond a Party’s control, including, but not
                            limited to, any fire, power failure, act of God, labour dispute or government measure or
                            other cause beyond such Party’s reasonable control (a “Force Majeure Event”), provided that
                            such Party gives prompt written notice of the Force Majeure Event to the other Party and
                            resumes performance of its obligations as soon as possible. Either Party may terminate this
                            Agreement without penalty if such delay due to a Force Majeure Event continues for a period
                            of ninety (90) days without cure.
                        </p>
                        <b>11.7 Survival.</b>
                        <p>
                            The following sections shall survive the expiration or termination of this Agreement in
                            addition to any other provision which by law or by its nature should survive: Section 3.3
                            (Effect of Termination); Section 5 (Fees and Taxes); Section 6.3 (Disclaimer); Section 7
                            (Confidentiality); Section 8 (Kaitongo’s Intellectual Property Rights); Section 9 (Indemnity
                            and Limitation of Liability); Section 10 (Dispute Resolution); and Section 11.8 (Governing
                            Law). Client’s obligations in respect of any Fees and Rewards owing shall also survive,
                            whether or not such Fees or Rewards have been invoiced by Kaitongo
                        </p>
                        <b>11.8 Governing Law.</b>
                        <p>
                            If Client is incorporated outside Canada, then this Agreement shall be governed by and
                            construed in accordance with the laws of the State of New York, excepting its choice of law
                            provisions, and the Parties hereby agree to irrevocably attorn to the exclusive jurisdiction
                            of the courts of the State of New York and the venue of Buffalo. If Client is incorporated
                            in Canada, then this Agreement shall be governed by and construed in accordance with the
                            laws of the Province of Ontario, excepting its choice of law provisions, and the Parties
                            hereby agree to irrevocably attorn to the exclusive jurisdiction of the courts of the
                            Province of Ontario and the venue of Toronto.
                        </p>
                        <b>11.9 Amendments, No Waiver.</b>
                        <p>
                            10 Dundas St. E, Ste. 600, Toronto 416.544.1443 www.kaitongo.com We may modify any part or
                            all of the Agreement by posting a revised version at{' '}
                            <a href="https://insights.kaitongo.com/terms">https://insights.kaitongo.com/terms</a> . The
                            revised version will become effective and binding the next business day after it is posted.
                            We will provide you notice of this revision by email or in-app notification.{' '}
                        </p>
                        <p>
                            If you do not agree with a modification to the Agreement, you must notify us in writing
                            within thirty (30) days after we send notice of the revision. If you give us this notice,
                            then your subscription will continue to be governed by the terms and conditions of the
                            Agreement prior to modification until your next renewal date, after which the current terms
                            posted at{' '}
                            <a href="https://insights.kaitongo.com/terms">https://insights.kaitongo.com/terms</a> will
                            apply. However, if we can no longer reasonably provide the subscription to you under the
                            terms prior to modification (for example, if the modifications are required by law or result
                            from general product changes), then the Agreement and/or affected Subscription Services will
                            terminate upon our notice to you and we will promptly refund any prepaid but unused fees
                            covering use of the Subscription Service after termination in accordance with the ‘Effect of
                            Termination or Expiration’ provision of this Agreement.
                        </p>
                        <p>
                            No delay in exercising any right or remedy or failure to object will be a waiver of such
                            right or remedy or any other right or remedy. A waiver on one occasion will not be a waiver
                            of any right or remedy on any future occasion.
                        </p>
                        <b>11.10 Notices.</b>
                        <p>All notices under the terms of this Agreement shall be bot</p>
                        <p>(i) sent by email to kaitongo@kaitongo.com and</p>
                        <p>
                            (ii) given in writing and sent by registered mail or email or shall be delivered by hand to
                            the following addresses:
                        </p>
                        <p>[Account.Name]</p>
                        <p>
                            <b>Address:</b> As set out on the Order Form{' '}
                        </p>
                        <p>Kaitongo, Inc.</p>
                        <p>
                            <b>Address:</b>
                        </p>
                        <p>10 Dundas St. East, Ste. 600</p>
                        <p>Toronto, ON, M7A 0B2</p>
                        <p>
                            <b>Attention:</b> Legal Department
                        </p>
                        <p>
                            <b>Email:</b> kaitongo@kaitongo.com
                        </p>
                    </div>
                </div>
            </Wrapper>
        )
    }
}
export default SaasScreen
const Wrapper = styled.div`
    a {
        text-decoration: underline;
        font-weight: bold;
    }

    h1 {
        color: black;
    }

    h3 {
        margin-bottom: 2rem;
    }

    section {
        margin-bottom: 2rem;
    }

    hr {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    th,
    td {
        border: 1px solid #ccc;
        padding: 8px;
        text-align: left;
    }

    @media (max-width: 575px) {
        .list-style-upper-alpha {
        }
    }
`
