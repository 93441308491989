import React from 'react'

export default function Resizer(props) {
    const { slidervalue, setStyleOn } = props

    return (
        <input
            type="range"
            style={{
                width: '100px',
                borderRadius: '5px',
            }}
            min="80"
            max="270"
            step="2"
            value={slidervalue}
            onInput={setStyleOn}
        />
    )
}
