import React from 'react'
import { Modal, ModalBody } from 'reactstrap'

export default function DeleteNews(props) {
    const {
        deleteModalOpen,
        setDeleteModalOpen,
        selectedStoriesLength,
        handleBatchDeleteStories,
        savedQueryDelete,
        queryName,
    } = props
    return (
        <div>
            {' '}
            <Modal
                isOpen={deleteModalOpen}
                className="newsCard20__deleteConfirmModalContainer"
                size={savedQueryDelete ? '' : 'lg'}
            >
                <ModalBody>
                    <h1 style={{ textAlign: 'right', cursor: 'pointer' }} onClick={() => setDeleteModalOpen(false)}>
                        X
                    </h1>
                    <div>
                        {!savedQueryDelete ? (
                            <h3>Are you sure you want to delete {selectedStoriesLength} item(s)</h3>
                        ) : (
                            <h3>Are you sure you want to delete {queryName}</h3>
                        )}
                        <div className="newsCard20__YesNoButtons">
                            <div className="newsCard20__NoButton" onClick={() => setDeleteModalOpen(false)}>
                                <p>No</p>
                            </div>
                            <div className="newsCard20__YesButton" onClick={handleBatchDeleteStories}>
                                <p>Yes</p>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}
