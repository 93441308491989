export const LOGIN_USER = 'LOGIN_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const SELECT_PACKAGE = 'SELECT_PACKAGE'

export const LOAD_INDUSTRIES = 'LOAD_INDUSTRIES'
export const LOAD_COMPANIES = 'LOAD_COMPANIES'
export const UPDATE_INDUSTRY_TYPE_AND_ID = 'update_industry_type_and_id'
export const LOAD_ALL_INDUSTRIES = 'LOAD_ALL_INDUSTRIES'
export const LOAD_INDUSTRY_INFO = 'LOAD_INDUSTRY_INFO'

export const UPDATE_SAVED_OPPORTUNITIES = 'UPDATE_SAVED_OPPORTUNITIES'

export const SET_LOADING = 'SET_LOADING'

export const UPDATE_REGION_FILTER = 'UPDATE_REGION_FILTER'

export const LOAD_ANALYST_ADMIN_DATA = 'LOAD_ANALYST_ADMIN_DATA'
