import React from 'react'
import BaseScreen from '../../BaseScreen/BaseScreen'
import './UserDashboard.css'

export default function UserDashboard() {
    return (
        <BaseScreen>
            <div className="main_section p-2">
                <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                    <div className="left_side_container" style={{ width: '40%' }}>
                        <div className="left_upper p-3">
                            <div className="d-flex align-items-center">
                                <div>
                                    <div className="profile-icon" title="Username">
                                        <span>K</span>
                                    </div>
                                </div>
                                <div>
                                    <p>
                                        <b>Hello Kaitongo!</b>
                                    </p>
                                    <p>
                                        <b>How can I help you today?</b>
                                    </p>
                                </div>
                            </div>
                            <input
                                placeholder="Ask me anything..."
                                style={{
                                    border: '1px solid lightgray',
                                    borderRadius: '25px',
                                    display: 'flex',
                                    margin: 'auto',
                                    width: '90%',
                                    marginTop: '20px',
                                    outline: 'none',
                                    border: '1px solid lightgray',
                                }}
                            />
                        </div>
                        <div
                            className="d-flex align-items-center justify-content-between left_lower text-center p-2"
                            style={{ width: '100%', marginTop: '20px' }}
                        >
                            <div className="newsletter" style={{ width: '33.33%' }}>
                                <div style={{ fontSize: '60px', textAlign: 'center', color: 'lightgray' }}>0</div>
                                <div>Newsletter</div>
                            </div>
                            <div style={{ borderRight: '2px solid lightgray', height: '100px', width: '2px' }} />
                            <div className="alert-section" style={{ width: '33.33%' }}>
                                <div style={{ fontSize: '60px', textAlign: 'center', color: 'lightgray' }}>0</div>
                                <div>Report</div>
                            </div>
                            <div style={{ borderRight: '2px solid lightgray', height: '100px', width: '2px' }} />
                            <div className="reports-count" style={{ width: '33.33%' }}>
                                <div style={{ fontSize: '60px', textAlign: 'center', color: 'lightgray' }}>0</div>
                                <div>Alert</div>
                            </div>
                        </div>
                    </div>

                    <div className="right_side_container" style={{ marginLeft: '10px', width: '58%' }}>
                        {/* <div className="background-image" /> */}
                        {/* Your content for the right side container goes here */}
                        <img
                            src="https://t4.ftcdn.net/jpg/01/84/59/13/240_F_184591349_bAIk4nZxjbX1fk3yUSBkb34RFJPH51mX.jpg"
                            alt="Placeholder Image"
                            style={{ width: '100%', height: '288px', objectFit: 'cover', borderRadius: '5px' }}
                        />
                    </div>
                </div>
            </div>
        </BaseScreen>
    )
}
