import React from 'react'
import EmailPreview from './EmailPreview'
import CompanyPreview from './CompanyNewsletterPreview'
import BlakesPreview from './BlakesPreview'
import BLGPreview from './BLGPreview'
import EvershedPreview from './EvershedsPreview'
import BLGManualPreview from './BLGManualPreview'
import WinstonPreview from './WinstonPreview'
import F3LawPreview from './F3LawPreview'

export default function RenderTemplate(props) {
    const {
        selectedTemplate,
        selectedStories,
        title,
        introText,
        primaryColor,
        secondaryColor,
        selectedLogo,
        selectedBg,
        secondaryLogo,
        setSize,
        justPreview,
        newsBodySize,
        includeSection,
        includeBusiness,
        hyperlinkSource,
        hyperlinkTitle,
        subjectLine,
    } = props

    return (
        <>
            {selectedTemplate.html_name === 'newsletter.html' && (
                <EmailPreview
                    selectedStories={selectedStories}
                    introText={introText}
                    primaryColor={primaryColor}
                    secondaryColor={secondaryColor}
                    selectedLogo={selectedLogo}
                    setSize={setSize}
                    justPreview={justPreview}
                    includeSection={includeSection}
                    includeBusiness={includeBusiness}
                    hyperlinkSource={hyperlinkSource}
                    hyperlinkTitle={hyperlinkTitle}
                />
            )}
            {selectedTemplate.html_name.includes('blakesTemplate') && (
                <BlakesPreview
                    selectedStories={selectedStories}
                    primaryColor={primaryColor}
                    secondaryColor={secondaryColor}
                    title={title}
                    selectedLogo={selectedLogo}
                    selectedBg={selectedTemplate.has_background ? selectedBg : null}
                    setSize={setSize}
                    justPreview={justPreview}
                    newsBodySize={newsBodySize}
                    includeSection={includeSection}
                    includeBusiness={includeBusiness}
                    hyperlinkSource={hyperlinkSource}
                    hyperlinkTitle={hyperlinkTitle}
                />
            )}
            {selectedTemplate.html_name === 'blgtemplate.html' && (
                <BLGPreview
                    selectedStories={selectedStories}
                    primaryColor={primaryColor}
                    secondaryColor={secondaryColor}
                    title={title}
                    primaryLogo={selectedLogo}
                    secondaryLogo={secondaryLogo}
                    selectedBg={selectedBg}
                    setSize={setSize}
                    justPreview={justPreview}
                    newsBodySize={newsBodySize}
                    includeSection={includeSection}
                    includeBusiness={includeBusiness}
                    hyperlinkSource={hyperlinkSource}
                    hyperlinkTitle={hyperlinkTitle}
                />
            )}
            {selectedTemplate.html_name === 'company_newsletter.html' && (
                <CompanyPreview
                    selectedStories={selectedStories}
                    introText={introText}
                    primaryColor={primaryColor}
                    secondaryColor={secondaryColor}
                    selectedLogo={selectedLogo}
                    setSize={setSize}
                    justPreview={justPreview}
                    includeSection={includeSection}
                    includeBusiness={includeBusiness}
                    hyperlinkSource={hyperlinkSource}
                    hyperlinkTitle={hyperlinkTitle}
                    subjectLine={subjectLine}
                />
            )}
            {selectedTemplate.html_name === 'evershedsTemplate.html' && (
                <EvershedPreview
                    selectedStories={selectedStories}
                    title={title}
                    primaryColor={primaryColor}
                    secondaryColor={secondaryColor}
                    selectedLogo={selectedLogo}
                    justPreview={justPreview}
                    newsBodySize={newsBodySize}
                    includeSection={includeSection}
                    includeBusiness={includeBusiness}
                    hyperlinkSource={hyperlinkSource}
                    hyperlinkTitle={hyperlinkTitle}
                />
            )}
            {selectedTemplate.html_name === 'blgtemplate2.html' && (
                <BLGManualPreview
                    selectedStories={selectedStories}
                    title={title}
                    primaryLogo={selectedLogo}
                    secondaryLogo={secondaryLogo}
                    selectedBg={selectedBg}
                    setSize={setSize}
                    introText={introText}
                    justPreview={justPreview}
                    includeSection={includeSection}
                    includeBusiness={includeBusiness}
                    hyperlinkSource={hyperlinkSource}
                    hyperlinkTitle={hyperlinkTitle}
                />
            )}
            {selectedTemplate.html_name === 'winston_static.html' && (
                <WinstonPreview
                    selectedStories={selectedStories}
                    introText={introText}
                    primaryColor={primaryColor}
                    secondaryColor={secondaryColor}
                    selectedLogo={selectedLogo}
                    setSize={setSize}
                    justPreview={justPreview}
                    newsBodySize={newsBodySize}
                    includeSection={includeSection}
                    includeBusiness={includeBusiness}
                    hyperlinkSource={hyperlinkSource}
                    hyperlinkTitle={hyperlinkTitle}
                />
            )}
            {selectedTemplate.html_name === 'f3law_newsletter.html' && (
                <F3LawPreview
                    selectedStories={selectedStories}
                    introText={introText}
                    primaryColor={primaryColor}
                    secondaryColor={secondaryColor}
                    selectedLogo={selectedLogo}
                    setSize={setSize}
                    justPreview={justPreview}
                    newsBodySize={newsBodySize}
                    includeSection={includeSection}
                    includeBusiness={includeBusiness}
                    hyperlinkSource={hyperlinkSource}
                    hyperlinkTitle={hyperlinkTitle}
                    subjectLine={subjectLine}
                />
            )}
        </>
    )
}
