import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import store from '../../../Store'
import './AdminHeader.css'

export default function AdminHeader(props) {
    const usertype = store.getState().auth.user.user_type
    return (
        <div className="adminHeader__container">
            {(props.currentPage === 'enduser' ||
                props.currentPage === 'firm' ||
                props.currentPage === 'sector' ||
                props.currentPage === 'documents' ||
                props.currentPage === 'contacts' ||
                props.currentPage === 'templates') && (
                <div>
                    {usertype !== 'Power User' && (
                        <>
                            <NavLink to="/app/admin/firm/">
                                <span
                                    className={
                                        props.currentPage === 'firm'
                                            ? 'adminHeader_menuElement-active'
                                            : 'adminHeader_menuElement-inactive'
                                    }
                                >
                                    {usertype === 'Power User' ? 'My Firm' : 'Firm'}
                                </span>
                            </NavLink>

                            <NavLink to="/app/admin/enduser/">
                                <span
                                    className={
                                        props.currentPage === 'enduser'
                                            ? 'adminHeader_menuElement-active'
                                            : 'adminHeader_menuElement-inactive'
                                    }
                                >
                                    {usertype === 'Power User' ? 'User Management' : 'User'}
                                </span>
                            </NavLink>

                            <NavLink to="/app/admin/sector/">
                                <span
                                    className={
                                        props.currentPage === 'sector'
                                            ? 'adminHeader_menuElement-active'
                                            : 'adminHeader_menuElement-inactive'
                                    }
                                >
                                    Industry / Sector
                                </span>
                            </NavLink>
                        </>
                    )}
                    {/* {usertype !== 'client_analyst' && usertype !== undefined && (
                        <NavLink to="/app/admin/documents/">
                            <span
                                className={
                                    props.currentPage === 'documents'
                                        ? 'adminHeader_menuElement-active'
                                        : 'adminHeader_menuElement-inactive'
                                }
                            >
                                Documents
                            </span>
                        </NavLink>
                    )} */}
                    {/* {usertype !== 'client_analyst' && usertype !== undefined && (
                        <NavLink to="/app/admin/contacts/">
                            <span
                                className={
                                    props.currentPage === 'contacts'
                                        ? 'adminHeader_menuElement-active'
                                        : 'adminHeader_menuElement-inactive'
                                }
                            >
                                Contacts
                            </span>
                        </NavLink>
                    )} */}
                    {/* {(usertype === 'analyst' || usertype === 'client_analyst') && (
                        <NavLink to="/app/admin/templates/">
                            <span
                                className={
                                    props.currentPage === 'templates'
                                        ? 'adminHeader_menuElement-active'
                                        : 'adminHeader_menuElement-inactive'
                                }
                            >
                                Templates
                            </span>
                        </NavLink>
                    )} */}
                </div>
            )}

            {props.currentPage === 'addUser' && (
                <div>
                    <NavLink to="/app/admin/enduser/">
                        <span className="adminHeader_menuElement-inactive">Go Back</span>
                    </NavLink>
                    <span className="adminHeader_menuElement-active">Adding new end user</span>
                </div>
            )}
            {props.currentPage === 'addFirm' && (
                <div>
                    <NavLink to="/app/admin/firm/">
                        <span className="adminHeader_menuElement-inactive">Go Back</span>
                    </NavLink>
                    <span className="adminHeader_menuElement-active">Adding new Firm</span>
                </div>
            )}
            {/* {props.currentPage === 'addSector' && (
                <div>
                    <NavLink to="/app/admin/sector/">
                        <span className="adminHeader_menuElement-inactive">Go Back</span>
                    </NavLink>
                    <span className="adminHeader_menuElement-active">Adding new Sector</span>
                </div>
            )} */}
            {/* {props.currentPage === 'addDocument' && (
                <div>
                    <NavLink to="/app/admin/documents/">
                        <span className="adminHeader_menuElement-inactive">Go Back</span>
                    </NavLink>
                    <span className="adminHeader_menuElement-active">Adding new Document</span>
                </div>
            )} */}
            {/* {props.currentPage === 'addContact' && (
                <div>
                    <NavLink to="/app/admin/contacts/">
                        <span className="adminHeader_menuElement-inactive">Go Back</span>
                    </NavLink>
                    <span className="adminHeader_menuElement-active">Adding new Contact</span>
                </div>
            )} */}
            {props.currentPage === 'createtemplates' && (
                <div>
                    <NavLink to="/app/newsletter-template/">
                        <span className="adminHeader_menuElement-inactive">Go Back</span>
                    </NavLink>
                    <span className="adminHeader_menuElement-active">Creating New Template</span>
                </div>
            )}
        </div>
    )
}
