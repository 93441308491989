import React, { useEffect } from 'react'
import { getFullFormattedDate } from '../../../utils/dateHelpers'
import twitterX from '../../../assets/icons/twitterx-white.svg'
import './MailModal.css'

export default function EmailPreview(props) {
    const {
        introText,
        selectedStories,
        primaryColor,
        secondaryColor,
        selectedLogo,
        setSize,
        hyperlinkSource,
        hyperlinkTitle,
    } = props

    const logoRef = React.useRef()
    const getSize = () => {
        setSize(logoRef.current.offsetWidth, logoRef.current.offsetHeight, 'primary')
    }

    return (
        <div>
            {/* Email Header */}
            <div className="mailModal__emailContainer">
                <table cellSpacing="0" cellPadding="0" className="mailModal__headerGraphicAndClickBelow">
                    <tbody>
                        <tr>
                            <td
                                colSpan="3"
                                className="mailModal__headerGraphic"
                                align="center"
                                style={{ width: '415px', height: '215px' }}
                            >
                                <img
                                    ref={ref => (logoRef.current = ref)}
                                    onLoad={e => getSize()}
                                    onChange={e => getSize()}
                                    alt="logo"
                                    src={selectedLogo}
                                    style={{ position: 'inherit', maxHeight: '100%', maxWidth: '100%' }}
                                />
                            </td>
                        </tr>

                        <tr align="center" className="mailModalClickBelowTR" style={{ backgroundColor: primaryColor }}>
                            <td>
                                <a href="https://app.kaitongo.com/" style={{ textDecoration: 'none' }}>
                                    Click Here to see more news on our app
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table style={{ width: '100%', border: `solid 1px ${primaryColor}` }}>
                    <tbody>
                        <tr>
                            <td className="mailModal__openingParagraphData">
                                <p>{getFullFormattedDate(Date.now())}</p>
                                <p>Hi,</p>
                                <p style={{ whiteSpace: 'pre-line' }}>{`${introText}`}</p>
                                {hyperlinkSource !== '' && hyperlinkTitle !== '' && (
                                    <div>
                                        <a href={hyperlinkSource} target="_blank" style={{ fontSize: '16px' }}>
                                            {hyperlinkTitle}
                                        </a>
                                    </div>
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>

                {/* News */}
                {selectedStories
                    .sort((a, b) => (a.date.split('T')[0] > b.date.split('T')[0] ? -1 : 1))
                    .map(story => {
                        return (
                            <table className="mailModal__newsContainer" style={{ border: `solid 1px ${primaryColor}` }}>
                                <tbody>
                                    <tr>
                                        <td
                                            className="mailModal__articleTD"
                                            style={{ border: `solid 1px ${primaryColor}` }}
                                        >
                                            {story.source_name && (
                                                <span style={{ fontSize: '13px', borderBottom: '0rem' }}>
                                                    {story.source_name}
                                                </span>
                                            )}
                                            <p style={{ fontSize: '13px' }}>{getFullFormattedDate(story.date)}</p>
                                            <p style={{ fontSize: '18px' }}>
                                                <strong> {story.title}</strong>
                                            </p>
                                            <p style={{ fontSize: '14px' }}>{story.snippet}</p>
                                            {story.subcategories && story.subcategories.length > 0 && (
                                                <p>
                                                    {story.subcategories.map(subcategory => {
                                                        return (
                                                            <span
                                                                key={subcategory.id}
                                                                style={{
                                                                    lineHeight: '25px',
                                                                    margin: '2px',
                                                                    fontSize: '12px',
                                                                    backgroundColor: secondaryColor,
                                                                    color: 'white',
                                                                    padding: '0.25rem',
                                                                    width: '-webkit-fit-content',
                                                                }}
                                                            >
                                                                {subcategory.name}
                                                            </span>
                                                        )
                                                    })}
                                                </p>
                                            )}
                                            <a href={story.source}>
                                                <p>Read the full article here</p>
                                            </a>
                                        </td>

                                        <td className="mailModal__ActionTD" style={{ backgroundColor: primaryColor }}>
                                            {story.opportunities &&
                                                story.opportunities.map(opp => {
                                                    let actionIcon
                                                    if (opp.opportunity_type__action_type === 'Client Outreach') {
                                                        actionIcon =
                                                            'https://kaitongoprod.nyc3.digitaloceanspaces.com/kaitongoprod/static/target.png'
                                                    } else if (
                                                        opp.opportunity_type__action_type === 'Thought Leadership'
                                                    ) {
                                                        actionIcon =
                                                            'https://kaitongoprod.nyc3.digitaloceanspaces.com/kaitongoprod/static/idea.png'
                                                    } else if (opp.opportunity_type__action_type === 'Social Media') {
                                                        actionIcon =
                                                            'https://kaitongoprod.nyc3.digitaloceanspaces.com/kaitongoprod/static/social-media.png'
                                                    }
                                                    return (
                                                        <div>
                                                            <span className="mailModal__actionIconAndTitle">
                                                                <img
                                                                    style={{ maxWidth: '40px', maxHeight: '40px' }}
                                                                    src={actionIcon}
                                                                    alt="action icon"
                                                                />
                                                                <span>{opp.opportunity_type__action_type}</span>
                                                            </span>
                                                            <p
                                                                style={{ fontSize: '14px' }}
                                                                dangerouslySetInnerHTML={{ __html: opp.sentence }}
                                                            />
                                                        </div>
                                                    )
                                                })}

                                            <div className="mailModal__ShareLine">
                                                {/* {story.linkedInGroups.length > 0 && <p>Groups to share with</p>} */}

                                                <p style={{ fontSize: '12px' }}>
                                                    <span>
                                                        Share on: &nbsp;
                                                        <a href="twitter.com">
                                                            {/* <img
                                                                style={{ width: '13.8px', height: '13.8px' }}
                                                                src="https://kaitongoprod.nyc3.digitaloceanspaces.com/kaitongoprod/static/Icon-awesome-twitter-square.png"
                                                                alt="twitter"
                                                            /> */}
                                                            <img
                                                                style={{ width: '13.8px', height: '13.8px' }}
                                                                src={twitterX}
                                                                alt="twitter"
                                                            />
                                                        </a>
                                                        &nbsp;
                                                        <a href="https://www.linkedin.com">
                                                            <img
                                                                style={{ width: '13.8px', height: '13.8px' }}
                                                                src="https://kaitongoprod.nyc3.digitaloceanspaces.com/kaitongoprod/static/Icon-awesome-linkedin.png"
                                                                alt="linkedIn"
                                                            />
                                                        </a>
                                                    </span>
                                                </p>
                                            </div>

                                            {/* {story.linkedInGroups.map(group => {
                                            return (
                                                <span className="mailModal__linkedGroupOuter">
                                                    <span className="mailModal__linkedGroupInner">
                                                        &nbsp;&nbsp;{group.label}&nbsp;&nbsp;
                                                    </span>
                                                </span>
                                            )
                                        })} */}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )
                    })}

                {/* Footer */}
                <table cellSpacing="0" cellPadding="0">
                    <tbody>
                        <tr>
                            <td className="mailModal__footerUpperContainer">
                                <p>
                                    Have questions or feedback? Email us at support@kaitongo.com You’re receiving this
                                    email because you’re subscribed to the Kaitongo digest. You can update your
                                    preferences or unsubscribe from this list
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td className="mailModal__footerLogos">
                                <img
                                    src="https://kaitongoprod.nyc3.digitaloceanspaces.com/kaitongoprod/static/linkedin2.png"
                                    alt="LinkedIn"
                                    style={{ marginRight: '32px' }}
                                />
                                <img
                                    src="https://kaitongoprod.nyc3.digitaloceanspaces.com/kaitongoprod/static/kaitongo-k.png"
                                    alt="Kaitongo Logo"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="mailModal__footerLowerContainer">
                                <p>
                                    Kaitongo: Actionable Intelligence for Law Firms See more sector insights on
                                    app.kaitongo.com
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
