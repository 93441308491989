import { getTrans } from './index'

export const validatePassword = password => {
    if (password.length < 8) {
        return 'Must be a minimum of 8 characters'
    } else if (!/[A-Z]/.test(password) && !/[a-z]/.test(password)) {
        return 'Must contain at least one letter'
    } else if (!/[0-9]/.test(password)) {
        return 'Must contain at least one number'
    } else if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)) {
        return 'Must contain at least one special character'
    }
    return null // Password is valid
}
