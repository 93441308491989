import React, { useEffect, useState } from 'react'
import './WhatsHappeningInMyIndustry.css'
import InfiniteScroll from 'react-infinite-scroller'
import { Circles } from 'react-loader-spinner'
import NewCards from '../../screens/NewsFeedScreen/components/NewCards'
import axios from 'axios'

export default function WhatsHappeningInMyIndustryV2(props) {
    const { news, setNews, isSector, isFeatured, name, type } = props
    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [isHover, setIsHover] = useState(false)
    const [batch, setBathcSize] = useState(3)

    const maxPages = Math.ceil(news.data && news.data.length / 6)

    const leftScroll = () => {
        if (page > 1) {
            setPage(prevPage => prevPage - 1)
        }
    }

    const rightScroll = () => {
        if (page <= maxPages && news.next !== null) {
            setPage(prevPage => prevPage + 1)
        } else if (maxPages > page) {
            setPage(prevPage => prevPage + 1)
        } else {
            setPage(prevPage => prevPage)
        }
    }

    const handleLoadMore = () => {
        const scrollUrl = news.next !== null && news.next.replace('http://localhost:8000', '')
        const defaultUnique = [...new Set(news.data)]
        if (page >= maxPages && news.next !== null) {
            setIsLoading(true)
            axios
                .get(scrollUrl)
                .then(response => {
                    let newsBefore = [...defaultUnique, ...response.data.data]
                    let uniqueNews = [...new Set(newsBefore)]
                    setNews({
                        next: response.data.next,
                        data: uniqueNews,
                    })
                })
                .catch(err => console.log(err))
                .finally(() => {
                    rightScroll()
                    setIsLoading(false)
                })
        } else {
            rightScroll()
        }
    }

    const renderNewsCards = () => {
        if (news && news.data && news.data.length > 0) {
            const cardsPerPage = 6
            const totalCards = news.data && news.data.length

            let startIndex = (page - 1) * cardsPerPage
            let endIndex = Math.min(startIndex + cardsPerPage, totalCards)

            if (totalCards < cardsPerPage) {
                startIndex = 0
                endIndex = totalCards
            }

            return (
                <div className="row">
                    {news.data &&
                        news.data.slice(startIndex, endIndex).map((val, index) => (
                            <div className="col-4 mt-4" key={index}>
                                <div className="child">
                                    <NewCards key={val.id} val={val} index={index} isTopicPage={true} />
                                </div>
                            </div>
                        ))}
                </div>
            )
        }
    }

    const handleMouseEnter = () => {
        setIsHover(true)
    }

    const handleMouseLeave = () => {
        setIsHover(false)
    }

    return (
        <div className="industryNews2__container">
            {news && news.data && news.data.length > 0 && (
                <div style={{ width: '95%', margin: 'auto' }}>
                    <div className="industryNews2__headingAndArrows">
                        <h3 className="headingGeneric" style={{ fontWeight: '800', fontSize: '21px' }}>
                            {isSector
                                ? `What Is Happening In ${name} ${
                                      type === 'industries' ? 'Industry' : type === 'sectors' ? 'Sector' : 'Topic'
                                  } and How Can I Utilize This?`
                                : `What Is Happening In ${name} and its Related Featured Companies`}
                        </h3>
                    </div>
                    <div
                        className="cover"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        style={{ padding: '0px' }}
                    >
                        <button
                            className="left"
                            style={{
                                color: page === 1 ? 'lightgray' : '#3b7dba',
                                opacity: isHover ? '1' : '0.3',
                                style: '1%',
                            }}
                            onClick={() => leftScroll()}
                        >
                            <i className="fas fa-angle-double-left" />
                        </button>

                        <div class="scroll-images" id="scrollss">
                            {renderNewsCards()}
                        </div>
                        {!isLoading ? (
                            <button
                                class="right"
                                id="right-scroll"
                                onClick={() => {
                                    handleLoadMore()
                                }}
                                style={{
                                    color: page >= maxPages ? 'lightgray' : '#3b7dba',
                                    opacity: isHover ? '1' : '0.3',
                                    background: 'white',
                                    style: '1%',
                                }}
                            >
                                <i class="fas fa-angle-double-right" />
                            </button>
                        ) : (
                            <div className="right loader">
                                <Circles
                                    key={0}
                                    style={{ fontWeight: 900 }}
                                    type="Grid"
                                    color="#adadad"
                                    height={60}
                                    width={60}
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}
