import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import { getTrans } from '../../../utils'
import './AnalystSidebar.css'

class FirmAnalystSidebar extends PureComponent {
    render() {
        return (
            <>
                <NavLink to="/app/firm-analyst/" className="nav-item">
                    <span>{getTrans('Dashboard')}</span>
                </NavLink>
                <NavLink to="/app/published-news/" className="nav-item">
                    <span>{getTrans('Publish Newsletter')}</span>
                </NavLink>
                <NavLink to="/app/add-news/" className="nav-item">
                    <div className="addNewsButtonContainer">
                        <p>Add News</p>
                        <p>+</p>
                    </div>
                </NavLink>
                <NavLink
                    to="/app/admin/enduser/"
                    className={this.props.urlPath.includes('admin') ? 'nav-item active' : 'nav-item '}
                >
                    <span>{getTrans('Admin')}</span>
                </NavLink>
            </>
        )
    }
}

export default FirmAnalystSidebar
