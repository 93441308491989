import React, { useState } from 'react'
import ChatRecommendationCards from './ChatRecommendationCards'

export default function RenderInsightsCards(props) {
    const { data, type } = props

    const [currentPage, setCurrentPage] = useState(1)
    const [expandedView, setExpandedView] = useState(false)

    const renderNewsCards = data => {
        if (data && data.length > 0) {
            const cardsPerPage = 3
            const totalCards = data && data.length
            const totalPages = Math.ceil(totalCards / cardsPerPage)

            let startIndex = (currentPage - 1) * cardsPerPage
            let endIndex = Math.min(startIndex + cardsPerPage, totalCards)

            if (totalCards < cardsPerPage) {
                startIndex = 0
                endIndex = totalCards
            }

            return (
                // <InfiniteScroll pageStart={0}>
                <div className="social_container_section" style={{ width: '100%' }}>
                    {data &&
                        data.slice(startIndex, endIndex).map((val, index) => (
                            <div className="" style={{ paddingLeft: '10px', paddingRight: '10px' }} key={index}>
                                <div
                                    className="child"
                                    style={{ height: ' auto ', margin: '5px 0px', borderRadius: '5px', width: '100%' }}
                                >
                                    {/* <ChatRecommendationCards
                                        key={val.id}
                                        val={val}
                                        index={index}
                                        isSocial={type === 'recommendation' ? true : false}
                                    /> */}
                                    <ChatRecommendationCards
                                        expandedView={!expandedView}
                                        setExpandedView={setExpandedView}
                                        key={val.id}
                                        val={val}
                                        index={index}
                                        isSocial={type === 'recommendation' ? true : false}
                                    />
                                </div>
                            </div>
                        ))}
                </div>
                // </InfiniteScroll>
            )
        }
    }
    return (
        <div style={{ width: '100%' }}>
            <div className="cover" style={{ padding: '0px' }}>
                <div class="scroll-images" style={{ padding: '0px', marginTop: '25px', width: '100%' }} id="scrollss">
                    {renderNewsCards(data)}
                </div>
            </div>
        </div>
    )
}
