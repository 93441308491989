import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import { getTrans } from '../../utils'
import AuthWrapper from '../../wrappers/AuthWrapper/AuthWrapper'
import { validateForm } from '../../utils/validateForm'
import { Form, Formik } from 'formik'
import RenderField from '../../form-elements/RenderField'
import { Button } from 'reactstrap'
import axios from 'axios'
import '../../wrappers/AuthWrapper/AuthWrapper.css'
import LoginFooter from '../../wrappers/LoginFooter'

const qs = require('query-string')

const fields = [
    {
        label: 'Password',
        value: '',
        name: 'password',
        component: 'password',
        placeholder: 'Password',
        type: 'password',
        required: true,
    },
    {
        label: getTrans('Confirm password'),
        value: '',
        name: 'password2',
        component: 'password',
        placeholder: getTrans('Type your password again'),
        type: 'password',
        required: true,
    },
]

class ResetPasswordScreen extends PureComponent {
    state = {
        nonFieldErrors: [],
        successMessage: '',
    }

    onSubmit = (values, { setSubmitting }) => {
        const { history, location } = this.props

        const { query } = qs.parseUrl(location.search)

        setSubmitting(false)
        axios
            .post(`/accounts/password-reset/confirm/`, {
                ...values,
                token: query.token,
            })
            .then(response => {
                console.log('reset password response', response)
                history.push('/reset-password-confirmation')
            })
            .catch(() => {
                this.setState({
                    nonFieldErrors: [getTrans('We could not reset your password.')],
                })
            })
    }

    render() {
        return (
            <AuthWrapper title={getTrans('Reset password')}>
                <Formik
                    initialValues={{
                        password: '',
                        password2: '',
                    }}
                    validate={values => validateForm(values, fields)}
                    onSubmit={this.onSubmit}
                    render={({ status, isSubmitting, ...formProps }) => (
                        <Form>
                            {fields.map(field => {
                                return RenderField(field, formProps)
                            })}
                            {this.state.nonFieldErrors.map(error => (
                                <div className="input-feedback" key={error}>
                                    {error}
                                </div>
                            ))}
                            <div className="text-right">
                                <Button
                                    color="primary"
                                    style={{
                                        // width: '80%',
                                        margin: 'auto',
                                        display: 'flex',
                                        backgroundColor: '#1766ad',
                                        textAlign: 'center',
                                    }}
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    {getTrans('Reset your password')}
                                </Button>
                            </div>
                        </Form>
                    )}
                />
            </AuthWrapper>
        )
    }
}

export default withRouter(ResetPasswordScreen)
