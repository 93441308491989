import React from 'react'
import './Step2Firm.css'

export default function Step2Form(props) {
    const createUser = () => {
        props.createNewUser()
    }

    const selectTopic = topic => {
        const topicCopy = [...props.selectedSubCats]
        topicCopy.push(topic)
        props.setSelectedSubCats(topicCopy)
    }
    const unselectTopic = topic => {
        let topicCopy = [...props.selectedSubCats]
        topicCopy = topicCopy.filter(item => topic !== item)
        props.setSelectedSubCats(topicCopy)
    }

    return (
        <div className="signup__step1Container">
            <p>
                News items selected for your relationships will be based on the topics below. These topics were
                pre-selected for you as you entered your Practice Area as{' '}
                <strong> "{props.selectedCategoryName}”</strong>
            </p>

            <div className="signup__topicsOuter">
                {props.allSubCats.map(cat => {
                    return (
                        <div
                            className={
                                !props.selectedSubCats.includes(cat.value)
                                    ? 'signup__topicCommon signup__topicNotSelected'
                                    : 'signup__topicCommon signup__topicSelected'
                            }
                            key={cat.value}
                            onClick={
                                !props.selectedSubCats.includes(cat.value)
                                    ? () => selectTopic(cat.value)
                                    : () => unselectTopic(cat.value)
                            }
                        >
                            <span>{cat.label}</span>

                            {!props.selectedSubCats.includes(cat.value) && (
                                <div className="signup__topicSelectButtons">
                                    <span className="signup__checkAndX">&#x2713;</span>
                                </div>
                            )}
                            {props.selectedSubCats.includes(cat.value) && (
                                <div className="signup__topicSelectButtons signup__topicSelected">
                                    <span className="signup__checkAndX">X</span>
                                </div>
                            )}
                        </div>
                    )
                })}

                {props.allSubCats.length === 0 && (
                    <h3>
                        No Topics for <strong>{props.selectedCategoryName} </strong>
                    </h3>
                )}
            </div>

            <div className="signup__buttons">
                <div className="signup__backButton" onClick={() => props.setPage(1)}>
                    <p>Back</p>
                </div>
                <div
                    style={{ marginTop: '43px' }}
                    className="signup__nextButton signup__nextButton75"
                    onClick={createUser}
                >
                    <p>Create Account</p>
                </div>
            </div>
        </div>
    )
}
