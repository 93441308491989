import React, { useEffect, useState, useRef } from 'react'
import BaseScreen from '../../BaseScreen/BaseScreen'
import './Home.css'
import './Scroll.css'
import './BigCard.css'
import { Circles } from 'react-loader-spinner'
import { ImPencil2 } from 'react-icons/im'
import { MdContentCopy } from 'react-icons/md'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import { BiPlus, BiMinus } from 'react-icons/bi'
import { AiFillCloseCircle } from 'react-icons/ai'
import axios from 'axios'
import { getCookie } from '../../../utils'
import { toast } from 'react-toastify'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Button, Input } from 'reactstrap'
import store from '../../../Store'
import { getFullFormattedDate } from '../../../utils/dateHelpers'
import NewCards from './NewCards'
import ScrollMenu from 'react-horizontal-scrolling-menu'
import ArrowBackIosNewSharp from '@mui/icons-material/ArrowBackIosNewSharp'
import ArrowForwardIosSharp from '@mui/icons-material/ArrowForwardIosSharp'
import InfiniteScroll from 'react-infinite-scroller'
const token = getCookie('csrftoken')
axios.defaults.headers.common['X-CSRFToken'] = token

export default function MyRecommendations(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [isThoughtLoader, setIsThoughtLoader] = useState(false)
    const [news, setNews] = useState({ next: null, data: [] })
    const [thoughtNews, setThoughtNews] = useState({ next: null, data: [] })
    const [emptyData, setEmptyData] = useState(false)
    const [selectscroll, setSelectscroll] = useState(0)
    const [emailNews, setEmailNews] = useState()
    const [emailNewsModal, setEmailNewsModal] = useState(false)
    const [emailNewsLoader, setEmailNewsLoader] = useState(false)
    const [defaultCount, setDefaultCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isHover, setIsHover] = useState(false)
    const maxPages = Math.ceil(news.socialAndClient && news.socialAndClient.length / 6)
    const [newsLoader, setNewsLoader] = useState(false)
    const [socialHeight, setSocialHeight] = useState(950)
    const [thoughtHeight, setThoughtHeight] = useState(0)

    useEffect(() => {
        const url = window.location.href
        const parts = url.split('?')
        const lastPart = parts[parts.length - 1]
        if (lastPart.includes('id=')) {
            const idPart = lastPart.split('id=')
            const id = idPart[1]
            axios
                .get(`/core/newsletter-news/?id=${id}`)
                .then(res => {
                    setEmailNews(res.data[0])
                    setEmailNewsModal(true)
                })
                .catch(err => console.log(err))
        } else {
            console.log('ID not found in the URL.')
        }
    }, [])

    useEffect(() => {
        setIsLoading(true)
        getRecommendationContent()
    }, [])

    useEffect(() => {
        if (selectscroll === 0) {
            if (
                document.getElementsByClassName('social_container_section') &&
                document.getElementsByClassName('social_container_section')[0]
            ) {
                setSocialHeight(document.getElementsByClassName('social_container_section')[0].clientHeight)
            }
        } else {
            if (
                document.getElementsByClassName('thought_container_section') &&
                document.getElementsByClassName('thought_container_section')[0]
            ) {
                setThoughtHeight(document.getElementsByClassName('thought_container_section')[0].clientHeight)
            } else {
                setThoughtHeight(document.body.scrollHeight)
            }
        }
    }, [news.next, thoughtNews.next])

    const getRecommendationContent = () => {
        axios
            .get(`/news/recommendations/`)
            .then(response => {
                const socialAndClient = response.data.Social_Client && response.data.Social_Client.data
                const Thoughtss = response.data.Thought && response.data.Thought.data
                const next = response.data.Social_Client && response.data.Social_Client.next
                const thoughtNext = response.data.Thought && response.data.Thought.next
                const socialTotal = response.data.Social_Client && response.data.Social_Client.total
                const thoughtTotal = response.data.Thought && response.data.Thought.total
                socialAndClient.length > 0 ? setSelectscroll(0) : setSelectscroll(1)
                // setDefaultCount(socialAndClient)

                if (socialAndClient.length > 0 || Thoughtss.length > 0) {
                    setNews({
                        socialAndClient: socialAndClient.sort((a, b) => new Date(b.date) - new Date(a.date)),
                        next: next,
                        total: socialTotal,
                    })
                    setThoughtNews({
                        thought: Thoughtss.sort((a, b) => new Date(b.date) - new Date(a.date)),
                        next: thoughtNext,
                        total: thoughtTotal,
                    })
                    setIsLoading(false)
                } else {
                    setEmptyData(true)
                    setIsLoading(false)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleLoadMore = type => {
        const scrollUrl =
            news.next !== null &&
            news.next.replace('http://localhost:8000', '') &&
            news.next.replace('http://kaitongo-backend:8000', '')
        const thoughtUrl =
            thoughtNews.next !== null &&
            thoughtNews.next.replace('http://localhost:8000', '') &&
            thoughtNews.next.replace('http://kaitongo-backend:8000/', '')

        const defaultUnique = [...new Set(news.socialAndClient)]
        const defaultThought = [...new Set(thoughtNews.thought)]
        if (news.next !== null || thoughtNews.next !== null) {
            if (type === 'social' && news.next !== null) {
                setNewsLoader(true)
                axios.get(scrollUrl).then(response => {
                    const next = response.data.Social_Client.next
                    let newsBefore = [...defaultUnique, ...response.data.Social_Client.data]
                    let uniqueNews = [...new Set(newsBefore)]
                    setNews({
                        next: next,
                        socialAndClient: uniqueNews.sort((a, b) => new Date(b.date) - new Date(a.date)),
                        total: response.data.Social_Client.total,
                    })
                    setNewsLoader(false)
                    window.scrollTo({
                        top: socialHeight + 60,
                        behavior: 'smooth',
                    })
                })
            }
            if (type === 'thought' && thoughtNews.next !== null) {
                setNewsLoader(true)
                axios.get(thoughtUrl).then(response => {
                    const nexts = response.data.Thought.next
                    let newsBefores = [...defaultThought, ...response.data.Thought.data]
                    let uniqueNewss = [...new Set(newsBefores)]
                    setThoughtNews({
                        next: nexts,
                        thought: uniqueNewss.sort((a, b) => new Date(b.date) - new Date(a.date)),
                        total: response.data.Thought.total,
                    })
                    setNewsLoader(false)
                    window.scrollTo({
                        top: thoughtHeight !== 0 ? thoughtHeight + 70 : document.body.scrollHeight,
                        behavior: 'smooth',
                    })
                })
            }
        }
    }

    const [result, setResult] = useState('')
    const [leadership, setLeadership] = useState(false)

    const thoughtContentApi = () => {
        result.toString()
        const removebr = result.replace(/(<([^>]+)>)/gi, '')
        navigator.clipboard.writeText(removebr)
        var tooltip = document.getElementById('thoughtTooltip')
        tooltip.innerHTML = 'Copy to clipboard'
        setTimeout(() => {
            tooltip.innerHTML = ''
        }, 1000)
    }
    function GenerateTexts() {
        setTimeout(() => {
            const search = document.getElementById('generatings')
            if (search) {
                const searchPlaceholder = 'Generating...'
                let i = 0
                let placeholder = ''
                let speed = 300
                typing()
                function typing() {
                    clearInterval(interval)
                    interval = setInterval(() => {
                        if (search) {
                            placeholder += searchPlaceholder[i]
                            i++
                            search.value = placeholder
                            search.scrollTop = search.scrollHeight
                            if (i > searchPlaceholder.length - 1) {
                                clearInterval(interval)
                            }
                        }
                    }, 30)
                }
            }
        }, 500)
    }
    let interval

    const newsGenerate = (val, act, type) => {
        if (type === 'thought' && (val.thought === null || val.thought === '') && act === 'Thought Leadership') {
            setIsThoughtLoader(true)
            GenerateTexts()
            setLeadership(!leadership)
            const payLoad = {
                id: val.id,
                action: act,
                media: 'Thoughts',
                snippet: val.snippet.length === 0 ? val.news_url : val.snippet,
                sectors: val.sectors,
            }
            axios
                .post(`/core/generate-content/`, payLoad)
                .then(response => {
                    if (response.data === 'You have exceeded the limit to generate content') {
                        setIsThoughtLoader(false)
                        setLeadership(leadership)
                        toast('You have exceeded the limit to generate content')
                    } else {
                        setLeadership(!leadership)
                        setIsThoughtLoader(false)
                        const search = document.getElementById('thoughtArea')
                        if (search) {
                            const searchPlaceholder = response.data
                            let i = 0
                            let speed = 120
                            let placeholder = ''
                            clearInterval(interval) // Clear any existing interval
                            interval = setInterval(() => {
                                if (search && i < searchPlaceholder.length) {
                                    placeholder += searchPlaceholder[i]
                                    i++
                                    search.value = placeholder
                                    search.scrollTop = search.scrollHeight
                                } else {
                                    clearInterval(interval) // Clear interval when animation is done
                                }
                            }, 30)
                        }
                        setIsThoughtLoader(false)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    const thoughtEdit = val => {
        setLeadership(!leadership)
        setResult(val.thought ? val.thought : val.snippet)
        if (val.thought === '' || val.thought === null || val.thought === undefined) {
            newsGenerate(val, 'Thought Leadership', 'thought')
        } else {
            setTimeout(() => {
                const search = document.getElementById('thoughtArea')
                const searchPlaceholder = val.thought ? val.thought : val.snippet
                let i = 0
                let placeholder = ''
                let speed = 120
                typing()
                function typing() {
                    let interval = setInterval(() => {
                        placeholder += searchPlaceholder[i]
                        i++
                        // search.setAttribute('placeholder', placeholder)
                        search.value = placeholder
                        if (i > searchPlaceholder.length - 1) {
                            clearInterval(interval)
                        }
                    }, 30)
                }
            }, 100)
        }
    }

    if (isLoading) {
        return (
            <BaseScreen>
                <Circles key={0} type="Grid" color="#adadad" height={80} width={80} />
            </BaseScreen>
        )
    }

    const leftScroll = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1)
        }
    }

    const rightScroll = () => {
        if (currentPage <= maxPages && news.next !== null) {
            setCurrentPage(prevPage => prevPage + 1)
        } else if (maxPages > currentPage) {
            setCurrentPage(prevPage => prevPage + 1)
        } else {
            setCurrentPage(prevPage => prevPage)
        }
    }

    const renderNewsCards = () => {
        if (news.socialAndClient && news.socialAndClient.length > 0) {
            const cardsPerPage = 6
            const totalCards = news.socialAndClient && news.socialAndClient.length
            const totalPages = Math.ceil(totalCards / cardsPerPage)

            let startIndex = (currentPage - 1) * cardsPerPage
            let endIndex = Math.min(startIndex + cardsPerPage, totalCards)

            if (totalCards < cardsPerPage) {
                startIndex = 0
                endIndex = totalCards
            }

            return (
                <InfiniteScroll
                    pageStart={0}
                    // loadMore={() => {
                    //     handleLoadMore('social')
                    // }}
                    // hasMore={news.socialAndClient.length < news.total}
                    // loader={
                    //     news.next !== null && <Circles key={0} type="Circles" color="#adadad" height={80} width={80} />
                    // }
                >
                    <div className="row social_container_section">
                        {news.socialAndClient &&
                            news.socialAndClient.map((val, index) => (
                                <div className="col-4 mt-4" key={index}>
                                    <div className="child">
                                        <NewCards key={val.id} val={val} index={index} isRecommendationSocial={true} />
                                    </div>
                                </div>
                            ))}
                    </div>
                </InfiniteScroll>
            )
        }
    }

    const handleMouseEnter = () => {
        setIsHover(true)
    }

    const handleMouseLeave = () => {
        setIsHover(false)
    }

    return (
        <BaseScreen>
            <Modal
                isOpen={emailNewsModal}
                toggle={() => {
                    setEmailNewsModal(!emailNewsModal)
                }}
                size="xl"
            >
                <div className="modal_header_black">
                    <span style={{ color: 'white' }}>{emailNews && emailNews.title}</span>
                    <span
                        onClick={() => {
                            setEmailNewsModal(!emailNewsModal)
                        }}
                        className="modal_close_btn"
                    >
                        <AiFillCloseCircle />
                    </span>
                </div>
                <ModalBody>
                    <div className="">
                        <NewCards
                            val={emailNews}
                            index={0}
                            isRecommendationMailNews={true}
                            emailNewsLoader={emailNewsLoader}
                            setEmailNewsLoader={setEmailNewsLoader}
                        />
                    </div>
                </ModalBody>
            </Modal>
            {emptyData && (
                <h1 style={{ textAlign: 'center' }}>Current preference don't have news, Please update preferences</h1>
            )}
            {isLoading && emptyData && (
                <div>
                    <Circles key={0} type="Grid" color="#adadad" height={80} width={80} />
                </div>
            )}
            {!isLoading && !emptyData && (
                <div style={{ marginLeft: '3%', marginRight: '3%' }}>
                    <div className="indSelect__container">
                        <div className="indSelect__content" style={{ width: '85%' }}>
                            {news.socialAndClient && news.socialAndClient.length > 0 && (
                                <span
                                    className={
                                        selectscroll === 0 ? 'indSelect__link-Active' : 'indSelect__link-Inactive'
                                    }
                                    onClick={() => {
                                        setSelectscroll(0)
                                        // window.scrollTo({ top: 0, behavior: 'smooth' })
                                    }}
                                >
                                    Social & Client Outreach
                                </span>
                            )}

                            {thoughtNews.thought && thoughtNews.thought && thoughtNews.thought.length > 0 && (
                                <span
                                    className={
                                        selectscroll === 1 ? 'indSelect__link-Active' : 'indSelect__link-Inactive'
                                    }
                                    onClick={() => {
                                        setSelectscroll(1)
                                        // window.scrollTo({ top: 0, behavior: 'smooth' })
                                    }}
                                >
                                    Thought Leadership
                                </span>
                            )}
                        </div>
                        <span
                            onClick={() => {
                                if (selectscroll === 0) {
                                    handleLoadMore('social')
                                } else if (selectscroll === 1) {
                                    handleLoadMore('thought')
                                }
                            }}
                            style={{
                                cursor: 'pointer',
                                width: 'max-content',
                                display:
                                    (selectscroll === 0 && news.next === null) ||
                                    (selectscroll === 1 && thoughtNews.next === null)
                                        ? 'none'
                                        : 'block',
                                marginRight: '4%',
                            }}
                        >
                            <span
                                style={{
                                    display: 'flex',
                                    marginBottom: '0rem',
                                    fontWeight: '900',
                                    alignItems: 'center',
                                }}
                            >
                                <span>See more</span>
                                <span style={{ marginLeft: '5px', marginTop: '2px' }}>
                                    <i class="fa fa-angle-double-right" aria-hidden="true" />
                                </span>
                            </span>
                        </span>
                    </div>
                    <div>
                        {selectscroll === 0 && (
                            <>
                                {news.socialAndClient && news.socialAndClient.length > 0 && (
                                    <div>
                                        <p
                                            style={{
                                                fontSize: '22px',
                                                fontWeight: '800',
                                                // marginBottom: '20px',
                                                marginTop: '6%',
                                            }}
                                        >
                                            Social & Client Outreach
                                        </p>
                                    </div>
                                )}

                                <div style={{ width: '100%' }}>
                                    <div className="cover" style={{ padding: '0px' }}>
                                        <div class="scroll-images" style={{ padding: '0px' }} id="scrollss">
                                            {renderNewsCards()}
                                        </div>
                                        {newsLoader && (
                                            <div className="d-flex justify-content-center mt-2">
                                                <Circles
                                                    key={0}
                                                    type="Circles"
                                                    color="#adadad"
                                                    height={80}
                                                    width={80}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}

                        {selectscroll === 1 && (
                            <>
                                {thoughtNews.thought && thoughtNews.thought && thoughtNews.thought.length > 0 && (
                                    <div>
                                        <p
                                            style={{
                                                fontSize: '22px',
                                                fontWeight: '800',
                                                marginTop: '6%',
                                            }}
                                        >
                                            Thought Leadership
                                        </p>

                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'left',
                                                width: '100%',
                                                minHeight: '300px',
                                                border: '1px solid #C3C3C3',
                                                marginBottom: '30px',
                                                textAlign: 'left',
                                                marginleft: '3%',
                                                backgroundColor: 'white',
                                                paddingBottom: '10px',
                                                marginTop: '10px',
                                            }}
                                            className="thought_container_section"
                                        >
                                            <span className="bigCard_container_top_box">
                                                <p />
                                            </span>
                                            <InfiniteScroll
                                                pageStart={0}
                                                // loadMore={() => {
                                                //     handleLoadMore('thought')
                                                // }}
                                                // hasMore={thoughtNews.thought.length < thoughtNews.total}
                                                // loader={
                                                //     thoughtNews.next !== null && (
                                                //         <Circles
                                                //             key={0}
                                                //             type="Circles"
                                                //             color="#adadad"
                                                //             height={80}
                                                //             width={80}
                                                //         />
                                                //     )
                                                // }
                                            >
                                                {thoughtNews.thought &&
                                                    thoughtNews.thought &&
                                                    thoughtNews.thought.map(val => (
                                                        <div class="Bigcard">
                                                            <div>
                                                                <a
                                                                    href={val.news_url}
                                                                    style={{ color: '#031429' }}
                                                                    target="_blank"
                                                                >
                                                                    {' '}
                                                                    <h1>{val.title}</h1>
                                                                </a>
                                                            </div>
                                                            <h2 style={{ fontSize: '15px' }}>
                                                                {getFullFormattedDate(val.date)}
                                                            </h2>

                                                            <p
                                                                style={{
                                                                    width: '95%',
                                                                }}
                                                            >
                                                                {val.snippet}
                                                            </p>

                                                            <div className="Social_container">
                                                                <p
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        thoughtEdit(val)
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <ImPencil2
                                                                        style={{
                                                                            marginRight: '5px',
                                                                            marginLeft: '5px',
                                                                        }}
                                                                        fontSize={18}
                                                                    />
                                                                </p>
                                                            </div>
                                                            <div className="d-flex align-items-center" title="source">
                                                                <a
                                                                    href={val.news_url ? val.news_url : ''}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    style={{
                                                                        textDecoration: 'none',
                                                                        fontSize: '15px',
                                                                    }}
                                                                >
                                                                    {val.source.toUpperCase()}
                                                                </a>

                                                                {val.other_sources.length > 0 && (
                                                                    <div className="d-flex align-items-center">
                                                                        {val.other_sources.map((val, index) => (
                                                                            <div className="d-flex align-items-center">
                                                                                <span
                                                                                    style={{
                                                                                        borderLeft:
                                                                                            '1.5px solid lightgray',
                                                                                        height: '15px',
                                                                                        marginRight: '10px',
                                                                                        fontSize: '10px',
                                                                                        marginLeft: '10px',
                                                                                    }}
                                                                                />
                                                                                <a
                                                                                    href={val.source ? val.source : ''}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    style={{
                                                                                        textDecoration: 'none',
                                                                                        fontSize: '15px',
                                                                                    }}
                                                                                >
                                                                                    {val.source_name
                                                                                        ? val.source_name.toUpperCase()
                                                                                        : val.source
                                                                                              .match(
                                                                                                  /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/gim
                                                                                              )[0]
                                                                                              .replace(
                                                                                                  /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?/gim,
                                                                                                  ''
                                                                                              )
                                                                                              .toUpperCase()}
                                                                                </a>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                            </InfiniteScroll>

                                            <Modal
                                                isOpen={leadership}
                                                toggle={() => {
                                                    setLeadership(!leadership)
                                                }}
                                                size="lg"
                                            >
                                                <div className="modal_header_black">
                                                    <span style={{ color: 'white' }}>AI Recommendations</span>
                                                    <span
                                                        onClick={() => {
                                                            setLeadership(!leadership)
                                                        }}
                                                        className="modal_close_btn"
                                                    >
                                                        <AiFillCloseCircle />
                                                    </span>
                                                </div>
                                                {isThoughtLoader ? (
                                                    <ModalBody>
                                                        <input
                                                            style={{
                                                                width: '97%',
                                                                height: '30px',
                                                                outline: 'none',
                                                                border: 'none',
                                                            }}
                                                            id="generatings"
                                                            value={''}
                                                        />
                                                    </ModalBody>
                                                ) : (
                                                    <ModalBody>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                            }}
                                                        >
                                                            <h3 style={{ fontWeight: 'bold' }} />
                                                            <div class="">
                                                                <button
                                                                    onClick={thoughtContentApi}
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        border: 'none',
                                                                        background: 'none',
                                                                        marginRight: '20px',
                                                                    }}
                                                                >
                                                                    <span class="tooltiptext" id="thoughtTooltip" />
                                                                    <MdContentCopy style={{ cursor: 'pointer' }} />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        {/* <Typed style={{ whiteSpace: 'pre-wrap' }} strings={[result]} typeSpeed={10} /> */}
                                                        <textarea
                                                            style={{ width: '97%', height: '200px' }}
                                                            id="thoughtArea"
                                                            // value={''}
                                                            placeholder="AI Recommendation Content"
                                                            readOnly
                                                        />
                                                    </ModalBody>
                                                )}
                                            </Modal>
                                        </div>
                                        {newsLoader && (
                                            <div className="d-flex justify-content-center mt-2">
                                                <Circles
                                                    key={0}
                                                    type="Circles"
                                                    color="#adadad"
                                                    height={80}
                                                    width={80}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            )}
        </BaseScreen>
    )
}
