import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Select from 'react-select'
import DataTable from 'react-data-table-component'
import { Circles } from 'react-loader-spinner'
import { NavLink } from 'react-router-dom'
import BaseScreen from '../BaseScreen/BaseScreen'
import AdminHeader from './components/AdminHeader'
import { sectorDataTableColumns as columns } from './constants/AnalystAdminConstants'

export default function AnalystAdminSector() {
    const [filterText, setFilterText] = useState('')
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
    const [statusFilter, setStatusFilter] = useState()
    const [allIndustries, setAllIndustries] = useState([])

    useEffect(() => {
        const headerTitle = document.getElementById('header_title')
        headerTitle.textContent = 'Manage Industy/Sector'
        axios.get(`/news/all-industries-sectors/`).then(response => {
            let industriesTemp = response.data
            axios.get(`/news/checklist/?sector&industry`).then(checklistResponse => {
                const checklist = checklistResponse.data
                industriesTemp.forEach((ind, index) => {
                    const indName = ind.label
                    if (
                        checklist[indName] &&
                        checklist[indName].Associations === true &&
                        checklist[indName].Featured_Companies === true &&
                        checklist[indName].Companies === true &&
                        checklist[indName].Conferences === true &&
                        checklist[indName].Deals === true
                    ) {
                        industriesTemp[index].completion = true
                    } else {
                        industriesTemp[index].completion = false
                    }
                })
                setAllIndustries(industriesTemp)
            })
        })
    }, [])

    const filteredItems = allIndustries
        .filter(item => item.label && item.label.toLowerCase().includes(filterText.toLowerCase()))
        .filter(item => {
            if (statusFilter && statusFilter.label === 'Active') {
                return item.status === 'True'
            } else if (statusFilter && statusFilter.label === 'Inactive') {
                return item.status === 'False'
            } else {
                return item
            }
        })

    const handleClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle)
            setFilterText('')
        }
        setStatusFilter()
    }

    if (allIndustries.length === 0) {
        return (
            <BaseScreen>
                <Circles key={0} type="Grid" color="#adadad" height={80} width={80} />
            </BaseScreen>
        )
    }

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: '#00193C', // Change to your desired header background color
                color: '#FFF', // Change to your desired header text color
                fontSize: '16px', // Change to your desired header font size
                height: '20px',
            },
        },
    }

    return (
        <BaseScreen>
            <AdminHeader currentPage={'sector'} />

            <div className="adminEndUser__filterSection" style={{ width: '95%', margin: '60px auto auto auto' }}>
                <div className="adminEndUser__filters">
                    <div className="adminEndUser__emailFilterContainer">
                        <input
                            id="search"
                            type="text"
                            className="analystAdmin__filterTextField"
                            placeholder="Sector name filter"
                            aria-label="Search Input"
                            value={filterText}
                            onChange={e => setFilterText(e.target.value)}
                            key={'Email Filter'}
                        />
                        <div type="button" onClick={handleClear} className="analystAdmin__ClearButton">
                            X
                        </div>
                    </div>

                    <Select
                        name="Status"
                        options={[{ label: 'Active', value: '1' }, { label: 'Inactive', value: '2' }]}
                        placeholder="Status"
                        onChange={value => setStatusFilter(value)}
                        value={statusFilter}
                        className="companiesAutoCompleteBox225NoMarginTop"
                    />
                </div>

                <NavLink to="/app/admin/add-sector/" className="adminEndUser__newUserButton">
                    <span>Add Sector +</span>
                </NavLink>
            </div>

            <div className="adminEndUser__body">
                <DataTable
                    title="Sectors"
                    columns={columns}
                    data={filteredItems}
                    pagination
                    paginationResetDefaultPage={resetPaginationToggle}
                    persistTableHead
                    customStyles={customStyles}
                />
            </div>
        </BaseScreen>
    )
}
